import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

import NewTooltip from '../../../components/Tooltip';
import { PatternFormat } from 'react-number-format';
import AutocompleteMaps from '../../../components/AutoCompleteMaps';
import { KeyMaps } from '../../../data/Data';

import styles from '../styles.module.scss';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../../data/theme';
import Swal from 'sweetalert2';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import { compareProperties } from '../../../functions/compareProperties';

const InfoCustomer = ({ open, close, customer, setCustomer, checkUser, customerRef, userRef }) => {
  const [changeData, setChangeData] = useState(false);
  const [canConfirm, setCanConfirm] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const props = checkCustomer();
    console.log(props);
    setCanConfirm(props.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, customerRef]);

  const checkCustomer = () => {
    const unequalProps = compareProperties(customer, customerRef, ['cep', 'cidade', 'estado', 'nome', 'telefone','data_nascimento', 'doc', 'email', 'empresa', 'complemento', 'bairro']);
    return unequalProps.length === 0 ? '' : unequalProps.join(', ');
  };

  const onSubmit = (e) => {
    try {
      e.preventDefault();
      Swal.fire({
        title: 'Atenção',
        html: `Você deseja confirmar a alteração dos dados?`,
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await updateDoc(doc(dataBase, 'Clientes', customer.id), {
            nome: customer.nome,
            cidade: customer.cidade ?? '',
            doc: customer.doc ?? '',
            empresa: customer.empresa ?? '',
            data_nascimento: customer.data_nascimento ?? '',
            telefone: typeof customer.telefone === 'number' ? customer.telefone : customer.telefone.replace(/\D/g, ''),
            email: customer.email ?? '',
            cep: customer.cep ?? '',
            endereco: customer.endereco ?? '',
            numero: customer.numero ?? '',
            bairro: customer.bairro ?? '',
            complemento: customer.complemento ?? '',
            updateAt: new Date(),
            historico: [...customer.historico, {
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Cliente Alterado',
              text: `Os seguintes dados cadastrais foram alterados: ${checkCustomer()}.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome
            }]

          }).then(() => {
            Swal.fire({
              title: 'Cliente',
              html: `Os dados foram alterados com sucesso.`,
              icon: "success",
              showCloseButton: true,
              confirmButtonColor: "#111",
              confirmButtonText: "Ok",
            })
            setChangeData(false);
          })
        }
      })
    } catch (error) {
      console.log('Erro: ' + error);
    }
  }

  const mask = (v) => {
    v = v.replace(/\D/g, "")

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      v = v.substring(0, 14); // limita em 14 números
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
  }

  const closeChangeData = (type) => {
    if (type === 'box') {
      close();
      setTimeout(() => {
        setCustomer(customerRef);
        setChangeData(false);
      }, 500);
    } else {
      setCustomer(customerRef);
      setChangeData(false);
    }
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth="md">
      <IconButton
        aria-label="close"
        onClick={() => closeChangeData('box')}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align='center' marginBottom={'0.3rem'}>Dados Cadastrais</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} className={styles.panel_content}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                label="Nome Completo"
                type="text"
                required
                disabled={!changeData}
                value={customer.nome ? customer.nome : ''}
                onChange={(e) => setCustomer({ ...customer, nome: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                label="CNPJ/CPF"
                type="text"
                inputProps={{
                  minLength: 14
                }}
                disabled={!changeData}
                value={customer.doc ? customer.doc : ''}
                onChange={(e) => setCustomer({ ...customer, doc: mask(e.target.value) })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                label="Email"
                type="text"
                disabled={!changeData}
                value={customer.email ? customer.email : ''}
                onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoFocus
                fullWidth
                label="Empresa"
                type="text"
                disabled={!changeData}
                value={customer.empresa ? customer.empresa : ''}
                onChange={(e) => setCustomer({ ...customer, empresa: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PatternFormat
                value={customer.telefone ? customer.telefone : ''}
                onChange={(e) => setCustomer({ ...customer, telefone: e.target.value })}
                format="(##) ##### ####"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="(00) 00000 0000"
                label="Telefone"
                minLength={9}
                variant="outlined"
                disabled={!changeData}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PatternFormat
                value={customer?.data_nascimento}
                onChange={(e) => setCustomer({ ...customer, data_nascimento: e.target.value })}
                format="##/##/####"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="00/00/0000"
                label="Data de Nascimento"
                minLength={10}
                variant="outlined"
                disabled={!changeData}
              />
            </Grid>
            <Grid item xs={12} sm={3} marginTop={1}>
              <PatternFormat
                value={customer.cep}
                onChange={(e) => setCustomer({ ...customer, cep: e.target.value })}
                format="#####-###"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="00000-000"
                label="CEP"
                minLength={9}
                variant="outlined"
                disabled={!changeData}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <AutocompleteMaps
                KeyMaps={KeyMaps}
                setCustomer={setCustomer}
                check={!changeData}
                address={customer.endereco?.endereco ?? ''}
                title='Endereço com Número' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                fullWidth
                label="Complemento"
                type="text"
                disabled={!changeData}
                value={customer.complemento ? customer.complemento : ''}
                onChange={(e) => setCustomer({ ...customer, complemento: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                fullWidth
                label="Bairro"
                type="text"
                disabled={!changeData}
                value={customer.bairro ? customer.bairro : ''}
                onChange={(e) => setCustomer({ ...customer, bairro: e.target.value })}
                variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NewTooltip title={'Só atendemos o estado de São Paulo'} placement={'top'}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Estado"
                  type="text"
                  disabled
                  value={'SP'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                  }}
                  variant="outlined" />
              </NewTooltip>
            </Grid>
            <Grid item xs={12} sm={3}>
              <NewTooltip title={'A Cidade é setada automaticamente através do Endereço informado'} placement={'top'}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Cidade"
                  type="text"
                  disabled
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                  }}
                  value={customer.cidade ? customer.cidade : ''}
                  onChange={(e) => setCustomer({ ...customer, cidade: e.target.value })}
                  variant="outlined" />
              </NewTooltip>
            </Grid>
          </Grid>
          <DialogActions>
            {changeData ?
              <>
                <Button
                  variant='contained'
                  disableElevation
                  color='success'
                  type='submit'
                  disabled={!canConfirm}
                  sx={{ textTransform: 'capitalize' }}
                  startIcon={<CheckCircle />}
                >Confirmar</Button>
                <Button
                  variant='contained'
                  disableElevation
                  color='error'
                  sx={{ textTransform: 'capitalize' }}
                  startIcon={<CancelIcon />}
                  onClick={closeChangeData}
                >Cancelar</Button>
              </> :
              <>
                <Button
                  variant='contained'
                  disableElevation
                  type='button'
                  disabled={!checkUser()}
                  sx={{ textTransform: 'capitalize' }}
                  startIcon={<EditIcon />}
                  onClick={() => setChangeData(true)}
                >Alterar Dados</Button>
                <ThemeProvider theme={theme}>
                  <Button
                    variant='contained'
                    disableElevation
                    type='button'
                    onClick={() => closeChangeData('box')}
                    sx={{ textTransform: 'capitalize' }}>Fechar</Button>
                </ThemeProvider>
              </>
            }
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default InfoCustomer
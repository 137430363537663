import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss';
import { Avatar, Box, InputAdornment, ListItem, ListItemAvatar, TextField, ListItemIcon, ListItemText, Typography, List } from '@mui/material';
import parse from 'html-react-parser';
import ChatBot from '../../assets/image/Chatbot.png';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PersonIcon from '@mui/icons-material/Person';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PeopleIcon from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DescriptionIcon from '@mui/icons-material/Description';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import { MdPayments } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";

const History = ({ history, members }) => {
    const [historyList, setHistoryList] = useState([]);

    useEffect(() => {
        if (history) {
            setHistoryList(history);
        }
    }, [history]);

    console.log(historyList);

    const checkMember = (data) => {
        const member = members.find(m => m.id === data) || { nome: 'Automação' };
        return member;
    }

    const checkIcon = (event, type = null) => {

        const iconStyles = {
            fill: "#575757",
            width: '20px'
        }

        if (event.includes('Cliente')) {
            return <PersonIcon sx={iconStyles} />
        } else if (event.includes('Visita')) {
            return visitType(type);
        } else if (event.includes('Planilha')) {
            return <DescriptionIcon sx={{ fill: "#4cda00" }} />;
        } else if (event.includes('Orçamento')) {
            return <RequestQuoteRoundedIcon sx={{ fill: "#063" }} />;
        } else if (event.includes('Pagamento')) {
            return <MdPayments size='1.3rem' color='#515151' />;
        } else if (event.includes('Simulação')) {
            return <BiMoneyWithdraw size='1.3rem' color='#000' />;
        }
    }

    const visitType = (type) => {

        if (type === 'comercial_tecnica') {
            return <PeopleIcon sx={{
                fill: "#576af5",
                width: '20px'
            }} />;
        } else if (type === 'comercial') {
            return <RequestQuoteIcon sx={{
                fill: "#0eb05f",
                width: '20px'
            }} />;
        } else if (type === 'pos_venda') {
            return <EngineeringIcon sx={{
                fill: "#e24725",
                width: '20px'
            }} />;
        } else if (type === 'tecnica') {
            return <EngineeringIcon sx={{
                fill: "#1c256e",
                width: '20px'
            }} />;
        } else {
            return <CalendarMonthRoundedIcon sx={{
                fill: "#606060",
                width: '20px'
            }} />;
        }
    }

    return (
        <div className={styles.history_main}>
            <Box sx={{
                margin: "1rem 0", display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'center'
            }}>
                <HistoryRoundedIcon sx={{ fill: "#606060" }} />
                <h3>Histórico</h3>
            </Box>
            <TextField
                fullWidth
                variant='standard'
                label="Pesquisar"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchRoundedIcon />
                        </InputAdornment>
                    ),
                }} />
                <Box
                    sx={{ 
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1
                     }}>
                    <List 
                        className={styles.history} 
                        sx={{ 
                            width: '100%', 
                            marginTop: '1rem', 
                            display: 'flex',
                            flex: 1,
                            minHeight: '100%',
                            maxHeight: '250px',
                            flexDirection: 'column',
                            overflow: 'auto' }}
                        >
                        {historyList.length > 0 ? historyList.slice().reverse().map((data, index) => (
                            <ListItem key={index} alignItems='flex-start' sx={{ padding: '0 16px' }} className={styles.history_item}>
                                <ListItemAvatar sx={{ minWidth: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '0.5rem', alignItems: 'center', marginRight: '0.3rem' }}>
                                    <Avatar alt={checkMember(data.usuario_id).nome} src={checkMember(data.usuario_id).photo?.url || ChatBot} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={checkMember(data.usuario_id).nome}
                                    primaryTypographyProps={{ fontSize: '17px', fontWeight: 'bold' }}
                                    secondary={<React.Fragment>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">
                                                {data.data.replace("-", "às")}
                                            </Typography>
                                            <ListItem alignItems='flex-start' sx={{ padding: '0 16px' }}>
                                                <ListItemIcon sx={{ minWidth: '30px', marginTop: '6px' }}>
                                                    {checkIcon(data.evento, data.visita)}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={data.evento}
                                                    primaryTypographyProps={{ fontWeight: 'bold', color: "#575757" }}
                                                    secondary={<div className={styles.list_text}>{parse(data.text)}</div>}
                                                    secondaryTypographyProps={{ fontStyle: 'italic', fontWeight: '500' }} />
                                            </ListItem>
                                        </Box>
                                    </React.Fragment>}
                                />
                            </ListItem>
                        )): <Typography variant='h5' color='#a1a1a1' textAlign='center'>Nenhum histórico encontrado</Typography>
                        }
                    </List>
                </Box>
        </div>
    )
}

export default History;
import React, { useEffect, useRef, useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import 'react-h5-audio-player/lib/styles.css';
import './styles.scss';
import NewTooltip from '../Tooltip';
import { Button, ThemeProvider } from '@mui/material';
import { AudioVisualizer } from 'react-audio-visualize';
import axios from 'axios';
import { theme } from '../../data/theme';

const CustomAudioPlayerFixed = ({ url, view, color = "#000", background = "#fff" }) => {
  const [isClient, setIsClient] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const audioRef = useRef(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [play, setPlay] = useState(false);
  const visualizerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    // Este efeito garante que o componente seja renderizado apenas no lado do cliente
    setIsClient(true);
  }, []);


  const fetchAudioBlob = async (audioUrl) => {
    try {
      const response = await axios.get(audioUrl, {
        responseType: 'arraybuffer',
      });
      return new Blob([response.data], { type: 'audio/webm' });
    } catch (error) {
      console.error('Failed to fetch audio blob:', error);
      return null;
    }
  };

  console.log(audioBlob)

  useEffect(() => {
    let isMounted = true;

    // Converter URL em Blob
    fetchAudioBlob(url).then(blob => {
      if (isMounted && blob) {
        setAudioBlob(blob);
      }
    });

    const handleTimeUpdate = () => {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.audio.current.currentTime);
        setDuration(audioRef.current.audio.current.duration);
      }
    };

    if (audioRef.current) {
      const audioElement = audioRef.current.audio.current;
      audioElement.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      isMounted = false;
      window.removeEventListener('resize', handleResize);
    };
  }, [url, play]);

  if (!isClient) {
    return null; // Não renderiza nada no lado do servidor
  }

  const changePlaybackRate = () => {
    const newRate = playbackRate === 1 ? 1.5 : playbackRate === 1.5 ? 2 : 1;
    setPlaybackRate(newRate);
    if (audioRef.current) {
      audioRef.current.audio.current.playbackRate = newRate;
    }
  };

  const handleVisualizerMouseDown = (event) => {
    setIsDragging(true);
    updateCurrentTime(event.clientX);
  };

  const handleVisualizerMouseMove = (event) => {
    if (isDragging) {
      updateCurrentTime(event.clientX);
    }
  };

  const handleVisualizerMouseUp = () => {
    setIsDragging(false);
  };

  const updateCurrentTime = (clientX) => {
    const rect = visualizerRef.current.getBoundingClientRect();
    const clickX = clientX - rect.left;
    const newTime = (clickX / rect.width) * duration;
    audioRef.current.audio.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  return (
    <>
      <style>
        {`
      .rhap_progress-section {
    gap: 0.5rem;
}

.rhap_controls-section {
    flex: none!important;
}

.rhap_progress-indicator {
    width: 10px!important;
    height: 15px!important;
    top: -5px!important;
    background-color: ${color}!important;
}

.rhap_container {
    box-shadow: none;
}

.rhap_progress-filled {
    background-color: #ccc!important;
}

.rhap_volume-container {
    width: 50px;
    margin-right: 10px;
}

.rhap_time {
    color: ${color};
      }
    `}
      </style>
      <AudioPlayer
        style={{ padding: '0.5rem 1rem', zIndex: '11', background: background }}
        customAdditionalControls={[]}
        ref={audioRef}
        customProgressBarSection={[
          <NewTooltip title={'Está com dificuldade em ler a página? Clique aqui para ouvir'} open={view} placement={'left'}>
          </NewTooltip>,
          audioBlob ? (
            <div
              ref={visualizerRef}
              onMouseDown={handleVisualizerMouseDown}
              onMouseMove={handleVisualizerMouseMove}
              onMouseUp={handleVisualizerMouseUp}
              onMouseLeave={handleVisualizerMouseUp}
              style={{ cursor: 'pointer', width: '100%' }}>
              <AudioVisualizer
                blob={audioBlob}
                style={{ width: '100%', objectFit: 'contain' }}
                width={windowWidth - (windowWidth / 100 * 30)}
                height={30}
                barWidth={2}
                currentTime={currentTime}
                gap={1}
                barColor={color}
              />
            </div>
          ) : RHAP_UI.PROGRESS_BAR,
          RHAP_UI.CURRENT_LEFT_TIME,
          <ThemeProvider theme={theme}>
            <Button 
              onClick={changePlaybackRate} 
              variant='contained' 
              disableElevation 
              sx={{ padding: '0.1rem 0.5rem', minWidth: '0', textTransform: 'lowercase' }}>
                {playbackRate}x
            </Button>
          </ThemeProvider>
        ]}
        customVolumeControls={[]}
        autoPlay={false}
        layout="horizontal-reverse"
        showJumpControls={false}
        preload='auto'
        customIcons={{
          play: <PlayCircleFilledRoundedIcon sx={{ fontSize: '40px', fill: color }} />,
          pause: <PauseCircleFilledRoundedIcon sx={{ fontSize: '40px', fill: color }} />
        }}
        src={url ?? ''}
        onPlay={e => setPlay(true)}
      /></>
  );
};

export default CustomAudioPlayerFixed;

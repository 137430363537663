import Regis from "../images/testimony/Regis.png";
import Denise from "../images/testimony/Denise.png";
import Nice from "../images/testimony/Nice.png";
import Alexandre from "../images/testimony/Alexandre.png";
import Gilberto from "../images/testimony/Gilberto.png";
import Nestor from "../images/testimony/Nestor.png";
import Nina from "../images/testimony/Nina.png";
import Ricardo from "../images/testimony/Ricardo.png";
import Gislayne from "../images/testimony/Gislayne.png";
import Alessandro from "../images/testimony/Alessandro.png";
import Simone from "../images/testimony/Simone.png";
import Rogerio from "../images/testimony/Rogerio.png";
import Lucimara from "../images/testimony/Lucimara.png";
import Hosana from "../images/testimony/Hosana.png";
import Fabio from "../images/testimony/Fabio.png";
import Lucas from "../images/testimony/Lucas.png";
import Cyntia from "../images/testimony/Cyntia.png";
import Adriano from "../images/testimony/Adriano.png";
import Felipe from "../images/testimony/Felipe.png";
import Talita from "../images/testimony/Talita.png";
import Robson from "../images/testimony/Robson.png";
import Chacara from "../images/testimony/Chacara.png";
import Reginaldo from "../images/testimony/Reginaldo.png";
import AdrianoAS from "../images/testimony/AdrianoAS.png";
import Douglas from "../images/testimony/Douglas.png";
import Eliana from "../images/testimony/Eliana.png";
import Murilo from "../images/testimony/Murilo.png";
import Carolina from "../images/testimony/Carolina.png";

export const Depoimentos = [
  {
    texto: "Super bem atentida, o pessoal sempre bem prestativo e um suporte muito bom",
    nome: "Carolina Vieira",
    foto: Carolina,
    cor: '#BF360C',
    data: '2024-08-16',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/dNjrZcfDQd9fFNo97'
  },
  {
    texto: "Corpbrasil energia renovável serviço top, pré venda com todas as explicações sobre o produto, seu funcionamento, visita na casa para ver como seria a instalação, material chegou tudo ok, funcionários que fizeram a instalação top, tudo como combinado, funcionária Ana da Pós venda, atenciosa, sempre tirou minhas dúvidas, Paulo que vendeu top, explicou tudo sobre o aparelho... Serviço de qualidade, recomendo 👏🙏",
    nome: "Murilo De Paula",
    foto: Murilo,
    cor: '#BF360C',
    data: '2024-08-13',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/7PuK4XeLGwJYk9oo6'
  },
  {
    texto: "Empresa de altíssima qualidades tantos nas prestações de mão de obra quanto no atendimento pós vendas super atencioso parabéns a todos.",
    nome: "Salsicha Reginaldo",
    foto: null,
    cor: '#BF360C',
    data: '2024-08-12',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/91KKwy6o7szqnTZN7'
  },
  {
    texto: "Atendimento , nota 10 , pessoal responsável , super atenciosos com os clientes , cumprem com o que falam , estão de parabéns",
    nome: "Douglas Tezoto",
    foto: Douglas,
    cor: '#5C6BC0',
    data: '2024-07-31',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/YeKjsPqmWSGwZ2Rc9'
  },
  {
    texto: "Atendimento impecável! Agradeço pelo profissionalismo e dedicação, que foram fundamentais para o sucesso do nosso projeto.",
    nome: "Eliana Leite lopes",
    foto: Eliana,
    cor: '#5C6BC0',
    data: '2024-08-06',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/BVPZ1hz4JPhMwsCQ9'
  },
  {
    texto: "Ótimo atendimento tanto como nas vendas e no prazo de está lação montadores profissionais uma equipe excelente estão todos de parabéns e o meu muito obrigado",
    nome: "Julio Vieira",
    foto: null,
    cor: '#33691E',
    data: '2024-08-03',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/57yVSQggC1p3iPZ38'
  },
  {
    texto: "Minha experiência tem sido boa, a empresa e o atendimento da Ana , estão de parabéns. Obrigado pela atenção e prestação dos serviços.",
    nome: "adriano afonso silva",
    foto: AdrianoAS,
    cor: '#5C6BC0',
    data: '2024-07-31',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/6sXVPJ8NftpjQ45V6'
  },
  {
    texto: "Experiência muito boa, fui muito bem atendido todas as vezes que precisei.",
    nome: "Roberto Savassa",
    foto: null,
    cor: '#5C6BC0',
    data: '2024-07-22',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/he3NrAXSLb9AfAgS9'
  },
  {
    texto: "Empresa muito boa. Produto de qualidade e ótimo atendimento",
    nome: "rogerio vieira",
    foto: null,
    cor: '#78909C',
    data: '2024-07-22',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/YJGBgbpX3RhGFNsw9'
  },
  {
    texto: "Excelente atendimento, pessoas capazes, rápidas e conhecedoras do assunto. O serviço correu mais rapidamente do que o esperado e os profissionais que nos atenderam por recomendação,  foram também prestos e competentes. Nossa unidade já começou a geração de E e esperamos os melhores resultados! Da mesma forma que nos bem recomendaram assim recomendamos!",
    nome: "Eliana Ferreira Persone",
    foto: null,
    cor: '#33691E',
    data: '2024-07-22',
    avaliacoes: '5',
    link: 'https://maps.app.goo.gl/qKREEHxa4DaNyCag6'
  },
  {
    texto: "Ótimo atendimento desde a Bruna vendas até o suporte pela Kauana....",
    nome: "Arnaldo tebalde",
    foto: null,
    cor: '#78909C',
    data: '2024-07-17',
    avaliacoes: '28',
    link: 'https://maps.app.goo.gl/d2Grh7MsupuhbCh97'
  },
  {
    texto: "Minha experiência foi muito satisfatória .desde o princípio ,  a visita da representante Mayara , a entrega do material e dua instalação e todas as conversas com a kauana. Fui tratado com muita cordialidade. Devo destacar a responsabilidade e profissionalismo de cada um.",
    nome: "Reginaldo gonçalges de almeida",
    foto: Reginaldo,
    cor: '#5C6BC0',
    data: '2024-07-15',
    avaliacoes: '11',
    link: 'https://maps.app.goo.gl/E8bKJP4ZDMuQh1h38'
  },
  {
    texto: "Muito  boa  atenciosos recomendo",
    nome: "Chacara Dalva",
    foto: Chacara,
    cor: '#5C6BC0',
    data: '2024-07-08',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/PQrKABbXzpbAvBZf8'
  },
  {
    texto: "Ótima venda, instalação e principalmente o pós venda onde a Kauana sempre passa todas as informações pertinentes aos próximos passos. Durante a instalação tivemos problemas com datas entre os instaladores e a minha disponibilidade, mas a Kauana ajudou e resolveu.",
    nome: "Danilo Pereira Leal",
    foto: null,
    cor: '#5C6BC0',
    data: '2024-06-25',
    avaliacoes: '6',
    link: 'https://maps.app.goo.gl/GenwWjQ727quXVmr7'
  },
  {
    texto: "Atendimento espetacular!!! Ana, Vc se mostrou muito atenciosa, profissional e conseguiu humanizar um atendimento que seria só técnico! Ganhou nossa confiança, pela forma segura e preocupada com que nos tratou! Um Show!!! 👏👏👏",
    nome: "AFONSO CANCIAN",
    foto: null,
    cor: '#01579b',
    data: '2024-06-20',
    avaliacoes: '4',
    link: 'https://maps.app.goo.gl/4SsJowFPHFAiHFac8'
  },
  {
    texto: "Foi uma excelente experiência.. gostei muito..e recomendo na hora que precisei fui prontamente atendida.. parabéns a todos envolvidos..",
    nome: "Hosana Patricia do Nascimento",
    foto: Hosana,
    data: '2024-06-10',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/uPUmCy3MJqEG1Akt5'
  },
  {
    texto: "Muito bem atendida. Super indico",
    nome: "Lucimara Mendes",
    foto: Lucimara,
    data: '2024-05-25',
    avaliacoes: '3',
    link: 'https://maps.app.goo.gl/gxsajdrgeBmG8FTd9'
  },
  {
    texto: "Presta muito bom atendimento bastante prestativa!",
    nome: "Gilvan Alves",
    foto: null,
    cor: '#33691e',
    data: '2024-05-25',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/PozvMZdYWaW4jATK9'
  },
  {
    texto: "Estou muito satisfeito com a empresa corporativo energia renovável. Agradeço a colaboradora kauana pelo seu ótimo atendimento sempre atensiosa nota 10!!",
    nome: "Jose Alves",
    foto: null,
    cor: '#ab47bc',
    data: '2024-05-25',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/9DKJcdF3JWyhxs4B9'
  },
  {
    texto: "Foi uma experiência maravilhosa. Comprei com a vendedora Bruna, que foi excelente, atenciosa e tirou todas as minhas dúvidas. No pós-venda, fui atendido pela Kauana, que foi muito paciente e profissional. A empresa tem uma equipe excelente, desde o primeiro contato até a instalação dos módulos, realizada por técnicos competentes como o eletricista Neto. Agradeço a todos pelo profissionalismo e respeito ao consumidor. Nota 1.000 para a empresa e sua equipe maravilhosa.",
    nome: "Andrea Inácio",
    foto: null,
    cor: '#512da8',
    data: '2024-05-10',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/ePDEdF239LQB7u5P7'
  },
  {
    texto: "Foi ótimo um atendimento exemplar parabéns meninas Deus abençoe.",
    nome: "Roseli Vilalva Fazan Pirani",
    foto: null,
    cor: '#78909c',
    data: '2024-04-25',
    avaliacoes: '4',
    link: 'https://maps.app.goo.gl/E2C53ZovuR3RL1NJA'
  },
  {
    texto: "Atendimento mais do que ótimo, maravilhoso, nota 10.",
    nome: "Dimas Nicolau",
    foto: null,
    cor: '#7b1fa2',
    data: '2024-04-22',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/6NQNEaRG2uu3pkKw7'
  },
  {
    texto: "Fiquei muito satisfeito com a aquisição do meu kit de energia solar!! Tive o prazer de conhecer a sede e fui muito bem recebido pelo Paulo e o Thales.. A empresa é organizada e respeita todas as etapas do processo..A vendedora Bruna é muito experiente e prestativa!! O pós venda TB é muito bom sempre estáva disponível a tirar dúvidas e esclarecer o passo a passo do processo.. Obrigado..",
    nome: "Rogerio Mineiro",
    foto: Rogerio,
    data: '2024-04-22',
    avaliacoes: '19',
    link: 'https://maps.app.goo.gl/2FUr75aCTms71cjn6'
  },
  {
    texto: "Quero parabenizar a empresa CORPBRASIL pelo atendimento que foi maravilhoso, desde o primeiro contato com as vendedoras, entre elas a Mayara, os técnicos sempre prestativos e por último a atendente do Pós venda a Ana super atenciosa em todos detalhes. Super indico vocês, quando aparecer algum amigo interessado já vou dar seu contato.",
    nome: "Simone Rodrigues",
    foto: Simone,
    data: '2024-04-22',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/ER9YMSgbcnAQ1ybM9'
  },
  {
    texto: "Empresa muito séria e prestativa super recomendo.pos venda incrível e atenciosa , vendedora muito transparente e honesta.",
    nome: "Edson Rodrigues",
    foto: null,
    cor: '#c2185b',
    data: '2024-04-15',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/FVrJwJyYhwH15mz77'
  },
  {
    texto: "Tudo perfeito! Desde o atendimento inicial na visita presencial, passando pelas orientações pelo WhatsApp (obrigada, Bruna e Ana), até a instalação (os rapazes foram super atenciosos, dedicados e cuidadosos no acabamento da instalação dos equipamentos). Estou muito satisfeita com todo o trabalho da CORPBRASIL! Parabéns pelo excelente trabalho na minha residência!",
    nome: "Nice Rossini",
    foto: Nice,
    data: '2024-04-01',
    avaliacoes: '8',
    link: 'https://maps.app.goo.gl/PuvpvFW8gvdGGKDU6'
  },
  {
    texto: "Muito bom recomendo prazo certo atendimento nota 10",
    nome: "Fabio Santos",
    foto: Fabio,
    data: '2024-02-15',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/u9Aq2ckxA6h8v6To9'
  },
  {
    texto: "Empresa seria, atendimento perfeito, com agilidade em tudo! Estamos mto satisfeitos!",
    nome: "Cacau Brasil Cerquilho",
    foto: null,
    cor: '#512da8',
    data: '2024-02-07',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/KqGi8C1v8nuEuvdh7'
  },
  {
    texto: "Ótima empresa , atendimento espetacular , serviço muito bem feito , totalmente satisfeito",
    nome: "Emerson spn",
    foto: null,
    data: '2023-12-15',
    cor: '#00897B',
    avaliacoes: '5',
    link: 'https://maps.app.goo.gl/khNyQrr13qMzZNqw6'
  },
  {
    texto: "Excelentes profissionais, vieram na minha casa explicar e tirar todas as minhas dúvidas sobre energia solar. Fizeram todos os processos, não precisei me preocupar com absolutamente nada. E agora estou aproveitando já a economia que me foi prometida!",
    nome: "Lucas vinicius",
    foto: Lucas,
    data: '2023-11-15',
    avaliacoes: '10',
    link: 'https://maps.app.goo.gl/b62SPJGv2pmvS1JX8'
  },
  {
    texto: "Atendimento pré e pós venda excelente, a instalação foi rápida e conseguimos obter energia solar mais rápido do q o esperado. Recomendo!!!",
    nome: "Cyntia Tagliatelli",
    foto: Cyntia,
    data: '2023-10-22',
    avaliacoes: '3',
    link: 'https://maps.app.goo.gl/H2uCFeoecd8wrLvN6'
  },
  {
    texto: "Atendimento ótimo desde a venda até o pós-venda... Foram atenciosos e rápidos na entrega dos equipamentos e montagens. Acompanharam junto à CPFL todo o processo para aprovação da geração de energia própria... Agradecido.",
    nome: "Gilberto de Freitas Vivaldini",
    foto: Gilberto,
    data: '2023-09-22',
    avaliacoes: '3',
    link : 'https://maps.app.goo.gl/ifz4wxf2bwhMo1399'
  },
  {
    texto: "Excelente atendimento, sempre disponíveis e voltados para resolver todo e qualquer problema/solicitação do cliente. Recomendo!",
    nome: "Adriano Viana",
    foto: Adriano,
    data: '2023-08-10',
    avaliacoes: '1',
    link : 'https://maps.app.goo.gl/67bKPz2bz61WAAnQ9'
  },
  {
    texto: "Tive uma experiência incrível ao ser atendido pela Ana e Lia da CORPBRASIL Energia Renovável. O atendimento delas foi simplesmente excepcional! Desde o início, mostraram um profissionalismo e conhecimento impressionantes, sempre prontas para me ajudar e responder a todas as minhas perguntas. Fiquei realmente satisfeito com a atenção e o cuidado que elas dedicaram a cada detalhe. Recomendo o serviço delas sem hesitação!",
    nome: "Regis Godinho",
    foto: Regis,
    data: '2023-07-15',
    avaliacoes: '4',
    link: 'https://maps.app.goo.gl/DgDERkXo3m3LwyaSA'
  },
  {
    texto: "Equipe sensacional, praticos e eficientes. O melhor investimento que eu fiz na minha casa merecia ter a melhor empresa no ramo cuidando do projeto!",
    nome: "Felipe Lara",
    foto: Felipe,
    data: '2023-07-22',
    avaliacoes: '6',
    link: 'https://maps.app.goo.gl/u7ESCnky5e4xCKwd7'
  },
  {
    texto: "Ótimo atendimento...excelentes funcionários...Ana do pós venda um amor de pessoa .... gratidão equipe Corpbrasil...",
    nome: "Talita Stopassoli",
    foto: Talita,
    data: '2023-07-22',
    avaliacoes: '6',
    link: 'https://maps.app.goo.gl/qXEPzemBBi9EkyuK6'
  },
  {
    texto: "Compriu com o que prometeu, entrega rápida, um bom atendimento e presta um bom suporte.",
    nome: "Alessandro Vaz",
    foto: Alessandro,
    data: '2023-07-21',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/5tf4QqM6tmkFL7Sw6'
  },
  {
    texto: "Atendimento excelente, funcionários atenciosos e ótimo trabalho",
    nome: "Nina Oliveira",
    foto: Nina,
    data: '2023-07-15',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/VVz7kQjpkJiBrzXc8'
  },
  {
    texto: "Foi ótima, atendimento nota 1000 e preço excelente. Recomendo!",
    nome: "Nestor Filho",
    foto: Nestor,
    data: '2023-06-01',
    avaliacoes: '42',
    link: 'https://maps.app.goo.gl/yfDrrcJDESxZuCX97'
  },
  {
    texto: "Atendimento rápido e preciso. Satisfeito com a contratação.",
    nome: "Ricardo Goulart",
    foto: Ricardo,
    data: '2023-05-29',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/d8uQdJEGW3A7U7n48'
  },
  {
    texto: "Muito bom, equipe excelente! Nota 1000! Comprometimento, entrega rápida e empresa confiável. Especialista Ana é uma pessoal amável, sempre disposta para atender e tirar toda as dúvidas. Equipe super boa.",
    nome: "Denise Coutinho",
    foto: Denise,
    data: '2023-02-17',
    avaliacoes: '3',
    link: 'https://maps.app.goo.gl/WzpsupHEMquyaepQ7'
  },
  {
    texto: "Recomendo a empresa com certeza. Tudo foi feito com excelência. Desde o atendimento com informações precisas e bem apresentadas, até a entrega final com toda a qualidade e tranquilidade que era esperada. Todos os prazos foram cumpridos até com antecedência. O sistema está em pleno funcionamento. Todos os funcionários foram muito atenciosos e educados. Em resumo, nota 10 !",
    nome: "Alexandre Grando",
    foto: Alexandre,
    data: '2023-02-03',
    avaliacoes: '69',
    link: 'https://maps.app.goo.gl/bQX3SfpcdfqSCD5R9'
  },
  {
    texto: "Muito boa, os funcionários são atenciosos e rápidos para instalar as placas. Ótima experiência, recomendo.",
    nome: "Gislayne Assumpção",
    foto: Gislayne,
    data: '2023-02-03',
    avaliacoes: '2',
    link: 'https://maps.app.goo.gl/uj9GSqH7A7umVys79'
  },
  {
    texto: "Atendimento rápido e preciso. Satisfeito com a contratação.",
    nome: "Robson Bazzo",
    foto: Robson,
    data: '2023-03-02',
    avaliacoes: '1',
    link: 'https://maps.app.goo.gl/ULzGtT4MxpLWSAD66'
  }
];


import { ThemeProvider } from '@emotion/react';
import React from 'react'
import { theme } from '../../../data/theme';
import { Button } from '@mui/material';

const NewButton = ({
    color,
    onClick,
    children,
    endIcon,
    sx = {},
    maxWidth = '350px',
    ...props
}) => {
    return (
        <ThemeProvider theme={theme}>
            <Button
                disableElevation
                fullWidth
                size="large"
                sx={{
                    textTransform: 'initial',
                    fontWeight: 'bold',
                    maxWidth: `${maxWidth}!important`,
                    borderRadius: '10px',
                    padding: '1rem',
                    ...sx,
                }}
                variant="contained"
                endIcon={endIcon}
                color={color}
                onClick={onClick}
                {...props}
            >
                {children}
            </Button>
        </ThemeProvider>
    );
};

export default NewButton
import { Alert, Autocomplete, Box, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

import React, { memo, useEffect, useState } from 'react'
import NewTooltip from '../../../components/Tooltip';

import ButtonCB from '../../../components/Button';
import axios from 'axios';
import sortData from '../../../functions/sortData';
import Swal from 'sweetalert2';
import { toast } from '../../../components/Toast';

const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const Consumption = ({ view, close, equipments, setEquipments }) => {
    const [equipmentsList, setEquipmentsList] = useState([]);
    const [kwh, setKwh] = useState(0);
    const loadingEquipments = equipmentsList.length === 0;

    console.log(equipments)

    useEffect(() => {

        const fetchData = async () => {
            try {
                const equipmentSheet = await axios.get('https://script.google.com/macros/s/AKfycbxo0u4-xIXvJnhW-9woEr_ZvGMhyWNdxz7UdtRw8MCmd8nOReLL5KoxB7AiB2fUigc/exec');
                // console.log(equipmentSheet);

                const equipmentData = equipmentSheet.data.GoogleSheetData.filter(data => data[0] !== 'Aparelho' && data[1].length > 0);
                const equipmentOptions = equipmentData.reduce((uniqueList, currentData) => {

                    if (currentData[0].trim() !== '') {
                        uniqueList.push({
                            nome: currentData[0],
                            categoria: currentData[1],
                            potencia: currentData[2],
                            kwh: Number(currentData[3]),
                            dias: currentData[4]
                        });
                    }

                    return uniqueList;
                }, []);

                setEquipmentsList([
                    ...sortData(equipmentOptions, 'nome'),
                ]);


            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [view])

    useEffect(() => {
        if (Array.isArray(equipments) && equipments.length > 0) {
            setKwh(equipments.reduce((acc, item) => acc + (item?.kwh_total || 0), 0).toFixed(2));
        } else {
            setKwh("0.00");
        }
    }, [equipments]);

    // console.log(equipmentsList)

    const addNewEquipment = () => {
        const newEquipment = {
            nome: '',
            categoria: '',
            kwh: 0,
            kwh_total: 0,
            potencia: 0,
            horas: '',
            dias: '',
            quantidade: '',
            meses: ['Janeiro', 'Fevereiro', 'Março', 'Dezembro', 'Novembro', 'Outubro', 'Setembro', 'Abril']
        };

        setEquipments(currentEquipments => [...(currentEquipments ?? []), newEquipment]);
    };

    const handleEquipmentChange = (array, index, field, value) => {
        const currentEquipments = array;
        const newEquipments = [...currentEquipments];
        
        // Atualiza o campo sem converter para número, exceto quando for numérico
        const newEquipment = { 
            ...newEquipments[index], 
            [field]: field === 'nome' ? value : (value ? Number(value) : '') 
        };
        
        let kwhTotal = 0;
        
        // Cálculo do kwh_total com base no tipo de categoria
        if (newEquipment.categoria === 'Locomoção') {
            kwhTotal = (newEquipment.horas * newEquipment.quantidade) * newEquipment.kwh;
        } else {
            kwhTotal = (newEquipment.horas * newEquipment.quantidade) * newEquipment.kwh * newEquipment.dias;
        }
        
        newEquipment.kwh_total = kwhTotal;
        newEquipments[index] = newEquipment;
        
        return newEquipments;
    };

    const handleEquipmentChangeList = (array, index, obj) => {
        const currentEquipments = array;
        const newEquipments = [...currentEquipments];
        const newEquipment = { ...newEquipments[index], ...obj };
        newEquipments[index] = newEquipment;
        return newEquipments;
    };

    const handleEquipmentSelection = (array, index, newValue) => {
        // Atualiza o equipamento selecionado
        let updatedEquipments = handleEquipmentChangeList(array, index, newValue);
        // Recalcula o valor baseado no equipamento selecionado
        updatedEquipments = handleEquipmentChange(updatedEquipments, index, 'nome', newValue.nome);
        return updatedEquipments;
    };

    const removeEquipment = async (index) => {
        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja excluir o <b>Equipamento?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })
            if (result.isConfirmed) {
                const newEquipments = equipments.filter((_, i) => i !== index);
                setEquipments(newEquipments);
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o pagamento.', error: error });
        }
    };

    // console.log(equipments);

    const monthWinter = (item) => {
        const months = ['Maio', 'Junho', 'Julho', 'Agosto'];
        return months.includes(item);
    }

    const handleChangeMonth = (value, checked, index) => {
        // Faz uma cópia do array original de equipamentos
        const updatedEquipments = [...equipments];

        // Atualiza o array 'meses' do equipamento específico
        const updatedMonths = checked
            ? [...updatedEquipments[index].meses, value] // Adiciona o valor se checked for true
            : updatedEquipments[index].meses.filter(month => month !== value); // Remove o valor se checked for false

        // Cria um novo objeto de equipamento com o array 'meses' atualizado
        updatedEquipments[index] = {
            ...updatedEquipments[index],
            meses: updatedMonths
        };

        // Atualiza o estado com o array de equipamentos modificado
        setEquipments(updatedEquipments);

        // console.log(value, checked, index);
    };

    return (
        <Dialog open={view} maxWidth='md' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DialogTitle align='center'>Consumo Adicionado</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                {equipments.length > 0 ?
                    equipments.map((data, index) => (
                        <Grid key={index} container spacing={1} sx={{
                            backgroundColor: "#f3f3f3",
                            borderLeft: '4px solid #ccc',
                            borderRadius: '5px',
                            padding: '0.5rem 0'
                        }}>
                            <Grid item sm={6.5} sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                <Autocomplete
                                    value={data}
                                    fullWidth
                                    loading={loadingEquipments}
                                    loadingText="Carregando..."
                                    sx={{ alignItems: 'center' }}
                                    onChange={(event, newValue) => {
                                        if (newValue != null) {
                                            setEquipments(handleEquipmentSelection(equipments, index, newValue));
                                        } else {
                                            // Caso seja necessário lidar com a limpeza do campo
                                            setEquipments({});
                                        }
                                    }}
                                    id="free-solo-with-text-demo"
                                    options={equipmentsList}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Opção Regular
                                        return option.nome;
                                    }}
                                    renderOption={(props, option, {index}) => {
                                        return <li {...props} key={index}>{option.nome}</li>;
                                    }}
                                    // noOptionsText="Cliente não encontrado"
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label={`Equipamento - ${index + 1}`}
                                            sx={{ backgroundColor: "#fff" }}
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                                />
                            </Grid>
                            <Grid item sm={1.5} sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                <TextField
                                    label="Quantidade"
                                    variant='outlined'
                                    sx={{ backgroundColor: "#fff" }}
                                    type='number'
                                    value={data.quantidade}
                                    onChange={(e) => setEquipments(handleEquipmentChange(equipments, index, 'quantidade', e.target.value))}
                                />
                            </Grid>
                            <Grid item sm={4} sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                <TextField
                                    label={data.categoria === 'Locomoção' ? "Recargas" : "Horas"}
                                    variant='outlined'
                                    sx={{ backgroundColor: "#fff" }}
                                    type='number'
                                    value={data.horas}
                                    onChange={(e) => setEquipments(handleEquipmentChange(equipments, index, 'horas', e.target.value))}
                                />
                                <TextField
                                    label="Dias"
                                    variant='outlined'
                                    sx={{ backgroundColor: "#fff" }}
                                    type='number'
                                    value={data.dias}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEquipments(handleEquipmentChange(equipments, index, 'dias', e.target.value))}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', flexDirection: 'column', padding: '0.5rem 1rem', color: "#663c00", backgroundColor: "#fff4e5", borderRadius: '5px' }}>
                                    <b>kWh</b>
                                    <p>{data.kwh_total.toFixed(2)}</p>
                                </Box>
                                <NewTooltip title='Excluir Pagamento' placement='top'>
                                    <IconButton onClick={() => removeEquipment(index)} color='error'>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </NewTooltip>
                            </Grid>
                            <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', flexWrap: 'wrap' }}>
                                <Box
                                    sx={{
                                        padding: '0.3rem 1rem',
                                        position: 'relative'
                                    }}>
                                    <Box sx={{ width: 'calc(100% - 70px)' }}>
                                        {meses.map((month, idx) => (
                                            <FormControlLabel
                                                key={idx}
                                                sx={{
                                                    width: '96px',
                                                    ".MuiFormControlLabel-label": {
                                                        fontSize: '0.8rem',
                                                        color: monthWinter(month) ? '#df1616' : '#000'
                                                    }
                                                }}
                                                label={month}
                                                control={<Checkbox
                                                    checked={data.meses.includes(month)}
                                                    name={month}
                                                    color={monthWinter(month) ? "error" : "primary"}
                                                    sx={{
                                                        ".MuiSvgIcon-root": {
                                                            fill: monthWinter(month) ? "#df1616" : "#000"

                                                        }
                                                    }}
                                                    onChange={(e) => handleChangeMonth(e.target.name, e.target.checked, index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                } />
                                        ))}
                                    </Box>
                                    <Chip icon={<CalendarMonthIcon />} sx={{ position: 'absolute', top: 10, right: 10 }} label="Meses" size='small' />
                                </Box>
                            </Grid>
                        </Grid>
                    ))
                    :
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum Equipamento encontrado</Typography>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                        <ButtonCB onClick={addNewEquipment} background="#313131" startIcon={<AddCircleRoundedIcon />}>
                            Adicionar Equipamento
                        </ButtonCB>
                    </Grid>
                    <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Alert icon={<ElectricBoltIcon />} color='warning' sx={{ ".MuiAlert-message": { textAlign: 'center' } }}>
                            <p>Consumo Adicionado -  Total</p>
                            <h1>{kwh}</h1>
                        </Alert>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
                <ButtonCB fullWidth sx={{ maxWidth: '300px' }} background='sucess' variant='contained' onClick={close}>Fechar</ButtonCB>
            </DialogActions>
        </Dialog>
    )
}

export default memo(Consumption);
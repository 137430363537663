import { useState, useEffect } from "react";
import Swal from "sweetalert2"; 

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { theme } from "../../../data/theme";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { ThemeProvider, DialogContentText, FormControlLabel, Checkbox, InputAdornment, Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import style from "../styles.module.scss";
import { KeyMaps } from "../../../data/Data";
import AutocompleteMaps from "../../../components/AutoCompleteMaps";
import NewTooltip from "../../../components/Tooltip";

const CreateSheet = ({isLoaded, customer, view, close, openSheet }) => {
    const [sheetData, setSheetData] = useState({
        kit: '',
        endereco: '',
        lat: '',
        lng: '',
        geracao: ''
    });
    const [newAddress, setNewAddress] = useState({
        local: ''
    });
    const [viewInput, setViewInput] = useState(false);
    const [city, setCity] = useState({
        cidade: '',
        cep: ''
    });

    useEffect(() => {
        if(view && customer && customer.endereco?.endereco) {
            setSheetData({
              ...sheetData,
              endereco: customer.endereco.endereco,
              lat: customer.endereco.lat,
              lng: customer.endereco.lng
            });
        } else if(view && !customer?.endereco?.endereco) {
          setViewInput(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[view, customer])

    useEffect(() => {
        if(newAddress && newAddress.endereco) {
            setSheetData({
                ...sheetData,
                endereco: newAddress.endereco.endereco,
                lat: newAddress.endereco.lat,
                lng: newAddress.endereco.lng
            });
            setCity({
                cidade: newAddress.cidade,
                cep: newAddress.cep ?? ''
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newAddress])

    console.log(sheetData)

    const closeBox = () => {
        setSheetData(({
            kit: '',
            endereco: '',
            lat: '',
            lng: '',
            geracao: ''
        }));
        setNewAddress({
            local: ''
        });
        setViewInput(false);
        close();
      }
      
    const onSubmit = async (e) => {
        e.preventDefault();

        const result = await Swal.fire({
            title: 'Atenção',
            text: `Todos os dados estão corretos?`,
            icon: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#05a937",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          });

        if(result.isConfirmed) {

            console.log(sheetData);
            console.log(viewInput);

            closeBox();
            openSheet({...sheetData, local: newAddress.local, ...city}, viewInput);
        }
        
    }

    const handleAddress = (address, checked) => {
        console.log(address);
        if(!checked) {
            setSheetData({
                ...sheetData,
                endereco: '',
                lat: '',
                lng: ''
            })
        } else {
            setViewInput(false);
            setSheetData({
                ...sheetData,
                endereco: address.endereco,
                lat: address.lat,
                lng: address.lng,
            })
        }
    }

    const handleNewAddress = (e) => {
        const { checked } = e.target;
        console.log(checked);

        if(checked) {
            setViewInput(true);
            setSheetData({
                ...sheetData,
                endereco: '',
                lat: '',
                lng: ''
            })
        } else {
            setViewInput(false);
        }
    }

    console.log(customer);

    const checkInputs = () => { // 30/08 - Alterado
        const { kit, geracao } = sheetData;
        const endereco = sheetData.endereco || newAddress.local;
        const hasValidInputs = kit.length > 0 && endereco?.length > 0 && geracao.length > 1;
        
        if (viewInput) {
            return !(hasValidInputs && newAddress.local.length > 2);
        }
    
        return !hasValidInputs;
    };

  return (
    <Dialog
    className={style.dialog}
    open={view}
    fullWidth
    maxWidth="sm"
  >
    <IconButton
        aria-label="close"
        onClick={closeBox}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
    <DialogTitle align="center">Planilha de Crédito</DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
        Informe a <b>potência</b> e o <b>local</b> aonde o kit será instalado para prosseguir com a criação da planilha.
      </DialogContentText>
      <form onSubmit={onSubmit} autoComplete="off">
      <div className={style.input_double}>
        <ThemeProvider theme={theme}>
          <TextField
            fullWidth
            margin="dense"
            label="Potência do Kit (Ex: 5.50)"
            type="number"
            name="nome"
            required
            InputProps={{
                endAdornment: <InputAdornment position="end">kWp</InputAdornment>,
              }}
            value={sheetData.kit ?? ''}
            onChange={(e) => setSheetData({...sheetData, kit: e.target.value})}
            variant="outlined" />
          <TextField
            fullWidth
            margin="dense"
            label="Geração do Kit (Ex: 550)"
            type="number"
            name="nome"
            required
            InputProps={{
                endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
              }}
            value={sheetData.geracao ?? ''}
            onChange={(e) => setSheetData({...sheetData, geracao: e.target.value})}
            variant="outlined" />
        </ThemeProvider>
      </div>
      <Box>
        <FormControl sx={{ m: '1rem 0' }} fullWidth component="fieldset" variant="standard">
        <FormLabel>Escolha o local aonde será instalado o kit</FormLabel>
        <FormGroup>
            {customer?.endereco?.endereco ?
                <FormControlLabel
                    control={
                    <Checkbox checked={customer.endereco?.endereco === sheetData.endereco} name="Endereço 1" onChange={(e) => handleAddress(customer.endereco, e.target.checked)} />
                    }
                    label={
                        <Box display="flex" gap={'0.5rem'} alignItems={'center'}>
                            <span>{customer.endereco?.endereco}</span>
                            <span className={style.badge}>Principal</span>
                        </Box>
                      }
                /> : <Alert severity="info">
                        <b>Atenção:</b> O cliente não possui nenhum endereço cadastrado.
                    </Alert>
            }
            {customer && customer.endereco_list ?
                customer.endereco_list.map((data, index) => (
                    <FormControlLabel key={index}
                    control={
                    <Checkbox checked={data.endereco === sheetData.endereco} name="Endereço 1" onChange={(e) => handleAddress(data, e.target.checked)} />
                    }
                    label={
                        <Box display="flex" gap={'0.5rem'} alignItems={'center'}>
                            <span>{data.endereco}</span>
                            <span className={`${style.badge} ${style.badge_black}`}>{data.local}</span>
                        </Box>
                      }
                />
                ))
                 : null
            }
          <FormControlLabel
            control={
              <Checkbox checked={viewInput === true} onChange={handleNewAddress} name="Novo Endereço" />
            }
            label="Adicionar Novo Local"
          />
          </FormGroup>
        </FormControl>
      {isLoaded && viewInput &&
        <><AutocompleteMaps KeyMaps={KeyMaps} setCustomer={setNewAddress} title='Endereço com Número' />
        <TextField 
            fullWidth 
            label="Nome do Local"
            margin="dense"
            value={newAddress.local ?? ''}
            required
            onChange={(e) => setNewAddress({...newAddress, local: e.target.value})} /></>
      }
      </Box>
      {viewInput && sheetData.endereco ?
        <Alert sx={{ m: '0.6rem 0' }} severity="success">
            <AlertTitle sx={{ fontWeight: 'bold' }}>Novo Local</AlertTitle>
            Endereço: {sheetData.endereco}
        </Alert> : null
      }
        <DialogActions sx={{ justifyContent: 'center' }}>
          <ThemeProvider theme={theme}>
            <NewTooltip title={checkInputs() ? 'Preencha os campos obrigatório para criar a planilha' : ''} placement='top'>
              <span>
                <Button
                  disableElevation
                  variant="contained"
                  color="success"
                  disabled={checkInputs()}
                  sx={{ textTransform: 'capitalize' }}
                  type="submit">Continuar</Button>
              </span>
            </NewTooltip>
            <Button
              disableElevation
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              onClick={closeBox}>Cancelar</Button>
          </ThemeProvider>
      </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
  )
}

export default CreateSheet
import Dialog from "@mui/material/Dialog";
import SlickSlider from "react-slick";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import { Document, Page } from 'react-pdf';
import IconButton from '@mui/material/IconButton';

import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';

import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

import { ThemeProvider, styled, useTheme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import styles from '../../../styles/Box/style.module.scss';
import { theme2 } from "../../../data/theme";
import Nota from '../../../images/comission/Nota.jpg';
import { useRef, useState } from "react";
import useUploadPhotos from "../../../hooks/useUploadPhotos";
import Swal from "sweetalert2";
import { dataBase } from "../../../firebase/database";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import axios from "axios";
import useCircular from "../../../hooks/useCircular";
import moment from "moment";

const Invoice = ({ userRef, comissionData, open, close }) => {
    const { uploadImages, changeProgress } = useUploadPhotos();
    const theme = useTheme();
    const slider = useRef(null);
    const { CircularProgressWithLabel  } = useCircular();
    const [pageNumber, setPageNumber] = useState(0);
    const [viewDrop, setViewDrop] = useState(false);
    const [step, setStep] = useState(0);
    const [checkPhotos, setCheckPhotos] = useState(false);
    const [invoice, setInvoice] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })
    const settingsPhotos = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
    };

const closeAll = () => {
    setInvoice([]);
    setStep(0);
    close();
}

const sendPhoto = async (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'comissão') {
      if(invoice) {
        setInvoice([...invoice, ...files])
      } else {
        setInvoice(files);
      }
    } 
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);

     switch (type) {
      case 'comissão':
        setInvoice(newPhotos);
        break;
        default:
     }
    }
  }

  const onSubmit = async(e) => {
    try {
        e.preventDefault();
        if(invoice.length === 0) {
            return Swal.fire({
                title: 'Atenção',
                html: `Envie a <b>Foto ou pdf da Nota Fiscal</b> para concluir.`,
                icon: "warning",
                showCloseButton: true,
                confirmButtonColor: "#111",
                confirmButtonText: "Fechar",
              })
        } else {
            Swal.fire({
                title: 'Nota Fiscal',
                html: `Todos os dados estão <b>corretos</b>?`,
                icon: "question",
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                confirmButtonText: "Sim",
                denyButtonText: `Não`,
              }).then(async (result) => {
                if(result.isConfirmed) {
                    setStep(1);

                    const resultInvoice = await uploadImages(invoice, 'Doc_Nota', 0, comissionData.id, 'Comissao');
                    const photos = resultInvoice.map(obj => obj.downloadURL);
                    const photosRef = resultInvoice.map(obj => obj.fullPath);

                    setCheckPhotos(true);
                    closeAll();
                    setViewDrop(true);

                    await updateDoc(doc(dataBase, 'Comissoes', comissionData.id), {
                        recibo: {
                            url: photos,
                            path: photosRef,
                            data: `${moment().format('DD/MM/YYYY')} ás ${moment().format('HH:mm')}`,
                            status: 'Aguardando'
                        },
                        historico: Array.isArray(comissionData.historico) ? [
                            ...comissionData.historico,
                            {
                                data: moment().format('DD/MM/YYYY HH:mm'),
                                text: `O Documento da Nota Fiscal foi enviado pelo Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b>.`
                            }
                        ] : [
                            {
                                data: moment().format('DD/MM/YYYY HH:mm'),
                                text: `O Documento da Nota Fiscal foi enviado pelo Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b>.`
                            }
                        ]
                    })
                    
                    await enviarSolicitacaoPost();
                    setViewDrop(false);
                    Swal.fire({
                        title: 'Nota Fiscal',
                        html: `O Envio da <b>Nota Fiscal</b> foi concluido com sucesso.`,
                        icon: "success",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonColor: "#0eb05f",
                    })
                }
            })
        }
    } catch (error) {
        console.log('erro');
    }
  }

  const enviarSolicitacaoPost = async () => {
    const representanteDoc = await getDoc(doc(dataBase, 'Membros', comissionData.representante?.id));
    const backofficeDoc = await getDoc(doc(dataBase, 'Membros', comissionData.backoffice_id));

    if(representanteDoc.exists() && backofficeDoc.exists()) {
        const representanteRef = representanteDoc.data();
        const backofficeRef = backofficeDoc.data();
        const valorFormatado = comissionData.valor_final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

        await axios.post('https://n8n.corpbrasil.cloud/webhook/4345892a-35a8-4aca-817f-4fa599ed0d3b', {
            nome: comissionData.nome,
            cidade: userRef.cidade,
            consultora: backofficeRef.nome,
            representante: representanteRef.nome,
            codigo: representanteRef.id_representante,
            kit: comissionData.kit,
            valor: valorFormatado,
            id: comissionData.id,
            telefone: '5515991573088' // backofficeRef.telefone
        })
  }
}

console.log(invoice)

const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(numPages);
  }

const handleDoc = () => {
    if (invoice.length > 0 && invoice[0].complete.type === "application/pdf") {
        return true
    } else {
        return false
    }
}  

  return (
    <div>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewDrop}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Dialog
            className={styles.dialog}
            open={open}
            fullScreen={fullScreen}
            maxWidth="lg"
            sx={{ zIndex: 90 }}
            // onClose={() => closeAll()}
        >
            <IconButton
                aria-label="close"
                onClick={() => closeAll()}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            ><CloseIcon /></IconButton>
          <DialogTitle align="center">Nota Fiscal</DialogTitle>
          <ThemeProvider theme={theme2}>
            <form onSubmit={onSubmit}>
                <DialogContent sx={{ padding: '0 24px 20px 24px' }}>
                    {step === 0 ? 
                        <div className={styles.register_container}>
                        <div className={styles.photo_item}>
                        {invoice && invoice.length === 0 ? 
                            <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                                Envie a foto ou pdf da <b>Nota Fiscal.</b><br />
                                Exemplo:
                                </DialogContentText>
                                <img className={styles.img_visit} src={Nota} alt='' /></> : 
                            <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                                Envie a foto ou pdf da <b>Nota Fiscal.</b>
                                </DialogContentText><div className={styles.slider_container}>
                                    <SlickSlider ref={slider} {...settingsPhotos} className={styles.slider}>
                                    {invoice && invoice.map((img, index) => (
                                        <div key={index} className={handleDoc() ? styles.pdf : styles.photos}>
                                        {handleDoc() ?
                                            <><Document file={img.file} onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                                                <Page pageNumber={1} width={380} />
                                            </Document>
                                            <p>{img.complete.name}</p>
                                            <b>{pageNumber} Páginas</b></> :
                                            <><img rel="preconnect" src={img.file} alt="" />
                                            <p>{img.complete.name}</p></>

                                        }
                                        <Button 
                                        disableElevation 
                                        startIcon={<DeleteForeverIcon />}
                                        variant='contained' 
                                        color='error' 
                                        sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                                        onClick={() => deletePhoto(index, invoice, 'comissão')}>Excluir</Button>
                                        </div>
                                    ))}
                                    </SlickSlider>
                            </div></>
                            }
                            <div className={styles.buttons_photo}>
                            <Button 
                                disableElevation
                                sx={{ textTransform: 'capitalize' }}
                                component="label" 
                                variant="contained" 
                                onChange={(e) => sendPhoto(e, 'comissão')} 
                                startIcon={<AddToPhotosIcon />}>
                                Enviar Documento
                                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                            </Button>
                        </div>
                        </div>
                        </div> : null
                    }
                    {step === 1 ? 
                        <div className={styles.view_send}>
                        <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                        <h3>Enviando dados, aguarde...</h3>
                        <ul>
                          <li>
                          {checkPhotos ? 
                            <CheckIcon color='success' /> :
                            <CircularProgressWithLabel value={changeProgress[0]} />
                          }
                           Nota Fiscal</li>
                        </ul>
                      </DialogContentText>
                        </div> : null
                    }
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
                    <Button 
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    variant='contained' 
                    onClick={() => closeAll()}>Fechar</Button>
                    <Button
                    disableElevation
                    sx={{ textTransform: 'capitalize' }} 
                    variant='contained' 
                    color='success'
                    type="submit"
                    disabled={step > 0 || invoice.length === 0} 
                    startIcon={<SendIcon />}>Enviar</Button>
                </DialogActions>
            </form>
          </ThemeProvider>
        </Dialog>
    </div>
  )
}

export default Invoice
import { useEffect, useState } from "react";
import { dataBase } from "../../firebase/database";
// import Header from "../../components/Header/Index";
import { onSnapshot, collection, addDoc, serverTimestamp, deleteDoc, doc } from "firebase/firestore";
import { theme } from "../../data/theme";
import { Company } from "../../data/Data";
import Swal from "sweetalert2";

// Css
import "cooltipz-css";
import styles from "./style.module.scss";


import CreateAdmin from "./Components/Create/Index";
import EditAdmin from "./Components/Edit/Index";
import { ReactComponent as Admin } from '../../images/icons/Admin.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';

import Table from '@mui/material/Table';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { DialogContentText } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import moment from "moment";
import Box from "@mui/material/Box";


const PanelAdmin = ({ user, alerts, userRef }) => {
  const [members, setMembers] = useState();
  const [membersRef, setMembersRef] = useState();
  const [fornecedores, setFornecedores] = useState();
  const [memberRef, setMemberRef] = useState();
  const [passView, setPassView] = useState(null);
  const [nome, setNome] = useState();
  const [open, setOpen] = useState({create: false, edit:false, supplier: false});
  const membersCollectionRef = collection(dataBase, "Membros");
  const supplierCollectionRef = collection(dataBase, "Fornecedores");
  const [TabsValue, setTabsValue] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
      const unsub = () => {
        onSnapshot(membersCollectionRef, (schedules) => {
          // Atualiza os dados em tempo real
          let documents = [];
          schedules.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setMembersRef(documents); // puxa a coleção 'Chats' para o state
        });

        onSnapshot(supplierCollectionRef, (schedules) => {
          // Atualiza os dados em tempo real
          let documents = [];
          schedules.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setFornecedores(documents); // puxa a coleção 'Chats' para o state
        });
      } 
      unsub();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

useEffect(() => {
  if(membersRef) {
    if(userRef && (userRef.cargo === 'Vendedor(a)' && userRef.time)) {
      const Representatives = membersRef.filter(member => member.cargo === 'Representante' && member.id_representante.includes(userRef.time));
      setMembers(Representatives);
    } else {
      setMembers(membersRef);
    }
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[membersRef])

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} alignItems={'center'}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const openBox = (data) => {
    if(data === 'edit') {
      setOpen({...open, edit: true});
    } else if(data === 'create') {
      setOpen({...open, create: true});
    } else {
      setOpen({...open, supplier: true});
    }
  }

  const close = () => {
    setOpen({create: false, edit: false, supplier: false});
    setNome('');
  }

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      const findSupplier = fornecedores.find((data) => data.nome === nome);
      if (findSupplier) {
        return Swal.fire({
          title: Company,
          html: `O fornecedor <b>${nome}</b> já está cadastrado no sistema.`,
          icon: "warning",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
      } else {
        Swal.fire({
          title: Company,
          text: `Você deseja cadastrar um novo Fornecedor?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if(result.isConfirmed) {
            await addDoc(collection(dataBase, 'Fornecedores'), {
              nome: nome,
              createAt: serverTimestamp(),
              data: moment(new Date()).format('DD MMM YYYY - HH:mm')
            }).then(() => {
              Swal.fire({
                title: Company,
                html: `O Fornecedor <b> ${nome}</b> foi cadastrado com sucesso.`,
                icon: "success",
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
              })
              close();
            })
          }
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const deleteSupplier = (data) => {
    try {
      Swal.fire({
        title: Company,
        html: `Você deseja deletar o fornecedor <b>${data.nome}</b> ?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if(result.isConfirmed) {
          await deleteDoc(doc(dataBase, 'Fornecedores', data.id)).then(() => {
            Swal.fire({
              title: Company,
              html: `O Fornecedor <b> ${nome}</b> foi excluido com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#F39200",
            })
          })
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={styles.container_panel}>
      {/* <Header user={user} alerts={alerts} userRef={userRef}></Header> */}
      <div className={styles.title_panel}>
        <Admin style={{ width: '42px', height: '42px', marginBottom: '0.5rem' }} />
        <h2>Área Administrativa</h2>
      </div>
      <div className={styles.content_panel}>
        <ThemeProvider theme={theme}>
          <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Colaboradores" centered>
              <Tab sx={{ textTransform: 'capitalize' }} label="Colaboradores" {...a11yProps(1)} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Fornecedores" {...a11yProps(2)} disabled={userRef && userRef.cargo !== 'Administrador' ? true : false} />
          </Tabs>
        </ThemeProvider>
        <CustomTabPanel value={TabsValue} index={0}>
        <div className={styles.box_panel}>
            <h1>Colaboradores</h1>
          <div className={styles.box_panel__add}>
            <button onClick={() => openBox('create')}>
              <AccountCircleIcon className={styles.icon_user} /> 
              <p>Cadastrar novo Colaborador</p>
            </button>
          </div>
          <div className={styles.box_panel__users}>
          <TableContainer className={styles.table_center} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Cor</TableCell>
                  <TableCell align="center" padding="none">Email</TableCell>
                  <TableCell align="center">Senha</TableCell>
                  <TableCell align="center">Cargo</TableCell>
                  <TableCell align="center">Veiculo</TableCell>
                  <TableCell align="center">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members && members.map((member) => (
                  <TableRow
                    hover
                    key={member.id}
                    className={`list-visit`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                     <TableCell align="center">{member.nome}</TableCell>
                      <TableCell style={member.cor && {
                            backgroundColor: member.cor
                          }}></TableCell>
                      <TableCell align="center">{member.email}</TableCell>
                      <TableCell onMouseOver={() => setPassView(member.id)} onMouseOut={() => setPassView(null)} align="center">
                        {passView === member.id ? member.senha : <b>********</b>}
                      </TableCell>
                      <TableCell align="center">{member.cargo}</TableCell>
                      <TableCell align="center">{member.veiculo}</TableCell>
                      <TableCell align="center">
                          <IconButton
                            id="basic-button"
                            onClick={() => {
                              setMemberRef(member);
                              return openBox('edit');
                            }}
                            aria-label="Editar Perfil"
                            data-cooltipz-dir="left"
                          >
                            <EditIcon sx={{ fill: '#000' }} />
                          </IconButton> 
                        </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
        </div>
        </CustomTabPanel>
        <CustomTabPanel value={TabsValue} index={1}>
        <div className={styles.box_panel}>
          <h1>Fornecedores</h1>
          <div className={styles.box_panel__add}>
            <button onClick={() => openBox('supplier')}>
                <AddShoppingCartIcon className={styles.icon_user} /> 
                <p>Cadastrar Fornecedor</p>
            </button>
          </div>
          <div className={styles.box_panel__users}>
          <TableContainer className={styles.table_center} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Criado em</TableCell>
                  <TableCell align="center">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fornecedores && fornecedores.length > 0 ? fornecedores.map((data) => (
                  <TableRow
                    hover
                    key={data.id}
                    className={`list-visit`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                     <TableCell align="center">{data.nome}</TableCell>
                     <TableCell align="center">{data.data.replace('-', 'às')}</TableCell>
                      <TableCell align="center"> 
                          <IconButton
                            id="basic-button"
                            color="error"
                            onClick={() => {
                              deleteSupplier(data);
                            }}
                            aria-label="Excluir Fornecedor"
                            data-cooltipz-dir="left"
                          >
                            <CloseIcon />
                          </IconButton> 
                        </TableCell>
                  </TableRow>
                )) : 
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: 'center', fontSize: '1rem' }}>
                    Nenhum Fornecedor Cadastrado
                  </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
          </div>
        </CustomTabPanel>
      </div>
        <CreateAdmin userRef={userRef} members={members} open={open.create} close={close} openBox={openBox}></CreateAdmin>
        <EditAdmin userRef={userRef} members={members} memberRef={memberRef} open={open.edit} close={close} openBox={openBox}></EditAdmin>
        <Dialog
          className={styles.dialog}
          open={open.supplier}
          fullWidth
          maxWidth="sm"
          onClose={() => close()}
        >
      <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Cadastrar Fornecedor</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          Informe o <b>Nome do Fornecedor</b> para cadastra-lo.
        </DialogContentText>
        <form onSubmit={onSubmit}>
        <ThemeProvider theme={theme}>
        <TextField
          margin="dense"
          id="name"
          label="Nome"
          type="text"
          value={nome ? nome : ''}
          onChange={(e) => setNome(e.target.value)}
          fullWidth
          required
          variant="outlined"
        />
          <DialogActions sx={{ justifyContent: 'center' }}>
          <Button type="submit">Confirmar</Button>
          <Button onClick={() => close()}>Cancelar</Button>
        </DialogActions>
        </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
    </div>
  );
};

export default PanelAdmin;

import { useState, useRef, useEffect } from 'react'
import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
import SlickSlider from "react-slick";
import CurrencyInput from "react-currency-input-field";
import { Document, Page, pdfjs } from 'react-pdf';

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
import {MenuItem, FormControl, InputLabel, Select } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import DataSheetInversor from '../../../../images/visit/datasheet_inversor.jpg';
import ManualInversor from '../../../../images/visit/manual_inversor.jpg';
import DataSheetModulo from '../../../../images/visit/datasheet_modulo.jpg';
import ManualModulo from '../../../../images/visit/manual_modulo.jpg';
import Kit from '../../../../images/visit/kit.jpg';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

const RegisterVisitStep45 = ({visit,  open, idStep4, close }) => {
  const theme = useTheme();
  const { uploadImages, changeProgress } = useUpload(visit.id);
  const { CircularProgressWithLabel  } = useCircular();
  const slider4 = useRef(null);
  const [step, setStep] = useState(0);
  const [checkData, setCheckData] = useState([false], [false],[false], [false], [false], [false], [false], [false]);
  const [kit, setKit] = useState([]);
  const [inversor, setInversor] = useState({ dataSheet: [], manual: [] });
  const [modulo, setModulo] = useState({ dataSheet: [], manual: [] });
  const [pageNumber, setPageNumber] = useState({});
  const [kitInfo, setKitInfo] = useState({inversor: '', placa_quantidade: '', placa_potencia: '', topologia: '', estrutura: ''});
  const [step4Ref, setStep4Ref] = useState({});
  const [observacaoKit, setObservacaoKit] = useState('');
  const [monitoramento, setMonitoramento] = useState('');
  const [openServico, setOpenServico] = useState('');
  const [servicoComplementar, setServicoComplementar] = useState('');
  const [horario, setHorario] = useState({entrada: '', saida: ''});
  const [comissionamento, setComissionamento] = useState('');
  const [custoAdicional, setCustoAdicional] = useState({descricao: '', valor: ''});
  const [openCusto, setOpenCusto] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; 

  const steps = [
    'Documentos',
    'Kit',
    'Instalação'
  ];

  const checkPhotos = ['PDF do Data Sheet do Inversor', 'PDF do Manual do Inversor', 'PDF do Data Sheet do Módulo', 'PDF do Manual do Módulo', 'Fotos do Telhado com Irradiação', 'Fotos do Kit'];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  useEffect(() => {
    const getStep = async () => {
      try {
        onSnapshot(doc(dataBase, "Visitas", visit.id, 'Tecnica', idStep4), (doc) => {
          setStep4Ref({...doc.data(), id: doc.id});
        });

      } catch (error) {
      }
    };
  
    getStep();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

console.log(step4Ref)

  const closeBox = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setCheckData([false], [false],[false], [false]);
      setKit([]);
      setObservacaoKit('');
      setMonitoramento('');
      setOpenServico('');
      setServicoComplementar('');
      setKitInfo({ inversor: '', placa_quantidade: '', placa_potencia: '', topologia: '', estrutura: '' });
      setHorario({ entrada : '', saida: '' });
      setInversor({ dataSheet: [], manual: [] });
      setComissionamento('');
    }, 500);
  }

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  const changeStep = (direction) => {
    if(direction === 'go') {
      if (step === 0 && (inversor.dataSheet.length === 0 || inversor.manual.length === 0 || modulo.dataSheet.length === 0 || modulo.manual.length === 0)) {
        let validationPhoto;
        if(inversor.dataSheet.length === 0) {
          validationPhoto = 'Envie o PDF do<b> Data Sheet do Inversor'
        } else if(inversor.manual.length === 0) {
          validationPhoto = 'Envie o PDF do<b> Manual do Inversor'
        } else if(modulo.dataSheet.length === 0) {
          validationPhoto = 'Envie o PDF do<b> Data Sheet do Módulo'
        } else if(modulo.manual.length === 0) {
          validationPhoto = 'Envie o PDF do<b> Manual do Módulo'
        }
        return Swal.fire({
          title: 'Fotos & Vídeos',
          html: `${validationPhoto}</b> para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Ok",
        })
      } else if (step === 1 && (kitInfo.topologia.length === 0 || kit.length === 0 || kitInfo.inversor.length === 0 || kitInfo.placa_quantidade.length === 0 
        || kitInfo.placa_potencia.length === 0 || kitInfo.estrutura.length === 0)) {
        let validationPhoto;
        if(kit.length === 0) {
          validationPhoto = 'Envie a foto do <b> kit'
        } else if(kitInfo.topologia.length === 0) {
          validationPhoto = 'Informe a <b>Topologia do Kit'
        } else if(kitInfo.inversor.length === 0) {
          validationPhoto = 'Informe a potência do <b>inversor'
        } else if(kitInfo.placa_quantidade.length === 0) {
          validationPhoto = 'Informe a quantidade de <b>placas'
        } else if(kitInfo.placa_potencia.length === 0) {
          validationPhoto = 'Informe a potência das <b>placas'
        } else if(kitInfo.estrutura.length === 0) {
          validationPhoto = 'Informe o <b>Tipo de Estrutura'
        }
        return Swal.fire({
          title: 'Fotos & Vídeos',
          html: `${validationPhoto}</b> para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Ok",
        })
      } else
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  }

  const sendPhoto = async (e, type) => { // Envia as fotos de acordo com o tipo
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
     if(type === 'dataSheetInversor') {
      if(inversor.dataSheet) {
        setInversor({...inversor, dataSheet: [...inversor.dataSheet, ...files] })
      } else {
        setInversor({...inversor, dataSheet: files});
      }
    } else if(type === 'manualInversor') {
      if(inversor.manual) {
        setInversor({...inversor, manual: [...inversor.manual, ...files] })
      } else {
        setInversor({...inversor, manual: files});
      }
    } else if(type === 'dataSheetModulo') {
      if(modulo.dataSheet) {
        setModulo({...modulo, dataSheet: [...modulo.dataSheet, ...files] })
      } else {
        setModulo({...modulo, dataSheet: files});
      }
    } else if(type === 'manualModulo') {
      if(modulo.manual) {
        setModulo({...modulo, manual: [...modulo.manual, ...files] })
      } else {
        setModulo({...modulo, manual: files});
      }
    } else if(type === 'kit') {
      if(kit) {
        setKit([...kit, ...files])
      } else {
        setKit(files);
      }
    }
  }

  console.log(inversor)

  const deletePhoto = (index, photos, type) => {
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);

     switch (type) {
      case 'dataSheetInversor':
        setInversor({...inversor, dataSheet: newPhotos});
        break;
      case 'manualInversor':
        setInversor({...inversor, manual: newPhotos});
        break;
      case 'dataSheetModulo':
        setModulo({...modulo, dataSheet: newPhotos});
        break;
      case 'manualModulo':
        setModulo({...modulo, manual: newPhotos});
        break;
      case 'kit':
        setKit(newPhotos);
        break;
        default:
     }
    }
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }
  
  const sendVisit = () => {
     if (openCusto.length === 0 || (openCusto === 'Sim ✅' && (custoAdicional.descricao.length === 0 || custoAdicional.valor.length === 0)) || monitoramento.length === 0 ||
      openServico.length === 0 || (openServico === 'Sim ✅' && servicoComplementar.length === 0) || comissionamento.length === 0 ||
      (visit && visit.condominio === 'Sim ✅' && (!horario.entrada || !horario.saida))) {
      let validationPhoto;
      if(!openCusto) {
        validationPhoto = 'Informe se a instalação terá <b> Custo Adicional'
      } else if(openCusto === 'Sim ✅' && custoAdicional.descricao.length === 0) {
        validationPhoto = 'Informe a descrição do <b>Custo Adicional'
      } else if(openCusto === 'Sim ✅' && !custoAdicional.valor) {
        validationPhoto = 'Informe o valor do <b>Custo Adicional'
      } else if(monitoramento.length === 0) {
        validationPhoto = 'Escolha uma opção de <b>Monitoramento'
      } else if(openServico.length === 0) {
        validationPhoto = 'Informe se será preciso um <b> Serviço Complementar'
      } else if(openServico === 'Sim ✅' && servicoComplementar.length === 0) {
        validationPhoto = 'Informe qual será o <b> Serviço Complementar'
      } else if(comissionamento.length === 0) {
        validationPhoto = 'Informe se o <b>Comissionamento será realizado via Wi-fi'
      } else if(visit && visit.condominio === 'Sim ✅' && (!horario.entrada || !horario.saida)) {
        validationPhoto = 'Informe o <b>hórario de Entrada e Saida'
      }
      return Swal.fire({
        title: 'Adicional',
        html: `${validationPhoto}</b> para prosseguir para a próxima etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#F39200",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Kit & Instalação',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          setStep(3)
          let visita = {};
          let ref = {...step4Ref.ref};

          console.log(ref)

          await updateDoc(doc(dataBase, 'Visitas', visit.id), {
            monitoramento: monitoramento,
            kit_info: kitInfo,
            custo_adicional: openCusto === 'Sim ✅' ? custoAdicional : 'Não ❌',
          })

          try {

            const resultInversorDataSheet = await uploadImages(inversor.dataSheet, 'inversorDataSheet', 3);
              visita.inversorDataSheet = resultInversorDataSheet.map(object => object.downloadURL);
              ref.inversorDataSheet = resultInversorDataSheet.map(object => object.fullPath);
              handleChangeCheck(0);

            const resultInversorManual = await uploadImages(inversor.manual, 'inversorManual', 4);
              visita.inversorManual = resultInversorManual.map(object => object.downloadURL);
              ref.inversorManual = resultInversorManual.map(object => object.fullPath);
              handleChangeCheck(1);

            const resultModuloDataSheet = await uploadImages(modulo.dataSheet, 'moduloDataSheet', 5);
              visita.moduloDataSheet = resultModuloDataSheet.map(object => object.downloadURL);
              ref.moduloDataSheet = resultModuloDataSheet.map(object => object.fullPath);
              handleChangeCheck(2);

            const resultModuloManual = await uploadImages(modulo.manual, 'moduloManual', 6);
              visita.moduloManual = resultModuloManual.map(object => object.downloadURL);
              ref.moduloManual = resultModuloManual.map(object => object.fullPath);
              handleChangeCheck(3);

            const resultKit = await uploadImages(kit, 'kit', 7);
              visita.kit = resultKit.map(object => object.downloadURL);
              ref.kit = resultKit.map(object => object.fullPath);
              handleChangeCheck(4);

              await updateDoc(doc(dataBase, 'Visitas', visit.id, 'Tecnica', idStep4), {
               ...visita,
               ref: ref,
               observacao_kit: observacaoKit,
               servico_complementar: openServico === 'Sim ✅' ? servicoComplementar : '',
               horario: {
                entrada: horario.entrada,
                saida: horario.saida
               },
               comissionamento: comissionamento
              }).then(() => {
                  Swal.fire({
                    title: 'Kit & Instalação',
                    html: `A etapa de <b>Kit & Instalação</b> foi concluido com sucesso.`,
                    icon: "success",
                    showCloseButton: true,
                    confirmButtonColor: "#F39200",
                    confirmButtonText: "Ok",
                    }).then(() => {
                      closeBox();
                    })
                   })   
           
          } catch(error) {
            console.error('Ocorreu um erro: ', error)
          }
          }
        })
      }
  }

  const onDocumentLoadSuccess = ({ numPages }, type) => {
    switch(type) {
      case 'inversorDataSheet':
        setPageNumber({...pageNumber, dataSheetInversor: numPages});
      break;
      case 'inversorManual':
        setPageNumber({...pageNumber, manualInversor: numPages});
      break;
      case 'moduloDataSheet':
        setPageNumber({...pageNumber, dataSheetModulo: numPages});
      break;
      case 'moduloManual':
        setPageNumber({...pageNumber, manualModulo: numPages});
      break;
      default:
    }
  }

  return (
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={() => closeBox()}
    >
      <IconButton
        aria-label="close"
        onClick={() => closeBox()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Kit & Instalação</DialogTitle>
      <DialogContent className={style.dialog_content}>
        <div className={style.stepper}>
          <Stepper sx={{ width: '100%' }} activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {step < 1 && 
          <div className={style.register_container}>
            <div className={style.photo_item}>
            {inversor.dataSheet && inversor.dataSheet.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1: </b>Envie o <b>Data Sheet do Inversor</b> em <b>PDF</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={DataSheetInversor} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1: </b>Envie o <b>Data Sheet do Inversor</b> em <b>PDF</b>.
                </DialogContentText><div className={style.slider_container}>
                  {inversor.dataSheet && inversor.dataSheet.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  }
                    <SlickSlider ref={slider4} {...settingsVisit} className={style.slider}>
                      {inversor.dataSheet && inversor.dataSheet.map((img, index) => (
                        <div key={index} className={style.pdf}>
                          {/* <img rel="preconnect" src={getThumURL({url: img.file})} alt="" /> */}
                          <Document file={img.file} onLoadSuccess={(e) => onDocumentLoadSuccess(e, 'inversorDataSheet')}>
                            <Page pageNumber={1} width={380} />
                          </Document>
                          <p>{img.complete.name}</p>
                          <b>{pageNumber.dataSheetInversor} Páginas</b>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, inversor.dataSheet, 'dataSheetInversor')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  {inversor.dataSheet && inversor.dataSheet.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                  }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'dataSheetInversor')} startIcon={<AddToPhotosIcon />}>
                Enviar PDF
                <VisuallyHiddenInput multiple={true} type="file" accept="application/pdf" />
              </Button>
            </ThemeProvider>
          </div>
            </div>
            <div className={style.photo_item}>
            {inversor.manual && inversor.manual.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2: </b>Envie o <b>Manual do Inversor</b> em <b>PDF</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={ManualInversor} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2: </b>Envie o <b>Manual do Inversor</b> em <b>PDF</b>.
                </DialogContentText><div className={style.slider_container}>
                  {inversor.manual && inversor.manual.length > 1 && 
                      <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  }
                    <SlickSlider ref={slider4} {...settingsVisit} className={style.slider}>
                      {inversor.manual && inversor.manual.map((img, index) => (
                        <div key={index} className={style.pdf}>
                          {/* <img rel="preconnect" src={getThumURL({url: img.file})} alt="" /> */}
                          <Document file={img.file} onLoadSuccess={(e) => onDocumentLoadSuccess(e, 'inversorManual')}>
                            <Page pageNumber={1} width={380} />
                          </Document>
                          <p>{img.complete.name}</p>
                          <b>{pageNumber.manualInversor} Páginas</b>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, inversor.manual, 'manualInversor')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  {inversor.manual && inversor.manual.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                  }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'manualInversor')} startIcon={<AddToPhotosIcon />}>
                Enviar PDF
                <VisuallyHiddenInput multiple={true} type="file" accept="application/pdf" />
              </Button>
            </ThemeProvider>
          </div>
            </div>
            <div className={style.photo_item}>
            {modulo.dataSheet && modulo.dataSheet.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3: </b>Envie o <b>Data Sheet do Módulo</b> em <b>PDF</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={DataSheetModulo} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3: </b>Envie o <b>Data Sheet do Módulo</b> em <b>PDF</b>.
                </DialogContentText><div className={style.slider_container}>
                  {modulo.dataSheet && modulo.dataSheet.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  }
                    <SlickSlider ref={slider4} {...settingsVisit} className={style.slider}>
                      {modulo.dataSheet && modulo.dataSheet.map((img, index) => (
                        <div key={index} className={style.pdf}>
                          {/* <img rel="preconnect" src={getThumURL({url: img.file})} alt="" /> */}
                          <Document file={img.file} onLoadSuccess={(e) => onDocumentLoadSuccess(e, 'moduloDataSheet')}>
                            <Page pageNumber={1} width={380} />
                          </Document>
                          <p>{img.complete.name}</p>
                          <b>{pageNumber.dataSheetModulo} Páginas</b>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, modulo.dataSheet, 'dataSheetModulo')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  {modulo.dataSheet && modulo.dataSheet.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                  }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'dataSheetModulo')} startIcon={<AddToPhotosIcon />}>
                Enviar PDF
                <VisuallyHiddenInput multiple={true} type="file" accept="application/pdf" />
              </Button>
            </ThemeProvider>
          </div>
            </div>
            <div className={style.photo_item}>
            {modulo.manual && modulo.manual.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 4: </b>Envie o <b>Manual do Módulo</b> em <b>PDF</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={ManualModulo} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 4: </b>Envie o <b>Manual do Módulo</b> em <b>PDF</b>.
                </DialogContentText><div className={style.slider_container}>
                  {modulo.manual && modulo.manual.length > 1 && 
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  }
                    <SlickSlider ref={slider4} {...settingsVisit} className={style.slider}>
                      {modulo.manual && modulo.manual.map((img, index) => (
                        <div key={index} className={style.pdf}>
                          {/* <img rel="preconnect" src={getThumURL({url: img.file})} alt="" /> */}
                          <Document file={img.file} onLoadSuccess={(e) => onDocumentLoadSuccess(e, 'moduloManual')}>
                            <Page pageNumber={1} width={380} />
                          </Document>
                          <p>{img.complete.name}</p>
                          <b>{pageNumber.manualModulo} Páginas</b>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, modulo.manual, 'manualModulo')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  {modulo.manual && modulo.manual.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                  }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'manualModulo')} startIcon={<AddToPhotosIcon />}>
                Enviar PDF
                <VisuallyHiddenInput multiple={true} type="file" accept="application/pdf" />
              </Button>
            </ThemeProvider>
          </div>
            </div>
          </div>
        }
        {step === 1 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
            {kit && kit.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 5: </b>Envie a foto <b>do Kit</b> <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Kit} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 5: </b>Envie a foto <b>do Kit.</b>
                </DialogContentText><div className={style.slider_container}>
                  {kit && kit.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  }
                    <SlickSlider ref={slider4} {...settingsVisit} className={style.slider}>
                      {kit && kit.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, kit, 'kit')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                    {kit && kit.length > 1 &&
                      <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider4?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                    }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'kit')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
        <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0 0' }}>
          Selecione a <b>topologia do inversor</b>
        </DialogContentText>
        <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
          <InputLabel id="altura">Topologia</InputLabel>
          <Select
          labelId='altura'
          label="Topologia"
          value={kitInfo.topologia}
          onChange={(e) => setKitInfo({...kitInfo, topologia: e.target.value})}
          required>
            <MenuItem value="Micro-Inversor">Micro-Inversor</MenuItem>
            <MenuItem value="Inversor">Inversor</MenuItem>
            <MenuItem value="Otimizador">Otimizador</MenuItem>
            <MenuItem value="Hibrido">Hibrido</MenuItem>
          </Select>
        </FormControl>
        <DialogContentText sx={{ textAlign: "center" }}>
        Informe a <b>potência do inversor.</b>
        </DialogContentText>
          <TextField
            fullWidth
            margin="dense"
            label="Potência"
            type="number"
            value={kitInfo.inversor}
            onChange={(e) => setKitInfo({...kitInfo, inversor: e.target.value})}
            variant="outlined" />
        <DialogContentText sx={{ textAlign: "center" }}>
        Informe a <b>quantidade de placa</b> e a <b>potência.</b>
        </DialogContentText>
        <div className={style.input_double}>
          <TextField
            fullWidth
            margin="dense"
            label="Quantidade"
            type="number"
            value={kitInfo.placa_quantidade}
            onChange={(e) => setKitInfo({...kitInfo, placa_quantidade: e.target.value})}
            variant="outlined" />
          <TextField
            fullWidth
            margin="dense"
            label="Potência"
            type="number"
            value={kitInfo.placa_potencia}
            onChange={(e) => setKitInfo({...kitInfo, placa_potencia: e.target.value})}
            variant="outlined" />
        </div>
        <div className={style.result}>
          <p>Potência total das Placas: <b>{kitInfo.placa_potencia * kitInfo.placa_quantidade}</b>w</p>
        </div>
        <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0 0' }}>
          Selecione o <b>tipo de estrutura</b> que será instalado o kit.
        </DialogContentText>
        <FormControl sx={{ margin: '0.5rem 0' }} fullWidth>
          <InputLabel id="altura">Tipo de Estrutura</InputLabel>
          <Select
          labelId='altura'
          label="Tipo de Estrutura"
          value={kitInfo.estrutura}
          onChange={(e) => setKitInfo({...kitInfo, estrutura: e.target.value})}
          required>
            <MenuItem value="Fibrocimento">Fibrocimento</MenuItem>
            <MenuItem value="Cerâmico">Cerâmico</MenuItem>
            <MenuItem value="Metálico">Metálico</MenuItem>
            <MenuItem value="Solo">Solo</MenuItem>
            <MenuItem value="Laje">Laje</MenuItem>
          </Select>
        </FormControl>
        <DialogContentText sx={{ textAlign: "center" }}>
        Caso preferir, deixe uma <b>observação</b> a respeito do <b>kit</b>.
        </DialogContentText>
        <div className={style.input}>
          <TextField
            fullWidth
            margin="dense"
            label="Observação"
            type="text"
            rows={3}
            multiline
            value={observacaoKit}
            onChange={(e) => setObservacaoKit(e.target.value)}
            variant="outlined" />
        </div>
      </div>}
      {step === 2 && 
      <div className={style.register_container}>
      {/* <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0.5rem 0' }}>
        Informe o 📃<b>número do contrato</b>.
        </DialogContentText>
        <div className={style.input_double}>
          <TextField
            fullWidth
            margin="dense"
            label="Numero do Contrato"
            type="number"
            value={contrato}
            onChange={(e) => setContrato(e.target.value)}
            variant="outlined" />
        </div> */}
      <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0 0' }}>
       A instalação terá 💵 <b>custo adicional</b>?
      </DialogContentText>
      <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
        <InputLabel id="altura">Resposta</InputLabel>
        <Select
        labelId='altura'
        label="Resposta"
        value={openCusto}
        onChange={(e) => setOpenCusto(e.target.value)}
        required>
          <MenuItem value="Sim ✅">Sim</MenuItem>
          <MenuItem value="Não ❌">Não</MenuItem>
        </Select>
      </FormControl>
      {openCusto && openCusto === 'Sim ✅' && 
      <><DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0.5rem 0', backgroundColor: '#f3f3f3', padding: '0.3rem', borderRadius: '5px' }}>
                🖊️ Informe a <b>descrição</b> e o <b>valor do custo adicional</b>
        </DialogContentText><div className={style.input_double}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Descrição"
                    type="text"
                    value={custoAdicional.descricao}
                    onChange={(e) => setCustoAdicional({...custoAdicional, descricao: e.target.value})}
                    variant="outlined" />
                  <CurrencyInput
                    fullWidth
                    customInput={TextField}
                    className="label__text"
                    label="Valor"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    value={custoAdicional.valor}
                    onValueChange={(value) => setCustoAdicional({...custoAdicional, valor: value})}
                    // fixedDecimalLength={0}
                    min={50}
                    color="primary" />
                </div></>
      }
      <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0 0' }}>
      📈 Será realizado <b>monitoramento</b> no sistema?
      </DialogContentText>
      <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
        <InputLabel id="altura">Resposta</InputLabel>
        <Select
        labelId='altura'
        label="Resposta"
        value={monitoramento}
        onChange={(e) => setMonitoramento(e.target.value)}
        required>
          <MenuItem value="Sim ✅">Sim</MenuItem>
          <MenuItem value="Não ❌">Não</MenuItem>
        </Select>
      </FormControl>
      <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0 0' }}>
      🛠️ Deseja adicionar algum <b>serviço complementar</b> para instalação?
      </DialogContentText>
      <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
        <InputLabel id="altura">Resposta</InputLabel>
        <Select
        labelId='altura'
        label="Resposta"
        value={openServico}
        onChange={(e) => setOpenServico(e.target.value)}
        required>
          <MenuItem value="Sim ✅">Sim</MenuItem>
          <MenuItem value="Não ❌">Não</MenuItem>
        </Select>
      </FormControl>
      {openServico && openServico === 'Sim ✅' && 
        <><DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0', backgroundColor: '#f3f3f3', padding: '0.3rem', borderRadius: '5px'  }}>
            🖊️ Informe abaixo o <b>serviço complementar</b>
          </DialogContentText>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={servicoComplementar}
            onChange={(e) => setServicoComplementar(e.target.value)} /></>
      }
      <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0 0' }}>
      📟 O comissionamento será realizado via <b>wi-fi</b>?
      </DialogContentText>
      <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
        <InputLabel id="altura">Resposta</InputLabel>
        <Select
        labelId='altura'
        label="Resposta"
        value={comissionamento}
        onChange={(e) => setComissionamento(e.target.value)}
        required>
          <MenuItem value="Sim ✅">Sim</MenuItem>
          <MenuItem value="Não ❌">Não</MenuItem>
        </Select>
      </FormControl>
      {visit && visit.condominio === 'Sim ✅' && 
      <><DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0 0.5rem 0' }}>
          ⏰ Digite o <b>hórario de entrada</b> e <b>saída</b> do condomínio
        </DialogContentText>
        <div className={style.input_double}>
            <TextField
              fullWidth
              type='time'
              label="Entrada"
              value={horario.entrada}
              onChange={(e) => setHorario({ ...horario, entrada: e.target.value })}
              required />
            <TextField
              fullWidth
              type='time'
              labelId='altura'
              label="Saida"
              value={horario.saida}
              onChange={(e) => setHorario({ ...horario, saida: e.target.value })}
              required />
          </div></>  
      }
      </div>
      }
        {step === 3 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li>
                {checkData[index] ?
                  <CheckIcon color='success' /> :
                  <CircularProgressWithLabel value={changeProgress[index]} />
                }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
        {step > 0 ?
          <Button 
            disableElevation
            component="label" 
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 2 ? true: false} 
            onClick={() => changeStep('back')}>Voltar</Button> :
          <Button 
            disableElevation
            component="label" 
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            onClick={() => close()}>Fechar</Button>
        }
        {step > 1 ? 
          <Button 
            disableElevation
            component="label" 
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 2 ? true: false} 
            color='success' startIcon={<SendIcon />} 
            onClick={() => sendVisit()}>Enviar</Button> :
          <Button 
            disableElevation
            component="label" 
            variant="contained" 
            sx={{ textTransform: 'capitalize' }} 
            onClick={() => changeStep('go')}>Próximo</Button>
        }
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    /* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div className={style.photo_card}>
                <img src={photo.file} alt='' /> :
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */
      
  )
}

export default RegisterVisitStep45;
import React from 'react';
import Box from '@mui/material/Box';

import Header from './Header/Index';
import { Outlet } from 'react-router-dom';
import Layout from './Layout';
import useAuth from '../hooks/useAuth';

const LayoutMain = () => {
    const { user } = useAuth();
    return (
        <Layout userRef={user}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center">
                <Header user={user} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        marginTop: '1rem',
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </Layout>
    );
}

export default LayoutMain;
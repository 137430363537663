import { styled } from "@mui/material";


const UploadInput = ({ inputRef, multiple = false, accept }) => {
    
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

  return (
    <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept={accept} />
  )
}

export default UploadInput;
export const handleDownload = ({ fileUrl, fileName }) => {
        // Cria um link temporário
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank'; // Abre em uma nova aba
        link.rel = 'noopener noreferrer'; // Segurança adicional
        
        link.setAttribute('download', fileName);
    
        // Simula um clique no link
        document.body.appendChild(link);
        link.click();
    
        // Remove o link do corpo do documento
        document.body.removeChild(link);
};
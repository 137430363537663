
import { useState, useEffect, memo } from "react";
import Swal from "sweetalert2"; 
import moment from "moment";
import CurrencyInput from "react-currency-input-field";

import { PatternFormat } from "react-number-format";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { theme } from '../../../../data/theme';
import { ThemeProvider } from "@mui/material";
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import style from "./style.module.scss";
import { doc, updateDoc } from "firebase/firestore";
import { dataBase } from "../../../../firebase/database";

const EditLead = ({ leads, view, close, userRef, lead }) => {
  const [consultora, setConsultora] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [date, setDate] = useState([]);
  const [kit, setKit] = useState("");
  const [valor, setValor] = useState("");
  const [situacao, setSituacao] = useState("");
  const [observacao, setObservacao] = useState("");
  const [cpf, setCPF] = useState("");

  console.log(lead);

  useEffect(() => {
    if(lead && view) {
      setNome(lead.nome);
      setDate(moment(lead.data_nascimento).format('yyyy-MM-DD'));
      setCPF(lead.cpf);
      setTelefone(lead.telefone);
      setConsultora(lead.consultora);
      setKit(lead.kit);
      setValor(lead.valor);
      setSituacao(lead.situacao);
      setObservacao(lead.observacao.mensagem);
    }
  },[lead, view])

  console.log(kit)

  const closeBox = () => {
    setConsultora("");
    setNome("");
    setTelefone("");
    setDate('');
    close();
    setCPF('');
  }

  const onSubmit = async (e) => {
    e.preventDefault();
      try {
        Swal.fire({
          title: 'Financiamento',
          text: `Você deseja alterar os dados do Cliente?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#F39200",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // const telefoneFormatado = typeof telefone === 'string' ? telefone.replace(/\D/g, '') : telefone;
            // const consultoraUID = members && members.find(data => data.nome === consultora && data.cargo === "Vendedor(a)"); 
            let obsRef;
            if(observacao !== lead.observacao.mensagem) {
              obsRef = {
                data: moment(new Date()).format('DD MMM YYYY - HH:mm'),
                mensagem: observacao
              }
            } else if(lead.observacao.mensagem) {
              obsRef = {
                data: lead.observacao.data,
                mensagem: lead.observacao.mensagem
                }
            } else {
              obsRef = ''
            }
            await updateDoc(doc(dataBase, 'Financiamento', lead.id), {
              nome: nome,
              telefone: telefone,
              data_nascimento: date,
              kit: kit,
              valor: valor,
              cpf: cpf,
              observacao: obsRef,
              situacao: situacao,
              dataRef: moment(new Date()).format('DD MMM YYYY - HH:mm')
            })
            closeBox();
            Swal.fire({
              title: 'Financiamento',
              html: `Os dados do Cliente <b>${nome}</b> foram alterados com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#F39200",
            })
          }
        });
      } catch (error) {
        // console.log(error);
      }
  };

  return (
    <Dialog
      className={style.dialog}
      open={view}
      fullWidth
      maxWidth="sm"
      onClose={closeBox}
    >
      <IconButton
          aria-label="close"
          onClick={closeBox}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Editar Cliente</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} autoComplete="off">
        <ThemeProvider theme={theme}>
        <div className={style.input_double}>
        <TextField
          margin="dense"
          label="Nome"
          type="text"
          value={nome ? nome : ''}
          onChange={(e) => setNome(e.target.value)}
          fullWidth
          variant="outlined"
          autoComplete="new-password"
          required
        />
        <TextField
          margin="dense"
          label="Data de Nascimento"
          type="date"
          value={date ? date : ''}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          variant="outlined"
          autoComplete="new-password"
          required
        />
        </div>
        <div className={style.input_double}>
          <PatternFormat
            fullWidth
            sx={{ marginTop: '8px' }}
            customInput={TextField}
            onChange={(e) => setCPF(e.target.value)}
            format="###.###.###-##"
            mask="_"
            placeholder="000.000.000-00"
            value={cpf ? cpf : ''}
            label="CPF"
            minLength={14}
            variant="outlined"
            color="primary"
            required
            autoComplete="new-password"          
            />
          <PatternFormat
            fullWidth
            sx={{ marginTop: '8px' }}
            customInput={TextField}
            onChange={(e) => setTelefone(e.target.value)}
            format="(##) #####-####"
            mask="_"
            placeholder="(00) 00000-0000"
            value={telefone ? telefone : ''}
            label="Telefone"
            minLength={9}
            variant="outlined"
            color="primary"
            required
            autoComplete="new-password"          
            />
        </div>
        <div className={style.input_double}>
          <TextField
            margin="dense"
            label="Kit"
            type="number"
            value={kit ? kit : ''}
            onChange={(e) => setKit(e.target.value)}
            fullWidth
            variant="outlined"
            autoComplete="new-password"
            required
          />
          <CurrencyInput
            fullWidth
            style={{ marginTop: '0.3rem' }}
            customInput={TextField}
            label="Valor"
            placeholder="R$ 00"
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            onValueChange={(value) => setValor(value)}
            value={valor || ''}
            min={50}
            required
            color="primary" />
        </div>
        <div className={style.input_double}>
          <FormControl sx={{ margin: "0.3rem 0" }} fullWidth>
            <InputLabel id="select-label">Consultora</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={consultora}
              label="Consultora"
              onChange={(e) => setConsultora(e.target.value)}
              required
            >
                <MenuItem value='Ana'>Ana</MenuItem>
                <MenuItem value='Bruna'>Bruna</MenuItem>
                <MenuItem value='Lia'>Lia</MenuItem>
                <MenuItem value='Mayara'>Mayara</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ margin: "0.3rem 0" }} fullWidth>
            <InputLabel id="select-label">Situação</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={situacao}
              label="Situação"
              onChange={(e) => setSituacao(e.target.value)}
              required
            >
                <MenuItem value='Aguardando'>Aguardando</MenuItem>
                <MenuItem value='Pago'>Pago</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={style.input_double}>
          <TextField
            fullWidth
            sx={{ marginTop: '0.3rem' }}
            type="textarea"
            label="Observação"
            multiline
            rows={5}
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />
        </div>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
          <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            disableElevation
            variant="contained"
            color="success"
            sx={{ textTransform: 'capitalize' }} 
            type="submit">Confirmar</Button>
          <Button
            disableElevation
            variant="contained"
            sx={{ textTransform: 'capitalize' }} 
            onClick={() => closeBox('all')}>Cancelar</Button>
        </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(EditLead);

import { useEffect, useRef, useState } from "react";
import { dataBase } from "../../firebase/database";
import Dashboard from "../../components/Dashboard/Visit_and_Prospection/Index";
import Filter from "../../components/Filter/Index";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ThemeProvider } from "@emotion/react";
import { theme } from "../../data/theme";

import RequestQuoteIcon from '@mui/icons-material/RequestQuote'; // Visita Comercial
import PeopleIcon from '@mui/icons-material/People'; // Tecnica + Comercial
import RestaurantIcon from '@mui/icons-material/Restaurant'; // Almoço
import EngineeringIcon from '@mui/icons-material/Engineering'; // Pós Venda

import { ReactComponent as ScheduleIcon } from "../../images/icons/Schedule1.svg";

import {
  onSnapshot,
  collection
} from "firebase/firestore";

import "../Schedule/_style.scss";
import "../../components/Dashboard/Visit_and_Prospection/_styles.scss";

const Finance = ({ visits, userRef, alerts, sellers }) => {
  const month = ['','Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
  const [schedule, setSchedule] = useState();
  const data = new Date();
  const [year, setYear] = useState('2024');
  const [monthSelect, setMonthSelect] = useState(
    String(data.getMonth() + 1).padStart(2, "0")
  );
  const [scheduleNew, setScheduleNew] = useState();
  const [members, setMembers] = useState();
  const [tecs, setTecs] = useState();
  const [sales, setSales] = useState([]);
  const [analyts, setAnalyts] = useState([]);
  const [projects, setProjects] = useState([]);
  const projectsRef  = useRef(null);
  const [total, setTotal] = useState();
  const membersCollectionRef = collection(dataBase, "Membros");
  const [sellersOrder, setSellersOrder] = useState();
  const [TabsValue, setTabsValue] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  console.log(projectsRef)

  useEffect(() => {
    if(monthSelect) {
      setSchedule(visits && visits.filter(data => data.data.substring(0,7) === year+'-'+monthSelect && 
      data.confirmar === true && data.categoria !== 'lunch' && data.consultora !== "Almoço Téc."));
      const projectFilter = projectsRef.current && projectsRef.current.filter(data => data.projeto_user.data.substring(0,7) === year +'-'+ monthSelect);
      console.log(projectFilter)
      setProjects(projectFilter);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthSelect, year, visits, projectsRef]);

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(membersCollectionRef, (member) => {
          // Atualiza os dados em tempo real
          setMembers(member.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if(sellers) {
      setSellersOrder(sellers.sort((a,b) => {
        if(a.nome< b.nome) return -1;
        if(a.nome > b.nome) return 1;
        return 0;
      }))
      setAnalyts(sellers.filter(data => data.nome_ref))
    }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sellers]
  );

useEffect(() => {
  if(visits && !projectsRef.current) {
    setProjects(visits && visits.filter(data => data.projeto_user))
    projectsRef.current = visits && visits.filter(data => data.projeto_user)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

useEffect(() => {
  if(userRef && userRef.nome === 'Pós-Venda') {
    setTabsValue(1)
  }
},[userRef])

useEffect(() => {
    if(schedule && monthSelect && members) {
      setScheduleNew(schedule.sort(function(a, b) { // Força a renderizaram da tabela ordenada
        if(a.data === b.data) {
          if(a.horario < b.horario) return -1;
          if(a.horario > b.horario) return 1;
        }
        return 0;
      }))
      let docs = [];
      members.map((ref) => {
        if(ref.cargo === 'Técnico' && schedule.find(name => name.tecnico === ref.nome)) {
          docs.push(ref.nome)
        }
        return setTecs(docs.sort());
      })
      tecs && tecs.map((tec, index) => (
        setTotal(schedule.filter((ref) => ref.tecnico && ref.tecnico !== 'Nenhum' && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia' && ref.consultora !== "Pós-Venda").length * 20)
      ))
    }
    // console.log(tecs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[members, monthSelect, schedule, scheduleNew])
  
  console.log(projects)

  useEffect(
    () => {
      const fetchData = async () => {
        if (members) {
          let docs = [];
        members.map((ref) => {
        if(ref.cargo === 'Vendedor(a)') {
          docs.push(ref.nome)
        }
        return setSales(docs.sort());
      })
          // setSales(members.filter((member) => member.cargo === "Vendedor(a)"));
        }
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [members]
  );

  const changeFilter = (data) => {
    if(data.length === visits.length) {
      setSchedule(visits && visits.filter(data => data.data.substring(0,7) === year+'-'+monthSelect && 
      data.confirmar === true && data.categoria !== 'lunch' && data.consultora !== "Almoço Téc."));
    } else {
      setSchedule(data);
    }
  }

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} alignItems={'center'}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <div className="container-schedule">
      <div className="title-panel">
        <ScheduleIcon />
        <h2>Agenda {year} </h2>
        <h2>Relátorio Mensal - {month[parseFloat(monthSelect)]} </h2>
            <div className="schedule-month">
            <select
              value={monthSelect}
              className="schedule-month__select"
              name="month"
              onChange={(e) => setMonthSelect(e.target.value)}
            >
              <option value="01">Janeiro</option>
              <option value="02">Fevereiro</option>
              <option value="03">Março</option>
              <option value="04">Abril</option>
              <option value="05">Maio</option>
              <option value="06">Junho</option>
              <option value="07">Julho</option>
              <option value="08">Agosto</option>
              <option value="09">Setembro</option>
              <option value="10">Outubro</option>
              <option value="11">Novembro</option>
              <option value="12">Dezembro</option>
            </select>
            <select
                value={year}
                className="schedule-month__select"
                name="year"
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
          </div>
      </div>
      <ThemeProvider theme={theme}>
          <Tabs className="print" value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Colaboradores" centered>
              <Tab sx={{ textTransform: 'capitalize' }} label="Visitas" {...a11yProps(1)} disabled={userRef && userRef.nome === 'Pós-Venda'} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Projetos" {...a11yProps(2)} disabled={userRef && userRef.cargo === 'Técnico'} />
          </Tabs>
        </ThemeProvider>
        <CustomTabPanel value={TabsValue} index={0}>
        <div className="content-schedule-visit">
          <Dashboard schedule={schedule} monthSelect={monthSelect} type={'financeiro'} total={total} />
          <div className="box-schedule-visit">
            <div className="container-table">
            {tecs && tecs.length > 0 &&
              <div className="container-info">
              {tecs && tecs.map((tec, index) => {
                if(index > 0) { return (
                  <table key={index} className="table-finance">
                    <thead>
                      <tr>
                        <th colSpan={3}>{tec}</th>
                      </tr>
                      <tr>
                        <th>Visitas</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedule && sales && sales.map((vend, index) => (
                        <tr className="table" key={index}>
                        <td>{schedule.filter((ref) => ref.consultora === vend && ref.tecnico === tec && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia').length}</td>
                        <td>
                        {(schedule.filter((ref) => ref.consultora === vend && ref.tecnico === tec && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia' && ref.consultora !== "Pós-Venda").length * 20).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </td>
                      </tr>
                      ))}
                      <tr className="bg-total">
                        <td>{schedule.filter((ref) => ref.tecnico === tec && ref.tecnico !== "Bruna" && ref.tecnico !== "Lia").length}</td>
                        <td>{(schedule.filter((ref) => ref.tecnico === tec && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia' && ref.consultora !== "Pós-Venda").length * 20).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                      </tr>
                    </tbody>
                  </table>
                ) }
                else { return (
                  <table key={index} className="table-finance">
                    <thead>
                      <tr>
                        <th rowSpan={2}>Vendedor(a)</th>
                        <th colSpan={3}>{tec}</th>
                      </tr>
                      <tr>
                        <th>Visitas</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedule && sales && sales.map((vend, index) => (
                        <tr className="table" key={index}>
                        <td className="bold">
                          {vend}
                        </td>
                        <td>{schedule.filter((ref) => ref.consultora === vend && ref.tecnico === tec).length}</td>
                        <td>
                        {(schedule.filter((ref) => ref.consultora === vend && ref.tecnico === tec && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia' && ref.consultora !== "Pós-Venda").length * 20).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </td>
                      </tr>
                      ))}
                      <tr className="bg-total">
                        <td>Total</td>
                        <td>{schedule.filter((ref) => ref.tecnico === tec).length}</td>
                        <td>{(schedule.filter((ref) => ref.tecnico === tec && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia' && ref.consultora !== "Pós-Venda").length * 20).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                      </tr>
                    </tbody>
                  </table>
              )}
            })}
              <table className="table-finance end">
                    <thead>
                      <tr>
                        <th colSpan={3}>Total</th>
                      </tr>
                      <tr>
                        <th>Visitas</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedule && sales && sales.map((vend, index) => (
                        <tr className="table" key={index}>
                        <td>{schedule.filter((ref) => ref.consultora === vend).length}</td>
                        <td>
                        {(schedule.filter((ref) => ref.consultora === vend && ref.tecnico !== 'Nenhum' && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia' && ref.consultora !== "Pós-Venda").length * 20).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </td>
                      </tr>
                      ))}
                      <tr className="bg-total">
                        <td>{schedule.filter((ref) => ref.tecnico).length}</td>
                        <td>{(schedule.filter((ref) => ref.tecnico && ref.tecnico !== 'Nenhum' && ref.tecnico !== 'Bruna' && ref.tecnico !== 'Lia' && ref.consultora !== "Pós-Venda").length * 20).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
              }        
              <div className="desktop filter-finance print">
                <Filter tableData={schedule} dataFull={visits} sellers={sellersOrder} changeFilter={changeFilter} type={'visit'} />
              </div>
              <TableContainer className="table-visit table-center" component={Paper} sx={{ maxWidth: '1000px' }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="table-visits_header">
                    <TableCell align="center">Visita</TableCell>
                    <TableCell align="center">Dia</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center" padding="none">Cliente</TableCell>
                    <TableCell align="center">Horário Marcado</TableCell>
                    <TableCell align="center">Consultora</TableCell>
                    <TableCell align="center">Técnico / Motorista</TableCell>
                    <TableCell align="center">Veiculo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedule && schedule.map((visita) => (
                    <TableRow
                      hover
                      key={visita.id}
                      className={`list-visit`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {!visita.categoria && <TableCell></TableCell>}
                      {visita.categoria === "lunch" && <TableCell style={{ filter: 'contrast', padding: '0.2rem' }} className="type-icon lunch" aria-label="Almoço" data-cooltipz-dir="right"><RestaurantIcon /></TableCell>}
                      {visita.categoria === "comercial" && <TableCell style={{ padding: '0.2rem' }} className="type-icon comercial" aria-label="Visita Comercial" data-cooltipz-dir="right"><RequestQuoteIcon /></TableCell>}
                      {visita.categoria === "comercial_tecnica" && <TableCell style={{ padding: '0.2rem' }} className="type-icon comercial_tec" aria-label="Comercial + Técnica" data-cooltipz-dir="right"><PeopleIcon /></TableCell>}
                      {visita.categoria === "pos_venda" && <TableCell style={{ padding: '0.2rem' }} className="type-icon pos_venda" aria-label="Pós-Venda" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                      {visita.categoria === "tecnica" && <TableCell style={{ padding: '0.2rem' }} className="type-icon tec" aria-label="Técnica" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                      <TableCell className="td-finance" sx={{ width: 30 }} align="center" scope="row">
                        {visita.dia.substring(8, 10)}
                      </TableCell>
                      <TableCell className="td-finance" align="center">{visita.cidade}</TableCell>
                      <TableCell className="td-finance" align="center">{visita.cliente}</TableCell>
                      <TableCell className="td-finance"
                      aria-label={visita.saidaEmpresa && `Saida: ${visita.saidaEmpresa} - Chegada: ${visita.chegadaEmpresa}`}
                      data-cooltipz-dir="top"
                      align="center"
                      >{visita.chegadaCliente}</TableCell>
                      <TableCell className="td-finance"
                      sx={{ backgroundColor: `${visita.cor}`, color: '#fff', fontWeight: 'bold' }} 
                      align="center" scope="row">
                        {visita.consultora}
                      </TableCell>
                      <TableCell className="td-finance" align="center">{visita.tecnico}</TableCell>
                      <TableCell className="td-finance" align="center">{visita.veiculo}</TableCell>
                    </TableRow>
                  ))}
                  {schedule && schedule.length < 1 &&
                    <TableRow>
                      <TableCell colSpan={15}>
                        <p className="margin1" style={{ textAlign: 'center', margin: '1rem', fontSize: '1.2rem' }}>Nenhuma Visita Encontrada</p>
                      </TableCell>
                    </TableRow>
                  } 
                </TableBody>
              </Table>
            </TableContainer>
              <div className="print">
              <button className="btn-print" onClick={() => window.print()}>Imprimir / Salvar PDF</button>
            </div>
            </div>
          </div>
        </div>
        </CustomTabPanel>
        <CustomTabPanel value={TabsValue} index={1}>
        <div className="content-schedule-visit">
          <div className="box-schedule-visit"> 
            <div className="container-table">
            <div className="desktop filter-finance">
                {/* <Filter tableData={schedule} dataFull={visits} sellers={sellersOrder} changeFilter={changeFilter} type={'visit'} /> */}
              </div>
              <TableContainer className="table-visit table-center" component={Paper} sx={{ maxWidth: '1000px' }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="table-visits_header">
                    <TableCell align="center">Dia</TableCell>
                    <TableCell align="center" padding="none">Cliente</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center">Consultora</TableCell>
                    <TableCell align="center">Responsável</TableCell>
                    <TableCell align="center">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects && projects.map((visita) => (
                    <TableRow
                      hover
                      key={visita.id}
                      className={`list-visit`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className="td-finance" sx={{ width: 30 }} align="center" scope="row">
                        {visita.projeto_user?.data.substring(8, 10)}
                      </TableCell>
                      <TableCell className="td-finance" align="center">{visita.cliente}</TableCell>
                      <TableCell className="td-finance" align="center">{visita.cidade}</TableCell>
                      <TableCell className="td-finance"
                      sx={{ backgroundColor: `${visita.cor}`, color: '#fff', fontWeight: 'bold' }} 
                      align="center" scope="row">
                        {visita.consultora}
                      </TableCell>
                      <TableCell className="td-finance" align="center">{visita.projeto_user?.nome}</TableCell>
                      <TableCell className="td-finance" align="center">R$ 10,00</TableCell>
                    </TableRow>
                  ))}
                  {projects && projects.length < 1 &&
                    <TableRow>
                      <TableCell colSpan={15}>
                        <p className="margin1" style={{ textAlign: 'center', margin: '1rem', fontSize: '1.2rem' }}>Nenhum Projeto Encontrado</p>
                      </TableCell>
                    </TableRow>
                  } 
                </TableBody>
              </Table>
            </TableContainer>
            {analyts && analyts.length > 0 &&
              <div className="container-info">
                  <table className="table-finance">
                    <thead>
                      <tr>
                        <th rowSpan={2}>Responsável</th>
                        <th colSpan={3}>Projetos</th>
                      </tr>
                      <tr>
                        <th>Quantidade</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects && analyts && analyts.map((vend, index) => (
                      <tr className="table" key={index}>
                        <td className="bold">
                          {vend.nome_ref}
                        </td>
                        <td>
                        {projects.filter((ref) => ref.projeto_user?.id === vend.uid).length}
                        </td>
                        <td>
                        {(projects.filter((ref) => ref.projeto_user?.id === vend.uid).length * 10).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </td>
                      </tr>
                      ))}
                      <tr className="bg-total">
                        <td>Total</td>
                        <td>{projects.filter((ref) => 
                        ref.projeto_user?.id === 'K6eQ7aNbB6d6s9R89Dx6K2rFxFB2' ||
                        ref.projeto_user?.id === 'FLNgLtdFELPoWV67PnHIMoUeIKC2').length}</td>
                        <td>{(projects.filter((ref) => 
                        ref.projeto_user?.id === 'K6eQ7aNbB6d6s9R89Dx6K2rFxFB2' ||
                        ref.projeto_user?.id === 'FLNgLtdFELPoWV67PnHIMoUeIKC2').length * 10).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }        
              <div className="print">
              <button className="btn-print" onClick={() => window.print()}>Imprimir / Salvar PDF</button>
            </div>
            </div>
          </div>
        </div>
        </CustomTabPanel>
    </div>
  );
};

export default Finance;

import { useState, useRef } from 'react'
import Swal from "sweetalert2";
import style from "./style.module.scss";
import SlickSlider from "react-slick";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

// import Telhas from '../../../../images/install/telhas.jpg';
import InversorLigado from '../../../../images/install/inversor_ligado.jpeg';
import Modulos from '../../../../images/install/modulos_instalados.jpeg';
import InversorString from '../../../../images/install/inversor_string.jpeg';
import Modulos2 from '../../../../images/install/modulos2.jpg';
import Modulos3 from '../../../../images/install/modulos3.jpg';

import { addDoc, collection } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUploadCleaner';
import useCircular from '../../../../hooks/useCircular';

const CleanerStep1 = ({cleaner,  open, close }) => {
  const theme = useTheme();
  const slider = useRef(null);
  const { uploadImages, changeProgress } = useUpload(cleaner);
  const { CircularProgressWithLabel  } = useCircular();
  const [openPhoto, setOpenPhoto] = useState(0);
  const [step, setStep] = useState(0);
  const [vistoria, setVistoria] = useState({ 
    modulos: [],
    inversor: [],
    inversor_string: [],
    modulos_local: [],
    modulos_foto_antes: [],
    modulos_video_antes: [],
    infiltracao: ''
 });
  const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false]);
  // const [handleStorageRef, setHandleStorageRef] = useState({ modulos: [], inversor: [], vazamento: [], telhas: [] });
  const checkPhotos = [
   'Video da Área dos Módulos', 
   'Foto do Inversor Ligado', 
   'Vídeo do Inversor e String Ligado', 
   'Foto/Vídeo do Local dos Módulos',
   'Foto do Módulos Instalados',
   'Vídeo do Módulos Instalados'
  ];

  const steps = [
    'Inspeção Técnica do Local',
    'Inspeção Técnica do Telhado'
  ];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  // useEffect(() => {
  //   if(storageRef && storageRef.length > 0) {
  //     setHandleStorageRef({
  //       modulo: storageRef[0],
  //       inversor: storageRef[1],
  //       vazamento: storageRef[2],
  //       telhas: storageRef[3]
  //     })
  //   }
  // },[storageRef])

  // console.log(handleStorageRef);

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  // const changeStep = (direction) => {
  //   if(direction === 'go') {
  //     if(step === 1 && newPosition !== position) {
  //       return Swal.fire({
  //         title: 'GPS do Padrão',
  //         html: `Confirme o <b>GPS do Padrão</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     } else if (telhadoPerto.length === 0 || telhadoLonge.length === 0 || telhadoVideo.length === 0) {
  //       let validationPhoto;
  //       if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Perto'
  //       } else if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Longe'
  //       } else if(telhadoVideo.length === 0) {
  //         validationPhoto = 'um vídeo do <b>Telhado'
  //       }
  //       return Swal.fire({
  //         title: 'Fotos & Vídeos',
  //         html: `Envie ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     }
  //     setStep(step + 1);
  //   } else {
  //     setStep(step - 1);
  //   }
  // }

  const closeBox = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setCheckData([false]);
      setVistoria({
        modulos: [],
        inversor: [],
        inversor_string: [],
        modulos_local: [],
        modulos_foto_antes: [],
        modulos_video_antes: [],
        infiltracao: ''
      });
    }, 500);
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'vistoriaModulos') { 
      if(vistoria.modulos) {
        setVistoria({...vistoria, modulos: [...vistoria.modulos, ...files]})
      } else {
        setVistoria(files);
      }
    } else if(type === 'vistoriaInversor') { 
      if(vistoria.inversor) {
        setVistoria({...vistoria, inversor: [...vistoria.inversor, ...files]})
      } else {
        setVistoria(files);
      }
    } else if(type === 'vistoriaString') { 
      if(vistoria.inversor_string) {
        setVistoria({...vistoria, inversor_string: [...vistoria.inversor_string, ...files]})
      } else {
        setVistoria(files);
      }
    } else if(type === 'vistoriaModulosLocal') { 
      if(vistoria.modulos_local) {
        setVistoria({...vistoria, modulos_local: [...vistoria.modulos_local, ...files]})
      } else {
        setVistoria(files);
      }
    } else if(type === 'vistoriaModulosFotosAntes') { 
      if(vistoria.modulos_foto_antes) {
        setVistoria({...vistoria, modulos_foto_antes: [...vistoria.modulos_foto_antes, ...files]})
      } else {
        setVistoria(files);
      }
    } else if(type === 'vistoriaModulosVideoAntes') { 
      if(vistoria.modulos_video_antes) {
        setVistoria({...vistoria, modulos_video_antes: [...vistoria.modulos_video_antes, ...files]})
      } else {
        setVistoria(files);
      }
    }
  }

  const changeStep = (direction) => {
    if(direction === 'go') {
       if (step < 1 && (vistoria.inversor.length === 0 || vistoria.modulos.length === 0 || vistoria.inversor_string.length === 0 || vistoria.infiltracao.length === 0)) {
        let validationPhoto;
        if(vistoria.modulos.length === 0) {
          validationPhoto = 'Envie a foto e video da área dos <b>Módulos instalados</b>'
        } else if(vistoria.infiltracao.length === 0) {
          validationPhoto = 'Informe se há <b>vestígios de infiltração</b>'
        } else if(vistoria.inversor.length === 0) {
          validationPhoto = 'Envie a foto do <b>Inversor ligado</b>'
        } else if(vistoria.inversor_string.length === 0) {
          validationPhoto = 'Envie o video do <b>Inversor e String ligado</b>'
        }
        return Swal.fire({
          title: 'Fotos',
          html: `${validationPhoto}</b> para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#F39200",
          confirmButtonText: "Ok",
        })
      }
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
      if(newPhotos.length === 0) {
        setOpenPhoto(false);
      }
    setPhotos({data: newPhotos, type: photos.type});
     switch (type) {
      case 'vistoriaInversor':
        setVistoria({...vistoria, inversor: newPhotos})
        break;
      case 'vistoriaModulos':
        setVistoria({...vistoria, modulos: newPhotos})
        break;
      case 'vistoriaString':
        setVistoria({...vistoria, inversor_string: newPhotos})
        break;
      case 'vistoriaTelhas':
        setVistoria({...vistoria, telhas: newPhotos})
        break;
        default:
     }
    }
  }

  // const viewPhoto = (photo, type) => {
  //   setPhotos({data: photo, type: type});
  //   setOpenPhoto(true);
  // }

  const sendVisit = async () => {
    if (vistoria.modulos_local.length === 0 || vistoria.modulos_foto_antes.length === 0 || vistoria.modulos_video_antes.length === 0) {
      let validationPhoto;
      if(vistoria.modulos_local.length === 0) {
        validationPhoto = 'o video ou foto mostrando o <b>Telhado local dos Módulos'
      } else if(vistoria.modulos_fotos_antes.length === 0) {
        validationPhoto = 'a foto dos <b>Módulos antes da limpeza'
      } else if (vistoria.modulos_video_antes.length === 0) {
        validationPhoto = 'o video dos <b>Módulos antes da limpeza'
      }
      return Swal.fire({
        title: 'Fotos & Vídeos',
        html: `Envie ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      console.log(vistoria)
      console.log(cleaner)
      Swal.fire({
        title: 'Antes da Limpeza',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let visita = {};
          let ref = {};
          setStep(2);

          try {
            const resultModulos = await uploadImages(vistoria.modulos, 'vistoriaModulos', 0);
            visita.modulos = resultModulos.map(object => object.downloadURL);
            ref.modulos = resultModulos.map(object => object.fullPath);

            const resultVistoriaInversor = await uploadImages(vistoria.inversor, 'vistoriaInversor', 1);
            visita.inversor = resultVistoriaInversor.map(object => object.downloadURL);
            ref.inversor = resultVistoriaInversor.map(object => object.fullPath);
            handleChangeCheck(1);

            const resultVistoriaInversorString = await uploadImages(vistoria.inversor_string, 'vistoriaInversorString', 2);
            visita.inversor_string = resultVistoriaInversorString.map(object => object.downloadURL);
            ref.inversor_string = resultVistoriaInversorString.map(object => object.fullPath);
            handleChangeCheck(2);

            const resultVistoriaModuloLocal = await uploadImages(vistoria.modulos_local, 'vistoriaModuloLocal', 3);
            visita.modulos_local = resultVistoriaModuloLocal.map(object => object.downloadURL);
            ref.modulos_local = resultVistoriaModuloLocal.map(object => object.fullPath);
            handleChangeCheck(3);

            const resultVistoriaFotosAntes = await uploadImages(vistoria.modulos_foto_antes, 'vistoriaModuloFotosAntes', 4);
            visita.modulos_foto_antes = resultVistoriaFotosAntes.map(object => object.downloadURL);
            ref.modulos_foto_antes = resultVistoriaFotosAntes.map(object => object.fullPath);
            handleChangeCheck(4);

            const resultVistoriaVideoAntes = await uploadImages(vistoria.modulos_video_antes, 'vistoriaModuloVideoAntes', 5);
            visita.modulos_video_antes = resultVistoriaVideoAntes.map(object => object.downloadURL);
            ref.modulos_video_antes = resultVistoriaVideoAntes.map(object => object.fullPath);
            handleChangeCheck(5);

              await addDoc(collection(dataBase, 'Limpezas', cleaner, 'Etapas'), {
               ...visita,
               ref,
               createAt: new Date(),
               etapa: 1,
               infiltracao: vistoria.infiltracao
              }).then(() => {
                 Swal.fire({
                 title: 'Antes da Limpeza',
                 html: `A <b>Etapa</b> foi concluida com sucesso.`,
                 icon: "success",
                 showCloseButton: true,
                 confirmButtonColor: "#111",
                 confirmButtonText: "Ok",
                 })
                 closeBox();
         })
          } catch (error) {
            console.error('Ocorreu um erro: ', error);
          }  
          }
        })
    }
  }

  return (
    <><Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={() => closeBox()}
    >
      <IconButton
        aria-label="close"
        onClick={() => closeBox()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Antes da Limpeza</DialogTitle>
      <DialogContent className={style.dialog_content}>
      <div className={style.stepper}>
          <Stepper sx={{ width: '100%' }} activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      {step === 0 &&
        <div className={style.register_container}>
           <div className={style.photo_item}>
            {vistoria && vistoria.modulos.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Grave e envie foto e vídeo abaixo da área onde está <b>instalado os módulos</b>.<br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Modulos3} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Grave e envie foto e vídeo abaixo da área onde está instalado os módulos.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.modulos.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                          }
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.modulos, 'vistoriaModulos')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaModulos')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto / Video
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg,video/*" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0' }}>
            Há vestígios de infiltração?
          </DialogContentText>
          <div className={style.input_double}>
            <FormControl fullWidth margin='dense'>
              <InputLabel id="infiltracao">Resposta</InputLabel>
                <Select
                  labelId='infiltracao'
                  label="Resposta"
                  value={vistoria.infiltracao}
                  onChange={(e) => setVistoria({...vistoria, infiltracao: e.target.value})}
                  required>
                  <MenuItem value="Sim">Sim</MenuItem>
                  <MenuItem value="Não">Não</MenuItem>
                  </Select>
            </FormControl>
          </div>
           <div className={style.photo_item}>
            {vistoria && vistoria.inversor.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 2:</b> Envie uma foto do <b>inversor ligado.</b> <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={InversorLigado} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Envie uma foto do <b>inversor ligado.</b>
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.inversor.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.inversor, 'vistoriaInversor')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
            </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }} 
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaInversor')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
           <div className={style.photo_item}>
            {vistoria && vistoria.inversor_string.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 3:</b> Grave e envie um video mostrando o <b>inversor e string ligado</b>.<br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={InversorString} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Grave e envie um video mostrando o <b>inversor e string ligado</b>.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.inversor_string.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <video src={img.file} width='90%' controls muted playsInline />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.inversor_string, 'vistoriaString')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
            </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaString')} startIcon={<AddToPhotosIcon />}>
                Enviar Video
                <VisuallyHiddenInput multiple={true} type="file" accept="video/*" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
        </div>
      }
      {step === 1 &&
        <div className={style.register_container}>
           <div className={style.photo_item}>
            {vistoria && vistoria.modulos_local.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 4:</b> Envie um vídeo ou foto do <b>telhado local dos módulos</b> ou <b>caminho para acessar os módulos</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Modulos2} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 4:</b> Grave e envie foto e vídeo abaixo da área onde está instalado os módulos.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.modulos_local.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                          }
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.modulos_local, 'vistoriaModulosLocal')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaModulosLocal')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto / Video
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg,video/*" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
           <div className={style.photo_item}>
            {vistoria && vistoria.modulos_foto_antes.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 5:</b> Envie uma foto dos <b>módulos antes da limpeza</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Modulos} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 5:</b> Envie uma foto dos <b>módulos antes da limpeza</b>.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.modulos_foto_antes.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.modulos_foto_antes, 'vistoriaModulosFotosAntes')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
            </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }} 
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaModulosFotosAntes')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
           <div className={style.photo_item}>
            {vistoria && vistoria.modulos_video_antes.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 6:</b> Envie um video dos <b>módulos antes da limpeza</b>.<br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Modulos} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 6:</b> Grave e envie um video mostrando o <b>inversor e string ligado</b>.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.modulos_video_antes.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <video src={img.file} width='90%' controls muted playsInline />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.modulos_video_antes, 'vistoriaModulosVideoAntes')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
            </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label"
                variant="contained"
                onChange={(e) => sendPhoto(e, 'vistoriaModulosVideoAntes')} startIcon={<AddToPhotosIcon />}>
                Enviar Video
                <VisuallyHiddenInput multiple={true} type="file" accept="video/*" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
        </div>
      }
        {step === 2 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li key={index}>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} /> 
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
        {step > 0 ? 
          <Button disableElevation sx={{ textTransform: 'capitalize' }} variant='contained' disabled={step > 1 ? true: false} onClick={() => setStep(step - 1)}>Voltar</Button> :
          <Button disableElevation sx={{ textTransform: 'capitalize' }} variant='contained' onClick={() => closeBox()}>Fechar</Button>
        }
        {step > 0 ?
          <Button disableElevation sx={{ textTransform: 'capitalize' }} variant='contained' disabled={step > 1 ? true: false} color='success' onClick={() => sendVisit()}>Enviar</Button> :
          <Button disableElevation sx={{ textTransform: 'capitalize' }} variant='contained' onClick={() => changeStep('go')}>Próximo</Button>
        }
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog></>
  )
}

export default CleanerStep1
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useMemo } from 'react';
 
const NewTooltip = ({ children, title, placement, followCursor = false, open = false, color = "#fff", background = "#111" }) => {
  const ButtonTooltip = useMemo(() => styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: background,
      color: color,
      textAlign: 'center',
      padding: '0.5rem 1rem',
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: background, // Defina a cor desejada para a seta aqui
    },
  })),[color, background]);
  return (
    (open ? 
    <ButtonTooltip 
      className='clickable' title={title} arrow placement={placement} followCursor={followCursor} open={open}>
      {children}
    </ButtonTooltip> :
    <ButtonTooltip 
      className='clickable' title={title} arrow placement={placement} followCursor={followCursor} enterTouchDelay={0}>
      {children}
    </ButtonTooltip>
    )
  );
};

export default NewTooltip;
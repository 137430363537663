import { useState, useRef } from 'react'
import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
import SlickSlider from "react-slick";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import TelhadoPerto from '../../../../images/visit/telhado_perto.jpeg';
import TelhadoLonge from '../../../../images/visit/telhado_longe.jpeg';
// import Video from '../../../../videos/telhado_video.mp4';

import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
import { addDoc, collection } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';

const RegisterVisitStep2 = ({ visit, open, close }) => {
  const theme = useTheme();
  const slider = useRef(null);
  const { uploadImages, changeProgress } = useUpload(visit.id);
  const { CircularProgressWithLabel  } = useCircular();
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [step, setStep] = useState(0);
  const [telhadoPerto, setTelhadoPerto] = useState([]);
  const [telhadoLonge, setTelhadoLonge] = useState([]);
  // const [telhadoVideo, setTelhadoVideo] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false], [false]);
  // const [changeProgress, setChangeProgress] = useState(['0', '0', '0']);

  const checkPhotos = ['Fotos de Perto do Telhado', 'Fotos de Longe do Telhado'];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  // const changeStep = (direction) => {
  //   if(direction === 'go') {
  //     if(step === 1 && newPosition !== position) {
  //       return Swal.fire({
  //         title: 'GPS do Padrão',
  //         html: `Confirme o <b>GPS do Padrão</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     } else if (telhadoPerto.length === 0 || telhadoLonge.length === 0 || telhadoVideo.length === 0) {
  //       let validationPhoto;
  //       if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Perto'
  //       } else if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Longe'
  //       } else if(telhadoVideo.length === 0) {
  //         validationPhoto = 'um vídeo do <b>Telhado'
  //       }
  //       return Swal.fire({
  //         title: 'Fotos & Vídeos',
  //         html: `Envie ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     }
  //     setStep(step + 1);
  //   } else {
  //     setStep(step - 1);
  //   }
  // }

  const closeBox = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setCheckData([false],[false],[false]);
      setTelhadoLonge([])
      setTelhadoPerto([])
      // setTelhadoVideo([])
      setPhotos();
    }, 500);
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  // const handleProgress= (progress, index) => {
  //   setChangeProgress((prevChangeProgress) => {
  //     const newChangeProgress = [...prevChangeProgress]; // Cria uma cópia do array existente
  //     newChangeProgress[index] = progress // Inverte o valor do elemento específico
  //     return newChangeProgress; // Retorna o novo array atualizado
  //   });
  // }

  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'telhadoPerto') {
      if(telhadoPerto) {
        setTelhadoPerto([...telhadoPerto, ...files])
      } else {
        setTelhadoPerto(files);
      }
    } else if(type === 'telhadoLonge') {
      if(telhadoLonge) {
        setTelhadoLonge([...telhadoLonge, ...files])
      } else {
        setTelhadoLonge(files);
      }
    } 
    // else if(type === 'telhadoVideo') {
    //   if(telhadoVideo) {
    //     setTelhadoVideo([...telhadoVideo, ...files])
    //   } else {
    //     setTelhadoVideo(files);
    //   }
    // }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
     switch (type) {
      case 'telhadoPerto':
        setTelhadoPerto(newPhotos);
        break;
      case 'telhadoLonge':
        setTelhadoLonge(newPhotos);
        break;
      // case 'telhadoVideo':
      //   setTelhadoVideo(newPhotos);
      //   break;
        default:
     }
    }
  }

  // const viewPhoto = (photo, type) => {
  //   setPhotos({data: photo, type: type});
  //   setOpenPhoto(true);
  // }

  const sendVisit = () => {
    if (telhadoPerto.length === 0 || telhadoLonge.length === 0) {
      let validationPhoto;
      if(telhadoPerto.length === 0) {
        validationPhoto = 'a foto do <b>Telhado de Perto'
      } else if(telhadoLonge.length === 0) {
        validationPhoto = 'a foto do <b>Telhado de Longe'
      }
      //  else if(telhadoVideo.length === 0) {
      //   validationPhoto = 'um vídeo do <b>Telhado'
      // }
      return Swal.fire({
        title: 'Fotos',
        html: `Envie ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Visita Técnica - Fotos (Telhado)',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let visita = {};
          let ref = {};
          setStep(1);

          try {
            const resultTelhadoPerto = await uploadImages(telhadoPerto, 'telhadoPerto', 0)
              visita.telhadoPerto = resultTelhadoPerto.map(object => object.downloadURL);
              ref.telhadoPerto = resultTelhadoPerto.map(object => object.fullPath);
              handleChangeCheck(0);

            const resultTelhadoLonge = await uploadImages(telhadoLonge, 'telhadoLonge', 1)
              visita.telhadoLonge = resultTelhadoLonge.map(object => object.downloadURL);
              ref.telhadoLonge = resultTelhadoLonge.map(object => object.fullPath);
              handleChangeCheck(1);

            // const resultTelhadoVideo = await uploadImages(telhadoVideo, 'telhadoVideo', 2)
            //   visita.telhadoVideo = resultTelhadoVideo.map(object => object.downloadURL);
            //   ref.telhadoVideo = resultTelhadoVideo.map(object => object.fullPath);
            //   handleChangeCheck(2);


            await addDoc(collection(dataBase, 'Visitas', visit.id, 'Tecnica'), {
              ...visita,
              ref: ref,
              etapa: 2
             }).then(() => {
              Swal.fire({
                title: 'Visita Técnica - Fotos (Telhado)',
                html: `A <b>Etapa</b> foi concluida com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#111",
                confirmButtonText: "Ok",
                })
                closeBox();
             })
          } catch (error) {
            console.error('Ocorreu um erro: ', error)
          }
          }
        })
    }
  }

  console.log(telhadoPerto);

  // const uploadImage = async (image, type, index) => {
  //   return new Promise((resolve, reject) => {
  //     const storageRef = ref(storage, `Visita/${visit.id}/${type}_${image.complete.name}`);
  //     const uploadTask = uploadBytesResumable(storageRef, image.complete, {contentType: image.complete.type})
  //     uploadTask.on('state_changed', (snapshot) => {
  //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       handleProgress(progress, index);
  //       console.log('upload is ' + progress + '% done')
  //     },
  //     (error) => {
  //       console.error(error);
  //       reject(error);
  //     }, 
  //     () => {
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         console.log(downloadURL);
  //         resolve(downloadURL);
  //       });
  //     })
  //   })
  // }

  // const uploadImages = async (images, type, index) => {
  //   const imagePromises = Array.from(images, (image) => uploadImage(image, type, index));
  //   const imageRes = await Promise.all(imagePromises);
  //   return imageRes;
  // }

  // function CircularProgressWithLabel(props) {
  //   return (
  //     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
  //       <CircularProgress variant="determinate" {...props} />
  //       <Box
  //         sx={{
  //           top: 0,
  //           left: 0,
  //           bottom: 0,
  //           right: 0,
  //           position: 'absolute',
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //         }}
  //       >
  //         <Typography variant="caption" component="div" color="text.secondary">
  //           {`${Math.round(props.value ? props.value : 0)}%`}
  //         </Typography>
  //       </Box>
  //     </Box>
  //   );
  // }
  
  // CircularProgressWithLabel.propTypes = {
  //   /**
  //    * The value of the progress indicator for the determinate variant.
  //    * Value between 0 and 100.
  //    * @default 0
  //    */
  //   value: PropTypes.number.isRequired,
  // };

  return (
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={close}
    >
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Visita Técnica - Fotos (Telhado)</DialogTitle>
      <DialogContent className={style.dialog_content}>
      {step === 0 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
          {telhadoPerto && telhadoPerto.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 1:</b> Tire foto de <b>perto</b> do telhado do local. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={TelhadoPerto} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Tire foto de <b>perto</b> do telhado do local. <br />
                </DialogContentText>
                <div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {telhadoPerto && telhadoPerto.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, telhadoPerto, 'telhadoPerto')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                </div>
            </>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'telhadoPerto')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {telhadoLonge && telhadoLonge.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Tire foto de <b>longe</b> do telhado do local. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={TelhadoLonge} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Tire foto de <b>longe</b> do telhado do local. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {telhadoLonge && telhadoLonge.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }} 
                          onClick={() => deletePhoto(index, telhadoLonge, 'telhadoLonge')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'telhadoLonge')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          {/* <div className={style.photo_item}>
          {telhadoVideo && telhadoVideo.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Grave um video mostrando todo o telhado do local. <br />
                  Exemplo:
                </DialogContentText>
                <video className={style.img_visit} width='100%' controls muted playsInline>
                  <source src={Video} type="video/mp4"/>
                </video></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Grave um video mostrando todo o telhado do local. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {telhadoVideo && telhadoVideo.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <video src={img.file} width='90%' controls muted playsInline />
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }} 
                          onClick={() => deletePhoto(index, telhadoVideo, 'telhadoVideo')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button
                disableElevation
                sx={{ textTransform: 'capitalize' }} 
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'telhadoVideo')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Video
                <VisuallyHiddenInput multiple type="file" accept="video/*" />
              </Button>
            </ThemeProvider>
          </div>
          </div> */}
        </div>
      }
        {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li key={index}>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} />
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant='contained' 
            disabled={step > 0 ? true: false} 
            onClick={() => closeBox()}>Fechar</Button>
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant='contained' 
            disabled={step > 0 ? true: false} 
            color='success' 
            onClick={() => sendVisit()}>Enviar</Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
     /* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog>  */
  )
}

export default RegisterVisitStep2
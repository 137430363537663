import Swal from 'sweetalert2';

const useSwalAlert = () => {

    const swalAlert = (title, text, icon) => {

        Swal.fire({
            title: title,
            html: text,
            icon: icon,
            confirmButtonText: 'Fechar',
            confirmButtonColor: '#111',
        })

    }
  return swalAlert;

}

export default useSwalAlert
import { Navigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import Loading from "./Loading";

export default function PrivateRoute({ element: Component, ...rest }) {
  const { user, error, loading } = useAuth();

  if (loading) return <Loading view={loading} />;

  if (error) return <div>Erro ao carregar usuário: {error.message}</div>;

  return user ? <Component {...rest} /> : <Navigate to="/login" />;

}

import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ThemeProvider } from '@mui/material'

import styles from '../styles.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../data/theme';

const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const IrradiationModal = ({ irradiaton, open, close }) => {
  return ( 
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <IconButton
          aria-label="close"
            onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle sx={{ fontWeight: 'bold', marginRight: '1rem' }}>Irradiação Solar Diária Média Mensal (kWh/m°.dia)</DialogTitle>
        <DialogContent className={styles.dialog_content}>
          <div className={styles.dialog_header}>
            <div className={styles.media}>
                <p>MÉDIA</p>
                <h2>{irradiaton && irradiaton.annual}</h2>
            </div>
            {/* <div className={styles.guidance}>
                <p>INCLINAÇÃO</p>
                <h2>0°</h2>
            </div> */}
          </div>
          <ul className={styles.table_irradiaton}>
            {meses.map((data, index) => (
                <li key={index}>
                    <p className={styles.table_irradiaton_title}>{data}</p>
                    <p>{irradiaton && irradiaton.monthly[index].toFixed(2)}</p>
                </li>
            ))}
          </ul>
        </DialogContent>
        <ThemeProvider theme={theme}>
            <DialogActions sx={{ padding: '1rem' }}>
                <Button sx={{ textTransform: 'capitalize' }} onClick={close}>Fechar</Button>
            </DialogActions>
        </ThemeProvider>
    </Dialog>
  )
}

export default IrradiationModal
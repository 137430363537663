import { useNavigate } from 'react-router-dom';

const useGoPage = () => {
    const navigate = useNavigate();

    const goPage = (link, direction) => {

        if (link) {
            navigate(link);
        }
        
        if (direction === 'top') {
            window.scrollTo(0, 0);
        }
        console.log(link)
    }

    return goPage;
};

export default useGoPage;

import SvgIcon from '@mui/material/SvgIcon';

export const asSvgIcon = (reactSvgComponent) => {
  return (
    <SvgIcon>
      {reactSvgComponent}
    </SvgIcon>
  );

}
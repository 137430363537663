import { useEffect, useState } from 'react'
import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
// import SlickSlider from "react-slick";
// import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';
import StepLabel from '@mui/material/StepLabel';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, useTheme } from '@mui/material/styles';
// import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
// import CheckIcon from '@mui/icons-material/Check';
// import CancelIcon from '@mui/icons-material/Cancel';
// import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
// import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
// import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// import Fachada from '../../../../images/visit/fachada.jpeg';
// import Poste from '../../../../images/visit/poste.jpeg';
// import Bengala from '../../../../images/visit/bengala.jpeg';
// import Caixa from '../../../../images/visit/caixa.jpeg';
// import Relogio from '../../../../images/visit/disjuntor.jpeg';
// import Disjuntor from '../../../../images/visit/disjuntor_especificacao.jpeg';
// import Amperimetro from '../../../../images/visit/amperimetro.jpeg';
// import Quadro from '../../../../images/visit/quadro.jpg';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { onSnapshot, doc, updateDoc, getDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import axios from 'axios';
import moment from 'moment';
// import useUpload from '../../../../hooks/useUpload';
// import useCircular from '../../../../hooks/useCircular';

const RegisterVisitStep1 = ({ visit, open, idStep1, close }) => {
  const theme = useTheme();
  // const slider = useRef(null);
  // const { uploadImages, changeProgress } = useUpload(visit.id);
  // const { CircularProgressWithLabel  } = useCircular();
  const [step, setStep] = useState(0);
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [openAnotation, setOpenAnotation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anotation, setAnotation] = useState('');
  const [concessionaria, setConcessionaria] = useState('');
  const [medidaBitolaRamal, setMedidaBitolaRamal] = useState('');
  const [quantidadeCaboInversor, setQuantidadeCaboInversor] = useState('');
  const [bitolaCliente, setBitolaCliente] = useState({ medida: '', cabo: '' });
  const [dps, setDps] = useState({
    possui: '',
    classe: '',
    troca: ''
  });
  const [padrao, setPadrao] = useState({
    tipo: '',
    melhoria: '',
    texto: ''
  });
  const [trocarDisjuntor, setTrocarDisjuntor] = useState('');
  // const [analyse, setAnalyse] = useState('Não ❌');
  const [injecao, setInjecao] = useState('');
  const [gerador, setGerador] = useState('');
  const [condominio, setCondominio] = useState('');
  const [representative, setRepresentative] = useState({});
  // const [confirmPhotos, setconfirmPhotos] = useState({
  //   fachada: false,
  //   poste: false,
  //   bengala: false,
  //   padrao: false,
  //   relogio: false,
  //   disjuntor: false,
  //   amperimetro: false,
  //   quadro: false,
  // });
  // const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const [checkData, setCheckData] = useState([false],[false],[false],[false],[false], [false], [false], [false]);

  // const checkPhotos = ['Fotos da Fachada do Local', 'Fotos do Poste Inteiro', 'Fotos da Bengala/Ramal de Energia',
  //  'Fotos da Caixa de Padrão', 'Fotos do Relógio', 'Fotos do Disjuntor', 'Fotos da Medição de Voltagem', 'Fotos do Quadro de Distruibuição'];

  const steps = [
    'Dados do Cliente',
    // 'Fotos - CheckList',
    'Rede'
  ];

  useEffect(() => {
    const fetchData = async () => {
      await onSnapshot(doc(dataBase, 'Roleta', 'Analista'), (doc) => {
        setRepresentative({...doc.data(), id_doc: doc.id })
      })
    }

    fetchData();
  },[])

  useEffect(() => {
    if(open) {
      setConcessionaria(visit && visit.concessionaria);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const closeBox = () => {
    close();
    setTimeout(() => {
      setMedidaBitolaRamal(0);
      setIsLoading(false);
      setDps({ 
        possui: '',
        classe: '',
        troca: ''
       })
      setBitolaCliente({ medida: '', cabo: '' });
      // setPhotos();
      // setCheckData([false], [false],[false], [false],[false], [false], [false]);
      setStep(0);
    }, 500);
  }

  console.log(visit)

  const changeStep = (direction) => {
    if(direction === 'go') {
      // const CheckFalse = Object.values(confirmPhotos).some(valor => !valor);

      if(step === 0 && condominio.length === 0) {
        let validationStep1;
        if(condominio.length === 0) {
          validationStep1 = 'Informe se o Local <b>é em Condomínio</b>'
        }
        return Swal.fire({
          title: 'Dados do Cliente',
          html: `${validationStep1} para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#F39200",
          confirmButtonText: "Ok",
        })
      } 
      // else if (step === 1 && CheckFalse) {
      //   let validationPhoto;
      //   if(!confirmPhotos.fachada) {
      //     validationPhoto = 'da <b>Fachada'
      //   } else if(!confirmPhotos.poste) {
      //     validationPhoto = 'do <b>Poste'
      //   } else if(!confirmPhotos.bengala) {
      //     validationPhoto = 'da <b>Bengala'
      //   } else if(!confirmPhotos.padrao) {
      //     validationPhoto = 'da <b> Caixa de Padrão'
      //   } else if(!confirmPhotos.relogio) {
      //     validationPhoto = 'do <b>Relógio'
      //   } else if(!confirmPhotos.disjuntor) {
      //     validationPhoto = 'do <b>Disjuntor'
      //   } else if(!confirmPhotos.amperimetro) {
      //     validationPhoto = 'do <b>Amperimetro'
      //   } else if(!confirmPhotos.quadro) {
      //     validationPhoto = 'do <b>Quadro de Distruibuição'
      //   }
      //   return Swal.fire({
      //     title: 'Fotos',
      //     html: `Confirme a foto ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
      //     icon: "error",
      //     showCloseButton: true,
      //     confirmButtonColor: "#F39200",
      //     confirmButtonText: "Ok",
      //   })
      // }
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  }

  // const sendPhoto = (e, type) => {
  //   const ref = e.target.files;
  //   const files = [];
  //   Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
  //   console.log(files)
  //   if(type === 'fachada') {
  //     if(fachada) {
  //       setFachada([...fachada, ...files])
  //     } else {
  //       setFachada(files);
  //     }
  //   } else if(type === 'poste') {
  //     if(poste) {
  //       setPoste([...poste, ...files])
  //     } else {
  //       setPoste(files);
  //     }
  //   } else if(type === 'bengala') {
  //     if(bengala) {
  //       setBengala([...bengala, ...files])
  //     } else {
  //       setBengala(files);
  //     }
  //   } else if(type === 'padrao') {
  //     if(padrao) {
  //       setPadrao([...padrao, ...files])
  //     } else {
  //       setPadrao(files);
  //     }
  //   } else if(type === 'relogio') {
  //     if(relogio) {
  //       setRelogio([...relogio, ...files])
  //     } else {
  //       setRelogio(files);
  //     }
  //   } else if(type === 'disjuntor') {
  //     if(disjuntor) {
  //       setDisjuntor([...disjuntor, ...files])
  //     } else {
  //       setDisjuntor(files);
  //     }
  //   } else if(type === 'amperimetro') {
  //     if(amperimetro) {
  //       setAmperimetro([...amperimetro, ...files])
  //     } else {
  //       setAmperimetro(files);
  //     }
  //   } else if(type === 'quadro') {
  //     if(quadro) {
  //       setQuadro([...quadro, ...files])
  //     } else {
  //       setQuadro(files);
  //     }
  //   }
  // }

  // const deletePhoto = (index, photos, type) => {
  //   console.log(index);
  //   if(index >= 0 && index < photos.length) {
  //     const newPhotos = [...photos];
  //     newPhotos.splice(index, 1);
  //   //   if(newPhotos.length === 0) {
  //   //     setOpenPhoto(false);
  //   //   }
  //   // setPhotos({data: newPhotos, type: photos.type});
  //    switch (type) {
  //     case 'fachada':
  //       setFachada(newPhotos);
  //       break;
  //     case 'poste':
  //       setPoste(newPhotos);
  //       break;
  //     case 'bengala':
  //       setBengala(newPhotos);
  //       break;
  //     case 'padrao':
  //       setPadrao(newPhotos);
  //       break;
  //     case 'relogio':
  //       setRelogio(newPhotos);
  //       break;
  //     case 'disjuntor':
  //       setDisjuntor(newPhotos);
  //       break;
  //     case 'amperimetro':
  //       setAmperimetro(newPhotos);
  //       break;
  //     case 'quadro':
  //       setQuadro(newPhotos);
  //       break;
  //       default:
  //    }
  //   }
  // }

  // const viewPhoto = (photo, type) => {
  //   setPhotos({data: photo, type: type});
  //   setOpenPhoto(true);
  // }

  const sendVisit = async () => {
    if(padrao.tipo.length === 0 || padrao.melhoria.length === 0 || (padrao.melhoria === 'Sim ✅' && padrao.texto.length === 0) || !medidaBitolaRamal || bitolaCliente.medida.length === 0 || bitolaCliente.cabo.length === 0 || 
    (concessionaria !== 'Elektro' && (dps.possui.length === 0 || (dps.possui === 'Sim ✅' && dps.classe.length === 0))) || injecao.length === 0 || quantidadeCaboInversor.length === 0 || trocarDisjuntor.length === 0 || gerador.length === 0) {
      let props = [];
      if(padrao.tipo.length === 0) {
        props = 'Informe o <b>Tipo do Padrão</b>'
      } else if(padrao.melhoria.length === 0) {
        props = 'Informe se o <b>Padrão precisa de Melhoria</b>'
      } else if(padrao.melhoria === 'Sim ✅' && padrao.texto.length === 0) {
        props = 'Informe as <b>melhorias a serem feitas</b>'
      } else if(!medidaBitolaRamal || bitolaCliente.medida.length === 0) {
        props = 'Informe a <b>Medida da Bitola</b>'
      } else if(bitolaCliente.cabo.length === 0) {
        props = 'Informe o tipo do <b>Cabo da Rede do Cliente</b>'
      } else if(dps.possui.length === 0 && concessionaria !== 'Elektro') {
        props = 'Informe se o cliente possui <b>DPS</b>'
      } else if(dps.classe.length === 0 && dps.possui === 'Sim ✅') {
        props = 'Informe qual é a <b>Classe do DPS</b>'
      } else if(injecao.length === 0) {
        props = 'Informe se a energia CA deverá obrigatoriamente ser no <b>Disjuntor Geral</b>'
      }else if(quantidadeCaboInversor.length === 0) {
        props = 'Informe a quantidade de cabo que será utilizado para o <b>Inversor</b>'
      } else if(trocarDisjuntor.length === 0) {
        props = 'Informe se é preciso <b>trocar o Disjuntor</b>'
      } else if(gerador.length === 0) {
        props = 'Informe se existe <b>Gerador no Local</b>'
      }
     return Swal.fire({
        title: 'Atenção',
        html: `${props} para concluir a etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Visita Técnica - Dados',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          //setStep(3)
          // let visita = {};
          // let ref = {};
          setIsLoading(true);

          await updateDoc(doc(dataBase, 'Visitas', visit.id), {
            anotacao_padrao: anotation,
            quantidade_cabo_inversor: quantidadeCaboInversor,
            medida_bitola_ramal: medidaBitolaRamal,
            medida_bitola_cliente: bitolaCliente.medida,
            cabo_bitola_cliente: bitolaCliente.cabo,
            dps: concessionaria === 'Elektro' ? 'Não Obrigatório' : dps.possui,
            dps_classe: dps.possui === 'Sim ✅' ? dps.classe : '',
            dps_troca: dps.possui === 'Sim ✅' ? dps.troca : '',
            trocar_disjuntor: trocarDisjuntor,
            injecao_energia_ca: injecao,
            gerador: gerador,
            condominio: condominio,
            concessionaria: concessionaria,
            padrao: {
              tipo: padrao.tipo,
              melhoria: padrao.melhoria,
              texto: padrao.melhoria === 'Sim ✅' ? padrao.texto : null
            },
          })

          try {
          //  const resultFachada = await uploadImages(fachada, 'fachada', 0)
          //     visita.fachada = resultFachada.map(object => object.downloadURL);
          //     ref.fachada = resultFachada.map(object => object.fullPath);
          //     handleChangeCheck(0);

          //  const resultPoste = await uploadImages(poste, 'poste', 1)
          //     visita.poste = resultPoste.map(object => object.downloadURL);
          //     ref.poste = resultPoste.map(object => object.fullPath);
          //     handleChangeCheck(1);

          //  const resultBengala = await uploadImages(bengala, 'bengala', 2)
          //     visita.bengala = resultBengala.map(object => object.downloadURL);
          //     ref.bengala = resultBengala.map(object => object.fullPath);
          //     handleChangeCheck(2);

          //  const resultPadrao = await uploadImages(padrao, 'padrao', 3)
          //     visita.padrao = resultPadrao.map(object => object.downloadURL);
          //     ref.padrao = resultPadrao.map(object => object.fullPath);
          //     handleChangeCheck(3);

          // const resultRelogio = await uploadImages(relogio, 'relogio', 4)
          //     visita.relogio = resultRelogio.map(object => object.downloadURL);
          //     ref.relogio = resultRelogio.map(object => object.fullPath);
          //     handleChangeCheck(4);

          // const resultDisjuntor = await uploadImages(disjuntor, 'disjuntor', 5)
          //     visita.disjuntor = resultDisjuntor.map(object => object.downloadURL);
          //     ref.disjuntor = resultDisjuntor.map(object => object.fullPath);
          //     handleChangeCheck(5);

          // const resultAmperimetro = await uploadImages(amperimetro, 'amperimetro', 6)
          //     visita.amperimetro = resultAmperimetro.map(object => object.downloadURL);
          //     ref.amperimetro = resultAmperimetro.map(object => object.fullPath);
          //     handleChangeCheck(6);

          // const resultQuadro = await uploadImages(quadro, 'quadro', 7)
          //     visita.quadro = resultQuadro.map(object => object.downloadURL);
          //     ref.quadro = resultQuadro.map(object => object.fullPath);
          //     handleChangeCheck(7);

          
          await updateDoc(doc(dataBase, 'Visitas', visit.id, 'Tecnica', idStep1), { // o idStep1 é criado pela etapa de GPS
                anotacao: anotation,
                injecao_energia_ca: injecao
               }).then(async () => {
                 await updateDoc(doc(dataBase, 'Visitas', visit.id), {
                  representante: {
                    nome: representative.escolhida,
                    id: representative.id
                  }
                });
                
                const nameRepresentative = representative.escolhida === 'Ana Flávia' ? 'Kauana' : 'Ana Flávia';
                const idRepresentative = representative.escolhida === 'Ana Flávia' ? 'K6eQ7aNbB6d6s9R89Dx6K2rFxFB2' : 'FLNgLtdFELPoWV67PnHIMoUeIKC2';
                
                  await updateDoc(doc(dataBase, 'Roleta', 'Analista'), {
                    escolhida: nameRepresentative,
                    id: idRepresentative
                  }).then(async () => {
                    const consultoraDoc = await getDoc(doc(dataBase, 'Membros', visit.uid));
                    const consultoraRef = consultoraDoc.data();
                    console.log(consultoraRef);
                    axios.post('https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/45898/1URWad7DauCi/', {
                      nome: visit.cliente,
                      telefone: consultoraRef.telefone, //consultoraRef.telefone
                      consultora: visit.consultora,
                      concessionaria: concessionaria,
                      analista: representative.escolhida,
                      padrao_tipo: padrao.tipo,
                      padrao_melhoria: padrao.melhoria,
                      padrao_texto: padrao.melhoria === 'Não ❌' ? 'Nenhuma' : padrao.texto,
                      medidaRamal: medidaBitolaRamal,
                      medidaCliente: bitolaCliente.medida,
                      tipoCliente: bitolaCliente.cabo,
                      tecnico: visit.tecnico,
                      dps: dps.possui,
                      dps_classe: dps.classe,
                      dps_troca: dps.troca,
                      energiaCA: injecao,
                      disjuntor_troca: trocarDisjuntor,
                      gerador: gerador,
                      id: visit.id,
                      data: moment(visit.data).format('DD/MM/YYYY')
                    });
                    Swal.fire({
                    title: 'Visita Técnica - Dados',
                    html: `A <b>Etapa</b> foi concluida com sucesso.`,
                    icon: "success",
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                    confirmButtonText: "Ok",
                    })
                    closeBox();
                  });
                })

          } catch (error) {
            console.error('Ocorreu um erro: ', error)
          }
          }
        })
      }
  }

  // const handleChangeCheck= (index) => {
  //   setCheckData((prevCheckData) => {
  //     const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
  //     newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
  //     return newCheckData; // Retorna o novo array atualizado
  //   });
  // }

  return (
    <>
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={closeBox}
    >
      <IconButton
        aria-label="close"
        onClick={closeBox}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Visita Técnica - Dados</DialogTitle>
      <DialogContent className={style.dialog_content}>
        <div className={style.stepper}>
          <Stepper sx={{ width: '100%' }} activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {step < 1 &&
        <div className={style.register_container}>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <b>Confirme os dados do Cliente</b>.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input_double}>
          <TextField
            fullWidth
            margin="dense"
            id="nome"
            label="Nome"
            type="text"
            disabled
            value={visit.cliente ? visit.cliente : ''}
            variant="outlined" />
          <TextField
            fullWidth
            margin="dense"
            id="cidade"
            label="Cidade"
            type="text"
            disabled
            value={visit.cidade ? visit.cidade : ''}
            variant="outlined" />
        </div>
        <FormControl fullWidth margin='dense'>
            <InputLabel id="concessionaria">Concessionária</InputLabel>
              <Select
                labelId='concessionaria'
                label="Concessionária"
                value={concessionaria}
                onChange={(e) => setConcessionaria(e.target.value)}
                required>
                <MenuItem value="CPFL">CPFL</MenuItem>
                <MenuItem value="Cerim">Cerim</MenuItem>
                <MenuItem value="Ceripa">Ceripa</MenuItem>
                <MenuItem value="Cetril">Cetril</MenuItem>
                <MenuItem value="Elektro">Elektro</MenuItem>
                <MenuItem value="Enel">Enel</MenuItem>
                </Select>
        </FormControl>
        </ThemeProvider>
        <ThemeProvider theme={theme2}>
            <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
              🏘️ O local é em <b>Condomínio</b>?
            </DialogContentText>
            <FormControl fullWidth>
            <InputLabel id="disjuntor">Resposta</InputLabel>
              <Select
                labelId='disjuntor'
                label="Resposta"
                value={condominio}
                onChange={(e) => setCondominio(e.target.value)}
                required>
                <MenuItem value="Sim ✅">Sim</MenuItem>
                <MenuItem value="Não ❌">Não</MenuItem>
                </Select>
            </FormControl>
          </ThemeProvider>
      </div>}
      {/* {step === 1 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
              <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 1:</b> Tire foto da fachada do local. <br />
                  Exemplo:
              </DialogContentText>
                <img className={style.img_visit} src={Fachada} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.fachada ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, fachada: true})} 
                startIcon={confirmPhotos.fachada ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.fachada ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
              <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 2:</b> Tire foto do poste inteiro do local. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Poste} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
            <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.poste ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, poste: true})} 
                startIcon={confirmPhotos.poste ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.poste ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
              <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 3:</b> Tire foto da bengala do poste e da entrada do ramal de energia. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Bengala} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
            <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.bengala ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, bengala: true})} 
                startIcon={confirmPhotos.bengala ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.bengala ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
              <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 4:</b> Tire foto da caixa de padrão. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Caixa} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.padrao ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, padrao: true})} 
                startIcon={confirmPhotos.padrao ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.padrao ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
              {analyse === 'Sim ✅' && 
                <Button 
                  disableElevation
                  sx={{ textTransform: 'capitalize' }}
                  variant="contained" 
                  color='warning' 
                  onClick={() => {setOpenAnotation(true)}} 
                  startIcon={<AddCircleOutline />}>
                    Anotação
                </Button>
              }
              {analyse === 'Sim ✅' ? 
                  <Button 
                    disableElevation 
                    sx={{ textTransform: 'capitalize' }}
                    variant="outlined" 
                    color='error' 
                    onClick={() => {setAnalyse('Não ❌')}} 
                    endIcon={<CancelIcon />}>
                    Cancelar Análise
                  </Button> :
                  <Button 
                    disableElevation 
                    sx={{ textTransform: 'capitalize' }} 
                    variant="contained"
                    color='success' 
                    onClick={() => {setAnalyse('Sim ✅')}} 
                    startIcon={<TroubleshootIcon />}>
                      Enviar para Análise
                    </Button>
              }
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
              <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 5:</b> Tire foto do relógio com o número. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Relogio} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.relogio ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, relogio: true})} 
                startIcon={confirmPhotos.relogio ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.relogio ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
            </ThemeProvider>
          </div>
          </div> 
          <div className={style.photo_item}>
              <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 6:</b> Tire foto do disjuntor geral com as especificações. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Disjuntor} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
            <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.disjuntor ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, disjuntor: true})} 
                startIcon={confirmPhotos.disjuntor ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.disjuntor ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 7:</b> Tire foto da medição da voltagem com o Amperimetro. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Amperimetro} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
            <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.amperimetro ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, amperimetro: true})} 
                startIcon={confirmPhotos.amperimetro ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.amperimetro ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 8:</b> Tire foto do quadro de distribuição. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Quadro} alt='' />
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
            <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant='contained' 
                color={confirmPhotos.quadro ? 'success' : 'primary'} 
                onClick={() => setconfirmPhotos({...confirmPhotos, quadro: true})} 
                startIcon={confirmPhotos.quadro ? <CheckIcon /> : <PhotoCameraIcon />}>
                {confirmPhotos.quadro ? 'Foto Confirmada' : 'Confirmar Foto' }
              </Button>
            </ThemeProvider>
          </div>
          </div>
        </div>} */}
        {step === 1 &&
        <div className={style.register_container}>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          Informe o <b>Tipo do Padrão</b> e se precisa de <b>Melhoria</b>.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input_double}>
        <FormControl fullWidth>
          <InputLabel id="bitola">Tipo do Padrão</InputLabel>
          <Select
          labelId='bitola'
          label="Tipo do Padrão"
          value={padrao.tipo}
          onChange={(e) => setPadrao({...padrao, tipo: e.target.value})}
          required>
            <MenuItem value="Monofásico">Monofásico</MenuItem>
            <MenuItem value="Bifásico">Bifásico</MenuItem>
            <MenuItem value="Trifásico">Trifásico</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="bitola">Precisa de Melhoria?</InputLabel>
          <Select
            labelId='bitola'
            label="Precisa de Melhoria?"
            value={padrao.melhoria || ''}
            onChange={(e) => setPadrao({...padrao, melhoria: e.target.value})}
            required>
            <MenuItem value="Sim ✅">Sim</MenuItem>
            <MenuItem value="Não ❌">Não</MenuItem>
          </Select>
        </FormControl>
        </div>
        {padrao.melhoria === 'Sim ✅' ? 
          <div className={style.input_double}>
            <TextField
              fullWidth
              margin="dense"
              label="Digite abaixo as melhorias a serem feitas"
              type="text"
              rows={4}
              multiline
              value={padrao.texto || ''}
              onChange={(e) => setPadrao({...padrao, texto: e.target.value})}
              variant="outlined" />
          </div> : null
        }
        <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
          Informe a <b>Medida da Bitola do Ramal de Entrada</b> e da<b> Rede do Cliente</b>
        </DialogContentText>
        <div className={style.input_double}>
        <FormControl fullWidth>
          <InputLabel id="bitola">Bitola Ramal de Entrada</InputLabel>
          <Select
          labelId='bitola'
          label="Bitola Ramal de Entrada"
          value={medidaBitolaRamal}
          onChange={(e) => setMedidaBitolaRamal(e.target.value)}
          required>
            <MenuItem value="6mm">6mm</MenuItem>
            <MenuItem value="10mm">10mm</MenuItem>
            <MenuItem value="16mm">16mm</MenuItem>
            <MenuItem value="25mm">25mm</MenuItem>
            <MenuItem value="35mm">35mm</MenuItem>
            <MenuItem value="50mm">50mm</MenuItem>
            <MenuItem value="70mm">70mm</MenuItem>
            <MenuItem value="95mm">95mm</MenuItem>
            <MenuItem value="120mm">120mm</MenuItem>
            <MenuItem value="185mm">185mm</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="bitola">Bitola Rede do Cliente</InputLabel>
          <Select
          labelId='bitola'
          label="Bitola Rede do Cliente"
          value={bitolaCliente.medida}
          onChange={(e) => setBitolaCliente({...bitolaCliente, medida: e.target.value})}
          required>
            <MenuItem value="6mm">6mm</MenuItem>
            <MenuItem value="10mm">10mm</MenuItem>
            <MenuItem value="16mm">16mm</MenuItem>
            <MenuItem value="25mm">25mm</MenuItem>
            <MenuItem value="35mm">35mm</MenuItem>
            <MenuItem value="50mm">50mm</MenuItem>
            <MenuItem value="70mm">70mm</MenuItem>
            <MenuItem value="95mm">95mm</MenuItem>
            <MenuItem value="120mm">120mm</MenuItem>
            <MenuItem value="185mm">185mm</MenuItem>
          </Select>
        </FormControl>
        </div>
        <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
          Informe o <b>Tipo do Cabo da Rede do Cliente</b>
        </DialogContentText>
        <div className={style.input_double}>
          <FormControl fullWidth>
            <InputLabel id="bitola">Cabo Rede do Cliente</InputLabel>
            <Select
            labelId='bitola'
            label="Cabo Rede do Cliente"
            value={bitolaCliente.cabo}
            onChange={(e) => setBitolaCliente({...bitolaCliente, cabo: e.target.value})}
            required>
              <MenuItem value="Cobre Rígido">Cobre Rígido</MenuItem>
              <MenuItem value="Cobre Flexível">Cobre Flexível</MenuItem>
              <MenuItem value="Alumínio Multiflexado">Alumínio Multiflexado</MenuItem>
            </Select>
          </FormControl>
        </div>
        {visit && concessionaria !== 'Elektro' && 
        <>
        <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
          O cliente possui <b>DPS</b>?
          </DialogContentText><FormControl fullWidth>
              <InputLabel id="bitola">Resposta</InputLabel>
              <Select
                labelId='bitola'
                label="Resposta"
                value={dps.possui}
                onChange={(e) => setDps({...dps, possui: e.target.value})}
                required>
                <MenuItem value="Sim ✅">Sim</MenuItem>
                <MenuItem value="Não ❌">Não</MenuItem>
              </Select>
            </FormControl>
            {dps.possui === "Sim ✅" ?
            <><DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
              Qual é a classe do <b>DPS</b>?
            </DialogContentText><FormControl fullWidth>
                <InputLabel id="bitola">Resposta</InputLabel>
                <Select
                  labelId='bitola'
                  label="Resposta"
                  value={dps.classe}
                  onChange={(e) => setDps({...dps, classe: e.target.value})}
                  required>
                  <MenuItem value="Classe 1">Classe 1</MenuItem>
                  <MenuItem value="Classe 2">Classe 2</MenuItem>
                </Select>
              </FormControl>
              <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
                É necessário a troca do <b>DPS</b>?
              </DialogContentText><FormControl fullWidth>
                <InputLabel id="dps">Resposta</InputLabel>
                <Select
                  labelId='dps'
                  label="Resposta"
                  value={dps.troca}
                  onChange={(e) => setDps({...dps, troca: e.target.value})}
                  required>
                  <MenuItem value="Sim ✅">Sim</MenuItem>
                  <MenuItem value="Não ❌">Não</MenuItem>
                </Select>
              </FormControl></> : null
            }
              {dps.classe === "Classe 2" && 
                <Stack sx={{ width: '100%'}} margin>
                  <Alert severity="warning" sx={{ display: 'flex', justifyContent: 'center' }}>Será necessário a troca do DPS.</Alert>
                </Stack>
              }
        </>
        }
        <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
          A injeção da Energia CA deverá <b>obrigatoriamente</b> ser no Disjuntor Geral?
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="injeção">Resposta</InputLabel>
            <Select
                      labelId='injeção'
                      label="Resposta"
                      value={injecao}
                      onChange={(e) => setInjecao(e.target.value)}
                      required>
              <MenuItem value="Sim ✅">Sim</MenuItem>
              <MenuItem value="Não ❌">Não</MenuItem>
              </Select>
          </FormControl>
        <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
          Qual é a <b>distância</b> do Inversor até o Padrão?
        </DialogContentText>
          <TextField 
            fullWidth
            label="Quantidade"
            variant='outlined'
            type='number'
            value={quantidadeCaboInversor}
            onChange={(e) => setQuantidadeCaboInversor(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">metros</InputAdornment>,
            }}/>
        <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
          Precisa trocar o <b>Disjuntor</b>?
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="disjuntor">Resposta</InputLabel>
            <Select
                      labelId='disjuntor'
                      label="Resposta"
                      value={trocarDisjuntor}
                      onChange={(e) => setTrocarDisjuntor(e.target.value)}
                      required>
              <MenuItem value="Sim ✅">Sim</MenuItem>
              <MenuItem value="Não ❌">Não</MenuItem>
              </Select>
          </FormControl>
        <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
          Existe <b>Gerador</b> no local?
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="disjuntor">Resposta</InputLabel>
            <Select
                      labelId='disjuntor'
                      label="Resposta"
                      value={gerador}
                      onChange={(e) => setGerador(e.target.value)}
                      required>
              <MenuItem value="Sim ✅">Sim</MenuItem>
              <MenuItem value="Não ❌">Não</MenuItem>
              </Select>
          </FormControl>
        </ThemeProvider>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
        {step > 0 ?
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 2 ? true: false} 
            onClick={() => changeStep('back')}>Voltar</Button> :
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 2 ? true: false} 
            onClick={() => close()}>Fechar</Button>
        }
        {step > 0 ? 
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={isLoading} 
            color='success' 
            onClick={() => sendVisit()}>Concluir</Button> :
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 2 ? true: false} 
            onClick={() => changeStep('go')}>Próximo</Button>
        }
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    {/* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
        {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */}
      <Dialog
      className={style.anotation_container}
      sx={{ padding: '1rem' }}
      open={openAnotation}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenAnotation(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenAnotation(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Anotação</DialogTitle>
        <DialogContent className={style.register_container}>
        <DialogContentText>
          Deixe uma anotação a respeito do <b>Padrão</b>
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            fullWidth
            margin="dense"
            label="Anotação"
            type="text"
            rows={10}
            multiline
            value={anotation}
            onChange={(e) => setAnotation(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button 
              disableElevation 
              variant='contained' 
              sx={{ textTransform: 'capitalize' }}
              onClick={() => setOpenAnotation(false)}>Fechar</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog></>
  )
}

export default RegisterVisitStep1
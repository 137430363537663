import { useEffect, useState } from 'react'
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
// import SlickSlider from "react-slick";
// import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, useTheme } from '@mui/material/styles';
// import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
// import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { addDoc, collection } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
// import useUpload from '../../../../hooks/useUpload';
// import useCircular from '../../../../hooks/useCircular';

const Gps = ({ isLoaded, visit, open, close }) => {
  const theme = useTheme();
  // const slider = useRef(null);
  // const { uploadImages, changeProgress } = useUpload(visit.id);
  // const { CircularProgressWithLabel  } = useCircular();
  const [step, setStep] = useState(0);
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [openCoord, setOpenCoord] = useState(false);
  const [position, setPosition] = useState(0);
  const [newPosition, setNewPosition] = useState(0);
  // const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const [checkData, setCheckData] = useState([false],[false],[false],[false],[false], [false], [false], [false]);

  // const checkPhotos = ['Fotos da Fachada do Local', 'Fotos do Poste Inteiro', 'Fotos da Bengala/Ramal de Energia',
  //  'Fotos da Caixa de Padrão', 'Fotos do Relógio', 'Fotos do Disjuntor', 'Fotos da Medição de Voltagem', 'Fotos do Quadro de Distruibuição'];

  useEffect(() => {
    if(open) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({lat: position.coords.latitude, lng: position.coords.longitude});
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const closeBox = () => {
    close();
    setTimeout(() => {
      setPosition(0);
      setNewPosition(0);
      // setPhotos();
      // setCheckData([false], [false],[false], [false],[false], [false], [false]);
      setStep(0);
    }, 500);
  }

  const onMarkerDragEnd = (event) => {
    const newPos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setPosition(newPos);
  };

  useEffect(() => {
    if(!openCoord) {
      let posRef = {
        lat: 0,
        lng: 0
      }
      posRef.lat = parseFloat(position.lat);
      posRef.lng = parseFloat(position.lng);
      setPosition(posRef);

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openCoord])

  // const changeStep = (direction) => {
  //   if(direction === 'go') {
  //     const CheckFalse = Object.values(confirmPhotos).some(valor => !valor);

  //     if(step === 0 && newPosition !== position ) {
  //       let validationStep1;
  //       if(newPosition !== position) {
  //         validationStep1 = 'Confirme o <b>GPS do Padrão</b>'
  //       } else if(condominio.length === 0) {
  //         validationStep1 = 'Informe se o Local <b>é em Condomínio</b>'
  //       }
  //       return Swal.fire({
  //         title: 'GPS do Padrão',
  //         html: `${validationStep1} para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     } else if (step ===2 && CheckFalse) {
  //       let validationPhoto;
  //       if(!confirmPhotos.fachada) {
  //         validationPhoto = 'da <b>Fachada'
  //       } else if(!confirmPhotos.poste) {
  //         validationPhoto = 'do <b>Poste'
  //       } else if(!confirmPhotos.bengala) {
  //         validationPhoto = 'da <b>Bengala'
  //       } else if(!confirmPhotos.padrao) {
  //         validationPhoto = 'da <b> Caixa de Padrão'
  //       } else if(!confirmPhotos.relogio) {
  //         validationPhoto = 'do <b>Relógio'
  //       } else if(!confirmPhotos.disjuntor) {
  //         validationPhoto = 'do <b>Disjuntor'
  //       } else if(!confirmPhotos.amperimetro) {
  //         validationPhoto = 'do <b>Amperimetro'
  //       } else if(!confirmPhotos.quadro) {
  //         validationPhoto = 'do <b>Quadro de Distruibuição'
  //       }
  //       return Swal.fire({
  //         title: 'Fotos',
  //         html: `Confirme a foto ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     }
  //     setStep(step + 1);
  //   } else {
  //     setStep(step - 1);
  //   }
  // }

  // const sendPhoto = (e, type) => {
  //   const ref = e.target.files;
  //   const files = [];
  //   Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
  //   console.log(files)
  //   if(type === 'fachada') {
  //     if(fachada) {
  //       setFachada([...fachada, ...files])
  //     } else {
  //       setFachada(files);
  //     }
  //   } else if(type === 'poste') {
  //     if(poste) {
  //       setPoste([...poste, ...files])
  //     } else {
  //       setPoste(files);
  //     }
  //   } else if(type === 'bengala') {
  //     if(bengala) {
  //       setBengala([...bengala, ...files])
  //     } else {
  //       setBengala(files);
  //     }
  //   } else if(type === 'padrao') {
  //     if(padrao) {
  //       setPadrao([...padrao, ...files])
  //     } else {
  //       setPadrao(files);
  //     }
  //   } else if(type === 'relogio') {
  //     if(relogio) {
  //       setRelogio([...relogio, ...files])
  //     } else {
  //       setRelogio(files);
  //     }
  //   } else if(type === 'disjuntor') {
  //     if(disjuntor) {
  //       setDisjuntor([...disjuntor, ...files])
  //     } else {
  //       setDisjuntor(files);
  //     }
  //   } else if(type === 'amperimetro') {
  //     if(amperimetro) {
  //       setAmperimetro([...amperimetro, ...files])
  //     } else {
  //       setAmperimetro(files);
  //     }
  //   } else if(type === 'quadro') {
  //     if(quadro) {
  //       setQuadro([...quadro, ...files])
  //     } else {
  //       setQuadro(files);
  //     }
  //   }
  // }

  // const deletePhoto = (index, photos, type) => {
  //   console.log(index);
  //   if(index >= 0 && index < photos.length) {
  //     const newPhotos = [...photos];
  //     newPhotos.splice(index, 1);
  //   //   if(newPhotos.length === 0) {
  //   //     setOpenPhoto(false);
  //   //   }
  //   // setPhotos({data: newPhotos, type: photos.type});
  //    switch (type) {
  //     case 'fachada':
  //       setFachada(newPhotos);
  //       break;
  //     case 'poste':
  //       setPoste(newPhotos);
  //       break;
  //     case 'bengala':
  //       setBengala(newPhotos);
  //       break;
  //     case 'padrao':
  //       setPadrao(newPhotos);
  //       break;
  //     case 'relogio':
  //       setRelogio(newPhotos);
  //       break;
  //     case 'disjuntor':
  //       setDisjuntor(newPhotos);
  //       break;
  //     case 'amperimetro':
  //       setAmperimetro(newPhotos);
  //       break;
  //     case 'quadro':
  //       setQuadro(newPhotos);
  //       break;
  //       default:
  //    }
  //   }
  // }

  // const viewPhoto = (photo, type) => {
  //   setPhotos({data: photo, type: type});
  //   setOpenPhoto(true);
  // }

  const sendVisit = () => {
    if(step === 0 && newPosition !== position ) {
      let validationStep1;
      if(newPosition !== position) {
        validationStep1 = 'Confirme o <b>GPS do Padrão</b>'
      }
      return Swal.fire({
        title: 'GPS do Padrão',
        html: `${validationStep1} para prosseguir para a próxima etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#F39200",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Visita Técnica - GPS',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          // let visita = {};
          // let ref = {};

          try {
          //  const resultFachada = await uploadImages(fachada, 'fachada', 0)
          //     visita.fachada = resultFachada.map(object => object.downloadURL);
          //     ref.fachada = resultFachada.map(object => object.fullPath);
          //     handleChangeCheck(0);

          //  const resultPoste = await uploadImages(poste, 'poste', 1)
          //     visita.poste = resultPoste.map(object => object.downloadURL);
          //     ref.poste = resultPoste.map(object => object.fullPath);
          //     handleChangeCheck(1);

          //  const resultBengala = await uploadImages(bengala, 'bengala', 2)
          //     visita.bengala = resultBengala.map(object => object.downloadURL);
          //     ref.bengala = resultBengala.map(object => object.fullPath);
          //     handleChangeCheck(2);

          //  const resultPadrao = await uploadImages(padrao, 'padrao', 3)
          //     visita.padrao = resultPadrao.map(object => object.downloadURL);
          //     ref.padrao = resultPadrao.map(object => object.fullPath);
          //     handleChangeCheck(3);

          // const resultRelogio = await uploadImages(relogio, 'relogio', 4)
          //     visita.relogio = resultRelogio.map(object => object.downloadURL);
          //     ref.relogio = resultRelogio.map(object => object.fullPath);
          //     handleChangeCheck(4);

          // const resultDisjuntor = await uploadImages(disjuntor, 'disjuntor', 5)
          //     visita.disjuntor = resultDisjuntor.map(object => object.downloadURL);
          //     ref.disjuntor = resultDisjuntor.map(object => object.fullPath);
          //     handleChangeCheck(5);

          // const resultAmperimetro = await uploadImages(amperimetro, 'amperimetro', 6)
          //     visita.amperimetro = resultAmperimetro.map(object => object.downloadURL);
          //     ref.amperimetro = resultAmperimetro.map(object => object.fullPath);
          //     handleChangeCheck(6);

          // const resultQuadro = await uploadImages(quadro, 'quadro', 7)
          //     visita.quadro = resultQuadro.map(object => object.downloadURL);
          //     ref.quadro = resultQuadro.map(object => object.fullPath);
          //     handleChangeCheck(7);
          
              await addDoc(collection(dataBase, 'Visitas', visit.id, 'Tecnica'), {
                etapa: 1,
                GPS_padrao: newPosition
               }).then(async () => {
                  Swal.fire({
                  title: 'Visita Técnica - GPS',
                  html: `A <b>Etapa</b> foi concluida com sucesso.`,
                  icon: "success",
                  showCloseButton: true,
                  confirmButtonColor: "#F39200",
                  confirmButtonText: "Ok",
                  }).then(() => {
                    closeBox();
                  })
                  close();
                })

          } catch (error) {
            console.error('Ocorreu um erro: ', error)
          }
          }
        })
      }
  }

  // const handleChangeCheck= (index) => {
  //   setCheckData((prevCheckData) => {
  //     const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
  //     newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
  //     return newCheckData; // Retorna o novo array atualizado
  //   });
  // }

  console.log(position)

  return (
    <>
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={close}
    >
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Visita Técnica - GPS</DialogTitle>
      <DialogContent className={style.dialog_content}>
      {step === 0 &&
        <div className={style.register_container}>
          <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
            Confirme a localização do <b>GPS</b> do Padrão.
          </DialogContentText>
          {isLoaded &&
            <GoogleMap key={`${openCoord}`} mapContainerStyle={{ width: '100%', height: '300px' }} zoom={18} center={{ lat: position.lat, lng: position.lng }}>
              {position && <MarkerF key={`${openCoord}`} position={{ lat: position.lat, lng: position.lng }} draggable={true} onDragEnd={onMarkerDragEnd} />}
            </GoogleMap>
          }
          <div>
            {position !== newPosition ?
              <Button 
                disableElevation
                variant='contained' 
                color='success' 
                sx={{ maxWidth: '9rem', margin: '1rem', textTransform: 'capitalize' }} 
                onClick={() => setNewPosition(position)}>Confirmar</Button> :
              <Button 
              color='success' 
              sx={{ maxWidth: '9rem', margin: '1rem', textTransform: 'capitalize' }} 
              variant='outlined' 
              endIcon={<CheckCircleOutlineIcon />}>Confirmado</Button>
            }
            <Button 
                disableElevation
                variant='contained' 
                color='primary' 
                sx={{ maxWidth: '9rem', margin: '1rem', textTransform: 'capitalize' }} 
                onClick={() => setOpenCoord(true)}>Alterar Coord</Button>
          </div>
        </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            onClick={() => close()}>Fechar</Button>
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            color='success' 
            onClick={() => sendVisit()}>Concluir</Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    {/* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
        {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */}
      <Dialog
      className={style.anotation_container}
      sx={{ padding: '1rem' }}
      open={openCoord}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenCoord(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenCoord(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Coordenadas</DialogTitle>
        <DialogContent className={style.register_container}>
        <DialogContentText align='center' margin='0 0 1rem 0'>
          Digite abaixo a <b>Latitude</b> e <b>Longitude</b> da localização. <br /> 
          <b>Exemplo: Latitude: -23.545776 | Longitude: -47.656544 </b>
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input_double}>
          <TextField
            fullWidth
            margin="dense"
            label="Latitude"
            type="text"
            value={position.lat || ''}
            onChange={(e) => setPosition({...position, lat: e.target.value})}
            variant="outlined" />
          <TextField
            fullWidth
            margin="dense"
            label="Longitude"
            type="text"
            value={position.lng || ''}
            onChange={(e) => setPosition({...position, lng: e.target.value})}
            variant="outlined" />
        </div>
        </ThemeProvider>
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button 
              disableElevation 
              variant='contained' 
              sx={{ textTransform: 'capitalize' }}
              onClick={() => setOpenCoord(false)}>Fechar</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog></>
  )
}

export default Gps
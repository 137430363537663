import { useState, useRef } from 'react'
import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
import SlickSlider from "react-slick";
// import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
import CheckIcon from '@mui/icons-material/Check';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

import Fachada from '../../../../images/visit/fachada.jpeg';
import Poste from '../../../../images/visit/poste.jpeg';
import Bengala from '../../../../images/visit/bengala.jpeg';
import Caixa from '../../../../images/visit/caixa.jpeg';
import Relogio from '../../../../images/visit/disjuntor.jpeg';
import Disjuntor from '../../../../images/visit/disjuntor_especificacao.jpeg';
import Amperimetro from '../../../../images/visit/amperimetro.jpeg';
import Quadro from '../../../../images/visit/quadro.jpg';
import LocalInversor from '../../../../images/visit/local_inversor.jpg';

import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';

const RegisterVisitStep15 = ({ visit, open, idStep1, close }) => {
  const theme = useTheme();
  const slider = useRef(null);
  const [analyse, setAnalyse] = useState('Não ❌');
  const { uploadImages, changeProgress } = useUpload(visit.id);
  const { CircularProgressWithLabel  } = useCircular();
  const [step, setStep] = useState(0);
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [openAnotation, setOpenAnotation] = useState(false);
  const [anotation, setAnotation] = useState('');
  const [fachada, setFachada] = useState([]);
  const [poste, setPoste] = useState([]);
  const [bengala, setBengala] = useState([]);
  const [padrao, setPadrao] = useState([]);
  const [relogio, setRelogio] = useState([]);
  const [disjuntor, setDisjuntor] = useState([]);
  const [amperimetro, setAmperimetro] = useState([]);
  const [quadro, setQuadro] = useState([]);
  const [localInversor, setLocalInversor] = useState([]);

  // const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false],[false],[false],[false],[false], [false], [false], [false], [false]);

  const checkPhotos = ['Fotos da Fachada do Local', 'Fotos do Poste Inteiro', 'Fotos da Bengala/Ramal de Energia',
   'Fotos da Caixa de Padrão', 'Fotos do Relógio', 'Fotos do Disjuntor', 'Fotos da Medição de Voltagem', 'Fotos do Quadro de Distruibuição', 'Fotos do Local do Inversor'];

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const closeBox = () => {
    close();
    setTimeout(() => {
      setFachada([]);
      setPoste([]);
      setBengala([]);
      setPadrao([]);
      setRelogio([]);
      setDisjuntor([]);
      setAmperimetro([]);
      setQuadro([]);
      // setPhotos();
      setCheckData([false], [false],[false], [false],[false], [false], [false]);
      setStep(0);
    }, 500);
  }

  console.log(visit)

  // const changeStep = (direction) => {
  //   if(direction === 'go') {
  //     if(fachada.length === 0 || poste.length === 0 || bengala.length === 0 || 
  //       padrao.length === 0 || relogio.length === 0 || disjuntor.length === 0 || amperimetro.length === 0 || quadro.length === 0) {
  //       let validationPhoto;
  //       if(fachada.length === 0) {
  //         validationPhoto = 'da <b>Fachada'
  //       } else if(poste.length === 0) {
  //         validationPhoto = 'do <b>Poste'
  //       } else if(bengala.length === 0) {
  //         validationPhoto = 'da <b>Bengala'
  //       } else if(padrao.length === 0) {
  //         validationPhoto = 'da <b> Caixa de Padrão'
  //       } else if(relogio.length === 0) {
  //         validationPhoto = 'do <b>Relógio'
  //       } else if(disjuntor.length === 0) {
  //         validationPhoto = 'do <b>Disjuntor'
  //       } else if(amperimetro.length === 0) {
  //         validationPhoto = 'do <b>Amperimetro'
  //       } else if(quadro.length === 0) {
  //         validationPhoto = 'do <b>Quadro de Distruibuição'
  //       }
  //       return Swal.fire({
  //         title: 'Fotos',
  //         html: `Envie a foto ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     }
  //     setStep(step + 1);
  //   } else {
  //     setStep(step - 1);
  //   }
  // }

  console.log(fachada[0])

  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    console.log(files)
    if(type === 'fachada') {
      if(fachada) {
        setFachada([...fachada, ...files])
      } else {
        setFachada(files);
      }
    } else if(type === 'poste') {
      if(poste) {
        setPoste([...poste, ...files])
      } else {
        setPoste(files);
      }
    } else if(type === 'bengala') {
      if(bengala) {
        setBengala([...bengala, ...files])
      } else {
        setBengala(files);
      }
    } else if(type === 'padrao') {
      if(padrao) {
        setPadrao([...padrao, ...files])
      } else {
        setPadrao(files);
      }
    } else if(type === 'relogio') {
      if(relogio) {
        setRelogio([...relogio, ...files])
      } else {
        setRelogio(files);
      }
    } else if(type === 'disjuntor') {
      if(disjuntor) {
        setDisjuntor([...disjuntor, ...files])
      } else {
        setDisjuntor(files);
      }
    } else if(type === 'amperimetro') {
      if(amperimetro) {
        setAmperimetro([...amperimetro, ...files])
      } else {
        setAmperimetro(files);
      }
    } else if(type === 'quadro') {
      if(quadro) {
        setQuadro([...quadro, ...files])
      } else {
        setQuadro(files);
      }
    } else if(type === 'localInversor') {
      if(localInversor) {
        setLocalInversor([...localInversor, ...files])
      } else {
        setLocalInversor(files);
      }
    }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
    //   if(newPhotos.length === 0) {
    //     setOpenPhoto(false);
    //   }
    // setPhotos({data: newPhotos, type: photos.type});
     switch (type) {
      case 'fachada':
        setFachada(newPhotos);
        break;
      case 'poste':
        setPoste(newPhotos);
        break;
      case 'bengala':
        setBengala(newPhotos);
        break;
      case 'padrao':
        setPadrao(newPhotos);
        break;
      case 'relogio':
        setRelogio(newPhotos);
        break;
      case 'disjuntor':
        setDisjuntor(newPhotos);
        break;
      case 'amperimetro':
        setAmperimetro(newPhotos);
        break;
      case 'quadro':
        setQuadro(newPhotos);
        break;
      case 'localInversor':
        setLocalInversor(newPhotos);
        break;
        default:
     }
    }
  }

  // const viewPhoto = (photo, type) => {
  //   setPhotos({data: photo, type: type});
  //   setOpenPhoto(true);
  // }

  const sendVisit = () => {
    if(fachada.length === 0 || poste.length === 0 || bengala.length === 0 || 
      padrao.length === 0 || relogio.length === 0 || disjuntor.length === 0 || amperimetro.length === 0 || quadro.length === 0 || localInversor.length === 0) {
      let validationPhoto;
      if(fachada.length === 0) {
        validationPhoto = 'da <b>Fachada'
      } else if(poste.length === 0) {
        validationPhoto = 'do <b>Poste'
      } else if(bengala.length === 0) {
        validationPhoto = 'da <b>Bengala'
      } else if(padrao.length === 0) {
        validationPhoto = 'da <b> Caixa de Padrão'
      } else if(relogio.length === 0) {
        validationPhoto = 'do <b>Relógio'
      } else if(disjuntor.length === 0) {
        validationPhoto = 'do <b>Disjuntor'
      } else if(amperimetro.length === 0) {
        validationPhoto = 'do <b>Amperimetro'
      } else if(quadro.length === 0) {
        validationPhoto = 'do <b>Quadro de Distruibuição'
      } else if(localInversor.length === 0) {
        validationPhoto = 'do <b>Local do Inversor'
      }
      return Swal.fire({
        title: 'Fotos',
        html: `Envie a foto ${validationPhoto}</b> para concluir a etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Visita Técnica - Fotos (Solo)',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          setStep(1)
          let visita = {};
          let ref = {};

          try {
           const resultFachada = await uploadImages(fachada, 'fachada', 0)
              visita.fachada = resultFachada.map(object => object.downloadURL);
              ref.fachada = resultFachada.map(object => object.fullPath);
              handleChangeCheck(0);

           const resultPoste = await uploadImages(poste, 'poste', 1)
              visita.poste = resultPoste.map(object => object.downloadURL);
              ref.poste = resultPoste.map(object => object.fullPath);
              handleChangeCheck(1);

           const resultBengala = await uploadImages(bengala, 'bengala', 2)
              visita.bengala = resultBengala.map(object => object.downloadURL);
              ref.bengala = resultBengala.map(object => object.fullPath);
              handleChangeCheck(2);

           const resultPadrao = await uploadImages(padrao, 'padrao', 3)
              visita.padrao = resultPadrao.map(object => object.downloadURL);
              ref.padrao = resultPadrao.map(object => object.fullPath);
              handleChangeCheck(3);

          const resultRelogio = await uploadImages(relogio, 'relogio', 4)
              visita.relogio = resultRelogio.map(object => object.downloadURL);
              ref.relogio = resultRelogio.map(object => object.fullPath);
              handleChangeCheck(4);

          const resultDisjuntor = await uploadImages(disjuntor, 'disjuntor', 5)
              visita.disjuntor = resultDisjuntor.map(object => object.downloadURL);
              ref.disjuntor = resultDisjuntor.map(object => object.fullPath);
              handleChangeCheck(5);

          const resultAmperimetro = await uploadImages(amperimetro, 'amperimetro', 6)
              visita.amperimetro = resultAmperimetro.map(object => object.downloadURL);
              ref.amperimetro = resultAmperimetro.map(object => object.fullPath);
              handleChangeCheck(6);

          const resultQuadro = await uploadImages(quadro, 'quadro', 7)
              visita.quadro = resultQuadro.map(object => object.downloadURL);
              ref.quadro = resultQuadro.map(object => object.fullPath);
              handleChangeCheck(7);

          const resultLocalInversor = await uploadImages(localInversor, 'localInversor', 8)
              visita.localInversor = resultLocalInversor.map(object => object.downloadURL);
              ref.localInversor = resultLocalInversor.map(object => object.fullPath);
              handleChangeCheck(8);

              await updateDoc(doc(dataBase, "Visitas", visit.id), {
                analise_padrao: analyse,
                analise_status: analyse === 'Sim ✅' ? 'Aberto' : 'Fechado'
              })
          
              await updateDoc(doc(dataBase, 'Visitas', visit.id, 'Tecnica', idStep1), {
                ...visita,
                ref: ref
               }).then(() => {
                  Swal.fire({
                  title: 'Visita Técnica - Fotos (Solo)',
                  html: `A <b>Etapa</b> foi concluida com sucesso.`,
                  icon: "success",
                  showCloseButton: true,
                  confirmButtonColor: "#111",
                  confirmButtonText: "Ok",
                  }).then(() => {
                    closeBox();
                  })
                  close();
                })

          } catch (error) {
            console.error('Ocorreu um erro: ', error)
          }
          }
        })
      }
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  return (
    <>
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={close}
    >
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Visita Técnica - Fotos (Solo)</DialogTitle>
      <DialogContent className={style.dialog_content}>
      {step === 0 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
            {fachada && fachada.length === 0 ? 
                <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Tire foto da fachada do local. <br />
                    Exemplo:
                  </DialogContentText>
                  <img className={style.img_visit} src={Fachada} alt='' /></> : 
                <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                    <b>Passo 1:</b> Tire foto da fachada do local. <br />
                  </DialogContentText><div className={style.slider_container}>
                      <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                        {fachada && fachada.map((img, index) => (
                          <div key={index} className={style.photos}>
                            <img rel="preconnect" src={img.file} alt="" />
                            <p>{img.complete.name}</p>
                            <Button 
                              disableElevation 
                              startIcon={<DeleteForeverIcon />}
                              variant='contained' 
                              color='error' 
                              sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                              onClick={() => deletePhoto(index, fachada, 'fachada')}>Excluir</Button>
                          </div>
                        ))}
                      </SlickSlider>
                    </div></>
              }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'fachada')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {poste && poste.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 2:</b> Tire foto do poste inteiro do local. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Poste} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Tire foto do poste inteiro do local. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {poste && poste.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, poste, 'poste')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'poste')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {bengala && bengala.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 3:</b> Tire foto da bengala do poste e da entrada do ramal de energia. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Bengala} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Tire foto da bengala do poste e da entrada do ramal de energia. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {bengala && bengala.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, bengala, 'bengala')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
            <Button 
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'bengala')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {padrao && padrao.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 4:</b> Tire foto da caixa de padrão. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Caixa} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 4:</b> Tire foto da caixa de padrão. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {padrao && padrao.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                          }
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, padrao, 'padrao')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'padrao')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg,video/*" />
              </Button>
              {analyse === 'Sim ✅' && 
                <Button 
                  disableElevation
                  sx={{ textTransform: 'capitalize' }}
                  variant="contained" 
                  color='warning' 
                  onClick={() => {setOpenAnotation(true)}} 
                  startIcon={<AddCircleOutline />}>
                    Anotação
                </Button>
              }
              {analyse === 'Sim ✅' ? 
                  <Button 
                    disableElevation 
                    sx={{ textTransform: 'capitalize' }}
                    variant="outlined" 
                    color='error' 
                    onClick={() => {setAnalyse('Não ❌')}} 
                    endIcon={<CancelIcon />}>
                    Cancelar Análise
                  </Button> :
                  <Button 
                    disableElevation 
                    sx={{ textTransform: 'capitalize' }} 
                    variant="contained"
                    color='success' 
                    onClick={() => {setAnalyse('Sim ✅')}} 
                    startIcon={<TroubleshootIcon />}>
                      Enviar para Análise
                    </Button>
              }
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {relogio && relogio.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 5:</b> Tire foto do relógio com o número. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Relogio} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 5:</b> Tire foto do relógio com o número. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {relogio && relogio.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, relogio, 'relogio')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained"
                onChange={(e) => sendPhoto(e, 'relogio')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div> 
          <div className={style.photo_item}>
          {disjuntor && disjuntor.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 6:</b> Tire foto do disjuntor geral com as especificações. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Disjuntor} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 6:</b> Tire foto do disjuntor geral com as especificações. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {disjuntor && disjuntor.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, disjuntor, 'disjuntor')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'disjuntor')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {amperimetro && amperimetro.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 7:</b> Tire foto da medição da voltagem com o Amperimetro. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Amperimetro} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 7:</b> Tire foto da medição da voltagem com o Amperimetro. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {amperimetro && amperimetro.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, amperimetro, 'amperimetro')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'amperimetro')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {quadro && quadro.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 8:</b> Tire foto do quadro de distribuição. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Quadro} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 8:</b> Tire foto do quadro de distribuição. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {quadro && quadro.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, quadro, 'quadro')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
          }
          <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'quadro')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
          {localInversor && localInversor.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 9:</b> Tire uma foto do Local aonde será instalado o Inversor. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={LocalInversor} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 9:</b> Tire uma foto do Local aonde será instalado o Inversor. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {localInversor && localInversor.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, localInversor, 'localInversor')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
          }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button
                disableElevation
                component="label" 
                sx={{ textTransform: 'capitalize' }}
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'localInversor')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
        </div>}
      {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
            {checkPhotos.map((data, index) => (
              <li>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} /> 
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            onClick={() => close()}>Fechar</Button>
          <Button 
            disableElevation
            variant='contained' 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            color='success' 
            onClick={() => sendVisit()}>Concluir</Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    {/* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
        {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */}
      <Dialog
      className={style.anotation_container}
      sx={{ padding: '1rem' }}
      open={openAnotation}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenAnotation(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenAnotation(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Anotação</DialogTitle>
        <DialogContent className={style.register_container}>
        <DialogContentText>
          Deixe uma anotação a respeito do <b>Padrão</b>
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            fullWidth
            margin="dense"
            label="Anotação"
            type="text"
            rows={10}
            multiline
            value={anotation}
            onChange={(e) => setAnotation(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button 
              disableElevation 
              variant='contained' 
              sx={{ textTransform: 'capitalize' }}
              onClick={() => setOpenAnotation(false)}>Fechar</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog></>
  )
}

export default RegisterVisitStep15
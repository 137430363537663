import React, { createContext, useContext, useState } from 'react';

// Criando o contexto
const CadastroContext = createContext();

// Componente provedor que envolve toda a aplicação e fornece os dados do cadastro
export const CadastroProvider = ({ children }) => {
  const [cadastro, setCadastro] = useState(null);

  // Função para atualizar os dados do cadastro
  const atualizarCadastro = (novosDados) => {
    setCadastro(novosDados);
  };

  return (
    <CadastroContext.Provider value={{ cadastro, atualizarCadastro }}>
      {children}
    </CadastroContext.Provider>
  );
};

// Hook personalizado para acessar o contexto
export const useCadastro = () => {
  const context = useContext(CadastroContext);
  if (!context) {
    throw new Error('useCadastro deve ser usado dentro de um CadastroProvider');
  }
  return context;
};

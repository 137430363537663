/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { doc, onSnapshot, collection, getDoc, updateDoc } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import moment from "moment";
import { theme } from "../../../data/theme";
import styles from "./styles.module.scss";

import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from "@emotion/react";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// import InstallStep1 from "./Components/Install/Step1";
import CleanerStep1 from "../Components/Service/Step1";
import CleanerStep2 from "../Components/Service/Step2";
// import InstallStep4 from "./Components/Install/Step4";

import Logo from "../../../images/LogoCORPBRASIL.png";
import { theme2 } from "../../../data/theme";
import File from "../../../components/VerifyURL/Index";
import Swal from "sweetalert2";
import axios from "axios";

const EstimateCleaner = ({ userRef }) => {
  const [relatorio, setRelatorio] = useState('Relatório Contrato');
  const [steps, setSteps] = useState([]);
  // const [supplier, setSupplier] = useState([]);
  const [checkStep, setCheckStep] = useState({
    step1: false,
    step2: false
  });
  const [info, setInfo] = useState();
  const refObservation  = useRef({telhado: '', kit: '', instalacao: '', servico: ''});
  const [observation, setObservation] = useState({telhado: '', kit: '', instalacao: '', servico: ''});
  const [bitola, setBitola] = useState({ramal: '', cliente: '', cabo: ''});
  const [kitInfo, setKitInfo] = useState({fornecedor: '', valorKit: '', custoInstalacao: '', valorVenda: '', potenciaInversor: '', quantidadePlacas: '',
  potenciaPlacas: '', tipoEstrutura: ''});
  const [redeInfo, setRedeInfo] = useState({dps: '', energiaCA: '', disjuntor: ''});
  const [openObservation, setOpenObservation] = useState(false);
  const [openAnotation, setOpenAnotation] = useState(false);
  const [photosStep2, setPhotosStep2] = useState();
  // const [photosStep5, setPhotosStep5] = useState(); // Conferência do Kit Solar
  const [step1, setStep1] = useState({}); // Antes da Limpeza
  const [step2, setStep2] = useState({}); // Depois da Limpeza
  // const [photosStep8, setPhotosStep8] = useState(); // Instalação Finalizada
  // const [openPhoto, setopenPhoto] = useState(
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false]
  // );
  // const [openPhotoInstall, setopenPhotoInstall] = useState(
  //   [false],
  //   [false],
  //   [false], 
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false]
  // );
  // const [quadro, setQuadro] = useState();
  // const [amperimetro, setAmperimetro] = useState();
  // const [bengala, setBengala] = useState();
  // const [disjuntor, setDisjuntor] = useState();
  const [photosStep1, setPhotosStep1] = useState();
  // const [padrao, setPadrao] = useState();
  // const [poste, setPoste] = useState();
  // const [relogio, setRelogio] = useState();
  // const [kit, setKit] = useState();
  const [anotation, setAnotation] = useState();
  const [address, setAddress] = useState();
  const [openEdit, setOpenEdit] = useState({ address: false, bitola_ramal: false, bitola_cliente: false, fornecedor: false, valorKit: false, custoInstalacao: false, valorVenda: false,
  potenciaInversor: false, quantidadePlacas: false, potenciaPlacas: false, tipoEstrutura: false, dps: false, energiaCA: false, disjuntor: false });
  const [OpenInstallStep, setOpenInstallStep] = useState('0');
  const [cleaner, setCleaner] = useState([]);
  // const [docs, setDocs] = useState([]);
  let { idCleaner } = useParams();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const stepCollectionRef = collection(
    dataBase,
    "Limpezas",
    idCleaner,
    "Etapas"
  );


  useEffect(() => {
    const getVisit = async () => {
      onSnapshot(doc(dataBase, "Limpezas", idCleaner), (doc) => {
        setCleaner({...doc.data(), id: doc.id});
      });
      onSnapshot(await stepCollectionRef, (ref) => {
        // Atualiza os dados em tempo real
        setSteps(ref.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
      });
      // onSnapshot(await supplierCollectionRef, (sup) => {
      //   // Atualiza os dados em tempo real
      //   setSupplier(sup.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
      // });
    };
    getVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(cleaner)
  console.log(idCleaner)

  useEffect(() => {
    if(cleaner) {
      document.title = cleaner.nome ? cleaner.nome + ' - Limpeza - CORPBRASIL Energia Solar' : 'Limpeza - CORPBRASIL Energia Solar';
    }
  },[cleaner])

  useEffect(() => {
    if(steps) {
      setStep1(steps.find(data => data.etapa === 1))
      setStep2(steps.find(data => data.etapa === 2))
    }
  },[steps])

  console.log(steps)

  return (
    <div className={styles.pdf_main}>
    <div id="PDF" className={styles.pdf}>
      <div className={styles.pdf_header}>
        <img className={styles.pdf_logo} src={Logo} alt="CORPBRASIL" />
        {/* <h1>Anexo I</h1> */}
        <h1>Limpeza</h1>
        <h2>{cleaner.nome}</h2>
      </div>
      <div className={styles.pdf_item_container}>
        <h2>Informações</h2>
        <ul className={styles.pdf_text}>
        <li>
            <b>Data Agendado:</b> 
            {
              cleaner.terceirizado?.data ? 
              `${moment(cleaner.terceirizado?.data).format('DD/MM/YYYY')} ás ${cleaner.terceirizado?.horario}` : 
              'Não informado'
            }
          </li>
          <li>
            <b>Cidade:</b> {cleaner.cidade ? cleaner.cidade : 'Carregando'}
          </li>
          <li>
            <b>Analista:</b> {cleaner.analista ? cleaner.analista?.nome: 'Carregando'}
          </li>
          <li>
           <b>Endereço do Cliente:</b>
              {openEdit && openEdit.address ? 
                <><input
                  type="text"
                  className={styles.input_address}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Endereço"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    // onClick={() => confirmEdit('Address')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    // onClick={() => closeEdit('Address')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {cleaner && cleaner.endereco}
                  {cleaner ?
                    <><a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${cleaner && cleaner.lat},${cleaner && cleaner.lng}`}> Clique aqui para visualizar o GPS</a>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Endereço"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        // onClick={() => changeOpenEdit('Address')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>
              }
          </li>
          <li>
            <p>
              <b>Mora em Condomínio? </b> {cleaner && cleaner.condominio ? cleaner.condominio : 'Não Informado'} 
            </p>
            {cleaner && cleaner.condominio === 'Sim' && 
              <p><b>Horário:</b> {cleaner.horario && cleaner.horario.entrada} - {cleaner && cleaner.horario.saida}</p>         
            }
          </li>
        </ul>
      </div>
      <div className={styles.pdf_item_container}>
        <h2>Telhado</h2>
          {cleaner &&
          <ul className={styles.pdf_text}>
            <li>
              <b>Altura da Casa:</b> {cleaner.altura ? cleaner.altura : 'Não Informado'}
            </li>
              <div>
              <li>
                <b>Telha:</b> {cleaner.telha}
              </li>
              </div>
          </ul>}
      </div>
      {/* <h1>Fotos</h1> */}
      <div className={styles.pdf_item_container}>
        <h2>Fotos & Vídeos</h2>
        <div className={styles.photo_content}>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Fachada</h2>
            </div>
              <div className={styles.photos}>
                {cleaner.fachada && cleaner.fachada.length > 0 ? 
                  cleaner.fachada.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  )) : <p className={styles.pdf_alert}>Não informado</p>}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Módulos</h2>
            </div>
            <div className={styles.photos}>
              {cleaner.modulos && cleaner.modulos.length > 0 ?
                cleaner.modulos.map((photo, index) => (
                  <img
                    key={index}
                    className={styles.img_pdf}
                    rel="preconnect"
                    src={photo}
                    alt=""
                  />
                  )) : <p className={styles.pdf_alert}>Não informado</p>
              }
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Telhado</h2>
            </div>
            <div className={styles.photos}>
            {cleaner.telhado ? 
              cleaner.telhado.map((photo, index) => (
                <img
                  key={index}
                  className={styles.img_pdf}
                  rel="preconnect"
                  src={photo}
                  alt=""
              />
            )) : <p className={styles.pdf_alert}>Não informado</p>}
            {cleaner.telhadoVideo ? 
              cleaner.telhadoVideo.map((photo, index) => (
                <><video
                      className={`${styles.img_pdf} ${styles.print}`}
                      width="100%"
                      controls
                      muted
                      playsInline
                    >
                      <source src={photo} type="video/mp4" />
                      <source src={photo} type="video/webm" />
                      <source src={photo} type="video/ogg" />
                    </video>
                  <a href={photo} target="_blank" rel="noreferrer" className={`${styles.view_link} ${styles.print_on}`}>Clique aqui para visualizar o Video do telhado</a></>
            )) : <p className={styles.pdf_alert}>Não informado</p>}
        </div>
          </div>
          {/* <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(1)}>
              <h2>Poste</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[1] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[1]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {poste &&
                  poste.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {poste &&
                  poste.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(2)}>
              <h2>Bengala e Entrada do Ramal de Energia</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[2] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[2]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {bengala &&
                  bengala.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {bengala &&
                  bengala.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(3)}>
              <h2>Caixa do Padrão</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[3] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[3]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {padrao &&
                  padrao.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {padrao &&
                  padrao.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(4)}>
              <h2>Relógio</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[4] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[4]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {relogio &&
                  relogio.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {relogio &&
                  relogio.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(5)}>
              <h2>Disjuntor</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[5] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[5]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {disjuntor &&
                  disjuntor.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {disjuntor &&
                  disjuntor.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(6)}>
              <h2>Amperímetro</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[6] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[6]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {amperimetro &&
                  amperimetro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {amperimetro &&
                  amperimetro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(7)}>
              <h2>Quadro de Distribuição</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[6] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[7]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {quadro &&
                  quadro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {quadro &&
                  quadro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div> */}
        </div>
        {/* <h2>Telhado</h2>
        <div className={styles.photo_content}>
          {photosStep2 &&
            photosStep2.map((photo, index) => (
              <div key={index}>
                {photo.type === "photo" ? (
                  <img
                    className={styles.img_pdf}
                    rel="preconnect"
                    src={`${photo.url}`}
                    alt=""
                  />
                ) : (
                  <><video
                      className={`${styles.img_pdf} ${styles.print}`}
                      width="100%"
                      controls
                      muted
                      playsInline
                    >
                      <source src={photo.url} type="video/mp4" />
                    </video><a href={photo.url} target="_blank" rel="noreferrer" className={`${styles.view_link} ${styles.print_on}`}>Clique aqui para visualizar o Video do telhado</a></>
                )}
              </div>
            ))}
        </div>
        <h2>Telhado com Medida</h2>
        <div className={styles.photo_content}>
          {photosStep3 &&
            photosStep3.map((photo, index) => (
              <img
                key={index}
                className={styles.img_pdf}
                rel="preconnect"
                src={photo}
                alt=""
              />
            ))}
        </div>
        <h2>Projeto</h2>
        <div className={styles.photo_content}>
          {photosStep4 ? (
            photosStep4.map((photo, index) => (
              <img
                key={index}
                className={styles.img_pdf}
                rel="preconnect"
                src={photo}
                alt=""
              />
            ))
          ) : (
            <p className={styles.pdf_alert}>Aguardando o Pós-Venda</p>
          )}
        </div> */}
        <h2>Observação sobre o Telhado</h2>
        <div className={styles.obs_container}>
          {openObservation && openObservation.telhado ? 
          <div className={styles.create_observation}>
            <ThemeProvider theme={theme2}>
              <h3>Observação sobre o Telhado</h3>
              <TextField 
              sx={{ width: '90%' }}
              multiline
              inputProps={{min: 0, styles: { textAlign: 'center' }}}
              rows={4} 
              value={observation && observation.telhado}
              onChange={(e) => setObservation({...observation, telhado: e.target.value})}/>
            </ThemeProvider>
            <div className={styles.create_observation_buttons}>
              {/* <Button variant="contained" color="success" onClick={() => sendObservation('Telhado')}>Confirmar</Button>
              <Button variant="contained" color="error" onClick={() => closeObservation()}>Cancelar</Button> */}
            </div>
            </div> : 
              <><p className={styles.pdf_paragraph}>
                {info && info.observacao_telhado ?
                  info.observacao_telhado : 'Nenhuma'}
              </p>
              {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                <IconButton
                className={styles.print}
                aria-label={'Editar Observação'}
                data-cooltipz-dir="top"
                size="small"
                onClick={() => setOpenObservation({ ...openObservation, telhado: true })}
              >
                  <EditIcon />
                </IconButton>
              }
              </>
            }
        </div>
      </div>
    </div>
    </div>
  );
};

export default EstimateCleaner;

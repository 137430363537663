import { useState, useRef } from 'react'
import Swal from "sweetalert2";
import style from "./style.module.scss";
import SlickSlider from "react-slick";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

// import Telhas from '../../../../images/install/telhas.jpg';
import InversorLigado from '../../../../images/install/inversor_ligado.jpeg';
import Modulos from '../../../../images/install/modulos_instalados.jpeg';

import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUploadCleaner';
import useCircular from '../../../../hooks/useCircular';

const CleanerStep2 = ({cleaner,  open, close }) => {
  const theme = useTheme();
  const slider = useRef(null);
  const { uploadImages, changeProgress } = useUpload(cleaner);
  const { CircularProgressWithLabel  } = useCircular();
  const [openPhoto, setOpenPhoto] = useState(0);
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState({});
  const [vistoria, setVistoria] = useState({ 
    modulos: [],
    inversor: [],
    adicional: [],
    reaperto: '',
    inspecao: ''
 });
  const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false],[false], [false]);

  const checkPhotos = [
   'Foto/Video dos Módulos', 
   'Foto/Video do Inversor Ligado', 
   'Foto Adicional',
  ];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  const closeBox = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setCheckData([false]);
      setVistoria({
        modulos: [],
        inversor: [],
        adicional: [],
        reaperto: '',
        inspecao: ''
      }); 
      setMessage({});
    }, 500);
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  const handleMsg = (value, index) => {
    setMessage({...message, [index]: value});
  }


  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'vistoriaModulos') { 
      if(vistoria.modulos) {
        setVistoria({...vistoria, modulos: [...vistoria.modulos, ...files]})
      } else {
        setVistoria(files);
      }
    } else if(type === 'vistoriaInversor') { 
      if(vistoria.inversor) {
        setVistoria({...vistoria, inversor: [...vistoria.inversor, ...files]})
      } else {
        setVistoria(files);
      }
    } else if(type === 'vistoriaAdicional') { 
      if(vistoria.adicional) {
        setVistoria({...vistoria, adicional: [...vistoria.adicional, ...files]})
      } else {
        setVistoria(files);
      }
    } 
  }

  // const changeStep = (direction) => {
  //   if(direction === 'go') {
  //      if (step < 1 && (vistoria.inversor.length === 0 || vistoria.modulos.length === 0 || vistoria.inversor_string.length === 0 || vistoria.infiltracao.length === 0)) {
  //       let validationPhoto;
  //       if(vistoria.modulos.length === 0) {
  //         validationPhoto = 'Envie a foto e video da área dos <b>Módulos instalados</b>'
  //       } else if(vistoria.infiltracao.length === 0) {
  //         validationPhoto = 'Informe se há <b>vestígios de infiltração</b>'
  //       } else if(vistoria.inversor.length === 0) {
  //         validationPhoto = 'Envie a foto do <b>Inversor ligado</b>'
  //       } else if(vistoria.inversor_string.length === 0) {
  //         validationPhoto = 'Envie o video do <b>Inversor e String ligado</b>'
  //       }
  //       return Swal.fire({
  //         title: 'Fotos',
  //         html: `${validationPhoto}</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#111",
  //         confirmButtonText: "Ok",
  //       })
  //     }
  //     setStep(step + 1);
  //   } else {
  //     setStep(step - 1);
  //   }
  // }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
      if(newPhotos.length === 0) {
        setOpenPhoto(false);
      }
    setPhotos({data: newPhotos, type: photos.type});
     switch (type) {
      case 'vistoriaInversor':
        setVistoria({...vistoria, inversor: newPhotos})
        break;
      case 'vistoriaModulos':
        setVistoria({...vistoria, modulos: newPhotos})
        break;
      case 'vistoriaAdicional':
        setVistoria({...vistoria, adicional: newPhotos})
        break;
        default:
     }
    }
  }

  const sendVisit = async () => {
    if (vistoria.inversor.length === 0 || vistoria.modulos.length === 0 || vistoria.inspecao.length === 0 || vistoria.reaperto.length === 0) {
      let validationPhoto;
      if(vistoria.modulos.length === 0) {
        validationPhoto = 'Envie a foto ou video dos <b>Módulos Limpos</b>'
      } else if(vistoria.inversor.length === 0) {
        validationPhoto = 'Envie a foto ou video do <b>Inversor Ligado</b>'
      } else if(vistoria.inspecao.length === 0) {
        validationPhoto = 'Informe se foi realizado a <b>Inspeção das Telhas</b>'
      } else if(vistoria.reaperto.length === 0) {
        validationPhoto = 'Informe se foi realizado o <b>Reaperto dos Intermédiarios</b>'
      }
      return Swal.fire({
        title: 'Fotos',
        html: `${validationPhoto}</b> para prosseguir para a próxima etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      console.log(vistoria)
      console.log(message)
      Swal.fire({
        title: 'Depois da Limpeza',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let visita = {};
          let ref = {};
          setStep(1);

          try {
            const resultModulos = await uploadImages(vistoria.modulos, 'vistoriaModulos', 0);
            visita.modulos = resultModulos.map(object => object.downloadURL);
            ref.modulos = resultModulos.map(object => object.fullPath);

            const resultVistoriaInversor = await uploadImages(vistoria.inversor, 'vistoriaInversor', 1);
            visita.inversor = resultVistoriaInversor.map(object => object.downloadURL);
            ref.inversor = resultVistoriaInversor.map(object => object.fullPath);
            handleChangeCheck(1);

            if(vistoria.adicional) {
              const resultVistoriaAdicional = await uploadImages(vistoria.adicional, 'vistoriaAdicional', 2);
              visita.adicional = resultVistoriaAdicional.map(object => object.downloadURL);
              ref.adicional = resultVistoriaAdicional.map(object => object.fullPath);
              handleChangeCheck(2);
            }

            let props = {
              ...visita,
               ref,
               createAt: new Date(),
               etapa: 2,
               inspecao: vistoria.inspecao,
               reaperto: vistoria.reaperto,
               mensagem: message
            }

              await addDoc(collection(dataBase, 'Limpezas', cleaner.id, 'Etapas'), props).then(async () => {
                await updateDoc(doc(dataBase, 'Limpezas', cleaner.id), {
                  jornada: {
                    etapa: 'Concluido',
                    index: 2,
                    sigla: 'C',
                    cor: '#addcc5',
                    data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
                  },
                  data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
                }).then(() => {
                  axios.post('https://n8n.corpbrasil.cloud/webhook/af691be5-c9ed-4224-9938-e88f4d61a808', {
                  cliente: cleaner.nome,
                  cidade: cleaner.cidade,
                  endereco: cleaner.endereco,
                  consultora: cleaner.analista?.nome,
                  instalador: cleaner.terceirizado?.nome,
                  empresa: cleaner.terceirizado?.razao_social,
                  gps_lat: 0,
                  gps_lng: 0,
                  telefone: cleaner.analista?.nome === 'Ana Flávia' ? '5515992510989' : '5515988350885',
                  id: cleaner.id,
                  status: 'Limpeza'
                })
                })
                 Swal.fire({
                 title: 'Depois da Limpeza',
                 html: `A <b>Etapa</b> foi concluida com sucesso.`,
                 icon: "success",
                 showCloseButton: true,
                 confirmButtonColor: "#111",
                 confirmButtonText: "Ok",
                 })
                 closeBox();
         })
          } catch (error) {
            console.error('Ocorreu um erro: ', error);
          }  
          }
        })
    }
  }

  return (
    <><Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={() => closeBox()}
    >
      <IconButton
        aria-label="close"
        onClick={() => closeBox()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Depois da Limpeza</DialogTitle>
      <DialogContent className={style.dialog_content}>
      {step === 0 &&
        <div className={style.register_container}>
           <div className={style.photo_item}>
            {vistoria && vistoria.modulos.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Grave e envie foto e vídeo dos <b>módulos após a limpeza</b>.<br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Modulos} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Grave e envie foto e vídeo dos <b>módulos após a limpeza</b>.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.modulos.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                          }
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.modulos, 'vistoriaModulos')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaModulos')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto / Video
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg,video/*" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
           <div className={style.photo_item}>
            {vistoria && vistoria.inversor.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 2:</b> Grave e envie foto e vídeo do <b>inversor ligado após a limpeza</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={InversorLigado} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Grave e envie foto e vídeo do <b>inversor ligado após a limpeza</b>.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.inversor.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                          }
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.inversor, 'vistoriaInversor')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
            </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }} 
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaInversor')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto / Vídeo
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg,video/*" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
           <div className={style.photo_item}>
              <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3 (Não obrigatório):</b> Caso queira e seja preciso, envie fotos adicionais sobre algum ocorrido durante a limpeza.
              </DialogContentText>
            {vistoria && vistoria.adicional.length === 0 ? 
              null :
                <div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.adicional.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.7rem', textTransform: 'capitalize' }} 
                            onClick={() => deletePhoto(index, vistoria.adicional, 'vistoriaAdicional')}>Excluir</Button>
                            <p>Comentário</p>
                            <TextField
                            fullWidth
                            multiline
                            rows={3} 
                            sx={{ margin: '1rem', backgroundColor: "#FFF", maxWidth: '90%' }}
                            onChange={(e) => handleMsg(e.target.value, index)}
                             />
                        </div>
                      ))}
                    </SlickSlider>
            </div>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'vistoriaAdicional')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0' }}>
            <b>Passo 4:</b> Fez inspeção para garantir que nenhuma telha foi quebrada ou desencaixada?
          </DialogContentText>
          <div className={style.input_double}>
            <FormControl fullWidth margin='dense'>
              <InputLabel id="inspecao">Resposta</InputLabel>
                <Select
                  labelId='inspecao'
                  label="Resposta"
                  value={vistoria.inspecao}
                  onChange={(e) => setVistoria({...vistoria, inspecao: e.target.value})}
                  required>
                  <MenuItem value="Sim">Sim</MenuItem>
                  <MenuItem value="Não">Não</MenuItem>
                  </Select>
            </FormControl>
          </div>
          <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0' }}>
            <b>Passo 5:</b> Fez o reaperto do intermediários e finais dos módulos?
          </DialogContentText>
          <div className={style.input_double}>
            <FormControl fullWidth margin='dense'>
              <InputLabel id="reaperto">Resposta</InputLabel>
                <Select
                  labelId='reaperto'
                  label="Resposta"
                  value={vistoria.reaperto}
                  onChange={(e) => setVistoria({...vistoria, reaperto: e.target.value})}
                  required>
                  <MenuItem value="Sim">Sim</MenuItem>
                  <MenuItem value="Não">Não</MenuItem>
                  </Select>
            </FormControl>
          </div>
        </div>
      }
        {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li key={index}>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} /> 
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <Button disableElevation sx={{ textTransform: 'capitalize' }} variant='contained' onClick={() => closeBox()}>Fechar</Button>
          <Button disableElevation sx={{ textTransform: 'capitalize' }} variant='contained' disabled={step > 0 ? true: false} color='success' onClick={() => sendVisit()}>Enviar</Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog></>
  )
}

export default CleanerStep2;
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#313131',
      contrastText: '#fff'
    },
    secondary: {
      main: '#006633',
      contrastText: '#fff'
    },
    info: {
      main: '#111',
      contrastText: '#fff'
    },
    success: {
      main: '#05a937',
      contrastText: '#fff'
    },
  },
});

export const theme2 = createTheme({ //Criei por conta de não poder user a paleta junto com um component 'theme' do material ui
  palette: {
    primary: {
      main: '#313131',
      contrastText: '#fff'
    },
    secondary: {
      main: '#006633',
      contrastText: '#fff'
    },
    info: {
      main: '#111',
      contrastText: '#fff'
    },
    success: {
      main: '#05a937',
      contrastText: '#fff'
    },
  },
});

export const themeSystem = createTheme({
  palette: {
    primary: {
      main: '#D9D9D9',
      contrastText: '#000'
    },
    secondary: {
      main: '#00B707',
      contrastText: '#fff'
    },
    info: {
      main: '#FFE500',
      contrastText: '#050C9C' 
    },
    success: {
      main: '#22C55E',
      contrastText: '#fff'
    },
    error: {
      main: '#FF302F',
      contrastText: '#fff'
    },
    warning: {
      main: '#00A7EF',
      contrastText: '#fff'
    }
  },
});

export const themeNegotiaton = createTheme({
  palette: {
    primary: {
      main: '#00A7EF',
      contrastText: '#fff'
    },
    secondary: {
      main: '#006633',
      contrastText: '#fff'
    },
    info: {
      main: '#FFE500',
      contrastText: '#050C9C'
    },
    success: {
      main: '#00B707',
      contrastText: '#fff'
    },
    error: {
      main: '#FF0000',
      contrastText: '#fff'
    },
    warning: {
      main: '#000AD9',
      contrastText: '#fff'
    }
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const themeInput = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#000',
            },
            '&:hover fieldset': {
              borderColor: '#000',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#000',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#000',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#000',
          },
        },
      },
    },
  },
});
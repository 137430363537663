import { useState, useEffect, memo } from "react";
import Swal from "sweetalert2"; 
import moment from "moment";

import { PatternFormat } from "react-number-format";
import CurrencyInput from "react-currency-input-field";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { theme } from '../../../../data/theme';
import { ThemeProvider, DialogContentText } from "@mui/material";
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import style from "./style.module.scss";
import { addDoc, collection } from "firebase/firestore";
import { dataBase } from "../../../../firebase/database";

const AddLead = ({ leads, members, view, close, userRef, checkClose }) => {
  const [consultora, setConsultora] = useState("");
  const [nome, setNome] = useState("");
  const [kit, setKit] = useState('');
  const [telefone, setTelefone] = useState("");
  const [cpf, setCPF] = useState("");
  const [date, setDate] = useState();
  const [valor, setValor] = useState('');


  console.log(checkClose)

  useEffect(() => {
    if(checkClose) {
      setNome("");
      setKit('');
      setTelefone("");
      setDate([]);
    }
  },[checkClose])

  useEffect(() => {
    if(userRef && userRef.cargo === 'Vendedor(a)') {
      setConsultora(userRef.nome);
    }
  },[userRef, view])

  useEffect(() => {
    if(view) {
      setDate(moment(new Date()).format('YYYY-MM-DD'));
    }
  },[view])

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         await axios.get('https://script.google.com/macros/s/AKfycbxad1yCWiFmL9Q2qXIMglIFbH-m9KafsaNoD9UVYrhKgdmdjHpAlbJ-IxeTj-OroUjjsw/exec')
//         .then((result) => {
//           let sheets = [];
//           result.data.GoogleSheetData.forEach((data) => {
//             if(data[0].length > 1) {
//               // const newData = Object.assign({}, data);
//               sheets.push(data)
//             }
//           })
//           setLeadsSheets(sheets);
//         })
//       } catch {
//       }
//     }
//     fetchData();
// },
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   []);

  // useEffect(() => {
  //   if(leadsSheets) {
  //     const uniqueOptions = leadsSheets.reduce((uniqueList, currentData) => {
  //       const isExisting = uniqueList.some(item => item.nome === currentData[1]);
  
  //       if (!isExisting && currentData[1].trim() !== '') {
  //         uniqueList.push({
  //           id: uniqueList.length,
  //           nome: currentData[1],
  //           cidade: currentData[4],
  //           telefone: currentData[2],
  //           consultora: currentData[9]
  //         });
  //       }
  
  //       return uniqueList;
  //     }, []);
  //     setOption(uniqueOptions.sort((a, b) => a.nome.localeCompare(b.nome)))
  //   }
  // },[leadsSheets])

  // useEffect(() => {
  //   if(members) {
  //     const membersSellers = members.filter(data => data.cargo === 'Vendedor(a)' && data.nome !== 'Leticia' && data.nome !== 'Pós-Venda')
  //     setSellers(membersSellers.sort((a,b) => a.nome.localeCompare(b.nome)))
  //   }
  // },[members])


  // const cidadeFormatada =
  // cities &&
  // cities.map((city) => ({
  //   nome: city.nome,
  //   id: city.id
  // }));

  const closeBox = () => {
    setConsultora("");
    setNome("");
    setKit("");
    setTelefone("");
    setDate("");
    setValor("");
    close('all');
  }

  const onSubmit = async (e) => {
    e.preventDefault(); 
    try {
      Swal.fire({
        title: 'Financiamento',
        html: `Você deseja cadastrar o Cliente <b>${nome}</b>?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // const telefoneFormatado = telefone.replace(/\D/g, '');
          await addDoc(collection(dataBase, 'Financiamento'), {
            nome: nome,
            telefone: telefone,
            kit: kit,
            valor: valor,
            cpf: cpf,
            consultora: consultora,
            data_nascimento: date,
            data: moment(new Date()).format('YYYY-MM-DD'),
            dataRef: moment(new Date()).format('DD MMM YYYY - HH:mm'),
            createAt: new Date(),
            situacao: 'Aguardando',
            banco: '',
            status: '',
            observacao: '',
            nota_fiscal: ''
          })
          closeBox('add');
          Swal.fire({
            title: 'Financiamento',
            html: `O Cliente <b>${nome}</b> foi cadastrado com sucesso.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
          })
        }
      });
    } catch (error) {
      // console.log(error);
    }
  };

  // const handleChange = (event, newValue) => {
  //   if (newValue === "") {
  //     setNome(newValue); // Se o novo valor for vazio, mantenha como vazio
  //   } else {
  //     // Verifica se o novo valor existe nas opções
  //     const optionExists = option.some(option => option.nome === newValue);
  
  //     if (optionExists) {
  //       setNome(newValue);
  //       const leadReff = leadsSheets.filter(data => data[1] === newValue);
  //       setLeadRef(leadReff[0]);
  //     } else {
  //       setNome(event.target.value);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if(leadRef) {
  //     setCity(leadRef[4]);
  //     setTelefone(leadRef[2]);
  //   }
  // },[leadRef])

  console.log(date)

  return (
    <Dialog
      className={style.dialog}
      open={view}
      fullWidth
      maxWidth="sm"
      onClose={() => closeBox()}
    >
      <IconButton
          aria-label="close"
          onClick={() => closeBox()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Cadastrar Cliente</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
          Preencha os campos abaixo para cadastrar um novo <b>Cliente</b>.
        </DialogContentText>
        <form onSubmit={onSubmit} autocomplete="off">
        <ThemeProvider theme={theme}>
        <div className={style.input_double}>
        <TextField
          margin="dense"
          id="name"
          label="Nome"
          type="text"
          value={nome ? nome : ''}
          onChange={(e) => setNome(e.target.value)}
          fullWidth
          variant="outlined"
          autoComplete="off"
          required
        />
        <TextField
          margin="dense"
          id="date"
          label="Data de Nascimento"
          type="date"
          value={date ? date : ''}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          variant="outlined"
          autoComplete="new-password"
          required
        />
        </div>
        <div className={style.input_double}>
        <PatternFormat
          fullWidth
          sx={{ marginTop: '8px' }}
          customInput={TextField}
          onChange={(e) => setCPF(e.target.value)}
          format="###.###.###-##"
          mask="_"
          placeholder="000.000.000-00"
          value={cpf ? cpf : ''}
          label="CPF"
          minLength={14}
          variant="outlined"
          color="primary"
          required
          autoComplete="new-password"
        />
        <PatternFormat
          fullWidth
          error={leads && leads.find(data => data.nome === nome && 
            (typeof telefone === 'string' ? data.telefone === Number(telefone.replace(/\D/g, '')) : data.telefone === telefone))}
          sx={{ marginTop: '8px' }}
          customInput={TextField}
          onChange={(e) => setTelefone(e.target.value)}
          format="(##) #####-####"
          mask="_"
          placeholder="(00) 00000-0000"
          value={telefone ? telefone : ''}
          label="Telefone"
          minLength={9}
          variant="outlined"
          color="primary"
          required
          autoComplete="new-password"
          helperText={leads && leads.find(data => data.nome === nome && (typeof telefone === 'string' ? data.telefone === Number(telefone.replace(/\D/g, '')) : data.telefone === telefone)) && "O Cliente já está cadastrado. Altere o nome ou o Telefone para continuar."}
        />
        </div>
        <div className={style.input_double}>
        <TextField
          margin="dense"
          id="kit"
          label="Kit (Somente Número)"
          type="number"
          value={kit ? kit : ''}
          onChange={(e) => setKit(e.target.value)}
          fullWidth
          variant="outlined"
          required
        />
        <FormControl sx={{ margin: "0.3rem 0" }} fullWidth>
        <CurrencyInput
          fullWidth
          customInput={TextField}
          label="Valor"
          placeholder="R$ 00"
          intlConfig={{ locale: "pt-BR", currency: "BRL" }}
          onValueChange={(value) => setValor(value)}
          value={valor || ''}
          min={50}
          required
          color="primary" />  
        </FormControl>
        </div>
          <FormControl sx={{ margin: "0.3rem 0" }} fullWidth>
            <InputLabel id="select-label">Consultora</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={consultora}
              label="Consultora"
              onChange={(e) => setConsultora(e.target.value)}
              required
              disabled={userRef && userRef.cargo === 'Vendedor(a)' ? true : false}
            >
                <MenuItem value='Ana'>Ana</MenuItem>
                <MenuItem value='Bruna'>Bruna</MenuItem>
                <MenuItem value='Lia'>Lia</MenuItem>
                <MenuItem value='Mayara'>Mayara</MenuItem>
            </Select>
          </FormControl>
          </ThemeProvider>
          <DialogActions sx={{ justifyContent: 'center' }}>
          <ThemeProvider theme={theme}>
            <Button
              disableElevation
              variant="contained"
              color="success"
              sx={{ textTransform: 'capitalize' }} 
              type="submit">Confirmar</Button>
            <Button
            disableElevation
            variant="contained"
            color="primary"
            sx={{ textTransform: 'capitalize' }}  
            onClick={() => closeBox()}>Cancelar</Button>
          </ThemeProvider>
        </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddLead);

import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { doc, onSnapshot, collection, updateDoc, addDoc, getDoc } from "firebase/firestore";
import { dataBase } from "../../firebase/database";
import moment from "moment";
// import SlickSlider from "react-slick";
// import { Document, Page, pdfjs } from 'react-pdf';
import { theme } from "../../data/theme";
import style from "./style.module.scss";

import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button } from "@mui/material";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';
import { ThemeProvider } from "@emotion/react";
// import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from '@mui/icons-material/Close';
import ReplyIcon from '@mui/icons-material/Reply';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

// import Homologacao from '../../images/visit/homologacao.jpg';

// import InstallStep1 from "./Components/Install/Step1";
import InstallStep2 from "./Components/Install/Step2";
import InstallStep3 from "./Components/Install/Step3";
import InstallStep4 from "./Components/Install/Step4";
import Analyse from "./Components/Analyse/Index";

import Logo from "../../images/LogoCORPBRASIL.png";
import { theme2 } from "../../data/theme";
import File from "../../components/VerifyURL/Index";
import axios from "axios";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { Helmet, HelmetProvider } from "react-helmet-async";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; 

const Visit = ({ isLoaded, userRef }) => {
  const [relatorio, setRelatorio] = useState('Relatório Contrato');
  const [visit, setVisit] = useState([]);
  const [visitTec, setVisitTec] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [checkStep, setCheckStep] = useState({
    step3: false,
    step5: false,
    step6: false,
    step7: false,
  });
  const [info, setInfo] = useState();
  const refObservation = useRef({ telhado: '', kit: '', instalacao: '', servico: '' });
  const [observation, setObservation] = useState({ telhado: '', kit: '', instalacao: '', servico: '' });
  const [bitola, setBitola] = useState({ ramal: '', cliente: '', cabo: 'Cobre' });
  const [kitInfo, setKitInfo] = useState({
    fornecedor: '', valorKit: '', custoInstalacao: '', valorVenda: '', potenciaInversor: '', quantidadePlacas: '',
    potenciaPlacas: '', tipoEstrutura: ''
  });
  const [redeInfo, setRedeInfo] = useState({ dps: 'Sim ✅', energiaCA: 'Sim ✅', disjuntor: 'Sim ✅', concessionaria: 'CPFL', });
  const [openObservation, setOpenObservation] = useState(false);
  const [openAnotation, setOpenAnotation] = useState(false);
  const [photosStep2Perto, setPhotosStep2Perto] = useState();
  const [photosStep2Longe, setPhotosStep2Longe] = useState();
  const [photosStep2Video, setPhotosStep2Video] = useState();
  const [photosStep3, setPhotosStep3] = useState();
  const [photosStep4, setPhotosStep4] = useState();
  // const [photosStep5, setPhotosStep5] = useState(); // Conferência do Kit Solar
  const [photosStep6, setPhotosStep6] = useState(); // Vistoria dos Módulos
  const [photosStep7, setPhotosStep7] = useState(); // Estrutura
  const [photosStep8, setPhotosStep8] = useState(); // Instalação Finalizada
  const [openPhoto, setopenPhoto] = useState(
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false]
  );
  const [openPhotoInstall, setopenPhotoInstall] = useState(
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false]
  );
  const [step, setStep] = useState([]);
  const [quadro, setQuadro] = useState();
  const [localInversor, setLocalInversor] = useState();
  const [amperimetro, setAmperimetro] = useState();
  const [bengala, setBengala] = useState();
  const [disjuntor, setDisjuntor] = useState();
  const [fachada, setFachada] = useState();
  const [padrao, setPadrao] = useState();
  const [poste, setPoste] = useState();
  const [relogio, setRelogio] = useState();
  const [kit, setKit] = useState();
  const [address, setAddress] = useState();
  const [openEdit, setOpenEdit] = useState({
    address: false, bitola_ramal: false, bitola_cliente: false, fornecedor: false, valorKit: false, custoInstalacao: false, valorVenda: false,
    potenciaInversor: false, quantidadePlacas: false, potenciaPlacas: false, tipoEstrutura: false, dps: false, energiaCA: false, disjuntor: false
  });
  const [OpenInstallStep, setOpenInstallStep] = useState('0');
  const [obsRef, setObsRef] = useState({});
  const [buttonSave, setButtonSave] = useState(false);
  const [observations, setObservations] = useState({
    fachada: [''],
    poste: [''],
    bengala: [''],
    padrao: [''],
    relogio: [''],
    disjuntor: [''],
    amperimetro: [''],
    quadro: [''],
    telhado: [''],
    telhado_medida: [''],
    projeto: [''],
    etapa: 'Comentários',
  });
  const [docs, setDocs] = useState([]);
  let { idVisit } = useParams();
  const visitCollectionRef = collection(
    dataBase,
    "Visitas",
    idVisit,
    "Tecnica"
  );
  const supplierCollectionRef = collection(
    dataBase,
    "Fornecedores"
  );
  const idTec = 'VdT1ifQE6LflKbgoaKQgL6P0uxo1';

  useEffect(() => {
    const getVisit = async () => {
      onSnapshot(doc(dataBase, "Visitas", idVisit), (doc) => {
        setVisit({ id: doc.id, ...doc.data() });
      });
      onSnapshot(await visitCollectionRef, (visit) => {
        // Atualiza os dados em tempo real
        setVisitTec(visit.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
      });
      onSnapshot(await supplierCollectionRef, (sup) => {
        // Atualiza os dados em tempo real
        setSupplier(sup.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
      });
    };
    getVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (visit) {
  //     document.title = visit.cliente ? visit.cliente + ' - Visita Comercial + Técnica - CORPBRASIL Energia Solar' : 'Visita Comercial + Técnica - CORPBRASIL Energia Solar';
  //   }
  // }, [visit])

  useEffect(() => {
    if (userRef && userRef.cargo === 'Instalador' && !userRef.obras.find(item => item.id === idVisit)) {
      window.location.href = "https://corpbrasil.app/"
    }
  }, [userRef, idVisit])

  useEffect(() => {
    if (visitTec) {
      const comentary = visitTec.find((etapa) => etapa.etapa === 'Comentários');
      const step4 = visitTec.find((etapa) => etapa.etapa === 4);
      if (comentary) { // Puxa os comentários salvos no banco de dados
        setObsRef(comentary);
        setObservations({ ...comentary, projeto: [step4 ? step4.observacao_telhado : ''] })
        console.log(comentary)
      } else {
        if (step4) {
          setObservations({ ...observations, projeto: [step4.observacao_telhado] })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitTec])


  useEffect(() => {
    if (visitTec) {
      const step1 = visitTec.find((etapa) => etapa.etapa === 1);
      const step2 = visitTec.find((etapa) => etapa.etapa === 2);
      const step3 = visitTec.find((etapa) => etapa.etapa === 3);
      const step4 = visitTec.find((etapa) => etapa.etapa === 4);
      const step6 = visitTec.find((etapa) => etapa.etapa === 6);
      const step7 = visitTec.find((etapa) => etapa.etapa === 7);
      const step8 = visitTec.find((etapa) => etapa.etapa === 8);
      setStep({
        step1: step1 ? step1 : null,
        step2: step2 ? step2 : null,
        step3: step3 ? step3 : null,
        step4: step4 ? step4 : null,
      })
      let photos = [];
      const photosFull2Perto = step2?.telhadoPerto;
      const photosFull2Longe = step2?.telhadoLonge;
      const videosFull2 = step2?.telhadoVideo;

      // if (step2) {
      //   for (let i = 0; i < photosFull2.length; i++) {
      //     FilesStep2.push({ type: "photo", url: photosFull2[i] });
      //   }
      //   for (let i = 0; i < videosFull2.length; i++) {
      //     FilesStep2.push({ type: "video", url: videosFull2[i] });
      //   }
      // }

      const photosFull4 =
        step4 &&
        photos.concat(
          step4.telhadoMedida,
          step4.telhadoPlaca,
          step4.telhadoIrradiacao,
        );
      const photosFull3 = step3 && step3.telhadoMedida;
      setFachada(step1 && step1.fachada);
      setPoste(step1 && step1.poste);
      setBengala(step1 && step1.bengala);
      setPadrao(step1 && step1.padrao);
      setRelogio(step1 && step1.relogio);
      setDisjuntor(step1 && step1.disjuntor);
      setAmperimetro(step1 && step1.amperimetro);
      setQuadro(step1 && step1.quadro);
      setLocalInversor(step1 && step1.localInversor);
      setKit(step4 && step4.kit);
      setDocs({
        inversorDataSheet: step4 && step4.inversorDataSheet,
        inversorManual: step4 && step4.inversorManual,
        moduloDataSheet: step4 && step4.moduloDataSheet,
        moduloManual: step4 && step4.moduloManual,
      })
      setPhotosStep2Perto(photosFull2Perto);
      setPhotosStep2Longe(photosFull2Longe);
      setPhotosStep2Video(videosFull2);
      setPhotosStep3(photosFull3);
      setPhotosStep4(photosFull4);
      // setPhotosStep5(photosFull5);
      setPhotosStep6(step6);
      setPhotosStep7(step7);
      setPhotosStep8(step8);
      setObservation({
        telhado: step4 && step4.observacao_telhado,
        kit: step4 && step4.observacao_kit,
        instalacao: visit && visit.observacao_instalacao,
        servico: step4 && step4.servico_complementar
      })
      refObservation.current.telhado = step4 && step4.observacao_telhado
      refObservation.current.kit = step4 && step4.observacao_kit
      refObservation.current.instalacao = visit && visit.observacao_instalacao
      refObservation.current.servico = step4 && step4.servico_complementar
      setInfo({
        gps_padrao: step1 && step1.GPS_padrao,
        telhado: step3 && step3.telhado,
        anotacao_padrao: step1 && step1.anotacao,
        observacao_telhado: step4 && step4.observacao_telhado,
        observacao_kit: step4 && step4.observacao_kit,
        injecao_energia_ca: step1 && step1.injecao_energia_ca,
        servico_complementar: step4 && step4.servico_complementar,
        altura_telhado: step3 && step3.altura_telhado,
        condominio: step4 && step4.condominio,
        comissionamento: step4 && step4.comissionamento,
        horario: step4 && step4.horario,
        bitola_aterramento: step8 && step8.bitola_aterramento,
        bitola_infra_ca: step8 && step8.bitola_infra_ca,
        gps_instalacao: step8 && step8.GPS_instalacao,
        metragem_aterramento: step8 && step8.metragem_aterramento,
        metragem_infra_ca: step8 && step8.metragem_infra_ca
      });
      setCheckStep({
        step1: step1 ? true : false,
        step3: step3 ? true : false,
        // step5: step5 ? true : false,
        step6: step6 ? true : false,
        step7: step7 ? true : false,
        step8: step8 ? true : false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitTec, visit]);

  console.log(step)

  const handleToggle = (index) => {
    setopenPhoto((prevData) => {
      const newCheckData = [...prevData];
      newCheckData[index] = !newCheckData[index];
      return newCheckData;
    });
  };

  const handleToggleInstall = (index) => {
    setopenPhotoInstall((prevData) => {
      const newCheckData = [...prevData];
      newCheckData[index] = !newCheckData[index];
      return newCheckData;
    });
  };

  console.log(visit)

  const checkPermission = () => {
    if (userRef?.cargo === 'Administrador' || (userRef?.nome === 'Pós-Venda' && visit?.representante?.id === userRef?.id)) {
      return true;
    } else {
      return false;
    }
  }

  const sendAnalyse = async () => {
    try {
      Swal.fire({
        title: 'Análise',
        html: `Você deseja gerar o <b>Relatório?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await updateDoc(doc(dataBase, 'Visitas', idVisit), {
            analise_status: 'Em Andamento'
          }).then(() => {
            Swal.fire({
              title: 'Relatório',
              html: `O <b>Relatório</b> para ser analisado foi gerado com sucesso.`,
              icon: "success",
              showCloseButton: true,
              confirmButtonColor: "#F39200",
              confirmButtonText: "Ok",
            })
          })
        }
      })
    } catch (error) {
      console.error('Ocorreu um erro: ', error)
    }
  }

  const sendObservation = async (type) => {
    try {
      Swal.fire({
        title: 'Observação',
        html: `Você deseja confirmar a <b>Observação?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let props;
          if (type === "Telhado" || type === "Kit" || type === "Servico") {
            if (type === 'Telhado') {
              props = {
                observacao_telhado: observation.telhado
              }
            } else if (type === 'Kit') {
              props = {
                observacao_kit: observation.kit
              }
            } else if (type === 'Servico') {
              props = {
                servico_complementar: observation.servico
              }
            }
            const idStep4 = visitTec.find((etapa) => etapa.etapa === 4).id;
            await updateDoc(doc(dataBase, 'Visitas', idVisit, "Tecnica", idStep4), props).then(() => {
              Swal.fire({
                title: 'Concluido',
                html: `A Ação foi concluida com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#F39200",
                confirmButtonText: "Ok",
              })
            })
          } else {
            props = {
              observacao_instalacao: observation.instalacao
            }
            await updateDoc(doc(dataBase, 'Visitas', idVisit), props).then(() => {
              Swal.fire({
                title: 'Concluido',
                html: `A <b>Observação</b> foi enviada com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#F39200",
                confirmButtonText: "Ok",
              })
            })
          }
          setOpenObservation({ telhado: false, kit: false, instalacao: false })
        }
      })
    } catch (error) {
      console.error('Ocorreu um erro: ', error);
    }
  }

  const saveObsProject = async () => {
    try {
      const idStep4 = visitTec.find((etapa) => etapa.etapa === 4).id;
      await updateDoc(doc(dataBase, 'Visitas', idVisit, 'Tecnica', idStep4), {
        observacao_telhado: observations.projeto[0]
      })

      return true;
    } catch (error) {
      console.log('[ERRO]: ', error)
    }
  }

  const saveObservation = async () => {

    try {
      Swal.fire({
        title: 'Observação',
        html: `Você deseja salvar as <b>Observações?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (obsRef && obsRef.etapa) {
            await updateDoc(doc(dataBase, 'Visitas', idVisit, 'Tecnica', obsRef.id), observations)
          } else {
            console.log(observations)
            await addDoc(collection(dataBase, 'Visitas', idVisit, 'Tecnica'), observations)
          }
          saveObsProject();
          Swal.fire({
            title: 'Concluido',
            html: `As <b>Observações</b> foram salvas com sucesso.`,
            icon: "success",
            showCloseButton: true,
            confirmButtonColor: "#111",
            confirmButtonText: "Ok",
          })
        }
      })
    } catch (error) {
      console.error('Ocorreu um erro: ', error);
    }
  }

  const changeOpenEdit = (type) => {
    switch (type) {
      case 'Address':
        setOpenEdit({ address: true });
        setAddress(visit && visit.endereco);
        break;
      case 'Bitola_Ramal':
        setOpenEdit({ bitola_ramal: true });
        setBitola({
          ...bitola,
          bitola_ramal: visit && visit.medida_bitola_ramal
        })
        break;
      case 'Bitola_Cliente':
        setOpenEdit({ bitola_cliente: true });
        setBitola({
          ...bitola,
          bitola_cliente: visit && visit.medida_bitola_cliente
        })
        break;
      case 'Bitola_Cabo':
        setOpenEdit({ bitola_cabo: true });
        setBitola({
          ...bitola,
          bitola_cabo: visit && visit?.cabo_bitola_cliente
        })
        break;
      case 'Fornecedor':
        setOpenEdit({ fornecedor: true });
        setKitInfo({
          ...kitInfo,
          fornecedor: visit && visit.kit_info?.fornecedor
        })
        break;
      case 'Valor_Kit':
        setOpenEdit({ valorKit: true });
        setKitInfo({
          ...kitInfo,
          valorKit: visit && visit.kit_info.valor
        })
        break;
      case 'Custo_Instalacao':
        setOpenEdit({ custoInstalacao: true });
        setKitInfo({
          ...kitInfo,
          custoInstalacao: visit && visit.kit_info?.instalacao
        })
        break;
      case 'Valor_Venda':
        setOpenEdit({ valorVenda: true });
        setKitInfo({
          ...kitInfo,
          valorVenda: visit && visit.kit_info?.venda
        })
        break;
      case 'Potencia_Inversor':
        setOpenEdit({ potenciaInversor: true });
        setKitInfo({
          ...kitInfo,
          potenciaInversor: visit && visit.kit_info?.inversor
        })
        break;
      case 'Quantidade_Placas':
        setOpenEdit({ quantidadePlacas: true });
        setKitInfo({
          ...kitInfo,
          quantidadePlacas: visit && visit.kit_info?.placa_quantidade
        })
        break;
      case 'Potencia_Placas':
        setOpenEdit({ potenciaPlacas: true });
        setKitInfo({
          ...kitInfo,
          potenciaPlacas: visit && visit.kit_info?.placa_potencia
        })
        break;
      case 'Tipo_Estrutura':
        setOpenEdit({ tipoEstrutura: true });
        setKitInfo({
          ...kitInfo,
          tipoEstrutura: visit && visit.kit_info?.estrutura
        })
        break;
      case 'Custo_InfraCA':
        setOpenEdit({ infraCACusto: true });
        setKitInfo({
          ...kitInfo,
          infraCACusto: visit && visit.infra_ca?.custo
        })
        break;
      case 'Fornecedor_InfraCA':
        setOpenEdit({ infraCAFornecedor: true });
        setKitInfo({
          ...kitInfo,
          infraCAFornecedor: visit && visit.infra_ca?.fornecedor
        })
        break;
      case 'DPS':
        setOpenEdit({ dps: true });
        setRedeInfo({
          ...redeInfo,
          dps: visit?.dps ?? 'Sim ✅'
        })
        break;
      case 'EnergiaCA':
        setOpenEdit({ energiaCA: true });
        setRedeInfo({
          ...redeInfo,
          energiaCA: visit?.injecao_energia_ca ?? 'Sim ✅'
        })
        break;
      case 'Disjuntor':
        setOpenEdit({ disjuntor: true });
        setRedeInfo({
          ...redeInfo,
          disjuntor: visit?.trocar_disjuntor ?? 'Sim ✅'
        })
        break;
      case 'Concessionaria':
        setOpenEdit({ concessionaria: true });
        setRedeInfo({
          ...redeInfo,
          concessionaria: visit?.concessionaria ?? 'CPFL'
        })
        break;
      default:
    }
  }

  const confirmEdit = (type) => {
    // if((address.length === 0 && type === 'Address') || (bitola && bitola.bitola_ramal.length === 0 && type === 'Bitola_Ramal') || (bitola && bitola.bitola_cliente.length === 0 && type === 'Bitola_Cliente')) {
    //   return Swal.fire({
    //     title: 'Observação',
    //     html: `Não é possivel enviar um campo em branco.`,
    //     icon: "warning",
    //     showCloseButton: true,
    //     confirmButtonColor: "#111",
    //     confirmButtonText: "Fechar",
    //   })
    // }

    try {
      console.log(redeInfo);
      Swal.fire({
        title: 'Observação',
        html: `Você deseja confirmar o <b>Campo?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let props;
          switch (type) {
            case 'Address':
              props = {
                endereco: address
              }
              break;
            case 'Bitola_Ramal':
              props = {
                medida_bitola_ramal: bitola.bitola_ramal
              }
              break;
            case 'Bitola_Cliente':
              props = {
                medida_bitola_cliente: bitola.bitola_cliente
              }
              break;
            case 'Bitola_Cabo':
              props = {
                cabo_bitola_cliente: bitola.cabo
              }
              break;
            case 'Fornecedor':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  fornecedor: kitInfo.fornecedor
                }
              }
              break;
            case 'Valor_Kit':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  valor: kitInfo.valorKit
                }
              }
              break;
            case 'Custo_Instalacao':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  instalacao: kitInfo.custoInstalacao
                }
              }
              break;
            case 'Valor_Venda':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  venda: kitInfo.valorVenda
                }
              }
              break;
            case 'Potencia_Inversor':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  inversor: kitInfo.potenciaInversor
                }
              }
              break;
            case 'Quantidade_Placas':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  placa_quantidade: kitInfo.quantidadePlacas
                }
              }
              break;
            case 'Potencia_Placas':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  placa_potencia: kitInfo.potenciaPlacas
                }
              }
              break;
            case 'Tipo_Estrutura':
              props = {
                kit_info: {
                  ...visit.kit_info,
                  estrutura: kitInfo.tipoEstrutura
                }
              }
              break;
            case 'Custo_InfraCA':
              props = {
                infra_ca: {
                  ...visit.infra_ca,
                  custo: kitInfo.infraCACusto
                }
              }
              break;
            case 'Fornecedor_InfraCA':
              props = {
                infra_ca: {
                  ...visit.infra_ca,
                  fornecedor: kitInfo.infraCAFornecedor
                }
              }
              break;
            case 'DPS':
              props = {
                dps: redeInfo.dps
              }
              break;
            case 'EnergiaCA':
              props = {
                injecao_energia_ca: redeInfo.energiaCA
              }
              break;
            case 'Disjuntor':
              props = {
                trocar_disjuntor: redeInfo.disjuntor
              }
              break;
            case 'Concessionaria':
              props = {
                concessionaria: redeInfo.concessionaria
              }
              break;
            default:
          }
          await updateDoc(doc(dataBase, 'Visitas', idVisit), props).then(() => {
            Swal.fire({
              title: 'Concluido',
              html: `O campo foi alterado com sucesso.`,
              icon: "success",
              showCloseButton: true,
              confirmButtonColor: "#F39200",
              confirmButtonText: "Ok",
            })

            setOpenEdit({
              address: false,
              bitola_ramal: false,
              bitola_cliente: false,
              bitola_cabo: false
            });
          })
        }
      })
    } catch (error) {
      console.error('Ocorreu um erro: ', error);
    }
  }

  const closeEdit = (type) => {
    switch (type) {
      case 'Address':
        setOpenEdit({ address: false });
        setAddress('');
        break;
      case 'Bitola_Ramal':
        setOpenEdit({ bitola_ramal: false });
        setBitola({
          ...bitola,
          bitola_ramal: visit && visit.medida_bitola_ramal
        })
        break;
      case 'Bitola_Cliente':
        setOpenEdit({ bitola_cliente: false });
        setBitola({
          ...bitola,
          bitola_cliente: visit && visit.medida_bitola_cliente
        })
        break;
      case 'Bitola_Cabo':
        setOpenEdit({ bitola_cabo: false });
        setBitola({
          ...bitola,
          bitola_cabo: visit?.cabo_bitola_cliente || 'Cobre'
        })
        break;
      case 'Fornecedor':
        setOpenEdit({ fornecedor: false });
        setKitInfo({
          ...kitInfo,
          fornecedor: visit && visit.kit_info?.fornecedor
        })
        break;
      case 'Valor_Kit':
        setOpenEdit({ valorKit: false });
        setKitInfo({
          ...kitInfo,
          valorKit: visit && visit.kit_info?.valor
        })
        break;
      case 'Custo_Instalacao':
        setOpenEdit({ custoInstalacao: false });
        setKitInfo({
          ...kitInfo,
          custoInstalacao: visit && visit.kit_info?.instalacao
        })
        break;
      case 'Valor_Venda':
        setOpenEdit({ valorVenda: false });
        setKitInfo({
          ...kitInfo,
          valorVenda: visit && visit.kit_info?.venda
        })
        break;
      case 'Potencia_Inversor':
        setOpenEdit({ potenciaInversor: false });
        setKitInfo({
          ...kitInfo,
          potenciaInversor: visit && visit.kit_info?.inversor
        })
        break;
      case 'Quantidade_Placas':
        setOpenEdit({ quantidadePlacas: false });
        setKitInfo({
          ...kitInfo,
          quantidadePlacas: visit && visit.kit_info?.placa_quantidade
        })
        break;
      case 'Potencia_Placas':
        setOpenEdit({ potenciaPlacas: false });
        setKitInfo({
          ...kitInfo,
          potenciaPlacas: visit && visit.kit_info?.placa_potencia
        })
        break;
      case 'Tipo_Estrutura':
        setOpenEdit({ tipoEstrutura: false });
        setKitInfo({
          ...kitInfo,
          tipoEstrutura: visit && visit.kit_info?.estrutura
        })
        break;
      case 'Custo_InfraCA':
        setOpenEdit({ infraCACusto: false });
        setKitInfo({
          ...kitInfo,
          infraCACusto: visit && visit.infra_ca?.custo
        })
        break;
      case 'Fornecedor_InfraCA':
        setOpenEdit({ infraCAFornecedor: false });
        setKitInfo({
          ...kitInfo,
          infraCAFornecedor: visit && visit.infra_ca?.fornecedor
        })
        break;
      case 'DPS':
        setOpenEdit({ dps: false });
        setRedeInfo({
          ...redeInfo,
          dps: visit && visit.dps
        })
        break;
      case 'EnergiaCA':
        setOpenEdit({ energiaCA: false });
        setRedeInfo({
          ...redeInfo,
          energiaCA: visit && visit.injecao_energia_ca
        })
        break;
      case 'Disjuntor':
        setOpenEdit({ disjuntor: false });
        setRedeInfo({
          ...redeInfo,
          disjuntor: visit && visit.trocar_disjuntor
        })
        break;
      case 'Concessionaria':
        setOpenEdit({ concessionaria: false });
        setRedeInfo({
          ...redeInfo,
          concessionaria: visit && visit.concessionaria
        })
        break;
      default:
    }
  }

  const closeObservation = () => {
    setObservation({
      telhado: refObservation.current.telhado,
      kit: refObservation.current.kit,
      instalacao: refObservation.current.instalacao,
      servico: refObservation.current.servico
    })
    setOpenObservation({
      telhado: false,
      kit: false,
      instalacao: false,
      servico: false
    })
  }

  const confirmInstall = () => {
    try {
      Swal.fire({
        title: 'Instalação',
        html: `Você deseja confirmar a <b>Instalação?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const getInstall = (await getDoc(doc(dataBase, "Membros", visit.instalador?.uid))).data();
          console.log(getInstall)
          const obrasRef = getInstall.obras;

          const obrasComFinalizado = obrasRef.map(obra => {
            if (obra.id === visit.id) {
              return { ...obra, finalizado: true };
            } else {
              return obra;
            }
          });

          await updateDoc(doc(dataBase, 'Visitas', visit.id), {
            instalacao: true,
            confirm_user: userRef && userRef.id
          }).then(async () => {

            await updateDoc(doc(dataBase, "Membros", visit.instalador?.uid), {
              obras: obrasComFinalizado
            })
            axios.post('https://n8n.corpbrasil.cloud/webhook/00ab7e27-4a12-48ef-9959-8ebcd740d843', {
              cliente: visit.cliente,
              cidade: visit.cidade,
              endereco: visit.endereco,
              consultora: visit.consultora,
              instalador: visit.instalador?.nome,
              id: visit.id,
              status: 'Liberado',
              analista: visit.representante?.nome
            })
            Swal.fire({
              title: 'Instalação',
              html: `A Instalação foi comfirmada com sucesso.`,
              icon: "success",
              showCloseButton: true,
              confirmButtonColor: "#111",
              confirmButtonText: "Ok",
            })
          })
        }
      })
    } catch {

    }
  }

  // const confirmAnalyse = async () => {
  //   try {
  //     Swal.fire({
  //       title: 'Análise',
  //       html: `Será <b>necessário</b> realizar alguma melhoria?`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //       if(result.isConfirmed) {
  //         const { value: text } = await Swal.fire({
  //           title: 'Melhorias',
  //           input: "textarea",
  //           html: `Explique abaixo quais melhorias precisa ser realizada</b>.`,
  //           inputPlaceholder: "Digite aqui...",
  //           inputAttributes: {
  //             "aria-label": "Digite aqui"
  //           },
  //           inputValidator: (value) => {
  //             if (!value) {
  //               return "Por favor, digite o motivo para poder enviar";
  //             }
  //           },
  //           showCancelButton: true,
  //           showCloseButton: true,
  //           confirmButtonColor: "#0eb05f",
  //           cancelButtonColor: "#111",
  //           confirmButtonText: "Enviar",
  //           cancelButtonText: "Fechar",
  //         });

  //         if(text) {
  //           await updateDoc(doc(dataBase, 'Visitas', visit.id), {
  //             analise: {
  //               status: true,
  //               responsavel: userRef && userRef.nome_ref,
  //               id: userRef && userRef.id,
  //               data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
  //             }
  //           }).then(async () => {
  //             let phone;
  //             const consultoraDoc = await getDoc(doc(dataBase, 'Membros', visit.uid));
  //             if(consultoraDoc.exists()) {
  //               const consultora = consultoraDoc.data();
  //               phone = consultora.telefone
  //             }
  //             axios.post('https://n8n.corpbrasil.cloud/webhook-test/b2f7831e-ffde-43a4-bd3c-b5ac48f99410', {
  //               cliente: visit.cliente,
  //               cidade: visit.cidade,
  //               endereco: visit.endereco,
  //               consultora: visit.consultora,
  //               marcado: visit.chegadaCliente,
  //               dia: moment(visit.data).format('DD/MM/YYYY'),
  //               concessionaria: visit.concessionaria,
  //               id: visit.id,
  //               telefone: phone,
  //               analista_ref: visit.representante?.nome,
  //               analista: userRef && userRef.nome_ref,
  //               melhoria: text,
  //               telefone_representante: visit.indicador && visit.indicador.telefone ? visit.indicador.telefone : null,
  //               nome_representante: visit.indicador && visit.indicador.nome ? visit.indicador.nome : null,
  //             })
  //             Swal.fire({
  //               title: 'Análise',
  //               html: `A Análise da Visita foi comfirmada com sucesso.`,
  //               icon: "success",
  //               showCloseButton: true,
  //               confirmButtonColor: "#111",
  //               confirmButtonText: "Ok",
  //               })
  //           })
  //         }
  //       } else if(result.isDenied) {
  //         await updateDoc(doc(dataBase, 'Visitas', visit.id), {
  //           analise: {
  //             status: true,
  //             responsavel: userRef && userRef.nome_ref,
  //             id: userRef && userRef.id,
  //             data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
  //           }
  //         }).then(async () => {
  //           let phone;
  //             const consultoraDoc = await getDoc(doc(dataBase, 'Membros', visit.uid));
  //             if(consultoraDoc.exists()) {
  //               const consultora = consultoraDoc.data();
  //               phone = consultora.telefone
  //             }
  //           axios.post('https://n8n.corpbrasil.cloud/webhook-test/b2f7831e-ffde-43a4-bd3c-b5ac48f99410', {
  //             cliente: visit.cliente,
  //             cidade: visit.cidade,
  //             endereco: visit.endereco,
  //             consultora: visit.consultora,
  //             marcado: visit.chegadaCliente,
  //             dia: moment(visit.data).format('DD/MM/YYYY'),
  //             concessionaria: visit.concessionaria,
  //             id: visit.id,
  //             telefone: phone,
  //             analista_ref: visit.representante?.nome,
  //             analista: userRef && userRef.nome_ref,
  //             melhoria: 'Nenhuma',
  //             telefone_representante: visit.indicador && visit.indicador.telefone ? visit.indicador.telefone : null,
  //             nome_representante: visit.indicador && visit.indicador.nome ? visit.indicador.nome : null,
  //           })
  //           Swal.fire({
  //             title: 'Análise',
  //             html: `A Análise da Visita foi comfirmada com sucesso.`,
  //             icon: "success",
  //             showCloseButton: true,
  //             confirmButtonColor: "#111",
  //             confirmButtonText: "Ok",
  //             })
  //         })
  //       }
  //     })
  //   } catch (error) {
  //     console.log('[ERRO]: ', error)
  //   }
  // }

  const confirmAnalyse = async () => {
    try {
      const firstResult = await Swal.fire({
        title: 'Análise',
        html: `Você deseja confirmar a <b>Análise da Visita</b>?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (firstResult.isConfirmed) {
        const secondResult = await Swal.fire({
          title: 'Análise',
          html: `Será <b>necessário</b> realizar alguma melhoria?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (secondResult.isConfirmed) {
          const { value: text } = await Swal.fire({
            title: 'Melhorias',
            input: "textarea",
            html: `Explique abaixo quais melhorias precisam ser realizadas.</b>`,
            inputPlaceholder: "Digite aqui...",
            inputAttributes: {
              "aria-label": "Digite aqui"
            },
            inputValidator: (value) => {
              if (!value) {
                return "Por favor, digite o motivo para poder enviar";
              }
            },
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#111",
            confirmButtonText: "Enviar",
            cancelButtonText: "Fechar",
          });

          if (text) {
            await saveAnalysis(true, text);
          }
        } else {
          await saveAnalysis(true, 'Nenhuma');
        }
      }
    } catch (error) {
      console.log('[ERRO]: ', error);
    }
  };

  const saveAnalysis = async (status, improvement) => {
    try {
      await updateDoc(doc(dataBase, 'Visitas', visit.id), {
        analise: {
          status,
          responsavel: userRef?.nome_ref,
          id: userRef?.id,
          data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
        }
      });

      const consultoraDoc = await getDoc(doc(dataBase, 'Membros', visit.uid));
      let phone = '';

      if (consultoraDoc.exists()) {
        const consultora = consultoraDoc.data();
        phone = consultora.telefone;
      }

      await axios.post('https://n8n.corpbrasil.cloud/webhook/b2f7831e-ffde-43a4-bd3c-b5ac48f99410', {
        cliente: visit.cliente,
        cidade: visit.cidade,
        endereco: visit.endereco,
        consultora: visit.consultora,
        marcado: visit.chegadaCliente,
        dia: moment(visit.data).format('DD/MM/YYYY'),
        concessionaria: visit.concessionaria,
        id: visit.id,
        telefone: phone,
        analista_ref: visit.representante?.nome,
        analista: userRef?.nome_ref,
        melhoria: improvement,
        telefone_representante: visit.indicador?.telefone ?? null,
        nome_representante: visit.indicador?.nome ?? null,
      });

      await Swal.fire({
        title: 'Análise',
        html: `A Análise da Visita foi confirmada com sucesso.`,
        icon: "success",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.log('[ERRO]: ', error);
    }
  };

  // const handleObs = (array, value, index, type) => {
  //   // Crie uma cópia do array original
  //   const newArray = Array.isArray(array) ? array.slice() : [];
  //   // Modifique o elemento no índice desejado
  //   newArray[index] = value;
  //   // Atualize o estado com o novo array modificado
  //   setObservations({...observations, [type]: newArray});
  //   // console.log(!areObjectsEqual(obsRef, {...observations, [type]: newArray}))
  //   setButtonSave(!areObjectsEqual(obsRef, {...observations, [type]: newArray}))
  // };

  const handleObs = (array, value, index, type) => {
    // Crie uma cópia do array original ou um novo array se for indefinido
    const newArray = Array.isArray(array) ? array.slice() : [];

    // Inicialize os elementos do array até o índice desejado se estiverem indefinidos
    for (let i = 0; i <= index; i++) {
      if (typeof newArray[i] === 'undefined') {
        newArray[i] = null; // ou qualquer valor padrão que você queira
      }
    }

    // Modifique o elemento no índice desejado
    newArray[index] = value;

    // Atualize o estado com o novo array modificado
    setObservations(prevObservations => ({
      ...prevObservations,
      [type]: newArray
    }));

    // Verifique se há mudanças em comparação com a referência e atualize o botão de salvar
    setButtonSave(!areObjectsEqual(obsRef, {
      ...observations,
      [type]: newArray
    }));
  }

  function areObjectsEqual(obj1, obj2) {
    // Verificar se ambos os parâmetros são objetos
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
    }

    // Verificar se o número de chaves é o mesmo
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Verificar se todas as chaves e valores são iguais
    for (let key of keys1) {
      const value1 = obj1[key];
      const value2 = obj2[key];

      // Verificar se os valores são objetos e, se forem, comparar recursivamente
      if (typeof value1 === 'object' && typeof value2 === 'object') {
        if (!areObjectsEqual(value1, value2)) {
          return false;
        }
      } else {
        // Verificar se os valores são iguais
        if (value1 !== value2) {
          return false;
        }
      }
    }

    // Se todas as verificações passarem, os objetos são iguais
    return true;
  }

  const handleDelete = async (index, stepRef, type, url) => {
    // Exibir o modal de confirmação usando SweetAlert
    const result = await Swal.fire({
      title: 'Atenção!',
      html: 'Você deseja <b>excluir</b> o arquivo?',
      icon: 'question',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#0eb05f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    });

    // Verificar se o usuário confirmou a exclusão
    if (result.isConfirmed) {
      try {
        const storage = getStorage();
        console.log(step[stepRef].ref)
        if (!step[stepRef].ref) {
          return Swal.fire({
            title: 'Erro',
            html: 'Esse arquivo foi enviado <b>antes</b> do sistema de referência e por esse motivo não é possivel excluir por aqui.',
            icon: 'error',
            showCloseButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Ok',
          });
        }
        const refLink = step[stepRef].ref[type][index];
        const fileRef = ref(storage, refLink);

        console.log('Ref Link:', refLink);
        console.log('URL:', url);

        // Descomente a linha abaixo para realmente deletar o arquivo do Storage
        await deleteObject(fileRef);

        // Filtrar o arquivo excluído das listas de URLs e referências
        const newStep = step[stepRef][type].filter((data) => data !== url);
        const newRef = step[stepRef].ref[type].filter((data) => data !== refLink);

        // Atualizar o objeto de referências
        const newPhotos = {
          ...step[stepRef].ref,
          [type]: newRef,
        };

        console.log(visit.id)

        // Atualizar o documento no Firestore
        await updateDoc(doc(dataBase, 'Visitas', visit.id, 'Tecnica', step[stepRef].id), {
          [type]: newStep,
          ref: newPhotos,
        });

        // Exibir mensagem de sucesso
        await Swal.fire({
          title: 'Arquivos',
          html: 'O arquivo foi excluído com sucesso.',
          icon: 'success',
          showCloseButton: true,
          confirmButtonColor: '#111',
          confirmButtonText: 'Ok',
        });

      } catch (error) {
        console.log('ERRO:', error);
        // Exibir mensagem de erro
        await Swal.fire({
          title: 'Erro',
          html: 'Ocorreu um erro ao tentar excluir o arquivo.',
          icon: 'error',
          showCloseButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Ok',
        });
      }
    }
  };

  return (
    <div className={style.pdf_main}>
      <HelmetProvider>
        <Helmet>
          <title>
            Cliente {visit?.cliente || ''} | CORPBRASIL Energia Renovável</title>
          <meta name="title" content={`Cliente ${visit?.cliente || ''} | CORPBRASIL Energia Renovável`} />
          <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
        </Helmet>
      </HelmetProvider>
      <div className={style.confirm_buttom}>
        {userRef && ((userRef.nome === 'Pós-Venda' && userRef.id === visit.representante?.id) || userRef.cargo === 'Administrador') &&
          <>{buttonSave ?
            <button
              aria-label="Salvar Observação"
              style={{ backgroundColor: "#063" }}
              data-cooltipz-dir="left"
              onClick={() => saveObservation()}>
              <SaveIcon sx={{ fill: '#fff' }} />
            </button> : null
          }
            <button
              aria-label={visit && visit.analise ? "Análise da Visita Confirmada" : "Confirmar Análise da Visita"}
              data-cooltipz-dir="left"
              disabled={visit && visit.analise}
              onClick={() => confirmAnalyse()}>
              <PlaylistAddCheckIcon sx={{ fill: '#fff' }} />
            </button>
            <button
              aria-label={visit && visit.instalacao ? "Instalação Confirmada" : "Confirmar Instalação"}
              data-cooltipz-dir="left"
              disabled={visit && visit.instalacao}
              onClick={() => confirmInstall()}>
              {visit && visit.instalacao ?
                <DoneAllIcon sx={{ fill: '#fff' }} /> :
                <CheckIcon sx={{ fill: '#fff' }} />
              }
            </button></>
        }
        <Link to={'/'}>
          <button
            aria-label={'Voltar'}
            data-cooltipz-dir="left">
            <ReplyIcon sx={{ fill: '#fff' }} />
          </button>
        </Link>
      </div>
      <div id="PDF" className={style.pdf}>
        <div className={style.pdf_header}>
          <Link to={'/'}>
            <img className={style.pdf_logo} src={Logo} alt="CORPBRASIL" />
          </Link>
          <select
            className={style.pdf_title}
            value={relatorio}
            disabled={userRef && userRef.cargo !== 'Administrador' ? true : false}
            onChange={(e) => setRelatorio(e.target.value)}
          >
            <option value="Relatório Contrato">Relatório Contrato</option>
            <option value="Relatório Administrativo">Relatório Administrativo</option>
          </select>
          <h1>Anexo I</h1>
          <h1>Visita Comercial + Técnica</h1>
          <h2>{visit.cliente}</h2>
        </div>
        <div className={style.pdf_item_container}>
          <h2>Informações</h2>
          <ul className={style.pdf_text}>
            <li>
              <b>Data da Visita:</b> {moment(visit.data).format("DD/MM/YYYY")}
            </li>
            <li>
              <b>Cidade:</b> {visit.cidade ? visit.cidade : 'Carregando'}
            </li>
            <li>
              <b>Consultora:</b> {visit.consultora ? visit.consultora : 'Carregando'}
            </li>
            <li>
              <b>Técnico:</b>{visit.tecnico ? visit.tecnico : 'Carregando'}
            </li>
            <li>
              <b>Endereço do Cliente:</b>
              {openEdit && openEdit.address ?
                <><input
                  type="text"
                  className={style.input_address}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)} />
                  <div>
                    <IconButton
                      className={style.print}
                      aria-label="Confirmar Endereço"
                      data-cooltipz-dir="top"
                      size="small"
                      color="success"
                      sx={{ marginLeft: '1rem' }}
                      onClick={() => confirmEdit('Address')}
                    >
                      <CheckIcon />
                    </IconButton><IconButton
                      className={style.print}
                      aria-label="Cancelar"
                      data-cooltipz-dir="top"
                      color="error"
                      size="small"
                      onClick={() => closeEdit('Address')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </> :
                <p> {visit && visit.endereco}
                  {checkStep.step1 ?
                    <><a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${info && info.gps_padrao.lat},${info && info.gps_padrao.lng}`}> Clique aqui para visualizar o GPS</a>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                        <IconButton
                          className={style.print}
                          aria-label="Alterar Endereço"
                          data-cooltipz-dir="top"
                          size="small"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => changeOpenEdit('Address')}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    </>
                    : null}
                </p>
              }
            </li>
            <li>
              <p>
                <b>Mora em Condomínio? </b> {visit && visit.condominio ? visit.condominio : 'Não Informado'}
              </p>
              {visit && visit.condominio === 'Sim ✅' && checkStep.step4 &&
                <p><b>Horário:</b> {info.horario ? info.horario.entrada : 'Não Informado'} - {info.horario ? info.horario.saida : 'Não Informado'}</p>
              }
            </li>
            <li>
              <p><b>Comissionamento será via Wi-fi?</b> {info && info.comissionamento ? info.comissionamento : 'Não Informado'}</p>
            </li>
          </ul>
        </div>
        <div className={style.pdf_item_container}>
          <h2>Detalhes da Rede de Energia</h2>
          <ul className={style.pdf_text}>
            <li>
              <b>Concessionária de Energia:</b>{" "}
              {openEdit && openEdit.concessionaria ?
                <>
                  <select
                    className={style.input_address}
                    style={{ width: '6rem' }}
                    value={redeInfo.concessionaria}
                    onChange={(e) => setRedeInfo({ ...redeInfo, concessionaria: e.target.value })}>
                    <option value="CPFL">CPFL</option>
                    <option value="Cerim">Cerim</option>
                    <option value="Ceripa">Ceripa</option>
                    <option value="Elektro">Elektro</option>
                    <option value="Enel">Enel</option>
                  </select>
                  <div>
                    <IconButton
                      className={style.print}
                      aria-label="Confirmar Concessionária"
                      data-cooltipz-dir="top"
                      size="small"
                      color="success"
                      sx={{ marginLeft: '1rem' }}
                      onClick={() => confirmEdit('Concessionaria')}
                    >
                      <CheckIcon />
                    </IconButton><IconButton
                      className={style.print}
                      aria-label="Cancelar"
                      data-cooltipz-dir="top"
                      color="error"
                      size="small"
                      onClick={() => closeEdit('Concessionaria')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </> :
                <p> {visit.concessionaria ? visit.concessionaria : "Não Informado"}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.uid === idTec || userRef.cargo === 'Administrador') &&
                        <IconButton
                          className={style.print}
                          aria-label="Alterar Concessionária"
                          data-cooltipz-dir="top"
                          size="small"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => changeOpenEdit('Concessionaria')}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    </>
                    : null}
                </p>}
            </li>
            <li>
              <b>Medida da Bitola do Ramal de Entrada: </b>
              {openEdit && openEdit.bitola_ramal ?
                <>
                  <input
                    type="text"
                    className={style.input_address}
                    style={{ width: '10rem' }}
                    value={bitola.bitola_ramal}
                    onChange={(e) => setBitola({ ...bitola, bitola_ramal: e.target.value })} />
                  <div>
                    <IconButton
                      className={style.print}
                      aria-label="Confirmar Medida da Bitola"
                      data-cooltipz-dir="top"
                      size="small"
                      color="success"
                      sx={{ marginLeft: '1rem' }}
                      onClick={() => confirmEdit('Bitola_Ramal')}
                    >
                      <CheckIcon />
                    </IconButton><IconButton
                      className={style.print}
                      aria-label="Cancelar"
                      data-cooltipz-dir="top"
                      color="error"
                      size="small"
                      onClick={() => closeEdit('Bitola_Ramal')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </> :
                <p> {visit && visit.medida_bitola_ramal}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.uid === idTec || userRef.cargo === 'Administrador') &&
                        <IconButton
                          className={style.print}
                          aria-label="Alterar Medida da Bitola"
                          data-cooltipz-dir="top"
                          size="small"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => changeOpenEdit('Bitola_Ramal')}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    </>
                    : 'Não Informado'}
                </p>}
            </li>
            <li>
              <div className={style.info}>
                <b>Medida da Bitola da Rede do Cliente: </b>
                {openEdit && openEdit.bitola_cliente ?
                  <>
                    <input
                      type="text"
                      className={style.input_address}
                      style={{ width: '10rem' }}
                      value={bitola.bitola_cliente}
                      onChange={(e) => setBitola({ ...bitola, bitola_cliente: e.target.value })} />
                    <div>
                      <IconButton
                        className={style.print}
                        aria-label="Confirmar Medida da Bitola"
                        data-cooltipz-dir="top"
                        size="small"
                        color="success"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => confirmEdit('Bitola_Cliente')}
                      >
                        <CheckIcon />
                      </IconButton><IconButton
                        className={style.print}
                        aria-label="Cancelar"
                        data-cooltipz-dir="top"
                        color="error"
                        size="small"
                        onClick={() => closeEdit('Bitola_Cliente')}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </> :
                  <p> {visit && visit.medida_bitola_cliente}
                    {checkStep.step1 ?
                      <>
                        {userRef && (userRef.nome === 'Pós-Venda' || userRef.uid === idTec || userRef.cargo === 'Administrador') &&
                          <IconButton
                            className={style.print}
                            aria-label="Alterar Medida da Bitola"
                            data-cooltipz-dir="top"
                            size="small"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => changeOpenEdit('Bitola_Cliente')}
                          >
                            <EditIcon />
                          </IconButton>
                        }
                      </>
                      : 'Não Informado'}
                  </p>}
              </div>
              <div className={style.info}>
                <b>Cabo da Rede do Cliente: </b>
                {openEdit && openEdit.bitola_cabo ?
                  <>
                    <select
                      className={style.input_address}
                      style={{ width: '13rem' }}
                      value={bitola.cabo}
                      onChange={(e) => setBitola({ ...bitola, cabo: e.target.value })}>
                      <option value="Cobre">Cobre</option>
                      <option value="Aluminio Simples">Alumínio Simples</option>
                      <option value="Aluminio Quadruplex">Alumínio Quadruplex</option>
                    </select>
                    <div>
                      <IconButton
                        className={style.print}
                        aria-label="Confirmar Medida da Bitola"
                        data-cooltipz-dir="top"
                        size="small"
                        color="success"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => confirmEdit('Bitola_Cabo')}
                      >
                        <CheckIcon />
                      </IconButton><IconButton
                        className={style.print}
                        aria-label="Cancelar"
                        data-cooltipz-dir="top"
                        color="error"
                        size="small"
                        onClick={() => closeEdit('Bitola_Cabo')}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </> :
                  <p> {visit?.cabo_bitola_cliente ?? 'Não Informado'}
                    {checkStep.step1 ?
                      <>
                        {userRef && (userRef.nome === 'Pós-Venda' || userRef.uid === idTec || userRef.cargo === 'Administrador') &&
                          <IconButton
                            className={style.print}
                            aria-label="Alterar Medida da Bitola"
                            data-cooltipz-dir="top"
                            size="small"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => changeOpenEdit('Bitola_Cabo')}
                          >
                            <EditIcon />
                          </IconButton>
                        }
                      </>
                      : null}
                  </p>}
              </div>
            </li>
            {visit.quantidadeCaboInversor ?
              <li>
                <b>Distância do Inversor até o Padrão:</b>
                <p> {visit.quantidadeCaboInversor && visit.quantidadeCaboInversor}</p>
              </li> : null
            }
            <li>
              <b>Possui DPS?</b>
              {openEdit && openEdit.dps ?
                <>
                  <select
                    className={style.input_address}
                    style={{ width: '6rem' }}
                    value={redeInfo.dps}
                    onChange={(e) => setRedeInfo({ ...redeInfo, dps: e.target.value })}>
                    <option value="Sim ✅">Sim ✅</option>
                    <option value="Não ❌">Não ❌</option>
                  </select>
                  <div>
                    <IconButton
                      className={style.print}
                      aria-label="Confirmar DPS"
                      data-cooltipz-dir="top"
                      size="small"
                      color="success"
                      sx={{ marginLeft: '1rem' }}
                      onClick={() => confirmEdit('DPS')}
                    >
                      <CheckIcon />
                    </IconButton><IconButton
                      className={style.print}
                      aria-label="Cancelar"
                      data-cooltipz-dir="top"
                      color="error"
                      size="small"
                      onClick={() => closeEdit('DPS')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </> :
                <p> {visit.dps ? visit.dps : "Não informado pelo Técnico"}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.uid === idTec || userRef.cargo === 'Administrador') &&
                        <IconButton
                          className={style.print}
                          aria-label="Alterar"
                          data-cooltipz-dir="top"
                          size="small"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => changeOpenEdit('DPS')}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    </>
                    : null}
                </p>}
            </li>
            <li>
              <b>A injeção de Energia CA obrigatoriamente deverá ser no Disjuntor geral? </b>
              {openEdit && openEdit.energiaCA ?
                <>
                  <select
                    className={style.input_address}
                    style={{ width: '6rem' }}
                    value={redeInfo.energiaCA}
                    onChange={(e) => setRedeInfo({ ...redeInfo, energiaCA: e.target.value })}>
                    <option value="Sim ✅">Sim ✅</option>
                    <option value="Não ❌">Não ❌</option>
                  </select>
                  <div>
                    <IconButton
                      className={style.print}
                      aria-label="Confirmar Injeção de Energia CA"
                      data-cooltipz-dir="top"
                      size="small"
                      color="success"
                      sx={{ marginLeft: '1rem' }}
                      onClick={() => confirmEdit('EnergiaCA')}
                    >
                      <CheckIcon />
                    </IconButton><IconButton
                      className={style.print}
                      aria-label="Cancelar"
                      data-cooltipz-dir="top"
                      color="error"
                      size="small"
                      onClick={() => closeEdit('EnergiaCA')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </> :
                <p> {visit.injecao_energia_ca ?? "Não informado pelo Técnico"}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.uid === idTec || userRef.cargo === 'Administrador') &&
                        <IconButton
                          className={style.print}
                          aria-label="Alterar"
                          data-cooltipz-dir="top"
                          size="small"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => changeOpenEdit('EnergiaCA')}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    </>
                    : null}
                </p>}
            </li>
            <li>
              <b>Precisa trocar o Disjuntor? </b>
              {openEdit && openEdit.disjuntor ?
                <>
                  <select
                    className={style.input_address}
                    style={{ width: '6rem' }}
                    value={redeInfo?.disjuntor ?? ''}
                    onChange={(e) => setRedeInfo({ ...redeInfo, disjuntor: e.target.value || 'Sim ✅' })}>
                    <option value="Sim ✅">Sim ✅</option>
                    <option value="Não ❌">Não ❌</option>
                  </select>
                  <div>
                    <IconButton
                      className={style.print}
                      aria-label="Confirmar Disjuntor"
                      data-cooltipz-dir="top"
                      size="small"
                      color="success"
                      sx={{ marginLeft: '1rem' }}
                      onClick={() => confirmEdit('Disjuntor')}
                    >
                      <CheckIcon />
                    </IconButton><IconButton
                      className={style.print}
                      aria-label="Cancelar"
                      data-cooltipz-dir="top"
                      color="error"
                      size="small"
                      onClick={() => closeEdit('Disjuntor')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </> :
                <p> {visit.trocar_disjuntor ?? "Não informado pelo Técnico"}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.uid === idTec || userRef.cargo === 'Administrador') &&
                        <IconButton
                          className={style.print}
                          aria-label="Alterar"
                          data-cooltipz-dir="top"
                          size="small"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => changeOpenEdit('Disjuntor')}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    </>
                    : null}
                </p>}
            </li>
          </ul>
        </div>
        <div className={style.pdf_item_container}>
          <h2>Telhado</h2>
          {checkStep.step3 ?
            <ul className={style.pdf_text}>
              <li>
                <b>Altura da Casa:</b> {info.altura_telhado ? info.altura_telhado : 'Não Informado'}
              </li>
              {info.telhado.map((data, index) => (
                <div key={index}>
                  <li>
                    <b>Tipo:</b> {data.tipo}
                  </li>
                  <li>
                    <b>Medida:</b> {data.medida}
                  </li>
                </div>
              ))}
            </ul> :
            <ul className={style.pdf_text}>
              <li>
                <b>Altura da Casa:</b> {info && info.altura_telhado ? info.altura_telhado : 'Não Informado'}
              </li>
              <div>
                <li>
                  <b>Tipo:</b> Não Informado
                </li>
                <li>
                  <b>Medida:</b> Não Informado
                </li>
              </div>
            </ul>}
        </div>
        <h1>Fotos</h1>
        <div className={style.pdf_item_container}>
          <h2>Entrada</h2>
          {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
            <div className={style.report}>
              <ThemeProvider theme={theme}>
                {visit.analise_status && visit.analise_status === 'Aberto' &&
                  <Button
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    variant="contained"
                    onClick={() => sendAnalyse()}
                    startIcon={<ArticleIcon />}>Gerar Relátorio</Button>
                }
                {visit.analise_status && visit.analise_status === 'Em Andamento' &&
                  <Link to='relatorio' target="_blank">
                    <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      variant="contained"
                      color="warning"
                      startIcon={<ArticleIcon />}>Visualizar</Button>
                  </Link>
                }
                {visit.analise_status && visit.analise_status === 'Em Andamento' &&
                  <Button
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    variant="contained"
                    color="success"
                    onClick={() => setOpenAnotation(true)}
                    startIcon={<CheckIcon />}>Concluir</Button>
                }
                {visit.analise_status && visit.analise_status === 'Concluido' &&
                  <Button variant="outlined" color="success" disabled startIcon={<CheckIcon />}>Análise Concluida</Button>
                }
              </ThemeProvider>
              {visit && <Analyse visit={visit} open={openAnotation} setOpenAnotation={setOpenAnotation} />}
            </div>
          }
          <div className={style.photo_content}>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(0)}>
                <h2>Fachada</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[0] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[0]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {fachada ?
                    fachada.map((photo, index) => (
                      <>
                        <div className={style.img_pdf}>
                          <img
                            key={index}
                            rel="preconnect"
                            src={photo}
                            alt=""
                          />
                          {checkPermission() ?
                            <button onClick={() => handleDelete(index, 'step1', 'fachada', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null
                          }
                        </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.fachada[index]}
                              onChange={(e) => handleObs(observations.fachada, e.target.value, index, 'fachada')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {fachada &&
                  fachada.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(1)}>
                <h2>Poste</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[1] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[1]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {poste ?
                    poste.map((photo, index) => (
                      <>
                        <div className={style.img_pdf}>
                          <img
                            key={index}
                            rel="preconnect"
                            src={photo}
                            alt=""
                          />
                          {checkPermission() ?
                            <button onClick={() => handleDelete(index, 'step1', 'poste', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                        </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.poste[index]}
                              onChange={(e) => handleObs(observations.poste, e.target.value, index, 'poste')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {poste &&
                  poste.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(2)}>
                <h2>Bengala e Entrada do Ramal de Energia</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[2] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[2]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {bengala ?
                    bengala.map((photo, index) => (
                      <>
                        <div className={style.img_pdf}>
                          <img
                            key={index}
                            rel="preconnect"
                            src={photo}
                            alt=""
                          />
                          {checkPermission() ?
                            <button onClick={() => handleDelete(index, 'step1', 'bengala', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                        </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.bengala[index]}
                              onChange={(e) => handleObs(observations.bengala, e.target.value, index, 'bengala')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {bengala &&
                  bengala.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(3)}>
                <h2>Caixa do Padrão</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[3] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[3]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {padrao ?
                    padrao.map((photo, index) => (
                      <>
                        <div className={style.img_pdf}>
                          <File url={photo} index={index} />
                          {checkPermission() ?
                            <button onClick={() => handleDelete(index, 'step1', 'padrao', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                        </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.padrao[index]}
                              onChange={(e) => handleObs(observations.padrao, e.target.value, index, 'padrao')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {padrao &&
                  padrao.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(4)}>
                <h2>Relógio</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[4] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[4]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {relogio ?
                    relogio.map((photo, index) => (
                      <>
                        <div className={style.img_pdf}>
                          <img
                            key={index}
                            rel="preconnect"
                            src={photo}
                            alt=""
                          />
                          {checkPermission() ?
                            <button onClick={() => handleDelete(index, 'step1', 'relogio', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null
                          }
                        </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.relogio[index]}
                              onChange={(e) => handleObs(observations.relogio, e.target.value, index, 'relogio')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {relogio &&
                  relogio.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(5)}>
                <h2>Disjuntor</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[5] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[5]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {disjuntor ?
                    disjuntor.map((photo, index) => (
                      <><div className={style.img_pdf}>
                        <img
                          key={index}
                          rel="preconnect"
                          src={photo}
                          alt=""
                        />
                        {checkPermission() ?
                          <button onClick={() => handleDelete(index, 'step1', 'disjuntor', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                      </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.disjuntor[index]}
                              onChange={(e) => handleObs(observations.disjuntor, e.target.value, index, 'disjuntor')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {disjuntor &&
                  disjuntor.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(6)}>
                <h2>Amperímetro</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[6] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[6]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {amperimetro ?
                    amperimetro.map((photo, index) => (
                      <><div className={style.img_pdf}>
                        <img
                          key={index}
                          rel="preconnect"
                          src={photo}
                          alt=""
                        />
                        {checkPermission() ?
                          <button onClick={() => handleDelete(index, 'step1', 'amperimetro', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                      </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.amperimetro[index]}
                              onChange={(e) => handleObs(observations.amperimetro, e.target.value, index, 'amperimetro')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {amperimetro &&
                  amperimetro.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggle(7)}>
                <h2>Quadro de Distribuição</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhoto[7] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhoto[7]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {quadro ?
                    quadro.map((photo, index) => (
                      <><div className={style.img_pdf}>
                        <img
                          key={index}
                          rel="preconnect"
                          src={photo}
                          alt=""
                        />
                        {checkPermission() ?
                          <button onClick={() => handleDelete(index, 'step1', 'quadro', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                      </div>
                        <div className={style.create_observation}>
                          <ThemeProvider theme={theme2}>
                            <h3>Observação sobre a imagem</h3>
                            <TextField
                              sx={{ width: '90%' }}
                              multiline
                              label='Observação'
                              inputProps={{ min: 0, style: { textAlign: 'center' } }}
                              rows={4}
                              value={observations && observations.quadro[index]}
                              onChange={(e) => handleObs(observations.quadro, e.target.value, index, 'quadro')} />
                          </ThemeProvider>
                        </div></>
                    )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {quadro &&
                  quadro.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            {localInversor ?
              <div className={style.photo_container}>
                <div className={style.photo_title} onClick={() => handleToggle(8)}>
                  <h2>Local da Instalação do Inversor</h2>
                  <IconButton
                    aria-label="Expandir"
                    data-cooltipz-dir="left"
                    size="small"
                  >
                    {openPhoto[8] ? (
                      <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                    )}
                  </IconButton>
                </div>
                <Collapse in={openPhoto[8]} timeout="auto" unmountOnExit>
                  <div className={`${style.photos} ${style.print}`}>
                    {localInversor ?
                      localInversor.map((photo, index) => (
                        <><div className={style.img_pdf}>
                          <img
                            key={index}
                            rel="preconnect"
                            src={photo}
                            alt=""
                          />
                          {checkPermission() ?
                            <button onClick={() => handleDelete(index, 'step1', 'localInversor', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                        </div>
                          <div className={style.create_observation}>
                            <ThemeProvider theme={theme2}>
                              <h3>Observação sobre a imagem</h3>
                              <TextField
                                sx={{ width: '90%' }}
                                multiline
                                label='Observação'
                                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                rows={4}
                                value={observations && observations.quadro[index]}
                                onChange={(e) => handleObs(observations.quadro, e.target.value, index, 'quadro')} />
                            </ThemeProvider>
                          </div></>
                      )) : <p className={style.pdf_alert}>Aguardando o Técnico</p>}
                  </div>
                </Collapse>
                <div className={`${style.photos} ${style.print_on}`}>
                  {localInversor &&
                    localInversor.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <img
                          key={index}
                          rel="preconnect"
                          src={photo}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
              </div> : null
            }
          </div>
          <h2>Telhado</h2>
          <Box sx={{ border: '1px solid #f39200', marginBottom: '1rem' }}>
            <div className={style.photo_content_grid}>
              {photosStep2Perto &&
                photosStep2Perto.map((photo, index) => (
                  <div className={style.img_pdf} key={index}>
                    <img
                      className={style.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                    {checkPermission() ?
                      <button onClick={() => handleDelete(index, 'step2', 'telhadoPerto', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                  </div>
                ))}
              {photosStep2Longe &&
                photosStep2Longe.map((photo, index) => (
                  <div className={style.img_pdf} key={index}>
                    <img
                      className={style.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                    {checkPermission() ?
                      <button onClick={() => handleDelete(index, 'step2', 'telhadoLonge', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                  </div>
                ))}
              {photosStep2Video &&
                photosStep2Video.map((photo, index) => (
                  <div className={style.img_pdf} key={index}>
                    <video
                      className={`${style.img_pdf} ${style.print}`}
                      width="100%"
                      controls
                      muted
                      playsInline
                    >
                      <source src={photo} type="video/mp4" />
                    </video>
                    <a href={photo} target="_blank" rel="noreferrer" className={`${style.view_link} ${style.print_on}`}>Clique aqui para visualizar o Video do telhado</a>
                    {checkPermission() ?
                      <button onClick={() => handleDelete(index, 'step2', 'telhadoVideo', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                  </div>
                ))}
              <div className={style.create_observation} style={{ gridColumn: 'span 2' }}>
                <ThemeProvider theme={theme2}>
                  <h3>Observação sobre o telhado</h3>
                  <TextField
                    sx={{ width: '90%' }}
                    multiline
                    label='Observação'
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    rows={4}
                    value={observations && observations.telhado[0]}
                    onChange={(e) => handleObs(observations.telhado, e.target.value, 0, 'telhado')} />
                </ThemeProvider>
              </div>
            </div>
          </Box>
          <h2>Telhado com Medida</h2>
          <div className={style.photo_content}>
            {photosStep3 && photosStep3.length > 0 &&
              photosStep3.map((photo, index) => (
                <div className={style.img_pdf} key={index}>
                  <img
                    // className={style.img_pdf}
                    rel="preconnect"
                    src={photo}
                    alt=""
                  />
                  {checkPermission() ?
                    <button onClick={() => handleDelete(index, 'step3', 'telhadoMedida', photo)}><CloseIcon sx={{ fontSize: '20px' }} /></button> : null}
                </div>
              ))}
            <div className={style.create_observation}>
              <ThemeProvider theme={theme2}>
                <h3>Observação sobre as medidas do telhado</h3>
                <TextField
                  sx={{ width: '90%' }}
                  multiline
                  label='Observação'
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  rows={4}
                  value={observations && observations.telhado_medida[0]}
                  onChange={(e) => handleObs(observations.telhado_medida, e.target.value, 0, 'telhado_medida')} />
              </ThemeProvider>
            </div>
          </div>
          {/* <h2>Conclusão sobre o Telhado</h2>
        <div className={style.obs_container}>
          {openObservation && openObservation.telhado ? 
          <div className={style.create_observation}>
            <ThemeProvider theme={theme2}>
              <h3>Conclusão</h3>
              <TextField 
              sx={{ width: '90%' }}
              multiline
              inputProps={{min: 0, style: { textAlign: 'center' }}}
              rows={4} 
              value={observation && observation.telhado}
              onChange={(e) => setObservation({...observation, telhado: e.target.value})}/>
            </ThemeProvider>
            <div className={style.create_observation_buttons}>
              <Button 
                disableElevation
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                color="success" 
                onClick={() => sendObservation('Telhado')}>Confirmar</Button>
              <Button 
                disableElevation
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                color="error" 
                onClick={() => closeObservation()}>Cancelar</Button>
            </div>
            </div> : 
              <><p className={style.pdf_paragraph}>
                {info && info.observacao_telhado ?
                  info.observacao_telhado : 'Nenhuma'}
              </p>
              {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                <IconButton
                className={style.print}
                aria-label={'Editar Observação'}
                data-cooltipz-dir="top"
                size="small"
                onClick={() => setOpenObservation({ ...openObservation, telhado: true })}
              >
                  <EditIcon />
                </IconButton>
              }
              </>
            }
        </div> */}
          <h2>Projeto</h2>
          <div className={style.photo_content}>
            {photosStep4 ?
              photosStep4.map((photo, index) => (
                <div className={style.img_pdf} key={index}>
                  <File url={photo} index={index} classname={`${style.img_pdf}`} />
                </div>
              ))
              :
              <p className={style.pdf_alert}>Aguardando o Pós-Venda</p>
            }
            {photosStep4 ?
              <div className={style.create_observation}>
                <ThemeProvider theme={theme2}>
                  <h3>Observação sobre o projeto</h3>
                  <TextField
                    sx={{ width: '90%' }}
                    multiline
                    label='Observação'
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    rows={4}
                    value={observations && observations.projeto[0]}
                    onChange={(e) => handleObs(observations.projeto, e.target.value, 0, 'projeto')} />
                </ThemeProvider>
              </div> : null}
          </div>
          <h2>Kit</h2>
          <div className={style.photo_content}>
            {kit ? (
              kit.map((photo, index) => (
                <div className={style.img_pdf} key={index}>
                  <File url={photo} index={index} classname={`${style.img_pdf}`} />
                </div>
              ))
            ) : (
              <p className={style.pdf_alert}>Aguardando o Pós-Venda</p>
            )}
          </div>
          {docs && docs.inversorDataSheet && docs.inversorManual && docs.moduloDataSheet && docs.moduloManual &&
            <><h2>Data Sheets e Manuais</h2>
              <div className={style.photo_content}>
                <div className={style.photo_container}>
                  <div className={style.photo_title}>
                    <h2>Inversor</h2>
                  </div>
                  <div className={style.photos}>
                    <Button variant="contained" href={docs.inversorDataSheet} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar DataSheet</Button>
                    <ThemeProvider theme={theme}>
                      <Button variant="contained" href={docs.inversorManual} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar Manual</Button>
                    </ThemeProvider>
                  </div>
                </div>
                <div className={style.photo_container}>
                  <div className={style.photo_title}>
                    <h2>Módulo</h2>
                  </div>
                  <div className={style.photos}>
                    <Button variant="contained" href={docs.moduloDataSheet} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar DataSheet</Button>
                    <ThemeProvider theme={theme}>
                      <Button variant="contained" href={docs.moduloManual} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar Manual</Button>
                    </ThemeProvider>
                  </div>
                </div>
              </div></>
          }
          {userRef && (userRef.cargo === 'Administrador' || userRef.nome === 'Pós-Venda') && visit && visit.pdf &&
            <div className={relatorio === 'Relatório Contrato' ? `${style.pdf_item_container} ${style.print}` : style.pdf_item_container}>
              <h2>Pedido de Acesso e Homologação Assinada</h2>
              <ul className={style.pdf_text}>
                <li>
                  <b>Data da Vistoria e Troca do Relógio:</b>
                  <p> {visit.analise?.data ?? 'Aguardando o Pós-Venda'} </p>
                </li>
                <li>
                  <b>Documento:</b>
                  {visit && visit.pdf ?
                    <a target="_blank" rel="noreferrer" href={visit.pdf?.file?.pedido}>Clique aqui para Visualizar o PDF</a> :
                    <p>Aguardando o Pós-Venda</p>
                  }
                </li>
              </ul>
            </div>
          }
          {userRef && (userRef.cargo === 'Administrador' || userRef.nome === 'Pós-Venda') &&
            <div className={relatorio === 'Relatório Contrato' ? `${style.pdf_item_container} ${style.print}` : style.pdf_item_container}>
              <h2>Informações</h2>
              <ul className={style.pdf_text}>
                <li>
                  <b>Fornecedor:</b>
                  {openEdit && openEdit.fornecedor ?
                    <>
                      <select
                        className={style.input_address}
                        style={{ width: '13rem' }}
                        value={kitInfo.fornecedor}
                        onChange={(e) => setKitInfo({ ...kitInfo, fornecedor: e.target.value })}>
                        <option value="Nenhum">Nenhum</option>
                        {supplier && supplier.map((data, index) => (
                          <option key={index} value={data.nome}>{data.nome}</option>
                        ))}
                      </select>
                      <div>
                        <IconButton
                          className={style.print}
                          aria-label="Confirmar o Fornecedor"
                          data-cooltipz-dir="top"
                          size="small"
                          color="success"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => confirmEdit('Fornecedor')}
                        >
                          <CheckIcon />
                        </IconButton><IconButton
                          className={style.print}
                          aria-label="Cancelar"
                          data-cooltipz-dir="top"
                          color="error"
                          size="small"
                          onClick={() => closeEdit('Fornecedor')}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </> :
                    <p> {visit.kit_info?.fornecedor ?? 'Aguardando o Pós-Venda'}
                      {checkStep.step1 ?
                        <>
                          {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                            <IconButton
                              className={style.print}
                              aria-label="Alterar o Fornecedor"
                              data-cooltipz-dir="top"
                              size="small"
                              sx={{ marginLeft: '1rem' }}
                              onClick={() => changeOpenEdit('Fornecedor')}
                            >
                              <EditIcon />
                            </IconButton>
                          }
                        </>
                        : 'Carregando...'}
                    </p>}
                </li>
                <li>
                  <div className={style.info}>
                    <b>Valor do Kit: </b>
                    {openEdit && openEdit.valorKit ?
                      <>
                        R$ <input
                          type="number"
                          className={style.input_address}
                          style={{ width: '7rem' }}
                          value={kitInfo.valorKit}
                          onChange={(e) => setKitInfo({ ...kitInfo, valorKit: e.target.value })} />
                        <div>
                          <IconButton
                            className={style.print}
                            aria-label="Confirmar Valor do Kit"
                            data-cooltipz-dir="top"
                            size="small"
                            color="success"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => confirmEdit('Valor_Kit')}
                          >
                            <CheckIcon />
                          </IconButton><IconButton
                            className={style.print}
                            aria-label="Cancelar"
                            data-cooltipz-dir="top"
                            color="error"
                            size="small"
                            onClick={() => closeEdit('Valor_Kit')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </> :
                      <p> {visit.kit_info && visit.kit_info.valor ? Number(visit.kit_info.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Aguardando o Financeiro'}
                        {checkStep.step1 ?
                          <>
                            {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                              <IconButton
                                className={style.print}
                                aria-label="Alterar Valor do Kit"
                                data-cooltipz-dir="top"
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => changeOpenEdit('Valor_Kit')}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          </>
                          : 'Carregando...'}
                      </p>}
                  </div>
                </li>
                <li>
                  <div className={style.info}>
                    <b>Custo de Instalação: </b>
                    {openEdit && openEdit.custoInstalacao ?
                      <>
                        R$ <input
                          type="number"
                          className={style.input_address}
                          style={{ width: '7rem' }}
                          value={kitInfo.custoInstalacao}
                          onChange={(e) => setKitInfo({ ...kitInfo, custoInstalacao: e.target.value })} />
                        <div>
                          <IconButton
                            className={style.print}
                            aria-label="Confirmar Custo de Instalacao"
                            data-cooltipz-dir="top"
                            size="small"
                            color="success"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => confirmEdit('Custo_Instalacao')}
                          >
                            <CheckIcon />
                          </IconButton><IconButton
                            className={style.print}
                            aria-label="Cancelar"
                            data-cooltipz-dir="top"
                            color="error"
                            size="small"
                            onClick={() => closeEdit('Custo_Instalacao')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </> :
                      <p> {visit.kit_info && visit.kit_info.instalacao ? Number(visit.kit_info?.instalacao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Aguardando o Financeiro'}
                        {checkStep.step1 ?
                          <>
                            {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                              <IconButton
                                className={style.print}
                                aria-label="Alterar Custo de Instalação"
                                data-cooltipz-dir="top"
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => changeOpenEdit('Custo_Instalacao')}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          </>
                          : 'Carregando...'}
                      </p>}
                  </div>
                </li>
                <li>
                  <b>Valor de Venda: </b>
                  {openEdit && openEdit.valorVenda ?
                    <>
                      R$ <input
                        type="number"
                        className={style.input_address}
                        style={{ width: '7rem' }}
                        value={kitInfo.valorVenda}
                        onChange={(e) => setKitInfo({ ...kitInfo, valorVenda: e.target.value })} />
                      <div>
                        <IconButton
                          className={style.print}
                          aria-label="Confirmar Valor de Venda"
                          data-cooltipz-dir="top"
                          size="small"
                          color="success"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => confirmEdit('Valor_Venda')}
                        >
                          <CheckIcon />
                        </IconButton><IconButton
                          className={style.print}
                          aria-label="Cancelar"
                          data-cooltipz-dir="top"
                          color="error"
                          size="small"
                          onClick={() => closeEdit('Valor_Venda')}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </> :
                    <p> {visit.kit_info && visit.kit_info.venda ? Number(visit.kit_info?.venda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Aguardando o Financeiro'}
                      {checkStep.step1 ?
                        <>
                          {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                            <IconButton
                              className={style.print}
                              aria-label="Alterar Valor de Venda"
                              data-cooltipz-dir="top"
                              size="small"
                              sx={{ marginLeft: '1rem' }}
                              onClick={() => changeOpenEdit('Valor_Venda')}
                            >
                              <EditIcon />
                            </IconButton>
                          }
                        </>
                        : 'Carregando...'}
                    </p>}
                </li>
                <li>
                  <div className={style.info}>
                    <b>Potência do Inversor: </b>
                    {openEdit && openEdit.potenciaInversor ?
                      <>
                        <input
                          type="number"
                          className={style.input_address}
                          style={{ width: '3rem' }}
                          value={kitInfo.potenciaInversor}
                          onChange={(e) => setKitInfo({ ...kitInfo, potenciaInversor: e.target.value })} />
                        <div>
                          <IconButton
                            className={style.print}
                            aria-label="Confirmar Potência do Inversor"
                            data-cooltipz-dir="top"
                            size="small"
                            color="success"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => confirmEdit('Potencia_Inversor')}
                          >
                            <CheckIcon />
                          </IconButton><IconButton
                            className={style.print}
                            aria-label="Cancelar"
                            data-cooltipz-dir="top"
                            color="error"
                            size="small"
                            onClick={() => closeEdit('Potencia_Inversor')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </> :
                      <p>{visit.kit_info ? visit.kit_info?.inversor + ' kWp' : 'Aguardando o Pós-Venda'}
                        {checkStep.step1 ?
                          <>
                            {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                              <IconButton
                                className={style.print}
                                aria-label="Alterar Potência do Inversor"
                                data-cooltipz-dir="top"
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => changeOpenEdit('Potencia_Inversor')}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          </>
                          : 'Carregando...'}
                      </p>}
                  </div>
                </li>
                <li>
                  <div className={style.info}>
                    <b>Quantidade de Placas: </b>
                    {openEdit && openEdit.quantidadePlacas ?
                      <>
                        <input
                          type="number"
                          className={style.input_address}
                          style={{ width: '3rem' }}
                          value={kitInfo.quantidadePlacas}
                          onChange={(e) => setKitInfo({ ...kitInfo, quantidadePlacas: e.target.value })} />
                        <div>
                          <IconButton
                            className={style.print}
                            aria-label="Confirmar Quantidade de Placas"
                            data-cooltipz-dir="top"
                            size="small"
                            color="success"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => confirmEdit('Quantidade_Placas')}
                          >
                            <CheckIcon />
                          </IconButton><IconButton
                            className={style.print}
                            aria-label="Cancelar"
                            data-cooltipz-dir="top"
                            color="error"
                            size="small"
                            onClick={() => closeEdit('Quantidade_Placas')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </> :
                      <p>{visit.kit_info ? visit.kit_info?.placa_quantidade : 'Aguardando o Pós-Venda'}
                        {checkStep.step1 ?
                          <>
                            {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                              <IconButton
                                className={style.print}
                                aria-label="Alterar Quantidade de Placas"
                                data-cooltipz-dir="top"
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => changeOpenEdit('Quantidade_Placas')}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          </>
                          : 'Carregando...'}
                      </p>}
                  </div>
                </li>
                <li>
                  <div className={style.info}>
                    <b>Potência das Placas: </b>
                    {openEdit && openEdit.potenciaPlacas ?
                      <>
                        <input
                          type="number"
                          className={style.input_address}
                          style={{ width: '3rem' }}
                          value={kitInfo.potenciaPlacas}
                          onChange={(e) => setKitInfo({ ...kitInfo, potenciaPlacas: e.target.value })} />
                        W<div>
                          <IconButton
                            className={style.print}
                            aria-label="Confirmar Potência das Placas"
                            data-cooltipz-dir="top"
                            size="small"
                            color="success"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => confirmEdit('Potencia_Placas')}
                          >
                            <CheckIcon />
                          </IconButton><IconButton
                            className={style.print}
                            aria-label="Cancelar"
                            data-cooltipz-dir="top"
                            color="error"
                            size="small"
                            onClick={() => closeEdit('Potencia_Placas')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </> :
                      <p>{visit.kit_info ? visit.kit_info?.placa_potencia + ' W' : 'Aguardando o Pós-Venda'}
                        {checkStep.step1 ?
                          <>
                            {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                              <IconButton
                                className={style.print}
                                aria-label="Alterar Potência das Placas"
                                data-cooltipz-dir="top"
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => changeOpenEdit('Potencia_Placas')}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          </>
                          : 'Carregando...'}
                      </p>}
                  </div>
                </li>
                <li>
                  <b>Tipo de Estrutura:</b>
                  {openEdit && openEdit.tipoEstrutura ?
                    <>
                      <select
                        className={style.input_address}
                        style={{ width: '13rem' }}
                        value={kitInfo.tipoEstrutura}
                        onChange={(e) => setKitInfo({ ...kitInfo, tipoEstrutura: e.target.value })}>
                        <option value="Fibrocimento">Fibrocimento</option>
                        <option value="Cerâmico">Cerâmico</option>
                        <option value="Metálico">Metálico</option>
                        <option value="Solo">Solo</option>
                        <option value="Laje">Laje</option>
                      </select>
                      <div>
                        <IconButton
                          className={style.print}
                          aria-label="Confirmar o Tipo de Estrutura"
                          data-cooltipz-dir="top"
                          size="small"
                          color="success"
                          sx={{ marginLeft: '1rem' }}
                          onClick={() => confirmEdit('Tipo_Estrutura')}
                        >
                          <CheckIcon />
                        </IconButton><IconButton
                          className={style.print}
                          aria-label="Cancelar"
                          data-cooltipz-dir="top"
                          color="error"
                          size="small"
                          onClick={() => closeEdit('Tipo_Estrutura')}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </> :
                    <p> {visit.kit_info?.estrutura ?? 'Aguardando o Pós-Venda'}
                      {checkStep.step1 ?
                        <>
                          {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                            <IconButton
                              className={style.print}
                              aria-label="Alterar Tipo de Estrutura"
                              data-cooltipz-dir="top"
                              size="small"
                              sx={{ marginLeft: '1rem' }}
                              onClick={() => changeOpenEdit('Tipo_Estrutura')}
                            >
                              <EditIcon />
                            </IconButton>
                          }
                        </>
                        : 'Carregando...'}
                    </p>}
                </li>
              </ul>
            </div>
          }
          <h2>Observação sobre o Kit</h2>
          <div className={style.obs_container}>
            {openObservation && openObservation.kit ?
              <div className={style.create_observation}>
                <ThemeProvider theme={theme2}>
                  <h3>Observação sobre o Kit</h3>
                  <TextField
                    sx={{ width: '90%' }}
                    multiline
                    rows={4}
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    value={observation && observation.kit}
                    onChange={(e) => setObservation({ ...observation, kit: e.target.value })} />
                </ThemeProvider>
                <div className={style.create_observation_buttons}>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    color="success"
                    onClick={() => sendObservation('Kit')}>Confirmar</Button>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    color="error"
                    onClick={() => closeObservation()}>Cancelar</Button>
                </div>
              </div> :
              <><p className={style.pdf_paragraph}>
                {info && info.observacao_kit ?
                  info.observacao_kit : 'Nenhuma'}
              </p>
                {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                  <IconButton
                    className={style.print}
                    aria-label={'Editar Observação'}
                    data-cooltipz-dir="top"
                    size="small"
                    onClick={() => setOpenObservation({ ...openObservation, kit: true })}
                  >
                    <EditIcon />
                  </IconButton>
                }
              </>
            }
          </div>
        </div>
        <div className={style.pdf_rules}>
          <h2>⚠️ Responsabilidade da Contratada ⚠️</h2>
          <div>
            <p style={{ marginBottom: '2rem' }} className={style.pdf_paragraph}>
              A CONTRATADA manifesta sua ciência quanto ao projeto acima apresentado e declara ter plena capacidade técnica para sua execução, manifestando ainda sua ciência e concordância quanto:
            </p>
            <p className={style.pdf_paragraph}>
              <b>1 – Verificação de materiais:</b> a contratada assume a responsabilidade de produzir fotos e vídeos e realizar a verificação dos materiais, especialmente os módulos, inversores, metragem de cabos antes do início da obra. Assume ainda integral responsabilidade pelos custos de substituição, em caso de danos ou falta (perda) durante a instalação.
            </p>
            <p className={style.pdf_paragraph}>
              <b>2 – Laudo:</b> antes de iniciar a instalação, é fundamental e obrigatório que a contratada realize inspeção visual abrangente, registrando fotos e vídeos do interior da residência e do telhado. Durante essa avaliação, a contratada deverá conduzir uma análise detalhada de possíveis pontos de infiltração, verificando lâmpadas, luminárias, pinturas ou gesso que possam ter sido danificados devido a infiltrações no telhado. Além disso, deverá examinar a condição das telhas, identificando trincas, quebras ou desencaixamentos. Essa documentação completa será uma evidência crucial em potenciais alegações relacionadas à instalação, estabelecendo uma base sólida para futuros reparos ou apuração de responsabilidades.
            </p>
            <p className={style.pdf_paragraph}>
              <b>3 – Custo adicional:</b> até 10 metros do ponto de conexão da rede, a instalação do inversor está incluída. Para distâncias superiores a 10 metros, o custo da infraestrutura será cobrado separadamente. Caso a conexão CA ultrapasse 10 metros, a contratada deverá realizar a medição da infraestrutura adicional e informar o setor de obras da empresa. Nesse caso, o cliente incorrerá em custos adicionais de R$55,00 por metro adicional para aterramento (com bitola de cabo até 6mm) e R$110,00 por metro adicional para a infraestrutura CA (com bitola de cabo até 10mm). O cliente está ciente dessa condição conforme estipulado no contrato e receberá um aviso da equipe de pós-vendas no dia agendado para a instalação.
            </p>
            <p className={style.pdf_paragraph}>A contratada deverá comunicar à contratante sobre o custo adicional por metro de infraestrutura CA, sob pena de, não o fazendo, ficar obrigada a reembolsar esse valor à contratante. É importante destacar que o custo adicional de infraestrutura será tratado entre a contratante e o cliente final, sendo expressamente proibido à contratada cobrar diretamente do cliente.</p>
            <p className={style.pdf_paragraph}>
              <b>4 – Gestão de materiais:</b> a contratada deverá prestar contas de todo material usado na execução do serviço e, em caso de sobra de materiais, deverá devolver à contratante.
            </p>
          </div>
        </div>
        <h1>Instalação</h1>
        {openObservation && openObservation.instalacao ?
          <div className={style.create_observation}>
            <ThemeProvider theme={theme2}>
              <h3>Observação</h3>
              <TextField
                fullWidth
                multiline
                rows={4}
                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                value={observation && observation.instalacao}
                onChange={(e) => setObservation({ ...observation, instalacao: e.target.value })} />
            </ThemeProvider>
            <div className={style.create_observation_buttons}>
              <Button
                disableElevation
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                color="success"
                onClick={() => sendObservation()}>Confirmar</Button>
              <Button
                disableElevation
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                color="error"
                onClick={() => closeObservation()}>Cancelar</Button>
            </div>
          </div> :
          <div className={style.observation}>
            <h3>Observação</h3>
            <p>{visit && visit.observacao_instalacao}</p>
            {userRef && (userRef.cargo === 'Instalador' || userRef.cargo === 'Administrador') &&
              <IconButton
                className={style.print}
                aria-label={observation && observation.instalacao ? "Editar Anotação" : "Criar Anotação"}
                data-cooltipz-dir="top"
                size="small"
                color={observation && observation.instalacao ? "" : "success"}
                onClick={() => setOpenObservation({ ...openObservation, instalacao: true })}
              >
                {observation && observation.instalacao ?
                  <EditIcon /> :
                  <AddCircleIcon />
                }
              </IconButton>
            }
          </div>
        }
        <div className={style.pdf_item_container}>
          <h2>Serviço Complementar</h2>
          <div className={style.obs_container}>
            {openObservation && openObservation.servico ?
              <div className={style.create_observation}>
                <ThemeProvider theme={theme2}>
                  <h3>Alterar Serviço Complementar</h3>
                  <TextField
                    sx={{ width: '90%' }}
                    multiline
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    rows={4}
                    value={observation && observation.servico}
                    onChange={(e) => setObservation({ ...observation, servico: e.target.value })} />
                </ThemeProvider>
                <div className={style.create_observation_buttons}>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    color="success"
                    onClick={() => sendObservation('Servico')}>Confirmar</Button>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    color="error"
                    onClick={() => closeObservation()}>Cancelar</Button>
                </div>
              </div> :
              <><p className={style.pdf_paragraph}>
                {info && info.servico_complementar ?
                  info.servico_complementar : 'Nenhuma'}
              </p>
                {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                  <IconButton
                    className={style.print}
                    aria-label={'Editar Serviço Complementar'}
                    data-cooltipz-dir="top"
                    size="small"
                    onClick={() => setOpenObservation({ ...openObservation, servico: true })}
                  >
                    <EditIcon />
                  </IconButton>
                }
              </>
            }
          </div>
          <div className={style.pdf_item_container}>
            <h2>Informações</h2>
            <ul className={style.pdf_text}>
              <li>
                <b>Responsável pela Instalação:</b> {visit && visit.instalador ? visit.instalador.nome : 'Aguardando o Pós-Venda'}
              </li>
              <li>
                <b>Bitola Utilizado no Aterramento:</b> {info && info.bitola_aterramento ? info.bitola_aterramento : 'Aguardando a instalação'}
              </li>
              <li>
                <b>Quantidade de Cabo Utilizado no Aterramento:</b> {info && info.metragem_aterramento ? info.metragem_aterramento + ' Metros' : 'Aguardando a instalação'}
              </li>
              <li>
                <b>Bitola Utilizado no Infra CA:</b> {info && info.bitola_infra_ca ? info.bitola_infra_ca : 'Aguardando a instalação'}
              </li>
              <li>
                <b>Quantidade de Cabo Utilizado no Infra CA:</b> {info && info.metragem_infra_ca ? info.metragem_infra_ca + ' Metros' : 'Aguardando a instalação'}
              </li>
              {userRef && (userRef.cargo === 'Administrador' || userRef.nome === 'Pós-Venda') &&
                <>
                  <li>
                    <b>Custo de Infra CA: </b>
                    {openEdit && openEdit.infraCACusto ?
                      <>
                        R$ <input
                          type="number"
                          className={style.input_address}
                          style={{ width: '7rem' }}
                          value={kitInfo.infraCACusto}
                          onChange={(e) => setKitInfo({ ...kitInfo, infraCACusto: e.target.value })} />
                        <div>
                          <IconButton
                            className={style.print}
                            aria-label="Confirmar Custo de Infra CA"
                            data-cooltipz-dir="top"
                            size="small"
                            color="success"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => confirmEdit('Custo_InfraCA')}
                          >
                            <CheckIcon />
                          </IconButton><IconButton
                            className={style.print}
                            aria-label="Cancelar"
                            data-cooltipz-dir="top"
                            color="error"
                            size="small"
                            onClick={() => closeEdit('Custo_InfraCA')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </> :
                      <p> {visit.infra_ca ? Number(visit.infra_ca?.custo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Aguardando o Financeiro'}
                        {checkStep.step1 ?
                          <>
                            {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                              <IconButton
                                className={style.print}
                                aria-label="Alterar Custo de Infra CA"
                                data-cooltipz-dir="top"
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => changeOpenEdit('Custo_InfraCA')}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          </>
                          : 'Carregando...'}
                      </p>}
                  </li>
                  <li>
                    <b>Fornecedor de Infra CA:</b>
                    {openEdit && openEdit.infraCAFornecedor ?
                      <>
                        <select
                          className={style.input_address}
                          style={{ width: '13rem' }}
                          value={kitInfo.infraCAFornecedor}
                          onChange={(e) => setKitInfo({ ...kitInfo, infraCAFornecedor: e.target.value })}>
                          <option value="Nenhum">Nenhum</option>
                          {supplier && supplier.map((data, index) => (
                            <option key={index} value={data.nome}>{data.nome}</option>
                          ))}
                        </select>
                        <div>
                          <IconButton
                            className={style.print}
                            aria-label="Confirmar o Fornecedor"
                            data-cooltipz-dir="top"
                            size="small"
                            color="success"
                            sx={{ marginLeft: '1rem' }}
                            onClick={() => confirmEdit('Fornecedor_InfraCA')}
                          >
                            <CheckIcon />
                          </IconButton><IconButton
                            className={style.print}
                            aria-label="Cancelar"
                            data-cooltipz-dir="top"
                            color="error"
                            size="small"
                            onClick={() => closeEdit('Fornecedor_InfraCA')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </> :
                      <p> {visit.infra_ca?.fornecedor ?? 'Aguardando o Financeiro'}
                        {checkStep.step1 ?
                          <>
                            {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                              <IconButton
                                className={style.print}
                                aria-label="Alterar o Fornecedor"
                                data-cooltipz-dir="top"
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => changeOpenEdit('Fornecedor_InfraCA')}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          </>
                          : 'Carregando...'}
                      </p>}
                  </li>
                  <li className={relatorio === 'Relatório Contrato' ? style.print : ''}>
                    <b>GPS da Instalação:</b>
                    {info.gps_instalacao ?
                      <a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${info.gps_instalacao && info.gps_instalacao.lat},${info.gps_instalacao && info.gps_instalacao.lng}`}> Clique aqui para visualizar o GPS</a> :
                      <p>Aguardando a instalação</p>
                    }
                  </li>
                </>
              }
            </ul>
          </div>
          <h2>Vistoria</h2>
          <div className={style.photo_content} style={{ padding: '2rem 0 1.5rem 0' }}>
            <div className={style.photo_container}>
              <div className={style.photo_title}>
                <h2>Módulos</h2>
              </div>
              <div className={style.photos}>
                {photosStep6 ? (
                  photosStep6.vistoria.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))
                ) : (
                  <p className={style.pdf_alert}>Aguardando a instalação</p>
                )}
              </div>
            </div>
            {/* {visit.kit_info && visit.kit_info.estrutura === 'Cerâmico' &&
              <div className={style.photo_container}>
                <div className={style.photo_title}>
                  <h2>Telhas</h2>
                </div>
                <div className={style.photos}>
                  {photosStep6 && photosStep6.telhas ? (
                    photosStep6.telhas.map((photo, index) => (
                      <img
                        key={index}
                        className={style.img_pdf}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    ))
                  ) : (
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  )}
                </div>
              </div>
            } */}
          </div>
          <h2>Fotos / Vídeo do Material do Kit Solar</h2>
          <div className={style.photo_content}>
            {photosStep6 ? (
              photosStep6.kitSolar.map((photo, index) => (
                <div className={style.img_pdf}>
                  <File url={photo} index={index} />
                </div>
              ))
            ) : (
              <p className={style.pdf_alert}>Aguardando a instalação</p>
            )}
          </div>
          <h2>Instalação Finalizada</h2>
          <div className={style.photo_content}>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(0)}>
                <h2>Estrutura Solar</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[0] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[0]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep7 ?
                    photosStep7.estrutura.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep7 &&
                  photosStep7.estrutura.map((photo, index) => (
                    <File url={photo} index={index} classname={`${style.img_pdf}`} />
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(1)}>
                <h2>Hooks</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[1] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[1]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep7 ?
                    photosStep7.hooks.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep7 &&
                  photosStep7.hooks.map((photo, index) => (
                    <File url={photo} index={index} classname={`${style.img_pdf}`} />
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(2)}>
                <h2>Aterramento</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[2] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[2]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep7 ?
                    photosStep7.aterramento.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep7 &&
                  photosStep7.aterramento.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(3)}>
                <h2>Módulos</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[3] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[3]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    photosStep8.modulos.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.modulos.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(4)}>
                <h2>Inversor Instalado</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[4] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[4]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    photosStep8.inversorInstalado.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.inversorInstalado.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(5)}>
                <h2>Etiqueta de Informação</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[5] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[5]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    photosStep8.etiqueta.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.etiqueta.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(6)}>
                <h2>Inversor Ligado</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[6] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[6]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    photosStep8.inversorLigado.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.inversorLigado.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(7)}>
                <h2>Inversor e String Box</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[7] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[7]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    <>{photosStep8.inversorString.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    ))}
                      {photosStep8.string.map((photo, index) => (
                        <div className={style.img_pdf}>
                          <File url={photo} index={index} />
                        </div>
                      ))}
                    </> :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.inversorString.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(8)}>
                <h2>Injeção CA</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[8] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[8]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    photosStep8.injecaoCA.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.injecaoCA.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(9)}>
                <h2>Disjuntor Geral</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[9] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[9]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    photosStep8.disjuntorGeral.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.disjuntorGeral.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.photo_container}>
              <div className={style.photo_title} onClick={() => handleToggleInstall(10)}>
                <h2>Placa de Advertência</h2>
                <IconButton
                  aria-label="Expandir"
                  data-cooltipz-dir="left"
                  size="small"
                >
                  {openPhotoInstall[10] ? (
                    <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                  )}
                </IconButton>
              </div>
              <Collapse in={openPhotoInstall[10]} timeout="auto" unmountOnExit>
                <div className={`${style.photos} ${style.print}`}>
                  {photosStep8 ?
                    photosStep8.placa.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                    )) :
                    <p className={style.pdf_alert}>Aguardando a instalação</p>
                  }
                </div>
              </Collapse>
              <div className={`${style.photos} ${style.print_on}`}>
                {photosStep8 &&
                  photosStep8.placa.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <File url={photo} index={index} />
                    </div>
                  ))}
              </div>
            </div>
            {photosStep8 && photosStep8.materiais ?
              <div className={style.photo_container}>
                <div className={style.photo_title} onClick={() => handleToggleInstall(11)}>
                  <h2>Materiais Restantes</h2>
                  <IconButton
                    aria-label="Expandir"
                    data-cooltipz-dir="left"
                    size="small"
                  >
                    {openPhotoInstall[11] ? (
                      <KeyboardArrowUpIcon style={{ fill: "#fff" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fill: "#fff" }} />
                    )}
                  </IconButton>
                </div>
                <Collapse in={openPhotoInstall[11]} timeout="auto" unmountOnExit>
                  <div className={`${style.photos} ${style.print}`}>
                    {photosStep8 ?
                      photosStep8.materiais.map((photo, index) => (
                        <div className={style.img_pdf}>
                          <File url={photo} index={index} />
                        </div>
                      )) :
                      <p className={style.pdf_alert}>Aguardando a instalação</p>
                    }
                  </div>
                </Collapse>
                <div className={`${style.photos} ${style.print_on}`}>
                  {photosStep8 &&
                    photosStep8.materiais.map((photo, index) => (
                      <div className={style.img_pdf}>
                        <File url={photo} index={index} />
                      </div>
                      // <img
                      //   key={index}
                      //   className={style.img_pdf}
                      //   rel="preconnect"
                      //   src={photo}
                      //   alt=""
                      // />
                    ))}
                </div>
              </div> : null
            }
          </div>
        </div>
        {userRef && (userRef.cargo === 'Instalador' || userRef.cargo === 'Administrador' || userRef.nome === 'Pós-Venda') &&
          <><h1 className={relatorio === 'Relatório Contrato' ? style.print : ''}>Área exclusiva do Terceirizado</h1>
            <div className={checkStep.step6 ? `${style.visit_actions_tec} ${style.confirm_tec}` : `${style.visit_actions_tec}`}>
              <h2>Antes da Instalação {checkStep.step6 ? '✅' : ''}</h2>
              <p className={relatorio === 'Relatório Contrato' ? `${style.print} ${style.text_legend}` : ''}>Antes da instalação será preciso realizar uma vistoria no kit do cliente. Envie uma foto e um vídeo do Kit Completo.</p>
              {/* <InstallStep1 visit={idVisit} open={OpenInstallStep === 'step1'} close={() => setOpenInstallStep('step0')} /> */}
              <InstallStep2 visit={idVisit} open={OpenInstallStep === 'step2' ? true : false} close={() => setOpenInstallStep('step0')} />
              <InstallStep3 visit={idVisit} open={OpenInstallStep === 'step3' ? true : false} close={() => setOpenInstallStep('step0')} />
              {visit && <InstallStep4 isLoaded={isLoaded} visit={visit} open={OpenInstallStep === 'step4' ? true : false} close={() => setOpenInstallStep('step0')} />}
              <div className={style.visit_buttons}>
                <ThemeProvider theme={theme}>
                  {checkStep.step6 ? (
                    <Button
                      variant="outlined"
                      color="success"
                      sx={{ textTransform: 'initial' }}
                      endIcon={<CheckCircleIcon />}
                    >
                      Vistoria do Kit
                    </Button>
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ textTransform: 'initial' }}
                      onClick={() => setOpenInstallStep('step2')}
                    >
                      Vistoria do Kit
                    </Button>
                  )}
                </ThemeProvider>
              </div>
            </div>
            <div className={checkStep.step7 ? `${style.visit_actions_tec} ${style.confirm_tec}` : `${style.visit_actions_tec}`}>
              <h2>Durante a Instalação {checkStep.step7 ? '✅' : ''}</h2>
              <p className={relatorio === 'Relatório Contrato' ? `${style.print} ${style.text_legend}` : ''}>Durante da instalação será preciso tirar fotos da estrutura. Envie uma foto da estrutura, dos hooks e do aterramento.</p>
              <div className={style.visit_buttons}>
                <ThemeProvider theme={theme}>
                  {checkStep.step7 ? (
                    <Button
                      variant="outlined"
                      color="success"
                      sx={{ textTransform: 'capitalize' }}
                      endIcon={<CheckCircleIcon />}
                    >
                      Estrutura
                    </Button>
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => setOpenInstallStep('step3')}
                    >
                      Estrutura
                    </Button>
                  )}
                </ThemeProvider>
              </div>
            </div><div className={checkStep.step8 ? `${style.visit_actions_tec} ${style.confirm_tec}` : `${style.visit_actions_tec}`}>
              <h2>Depois da Instalação {checkStep.step8 ? '✅' : ''}</h2>
              <p className={relatorio === 'Relatório Contrato' ? `${style.print} ${style.text_legend}` : ''}>Após a finalização da instalação, siga as etapas através do botão abaixo para enviar as fotos do kit instalado.</p>
              <div className={style.visit_buttons}>
                <ThemeProvider theme={theme}>
                  {checkStep.step8 ? (
                    <Button
                      variant="outlined"
                      color="success"
                      sx={{ textTransform: 'capitalize' }}
                      endIcon={<CheckCircleIcon />}
                    >
                      Kit Instalado
                    </Button>
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => setOpenInstallStep('step4')}
                    >
                      Kit Instalado
                    </Button>
                  )}
                </ThemeProvider>
              </div>
            </div></>
        }
      </div>
    </div>
  );
};

export default Visit;

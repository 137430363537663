import { collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { dataBase } from '../../firebase/database';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { Button, IconButton, ThemeProvider } from '@mui/material';
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { ReactComponent as ScheduleIcon } from "../../images/icons/Schedule1.svg";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PaidIcon from '@mui/icons-material/Paid';

import style from "./style.module.scss";

import Create from './Components/Register/Create';
import Edit from './Components/Register/Edit';
import Swal from 'sweetalert2';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { theme } from '../../data/theme';
import NewTooltip from '../../components/Tooltip';

const Cleaner = () => {
  // eslint-disable-next-line no-unused-vars
  const [works, setWorks] = useState([]);
  const [worksRef, setWorksRef] = useState([]);
  const [open, setOpen] = useState(false);
  const [workRef, setWorkRef] = useState({});
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const cleanerCollectionRef = collection(
    dataBase,
    "Limpezas"
  );
  const membersCollectionRef = collection(
    dataBase,
    "Membros"
  );
  const month = ['Todos','Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
  const [year, setYear] = useState('2024');
  const [openBox, setOpenBox] = useState(null);
  const [workers, setWorkers] = useState([]);
  const [monthSelect, setMonthSelect] = useState(
    '00'
  );
  const journey = ['Orçamento', 'Agendado', 'Concluido', 'Pago'];
  const journeySG = ['O', 'A', 'C', 'P'];
  const journeyColor = ['#dcdbad', '#5facff', '#addcc5', '#6ece99'];
  
  useEffect(() => {
    const getVisit = async () => {
      onSnapshot(query(cleanerCollectionRef, orderBy("createAt", 'asc')), (work) => {
        // Atualiza os dados em tempo real
        setWorksRef(work.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
      onSnapshot(await membersCollectionRef, (member) => {
        // Atualiza os dados em tempo real
        setMembers(member.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
    };
    getVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(members) {
      setWorkers(members.filter(data => data.cargo === 'Terceirizado'))
    }
  },[members])

  useEffect(() => {
    if(worksRef) {
      if(monthSelect === '00') {
        setWorks(worksRef);
      } else {
        setWorks(worksRef.filter(data => data.terceirizado?.data.substring(0,7) === year+'-' + monthSelect));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[worksRef, monthSelect])

  console.log(monthSelect)

  useEffect(() => {
    if(openBox === 'edit') {
      const key = Object.keys(open);
      console.log(key)
      setWorkRef(works.find(data => data.id === key[0]))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openBox])

  const handleToggle = (id) => {
    setOpen((prevState) => ({[id]: !prevState[id] }));
  };

  const handlejourney = async (journey, index, data) => {
    try {
      Swal.fire({
        title: 'Jornada do Cliente',
        html: `Você deseja alterar para <b>${journey}</b>?`,
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if(result.isConfirmed) {
          const docRef = doc(dataBase, 'Limpezas', data.id);
          await updateDoc(docRef, {
            jornada: {
              etapa: journey,
              index: index,
              sigla: journeySG[index],
              cor: journeyColor[index],
              data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
            }
          }).then(() => {
            Swal.fire({
              title: 'Jornada do Cliente',
              html: `A Jornada do Cliente foi alterada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
          });
        }
      })
    } catch (error) {
      console.error('[ERRO]: ', error)
    }
  }

  const deleteCleaner = async (data) => {
    try {
      console.log(data)
      Swal.fire({
        title: 'Limpeza',
        html: `Você deseja excluir a <b>Limpeza</b>?`,
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if(result.isConfirmed) {

          if(data.ref) {
            const storage = getStorage();
            const keys = Object.keys(data.ref); // Pega o nome das propriedades do Objeto dentro de 'ref'
            console.log(keys)
  
            keys.forEach(async key => {
                const value = data.ref[key];
                // console.log(value[0]); // Apenas para depuração, se necessário
  
                for (const data of value) {
                    try {
                        // console.log("Dados: ", data);
                        await deleteObject(ref(storage, data));
                        // console.log('Deletado com sucesso:', data);
                    } catch (error) {
                        // console.log('Erro ao deletar:', data, error);
                    }
                }
            });
          }
          const docRef = doc(dataBase, 'Limpezas', data.id);

          await deleteDoc(docRef).then(() => {
            Swal.fire({
              title: 'Limpeza',
              html: `A Limpeza foi excluida com sucesso!`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
          });
        }
      })
    } catch (error) {
      console.error('[ERRO]: ', error)
    }
  }

  const formatCurrency = (value) => {
    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  const checkService = (step) => {
    return step !== 'Agendado' && step !== 'Orçamento' ? true : false
  }

  return (
    <div className="container-schedule">
      <Create members={members} open={openBox === 'create' ? true : false} close={() => setOpenBox(null)} />
      <Edit members={workers} work={workRef} open={openBox === 'edit' ? true : false} close={() => setOpenBox(null)} />
      <div className="title-schedule">
        <ScheduleIcon />
        <h2>Agenda {year} </h2>
        <h2>Limpezas {monthSelect === '00' ? 'do Ano' : `de ${month[parseFloat(monthSelect)]}`} </h2>
            <div className="schedule-month">
            <select
              value={monthSelect}
              className="schedule-month__select"
              name="month"
              onChange={(e) => setMonthSelect(e.target.value)}
            >
              {month.map((data, index) => {
                const numberFormat = index < 10 ? (index).toString().padStart(2, '0') : (index).toString()
                return (
                  <option key={index} value={numberFormat}>{data}</option>
                )
              })}
            </select>
            <select
                value={year}
                className="schedule-month__select"
                name="year"
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="2024">2024</option>
              </select>
          </div>
      </div>
      <div className={style.content_panel}>
        <div className={style.box_panel}>
            <h1>Serviços</h1>
            <div className={style.box_panel_add}>
              <button onClick={() => setOpenBox('create')}>
                <PersonAddIcon className={style.icon_user} /> 
                <p>Cadastrar Terceirizado</p>
              </button>
              <button onClick={() => navigate(`/limpeza/criar`)}>
                <CleaningServicesIcon className={style.icon_user} /> 
                <p>Cadastrar Limpeza</p>
              </button>
            </div>
          </div>
          <div className={style.box_panel_works}>
            <TableContainer className={style.table_center} component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Etapa</TableCell>
                    <TableCell align="center">Data da Limpeza</TableCell>
                    <TableCell align="center">Cliente</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center">Terceirizado</TableCell>
                    <TableCell align="center">Valor Estimado</TableCell>
                    <TableCell align="center">Valor Fechado</TableCell>
                    <TableCell align="center">Analista</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {works && works.map((work) => (
                      <>
                      <TableRow
                        hover
                        key={work.id}
                        className={`list-visit`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center" sx={{ backgroundColor: `${work.jornada?.cor}`, fontWeight: 'bold', width: '90px' }}>{work.jornada?.etapa ?? 'Ainda não definido'}</TableCell>
                        <TableCell align="center">
                          {work.terceirizado?.data ? 
                          `${moment(work.terceirizado?.data).format("DD/MM/YYYY")} ás ${work.terceirizado?.horario}` :
                           'Ainda não definido'}
                        </TableCell>
                        <TableCell align="center">{work.nome}</TableCell>
                        <TableCell align="center">{work.cidade}</TableCell>
                        <TableCell align="center">{work.terceirizado?.nome ?? 'Ainda não definido'}</TableCell>
                        <TableCell align="center">{formatCurrency(work.valor)}</TableCell>
                        <TableCell align="center">
                          {work.terceirizado?.valor_fechado ? formatCurrency(work.terceirizado?.valor_fechado) : 'Ainda não definido'}
                        </TableCell>
                        <TableCell align="center">{work.analista?.nome}</TableCell>
                        <TableCell align="center">
                            <IconButton
                              aria-label="Expandir"
                              data-cooltipz-dir="left"
                              size="small"
                              onClick={() => handleToggle(work.id)}>
                              {open[work.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: 0, backgroundColor: "#f9f9f9" }}>
                      <TableCell colSpan={9}>
                        <Collapse in={open[work.id]} timeout="auto" unmountOnExit colSpan={7}>
                          <div className={style.cleaner_content}>
                          <h2>Jornada do Cliente</h2>
                          <div className={style.step_box}>
                            <div className={style.step_client}>
                              {journey.map((journey, index) => {
                                const step = work.jornada?.index; 
                                let styleStep = null;
                                if(step === index) {
                                  if(journey === 'Pago') {
                                    styleStep = style.active_item_last;
                                  } else {
                                    styleStep = style.current_active_item;
                                  }
                                } else if (step > index ) {
                                  styleStep = style.active_item
                                }
                                return (
                                  <div className={styleStep}>
                                    <button disabled={journey === work.jornada?.etapa} onClick={() => handlejourney(journey, index, work)}>
                                      {journey}
                                    </button>
                                  </div>
                              )})}
                            </div>
                            {work.jornada ? 
                              <span>Atualização: {work.jornada?.data}</span> : null
                            }
                          </div>
                          <h2>Propostas</h2>
                          <div className={style.step_box}>
                            <h3>Nenhuma proposta enviada</h3>
                          </div>
                          <div className={style.cleaner_buttons}>
                          <NewTooltip title={'Visualizar Limpeza'} placement={'top'}>
                              <Button
                                sx={{ textTransform: 'capitalize' }}
                                disableElevation
                                color='info'
                                variant='contained'
                                onClick={() => navigate(`/limpeza/${work.id}`)}
                                startIcon={<RemoveRedEyeIcon />}
                              >
                                Limpeza
                            </Button>
                          </NewTooltip>
                              <ThemeProvider theme={theme}>
                              <NewTooltip title={'Visualizar Orçamento'} placement={'top'}>
                                <Button
                                  sx={{ textTransform: 'capitalize' }}
                                  disableElevation
                                  variant='contained'
                                  color='success'
                                  onClick={() => navigate(`/limpeza/${work.id}/orcamento`)}
                                  startIcon={<PaidIcon />}
                                >
                                  Orçamento
                                </Button> 
                              </NewTooltip> 
                              <NewTooltip title={checkService(work.jornada?.etapa) ? 'Não é possivel definir um Terceirizado pois a Limpeza já foi concluida' : 'Definir Terceirizado'} placement={'top'}>
                                <span>
                                  <Button
                                    sx={{ textTransform: 'capitalize' }}
                                    disableElevation
                                    variant='contained'
                                    disabled={work.jornada?.etapa !== 'Agendado' && work.jornada?.etapa !== 'Orçamento' ? true : false}
                                    onClick={() => setOpenBox('edit')}
                                    startIcon={<EditCalendarIcon />}
                                  >
                                    Terceirizado
                                  </Button> 
                                </span>
                              </NewTooltip>
                              <NewTooltip title={'Excluir Limpeza'} placement={'top'}>
                                <Button
                                  sx={{ textTransform: 'capitalize' }}
                                  disableElevation
                                  variant='contained'
                                  color='error'
                                  onClick={() => deleteCleaner(work)}
                                  startIcon={<DeleteIcon />}
                                >
                                  Excluir
                                </Button> 
                              </NewTooltip>
                            </ThemeProvider>
                          </div>
                          </div>
                        </Collapse>
                      </TableCell>
                   </TableRow></>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </div>
      </div>
    </div>
  )
}

export default Cleaner
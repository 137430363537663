import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';

function FileViewer({ fileUrl }) {
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    const determineFileType = async () => {
      try {
        // const corsAnywhereUrl = `https://cors-anywhere.herokuapp.com/${fileUrl}`;
        // const response = await fetch(corsAnywhereUrl);
        // const blob = await response.blob();
        // const type = blob.type;
        
        if (fileUrl.includes('.pdf?')) {
            setFileType('pdf');
        } else {
            setFileType('image');
        }
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    determineFileType();
  }, [fileUrl]);

  if (fileType === 'image') {
    return <img src={fileUrl} alt="Imagem" />;
  } else if (fileType === 'pdf') {
    return (
        <><object data={fileUrl} type="application/pdf" width="100%" height="500">
            <p>Seu navegador não suporta a exibição de PDFs. Você pode baixar o PDF clicando no botão abaixo.</p>
        </object>
        <a href={fileUrl} rel='noreferrer'>
        <Button variant='outlined'>Baixar PDF</Button>
        </a></>
    );
  } else {
    return <div>Carregando...</div>;
  }
}

export default FileViewer;

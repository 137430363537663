export const Users = [
    {
     email: "admin@corpbrasil.com"
    },
    {
     email: "financeiro@corpbrasil.com"
    }
]

export const Company = 'CORPBRASIL';

// export const KeyMaps = 'AIzaSyD1WsJJhTpdhTIVLxxXCgdlV8iYfmOeiC4'; // Minha key
export const KeyMaps = 'AIzaSyBbTGwFUfZTO0n2E5JwB8YnbJ6qRmvebgc'; // Key da Corp

export const idBruna = '0yGoA039sLVgB99bKJFAz2fMdHH3'; 
export const idPaulo = 'HtJJPhG3nRUYTVJQIHo8b2AbJgL2'; 
import { useState, useRef } from 'react'
import Swal from "sweetalert2";
import style from "./style.module.scss";
import SlickSlider from "react-slick";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';


import Estrutura from '../../../../images/install/estrutura.jpeg';
import Hooks from '../../../../images/install/hooks.jpeg';
import Aterramento from '../../../../images/install/aterramento.jpeg';

import { addDoc, collection } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';

const InstallStep3 = ({visit,  open, close }) => {
  const theme = useTheme();
  const slider = useRef(null);
  const { uploadImages, changeProgress } = useUpload(visit);
  const { CircularProgressWithLabel  } = useCircular();
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [step, setStep] = useState(0);
  const [estrutura, setEstrutura] = useState([]);
  const [hooks, setHooks] = useState([]);
  const [aterramento, setAterramento] = useState([]);
  // const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false], [false], [false]);

  const checkPhotos = ['Fotos da Estrutura Solar', 'Fotos do Hooks com Perfil', 'Fotos do Aterramento'];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  // const changeStep = (direction) => {
  //   if(direction === 'go') {
  //     if(step === 1 && newPosition !== position) {
  //       return Swal.fire({
  //         title: 'GPS do Padrão',
  //         html: `Confirme o <b>GPS do Padrão</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     } else if (telhadoPerto.length === 0 || telhadoLonge.length === 0 || telhadoVideo.length === 0) {
  //       let validationPhoto;
  //       if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Perto'
  //       } else if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Longe'
  //       } else if(telhadoVideo.length === 0) {
  //         validationPhoto = 'um vídeo do <b>Telhado'
  //       }
  //       return Swal.fire({
  //         title: 'Fotos & Vídeos',
  //         html: `Envie ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     }
  //     setStep(step + 1);
  //   } else {
  //     setStep(step - 1);
  //   }
  // }

  const closeBox = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setCheckData([false], [false], [false]);
      setEstrutura([]);
      setHooks([]);
      setAterramento([]);
    }, 500);
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    console.log(files)
    if(type === 'estrutura') {
      if(estrutura) {
        setEstrutura([...estrutura, ...files])
      } else {
        setEstrutura(files);
      }
    } else if(type === 'hooks') {
      if(hooks) {
        setHooks([...hooks, ...files])
      } else {
        setHooks(files);
      }
    } else if(type === 'aterramento') {
      if(aterramento) {
        setAterramento([...aterramento, ...files])
      } else {
        setAterramento(files);
      }
    }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
    //   if(newPhotos.length === 0) {
    //     setOpenPhoto(false);
    //   }
    // setPhotos({data: newPhotos, type: photos.type});
     switch (type) {
      case 'estrutura':
        setEstrutura(newPhotos);
        break;
      case 'hooks':
        setHooks(newPhotos);
        break;
      case 'aterramento':
        setAterramento(newPhotos);
        break;
        default:
     }
    }
  }

  const sendVisit = () => {
    let validationPhoto;
    if(estrutura.length === 0 || hooks.length === 0 || aterramento.length === 0) {
      if (estrutura.length === 0) {
        validationPhoto = 'a foto da <b>Estrutura Solar'
      } else if (hooks.length === 0) {
        validationPhoto = 'a foto dos <b>Hooks Instalados'
      } else if (aterramento.length === 0) {
        validationPhoto = 'a foto da <b>Estrutura com Aterramento'
      }
        return Swal.fire({
          title: 'Fotos & Vídeos',
          html: `Envie ${validationPhoto}</b> para finalizar a etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#F39200",
          confirmButtonText: "Ok",
        })
    } else {
      Swal.fire({
        title: 'Estrutura',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#F39200",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let visita = {};
          let ref = {};
          setStep(1);

          try {
            const resultEstrutura = await uploadImages(estrutura, 'estrutura', 0)
              visita.estrutura = resultEstrutura.map(object => object.downloadURL);
              ref.estrutura = resultEstrutura.map(object => object.fullPath);
              console.log(visita)
              handleChangeCheck(0); 

            const resultHooks = await uploadImages(hooks, 'hooks', 1)
                visita.hooks = resultHooks.map(object => object.downloadURL);
                ref.hooks = resultHooks.map(object => object.fullPath);
                console.log(visita)
                handleChangeCheck(1);
            
            const resultAterramento = await uploadImages(aterramento, 'aterramento', 2)
              visita.aterramento = resultAterramento.map(object => object.downloadURL);
              ref.aterramento = resultAterramento.map(object => object.fullPath);
              console.log(visita)
              handleChangeCheck(2);

              await addDoc(collection(dataBase, 'Visitas', visit, 'Tecnica'), {
                ...visita,
                ref: ref,
                etapa: 7
               }).then(() => {
                  Swal.fire({
                  title: 'Estrutura',
                  html: `A <b>Estrutura</b> foi concluida com sucesso.`,
                  icon: "success",
                  showCloseButton: true,
                  confirmButtonColor: "#F39200",
                  confirmButtonText: "Ok",
                  })
                  closeBox();
              })  
          } catch (error) {
            console.error('Ocorreu um erro: ', error);
          }                             
          }
        })
    }
  }

  return (
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={close}
    >
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Estrutura</DialogTitle>
      <DialogContent className={style.dialog_content}>
      {step === 0 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
            {estrutura && estrutura.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 1:</b> Envie uma foto da Estrutura Solar instalada. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Estrutura} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Envie uma foto do Kit Solar Completo <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {estrutura && estrutura.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, estrutura, 'estrutura')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'estrutura')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {hooks && hooks.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 2:</b> Envie uma foto dos hooks instalados com perfil. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Hooks} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Envie uma foto dos hooks instalados com perfil
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {hooks && hooks.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, hooks, 'hooks')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained"
                onChange={(e) => sendPhoto(e, 'hooks')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {aterramento && aterramento.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 3:</b> Envie uma foto da estrutura com aterramento. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Aterramento} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Envie uma foto da estrutura com aterramento.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {aterramento && aterramento.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, aterramento, 'aterramento')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'aterramento')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
        </div>
      }
        {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} />
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            disabled={step > 0 ? true: false} 
            onClick={() => closeBox()}>Fechar</Button>
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            disabled={step > 0 ? true: false} 
            color='success' 
            onClick={() => sendVisit()}>Enviar</Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    /* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog></> */
  )
}

export default InstallStep3
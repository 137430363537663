import { useCallback } from "react";
import { IconButton } from "@mui/material";
import NewTooltip from "../components/Tooltip";
import Swal from "sweetalert2";

import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import RemoveDoneRoundedIcon from '@mui/icons-material/RemoveDoneRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';

const viewVisitConfirm = (data) => {
  return Swal.fire({
    title: 'Confirmação de Visita',
    html: `O horário combinado foi <b>${data.status}</b> pelo Cliente. <br /> <br /> ${data.resposta ? data.resposta : ''}`,
    icon: "info",
    showConfirmButton: true,
    showCloseButton: true,
    confirmButtonColor: "#0eb05f",
  });
};

const useVisitConfirm = () => {
  const handleVisitConfirm = useCallback((visitConfirm) => {
    if (visitConfirm) {
      return (
        <NewTooltip title='Clique aqui para ver mais detalhes' placement='top'>
          <IconButton size='small' onClick={() => viewVisitConfirm(visitConfirm)}>
            {visitConfirm.status === '✅ Aceito' ? <DoneAllRoundedIcon sx={{ fill: "#72cd4e" }} /> : <RemoveDoneRoundedIcon sx={{ fill: "red" }} />}
          </IconButton>
        </NewTooltip>
      );
    } else {
      return (
        <NewTooltip title='O Cliente ainda não confirmou o horário' placement='top'>
          <IconButton size='small'>
            <HourglassEmptyRoundedIcon />
          </IconButton>
        </NewTooltip>
      );
    }
  }, []);

  return { handleVisitConfirm };
};

export default useVisitConfirm;

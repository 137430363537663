import { useState, useEffect, useRef } from "react";
import { onSnapshot, collection, updateDoc, doc, deleteDoc, orderBy, query } from "firebase/firestore";
import Select from 'react-select';
import Swal from "sweetalert2";
import moment from "moment";
import { dataBase } from "../../firebase/database";

// import Header from "../../components/Header/Index";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Collapse from "@mui/material/Collapse";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';

// Css
import "cooltipz-css";
import style from "./style.module.scss";
import "../../styles/_buttons.scss";
import AddLead from "./Components/Leads/Add";
import { theme } from "../../data/theme";
import { ThemeProvider } from "@emotion/react";
import EditLead from "./Components/Leads/Edit";

const Financing = ({ user, userRef }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [option, setOption] = useState([]);
  const [banco, setBanco] = useState([]);
  const [status, setStatus] = useState([]);
  const [notaFiscal, setNotaFiscal] = useState([]);
  const [leadsRef, setLeadsRef] = useState([]);
  const [leads, setLeads] = useState([]);
  const [view, setView] = useState({ create: false, add: false, edit: false });
  const [checkClose, setCheckClose] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [year, setYear] = useState('2024');
  const [monthSelect, setMonthSelect] = useState('');
  const leadRef = useRef();

  const bancos = [
    { value: 'Nenhum', label: 'Nenhum',},
    { value: 'Banco do Brasil', label: 'Banco do Brasil', color: '#FFE5A0', font: "#5c5236"},
    { value: 'Bradesco', label: 'Bradesco', color: '#ff2222', font: "#fff"},
    { value: 'BV', label: 'BV', color: '#E6CFF2', font: '#4a0246'},
    { value: 'Caixa Economica Federal', label: 'Caixa Economica Federal', color: '#0A53A8', font: "#BFDFF4"},
    { value: 'Cartão de Crédito', label: 'Cartão de Crédito', color: '#6e6e6e', font: "#ffffff"},
    { value: 'CredSolaris', label: 'CredSolaris', color: '#D4EDBC', font: "#475c34"},
    { value: 'Losango', label: 'Losango', color: '#0c0071', font: "#fff"},
    { value: 'Recursos Próprios', label: 'Recursos Próprios', color: '#E6E5E4', font: "#51504f"},
    { value: 'Santander', label: 'Santander', color: '#B10202', font: "#FFF"},
    { value: 'Sol Agora', label: 'Sol Agora', color: '#BFE1F6', font: "#263a47"},
  ];

  const statusAll = [
    { value: 'Nenhum', label: 'Nenhum',},
    { value: 'Aprovado', label: 'Aprovado', color: '#11734B', font: '#fff'},
    { value: 'Negado', label: 'Negado', color: '#BE1C21', font: "#FFF"},
    { value: 'Falta Documentos', label: 'Falta Documentos', color: '#FFB900', font: "#fff"},
    { value: 'Aguardando Visita Técnica', label: 'Aguardando Visita Técnica', color: '#00FFE6', font: "#5c5236"},
    { value: 'Aguardando Banco', label: 'Aguardando Banco', color: '#BFDFF4', font: '#2b3c47'},
    { value: 'Aguardando Cliente', label: 'Aguardando Cliente', color: '#5A3286', font: "#fff"},
    { value: 'Cliente Recusou', label: 'Cliente Recusou', color: '#3D3D3D', font: "#fff"},
    { value: 'Cliente Não Responde', label: 'Cliente Não Responde', color: '#FFCFC9', font: "#111"},
  ];

  const nota = [
    { value: 'Nenhum', label: 'Nenhum',},
    { value: 'Aguardando', label: 'Aguardando', color: '#BFDFF4', font: '#2b3c47'},
    { value: 'Emitida', label: 'Emitida', color: '#d4edbc', font: "#3c4c2d"},
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? state.data.color : 'transparent',
      color: state.isSelected ? 'white' : 'black',
      zIndex: '1111'
    }),
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      boxShadow: "none",
      "&:focus-within": {
      borderColor: '#111',
      boxShadow: "0 0 0.2rem rgba(175, 175, 175, 0.7)",
    }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.font,
      backgroundColor: state.data.color,
      borderRadius: '10px',
      padding: '0.3rem 0'
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }) // Deixa as Options por cima de outros elementos
  };;

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(query(collection(dataBase, "Financiamento"), orderBy("data", "desc")), (data) => {
          // Atualiza os dados em tempo real
          setLeadsRef(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
        // onSnapshot(collection(dataBase, "Membros"), (data) => {
        //   // Atualiza os dados em tempo real
        //   setMembers(
        //     data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //   );
        // });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
      if(monthSelect) {
        setLeads(leadsRef && leadsRef.filter(data => data.data.substring(0,7) === year+'-'+monthSelect))
      } else {
        setLeads(leadsRef);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[monthSelect])

    useEffect(() => {
      if(leads) {
          setLeads(leadsRef);
          setBanco(leadsRef.map(data => data.banco));
          setStatus(leadsRef.map(data => data.status));
          setNotaFiscal(leadsRef.map(data => data.nota_fiscal));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[leadsRef])

    console.log(banco)

    useEffect(() => {
      if(leadsRef) {
        const uniqueOptions = leadsRef.reduce((uniqueList, currentData) => {
          const isExisting = uniqueList.some(item => item.nome === currentData.nome);
    
          if (!isExisting && currentData.nome.trim() !== '') {
            uniqueList.push({
              id: uniqueList.length,
              nome: currentData.nome
            });
          }
    
          return uniqueList;
        }, []);
        setOption(uniqueOptions.sort((a, b) => a.nome.localeCompare(b.nome)))
      }
    },[leadsRef])


  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };

  const openBox = (type, data) => {
    if(type === 'create') {
      setView({ ...view, create: true});
    } else if(type === 'add') {
      setView({ ...view, add: true});
    } else {
      leadRef.current = data;
      setView({ ...view, edit: true});
    }
  }

  const closeBox = (type) => {
    if(type === 'all') {
      setView({ create: false, add: false, edit: false});
      setCheckClose(true);
    } else if(type === 'add') {
      setView({ ...view, add: false});
    } else {
      setView({ ...view, edit: false});
    }
  } 

  console.log(view)

  const handleToggle = (id) => {
    setOpen((prevState) => ({[id]: !prevState[id] }));
  };

  const handleChange = (event, newValue) => {
    if (newValue === "") {
      setSearch(newValue); // Se o novo valor for vazio, mantenha como vazio
    } else {
      // Verifica se o novo valor existe nas opções
      const optionExists = option.some(option => option.nome === newValue);
  
      if (optionExists) {
        setSearch(newValue);
        setLeads(leadsRef.filter(data => data.nome === newValue));
      } else {
        // Trate o caso em que o valor não corresponde a nenhuma opção existente, se necessário
      }
    }
  };

  const deleteLead = async (data) => {
    try {
          Swal.fire({
            title: 'Financiamento',
            html: `Você deseja excluir o Cliente <b>${data.nome}</b>?`,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then(async (result) => {
            if (result.isConfirmed) {
              await deleteDoc(doc(dataBase, "Financiamento", data.id));
              Swal.fire({
                title: 'Financiamento',
                html: `O Cliente <b>${data.nome}</b> foi excluido com sucesso.`,
                icon: "success",
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonColor: "#F39200",
              });
            }
          });
        }
     catch {}
  };

  const editObservation = (data, type) => {
    let obsRef = {};
    if(type === 'valor') {
      obsRef = {
       msgFull: `${data.observacao_valor.data} <br /><br /> ${data.observacao_valor.mensagem}`,
       msg: data.observacao_valor.mensagem
      }
    } else {
      obsRef = data.observacao
    }
    Swal.fire({
      title: 'Observação',
      html: obsRef.msgFull,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      confirmButtonText: "Editar",
      cancelButtonText: "Fechar",
    }).then(async (result) => {
      if(result.isConfirmed) {
        const { value: obs } = await Swal.fire({
          title: 'Editar Observação',
          html: `Digite abaixo uma observação.`,
          showCancelButton: true,
          showCloseButton: true,
          inputValue: obsRef.msg,
          confirmButtonColor: "#27d327",
          confirmButtonText: "Enviar",
          cancelButtonText: "Fechar",
          input: 'textarea',
          inputValidator: (value) => {
            if (!value) {
              return 'Não é possível enviar um campo em branco.'
            }
          },
        })

        if(obs) {
          let props;
          if(type === 'valor'){
            props = {
              observacao_valor: {
                data: moment(new Date()).format('DD MMM YYYY - HH:mm'),
                mensagem: obs
              }
            }
          } else {
            props = {
              observacao: {
                data: moment(new Date()).format('DD MMM YYYY - HH:mm'),
                mensagem: obs
              }
            }
          }
          await updateDoc(doc(dataBase, "Financiamento", data.id), props).then(() => {
            Swal.fire({
              title: 'Observação',
              html: `A <b>Observação</b> foi alterada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#F39200",
            });
          })
        }
      }
    })
  }

  const addObservation = (data, type) => {
    Swal.fire({
      title: 'Observação',
      html: `Nenhuma Observação criada para esse campo. Deseja criar uma?`,
      icon: "info",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if(result.isConfirmed) {
        const { value: obs } = await Swal.fire({
          title: 'Observação',
          html: `Digite abaixo uma observação.`,
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#27d327",
          confirmButtonText: "Enviar",
          cancelButtonText: "Fechar",
          input: 'textarea',
          inputValidator: (value) => {
            if (!value) {
              return 'Não é possível enviar um campo em branco.'
            }
          },
        })

        if(obs) {
          let props;
          if(type === 'valor'){
            props = {
              observacao_valor: {
                  data: moment(new Date()).format('DD MMM YYYY - HH:mm'),
                  mensagem: obs
              }
            }
          } else {
            props = {
              observacao: {
                data: moment(new Date()).format('DD MMM YYYY - HH:mm'),
                mensagem: obs
              }
            }
          }
          await updateDoc(doc(dataBase, "Financiamento", data.id), props).then(() => {
            Swal.fire({
              title: 'Observação',
              html: `A <b>Observação</b> foi criada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#F39200",
            });
          })
        }
      }
    })
  }

  const handleChangeSelect = async (value, type, index, id) => {
    try {
      let props;
      switch(type) {
        case 'banco':
          let bancoArray = [...banco];
          bancoArray[index] = value;
          setBanco(bancoArray);
          props = {
            banco: bancoArray[index]
          }
          break;
        case 'status':
          let statusArray = [...status];
          statusArray[index] = value;
          setStatus(statusArray);
          props = {
            status: statusArray[index]
          }
          break;
        case 'nota_fiscal':
          let notaArray = [...notaFiscal];
          notaArray[index] = value;
          setNotaFiscal(notaArray);
          props = {
            nota_fiscal: notaArray[index]
          }
          break;
          default:
      }

      await updateDoc(doc(dataBase, "Financiamento", id),props)
    } catch (error) {
      console.log("ERRO: ", error)
    }
  } 

  return (
    <div className={style.container_panel}>
      {/* <Header user={user} userRef={userRef}></Header> */}
      <div className='title-panel'>
        <AccountBalanceIcon sx={{ width: '62px', height: '62px' }} />
        <h2>Financiamento</h2>
      </div>
      <div className="schedule-month">
        <select
          value={monthSelect}
          className="schedule-month__select"
          name="month"
          onChange={(e) => setMonthSelect(e.target.value)}>
            <option value="">Todos os Meses</option>
            <option value="01">Janeiro</option>
            <option value="02">Fevereiro</option>
            <option value="03">Março</option>
            <option value="04">Abril</option>
            <option value="05">Maio</option>
            <option value="06">Junho</option>
            <option value="07">Julho</option>
            <option value="08">Agosto</option>
            <option value="09">Setembro</option>
            <option value="10">Outubro</option>
            <option value="11">Novembro</option>
            <option value="12">Dezembro</option>
          </select>
        <select
          value={year}
          className="schedule-month__select"
          name="year"
          onChange={(e) => setYear(e.target.value)}
        >
          <option value="2024">2024</option>
        </select>
        </div>  
      <div className={style.content_panel}>
        <div className={style.header_sheet}>
          <div className="btn_add">
            <button
             onClick={() => openBox("add")}>
              <span className="visit-icon comercial-fill"><PersonAddAlt1Icon /></span>
              <div className="visit-text"><p>Novo Cliente</p></div>
             </button>
           </div>
        </div>
        <h2 className={style.title_sheet}>Lista de Clientes</h2>
        <div className={style.search}>
          <ThemeProvider theme={theme}>
             <Autocomplete
               value={search}
               disableClearable
               sx={{ width: '15rem' }}
               isOptionEqualToValue={(option, value) => option.value === value.value}
               options={option && option.map((option) => option.nome)}
               onInputChange={(event, newInputValue) => {
                    if (newInputValue === '') {
                      setLeads(leadsRef);
                      setSearch('');
                    }
                }}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key={params.id}
                  label="Pesquisar Cliente"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}/> 
          </ThemeProvider>
        </div>
        <AddLead leads={leads} view={view.add} close={closeBox} userRef={userRef} openSheet={() => openBox('create')} checkClose={checkClose} />
        <EditLead leads={leads} view={view.edit} close={closeBox} userRef={userRef} checkClose={checkClose} lead={leadRef.current}/>
        <TableContainer className={style.table_sheets} component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="center">Consultora</TableCell>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Valor</TableCell>
                    <TableCell align="center">Kit</TableCell>
                    <TableCell align="center">Banco</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Observação</TableCell>
                    <TableCell align="center">Nota Fiscal</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                {leads && leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                <TableBody>
                  <TableRow
                    className={style.row_sheet}
                    key={index}
                    hover
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell 
                      className={data.situacao === 'Pago' ? style.check : style.await} 
                      aria-label={data.situacao}
                      data-cooltipz-dir="right"
                      align="center">
                      {data.situacao && data.situacao === 'Pago' ? <CheckIcon /> : <HourglassEmptyIcon />}
                    </TableCell>
                    <TableCell align="center">{moment(data.data).format('DD/MM/YYYY')}</TableCell>
                    <TableCell className={style.consultora} align="center"
                      sx={(data && data.consultora === 'Ana' && { backgroundColor: "#FF5B00"}) || 
                      (data && data.consultora === 'Bruna' && { backgroundColor: "#02b902"}) || 
                      (data && data.consultora === 'Lia' && { backgroundColor: "#FB83F6"}) || 
                      (data && data.consultora === 'Mayara' && { backgroundColor: "#FFB900"})}>
                        {data.consultora}
                    </TableCell>
                    <TableCell align="center">{data.nome}</TableCell>
                    <TableCell className={style.value} align="center">
                      {parseFloat(data.valor).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})} 
                      {data.observacao_valor ? 
                        <IconButton aria-label="Visualizar Observação" data-cooltipz-dir="left" onClick={() => editObservation(data,'valor')}><InfoIcon /></IconButton> : 
                        <IconButton aria-label="Adicionar Observação" data-cooltipz-dir="left" onClick={() => addObservation(data,'valor')}><AddCircleIcon color="success" /></IconButton>
                      }
                    </TableCell>
                    <TableCell align="center">{data.kit} kWp</TableCell>
                    <TableCell sx={{ width: '13rem' }} align="center">
                      <Select 
                      menuPosition={'fixed'}
                      className={style.input_select}
                      styles={customStyles}
                      value={banco[index]}
                      onChange={(value) => handleChangeSelect(value, 'banco', index, data.id)}
                      options={bancos} />
                    </TableCell>
                    <TableCell sx={{ width: '15rem' }} align="center">
                    <Select 
                      menuPosition={'fixed'}
                      className={style.input_select}
                      styles={customStyles}
                      value={status[index]}
                      onChange={(value) => handleChangeSelect(value, 'status', index, data.id)}
                      options={statusAll} />
                    </TableCell>
                    {data.observacao && data.observacao.mensagem ? 
                      <TableCell align="center">{data.observacao && data.observacao.mensagem.length > 10 ? data.observacao.mensagem.substring(0,10) + '...' : data.observacao.mensagem}</TableCell> :
                      <TableCell sx={{ margin: 'auto' }} align="center">
                        <iconButton aria-label="Adicionar Observação" data-cooltipz-dir="left" onClick={() => addObservation(data)}><AddCircleIcon color="success" /></iconButton>
                      </TableCell>
                    }
                    <TableCell sx={{ width: '12rem' }} align="center">
                    <Select 
                      menuPosition={'fixed'}
                      className={style.input_select}
                      styles={customStyles}
                      value={notaFiscal[index]}
                      onChange={(value) => handleChangeSelect(value, 'nota_fiscal', index, data.id)}
                      options={nota} />
                    </TableCell>
                    <TableCell align="center" sx={{ width: '50px' }}>
                      <IconButton
                        aria-label="Expandir"
                        data-cooltipz-dir="left"
                        size="small"
                        onClick={() => handleToggle(data.id)}>
                        {open[data.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ padding: 0 }}>
                      <TableCell colSpan={11}>
                        <Collapse in={open[data.id]} timeout="auto" unmountOnExit colSpan={11}>
                          <div className={style.info_sheets}>
                            {userRef && userRef.cargo === 'Administrador' && 
                              <span>Ultima atualização do Cliente - {data.dataRef && data.dataRef.replace('-', 'às')}</span>
                            }
                            <Alert sx={{ width: '100%', maxWidth: '500px', margin: '0.3rem' }} severity="info" color="warning">
                              <AlertTitle>Observação {data.observacao && "- " + data.observacao.data.replace('-', 'às')}</AlertTitle>
                              {data.observacao ? data.observacao.mensagem : 'Nenhuma'}
                            </Alert>
                            <Alert sx={{ width: '100%', maxWidth: '500px', margin: '0.3rem' }} severity="info">
                              <AlertTitle>Documentos</AlertTitle>
                              CPF: <b>{data.cpf ? data.cpf : 'Não informado'}</b> <br />
                              Telefone: <b>{data.telefone ? data.telefone : 'Não informado'}</b><br />
                              Data de Nascimento: <b>{data.data_nascimento ? moment(data.data_nascimento).format("DD/MM/YYYY") : 'Não informado'}</b>
                            </Alert>
                            <div>
                            <IconButton
                            id="basic-button"
                            aria-label="Editar Cliente"
                            data-cooltipz-dir="left"
                            onClick={() => openBox("edit", data)}
                          >
                            <EditIcon />
                          </IconButton>
                            <IconButton
                            id="basic-button"
                            aria-label="Excluir Cliente"
                            data-cooltipz-dir="left"
                            onClick={() => deleteLead(data)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                            </div>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                </TableBody>
                ))}
                {leads && leads.length === 0 &&
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <p className={style.value}>Nenhum Cliente Encontrado</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }
              </Table>
              <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              labelRowsPerPage="Lista por página"
              component="div"
              count={leads ? leads.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              />
        </TableContainer>
      </div>
    </div>
  );
};

export default Financing;

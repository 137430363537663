import { useState, useEffect, memo } from "react";
// import CurrencyInput from "react-currency-input-field";
import Dialog from "@mui/material/Dialog";
import { PatternFormat } from "react-number-format";
import { theme } from "../../../data/theme";
import Autocomplete from '@mui/material/Autocomplete';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Grid, ListItem, ThemeProvider } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogContentText from "@mui/material/DialogContentText";
import FormLabel from "@mui/material/FormLabel";
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import Checkbox from '@mui/material/Checkbox';
import styles from "./styles.module.scss";
import { dataBase } from "../../../firebase/database";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import removeNullProperties from "../../../functions/removeNullProperties";
import { useCadastro } from "../../../contexts/CadastroContext";

const Requirement = ({ typeVisit, userRef, type, view, openBox, closeBox }) => {
  const { atualizarCadastro } = useCadastro();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  // const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [data, setData] = useState("");
  const [simulacao, setSimulacao] = useState("");
  const [confirm, setConfirm] = useState("");
  const [confirm2, setConfirm2] = useState("");
  const [confirm3, setConfirm3] = useState("");
  const [confirm4, setConfirm4] = useState("");
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [cliente_id, setCliente_id] = useState('');
  // const [consultora, setConsultora] = useState('');
  const [leadsSheets, setLeadsSheets] = useState([]);
  const loadingCustomers = open && leadsSheets.length === 0;
  const [isValid, setIsValid] = useState({
    text: '',
    valid: true
  });

  console.log(typeVisit);

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(query(collection(dataBase, "Clientes"), orderBy("createAt", 'desc')), (data) => {
          // Atualiza os dados em tempo real
          const leadsRef = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          console.log(leadsRef)
          const uniqueOptions = leadsRef.reduce((uniqueList, currentData) => {
            const isExisting = uniqueList.some(item => item.nome === currentData.nome);

            if (!isExisting && currentData.nome.trim() !== '') {
              uniqueList.push({
                id: currentData.id,
                nome: currentData.nome,
                data_nascimento: currentData.data_nascimento || '',
                telefone: currentData.telefone,
                cpfOuCnpj: currentData.doc,
                consultora: currentData.consultora,
                consultora_uid: currentData.consultora_uid,
                endereco: currentData.endereco
              });
            }

            return uniqueList;
          }, []);
          console.log(uniqueOptions)
          const leadsSort = uniqueOptions.sort((a, b) => a.nome.localeCompare(b.nome));
          if (userRef?.cargo === 'Vendedor(a)' && !userRef.nome_ref) {
            setLeadsSheets(
              leadsSort.filter((item) => item.consultora_uid === userRef.uid)
            );
          } else {
            setLeadsSheets(
              uniqueOptions.sort((a, b) => a.nome.localeCompare(b.nome))
            );
          }
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(cliente_id);
    if (confirm === "Não" || confirm2 === 'Não') {
      return Swal.fire({
        title: 'Visita Não Qualificada',
        html: `A visita não pode ser criada pois a visita não é <b>Qualificada</b>`,
        icon: "warning",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      }).then(() => {
        openBox(type);
      })
    } else {
      Swal.fire({
        title: 'CORPBRASIL',
        html: `Todos os dados preenchidos estão corretos?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.isConfirmed) {
          // let telefoneFormatado = typeof telefone === 'number' ? telefone : telefone.replace(/\D/g, '');
          // collectData({
          //   nome: nome,
          //   telefone: telefoneFormatado,
          //   dataNouF: data,
          //   simulacao: simulacao,
          //   cpfOuCnpj: cpfCnpj,
          //   pagamento: confirm,
          //   proposta: confirm2,
          //   inversor10kW: confirm3,
          //   link: confirm4,
          //   cliente_id: cliente_id,
          //   consultora: type === 'pos_venda' || type === 'tecnica' ? userRef?.nome : consultora
          // })

          const cadastroRef = {
            preData: {
              nome: customer.nome,
              telefone: customer.telefone ?? '00000000000',
              cpfOuCnpj: customer.doc ?? '00000000000',
            },
            cliente_id: customer.id,
            consultora: customer.consultora,
            indicador: {
              id: customer.representante?.id ?? '0',
              nome: customer.representante?.nome ?? 'Nenhum',
              ref_indicador: customer.representante?.codigo ?? 'Nenhum'
            },
            endereco: customer.endereco ? {
              ...customer.endereco,
              cidade: customer.cidade
            } : null
          };

          console.log(customer);
          console.log(cadastroRef)
          atualizarCadastro(removeNullProperties(cadastroRef));
          // setNome('');
          setTelefone('');
          setCpfCnpj('');
          setData('');
          setSimulacao('');
          setConfirm('');
          setConfirm2('');
          setConfirm3('');
          setConfirm4('');
          setChecked(false);

          // changeBox(type)
          // closeBox()
        } else {
          openBox(type);
        }
      })
    }
  }

  const close = () => {
    closeBox();
    // setNome('');
    setTelefone('');
    setCpfCnpj('');
    setData('');
    setSimulacao('');
    setConfirm('');
    setConfirm2('');
    setConfirm3('');
    setConfirm4('');
  }

  const mask = (v) => {
    v = v.replace(/\D/g, "")

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      v = v.substring(0, 14); // limita em 14 números
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
  }

  // const removePrefix = (number) => {
  //   const numberString = number.toString(); // Converte o número para string para facilitar a manipulação
  //   if (numberString.startsWith('55')) { // Verifica se a string começa com '55'
  //     return numberString.slice(2); // Remove os dois primeiros caracteres
  //   }
  //   return numberString; // Retorna o número original caso não comece com '55'
  // }

  const handlePhone = (event) => {
    let input = event;
    const formatter = new AsYouType('BR');

    if (event.target) {
      input = formatter.input(event.target.value);
    }
    const phoneNumber = parsePhoneNumberFromString(input, 'BR'); // Substitua 'US' pelo código do país desejado

    if (phoneNumber && phoneNumber.isValid() && !telefone.includes("_")) {
      setIsValid({
        text: '',
        valid: true
      });
    } else {
      setIsValid({
        text: 'Número de Telefone inválido',
        valid: false
      });
    }
  };


  const filterOptions = (options, { inputValue }) => {
    const filtered = options.filter((option) => 
      option.nome.toLowerCase().includes(inputValue.toLowerCase())
    );

    // Add a special item to create a new option
    if (inputValue !== '' && !options.some((option) => option.nome === inputValue)) {
      filtered.push({
        nome: `Adicionar "${inputValue}"`,
        isNew: true,
      });
    }

    return filtered;
  };

  return (
    <Dialog
      open={view}
      fullWidth={true}
      maxWidth="sm"
    >
      <IconButton
        aria-label="close"
        onClick={() => close()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Requisitos de Visita</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          Preencha os campos abaixo para agendar a <b>Visita</b>.
        </DialogContentText>
        <form onSubmit={onSubmit}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={1.5}>
              <Grid item xs={type === 'pos_venda' || type === 'tecnica' ? 6 : 12}>
                <Autocomplete
                  value={customer?.nome}
                  fullWidth
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loadingCustomers}
                  loadingText="Carregando..."
                  sx={{ alignItems: 'center' }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      // setNome(newValue);
                      setTelefone('');
                      setCpfCnpj('');
                      setCliente_id('');
                      // setConsultora('');

                    } else if (newValue && newValue.inputValue) {
                      // setNome(newValue.inputValue);
                      setTelefone('');
                      setCpfCnpj('');
                      setData('');
                      setCliente_id('');
                      // setConsultora('');
                    } else {
                      setCustomer(newValue);
                      // setNome(newValue.nome);
                      // setTelefone(removePrefix(newValue.telefone));
                      // setCpfCnpj(newValue.cpfOuCnpj || '');
                      // setData(newValue.data_nascimento);
                      // setCliente_id(newValue.id);
                      // setConsultora(newValue.consultora);
                      // handlePhone(removePrefix(newValue.telefone));
                    }
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable={true}
                  id="free-solo-with-text-demo"
                  options={leadsSheets}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Opção Regular
                    return option.nome;
                  }}
                  filterOptions={filterOptions}
                  renderOption={(props, option) => {
                    if (option.isNew) {
                      return (
                        <ListItem {...props} component="div">
                          <Button
                            variant="outlined"
                            
                            color="success"
                            sx={{ textTransform: 'capitalize' }}
                            onMouseDown={(event) => {
                              event.preventDefault(); // Prevent option from being selected
                              navigate('/clientes')
                            }}
                          >
                            + {option.nome}
                          </Button>
                        </ListItem>
                      );
                    }
            
                    return <li {...props}>{option.nome}</li>;
                  }}
                  // noOptionsText="Cliente não encontrado"
                  renderInput={(params) => (
                    <TextField {...params}
                      label="Cliente (Já cadastrado)"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <PatternFormat
                  onChange={(e) => setTelefone(e.target.value)}
                  format="(##) ##### ####"
                  mask="_"
                  fullWidth
                  placeholder="(00) 00000 0000"
                  value={customer?.telefone || ''}
                  label="Telefone (Válido)"
                  customInput={TextField}
                  onBlur={handlePhone}
                  error={!isValid.valid}
                  minLength={9}
                  disabled={type === 'pos_venda' || type === 'tecnica' ? true : false}
                  helperText={isValid.text}
                  variant="outlined"
                  color="primary"
                  required
                />
              </Grid>
              {type === 'pos_venda' || type === 'tecnica' ?
                null :
                <>
                  <Grid item xs={6}>
                    <PatternFormat
                      onChange={(e) => setData(e.target.value)}
                      format="## / ## / ####"
                      mask="_"
                      fullWidth
                      placeholder="00 / 00 / 0000"
                      value={data}
                      customInput={TextField}
                      label="Data de Nascimento"
                      variant="outlined"
                      color="primary"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="simple-select-label">Simulação</InputLabel>
                      <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={simulacao ? simulacao : ''}
                        label="Simulação"
                        onChange={(e) => setSimulacao(e.target.value)}
                        required
                      >
                        <MenuItem value="Aprovado">Aprovada</MenuItem>
                        <MenuItem value="Negada">Negada</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CNPJ/CPF"
                      type="text"
                      inputProps={{
                        minLength: 14
                      }}
                      onChange={(e) => setCpfCnpj(mask(e.target.value))}
                      value={cpfCnpj}
                      variant="outlined" />
                  </Grid>
                  {simulacao && simulacao === "Negada" && (
                    <Grid item xs={12}>
                      <Alert severity="warning" sx={{ marginTop: "1rem" }}>
                        <AlertTitle>Atenção</AlertTitle>
                        Cliente está <b>Ciente</b> que o método de pagamento só poderá ser por <b>PIX</b> ou parcelado no <b>Cartão de Crédito</b>?
                        <RadioGroup
                          row
                          aria-labelledby="radio-buttons-group-label"
                          defaultValue="Sim"
                          name="radio-buttons-group"
                          value={confirm ? confirm : ''}
                          onChange={(e) => setConfirm(e.target.value)}
                        >
                          <FormControlLabel value="Sim" control={<Radio required />} label="Sim" />
                          <FormControlLabel value="Não" control={<Radio required />} label="Não" />
                        </RadioGroup>
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ margin: '0 1rem' }}>
                    <FormControl fullWidth>
                      <FormLabel id="radio-buttons-group-label2">
                        Cliente tem proposta apresentada e quer prosseguir com a visita
                        técnica?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group-label2"
                        defaultValue="Sim"
                        name="radio-buttons-group2"
                        value={confirm2 ? confirm2 : ''}
                        onChange={(e) => setConfirm2(e.target.value)}
                      >
                        <FormControlLabel value="Sim" control={<Radio required />} label="Sim" />
                        <FormControlLabel value="Não" control={<Radio required />} label="Não" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {confirm2 && confirm2 === "Não" &&
                    <Grid item xs={12} sx={{ margin: '0 1rem' }}>
                      <FormControl fullWidth>
                        <FormLabel id="radio-buttons-group-label3">
                          A proposta tem Inversor 10kw pra cima?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="radio-buttons-group-label3"
                          defaultValue="Sim"
                          name="radio-buttons-group3"
                          value={confirm3 ? confirm3 : ''}
                          onChange={(e) => setConfirm3(e.target.value)}
                        >
                          <FormControlLabel value="Sim" control={<Radio required />} label="Sim" />
                          <FormControlLabel value="Não" control={<Radio required />} label="Não" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  }
                  <Grid item xs={12} sx={{ margin: '0 1rem' }}>
                    <FormControl fullWidth>
                      <FormLabel id="radio-buttons-group-label4">
                        Cliente já deu aceite no link da visita?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group-label4"
                        defaultValue="Sim"
                        name="radio-buttons-group4"
                        value={confirm4 ? confirm4 : ''}
                        onChange={(e) => setConfirm4(e.target.value)}
                      >
                        <FormControlLabel value="Sim" control={<Radio required />} label="Sim" />
                        <FormControlLabel value="Não" control={<Radio required />} label="Não" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sx={{ margin: '0 1rem' }}>
                    <FormControlLabel className={styles.check_form} control={<Checkbox
                      checked={checked}
                      required
                      sx={{ fontSize: '0.8rem' }}
                      onChange={(e) => setChecked(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Declaro que os dados fornecidos foram preenchidos com precisão e assumo total responsabilidade por sua veracidade." />
                  </Grid>
                </>
              }
            </Grid>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                disableElevation
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                disabled={!isValid.valid}
                color="success"
                type="submit"
              >Confirmar</Button>
              <Button
                disableElevation
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                onClick={() => close()}>Cancelar</Button>
            </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(Requirement);

import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
import SlickSlider from "react-slick";
import { styled } from '@mui/material/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import { theme } from "../../../../data/theme";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check'

import Homologacao from '../../../../images/visit/homologacao.jpg';
import { useState, useRef } from "react";
import { dataBase } from "../../../../firebase/database";
import { doc, updateDoc } from "firebase/firestore";
import useCircular from "../../../../hooks/useCircular";
import useUpload from "../../../../hooks/useUpload";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const RegisterVisitStep6 = ({visit, open, close }) => {
  const [pedido, setPedido] = useState([]);
  const { uploadImages, changeProgress } = useUpload(visit.id);
  const [step, setStep] = useState(0);
  const { CircularProgressWithLabel  } = useCircular();
  const slider = useRef(null);
  const [pageNumber, setPageNumber] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false]);
  const checkPhotos = ['PDF do Pedido de acesso e Homologação'];

  const settingsDoc = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const onDocumentLoadSuccess = ({ numPages }, type) => {
    switch(type) {
      case 'pedido':
        setPageNumber({...pageNumber, pedido: numPages});
      break;
      default: 
    }
  }

  const deletePhoto = (index, photos, type) => {
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);

     switch (type) {
      case 'pedido':
        setPedido(newPhotos);
        break;
        default:
     }
    }
  }

  const sendPhoto = async (e, type) => { // Envia as fotos de acordo com o tipo
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'pedido') {
      if(pedido) {
        setPedido([...pedido, ...files])
      } else {
        setPedido(files);
      }
    }
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  const closeBox = () => {
    close();
    setPedido([]);
    setStep(0);
  }

  console.log(visit)

  const sendAnalyseEnd = async() => {
    try {
      Swal.fire({
        title: 'Homologação',
        html: `Você deseja enviar a <b>Homologação?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if(result.isConfirmed) {
          let visita = {};
          let ref = {};
          setStep(1);
          const resultPedido = await uploadImages(pedido, 'pedido', 0);
              visita.pedido = resultPedido.map(object => object.downloadURL);
              ref.pedido = resultPedido.map(object => object.fullPath);
              console.log(visita)
              handleChangeCheck(0);
          
          await updateDoc(doc(dataBase, 'Visitas', visit.id), {
            pdf: {
              ref: ref,
              file: visita
            }
          }).then(async () => {
              Swal.fire({
                title: 'Concluido',
                html: `A <b>Homologação</b> foi enviado com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#F39200",
                confirmButtonText: "Ok",
                })
                closeBox();
            })
        }
      })
    } catch (error) {
      console.error('Ocorreu um erro: ', error);
    }
  }

  return (
    <Dialog
      className={style.anotation_container}
      sx={{ padding: '1rem' }}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => closeBox()}
      >
        <IconButton
          aria-label="close"
          onClick={() => closeBox()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Homologação</DialogTitle>
        <DialogContent>
      {/* <div className={style.register_container}>
        <DialogContentText sx={{ marginBottom: '0.2rem', textAlign: 'center' }}>
          Informe a <b>Data da Vistoria e Troca do Relógio</b>.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Nota"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        <DialogContentText sx={{ marginBottom: '0.2rem' }}>
          Deixe uma nota a respeito da análise do <b>Padrão/Telhado</b> do cliente para concluir.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Nota"
            type="text"
            rows={10}
            multiline
            value={anotation}
            onChange={(e) => setAnotation(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
      </div> */}
      {step === 0 && 
        <div className={style.register_container}>
          <div className={style.photo_item}>
                {pedido && pedido.length === 0 ? 
                  <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                    Envie o <b>Pedido de acesso e homologação Assinada</b> em <b>PDF</b>. <br />
                      Exemplo:
                    </DialogContentText>
                    <img className={style.img_visit} src={Homologacao} alt='Foto do Documento' /></> : 
                  <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                      Envie o <b>Pedido de acesso e homologação Assinada</b> em <b>PDF</b>
                    </DialogContentText><div className={style.slider_container}>
                      {pedido && pedido.length > 1 &&
                        <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                      }
                        <SlickSlider ref={slider} {...settingsDoc} className={style.slider}>
                          {pedido && pedido.map((img, index) => (
                            <div key={index} className={style.pdf}>
                              {/* <img rel="preconnect" src={getThumURL({url: img.file})} alt="" /> */}
                              <Document file={img.file} onLoadSuccess={(e) => onDocumentLoadSuccess(e, 'pedido')}>
                                <Page pageNumber={1} width={380} />
                              </Document>
                              <p>{img.complete.name}</p>
                              <b>{pageNumber.pedido} Páginas</b>
                              <Button 
                                disableElevation 
                                startIcon={<DeleteForeverIcon />}
                                variant='contained' 
                                color='error' 
                                sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                                onClick={() => deletePhoto(index, pedido, 'pedido')}>Excluir</Button>
                            </div>
                          ))}
                        </SlickSlider>
                      {pedido && pedido.length > 1 &&
                        <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                      }
                  </div></>
                }
                <div className={style.buttons_photo}>
                <ThemeProvider theme={theme2}>
                  <Button 
                    disableElevation
                    component="label" 
                    variant="contained" 
                    sx={{ textTransform: 'capitalize' }}
                    onChange={(e) => sendPhoto(e, 'pedido')} startIcon={<AddToPhotosIcon />}>
                    Enviar PDF
                    <VisuallyHiddenInput multiple={true} type="file" accept="application/pdf" />
                  </Button>
                </ThemeProvider>
              </div>
          </div>
        </div>
      }
        {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li key={index}>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} /> 
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
            <Button 
              disableElevation
              sx={{ textTransform: 'capitalize' }}
              variant='contained' 
              onClick={() => closeBox()}>Fechar</Button>
            <Button
              disableElevation
              sx={{ textTransform: 'capitalize' }}
              variant='contained' 
              color="success" 
              onClick={() => sendAnalyseEnd()}>Enviar</Button>
          </DialogActions>
        </ThemeProvider>
        </Dialog>
  )
}

export default RegisterVisitStep6;
import React, { useEffect, useState, useRef } from 'react'
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import Swal from "sweetalert2";
import style from "./style.module.scss";
import SlickSlider from "react-slick";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import ModulosInstalados from '../../../../images/install/modulos_instalados.jpeg';
import Inversor from '../../../../images/install/inversor.jpeg';
import Etiqueta from '../../../../images/install/etiqueta.jpeg';
import InversorLigado from '../../../../images/install/inversor_ligado.jpeg';
import InversorString from '../../../../images/install/inversor_string.jpeg';
import String from '../../../../images/install/string.jpeg';
import InjecaoCa from '../../../../images/install/injecao_ca.jpeg';
import Disjuntor from '../../../../images/install/disjuntor.jpeg';
import Placa from '../../../../images/install/placa.jpeg';
import Materiais from '../../../../images/install/materiais.jpg';

import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';
import axios from 'axios';
import moment from 'moment';

const InstallStep4 = ({isLoaded, visit, open, close }) => {
  const theme = useTheme();
  const { uploadImages, changeProgress } = useUpload(visit.id);
  const { CircularProgressWithLabel  } = useCircular();
  const [step, setStep] = useState(0);
  const dialogRef = useRef(null);
  const slider = useRef(null);
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [openAnotation, setOpenAnotation] = useState(false);
  const [anotation, setAnotation] = useState('');
  const [materiais, setMateriais] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [inversorInstalado, setInversorInstalado] = useState([]);
  const [etiqueta, setEtiqueta] = useState([]);
  const [inversorLigado, setInversorLigado] = useState([]);
  const [inversorString, setInversorString] = useState([]);
  const [string, setString] = useState([]);
  const [injecaoCA, setInjecaoCA] = useState([]);
  const [disjuntorGeral, setDisjuntorGeral] = useState([]);
  const [placa, setPlaca] = useState([]);
  const [position, setPosition] = useState(0);
  const [newPosition, setNewPosition] = useState(0);
  // const [photos, setPhotos] = useState();
  const [bitola, setBitola] = useState('');
  const [bitolaInfraCA, setBitolaInfraCA] = useState('');
  const [metragemCabo, setMetragemCabo] = useState('');
  const [metragemCA, setMetragemCA] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false],[false],[false],[false],[false], [false], [false], [false], [false]);

  const checkPhotos = ['Fotos dos Módulos Instalados','Fotos do Inversor Instalado', 'Fotos da Etiqueta de Informações',
   'Fotos do Inversor Ligado', 'Fotos do Inversor com String Box','Fotos da String Box', 'Fotos da Injeção CA',
    'Fotos do Disjuntor Geral', 'Fotos da Placa de Advertência', 'Fotos/Vídeos dos Materiais Restantes'];

  const steps = [
    'Fotos - Parte 1',
    'Fotos - Parte 2',
    'Materiais Utilizados',
    'GPS do Padrão'
  ];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    if(open) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({lat: position.coords.latitude, lng: position.coords.longitude});
      })
    }
  },[open])

  const closeBox = () => {
    close();
    setTimeout(() => {
      setModulos([]);
      setInversorInstalado([]);
      setEtiqueta([]);
      setInversorLigado([]);
      setInversorString([]);
      setString([]);
      setInjecaoCA([]);
      setDisjuntorGeral([]);
      setMateriais([]);
      setPlaca([]);
      setPosition(0);
      setNewPosition(0);
      // setPhotos();
      setCheckData([false], [false],[false], [false],[false], [false], [false], [false], [false]);
      setStep(0);
    }, 500);
  }

  const onMarkerDragEnd = (event) => {
    const newPos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setPosition(newPos);
  };

  

  const changeStep = (direction) => {
    console.log(metragemCA)
    if(direction === 'go') {
       if (step === 0 && (modulos.length === 0 || inversorInstalado.length === 0 || etiqueta.length === 0 || inversorLigado.length === 0)) {
        let validationPhoto;
        if(modulos.length === 0) {
          validationPhoto = 'de <b>todos os Módulos Instalados'
        } else if(inversorInstalado.length === 0) {
          validationPhoto = 'do <b>Inversor instalado'
        } else if(etiqueta.length === 0) {
          validationPhoto = 'das <b>Etiquetas'
        } else if(inversorLigado.length === 0) {
          validationPhoto = 'do <b>Inversor Ligado'
        }
        return Swal.fire({
          title: 'Fotos',
          html: `Envie a foto ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Ok",
        })
      } else if (step === 1 && (inversorString.length === 0 || injecaoCA.length === 0 || disjuntorGeral.length === 0 || placa.length === 0 || string.length === 0)) {
        let validationPhoto;
        if(inversorString.length === 0) {
          validationPhoto = 'do <b>Inversor com String Box'
        } else if(string.length === 0) {
          validationPhoto = 'do <b>String Box'
        } else if(injecaoCA.length === 0) {
          validationPhoto = 'da <b>Injeção da Conexão CA'
        } else if(disjuntorGeral.length === 0) {
          validationPhoto = 'do <b>Disjuntor Geral'
        } else if(placa.length === 0) {
          validationPhoto = 'da <b>Placa de Advertência'
        }
        return Swal.fire({
          title: 'Fotos',
          html: `Envie a foto ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Ok",
        })
      } else if (step === 2 && (materiais.length === 0 || bitolaInfraCA.length === 0 || bitola.length === 0 || metragemCA.length === 0 || metragemCabo.length === 0)) {
        let validationInput;
        if(materiais.length === 0) {
          validationInput = 'Envie foto ou video dos <b>Materiais'
        } else if(bitola.length === 0) {
          validationInput = 'Informe a bitola utilizada no <b>Aterramento'
        } else if(metragemCabo.length === 0) {
          validationInput = 'Informe o metro utilizado no <b>Aterramento'
        } else if(bitolaInfraCA.length === 0) {
          validationInput = 'Informe  a bitola utilizada no <b>Infra CA'
        } else if(metragemCA.length === 0) {
          validationInput = 'Informe do metro utilizado no <b>Infra CA'
        }
        return Swal.fire({
          title: 'Fotos',
          html: `${validationInput}</b> para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Ok",
        })
      }
      setStep(step + 1);
      setTimeout(() => {
        dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });         
      }, 50);
    } else {
      setStep(step - 1);
    }
  }

  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    console.log(files)
     if(type === 'modulos') {
      if(modulos) {
        setModulos([...modulos, ...files])
      } else {
        setModulos(files);
      }
    } else if(type === 'inversorInstalado') {
      if(inversorInstalado) {
        setInversorInstalado([...inversorInstalado, ...files])
      } else {
        setInversorInstalado(files);
      }
    } else if(type === 'etiqueta') {
      if(etiqueta) {
        setEtiqueta([...etiqueta, ...files])
      } else {
        setEtiqueta(files);
      }
    } else if(type === 'inversorLigado') {
      if(inversorLigado) {
        setInversorLigado([...inversorLigado, ...files])
      } else {
        setInversorLigado(files);
      }
    } else if(type === 'inversorString') {
      if(inversorString) {
        setInversorString([...inversorString, ...files])
      } else {
        setInversorString(files);
      }
    } else if(type === 'string') {
      if(string) {
        setString([...string, ...files])
      } else {
        setString(files);
      }
    } else if(type === 'injecaoCA') {
      if(injecaoCA) {
        setInjecaoCA([...injecaoCA, ...files])
      } else {
        setInjecaoCA(files);
      }
    } else if(type === 'disjuntorGeral') {
      if(disjuntorGeral) {
        setDisjuntorGeral([...disjuntorGeral, ...files])
      } else {
        setDisjuntorGeral(files);
      }
    } else if(type === 'placa') {
      if(placa) {
        setPlaca([...placa, ...files])
      } else {
        setPlaca(files);
      }
    } else if(type === 'materiais') {
      if(materiais) {
        setMateriais([...materiais, ...files])
      } else {
        setMateriais(files);
      }
    }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);

     switch (type) {
      case 'modulos':
        setModulos(newPhotos);
        break;
      case 'inversorInstalado':
        setInversorInstalado(newPhotos);
        break;
      case 'etiqueta':
        setEtiqueta(newPhotos);
        break;
      case 'inversorLigado':
        setInversorLigado(newPhotos);
        break;
      case 'inversorString':
        setInversorString(newPhotos);
        break;
      case 'string':
        setString(newPhotos);
        break;
      case 'injecaoCA':
        setInjecaoCA(newPhotos);
        break;
      case 'disjuntorGeral':
        setDisjuntorGeral(newPhotos);
        break;
      case 'placa':
        setPlaca(newPhotos);
        break;
      case 'materiais':
        setMateriais(newPhotos);
        break;
        default:
     }
    }
  }

  const sendVisit = () => {
    if(step === 2 && newPosition !== position) {
      return Swal.fire({
        title: 'Localização da Instalação',
        html: `Confirme a <b>Localização da Instalação</b> para finalizar a etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Instalação',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          setStep(4)
          let visita = {};
          let ref = {};

          try {
          const resultModulos = await uploadImages(modulos, 'modulos', 0)
              visita.modulos = resultModulos.map(object => object.downloadURL);
              ref.modulos = resultModulos.map(object => object.fullPath);
              handleChangeCheck(0);

          const resultInversorInstalado = await uploadImages(inversorInstalado, 'inversorInstalado', 1)
            visita.inversorInstalado = resultInversorInstalado.map(object => object.downloadURL);
            ref.inversorInstalado = resultInversorInstalado.map(object => object.fullPath);
            handleChangeCheck(1);
          
          const resultEtiqueta = await uploadImages(etiqueta, 'etiqueta', 2)
            visita.etiqueta = resultEtiqueta.map(object => object.downloadURL);
            ref.etiqueta = resultEtiqueta.map(object => object.fullPath);
            handleChangeCheck(2);

          const resultInversorLigado = await uploadImages(inversorLigado, 'inversorLigado', 3)
            visita.inversorLigado = resultInversorLigado.map(object => object.downloadURL);
            ref.inversorLigado = resultInversorLigado.map(object => object.fullPath);
            handleChangeCheck(3);  
          
          const resultInversorString = await uploadImages(inversorString, 'inversorString', 4)
            visita.inversorString = resultInversorString.map(object => object.downloadURL);
            ref.inversorString = resultInversorString.map(object => object.fullPath);
            handleChangeCheck(4); 
            
          const resultString = await uploadImages(string, 'string', 5)
            visita.string = resultString.map(object => object.downloadURL);
            ref.string = resultString.map(object => object.fullPath);
            handleChangeCheck(5); 

          const resultInjecaoCA = await uploadImages(injecaoCA, 'injecaoCA', 6)
            visita.injecaoCA = resultInjecaoCA.map(object => object.downloadURL);
            ref.injecaoCA = resultInjecaoCA.map(object => object.fullPath);
            handleChangeCheck(6);
          
          const resultDisjuntorGeral = await uploadImages(disjuntorGeral, 'disjuntorGeral', 7)
            visita.disjuntorGeral = resultDisjuntorGeral.map(object => object.downloadURL);
            ref.disjuntorGeral = resultDisjuntorGeral.map(object => object.fullPath);
            handleChangeCheck(7);

          const resultPlaca = await uploadImages(placa, 'placa', 8)
            visita.placa = resultPlaca.map(object => object.downloadURL);
            ref.placa = resultPlaca.map(object => object.fullPath);
            handleChangeCheck(8);

          const resultMateriais = await uploadImages(materiais, 'materiais', 9)
            visita.materiais = resultMateriais.map(object => object.downloadURL);
            ref.materiais = resultMateriais.map(object => object.fullPath);
            handleChangeCheck(9);
          
            await addDoc(collection(dataBase, 'Visitas', visit.id, 'Tecnica'), {
             ...visita,
             ref: ref,
             etapa: 8,
             GPS_instalacao: newPosition,
             bitola_aterramento: bitola,
             bitola_infra_ca: bitolaInfraCA,
             metragem_aterramento: metragemCabo,
             metragem_infra_ca: metragemCA
            }).then(async () => {
              await updateDoc(doc(dataBase, 'Visitas', visit.id), {
                instalacao_etapas: true,
                instalador: {
                  ...visit.instalador,
                  data_final: moment().format('YYYY-MM-DD'),
                  horario_final: moment().format('HH:mm')
                },
                jornada: {
                  etapa: 'Instalação',
                  index: 5,
                  sigla: 'INS',
                  cor: '#45e952',
                  data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
                }
              })

              await axios.post('https://n8n.corpbrasil.cloud/webhook/00ab7e27-4a12-48ef-9959-8ebcd740d843', {
                cliente: visit.cliente,
                cidade: visit.cidade,
                endereco: visit.endereco,
                consultora: visit.consultora,
                instalador: visit.instalador?.nome,
                gps_lat: newPosition.lat,
                gps_lng: newPosition.lng,
                id: visit.id,
                analista: visit.representante?.nome
              })
               Swal.fire({
               title: 'Instalação',
               html: `A <b>Instalação</b> foi concluida com sucesso.`,
               icon: "success",
               showCloseButton: true,
               confirmButtonColor: "#111",
               confirmButtonText: "Ok",
               }).then(() => {
                 closeBox();
               })
               close();
             })

          } catch (error) {
            console.error('Ocorreu um erro: ', error)
          } 
          }
        })
      }
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  return (
    <><Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={close}
    >
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Visita Técnica - Etapa 1</DialogTitle>
      <div ref={dialogRef}>
      <DialogContent className={style.dialog_content}>
        <div className={style.stepper}>
          <Stepper sx={{ width: '100%' }} activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {step === 0 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
            {modulos && modulos.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Tire fotos dos módulos instalados. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={ModulosInstalados} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Tire fotos dos módulos instalados.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {modulos && modulos.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, modulos, 'modulos')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'modulos')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {inversorInstalado && inversorInstalado.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Tire fotos do inversor instalado. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Inversor} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Tire fotos do inversor instalado.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {inversorInstalado && inversorInstalado.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, inversorInstalado, 'inversorInstalado')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'inversorInstalado')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {etiqueta && etiqueta.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Tire fotos das etiquetas de informação. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Etiqueta} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Tire fotos das etiquetas de informação.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {etiqueta && etiqueta.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, etiqueta, 'etiqueta')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'etiqueta')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {inversorLigado && inversorLigado.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 4:</b> Tire fotos do inversor ligado. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={InversorLigado} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 4:</b> Tire fotos do inversor ligado.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {inversorLigado && inversorLigado.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, inversorLigado, 'inversorLigado')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'inversorLigado')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
        </div>}
        {step === 1 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
            {inversorString && inversorString.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 5:</b> Tire fotos do inversor com string box. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={InversorString} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 5:</b> Tire fotos do inversor com string box.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {inversorString && inversorString.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, inversorString, 'inversorString')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'inversorString')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {string && string.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 6:</b> Tire uma foto da string box aberta. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={String} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 6:</b> Tire uma foto da string box aberta.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {string && string.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, string, 'string')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'string')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {injecaoCA && injecaoCA.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 7:</b> Tire fotos da injeção CA com a rede do cliente. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={InjecaoCa} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 7:</b> Tire fotos da injeção CA com a rede do cliente.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {injecaoCA && injecaoCA.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, injecaoCA, 'injecaoCA')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'injecaoCA')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {disjuntorGeral && disjuntorGeral.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 8:</b> Tire fotos do disjuntor geral. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Disjuntor} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 8:</b> Tire fotos do disjuntor geral.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {disjuntorGeral && disjuntorGeral.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, disjuntorGeral, 'disjuntorGeral')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'disjuntorGeral')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {placa && placa.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 9:</b> Tire fotos da placa de advertência. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Placa} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 9:</b> Tire fotos da placa de advertência.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {placa && placa.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, placa, 'placa')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'placa')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
        </div>}
      {step === 2 &&
        <div className={style.register_container}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '0.5rem' }}>
            <b>Materiais Utilizados</b> <br />
            Confirme os materiais utilizados para avançar para a próxima etapa. <br /><br />
          </DialogContentText>
          <div className={style.photo_item}>
            {materiais && materiais.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 10:</b> Tire fotos ou grave video dos materiais que restou. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Materiais} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 10:</b> Tire fotos ou grave video dos materiais que restou.
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {materiais && materiais.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                           }
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, materiais, 'materiais')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'materiais')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto / Vídeo
                <VisuallyHiddenInput multiple={true} type="file" aaccept="video/*,image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
            <p style={{ marginBottom: '0.5rem' }}>Qual bitola de cabo e a quantidade de metros foi utilizado no <b>aterramento</b> do sistema?</p>
            <div className={style.input_double}>
              <FormControl fullWidth>
                <InputLabel id="bitola">Bitola</InputLabel>
                <Select
                labelId='bitola'
                label="Bitola"
                value={bitola}
                onChange={(e) => setBitola(e.target.value)}
                required>
                  <MenuItem value="Infra Aterramento">Infra Aterramento</MenuItem>
                  <MenuItem value="Cabo Simples">Cabo Simples</MenuItem>
                  <MenuItem value="Cobre Nu">Cobre Nu</MenuItem>
                </Select>
              </FormControl>
              <OutlinedInput
                type='number'
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end">Mt(s)</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                value={metragemCabo}
                onChange={(e) => setMetragemCabo(e.target.value)}
               />
            </div>
          <DialogContentText sx={{ textAlign: "center", margin: '0.5rem 0', color: "#111" }}>
            Qual bitola de cabo e a quantidade de metros foi utilizado no <b>infra CA</b>?
          </DialogContentText>
          <div className={style.input_double}>
            <FormControl sx={{ display: 'flex' }} fullWidth>
              <InputLabel id="bitolaCA">Bitola</InputLabel>
              <Select
              labelId='bitolaCA'
              label="Bitola"
              value={bitolaInfraCA}
              onChange={(e) => setBitolaInfraCA(e.target.value)}
              required>
                <MenuItem value="Cabo Simples">Cabo Simples</MenuItem>
                <MenuItem value="Cobre Alumínio Quadruplex">Cabo Alumínio Quadruplex</MenuItem>
              </Select>
            </FormControl>
            <OutlinedInput
            type='number'
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">Mt(s)</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            value={metragemCA}
            onChange={(e) => setMetragemCA(e.target.value)}
          />
          </div>
          <div>
          </div>
        </div>}
      {step === 3 &&
        <div className={style.register_container}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
            <b>Localização GPS do Padrão</b> <br />
            Confirme a localização do Padrão para concluir a instalação.
          </DialogContentText>
          {isLoaded &&
            <GoogleMap mapContainerStyle={{ width: '100%', height: '300px' }} zoom={18} center={{ lat: position.lat, lng: position.lng }}>
              {position && <MarkerF position={{ lat: position.lat, lng: position.lng }} draggable={true} onDragEnd={onMarkerDragEnd} />}
            </GoogleMap>}
          <div>
            {position !== newPosition ?
              <Button 
              disableElevation
              color='success' 
              sx={{ maxWidth: '9rem', margin: '1rem', textTransform: 'capitalize' }} 
              variant='contained' 
              onClick={() => setNewPosition(position)}>Confirmar</Button> :
              <Button 
              color='success' 
              sx={{ maxWidth: '9rem', margin: '1rem', textTransform: 'capitalize' }} 
              variant='outlined' 
              endIcon={<CheckCircleOutlineIcon />}>Confirmado</Button>}
          </div>
        </div>}
      {step === 4 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
            {checkPhotos.map((data, index) => (
              <li>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} /> 
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      </div>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
        {step > 0 ?
          <Button 
            disableElevation
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            onClick={() => changeStep('back')}>Voltar</Button> :
          <Button 
            disableElevation
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            onClick={() => close()}>Fechar</Button>
        }
        {step > 2 ? 
          <Button 
            disableElevation
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            color='success' 
            onClick={() => sendVisit()}>Concluir</Button> :
          <Button 
            disableElevation
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 3 ? true: false} 
            onClick={() => changeStep('go')}>Próximo</Button>
        }
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    {/* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div className={style.photo_card}>
              <img src={photo.file} alt='' />
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */}
      <Dialog
      className={style.anotation_container}
      sx={{ padding: '1rem' }}
      open={openAnotation}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenAnotation(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenAnotation(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Anotação</DialogTitle>
        <DialogContent className={style.register_container}>
        <DialogContentText>
          Deixe uma anotação a respeito do <b>Padrão</b>
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            fullWidth
            margin="dense"
            label="Anotação"
            type="text"
            rows={10}
            multiline
            value={anotation}
            onChange={(e) => setAnotation(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenAnotation(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog></>
  )
}

export default InstallStep4
import { setDoc, doc } from "firebase/firestore";
import React, { useState } from "react";
import Swal from "sweetalert2"; // cria alertas personalizado
import { auth } from "../../../../firebase/database";
import { dataBase } from "../../../../firebase/database";
import { Company } from "../../../../data/Data";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// import "../../_modal.scss";

import { PatternFormat } from "react-number-format";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { theme } from '../../../../data/theme';
import { ThemeProvider, DialogContentText } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from "./styles.module.scss";

const Create = ({ members, open, close }) => {
  // const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [register, setRegister] = useState({
    nome: "",
    email: "",
    senha: "",
    razao_social: "",
    cnpj: "",
    pix: "",
    telefone: "",
    cidade: "",
    cargo: "Terceirizado"
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(register)
    const findEmail = members.find((member) => member.email === register.email);
    if (findEmail) {
      return Swal.fire({
        title: Company,
        html: `O email <b>${register.email}</b> já está cadastrado no sistema.`,
        icon: "warning",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      });
    } else {
      try {
        Swal.fire({
          title: Company,
          text: `Você deseja cadastrar um novo Terceirizado?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await createUserWithEmailAndPassword(
              auth,
              register.email,
              register.senha
            )
              .then((userCredential) => {
                // Signed in
                updateProfile(auth.currentUser, {
                  displayName: register.nome,
                })
                const user = userCredential.user;
                setDoc(doc(dataBase, "Membros", user.uid), {
                  ...register,
                  uid: user.uid,
                  relatorio: 0,
                  obras: []
                });
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
              });
            navigate("/");
            Swal.fire({
              title: Company,
              html: `O Terceirizado <b> ${register.nome}</b> foi cadastrado com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#111",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload(true); // Recarrega a pagina
              }
            });
          }
        });
      } catch (error) {
        // console.log(error);
      }
    }
  };

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Cadastrar Terceirizado</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
          Preencha os campos abaixo para cadastrar um novo <b>Terceirizado</b>.
        </DialogContentText>
        <form onSubmit={onSubmit}>
        <ThemeProvider theme={theme}>
        <TextField
          margin="dense"
          id="name"
          label="Nome"
          type="text"
          value={register.nome ?? ''}
          onChange={(e) => setRegister({ ...register, nome: e.target.value })}
          fullWidth
          required
          variant="outlined"
        />
        <div className={styles.label_content}>
          <TextField
            margin="dense"
            id="name"
            label="Razão Social"
            type="text"
            value={register.razao_social ?? ''}
            onChange={(e) => setRegister({ ...register, razao_social: e.target.value })}
            fullWidth
            required
            variant="outlined"
          />
          <PatternFormat
            value={register.cnpj ?? ''}
            onChange={(e) => setRegister({ ...register, cnpj: e.target.value })}
            format="##.###.###/####-##"
            mask="_"
            fullWidth
            sx={{ marginTop: '5px' }}
            placeholder="00 (00) 00000 0000"
            label="CNPJ"
            customInput={TextField}
            minLength={9}
            variant="outlined"
            color="primary"
            required
            /> 
        </div>
        <TextField
          margin="dense"
          id="name"
          label="Email"
          type="mail"
          value={register.email ?? ''}
          onChange={(e) => setRegister({ ...register, email: e.target.value })}
          fullWidth
          required
          variant="outlined"
        />
        <TextField
          margin="dense"
          id="name"
          label="Senha"
          type="text"
          value={register.senha ?? ''}
          onChange={(e) => setRegister({ ...register, senha: e.target.value })}
          fullWidth
          inputProps={{ minLength: 6 }}
          required
          variant="outlined"
        />
          <TextField
            margin="dense"
            id="name"
            label="Cidade"
            value={register.cidade ?? ''}
            onChange={(e) => setRegister({ ...register, cidade: e.target.value })}
            fullWidth
            required
            variant="outlined"
          />
        <div className={styles.label_content}>
            <PatternFormat
              value={register.telefone ?? ''}
              onChange={(e) => setRegister({ ...register, telefone: e.target.value })}
              format="## (##) ##### ####"
              mask="_"
              fullWidth
              sx={{ marginTop: '5px' }}
              placeholder="00 (00) 00000 0000"
              label="Telefone"
              customInput={TextField}
              inputProps={{ minLength: 12 }}
              variant="outlined"
              color="primary"
              required
            />
            <TextField
              margin="dense"
              id="name"
              label="Chave Pix"
              type="text"
              value={register.pix ?? ''}
              onChange={(e) => setRegister({ ...register, pix: e.target.value })}
              fullWidth
              inputProps={{ minLength: 3 }}
              required
              variant="outlined"
            />
        </div>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
          <DialogActions sx={{ justifyContent: 'center', marginTop: '1rem' }}>
            <Button disableElevation sx={{ textTransform: 'capitalize' }} color="success" variant="contained" type="submit">Cadastrar</Button>
            <Button disableElevation sx={{ textTransform: 'capitalize' }} variant="contained" onClick={close}>Cancelar</Button>
        </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Create;

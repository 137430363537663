const removeNullProperties = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      // Remove a propriedade se for null ou undefined
      delete obj[key];
    } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      // Recursivamente remove propriedades nulas ou indefinidas de objetos aninhados
      removeNullProperties(obj[key]);
    }
  }
  return obj;
};

export default removeNullProperties;
import React from 'react'
import { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { ReactComponent as ScheduleIcon2 } from "../../../images/icons/Schedule2.svg";
import { ReactComponent as CheckIcon } from "../../../images/icons/Check.svg";
import { ReactComponent as BlockIcon } from "../../../images/icons/Block.svg";
import { ReactComponent as ProspectionIcon } from '../../../images/icons/Prospection.svg';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'; // Visita Comercial
import PeopleIcon from '@mui/icons-material/People'; // Tecnica + Comercial
import EngineeringIcon from '@mui/icons-material/Engineering'; // Pós Venda
import { ReactComponent as Email } from '../../../images/icons/Mail.svg';
import { ReactComponent as Phone } from '../../../images/icons/Phone.svg';
import { ReactComponent as WhatsApp } from '../../../images/icons/WhatsApp.svg';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import RemoveDoneRoundedIcon from '@mui/icons-material/RemoveDoneRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';

const Dashboard = ({ schedule, monthSelect, type, total }) => {
  const [dataChart, setdataChart] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState();
  const [confirmar, setConfirmar] = useState();
  const [nconfirmar, setNconfirmar] = useState();
  const [visitasConfirmadas, setVisitasConfirmadas] = useState({
    aceito: 0,
    recusado: 0,
    espera: 0
  });
  const [visitas, setVisitas] = useState();
  const [atividadesHoje, setAtividadesHoje] = useState();
  const [mes, setMes] = useState();

  useEffect(() => {
    if (schedule && monthSelect && type !== 'prospeccao') {
          setData1(schedule.filter((vis) => vis.categoria === 'comercial').length);
          setData2(schedule.filter((vis) => vis.categoria === 'comercial_tecnica').length);
          setData3(schedule.filter((vis) => vis.categoria === 'pos_venda').length);
          setData4(schedule.filter((vis) => vis.categoria === 'tecnica').length);
          setConfirmar(schedule.filter((vis) => vis.confirmar === true).length);
          setNconfirmar(schedule.filter((vis) => vis.confirmar === false).length);
          setVisitasConfirmadas({
            aceito: schedule.filter((vis) => vis.categoria !== 'pos_venda' && vis.confirmarVisita?.status === "✅ Aceito").length,
            recusado: schedule.filter((vis) => vis.categoria !== 'pos_venda' && vis.confirmarVisita?.status === "❌ Recusado").length,
            espera: schedule.filter((vis) => vis.categoria !== 'pos_venda' && !vis.confirmarVisita).length
          })
          setVisitas(schedule.filter((vis) => vis.categoria !== 'lunch').length);
          switch(monthSelect) {
              case '01':
                setMes('Janeiro');
                break;
              case '02':
                setMes('Fevereiro');
                break;
              case '03':
                setMes('Março');
                break;
              case '04':
                setMes('Abril');
                break;
              case '05':
                setMes('Maio');
                break;
              case '06':
                setMes('Junho');
                break;
              case '07':
                setMes('Julho');
                break;
              case '08':
                setMes('Agosto');
                break;
              case '09':
                setMes('Setembro');
                break;
              case '10':
                setMes('Outubro');
                break;
              case '11':
                setMes('Novembro');
                break;
              case '12':
                setMes('Dezembro');
                break;
              default:
                break;
            }
      }
    if(schedule && type === 'prospeccao') {
      setData1(schedule.filter((vis) => vis.atividade === 'Email').length);
      setData2(schedule.filter((vis) => vis.atividade === 'Ligação').length);
      setData3(schedule.filter((vis) => vis.atividade === 'WhatsApp').length);
      setAtividadesHoje(schedule.filter((act) => act.dataRef === moment(new Date()).format('YYYY-MM-DD')));
    }
  }, [monthSelect, schedule, type]);

  useEffect(() => {
    if(schedule) {
      if(type === 'prospeccao') {
        setdataChart([
          {
            name: 'Bruna',
            Atividades: schedule.filter((con) => con.consultora === 'Bruna').length,
            fill: '#44BF2B'
          },
          {
            name: 'Mayara',
            Atividades: schedule.filter((con) => con.consultora === 'Mayara').length,
            fill: '#e5dd06'
          },
          {
            name: 'Paulo',
            Atividades: schedule.filter((con) => con.consultora === 'Paulo').length,
            fill: '#063'
          }
          // {
          //   name: 'Fernanda',
          //   Atividades: schedule.filter((con) => con.consultora === 'Fernanda').length,
          //   fill: '#FFC107'
          // },
          // {
          //   name: 'Leticia',
          //   Atividades: schedule.filter((con) => con.consultora === 'Leticia').length,
          //   fill: '#B901C6'
          // }
        ])
      } else {
        setdataChart([
          {
            name: 'Bruna',
            Visitas: schedule.filter((con) => con.consultora === 'Bruna').length,
            fill: '#44BF2B'
          },
          {
            name: 'Mayara',
            Visitas: schedule.filter((con) => con.consultora === 'Mayara').length,
            fill: '#e5dd06'
          },
          {
            name: 'Paulo',
            Visitas: schedule.filter((con) => con.consultora === 'Paulo').length,
            fill: '#063'
          },
          // {
          //   name: 'Fernanda',
          //   Visitas: schedule.filter((con) => con.consultora === 'Fernanda').length,
          //   fill: '#FFC107'
          // },
          // {
          //   name: 'Leticia',
          //   Visitas: schedule.filter((con) => con.consultora === 'Leticia').length,
          //   fill: '#B901C6'
          // }
        ])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[schedule])

  console.log(visitas)

  return (
    <div className="dashboard">
          <h2 className="dashboard-title">Visão Geral</h2>
          <div className="dashboard__content">
          <div className="dashboard__box1">
            {type === 'prospeccao' ? 
            <div className="dashboard__box1-info">
              {schedule ? 
              <><h1>{schedule && schedule.length}</h1>
              <h2>Atividades Totais</h2></> :
              <Stack>
                <Skeleton variant='rounded' width={100} height={50} />
                <Skeleton variant='rounded' width='100%' height={50} />
              </Stack>
              }
            </div> :
            <div className="dashboard__box1-info" style={{ height: '40%' }}>
              {schedule ? 
              <><h1>{visitas}</h1>
              <h2>Visitas em {mes}</h2></> :
              <Stack>
                <Skeleton variant='rounded' width={100} height={50} />
                <Skeleton variant='rounded' width='100%' height={50} />
              </Stack>
            }
            </div> 
          }
            {type === 'financeiro' &&
            <div className="dashboard__box1-info" style={{ height: '60%' }}>
              <h1>{total ? total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'R$ 0,00'}</h1>
              <h2>Comissão em {mes}</h2>
            </div>
            }
            {type === 'visit' && 
              <div className="dashboard__box11-info">
                {schedule ? 
                <>
                <h2><b>Visitas</b></h2>
                <div>
                  <CheckIcon />
                  <h2>{confirmar}</h2>
                  <p>Confirmada(s)</p>
                </div>
                <div>
                  <BlockIcon />
                  <h2>{nconfirmar}</h2>
                  <p>Não Confirmada(s)</p>
                </div>
                {/* <h3 style={{ marginTop: '0.3rem' }}>Clientes</h3> */}
                <div>
                  <DoneAllRoundedIcon sx={{ fill: "#72cd4e" }} />
                  <h2>{visitasConfirmadas.aceito}</h2>
                  <p>Aceito(s)</p>
                </div>
                <div>
                  <RemoveDoneRoundedIcon sx={{ fill: "red" }} />
                  <h2>{visitasConfirmadas.recusado}</h2>
                  <p>Recusado(s)</p>
                </div>
                <div>
                  <HourglassEmptyRoundedIcon sx={{ fill: "#ccc" }} />
                  <h2>{visitasConfirmadas.espera}</h2>
                  <p>Em Espera</p>
                </div></> :
                  <Stack>
                    <Skeleton variant='rounded' width='90%' height={30} />
                    <Skeleton variant='rounded' width='90%' height={30} />
                  </Stack>
                }
              </div>
            }
            {type === 'prospeccao' && 
              <div className="dashboard__box1-info">
              {atividadesHoje ? 
                <><h1>{atividadesHoje && atividadesHoje.length}</h1>
                <h2>Atividades Hoje</h2></> :
              <Stack>
                  <Skeleton variant='rounded' width={100} height={50} />
                  <Skeleton variant='rounded' width='100%' height={50} />
                </Stack>
            }
            </div>
            }
          </div>
          {type === 'prospeccao' ? 
          <div className="dashboard__box2">
            {schedule && type ? 
            <><div className="dashboard__box2-icon">
                <ProspectionIcon />
              </div><h2>Atividades</h2><ul className="dashboard__box2-visits">
                  <li>
                    <div className="dashboard__box2-visits-info">
                      <span className="visit-icon mail"><Email /></span>
                      <p>Email</p>
                    </div>
                    <h3>{data1 ? data1 : 0}</h3>
                  </li>
                  <li>
                    <div className="dashboard__box2-visits-info">
                      <span className="visit-icon phone"><Phone /></span>
                      <p>Ligação</p>
                    </div>
                    <h3>{data2 ? data2 : 0}</h3>
                  </li>
                  <li>
                    <div className="dashboard__box2-visits-info">
                      <span className="visit-icon whatsapp"><WhatsApp /></span>
                      <p>WhatsApp</p>
                    </div>
                    <h3>{data3 ? data3 : 0}</h3>
                  </li>
                </ul></> :
                <Stack width='100%'
                justifyContent='center'
                alignItems='center'
                spacing={4}>
                  <Skeleton variant='circular' width={72} height={72} />
                  <Skeleton variant='rounded' width='80%' height={30} />
                  <Skeleton variant='rounded' width='80%' height={30} />
                  <Skeleton variant='rounded' width='80%' height={30} />
              </Stack>
                }
          </div> :
          <div className="dashboard__box2">
            {schedule && type && monthSelect ?
          <><div className="dashboard__box2-icon">
                <ScheduleIcon2 />
              </div><h2>Visitas</h2><ul className="dashboard__box2-visits">
                  <li>
                    <div className="dashboard__box2-visits-info">
                      <span className="visit-icon comercial"><RequestQuoteIcon /></span>
                      <p>Comercial</p>
                    </div>
                    <h3>{data1}</h3>
                  </li>
                  <li>
                    <div className="dashboard__box2-visits-info">
                      <span className="visit-icon comercial_tec"><PeopleIcon /></span>
                      <p>Comercial + Téc.</p>
                    </div>
                    <h3>{data2}</h3>
                  </li>
                  <li>
                    <div className="dashboard__box2-visits-info">
                      <span className="visit-icon tec"><EngineeringIcon /></span>
                      <p>Técnica</p>
                    </div>
                    <h3>{data4}</h3>
                  </li>
                  <li>
                    <div className="dashboard__box2-visits-info">
                      <span className="visit-icon pos_venda"><EngineeringIcon /></span>
                      <p>Pós-Venda</p>
                    </div>
                    <h3>{data3}</h3>
                  </li>
                </ul></> :
                <Stack width='100%'
                  justifyContent='center'
                  alignItems='center'
                  spacing={4}>
                  <Skeleton variant='circular' width={72} height={72} />
                  <Skeleton variant='rounded' width='80%' height={30} />
                  <Skeleton variant='rounded' width='80%' height={30} />
                  <Skeleton variant='rounded' width='80%' height={30} />
                </Stack>
                }
            </div> 
        }
          {type === 'prospeccao' ?
          <div className="dashboard__box3">
            {dataChart ? 
            <><h2>Atividades por Consultoras</h2>
            <ResponsiveContainer width="90%" height="80%">
                <BarChart
                  width='100%'
                  height='100%'
                  data={dataChart}
                  margin={{
                    top: 5,
                    right: 30,
                    bottom: 5,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {type === 'prospeccao' ?
                    <Bar dataKey="Atividades" fill="#8884d8" /> :
                    <Bar dataKey="Visitas" fill="#8884d8" />}
                </BarChart>
              </ResponsiveContainer></> 
              :
              <Stack width='80%' 
              direction='row' 
              spacing={3} 
              justifyContent='center' 
              alignItems='center'>
                <Skeleton variant='rectangular' width={100} height={300} />
                <Skeleton variant='rectangular' width={100} height={300} />
                <Skeleton variant='rectangular' width={100} height={300} />
              </Stack>
            }
          </div> :
          <div className="dashboard__box3">
          {schedule && monthSelect ? 
          <><h2>Visitas por Consultoras</h2>
          <ResponsiveContainer width="90%" height="80%">
                <BarChart
                  width='100%'
                  height='100%'
                  data={dataChart}
                  margin={{
                    top: 5,
                    right: 30,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Visitas" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer></> :
          <Stack width='80%' 
          direction='row' 
          spacing={3} 
          justifyContent='center' 
          alignItems='center'>
            <Skeleton variant='rectangular' width={100} height={300} />
            <Skeleton variant='rectangular' width={100} height={300} />
            <Skeleton variant='rectangular' width={100} height={300} />
          </Stack>
        }
        </div>
        }
          </div>
        </div>
  )
}

export default Dashboard;
import React, { useEffect, useState } from 'react'

import styles from './style.module.scss';

import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton, InputAdornment, TextField } from '@mui/material';
import { PatternFormat } from 'react-number-format';
import LoadingButton from '@mui/lab/LoadingButton';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../data/theme';

import UpdatePassword from './Components/UpdatePassword';
import Swal from 'sweetalert2';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../firebase/database';

import Logo from '../../images/Logo512.png';
import UploadInput from '../../components/UploadInput';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const Profile = ({ userRef }) => {
    const storage = getStorage();
    const [info, setInfo] = useState({
        nome: '',
        email: '',
        pix: '',
        telefone: '',
        cidade: ''
    });
    const [errors, setErrors] = useState({});
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        if(userRef) {
            if(userRef.cargo === 'Representante') {
                setInfo({
                    nome: userRef.nome,
                    email: userRef.email,
                    cidade: userRef.cidade,
                    pix: userRef.pix,
                    telefone: userRef.telefone,
                    id_representante: userRef.id_representante
                })
            } else {
                setInfo({
                    nome: userRef.nome,
                    email: userRef.email,
                    cidade: userRef.cidade ?? '',
                    pix: userRef.pix ?? '',
                    telefone: userRef.telefone,
                })
            }
            setPhoto({ file: userRef.photo?.url ?? null})
        }
    },[userRef])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInfo({...info,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: ''
        })
    }

    const sanitizeFields = (obj) => { // Remove caracteres especiais das props do fieldsToSanitize
        const removeSpecialCharacters = (str) => {
          return str.replace(/[^0-9]/g, '');
        };
      
        const fieldsToSanitize = ['telefone']; // Arrays de propriedades que serão formatadas
      
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (fieldsToSanitize.includes(key) && typeof value === 'string') {
            acc[key] = removeSpecialCharacters(value);
          } else {
            acc[key] = value;
          }
          return acc;
        }, {});
      };

    const validateInput = (name, value) => {
        let errorMsg = '';
    
        switch(name) {
            case 'telefone': 
            if (!value || value.includes("_")) {
                errorMsg = 'É necessário informar um telefone válido.';
              }
            break;
            default: 
                return null;
        }
        
        return errorMsg;
    }

    const handleUpload = async () => {

        if (!photo || (photo && !photo.complete)) {
          return null;
        }
    
        const photoRef = ref(storage, `Membros/${userRef && userRef.id}/Profile-${Date.now()}.${photo.complete.type.split('/')[1]}`);
    
        try {
    
          // Upload da foto
          const photoSnapshot = await uploadBytes(photoRef, photo.complete);
          const photoURL = await getDownloadURL(photoSnapshot.ref);
    
          console.log('Foto enviada com sucesso! URL:', photoURL);

          if(userRef && userRef.photo) {
            await deleteObject(ref(storage, userRef.photo.ref));
          }
    
          const photoProps = {
            url: photoURL,
            ref: photoSnapshot.ref.fullPath
          };
    
          return photoProps;
    
        } catch (error) {
          console.error('Erro ao enviar arquivos:', error);
        }
      };
    

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log(info);
        
        try {
            
            const result = await Swal.fire({
                title: 'Atenção',
                text: `Você deseja alterar os dados?`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
              })
                if (result.isConfirmed) {
                    setLoadingButton(true);
                    const newInfo = sanitizeFields(info);
                    const photoUpload = await handleUpload();
                    console.log(photoUpload);
                    await updateDoc(doc(dataBase, 'Membros', userRef.id), {
                        ...newInfo,
                        photo: photoUpload ? photoUpload : userRef && userRef.photo ? userRef.photo : null
                    }).then(() => {
                        Swal.fire({
                            title: 'Perfil',
                            html: `Os dados foram alterados com sucesso.`,
                            icon: "success",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonColor: "#0eb05f",
                          })
                    })
                    setLoadingButton(false);
                    if(photoUpload) {
                        setPhoto({ file: photoUpload.url });
                    }
                }
        } catch (error) {
            console.log(error);
            setLoadingButton(false);
        }  
    }

    const checkCargo = () => {
        if (userRef && userRef.cargo !== 'Representante') {
            return true;
        } else {
            return false;
        }
    }

    const handleBlur = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        const error = validateInput(name, value);
            setErrors({
                ...errors,
                [name]: error
            });
    }

    const sendPhoto = (e) => {
        const image = e.target.files[0];
        setPhoto({ file: URL.createObjectURL(image), complete: image });
        console.log(image)
    }

    const removePhoto = () => {
        setPhoto(null);
    }

    const handleOffice = (office) => {
        switch (office) {
            case 'Vendedor(a)':
                if(userRef?.nome === 'Pós-Venda') {
                    return 'Pós-Venda'
                } return office;
            case 'Instalador': 
                return 'Terceirizado';
            case 'Limpeza': 
                return 'Terceirizado';
            default: 
                return office;
        }
    }

  return (
        <div className={styles.container_panel}>
            <UpdatePassword userRef={userRef} open={open} close={() => setOpen(false)}/>
            <div className={styles.title_panel}>
                <div className={styles.photo_profile}>
                    <img src={photo && photo.file ? photo.file : Logo} alt='' />
                    {photo && photo.complete ? 
                        <IconButton 
                            size='small'
                            onClick={removePhoto} 
                            className={styles.photo_close}>
                                <CloseIcon sx={{ fill: "#fff" }}/>
                        </IconButton> : null
                    }
                    <IconButton 
                        component="label" 
                        size='small'
                        onChange={sendPhoto} 
                        className={styles.photo_edit}>
                        <EditIcon sx={{ fill: "#fff" }}/>
                        <UploadInput accept="image/png,image/jpeg" />
                    </IconButton>
                </div>
                <div className={styles.name_profile}>
                    <h3>{userRef ? userRef.nome_completo ? userRef.nome_completo : userRef.nome : null}</h3>      
                    <p>{handleOffice(userRef?.cargo)}</p>
                </div>
            </div>
            <ThemeProvider theme={theme}>
                <form className={styles.form_profile} onSubmit={onSubmit} autoComplete='off'>
                    <TextField
                        name='nome'
                        fullWidth
                        variant='outlined'
                        value={info.nome ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Nome"
                        required
                        disabled={checkCargo()}
                        InputProps={ checkCargo() ? {
                            endAdornment:
                                <InputAdornment position="end">
                                    <LockIcon />
                                </InputAdornment>
                        } : null}
                    />
                    <TextField
                        name='email'
                        fullWidth
                        variant='outlined'
                        value={info.email ?? ''}
                        label="E-mail"
                        disabled
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <LockIcon />
                                </InputAdornment>
                        }}
                    />
                    {!checkCargo() ? 
                        <TextField
                            name='id_representante'
                            fullWidth
                            variant='outlined'
                            value={info.id_representante || ''}
                            label="Código"
                            disabled
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <LockIcon />
                                    </InputAdornment>
                            }}
                        /> : null
                    }
                    <TextField
                        name='cidade'
                        fullWidth
                        variant='outlined'
                        value={info.cidade ?? ''}
                        onChange={handleChange}
                        label="Cidade"
                        required
                    />
                    <PatternFormat
                        onChange={handleChange}
                        format="## (##) ##### ####"
                        mask="_"
                        name='telefone'
                        fullWidth
                        customInput={TextField}
                        onBlur={handleBlur}
                        placeholder="00 (00) 00000 0000"
                        value={info.telefone ?? ''}
                        label="Telefone"
                        error={!!errors.telefone}
                        helperText={errors.telefone}
                        variant="outlined"
                        color="primary"
                        required
                        />
                    <TextField
                        name='pix'
                        fullWidth
                        variant='outlined'
                        value={info.pix}
                        onChange={handleChange}
                        label="Chave Pix"
                        required
                    />
                    <div className={styles.button_form}>
                        <LoadingButton
                            disableElevation
                            size='large'
                            fullWidth
                            loading={loadingButton}
                            variant='contained'
                            color='success'
                            type='submit'
                            sx={{ textTransform: 'capitalize' }}>
                                Confirmar
                        </LoadingButton>
                        <LoadingButton
                            disableElevation
                            size='large'
                            fullWidth
                            sx={{
                                "&.Mui-selected": {},
                                ":hover": {
                                    borderWidth: '2px'
                                },
                                ":focus": {
                                    borderWidth: '2px'
                                },
                                borderWidth: '2px',
                                textTransform: 'capitalize'
                            }}
                            loading={loadingButton}
                            variant='outlined'
                            color='primary'
                            onClick={() => setOpen(true)}
                            >
                            Alterar Senha
                        </LoadingButton>
                    </div>
                </form>
            </ThemeProvider>
        </div>
  )
}

export default Profile
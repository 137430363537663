import { collection, getDocs } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { dataBase } from '../firebase/database'

export const useMember = () => {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const membersCollection = collection(dataBase, 'Membros');
            const membersSnapshot = await getDocs(membersCollection);
            const membersList = membersSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            
            setMembers(membersList);
            console.log(membersList);
          } catch (error) {
            console.error("Erro ao buscar os dados dos membros: ", error);
          }
        };
    
        fetchData();
      }, []);

    const getMemberColor = (id) => {
        const member = members.find((member) => member.id === id);
        return member ? member.cor : 'transparent';
    }
    
  return { getMemberColor, members }; 
}
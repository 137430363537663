import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { StyledMenu } from '../../../components/StyledMenu';

import { Divider } from '@mui/material';
import NewTooltip from '../../../components/Tooltip';
import Swal from 'sweetalert2';
import { toast } from '../../../components/Toast';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import { capitalizeString } from '../../../functions/capitalizeString';
import axios from 'axios';


const StatusCustomer = ({ customer, userRef }) => {
    const [status, setStatus] = useState('aberto');
    const [anchorElStatus, setAnchorElStatus] = useState(null);
    const open = Boolean(anchorElStatus);

    useEffect(() => {
        if(customer?.status) {
            setStatus(customer.status.valor);
        }
    },[customer])

    const handleChipClick = (event) => {
        setAnchorElStatus(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorElStatus(null);
    };
    
    const handleChangeStatus = async (type) => {

        const typeFormated = capitalizeString(type);
        handleMenuClose();

        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja alterar o status para <b>${typeFormated}</b>?`,
                icon: "warning",
                iconColor: "#000",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
              })

            if(result.isConfirmed) {

                const colorStatus = {
                    ganho: '#2e7d32',
                    perdido: '#d32f2f',
                    aberto: '#575757',
                }

                const customerStatus = customer.status?.valor || 'aberto';

                await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                    status: {
                        valor: type,
                        text: `${typeFormated} pelo(a) ${userRef?.nome} em ${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
                    },
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Cliente Alterado',
                        text: `<b>[Status]:</b> <b style="color: ${colorStatus[customerStatus]}">${capitalizeString(customerStatus)}</b> > <b style="color: ${colorStatus[type]}">${capitalizeString(type)}</b>.`,
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                      }]
                })
                await sendN8N(type);
                toast({ icon: 'success', text:'Status alterado com sucesso!' });
                setStatus(type);
            }
        } catch (error) {
            toast({ icon: 'error', text:'Ocorreu um erro ao alterar o status.' });
            console.log('ERRO: ', error)
        }
    };

    const sendN8N = async (type) => {
        return await axios.post("https://n8n.corpbrasil.cloud/webhook/41485ed1-bb2f-42e9-8a8b-735af046b818", {
            id: customer.id,
            status: capitalizeString(type),
            cliente: customer.nome,
            telefone: customer.telefone,
            cidade: customer.cidade,
            responsavel: customer.consultora,
            analista: customer.assistente_nome || 'Nenhum',
            representante: customer.representante?.nome || 'Nenhum',
            indicador: customer.indicador?.nome || 'Nenhum'
        })
    }

    const chipProps = {
        ganho: {
            label: 'Ganho',
            sx: { backgroundColor: "#e9fbf0", fontWeight: '500' },
            icon: <ThumbUpRoundedIcon sx={{ fontSize: '22px', paddingLeft: '0.2rem' }} />,
            color: 'success',
        },
        perdido: {
            label: 'Perdido',
            sx: { backgroundColor: "#fbe9e9", fontWeight: '500' },
            icon: <ThumbDownRoundedIcon sx={{ fontSize: '22px', paddingLeft: '0.2rem' }} />,
            color: 'error',
        },
        aberto: {
            label: 'Aberto',
            sx: { backgroundColor: "#f8f8f8", fontWeight: '500' },
            icon: <VisibilityRoundedIcon sx={{ fontSize: '22px', paddingLeft: '0.2rem' }} />,
        },
    };

    return (
        <div>
            <NewTooltip title={customer?.status?.text || ''} placement='top'>
                <Chip
                    {...chipProps[status]}
                    variant='outlined'
                    deleteIcon={<KeyboardArrowDownIcon />}
                    onDelete={handleChipClick}
                    disabled={userRef?.uid !== customer.consultora_uid && userRef?.cargo !== 'Administrador' && userRef?.uid !== customer.assistente}
                    id="chip-status clickable"
                    aria-controls={open ? 'menu-status' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined} />
            </NewTooltip>
            <StyledMenu
                MenuListProps={{ 'aria-labelledby': 'chip-status' }}
                id="menu-status"
                anchorEl={anchorElStatus}
                open={open}
                onClose={handleMenuClose}>
                <MenuItem 
                    onClick={() => handleChangeStatus('ganho')} 
                    selected={status === 'ganho'} 
                    disableRipple
                    disabled={status === 'ganho'} 
                    sx={{ color: "#2e7d32" }}>
                        <ThumbUpRoundedIcon sx={{ fill: "#2e7d32" }} />Ganho
                </MenuItem>
                <MenuItem 
                    onClick={() => handleChangeStatus('perdido')}
                    selected={status === 'perdido'} 
                    disableRipple
                    disabled={status === 'perdido'} 
                    sx={{ color: "#d32f2f" }}>
                        <ThumbDownRoundedIcon sx={{ fill: "#d32f2f" }} />Perdido
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem 
                    onClick={() => handleChangeStatus('aberto')} 
                    selected={status === 'aberto'} 
                    disabled={status === 'aberto'}
                    disableRipple>
                        <VisibilityRoundedIcon />Aberto
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

export default StatusCustomer;

import React, { useEffect, useRef, useState, memo } from 'react';
import AudioPlayer from 'react-h5-audio-player';
// import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import 'react-h5-audio-player/lib/styles.css';
import './styles.scss';

import { Button } from '@mui/material';

const CustomAudioPlayer = ({ url, color = "#00A7EF", background = "#fff" }) => {
  const [isClient, setIsClient] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const audioRef = useRef(null);
  // const [audioBlob, setAudioBlob] = useState(null);
  // const [play, setPlay] = useState(false);

  useEffect(() => {
    // Este efeito garante que o componente seja renderizado apenas no lado do cliente
    setIsClient(true);
  }, []);


  // const fetchAudioBlob = async (audioUrl) => {
  //   try {
  //     const response = await axios.get(audioUrl, {
  //       responseType: 'arraybuffer',
  //     });
  //     return new Blob([response.data], { type: 'audio/webm' });
  //   } catch (error) {
  //     console.error('Failed to fetch audio blob:', error);
  //     return null;
  //   }
  // };


  // useEffect(() => {
  //   let isMounted = true;

  //   // Converter URL em Blob
  //   fetchAudioBlob(url).then(blob => {
  //     if (isMounted && blob) {
  //       setAudioBlob(blob);
  //     }
  //   });

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [url, play]);

  if (!isClient) {
    return null; // Não renderiza nada no lado do servidor
  }

  const changePlaybackRate = () => {
    const newRate = playbackRate === 1 ? 1.5 : playbackRate === 1.5 ? 2 : 1;
    setPlaybackRate(newRate);
    if (audioRef.current) {
      audioRef.current.audio.current.playbackRate = newRate;
    }
  };

  return (
    <>
      <style>
        {`
      .rhap_progress-section {
    gap: 0.5rem;
}

.rhap_controls-section {
    flex: none!important;
}

.rhap_progress-indicator {
    width: 10px!important;
    height: 15px!important;
    top: -5px!important;
    background-color: ${color}!important;
}

.rhap_container {
    box-shadow: none;
    width: auto;
}

.rhap_progress-filled {
    background-color: #a5dffd!important;
}

.rhap_volume-container {
    width: 50px;
    margin-right: 10px;
}
.rhap_play-pause-button {
    width: 50px;
    height: 50px;
}
.rhap_progress-section {
    flex: 0;
  }

.rhap_time {
    color: ${color};
      }
    `}
      </style>
      <AudioPlayer
        style={{ padding: '0.5rem', zIndex: '11', background: background }}
        customAdditionalControls={[]}
        ref={audioRef}
        customProgressBarSection={[
          <Button 
            onClick={changePlaybackRate} 
            variant='contained' 
            disableElevation 
            sx={{ 
              padding: '0rem 0.2rem', 
              minWidth: '0', 
              textTransform: 'lowercase', 
              backgroundColor: "#747474",
              '&:hover': {
                        backgroundColor: '#494949',
                    }, }}>
              {playbackRate}x
          </Button>
        ]}
        customVolumeControls={[]}
        autoPlay={false}
        layout="horizontal-reverse"
        showJumpControls={false}
        preload='auto'
        customIcons={{
          play: <PlayCircleOutlineRoundedIcon sx={{ fontSize: '50px', fill: color }} />,
          pause: <PauseCircleFilledRoundedIcon sx={{ fontSize: '50px', fill: color }} />
        }}
        src={url ?? ''}
        // onPlay={e => setPlay(true)}
      /></>
  );
};

export default memo(CustomAudioPlayer);

import axios from "axios";
import { useEffect, useState, useRef, forwardRef, memo } from "react";
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Link, useNavigate } from 'react-router-dom';
import { dataBase } from "../../firebase/database";

// import Header from "../../components/Header/Index";
import useAuth from "../../hooks/useAuth";
import Dashboard from "../../components/Dashboard/Visit_and_Prospection/Index";
import Filter from "../../components/Filter/Index";
import Gps from "./Components/Register_Visit/Gps";
import RegisterVisitStep1 from "./Components/Register_Visit/Step1";
import RegisterVisitStep15 from "./Components/Register_Visit/Step1_5";
import RegisterVisitStep2 from "./Components/Register_Visit/Step2";
import RegisterVisitStep3 from "./Components/Register_Visit/Step3";
import VisitTec from "./Components/Visit_Tec/Index";
import Logo from '../../images/logo192.png';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'; // Visita Comercial
import PeopleIcon from '@mui/icons-material/People'; // Tecnica + Comercial
import EngineeringIcon from '@mui/icons-material/Engineering'; // Pós Venda
import RestaurantIcon from '@mui/icons-material/Restaurant'; // Almoço
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as ScheduleIcon } from "../../images/icons/Schedule1.svg";
import { ReactComponent as VisitIcon } from "../../images/icons/VisitB.svg";
import { ReactComponent as GroupIcon } from "../../images/icons/VisitGroup.svg";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArticleIcon from '@mui/icons-material/Article';
import MessageIcon from '@mui/icons-material/Message';
import BeenhereIcon from '@mui/icons-material/Beenhere';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {
  doc,
  collection,
  deleteDoc,
  updateDoc,
  onSnapshot
} from "firebase/firestore";

import "cooltipz-css";
import "./_style.scss";
import "../../components/Dashboard/Visit_and_Prospection/_styles.scss";

import { Company, Users } from "../../data/Data";
import { Box, Button } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../data/theme";
import useVisitConfirm from "../../hooks/useVisitConfirm";
import NewTooltip from "../../components/Tooltip";
import useGoPage from "../../hooks/useGoPage";
import { useMember } from '../../hooks/useMember';
import useSwalAlert from "../../hooks/useSwalAlert";
import Tooltip from "@mui/material/Tooltip";
import RegisterVisitStep25 from "./Components/Register_Visit/Step2_5";

const Schedule = ({ isLoaded, visits, userRef, sellers, check, members }) => {
  const { handleVisitConfirm } = useVisitConfirm();
  const goPage = useGoPage();
  const { getMemberColor } = useMember();
  const swalAlert = useSwalAlert();
  const date = new Date();
  const month = ['Todos', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  // const { year } = useParams();
  const { user } = useAuth();
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const boxVisitRef = useRef();
  // const visitRef = useRef();
  const [schedule, setSchedule] = useState();
  // const [scheduleNew, setScheduleNew] = useState();
  const [year, setYear] = useState('2024');
  const [monthSelect, setMonthSelect] = useState(
    String(date.getMonth() + 1).padStart(2, "0") // 1
  );
  // const [dayVisits, setDayVisits] = useState(undefined);
  const [open, setOpen] = useState(false);
  // const [info, setInfo] = useState();
  const [checkStep, setCheckStep] = useState({ step0: false, step1: false, step1_5: false, step2: false, step3: false, step4: false, step45: false });
  const [openVisit, setOpenVisit] = useState('step0');
  const [idStep1, setIdStep1] = useState(null);
  const [, setIdStep4] = useState(null);
  const [step2, setStep2] = useState(null);
  // const [install, setInstall] = useState('Nenhum');
  // const [cleaner, setCleaner] = useState('Nenhum');
  // const [installAll, setInstallAll] = useState();
  // const [cleanerAll, setCleanerAll] = useState();
  const [representative, setRepresentative] = useState({});
  const [search, setSearch] = useState('');
  const [option, setOption] = useState([]);
  const navigate = useNavigate();

  const categoriaMap = {
    lunch: {
      icon: <RestaurantIcon sx={{ fill: "#fff" }} />,
      className: 'lunch clickable',
      ariaLabel: 'Almoço'
    },
    comercial: {
      icon: <RequestQuoteIcon sx={{ fill: "#fff" }} />,
      className: 'comercial clickable',
      ariaLabel: 'Visita Comercial'
    },
    comercial_tecnica: {
      icon: <PeopleIcon sx={{ fill: "#fff" }} />,
      className: 'comercial_tec clickable',
      ariaLabel: 'Comercial + Técnica'
    },
    pos_venda: {
      icon: <EngineeringIcon sx={{ fill: "#fff" }} />,
      className: 'pos_venda clickable',
      ariaLabel: 'Pós-Venda'
    },
    tecnica: {
      icon: <EngineeringIcon sx={{ fill: "#fff" }} />,
      className: 'tec clickable',
      ariaLabel: 'Técnica'
    }
  };

  useEffect(() => {
    if (monthSelect) {
      if (userRef && userRef.cargo !== 'Representante') {
        setSchedule(visits && visits.filter(data => data.data.substring(0, 7) === year + '-' + monthSelect));
      } else {
        setSchedule(visits && visits.filter(data => data.data.substring(0, 7) === year + '-' + monthSelect && data.indicador?.id === userRef.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthSelect, year, visits]);

  console.log(visits?.filter(data => data.instalacao))

  useEffect(() => {
    const fetchData = async () => {
      await onSnapshot(doc(dataBase, 'Roleta', 'Analista'), (doc) => {
        setRepresentative({ ...doc.data(), id_doc: doc.id })
      })
    }

    fetchData();
  }, [])

  // useEffect(() => {
  //   if(members) {
  //     setInstallAll(members.filter((member) => member.cargo === 'Instalador'))
  //     setCleanerAll(members.filter((member) => member.cargo === 'Limpeza'))
  //   }
  // },[members])

  useEffect(() => {
    if (visits) {
      const uniqueOptions = visits.reduce((uniqueList, currentData) => {
        const isExisting = uniqueList.some(item => item.nome === currentData.cliente);

        if (!isExisting && currentData.cliente.trim() !== '') {
          uniqueList.push({
            id: uniqueList.length,
            nome: currentData.cliente,
            cidade: currentData.cidade
          });
        }

        return uniqueList;
      }, []);
      setOption(uniqueOptions.sort((a, b) => a.nome.localeCompare(b.nome)))

      // const visitsConfirm = visits && visits.filter((data) => data.confirmar)

      // visitsConfirm.map(async (data) => {
      //   await updateDoc(doc(dataBase, 'Visitas', data.id), {
      //     representante: {
      //       id: 'FLNgLtdFELPoWV67PnHIMoUeIKC2',
      //       nome: 'Ana Flávia'
      //     }
      //   })
      // })

      //  const updateVisits = async (visitsChange) => { // Para caso seja necessário atualizar alguma informação em massa dos clientes

      //    try {
      //      visitsChange.map(async (data) => (
      //        await updateDoc(doc(dataBase, 'Visitas', data.id), {
      //          jornada: {
      //               etapa: 'Instalação',
      //               index: 5,
      //               sigla: 'INS',
      //               cor: '#45e952',
      //               data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
      //             }
      //        })
      //     ))

      //    } catch (error) {
      //       console.log('ERRO: ', error)
      //     }
      //   }

      // updateVisits(visits?.filter(data => data.instalacao));
  }
    
  }, [visits])

  const handleChange = (event, newValue) => {
    if (newValue === "") {
      setSearch(newValue); // Se o novo valor for vazio, mantenha como vazio
    } else {
      // Verifica se o novo valor existe nas opções
      const optionExists = option.some(option => option.nome === newValue);

      if (optionExists) {
        setSearch(newValue);
        setSchedule(visits.filter(data => data.cliente === newValue));
      } else {
        // Trate o caso em que o valor não corresponde a nenhuma opção existente, se necessário
      }
    }
  };

  const getPhotoMember = (idMember) => {
    const member = members.find((member) => member.id === idMember);
    if (member) {
      return member.photo?.url || Logo;
    } else {
      return Logo;
    }
  }

  const visitType = (visit) => {
    const categoria = categoriaMap[visit.categoria];
    if (!categoria) return null;

    return (
      <Box
        onClick={() => viewVisita(visit, visit.categoria)}
        style={{ padding: '0.2rem', display: 'flex', alignItems: 'center', borderRadius: '10px', width: '30px' }}
        className={categoria.className}
        aria-label={categoria.ariaLabel}
        data-cooltipz-dir="top"
      >
        {categoria.icon}
      </Box>
    );
  };

  const analyseType = (type) => {

    const analyseMap = {
      aberto: {
        className: 'aberto',
        ariaLabel: 'Análise em Aberto'
      },
      emandamento: {
        className: 'andamento',
        ariaLabel: 'Análise em Andamento'
      },
      concluido: {
        className: 'concluido',
        ariaLabel: 'Análise Concluida'
      },
      fechado: {
        className: 'lunch',
        ariaLabel: 'Nenhuma Análise'
      },
    }

    const typeFormatted = type.replace(/\s+/g, '').toLowerCase();

    const categoria = analyseMap[typeFormatted];

    return (
      <Box
        style={{ padding: '0.2rem', display: 'flex', alignItems: 'center', borderRadius: '10px', width: '30px', margin: '0 auto' }}
        className={categoria.className}
        aria-label={categoria.ariaLabel}
        data-cooltipz-dir="top"
      >
        <TroubleshootIcon sx={{ fill: "#fff" }} />
      </Box>
    );
  };

  const permission = (visit) => { //Permissão
    console.log(visit)
    if (visit.uid === userRef.uid || userRef.cargo === 'Administrador' || userRef.cargo === 'Técnico') {
      return true;
    } else {
      return false;
    }
  }

  const NewTableRow = ({ children, onClick }) => {
    const [showTooltip, setShowTooltip] = useState(true);
  
    const handleTrClick = (event) => {
      if (event.target === event.currentTarget || !event.target.closest('.clickable')) {
        onClick();
        setShowTooltip(false);
      }
    };
  
    const handleMouseOver = (event) => {
      if (event.target.closest('.clickable')) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    };
  
    return (
      <Tooltip 
        title={showTooltip ? 'Clique para visualizar a Visita' : ''}
        placement='bottom' 
        followCursor={true}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10], // [skidding, distance]
              },
            },
          ],
        }}
      >
        <TableRow 
          hover 
          onClick={handleTrClick} 
          onMouseOver={handleMouseOver} 
          sx={{ cursor: 'pointer' }}
        >
          {children}
        </TableRow>
      </Tooltip>
    );
  };

  // const visitsFind = (type, visit) => {
  //   if (type === "antes")
  //     return schedule.filter(
  //       (ref) =>
  //         ref.data === visit.data && ref.chegadaEmpresa === visit.saidaEmpresa &&
  //         ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
  //     );
  //   if (type === "depois")
  //     return schedule.filter(
  //       (ref) =>
  //         ref.data === visit.data && ref.saidaEmpresa === visit.chegadaEmpresa &&
  //         ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
  //     );
  // };


  function getMonthlyWeekNumber(dt) {
    // como função interna, permite reuso
    var getmonweek = function (myDate) {
      var today = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate(), 0, 0, 0);
      var first_of_month = new Date(myDate.getFullYear(), myDate.getMonth(), 1, 0, 0, 0);
      var p = Math.floor((today.getTime() - first_of_month.getTime()) / 1000 / 60 / 60 / 24 / 7);
      // ajuste de contagem
      if (today.getDay() < first_of_month.getDay()) ++p;
      // ISO 8601.
      if (first_of_month.getDay() <= 3) p++;
      return p;
    }
    // último dia do mês
    var udm = (new Date(dt.getFullYear(), dt.getMonth() + 1, 0, 0, 0, 0)).getDate();
    /*  Nos seis primeiros dias de um mês: verifica se estamos antes do primeiro Domingo.
     *  Caso positivo, usa o último dia do mês anterior para o cálculo.
     */
    if ((dt.getDate() < 7) && ((dt.getDate() - dt.getDay()) < -2))
      return getmonweek(new Date(dt.getFullYear(), dt.getMonth(), 0));
    /*  Nos seis últimos dias de um mês: verifica se estamos dentro ou depois do último Domingo.
     *  Caso positivo, retorna 1 "de pronto".
     */
    else if ((dt.getDate() > (udm - 6)) && ((dt.getDate() - dt.getDay()) > (udm - 3)))
      return 1;
    else
      return getmonweek(dt);
  }

  const confirmVisit = async (ref, type) => {
    if (check) {
      Swal.fire({
        title: 'Sem Conexão',
        icon: "error",
        html: `Não é possível ${type === 'confirm' ? 'Confirmar' : 'Cancelar'} <b>sem internet.</b> Verifique a sua conexão.`,
        confirmButtonText: "Fechar",
        showCloseButton: true,
        confirmButtonColor: "#d33"
      })
    } else {
      const visitRef = doc(dataBase, "Visitas", ref.id);
      const financeCol = collection(dataBase, "Financeiro", year, monthSelect);
      const financeRef = doc(financeCol, ref.id);
      const date = new Date(ref.data);
      if (type === "confirm") {
        Swal.fire({
          title: Company,
          html: `Você deseja confirmar essa <b>Visita</b>?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await updateDoc(visitRef, {
              //Atualizar dados sem sobrescrever os existentes
              confirmar: true,
              representante: {
                nome: representative.escolhida,
                id: representative.id
              }
            });

            // if(ref.tipo !== "Almoço") {
            //   await setDoc(financeRef, {
            //     data: ref.data,
            //     dia: ref.dia,
            //     cidade: ref.cidade,
            //     cliente: ref.cliente,
            //     horario: ref.chegadaCliente,
            //     saida: ref.saidaEmpresa,
            //     chegada: ref.chegadaEmpresa,
            //     consultora: ref.consultora,
            //     cor: ref.cor,
            //     tecnico: ref.tecnico,
            //     tecnicoUID: ref.tecnicoUID,
            //     veiculo: ref.veiculo,
            //     categoria: ref.categoria,
            //     createConfirm: new Date()
            //   });
            // }
            axios.post('https://n8n.corpbrasil.cloud/webhook/63b48297-3e22-4eba-8b21-45e87f52f3fb', {
              ID: ref.id,
              data: moment(ref.data).format("DD/MM/YYYY"),
              nome: ref.tecnico,
              cliente: ref.cliente,
              marcado: ref.chegadaCliente,
              consultora: ref.consultora,
              city: ref.cidade,
              semana: getMonthlyWeekNumber(date),
              mes: moment(ref.data).format("M"),
              ende: ref.endereco,
              categoria: ref.categoria,
              confirmada: 'Sim',
            })
            Swal.fire({
              title: Company,
              html: `A Visita em <b>${ref.cidade}</b> foi confirmada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#F39200",
            });
          }
        });
      } else {
        Swal.fire({
          title: Company,
          html: `Você deseja cancelar essa <b>visita</b>?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            axios.post('https://n8n.corpbrasil.cloud/webhook/dfbbb99b-1721-4a7d-8ac0-f95335b15aa7', {
              ID: ref.id,
              data: moment(ref.data).format("DD/MM/YYYY"),
              nome: ref.tecnico,
              cliente: ref.cliente,
              marcado: ref.chegadaCliente,
              consultora: ref.consultora,
              city: ref.cidade,
              semana: getMonthlyWeekNumber(date),
              mes: moment(ref.data).format("M"),
              ende: ref.endereco,
              confirmada: 'Não',
              categoria: ref.categoria
            })
            Swal.fire({
              title: Company,
              html: `A Visita em <b>${ref.cidade}</b> foi cancelada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#F39200",
            });
            await updateDoc(visitRef, {
              //Atualizar dados sem sobrescrever os existentes
              confirmar: false,
            });
            await deleteDoc(financeRef);
          }
        });
      }
    }
  };

  const handleCheckStep = (data) => { // Retorna as Etapas concluidas da visita técnica
    setCheckStep(data);
  }

  const changeFilter = (data) => {
    if (data.length === visits.length) {
      setSchedule(visits && visits.filter(data => data.data.substring(0, 7) === year + '-' + monthSelect));
    } else {
      setSchedule(data);
    }
  }

  // ======== Visualizar informação da visita em uma box =================

  const visitTitles = {
    comercial: 'Visita Comercial',
    comercial_tecnica: 'Visita Comercial + Técnica',
    pos_venda: 'Visita Pós-Venda',
    tecnica: 'Visita Técnica',
    lunch: 'Almoço'
  };

  const viewVisita = (visit, type) => {
    let visitType = { title: visitTitles[type] || 'Visita', driver: `Técnico: <b>${visit.tecnico}</b></br>` };
    let visitInfo = getVisitInfo(visit, type);

    Swal.fire({
      title: visitType.title,
      html: visitInfo,
      showConfirmButton: permission(visit),
      showDenyButton: permission(visit),
      showCancelButton: permission(visit),
      showCloseButton: true,
      confirmButtonColor: determineButtonColor(visit, 'confirm'),
      denyButtonColor: determineButtonColor(visit, 'deny'),
      cancelButtonColor: determineButtonColor(visit, 'cancel'),
      confirmButtonText: 'GPS',
      denyButtonText: 'Visualizar',
      cancelButtonText: 'Editar',
    }).then((result) => {
      handleResult(result, visit, type);
      console.log(permission(visit))
    });
  };

  const getVisitInfo = (visit, type) => {
    const observacao = visit.observacao ? `</br>Observação: <b>${visit.observacao}</b></br>` : '';
    const basicInfo = `Data <b>${moment(visit.data).format("DD/MM/YYYY")}</b> </br></br>` +
      `Cliente: <b>${visit.cliente}</b></br>` +
      `Cidade: <b>${visit.cidade}</b></br></br>` +
      `Horário de Saída: <b>${visit.saidaEmpresa}</b></br>` +
      `Chegada no Cliente: <b>${visit.chegadaCliente}</b></br>` +
      `Tempo de Visita: <b>${visit.visita}</b></br>` +
      `Saída no Cliente: <b>${visit.saidaDoCliente}</b></br>` +
      `Chegada na Empresa: <b>${visit.chegadaEmpresa}</b></br></br>` +
      `Consultora: <b style={{ color: ${visit.cor} }}>${visit.consultora}</b></br>` +
      `Veículo: <b>${visit.veiculo}</b></br></br>` +
      `Endereço: <b>${visit.endereco}</b></br>` + observacao;

    if (type === 'lunch') {
      return `Data <b>${moment(visit.data).format("DD/MM/YYYY")}</b> </br></br>` +
        `Horário de Início: <b>${visit.saidaEmpresa}</b></br>` +
        `Duração: <b>${visit.visita}</b></br>` +
        `Horário de Saída: <b>${visit.chegadaEmpresa}</b></br></br>` +
        `Responsável: <b style={{ color: ${visit.cor} }}>${visit.consultora}</b></br>` + observacao;
    } else {
      return basicInfo;
    }
  };

  const determineButtonColor = (visit, buttonType) => {
    const isActive = (userRef && visit.consultora === userRef.nome && !visit.confirmar) || userRef.cargo === 'Administrador' || userRef.cargo === 'Técnico';
    if (buttonType === 'confirm') {
      return isActive ? '#0eb05f' : '#ababab';
    } else if (buttonType === 'deny') {
      return isActive ? '#1976d2' : '#ababab';
    } else {
      return isActive ? '#111' : '#ababab';
    }
  };

  const handleResult = (result, visit, type) => {
    if (result.isConfirmed) {
      if (check) {
        Swal.fire({
          title: 'Sem Conexão',
          icon: "error",
          html: `Não é possível Editar um Almoço <b>sem internet.</b> Verifique a sua conexão.`,
          confirmButtonText: "Fechar",
          showCloseButton: true,
          confirmButtonColor: "#d33"
        });
      } else {
        if (visit.confirmar) {
          Swal.fire({
            title: 'Aviso',
            icon: 'warning',
            html: 'Não é possível editar uma <b>Visita Confirmada.</b> Para poder editar, cancele a <b>Visita.</b>',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            confirmButtonText: 'Ok',
          }).then((newResult) => {
            if (newResult.isConfirmed) return viewVisita(visit, type);
          });
        } else if (permission(visit)) {
          setTimeout(() => {
            window.open(`https://waze.com/ul?ll=${visit.lat},${visit.lng}`, "_blank");
          }, 200);
        } else {
          Swal.fire({
            title: 'Acesso Negado',
            html: `Somente o responsável pode Editar a <b>Visita.</b>`,
            icon: "error",
            showCloseButton: true,
            confirmButtonColor: "#F39200",
            confirmButtonText: "Ok",
          });
        }
      }
    } else if (result.isDenied) {
      goPage(`${visit.id}`, 'top');
    } else if (result.dismiss === 'cancel') {
      goPage(`editar/${visit.id}`, 'top');
    }
  };

  const handleToggle = (visit) => {
    if (visit.categoria === 'comercial_tecnica' || visit.categoria === 'tecnica') {
      setOpen((prevState) => ({ [visit.id]: !prevState[visit.id] })); //Abre uma segunda área da visita
    } else {
      setOpen(false);
    };
  };

  console.log(checkStep)

  const goCreateVisit = (value) => {
    navigate(value);
    window.scrollTo(0, 0);
  }

  const linkCliente = (visit) => {
    const { cliente, cliente_id } = visit;
    const truncatedName = cliente.length > 22 ? `${cliente.substring(0, 22)}...` : cliente;

    if (!cliente_id) {
      return truncatedName;
    }

    if (!permission(visit)) {
      return truncatedName;
    }

    return (
      <Link className="link_black clickable" to={`/clientes/${cliente_id}`} onClick={() => goPage(null, 'top')}>
        {truncatedName}
      </Link>
    );
  };

  return (
    <div className="container-schedule">
      <div className="title-panel">
        <ScheduleIcon />
        <h2>Agenda {year} </h2>
        <h2>Visitas de {month[parseFloat(monthSelect)]}</h2>
        <div className="schedule-month">
          <select
            value={monthSelect}
            className="schedule-month__select"
            name="month"
            onChange={(e) => setMonthSelect(e.target.value)}
          >
            <option value="01">Janeiro</option>
            <option value="02">Fevereiro</option>
            <option value="03">Março</option>
            <option value="04">Abril</option>
            <option value="05">Maio</option>
            <option value="06">Junho</option>
            <option value="07">Julho</option>
            <option value="08">Agosto</option>
            <option value="09">Setembro</option>
            <option value="10">Outubro</option>
            <option value="11">Novembro</option>
            <option value="12">Dezembro</option>
          </select>
          <select
            value={year}
            className="schedule-month__select"
            name="year"
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
      </div>
      <div className="content-schedule-visit">
        {userRef && userRef.cargo !== 'Representante' ?
          <Dashboard schedule={schedule} monthSelect={monthSelect} type='visit' /> : null
        }
        <div className="box-schedule-visit" ref={boxVisitRef}>
          {(userRef && userRef.cargo === "Vendedor(a)") ||
            (userRef && userRef.cargo === "Administrador") ||
            (user.email === Users[0].email) ? (
            <><h2>Criar Visita</h2>
              <div className="box-schedule-visit__content">
                {userRef && (userRef.cargo === 'Vendedor(a)' || userRef.cargo === 'Administrador') && userRef.nome !== 'Pós-Venda' &&
                  <><div className="box-schedule-visit__add">
                    <button onClick={() => goCreateVisit('/visitas/criar/comercial')}>
                      <span className="visit-icon comercial-fill"><RequestQuoteIcon /></span>
                      <div className="visit-text"><p>Visita Comercial</p></div>
                    </button>
                  </div>
                    <div className="box-schedule-visit__add">
                      <button
                        onClick={() => goCreateVisit('/visitas/criar/comercial_tecnica')}>
                        <span className="visit-icon comercial_tec-fill"><PeopleIcon /></span>
                        <div className="visit-text"><p>Comercial + Técnica</p></div>
                      </button>
                    </div>
                    <div className="box-schedule-visit__add">
                      <button
                        onClick={() => goCreateVisit('/visitas/criar/tecnica')}>
                        <span className="visit-icon tec-fill"><EngineeringIcon /></span>
                        <div className="visit-text"><p>Técnica</p></div>
                      </button>
                    </div></>
                }
                {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') &&
                  <div className="box-schedule-visit__add">
                    <button onClick={() => goCreateVisit('/visitas/criar/pos_venda')}>
                      <span className="visit-icon pos_venda-fill"><EngineeringIcon /></span>
                      <div className="visit-text"><p>Pós-Venda</p></div>
                    </button>
                  </div>
                }
              </div></>
          ) : (
            <></>
          )}
          <div className="toggle-box desktop">
            <Filter tableData={schedule} dataFull={visits} sellers={sellers} changeFilter={changeFilter} type={'visit'} />
            <div className="search-container">
              <ThemeProvider theme={theme}>
                <Autocomplete
                  value={search}
                  disableClearable
                  sx={{ width: '15rem' }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={option && option.map((option) => option.nome)}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue === '') {
                      setSchedule(visits && visits.filter(data => data.data.substring(0, 7) === year + '-' + monthSelect));
                      setSearch('');
                    }
                  }}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.id}
                      label="Pesquisar Cliente"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </ThemeProvider>
            </div>
          </div>
          <div className="container-table desktop">
            <TableContainer className="table-visit" component={Paper} >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="table-visits_header">
                    <TableCell align="center">Etapa</TableCell>
                    <TableCell align="center" padding="none">Visita</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Dia</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center">Cliente</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Saida</TableCell>
                    <TableCell align="center">Horário da Visita</TableCell>
                    <TableCell align="center">Chegada</TableCell>
                    <TableCell align="center">Responsável</TableCell>
                    <TableCell align="center">Motorista</TableCell>
                    <TableCell align="center">Analista</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">Observação</TableCell>
                    <TableCell></TableCell>
                    {/* <TableCell align="center">Ação</TableCell> */}
                  </TableRow>
                </TableHead>
                  <TableBody>
                {schedule && schedule?.length > 0 ? schedule.map((visita, index) => 
                    <NewTableRow
                      hover
                      key={index}
                      className={`list-visit`}
                      onClick={() => goCreateVisit(`${visita.id}`)}
                    >
                      {visita.categoria === 'comercial_tecnica' || visita.categoria === 'tecnica' ?
                        <TableCell align="center">
                          <NewTooltip title={visita.jornada?.etapa} placement="top">
                            <Box className='info-visit-small' sx={{ backgroundColor: visita.jornada?.cor ?? 'transparent' }}>
                              <b>{visita.jornada && visita.jornada.sigla}</b>
                            </Box>
                          </NewTooltip>
                        </TableCell> :
                        <TableCell></TableCell>
                      }
                      {!visita.confirmar && visita.categoria === 'lunch' &&
                        <TableCell></TableCell>}
                      {!visita.confirmar && visita.tipo === 'Visita Conjunta' &&
                        <TableCell align="center">
                          <Box className='type-icon'
                            aria-label="Visita Conjunta"
                            data-cooltipz-dir="top">
                            <GroupIcon />
                          </Box>
                        </TableCell>}
                      {!visita.confirmar && visita.tipo === 'Visita' &&
                        <TableCell align="center">
                          <Box className='type-icon'
                            aria-label="Visita Única"
                            data-cooltipz-dir="top">
                            <VisitIcon style={{ height: '20px' }} />
                          </Box>
                        </TableCell>}
                      {visita.confirmar &&
                        <TableCell align="center">
                          <Box className='type-icon'
                            aria-label="Visita Confirmada"
                            data-cooltipz-dir="top">
                            <BeenhereIcon sx={{ fill: '#0eb05f' }} />
                          </Box>
                        </TableCell>}
                      <TableCell align="center">
                        {visitType(visita)}
                      </TableCell>
                      <TableCell sx={{ width: 30, fontWeight: 'bold' }} align="center" scope="row" aria-label={moment(visita.dia).format('DD/MM/YYYY')} data-cooltipz-dir="top">
                        {visita.dia.substring(8, 10)}
                      </TableCell>
                      <TableCell align="center">{visita.cidade && visita.cidade}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', gap: '0.3rem', alignItems: 'center', justifyContent: 'center' }}>
                          <NewTooltip title={`${visita.cliente} | Tel: ${visita.preData?.telefone ?? 'Não Informado'}${visita.cliente_id && permission(visita) ? ' | Clique aqui para visualizar o Cliente' : ''} `} placement='top'>
                            {linkCliente(visita)}
                          </NewTooltip>
                          {visita.categoria !== 'pos_venda' ?
                            <Box className='clickable'>{handleVisitConfirm(visita.confirmarVisita)}</Box> : null
                          }
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box className='clickable'>
                          {analyseType(visita?.analise_status || 'Fechado')}
                        </Box>
                      </TableCell>
                      {visita.tipo === "Almoço" ? (
                        <TableCell></TableCell>
                      ) : (
                        <TableCell align="center" className="bold">
                          <Box sx={{ backgroundColor: "#d9ead3", padding: '0.3rem', borderRadius: '10px' }}>
                            {visita.saidaEmpresa}
                          </Box>
                        </TableCell>
                      )}
                      <TableCell align="center" sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', justifyContent: 'center', height: '40px' }}>
                        <Box className='bold' sx={{ backgroundColor: "#ededed", padding: '0.3rem 0.5rem', borderRadius: '10px' }}>
                          {visita.chegadaCliente}
                        </Box> até
                        <Box className='bold' sx={{ backgroundColor: "#f4cccc", padding: '0.3rem 0.5rem', borderRadius: '10px' }}>
                          {visita.saidaDoCliente}
                        </Box>
                      </TableCell>
                      {visita.tipo === "Almoço" ? (
                        <TableCell></TableCell>
                      ) : (
                        <TableCell align="center">
                          <Box className="bold" sx={{ backgroundColor: "#d9ead3", padding: '0.3rem 0', borderRadius: '10px' }}>
                            {visita.chegadaEmpresa}
                          </Box>
                        </TableCell>
                      )}
                      <TableCell
                        sx={{ width: '160px'}}
                        align="center" >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(visita.uid), borderRadius: '10px' }}>
                              <img src={getPhotoMember(visita.uid)} alt={visita.consultora} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                              <p style={{ color: "#fff" }}>{visita.consultora}</p>

                            {visita.responsavel ? visita.responsavel?.id !== visita.uid ?
                              <NewTooltip title={`Essa Visita foi criada pela ${visita.responsavel?.nome}`} placement='top'>
                                  <InfoIcon className="clickable" sx={{ cursor: 'pointer', fill: "#fff" }} />
                              </NewTooltip> : null : null
                            }
                          </Box>
                      </TableCell>
                      <TableCell
                        sx={{ width: '140px'}}
                        align="center" >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(visita.tecnicoUID), borderRadius: '10px' }}>
                              <img src={getPhotoMember(visita.tecnicoUID)} alt={visita.tecnico} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                              <p style={{ color: "#fff" }}>{visita.tecnico}</p>
                          </Box>
                      </TableCell>
                      <TableCell
                        sx={{ width: '140px'}}
                        align="center" >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(visita.representante?.id), borderRadius: '10px' }}>
                              <img src={getPhotoMember(visita.representante?.id)} alt={visita.representante?.nome} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                              <p style={{ color: visita.representante?.nome ? "#fff" : '#000' }}>{visita.representante?.nome || 'Nenhuma'}</p>
                          </Box>
                      </TableCell>
                      <TableCell align="center">
                          <Box 
                            aria-label={visita.analise ? `Visita analisada pela ${visita.representante?.nome} ✅` : 'Visita não analisada'}
                            data-cooltipz-dir="top" 
                            sx={{ backgroundColor: visita.analise ? '#03ba3a' : '#111', borderRadius: '10px', display: 'flex', padding: '0.2rem', justifyContent: 'center', width: '35px' }}>
                            <PlaylistAddCheckIcon sx={{ fill: "#fff" }} />
                          </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ width: '20px' }}>
                        {visita.observacao ?
                          <IconButton data-cooltipz-dir="top" className="clickable" aria-label="Exibir Observação" onClick={() => swalAlert('Observação', visita.observacao, 'info')}>
                            <MessageIcon />
                          </IconButton> :
                          null
                        }
                      </TableCell>
                    </NewTableRow>
                    )
                    :
                    <TableRow>
                        <TableCell colSpan={15}>
                          <p className="margin1" style={{ textAlign: 'center', margin: '1rem', fontSize: '1.2rem' }}>Nenhuma Visita Encontrada</p>
                        </TableCell>
                    </TableRow>
                    }
                  </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* MOBILE */}
          <div className="box-mobile">
            <h1 className="mobile-title">Visitas do Mês</h1>
            {schedule && schedule.length > 0 ?
              <TableContainer className="table-visits" component={Paper}
                sx={{ maxHeight: 600 }}>
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className="table-visits_header">
                      <TableCell align="center"></TableCell>
                      {userRef && userRef.cargo === 'Representante' ?
                        <TableCell align="center" padding="none">Etapa</TableCell> :
                        <TableCell align="center" padding="none">Visita</TableCell>
                      }
                      <TableCell align="center">Dia</TableCell>
                      <TableCell align="center">Saida</TableCell>
                      <TableCell align="center">Chegada</TableCell>
                      <TableCell align="center">Cidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schedule.map((visita, index) => (
                      <><TableRow
                        hover
                        key={index}
                        onClick={() => handleToggle(visita)}
                        className={`list-visit ${visita.tipo === 'Visita Conjunta' && !visita.confirmar ? 'conjunta' : ''} ${visita.confirmar ? 'confirmar' : ''}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell
                          aria-label={visita.consultora}
                          data-cooltipz-dir="right"
                          sx={{ backgroundColor: `${visita.cor}`, color: '#fff', fontWeight: 'bold' }}
                          align="center" scope="row">
                          {visita.consultora.substring(0, 1)}
                        </TableCell>
                        {userRef && userRef.cargo === 'Representante' ?
                          <TableCell sx={{ backgroundColor: visita.jornada?.cor ?? 'transparent' }} align="center" aria-label={visita.jornada?.etapa} data-cooltipz-dir="right"><b>{visita.jornada && visita.jornada.sigla}</b></TableCell> : <></>
                        }
                        {visita.categoria === "lunch" && userRef.cargo !== 'Representante' && <TableCell onClick={() => viewVisita(visita, visita.categoria)} style={{ filter: 'contrast', padding: '0.2rem' }} className="type-icon lunch" aria-label="Almoço" data-cooltipz-dir="right"><RestaurantIcon /></TableCell>}
                        {visita.categoria === "comercial" && userRef.cargo !== 'Representante' && <TableCell onClick={() => viewVisita(visita, visita.categoria)} style={{ padding: '0.2rem' }} className="type-icon comercial" aria-label="Visita Comercial" data-cooltipz-dir="right"><RequestQuoteIcon /></TableCell>}
                        {visita.categoria === "comercial_tecnica" && userRef.cargo !== 'Representante' && <TableCell onClick={() => viewVisita(visita, visita.categoria)} style={{ padding: '0.2rem' }} className="type-icon comercial_tec" aria-label="Comercial + Técnica" data-cooltipz-dir="right"><PeopleIcon /></TableCell>}
                        {visita.categoria === "pos_venda" && userRef.cargo !== 'Representante' && <TableCell onClick={() => viewVisita(visita, visita.categoria)} style={{ padding: '0.2rem' }} className="type-icon pos_venda" aria-label="Pós-Venda" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                        {visita.categoria === "tecnica" && userRef.cargo !== 'Representante' && <TableCell onClick={() => viewVisita(visita, visita.categoria)} style={{ padding: '0.2rem' }} className="type-icon tec" aria-label="Técnica" data-cooltipz-dir="right"><EngineeringIcon /></TableCell>}
                        {visita.confirmar === false &&
                          (user.email === Users[0].email || (userRef && userRef.cargo === "Administrador") || (userRef && userRef.nome === 'Pós-Venda')) ? (
                          <TableCell className="btn-confirm"
                            aria-label="Confirmar Visita"
                            data-cooltipz-dir="right"
                            onClick={() => confirmVisit(visita, "confirm")}
                          ></TableCell>
                        ) :
                          (
                            <></>
                          )}
                        {visita.confirmar === true &&
                          (user.email === Users[0].email || (userRef && userRef.cargo === "Administrador") || (userRef && userRef.nome === 'Pós-Venda')) ? (
                          <TableCell className="btn-cancel"
                            aria-label="Cancelar Visita"
                            data-cooltipz-dir="right"
                            onClick={() => confirmVisit(visita, "cancel")}
                          ></TableCell>
                        ) : (
                          <></>
                        )}
                        {/* {(visita.confirmar || visita.categoria === 'pos_venda' || visita.categoria === 'lunch') && userRef && userRef.cargo === 'Vendedor(a)' &&
                      <TableCell className="btn-add disabled"
                      ></TableCell>} */}
                        <TableCell sx={{ width: 30 }} align="center" scope="row">
                          {visita.dia.substring(8, 10)}
                        </TableCell>
                        <TableCell align="center">{visita.saidaEmpresa}</TableCell>
                        <TableCell align="center">{visita.chegadaEmpresa}</TableCell>
                        {visita.categoria !== "lunch" &&
                          <TableCell align="center">{visita.cidade && visita.cidade.substring(0, 7)}...</TableCell>}
                        {visita.categoria === 'lunch' &&
                          <TableCell />}
                      </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, padding: 0, height: 0, backgroundColor: "#f9f9f9" }} colSpan={7}>
                            <Collapse in={open[visita.id]} timeout='auto' unmountOnExit colSpan={7}>
                              <Box sx={{ display: 'flex', margin: '1rem 0', justifyContent: 'center', flexDirection: 'column' }}>
                                <VisitTec data={visita} handleCheckStep={handleCheckStep} userRef={userRef} setIdStep1={setIdStep1} setIdStep4={setIdStep4} setStep2={setStep2} />
                                <Gps isLoaded={isLoaded} visit={visita} open={openVisit === 'step00'} close={() => setOpenVisit('step0')} />
                                <RegisterVisitStep1 visit={visita} open={openVisit === 'step1'} idStep1={idStep1} close={() => setOpenVisit('step0')} />
                                <RegisterVisitStep15 visit={visita} open={openVisit === 'step1_5'} idStep1={idStep1} close={() => setOpenVisit('step0')} />
                                <RegisterVisitStep2 visit={visita} open={openVisit === 'step2'} close={() => setOpenVisit('step0')} />
                                <RegisterVisitStep3 visit={visita} open={openVisit === 'step3'} close={() => setOpenVisit('step0')} sellers={sellers} />
                                <RegisterVisitStep25 visit={visita} open={openVisit === 'step2_5'} step2={step2} close={() => setOpenVisit('step0')} sellers={sellers} />
                                {/* <VisitTec data={visita} handleCheckStep={handleCheckStep} userRef={userRef} setIdStep1={setIdStep1} setIdStep4={setIdStep4} /> */}
                                <div className="visit_actions_tec">
                                  <h3>Jornada do Cliente</h3>
                                  <div className="visit-journey">
                                    <p>{visita.jornada?.etapa}</p>
                                    <span>Atualização: {visita.jornada?.data}</span>
                                  </div>
                                </div>
                                <div className="visit_actions_tec">
                                  <h3>Visita Técnica</h3>
                                  <div className="visit_buttons">
                                    <ThemeProvider theme={theme}>
                                      {checkStep.step0 ?
                                        <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>GPS</Button> :
                                        <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico') ? true : false} variant="contained" color="secondary" onClick={() => setOpenVisit('step00')}>GPS</Button>
                                      }
                                      {checkStep.step1 ?
                                        <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Dados</Button> :
                                        <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico')) || checkStep.step0 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step1')}>Dados</Button>
                                      }
                                      {checkStep.step1_5 ?
                                        <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Fotos (Solo)</Button> :
                                        <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visita.representante?.id)) || checkStep.step1 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step1_5')}>Fotos (Solo)</Button>
                                      }
                                      {checkStep.step2 ?
                                        <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Fotos (Telhado)</Button> :
                                        <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visita.representante?.id)) || checkStep.step1_5 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step2')}>Fotos (Telhado)</Button>
                                      }
                                      {checkStep.step2_5 ? 
                                        <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Vídeo (Telhado)</Button> : 
                                        <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visita.representante?.id)) || checkStep.step2 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step2_5')}>Vídeo (Telhado)</Button>
                                        }
                                      {checkStep.step3 ?
                                        <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Fotos (Medidas)</Button> :
                                        <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visita.representante?.id)) || checkStep.step2 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step3')}>Fotos (Med. Telhado)</Button>
                                      }
                                    </ThemeProvider>
                                  </div>
                                  <Link to={"../visita/" + visita.id} target='_blank'>
                                    <Button
                                      disableElevation
                                      sx={{ margin: '0.7rem 0' }}
                                      startIcon={<ArticleIcon />}
                                      // disabled={checkStep.step1 && checkStep.step2 && checkStep.step3 ? false : true}
                                      variant="contained">Ver Visita Completa</Button>
                                  </Link>
                                </div>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow></>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <div style={{ display: 'none!important', margin: 'auto' }} className="visit-aviso">
                <h1>Nenhuma Visita Encontrada</h1>
              </div>
            }
          </div>
        </div>
      </div>
      <Snackbar open={check} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Você está sem conexão. Verifique a sua conexão com a internet.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default memo(Schedule);

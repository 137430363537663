import { Box, Button, IconButton, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';

import MicIcon from '@mui/icons-material/Mic';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

import styles from './styles.module.scss';
import { theme } from '../../data/theme';
import NewTooltip from '../Tooltip';

const AudioRecorder = ({ setAudio, audio, disabled = false, audioRef }) => {
  const recorderControls = useAudioRecorder();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const stopRecording = () => {
    recorderControls.stopRecording();
  }

  useEffect(() => {
    if(recorderControls.recordingBlob) {
      setAudio({ file: URL.createObjectURL(recorderControls.recordingBlob), complete: recorderControls.recordingBlob });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[recorderControls.stopRecording])

  const deleteRecording = () => {
    setAudio(null);
  }

  return (
    <div className={styles.recording_container}>
      {recorderControls.isRecording ?
        <div>
        <ThemeProvider theme={theme}>
            <Button
              sx={{ textTransform: 'capitalize' }}
              onClick={recorderControls.togglePauseResume}
              color='primary'
              startIcon={recorderControls.isPaused ? <PlayArrowIcon /> : <PauseIcon />}
            >
              {recorderControls.isPaused ? 'Retornar' : 'Pausar'}
            </Button>
            <div className={styles.recording_indicator}>
              <div className={recorderControls.isPaused ? styles.pulse_stop : styles.pulse}></div>
              <div>{formatTime(recorderControls.recordingTime)}</div>
              <div className={styles.audio_wave}>
                {[...Array(18)].map((_, i) => (
                  <div key={i} className={styles.bar} style={recorderControls.isPaused ? {animationPlayState: 'paused'} : null}></div>
                ))}
              </div>
            </div>
            </ThemeProvider>
            <Button
              sx={{ textTransform: 'capitalize' }}
              onClick={stopRecording}
              color='primary'
              startIcon={<SquareRoundedIcon />}
            >
              Encerrar
            </Button>
        </div> :
      <div className={styles.recording_finished}>
        <NewTooltip title='Gravar Mensagem de Voz' placement='top'>
          <IconButton disabled={disabled} onClick={recorderControls.startRecording} color='primary'>
            <MicIcon />
          </IconButton>
          </NewTooltip>
          {audio && !recorderControls.isRecording && 
            <Box sx={{
              display: 'flex',
              gap: '0.7rem',
              alignItems: 'center'
            }}>
              <div className={styles.audio_player_container}>
                <audio controls src={audio.file} style={ disabled ? { pointerEvents: 'none', opacity: '0.5' } : null }></audio>
              </div>
              <NewTooltip title='Excluir Mensagem de Voz' placement='top'>
                <IconButton disabled={disabled} color='error' onClick={deleteRecording}>
                  <DeleteRoundedIcon />
                </IconButton>
              </NewTooltip>
            </Box>
          }
      </div>
      }
    </div>
  );
};

export default AudioRecorder;

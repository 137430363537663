/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { doc, onSnapshot, collection, getDoc, updateDoc } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import moment from "moment";
import { theme } from "../../../data/theme";
import styles from "./styles.module.scss";

import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from "@emotion/react";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ReplyIcon from '@mui/icons-material/Reply';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// import InstallStep1 from "./Components/Install/Step1";
import CleanerStep1 from "../Components/Service/Step1";
import CleanerStep2 from "../Components/Service/Step2";
// import InstallStep4 from "./Components/Install/Step4";

import Logo from "../../../images/LogoCORPBRASIL.png";
import { theme2 } from "../../../data/theme";
import File from "../../../components/VerifyURL/Index";
import Swal from "sweetalert2";
import axios from "axios";

const Cleaner = ({ userRef }) => {
  const [relatorio, setRelatorio] = useState('Relatório Contrato');
  const [steps, setSteps] = useState([]);
  // const [supplier, setSupplier] = useState([]);
  const [checkStep, setCheckStep] = useState({
    step1: false,
    step2: false
  });
  const [info, setInfo] = useState();
  const refObservation  = useRef({telhado: '', kit: '', instalacao: '', servico: ''});
  const [observation, setObservation] = useState({telhado: '', kit: '', instalacao: '', servico: ''});
  const [bitola, setBitola] = useState({ramal: '', cliente: '', cabo: ''});
  const [kitInfo, setKitInfo] = useState({fornecedor: '', valorKit: '', custoInstalacao: '', valorVenda: '', potenciaInversor: '', quantidadePlacas: '',
  potenciaPlacas: '', tipoEstrutura: ''});
  const [redeInfo, setRedeInfo] = useState({dps: '', energiaCA: '', disjuntor: ''});
  const [openObservation, setOpenObservation] = useState(false);
  const [openAnotation, setOpenAnotation] = useState(false);
  const [photosStep2, setPhotosStep2] = useState();
  // const [photosStep5, setPhotosStep5] = useState(); // Conferência do Kit Solar
  const [step1, setStep1] = useState({}); // Antes da Limpeza
  const [step2, setStep2] = useState({}); // Depois da Limpeza
  // const [photosStep8, setPhotosStep8] = useState(); // Instalação Finalizada
  // const [openPhoto, setopenPhoto] = useState(
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false]
  // );
  // const [openPhotoInstall, setopenPhotoInstall] = useState(
  //   [false],
  //   [false],
  //   [false], 
  //   [false],
  //   [false],
  //   [false],
  //   [false],
  //   [false]
  // );
  // const [quadro, setQuadro] = useState();
  // const [amperimetro, setAmperimetro] = useState();
  // const [bengala, setBengala] = useState();
  // const [disjuntor, setDisjuntor] = useState();
  const [photosStep1, setPhotosStep1] = useState();
  // const [padrao, setPadrao] = useState();
  // const [poste, setPoste] = useState();
  // const [relogio, setRelogio] = useState();
  // const [kit, setKit] = useState();
  const [anotation, setAnotation] = useState();
  const [address, setAddress] = useState();
  const [openEdit, setOpenEdit] = useState({ address: false, bitola_ramal: false, bitola_cliente: false, fornecedor: false, valorKit: false, custoInstalacao: false, valorVenda: false,
  potenciaInversor: false, quantidadePlacas: false, potenciaPlacas: false, tipoEstrutura: false, dps: false, energiaCA: false, disjuntor: false });
  const [OpenInstallStep, setOpenInstallStep] = useState('0');
  const [cleaner, setCleaner] = useState([]);
  // const [docs, setDocs] = useState([]);
  let { idCleaner } = useParams();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const stepCollectionRef = collection(
    dataBase,
    "Limpezas",
    idCleaner,
    "Etapas"
  );


  useEffect(() => {
    const getVisit = async () => {
      onSnapshot(doc(dataBase, "Limpezas", idCleaner), (doc) => {
        setCleaner({...doc.data(), id: doc.id});
      });
      onSnapshot(await stepCollectionRef, (ref) => {
        // Atualiza os dados em tempo real
        setSteps(ref.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
      });
      // onSnapshot(await supplierCollectionRef, (sup) => {
      //   // Atualiza os dados em tempo real
      //   setSupplier(sup.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
      // });
    };
    getVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(cleaner)
  console.log(idCleaner)

  useEffect(() => {
    if(cleaner) {
      document.title = cleaner.nome ? cleaner.nome + ' - Limpeza - CORPBRASIL Energia Solar' : 'Limpeza - CORPBRASIL Energia Solar';
    }
  },[cleaner])

  useEffect(() => {
    if(steps) {
      setStep1(steps.find(data => data.etapa === 1))
      setStep2(steps.find(data => data.etapa === 2))
    }
  },[steps])

  const confirmCleaner = () => {
    try {
      Swal.fire({
        title: 'Limpeza',
        html: `Você deseja confirmar a <b>Limpeza?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if(result.isConfirmed) {
          const getInstall = (await getDoc(doc(dataBase, "Membros", cleaner.terceirizado?.id))).data();
          console.log(getInstall)
          const obrasRef = getInstall.obras;

          const obrasComFinalizado = obrasRef.map(obra => {
            if (obra.id === cleaner.id) {
              return { ...obra, finalizado: true };
            } else {
              return obra;
            }
          });

          await updateDoc(doc(dataBase, 'Limpezas', cleaner.id), {
            limpeza: true,
            confirm_user: userRef && userRef.id,
            jornada: {
              etapa: 'Pago',
              index: 3,
              sigla: 'P',
              cor: '#6ece99',
              data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
            },
          }).then(async () => {

            await updateDoc(doc(dataBase, "Membros", cleaner.terceirizado?.id), {
              obras: obrasComFinalizado
            })
            axios.post('https://n8n.corpbrasil.cloud/webhook/f29dd264-030e-4d64-aef3-42dd3a5a44d4', {
              cliente: cleaner.nome,
              cidade: cleaner.cidade,
              endereco: cleaner.endereco,
              consultora: cleaner.analista?.nome,
              instalador: cleaner.terceirizado?.nome,
              empresa: cleaner.terceirizado?.razao_social,
              gps_lat: 0,
              gps_lng: 0,
              id: cleaner.id,
              telefone: cleaner.analista?.nome === 'Ana Flávia' ? '5515992510989' : '5515988350885',
              status: 'Liberado'
            })
            Swal.fire({
              title: 'Limpeza',
              html: `A Limpeza foi comfirmada com sucesso.`,
              icon: "success",
              showCloseButton: true,
              confirmButtonColor: "#111",
              confirmButtonText: "Ok",
              })
          })
        }
      })
    } catch {

    }
  }

  console.log(steps)

  return (
    <div className={styles.pdf_main}>
        <div className={styles.confirm_buttom}>
        {userRef && ((userRef.nome === 'Pós-Venda' && userRef.id === cleaner.representante?.id) || userRef.cargo === 'Administrador') && 
          <button 
          aria-label={cleaner && cleaner.limpeza ? "Limpeza Confirmada" : "Confirmar Limpeza"}
          data-cooltipz-dir="left"
          disabled={cleaner && cleaner.limpeza}
          onClick={() => confirmCleaner()}>
            {cleaner && cleaner.limpeza ? 
              <DoneAllIcon sx={{ fill: '#fff' }} /> :
              <CheckIcon sx={{ fill: '#fff' }} />
            }
          </button>
          }
        <Link to={'/'}>
          <button 
            aria-label={'Voltar'}
            data-cooltipz-dir="left">
              <ReplyIcon sx={{ fill: '#fff' }} />
            </button>
        </Link>
        </div>
    <div id="PDF" className={styles.pdf}>
      <div className={styles.pdf_header}>
        <img className={styles.pdf_logo} src={Logo} alt="CORPBRASIL" />
        <select 
        className={styles.pdf_title}
        value={relatorio}
        disabled={userRef && userRef.cargo !== 'Administrador' ? true : false}
        onChange={(e) => setRelatorio(e.target.value)}
        >
          <option value="Relatório Contrato">Relatório Contrato</option>
          <option value="Relatório Administrativo">Relatório Administrativo</option>
        </select>
        {/* <h1>Anexo I</h1> */}
        <h1>Limpeza</h1>
        <h2>{cleaner.nome}</h2>
      </div>
      <div className={styles.pdf_item_container}>
        <h2>Informações</h2>
        <ul className={styles.pdf_text}>
        <li>
            <b>Data Agendado:</b> 
            {
              cleaner.terceirizado?.data ? 
              `${moment(cleaner.terceirizado?.data).format('DD/MM/YYYY')} ás ${cleaner.terceirizado?.horario}` : 
              'Não informado'
            }
          </li>
          <li>
            <b>Cidade:</b> {cleaner.cidade ? cleaner.cidade : 'Carregando'}
          </li>
          <li>
            <b>Analista:</b> {cleaner.analista ? cleaner.analista?.nome: 'Carregando'}
          </li>
          <li>
           <b>Endereço do Cliente:</b>
              {openEdit && openEdit.address ? 
                <><input
                  type="text"
                  className={styles.input_address}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Endereço"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    // onClick={() => confirmEdit('Address')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    // onClick={() => closeEdit('Address')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {cleaner && cleaner.endereco}
                  {cleaner ?
                    <><a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${cleaner && cleaner.lat},${cleaner && cleaner.lng}`}> Clique aqui para visualizar o GPS</a>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Endereço"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        // onClick={() => changeOpenEdit('Address')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>
              }
          </li>
          <li>
            <p>
              <b>Mora em Condomínio? </b> {cleaner && cleaner.condominio ? cleaner.condominio : 'Não Informado'} 
            </p>
            {cleaner && cleaner.condominio === 'Sim' && 
              <p><b>Horário:</b> {cleaner.horario && cleaner.horario.entrada} - {cleaner && cleaner.horario.saida}</p>         
            }
          </li>
        </ul>
      </div>
      <div className={styles.pdf_item_container}>
        <h2>Telhado</h2>
          {cleaner &&
          <ul className={styles.pdf_text}>
            <li>
              <b>Altura da Casa:</b> {cleaner.altura ? cleaner.altura : 'Não Informado'}
            </li>
              <div>
              <li>
                <b>Telha:</b> {cleaner.telha}
              </li>
              </div>
          </ul>}
      </div>
      {/* <h1>Fotos</h1> */}
      <div className={styles.pdf_item_container}>
        <h2>Fotos & Vídeos</h2>
        <div className={styles.photo_content}>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Fachada</h2>
            </div>
              <div className={styles.photos}>
                {cleaner.fachada && cleaner.fachada.length > 0 ? 
                  cleaner.fachada.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  )) : <p className={styles.pdf_alert}>Não informado</p>}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Módulos</h2>
            </div>
            <div className={styles.photos}>
              {cleaner.modulos && cleaner.modulos.length > 0 ?
                cleaner.modulos.map((photo, index) => (
                  <img
                    key={index}
                    className={styles.img_pdf}
                    rel="preconnect"
                    src={photo}
                    alt=""
                  />
                  )) : <p className={styles.pdf_alert}>Não informado</p>
              }
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Telhado</h2>
            </div>
            <div className={styles.photos}>
            {cleaner.telhado ? 
              cleaner.telhado.map((photo, index) => (
                <img
                  key={index}
                  className={styles.img_pdf}
                  rel="preconnect"
                  src={photo}
                  alt=""
              />
            )) : <p className={styles.pdf_alert}>Não informado</p>}
            {cleaner.telhadoVideo ? 
              cleaner.telhadoVideo.map((photo, index) => (
                <><video
                      className={`${styles.img_pdf} ${styles.print}`}
                      width="100%"
                      controls
                      muted
                      playsInline
                    >
                      <source src={photo} type="video/mp4" />
                      <source src={photo} type="video/webm" />
                      <source src={photo} type="video/ogg" />
                    </video>
                  <a href={photo} target="_blank" rel="noreferrer" className={`${styles.view_link} ${styles.print_on}`}>Clique aqui para visualizar o Video do telhado</a></>
            )) : <p className={styles.pdf_alert}>Não informado</p>}
        </div>
          </div>
          {/* <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(1)}>
              <h2>Poste</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[1] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[1]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {poste &&
                  poste.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {poste &&
                  poste.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(2)}>
              <h2>Bengala e Entrada do Ramal de Energia</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[2] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[2]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {bengala &&
                  bengala.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {bengala &&
                  bengala.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(3)}>
              <h2>Caixa do Padrão</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[3] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[3]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {padrao &&
                  padrao.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {padrao &&
                  padrao.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(4)}>
              <h2>Relógio</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[4] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[4]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {relogio &&
                  relogio.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {relogio &&
                  relogio.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(5)}>
              <h2>Disjuntor</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[5] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[5]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {disjuntor &&
                  disjuntor.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {disjuntor &&
                  disjuntor.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(6)}>
              <h2>Amperímetro</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[6] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[6]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {amperimetro &&
                  amperimetro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {amperimetro &&
                  amperimetro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title} onClick={() => handleToggle(7)}>
              <h2>Quadro de Distribuição</h2>
              <IconButton
                aria-label="Expandir"
                data-cooltipz-dir="left"
                size="small"
              >
                {openPhoto[6] ? (
                  <KeyboardArrowUpIcon styles={{ fill: "#fff" }} />
                ) : (
                  <KeyboardArrowDownIcon styles={{ fill: "#fff" }} />
                )}
              </IconButton>
            </div>
            <Collapse in={openPhoto[7]} timeout="auto" unmountOnExit>
              <div className={`${styles.photos} ${styles.print}`}>
                {quadro &&
                  quadro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
            </Collapse>
            <div className={`${styles.photos} ${styles.print_on}`}>
                {quadro &&
                  quadro.map((photo, index) => (
                    <img
                      key={index}
                      className={styles.img_pdf}
                      rel="preconnect"
                      src={photo}
                      alt=""
                    />
                  ))}
              </div>
          </div> */}
        </div>
        {/* <h2>Telhado</h2>
        <div className={styles.photo_content}>
          {photosStep2 &&
            photosStep2.map((photo, index) => (
              <div key={index}>
                {photo.type === "photo" ? (
                  <img
                    className={styles.img_pdf}
                    rel="preconnect"
                    src={`${photo.url}`}
                    alt=""
                  />
                ) : (
                  <><video
                      className={`${styles.img_pdf} ${styles.print}`}
                      width="100%"
                      controls
                      muted
                      playsInline
                    >
                      <source src={photo.url} type="video/mp4" />
                    </video><a href={photo.url} target="_blank" rel="noreferrer" className={`${styles.view_link} ${styles.print_on}`}>Clique aqui para visualizar o Video do telhado</a></>
                )}
              </div>
            ))}
        </div>
        <h2>Telhado com Medida</h2>
        <div className={styles.photo_content}>
          {photosStep3 &&
            photosStep3.map((photo, index) => (
              <img
                key={index}
                className={styles.img_pdf}
                rel="preconnect"
                src={photo}
                alt=""
              />
            ))}
        </div>
        <h2>Projeto</h2>
        <div className={styles.photo_content}>
          {photosStep4 ? (
            photosStep4.map((photo, index) => (
              <img
                key={index}
                className={styles.img_pdf}
                rel="preconnect"
                src={photo}
                alt=""
              />
            ))
          ) : (
            <p className={styles.pdf_alert}>Aguardando o Pós-Venda</p>
          )}
        </div> */}
        <h2>Observação sobre o Telhado</h2>
        <div className={styles.obs_container}>
          {openObservation && openObservation.telhado ? 
          <div className={styles.create_observation}>
            <ThemeProvider theme={theme2}>
              <h3>Observação sobre o Telhado</h3>
              <TextField 
              sx={{ width: '90%' }}
              multiline
              inputProps={{min: 0, styles: { textAlign: 'center' }}}
              rows={4} 
              value={observation && observation.telhado}
              onChange={(e) => setObservation({...observation, telhado: e.target.value})}/>
            </ThemeProvider>
            <div className={styles.create_observation_buttons}>
              {/* <Button variant="contained" color="success" onClick={() => sendObservation('Telhado')}>Confirmar</Button>
              <Button variant="contained" color="error" onClick={() => closeObservation()}>Cancelar</Button> */}
            </div>
            </div> : 
              <><p className={styles.pdf_paragraph}>
                {info && info.observacao_telhado ?
                  info.observacao_telhado : 'Nenhuma'}
              </p>
              {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                <IconButton
                className={styles.print}
                aria-label={'Editar Observação'}
                data-cooltipz-dir="top"
                size="small"
                onClick={() => setOpenObservation({ ...openObservation, telhado: true })}
              >
                  <EditIcon />
                </IconButton>
              }
              </>
            }
        </div>
        {/* <h2>Kit</h2>
        <div className={styles.photo_content}>
          {kit ? (
            kit.map((photo, index) => (
              <img
                key={index}
                className={styles.img_pdf}
                rel="preconnect"
                src={photo}
                alt=""
              />
            ))
          ) : (
            <p className={styles.pdf_alert}>Aguardando o Pós-Venda</p>
          )}
        </div> */}
        {/* {docs && docs.inversorDataSheet && docs.inversorManual && docs.moduloDataSheet && docs.moduloManual && 
          <><h2>Data Sheets e Manuais</h2>
          <div className={styles.photo_content}>
            <div className={styles.photo_container}>
                <div className={styles.photo_title}>
                  <h2>Inversor</h2>
                </div>
                  <div className={styles.photos}>
                    <Button variant="contained" href={docs.inversorDataSheet} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar DataSheet</Button>
                    <ThemeProvider theme={theme}>
                      <Button variant="contained" href={docs.inversorManual} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar Manual</Button>
                    </ThemeProvider>
                  </div>
              </div>
            <div className={styles.photo_container}>
                <div className={styles.photo_title}>
                  <h2>Módulo</h2>
                </div>
                  <div className={styles.photos}>
                    <Button variant="contained" href={docs.moduloDataSheet} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar DataSheet</Button>
                    <ThemeProvider theme={theme}>
                      <Button variant="contained" href={docs.moduloManual} target="_blank" endIcon={<PictureAsPdfIcon />}>Visualizar Manual</Button>
                    </ThemeProvider>
                  </div>
              </div>
          </div></>
        } */}
        {/* {userRef && (userRef.cargo === 'Administrador' || userRef.nome === 'Pós-Venda') && 
        <div className={relatorio === 'Relatório Contrato' ? `${styles.pdf_item_container} ${styles.print}` : styles.pdf_item_container}>
          <h2>Informações</h2>
          <ul className={styles.pdf_text}>
            <li>
              <b>Fornecedor:</b>
              {openEdit && openEdit.fornecedor ? 
                <>
                <select
                  className={styles.input_address}
                  styles={{ width: '13rem' }}
                  value={kitInfo.fornecedor}
                  onChange={(e) => setKitInfo({...kitInfo, fornecedor: e.target.value})}>
                    <option value="Nenhum">Nenhum</option>
                    {supplier && supplier.map((data, index) => (
                      <option key={index} value={data.nome}>{data.nome}</option>
                      ))}       
                  </select>
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar o Fornecedor"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Fornecedor')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Fornecedor')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {visit.kit_info?.fornecedor ?? 'Aguardando o Pós-Venda'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar o Fornecedor"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Fornecedor')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </li>
            <li>
            <div className={styles.info}>
            <b>Valor do Kit: </b>
              {openEdit && openEdit.valorKit ? 
                <>
                R$ <input
                  type="number"
                  className={styles.input_address}
                  styles={{ width: '7rem' }}
                  value={kitInfo.valorKit}
                  onChange={(e) => setKitInfo({...kitInfo, valorKit: e.target.value})} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Valor do Kit"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Valor_Kit')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Valor_Kit')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {visit.kit_info ? Number(visit.kit_info?.valor).toLocaleString('pt-BR', {styles: 'currency', currency: 'BRL'}) : 'Aguardando o Financeiro'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Valor do Kit"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Valor_Kit')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </div>
          </li>
            <li>
            <div className={styles.info}>
            <b>Custo de Instalação: </b>
              {openEdit && openEdit.custoInstalacao ? 
                <>
                R$ <input
                  type="number"
                  className={styles.input_address}
                  styles={{ width: '7rem' }}
                  value={kitInfo.custoInstalacao}
                  onChange={(e) => setKitInfo({...kitInfo, custoInstalacao: e.target.value})} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Custo de Instalacao"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Custo_Instalacao')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Custo_Instalacao')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {visit.kit_info ? Number(visit.kit_info?.instalacao).toLocaleString('pt-BR', {styles: 'currency', currency: 'BRL'}) : 'Aguardando o Financeiro'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Custo de Instalação"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Custo_Instalacao')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </div>
          </li>
          <li>
            <b>Valor de Venda: </b>
              {openEdit && openEdit.valorVenda ? 
                <>
                R$ <input
                  type="number"
                  className={styles.input_address}
                  styles={{ width: '7rem' }}
                  value={kitInfo.valorVenda}
                  onChange={(e) => setKitInfo({...kitInfo, valorVenda: e.target.value})} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Valor de Venda"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Valor_Venda')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Valor_Venda')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {visit.kit_info ? Number(visit.kit_info?.venda).toLocaleString('pt-BR', {styles: 'currency', currency: 'BRL'}) : 'Aguardando o Financeiro'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Valor de Venda"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Valor_Venda')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
          </li>
            <li>
            <div className={styles.info}>
            <b>Potência do Inversor: </b>
              {openEdit && openEdit.potenciaInversor ? 
                <>
                <input
                  type="number"
                  className={styles.input_address}
                  styles={{ width: '3rem' }}
                  value={kitInfo.potenciaInversor}
                  onChange={(e) => setKitInfo({...kitInfo, potenciaInversor: e.target.value})} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Potência do Inversor"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Potencia_Inversor')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Potencia_Inversor')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p>{visit.kit_info ? visit.kit_info?.inversor + ' kWp' : 'Aguardando o Pós-Venda'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Potência do Inversor"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Potencia_Inversor')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </div>
          </li>
          <li>
            <div className={styles.info}>
            <b>Quantidade de Placas: </b>
              {openEdit && openEdit.quantidadePlacas ? 
                <>
                <input
                  type="number"
                  className={styles.input_address}
                  styles={{ width: '3rem' }}
                  value={kitInfo.quantidadePlacas}
                  onChange={(e) => setKitInfo({...kitInfo, quantidadePlacas: e.target.value})} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Quantidade de Placas"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Quantidade_Placas')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Quantidade_Placas')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p>{visit.kit_info ? visit.kit_info?.placa_quantidade : 'Aguardando o Pós-Venda'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Quantidade de Placas"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Quantidade_Placas')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </div>
          </li>
          <li>
            <div className={styles.info}>
            <b>Potência das Placas: </b>
              {openEdit && openEdit.potenciaPlacas ? 
                <>
                <input
                  type="number"
                  className={styles.input_address}
                  styles={{ width: '3rem' }}
                  value={kitInfo.potenciaPlacas}
                  onChange={(e) => setKitInfo({...kitInfo, potenciaPlacas: e.target.value})} />
                  W<div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Potência das Placas"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Potencia_Placas')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Potencia_Placas')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p>{visit.kit_info ? visit.kit_info?.placa_potencia + ' W' : 'Aguardando o Pós-Venda'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Potência das Placas"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Potencia_Placas')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </div>
          </li>
            <li>
              <b>Tipo de Estrutura:</b>
              {openEdit && openEdit.tipoEstrutura ? 
                <>
                <select
                  className={styles.input_address}
                  styles={{ width: '13rem' }}
                  value={kitInfo.tipoEstrutura}
                  onChange={(e) => setKitInfo({...kitInfo, tipoEstrutura: e.target.value})}>
                    <option value="Fibrocimento">Fibrocimento</option>
                    <option value="Cerâmico">Cerâmico</option>
                    <option value="Metálico">Metálico</option>
                    <option value="Solo">Solo</option>
                    <option value="Laje">Laje</option>
                  </select>
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar o Tipo de Estrutura"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Tipo_Estrutura')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Tipo_Estrutura')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {visit.kit_info?.estrutura ?? 'Aguardando o Pós-Venda'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Tipo de Estrutura"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Tipo_Estrutura')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </li>
          </ul>
        </div>  
        } */}
        {/* <h2>Observação sobre o Kit</h2>
        <div className={styles.obs_container}>
          {openObservation && openObservation.kit ? 
          <div className={styles.create_observation}>
            <ThemeProvider theme={theme2}>
              <h3>Observação sobre o Kit</h3>
              <TextField 
              sx={{ width: '90%' }}
              multiline
              rows={4}
              inputProps={{min: 0, styles: { textAlign: 'center' }}} 
              value={observation && observation.kit}
              onChange={(e) => setObservation({...observation, kit: e.target.value})} />
            </ThemeProvider>
            <div className={styles.create_observation_buttons}>
              <Button variant="contained" color="success" onClick={() => sendObservation('Kit')}>Confirmar</Button>
              <Button variant="contained" color="error" onClick={() => closeObservation()}>Cancelar</Button>
            </div>
            </div> : 
              <><p className={styles.pdf_paragraph}>
                {info && info.observacao_kit ?
                  info.observacao_kit : 'Nenhuma'}
              </p>
              {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                <IconButton
                className={styles.print}
                aria-label={'Editar Observação'}
                data-cooltipz-dir="top"
                size="small"
                onClick={() => setOpenObservation({ ...openObservation, kit: true })}
              >
                  <EditIcon />
                </IconButton>
              }
              </>
            }
        </div> */}
      </div>
      <div className={styles.pdf_rules}>
        <h2>⚠️ Responsabilidade da Contratada ⚠️</h2>
        <div>
          <p styles={{ marginBottom: '2rem' }} className={styles.pdf_paragraph}>
            A CONTRATADA manifesta sua ciência quanto ao projeto acima apresentado e declara ter plena capacidade técnica para sua execução, manifestando ainda sua ciência e concordância quanto:
          </p>
          <p className={styles.pdf_paragraph}>
          <b>1 – Verificação de materiais:</b> a contratada assume a responsabilidade de produzir fotos e vídeos e realizar a verificação dos materiais, especialmente os módulos, inversores, metragem de cabos antes do início da obra. Assume ainda integral responsabilidade pelos custos de substituição, em caso de danos ou falta (perda) durante a instalação.
          </p>
          <p className={styles.pdf_paragraph}>
          <b>2 – Laudo:</b> antes de iniciar a instalação, é fundamental e obrigatório que a contratada realize inspeção visual abrangente, registrando fotos e vídeos do interior da residência e do telhado. Durante essa avaliação, a contratada deverá conduzir uma análise detalhada de possíveis pontos de infiltração, verificando lâmpadas, luminárias, pinturas ou gesso que possam ter sido danificados devido a infiltrações no telhado. Além disso, deverá examinar a condição das telhas, identificando trincas, quebras ou desencaixamentos. Essa documentação completa será uma evidência crucial em potenciais alegações relacionadas à instalação, estabelecendo uma base sólida para futuros reparos ou apuração de responsabilidades.
          </p>
          <p className={styles.pdf_paragraph}>
          <b>3 – Custo adicional:</b> até 10 metros do ponto de conexão da rede, a instalação do inversor está incluída. Para distâncias superiores a 10 metros, o custo da infraestrutura será cobrado separadamente. Caso a conexão CA ultrapasse 10 metros, a contratada deverá realizar a medição da infraestrutura adicional e informar o setor de obras da empresa. Nesse caso, o cliente incorrerá em custos adicionais de R$55,00 por metro adicional para aterramento (com bitola de cabo até 6mm) e R$110,00 por metro adicional para a infraestrutura CA (com bitola de cabo até 10mm). O cliente está ciente dessa condição conforme estipulado no contrato e receberá um aviso da equipe de pós-vendas no dia agendado para a instalação.
          </p>
          <p className={styles.pdf_paragraph}>A contratada deverá comunicar à contratante sobre o custo adicional por metro de infraestrutura CA, sob pena de, não o fazendo, ficar obrigada a reembolsar esse valor à contratante. É importante destacar que o custo adicional de infraestrutura será tratado entre a contratante e o cliente final, sendo expressamente proibido à contratada cobrar diretamente do cliente.</p>
          <p className={styles.pdf_paragraph}>
          <b>4 – Gestão de materiais:</b> a contratada deverá prestar contas de todo material usado na execução do serviço e, em caso de sobra de materiais, deverá devolver à contratante.
          </p>
        </div>
      </div>
      <h1>Serviço</h1>
      {openObservation && openObservation.instalacao ? 
      <div className={styles.create_observation}>
      <ThemeProvider theme={theme2}>
        <h3>Observação</h3>
        <TextField 
        fullWidth
        multiline
        rows={4}
        inputProps={{min: 0, styles: { textAlign: 'center' }}} 
        value={observation && observation.instalacao}
        onChange={(e) => setObservation({...observation, instalacao: e.target.value})}/>
      </ThemeProvider>
      <div className={styles.create_observation_buttons}>
        {/* <Button variant="contained" color="success" onClick={() => sendObservation()}>Confirmar</Button>
        <Button variant="contained" color="error" onClick={() => closeObservation()}>Cancelar</Button> */}
      </div>
    </div> :
      <div className={styles.observation}>
        <h3>Observação</h3>
        <p>{cleaner && cleaner.observacao_limpeza}</p>
        {userRef && (userRef.cargo === 'limpeza' || userRef.cargo === 'Administrador') && 
          <IconButton
            className={styles.print}
            aria-label={observation && observation.limpeza ? "Editar Anotação" : "Criar Anotação"}
            data-cooltipz-dir="top"
            size="small"
            color={observation && observation.limpeza ? "" : "success"}
            onClick={() => setOpenObservation({...openObservation, limpeza: true})}
          >
            {observation && observation.limpeza ? 
            <EditIcon /> : 
            <AddCircleIcon />
            }
          </IconButton>
        }
      </div>
    }    
      <div className={styles.pdf_item_container}>
      <h2>Serviço Complementar</h2>
      <div className={styles.obs_container}>
          {openObservation && openObservation.servico ? 
          <div className={styles.create_observation}>
            <ThemeProvider theme={theme2}>
              <h3>Alterar Serviço Complementar</h3>
              <TextField 
              sx={{ width: '90%' }}
              multiline
              inputProps={{min: 0, styles: { textAlign: 'center' }}}
              rows={4}
              value={observation && observation.servico}
              onChange={(e) => setObservation({...observation, servico: e.target.value})}/>
            </ThemeProvider>
            <div className={styles.create_observation_buttons}>
              {/* <Button variant="contained" color="success" onClick={() => sendObservation('Servico')}>Confirmar</Button>
              <Button variant="contained" color="error" onClick={() => closeObservation()}>Cancelar</Button> */}
            </div>
            </div> : 
              <><p className={styles.pdf_paragraph}>
                {info && info.servico_complementar ?
                  info.servico_complementar : 'Nenhuma'}
              </p>
              {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                <IconButton
                className={styles.print}
                aria-label={'Editar Serviço Complementar'}
                data-cooltipz-dir="top"
                size="small"
                onClick={() => setOpenObservation({ ...openObservation, servico: true })}
              >
                  <EditIcon />
                </IconButton>
              }
              </>
            }
      </div>
      <div className={styles.pdf_item_container}>
        <h2>Informações</h2>
        <ul className={styles.pdf_text}>
          <li>
            <b>Data de Conclusão:</b> {cleaner && cleaner.data ? cleaner.data : 'Aguardando o Terceirizado'}
          </li>
          <li>
            <b>Responsável pela Limpeza:</b> 
            {cleaner && cleaner.terceirizado ? 
            `${cleaner.terceirizado?.nome} (${cleaner.terceirizado?.razao_social})` : 
            'Aguardando o Pós-Venda'}
          </li>
          <li>
            <b>Há vestígios de infiltração??</b> {step1 && step1.infiltracao ? step1.infiltracao : 'Aguardando o Terceirizado'}
          </li>
          <li>
            <b>Foi realizado a inspeção para garantir que nenhuma telha foi quebrada ou desencaixada?</b> {step2 && step2.inspecao ? step2.inspecao : 'Aguardando o Terceirizado'}
          </li>
          <li>
            <b>Foi realizado o reaperto do intermediários e finais dos módulos?</b> {step2 && step2.inspecao ? step2.inspecao : 'Aguardando o Terceirizado'}
          </li>
          {userRef && (userRef.cargo === 'Administrador' || userRef.nome === 'Pós-Venda') && 
            <>
            {/* <li>
            <b>Custo de Infra CA: </b>
              {openEdit && openEdit.infraCACusto ? 
                <>
                R$ <input
                  type="number"
                  className={styles.input_address}
                  styles={{ width: '7rem' }}
                  value={kitInfo.infraCACusto}
                  onChange={(e) => setKitInfo({...kitInfo, infraCACusto: e.target.value})} />
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar Custo de Infra CA"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Custo_InfraCA')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Custo_InfraCA')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {visit.infra_ca ? Number(visit.infra_ca?.custo).toLocaleString('pt-BR', {styles: 'currency', currency: 'BRL'}) : 'Aguardando o Financeiro'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar Custo de Infra CA"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Custo_InfraCA')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </li>
            <li>
              <b>Fornecedor de Infra CA:</b>
              {openEdit && openEdit.infraCAFornecedor ? 
                <>
                <select
                  className={styles.input_address}
                  styles={{ width: '13rem' }}
                  value={kitInfo.infraCAFornecedor}
                  onChange={(e) => setKitInfo({...kitInfo, infraCAFornecedor: e.target.value})}>
                    <option value="Nenhum">Nenhum</option>
                    {supplier && supplier.map((data, index) => (
                      <option key={index} value={data.nome}>{data.nome}</option>
                      ))}       
                  </select>
                  <div>
                  <IconButton
                    className={styles.print}
                    aria-label="Confirmar o Fornecedor"
                    data-cooltipz-dir="top"
                    size="small"
                    color="success"
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => confirmEdit('Fornecedor_InfraCA')}
                  >
                    <CheckIcon />
                  </IconButton><IconButton
                    className={styles.print}
                    aria-label="Cancelar"
                    data-cooltipz-dir="top"
                    color="error"
                    size="small"
                    onClick={() => closeEdit('Fornecedor_InfraCA')}
                  >
                    <CloseIcon />
                  </IconButton>
                  </div>
                  </> : 
                  <p> {visit.infra_ca?.fornecedor ?? 'Aguardando o Financeiro'}
                  {checkStep.step1 ?
                    <>
                      {userRef && (userRef.nome === 'Pós-Venda' || userRef.cargo === 'Administrador') && 
                        <IconButton
                        className={styles.print}
                        aria-label="Alterar o Fornecedor"
                        data-cooltipz-dir="top"
                        size="small"
                        sx={{ marginLeft: '1rem' }}
                        onClick={() => changeOpenEdit('Fornecedor_InfraCA')}
                      >
                        <EditIcon />
                      </IconButton>
                      }
                      </>
                    : 'Carregando...'}
                </p>}
            </li> */}
            {/* <li className={relatorio === 'Relatório Contrato' ? styles.print : ''}>
              <b>GPS da Limpeza:</b>
              {info.gps_instalacao ? 
              <a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${info.gps_instalacao && info.gps_instalacao.lat},${info.gps_instalacao && info.gps_instalacao.lng}`}> Clique aqui para visualizar o GPS</a> :
              <p>Aguardando a instalação</p>
              }
            </li> */}
            </>
          }
        </ul>
      </div>
      <h2>Vistoria</h2>
      <div className={styles.photo_content} styles={{ padding: '2rem 0 1.5rem 0' }}>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Módulos</h2>
            </div>
              <div className={styles.photos}>
              {step1 && step1.modulos ? (
                step1.modulos.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                ))
              ) : (
                <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
              )}
              {step1 && step1.modulos_foto_antes ? (
                step1.modulos_foto_antes.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                ))
              ) : (
                <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
              )}
              {step1 && step1.modulos_local ? (
                step1.modulos_local.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                ))
              ) : (
                <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
              )}
              {step1 && step1.modulos_video_antes ? (
                step1.modulos_video_antes.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                ))
              ) : (
                <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
              )}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Inversor</h2>
            </div>
              <div className={styles.photos}>
              {step1 && step1.inversor ? (
                step1.inversor.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                  ))
                ) : (
                  <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
                )}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Inversor String</h2>
            </div>
            <div className={styles.photos}>
              {step1 && step1.inversor_string ? (
                step1.inversor_string.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                ))
              ) : (
                <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
              )}
              </div>
          </div>
        </div>
        <h2>Limpeza Finalizada</h2>
        <div className={styles.photo_content}>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Módulos</h2>
            </div>
            <div className={styles.photos}>
              {step2 && step2.modulos ? (
                step2.modulos.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                ))
              ) : (
                <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
              )}
              </div>
          </div>
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Inversor</h2>
            </div>
            <div className={styles.photos}>
              {step2 && step2.inversor ? (
                step2.inversor.map((photo, index) => (
                  <File url={photo} index={index} classname={styles.img_pdf} />
                  ))
                ) : (
                  <p className={styles.pdf_alert}>Aguardando a Limpeza</p>
                )}
              </div>
          </div>
          {step2 && step2.adicional ? (
          <div className={styles.photo_container}>
            <div className={styles.photo_title}>
              <h2>Adicional</h2>
            </div>
            <div className={styles.photos}>
                {step2.adicional.map((photo, index) => (
                  <div>
                    <File url={photo} index={index} classname={styles.img_pdf} />
                    <div className={styles.comentary}>
                      <p>{step2.mensagem[index]}</p>
                    </div>
                  </div>
                  ))}
              </div>
          </div>) : null}
        </div>
      </div>
      {userRef && ((userRef.cargo === 'Terceirizado' && userRef.id === cleaner.terceirizado?.id) || userRef.cargo === 'Administrador' || userRef.nome === "Pós-Venda") && 
      <><h1 className={relatorio === 'Relatório Contrato' ? styles.print : ''}>Área exclusiva do Terceirizado</h1>
        <div className={step1 ? `${styles.visit_actions_tec} ${styles.confirm_tec}` : `${styles.visit_actions_tec}`}>
            <h2>Antes da Limpeza {step1 ? '✅' : ''}</h2>
            <p className={relatorio === 'Relatório Contrato' ? `${styles.print} ${styles.text_legend}` : ''}>
              Antes da limpeza será preciso <b>realizar uma vístoria</b> no sistema do cliente.
            </p>
            <CleanerStep1 cleaner={idCleaner} open={OpenInstallStep === 'step1' ? true : false} close={() => setOpenInstallStep('step0')} />
            <CleanerStep2 cleaner={cleaner} open={OpenInstallStep === 'step2' ? true : false} close={() => setOpenInstallStep('step0')} />
            <div className={styles.visit_buttons}>
              <ThemeProvider theme={theme}>
                {step1 ? (
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{ textTransform: 'capitalize' }}
                    endIcon={<CheckCircleIcon />}
                  >
                    Vistoria do Sistema
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => setOpenInstallStep('step1')}
                  >
                    Vistoria do Sistema
                  </Button>
                )}
              </ThemeProvider>
            </div>
          </div>
          {/* <div className={checkStep.step7 ? `${styles.visit_actions_tec} ${styles.confirm_tec}` : `${styles.visit_actions_tec}`}>
              <h2>Antes da Limpeza {checkStep.step7 ? '✅' : ''}</h2>
              <p className={relatorio === 'Relatório Contrato' ? `${styles.print} ${styles.text_legend}` : ''}>Durante da instalação será preciso tirar fotos da estrutura. Envie uma foto da estrutura, dos hooks e do aterramento.</p>
              <div className={styles.visit_buttons}>
                <ThemeProvider theme={theme}>
                  {checkStep.step7 ? (
                    <Button
                      variant="outlined"
                      color="success"
                      endIcon={<CheckCircleIcon />}
                    >
                      Estrutura
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => setOpenInstallStep('step3')}
                    >
                      Estrutura
                    </Button>
                  )}
                </ThemeProvider>
              </div>
            </div> */}
            <div className={step2 ? `${styles.visit_actions_tec} ${styles.confirm_tec}` : `${styles.visit_actions_tec}`}>
              <h2>Depois da Limpeza {step2 ? '✅' : ''}</h2>
              <p className={relatorio === 'Relatório Contrato' ? `${styles.print} ${styles.text_legend}` : ''}>
                Após a finalização da limpeza, <b>siga as etapas</b> através do botão abaixo para enviar as fotos dos módulos limpos.
              </p>
              <div className={styles.visit_buttons}>
                <ThemeProvider theme={theme}>
                  {step2 ? (
                    <Button
                      variant="outlined"
                      color="success"
                      sx={{ textTransform: 'capitalize' }}
                      endIcon={<CheckCircleIcon />}
                    >
                      Módulos Limpos
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => setOpenInstallStep('step2')}
                    >
                      Módulos Limpos
                    </Button>
                  )}
                </ThemeProvider>
              </div>
            </div></>   
      }
      {/* <Dialog
      className={styles.anotation_container}
      sx={{ padding: '1rem' }}
      open={openAnotation}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenAnotation(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenAnotation(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Concluir Análise</DialogTitle>
        <DialogContent>
        <DialogContentText sx={{ marginBottom: '0.2rem' }}>
          Deixe uma nota a respeito da análise do <b>Padrão/Telhado</b> do cliente para concluir.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={styles.input}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Nota"
            type="text"
            rows={10}
            multiline
            value={anotation}
            onChange={(e) => setAnotation(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
            <Button variant='contained' onClick={() => setOpenAnotation(false)}>FECHAR</Button>
            <Button variant='contained' color="success" onClick={() => sendAnalyseEnd()}>Enviar</Button>
          </DialogActions>
        </ThemeProvider>
        </Dialog> */}
    </div>
    </div>
  );
};

export default Cleaner;

import { Box, Dialog, IconButton, useMediaQuery } from '@mui/material'
import React from 'react'

import styles from '../styles.module.scss';
import { theme } from '../../../data/theme';
import CloseIcon from '@mui/icons-material/Close';

import Estudo from '../../../images/system/Estudo.png';
import Irradiacao from '../../../images/system/Irradiacao.png';
import Irradiacao2 from '../../../images/system/Irradiacao2.png';
import NewButton from './NewButton';

const ModalCase = ({ open, close }) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            className={styles.dialog}
            open={open}
            fullWidth
            fullScreen={fullScreen}
            maxWidth="md"
        >
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ fill: '#000' }} />
            </IconButton>
            <Box className={styles.dialog_case} sx={{ padding: '3rem 1rem' }}>
                <p><b>Situação Inicial:</b> Antes da análise de irradiação, calculamos que o cliente precisaria de 15 placas fotovoltaicas para zerar o consumo de kWh.</p>
                <img alt='' src={Estudo} />
                <p><b>Análise de Irradiação:</b> Após a visita técnica e um estudo detalhado de irradiação solar, identificamos que o telhado do cliente possui muitos pontos de sombreamento, o que resultaria em perdas significativas na geração de energia.</p>
                <img alt='' src={Irradiacao} />
                <div className={styles.legend}>
                    <div className={styles.legend_item}>
                        <div className={styles.legend_item_irradiaton}></div>
                        <p>Com Irradiação Solar</p>
                    </div>
                    <div className={styles.legend_item}>
                        <div className={styles.legend_item_shading}></div>
                        <p>Com Sombreamento</p>
                    </div>
                </div>
                <img alt='' src={Irradiacao2} />
                <p><b>Conclusão:</b> Para atender à necessidade do cliente, seriam necessárias 15 placas, mas o telhado não tem espaço suficiente. Sugerimos a instalação de 11 unidades, considerando as limitações técnicas do local.</p>
                <p>Antes de conhecer a CorpBrasil, o cliente estava prestes a fechar negócio com uma empresa de energia solar que não dispunha de toda essa tecnologia necessária para realizar uma análise detalhada.</p>
                <p>Este é mais um exemplo de como garantimos a melhor escolha para nossos clientes, baseada em avaliações técnicas.</p>
                <NewButton sx={{ marginTop: '1rem' }} onClick={close}>Fechar</NewButton>
            </Box>
        </Dialog>
    )
}

export default ModalCase
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import Swal from "sweetalert2";
import moment from 'moment';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AudioFileIcon from '@mui/icons-material/AudioFile';

import CloseIcon from '@mui/icons-material/Close';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DeleteRounded from '@mui/icons-material/DeleteRounded'; 
import MapIcon from '@mui/icons-material/Map';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

import Logo from '../../../../images/LogoCORPBRASIL.png';
import PainelSolar from '../../../../images/icons/PainelSolar.png';

import "cooltipz-css";

import style from "../style.module.scss";
import { Badge, Box, Button, Checkbox, ThemeProvider, styled } from '@mui/material';
import { theme } from '../../../../data/theme';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import axios from 'axios';
import AudioRecorder from '../../../../components/AudioRecorder/Index';
import NewTooltip from '../../../../components/Tooltip';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';

import Loading from '../../../../components/Loading';
import Estimate from '../Estimate';
import Consumption from '../Consumption';
import ButtonCB from '../../../../components/Button';

const Create = ({customer, view, close, userRef, data }) => {
  const inputRef = useRef(null);
  const [audio, setAudio] = useState(null);
  const storage = getStorage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [observacao, setObservacao] = useState('');
  const [checkSheet, setCheckSheet] = useState(false);
  const [kit, setKit] = useState('');
  const [generation, setGeneration] = useState('');
  const [showExtra, setShowExtra] = useState(false);
  const [googleEarth, setGoogleEarth] = useState({
    file: '',
    complete: null
  });
  const [proposal, setProposal] = useState(null);
  const [open, setOpen] = useState({
    estimate: false,
    consumption: false,
  });

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  console.log(data)

useEffect(() => {
  if(view) {
    setKit(data.kit || '0');
    setGeneration(data.geracao || '0');
    meses.forEach((m) => {
      handleDataChange(`${m}.consumo_adicionado`, String(0));
    });
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[view])

  const initialState = {
    janeiro: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    fevereiro: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    marco: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    abril: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    maio: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    junho: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    julho: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    agosto: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    setembro: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    outubro: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    novembro: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    },
    dezembro: {
      geracao: 0,
      consumo_atual: 0,
      consumo_adicionado: 0
    }
  };

  console.log(equipments)

  const meses = ['janeiro', 'fevereiro', 'marco', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'set_field', field: name, value });
  };

  const reducer = (state, action) => {
    switch(action.type) {
      case 'reset':
        return initialState;
      case 'set_field': 
      const { field, value } = action;
      if(field.includes('.')) {
        const [mainField, nestedField] = field.split('.');
        return {
          ...state,
          [mainField]: {
            ...state[mainField],
            [nestedField]: value.replace(',','.')
          },
        };
      }
      return {...state, [field]: value}
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  console.log('STATEEE', state)

  useEffect(() => {
    if (!open.consumption) {
        const monthTotals = {};
        console.log(equipments);
        if(equipments.length > 0) {
          // Itera sobre cada equipamento
          equipments.forEach(item => {
              // Itera sobre os meses do equipamento
              item.meses.forEach(mes => {
                  const mesFormatado = mes.toLowerCase() === 'março' ? 'marco' : mes.toLowerCase();
  
                  // Se o mês já existe no objeto monthTotals, adicione ao total existente
                  if (monthTotals[mesFormatado]) {
                      monthTotals[mesFormatado] += item.kwh_total;
                  } else {
                      // Se não, inicie o total com o valor kwh_total do item atual
                      monthTotals[mesFormatado] = item.kwh_total;
                  }
              });
          });
  
          // Chama handleDataChange para cada mês com o total acumulado
          Object.keys(monthTotals).forEach(mes => {
              handleDataChange(`${mes}.consumo_adicionado`, String(monthTotals[mes].toFixed(2)));
          });
        } else {
          meses.forEach((m) => {
            handleDataChange(`${m}.consumo_adicionado`, '0');
          });
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [equipments, open.consumption]);

  const handleDataChange = useCallback((name, value) => {
    dispatch({ type: 'set_field', field: name, value });
  }, [dispatch]);

  const SumValue = (type) => {
    if(type === 'CSC') {
      let sum = 0;
      meses.forEach(value => {
        const geracao = state[value]?.geracao ? Number(state[value]?.geracao.replace(',','.')) : 0;
        const consumoAtual = state[value]?.consumo_atual ? Number(state[value]?.consumo_atual) : 0
        sum += geracao - consumoAtual
      })
      return sum;
    } else if(type === 'CCC') {
      let sum = 0;
      meses.forEach(value => {
        const geracao = state[value]?.geracao ? Number(state[value]?.geracao) : 0;
        const consumoAtual = state[value]?.consumo_atual ? Number(state[value]?.consumo_atual) : 0;
        const consumoAdicionado = state[value]?.consumo_adicionado ? Number(state[value]?.consumo_adicionado) : 0;
        sum += geracao - (consumoAtual + consumoAdicionado)
      })
      return sum;
    } else if(type === 'MSC') {
      let sum = 0;
      meses.forEach(value => {
        const consumoAtual = state[value]?.consumo_atual ? Number(state[value]?.consumo_atual) : 0;
        sum += consumoAtual / 12
      })
      return sum;
    } else if(type === 'MCC') {
      let sum = 0;
      meses.forEach(value => {
        const consumoAtual = state[value]?.consumo_atual ? Number(state[value]?.consumo_atual) : 0;
        const consumoAdicionado = state[value]?.consumo_adicionado ? Number(state[value]?.consumo_adicionado) : 0;
        sum += (consumoAtual + consumoAdicionado) / 12
      })
      return sum;
    }
  }

  const handleUpload = async () => {

    if (!audio || !googleEarth) {
      // alert("Por favor, selecione um arquivo de áudio e uma foto primeiro!");
      return;
    }

    const audioRef = ref(storage, `Clientes/${customer.id}/Mensagem-${Date.now()}.webm`);
    const photoRef = ref(storage, `Clientes/${customer.id}/GoogleEarth-${Date.now()}.${googleEarth.complete.type.split('/')[1]}`);

    try {
      // Upload do áudio
      const audioSnapshot = await uploadBytes(audioRef, audio.complete);
      const audioURL = await getDownloadURL(audioSnapshot.ref);

      // Upload da foto
      const photoSnapshot = await uploadBytes(photoRef, googleEarth.complete);
      const photoURL = await getDownloadURL(photoSnapshot.ref);

      console.log('Áudio enviado com sucesso! URL:', audioURL);
      console.log('Foto enviada com sucesso! URL:', photoURL);

      const filesRef = {};

      if (audio) {
        filesRef.mensagemVoz = {
          url: audioURL,
          ref: audioSnapshot.ref.fullPath
        };
      }

      if (googleEarth.file.length > 0) {
        filesRef.googleEarth = {
          url: photoURL,
          ref: photoSnapshot.ref.fullPath
        };
      }

      return filesRef;

    } catch (error) {
      console.error('Erro ao enviar arquivos:', error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(proposal)
    try {

      if(!kit || !generation) {
        return Swal.fire({
          title: 'Atenção',
            html: 'Preecha os campos <b>Kit</b> e <b>Geração Média</b> para prosseguir.',
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#0eb05f"
        })
      }

      if(showExtra && (!audio || !googleEarth.complete || !proposal)) {
        let msg = !audio ? {
          title: 'Mensagem de Voz',
          text: `Envie ou grave um <b>Áudio</b> para prosseguir.`
        } : !googleEarth.complete ? {
          title: 'Imagem do Google Earth',
          text: `Envie uma imagem do <b>Google Earth</b> para prosseguir`
        } : {
          title: 'Proposta',
          text: `Selecione uma <b>Proposta</b> para prosseguir`
        };

        return Swal.fire({
          title: msg.title,
            html: msg.text,
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#0eb05f"
        })
      }

      if(!checkPercentageRecommended()) {
        const { value: motivo } = await Swal.fire({
          title: 'Planilha de Crédito',
          html: `A Gordura com Consumo Adicionado está fora do padrão <b>(5 a 12%)</b>. `
          + `Explique o motivo:`,
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#27d327",
          confirmButtonText: "Continuar",
          cancelButtonText: "Fechar",
          input: 'textarea',
          inputValidator: (value) => {
            if (!value) {
              return 'Deixe uma explicação sobre a Gordura'
            }
          },
        })

        if(motivo) {
          Swal.fire({
            title: 'Planilha de Crédito',
            text: `Você deseja criar uma nova planilha?`,
            icon: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then(async (result) => {
            if (result.isConfirmed) {
              sendData(motivo);
            }
          })
        }
      } else {
        Swal.fire({
          title: 'Planilha de Crédito',
          text: `Você deseja criar uma nova planilha?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await sendData();
          }
        })
      }
    } catch (error) {
      console.error('Ocorreu um erro' + error)
    }
  }

  const sendPhoto = (e) => {
    const image = e.target.files[0];
    setGoogleEarth({ file: URL.createObjectURL(image), complete: image });
    console.log(image)
  }

  const sendAudio = (e) => {
    const audio = e.target.files[0];
    setAudio({ file: URL.createObjectURL(audio), complete: audio });
    console.log(audio)
  }

  const sendData = async (value) => {
    const checkValue = value ?? '';
    try {
        setLoading(true);
        const Sheets = [];
        const extra = !checkPercentage().check ? null : await handleUpload();
        let idRef = null;
        if(customer) {
          if(customer.planilha) {
            Sheets.push(...customer.planilha);
          }
          if(extra) {
            Sheets.push({
              ...state,
              equipamentos: equipments,
              kit: kit,
              geracao: generation,
              motivo: checkValue,
              responsavel: {
                nome: userRef?.nome,
                id: userRef?.id
              },
              gordura: (((SumValue('CCC')/12) / SumValue('MCC')) * 100).toFixed(2),
              observacao: observacao,
              ...customer.endereco_escolhido,
              historico: [
                {
                    data: moment().format('DD/MM/YYYY HH:mm'),
                    text: `Planilha criada pelo(a) ${userRef && userRef.nome}.`
                }
              ],
              extra,
              orcamento: proposal
            });
          } else {
            Sheets.push({
              ...state,
              kit: kit,
              equipamentos: equipments,
              motivo: checkValue,
              geracao: generation,
              gordura: (((SumValue('CCC')/12) / SumValue('MCC')) * 100).toFixed(2),
              observacao: observacao,
              responsavel: {
                nome: userRef?.nome,
                id: userRef?.id
              },
              ...customer.endereco_escolhido,
              historico: [
                {
                    data: moment().format('DD/MM/YYYY HH:mm'),
                    text: `Planilha criada pelo(a) ${userRef && userRef.nome}.`
                }
              ]
            });
          }
          
          await updateDoc(doc(dataBase, "Clientes", customer.id), {
            planilha: Sheets,
            data: moment(new Date()).format('DD MMM YYYY - HH:mm'),
            endereco_list: customer.endereco_list ?? null,
            cidade: customer.cidade,
            endereco: customer.endereco,
            cep: customer.cep ?? '',
            historico: [...customer.historico, {
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Planilha Criada',
              text: `Foi criada uma planilha de crédito de ${kit} kWp com geração média de ${generation} kWh.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome
            }]
          })
          idRef = customer.id;

        }

        console.log(Sheets)

        const consultoraRef = customer.assistente ? 
        customer.consultora_uid === userRef?.id ?
        customer.consultora : `${customer.consultora} (${userRef?.nome})` :
        customer.consultora;

        if(!checkPercentageRecommended()) {
            await axios.post('https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/45898/IsDRdPcw5BQ9/', {
              nome: data.nome,
              kit: kit,
              gordura: (((SumValue('CCC')/12) / SumValue('MCC')) * 100).toFixed(2),
              consultora: consultoraRef,
              id: idRef,
              index: Sheets.length - 1,
              telefone: '5515991573088',
              motivo: checkValue,
              ceo: 'Thales',
            })
          }

          setCheckSheet(true);

          await Swal.fire({
            title: 'CORPBRASIL',
            html: `A Planilha de Créditos do(a) <b>${data.nome}</b> foi criada com sucesso.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          });
          
          navigate(`/credito/${customer.id}/${Sheets.length - 1}`);
          setTimeout(() => {
            setLoading(false);
          }, 500);
    } catch (error) {
      console.log(error);
    } 
  }

  const closeAll = () => {
    dispatch({ type: 'reset' });
    setCheckSheet(false);
    setObservacao('');
    close('all');
  }

  const handleCheck = (checked) => {
    console.log(checked)

    if(!checked) {
      setGoogleEarth({file: '', complete: null});
      setAudio(null);
    }

    setShowExtra(checked);
  }

  const checkPercentageRecommended = () => {
    const gorduraConsumoAdicionado = (((SumValue('CCC') / 12) / SumValue('MCC')) * 100).toFixed(2);

    if (gorduraConsumoAdicionado < 5 || gorduraConsumoAdicionado > 12) {
      return false
    } else {
      return true;
    }
  }

  const checkPercentage = () => {

    const gorduraConsumoAdicionado = (((SumValue('CCC') / 12) / SumValue('MCC')) * 100).toFixed(2);

    if (gorduraConsumoAdicionado >= 1 && gorduraConsumoAdicionado <= 5) {
      return {
        color: '#000',
        check: true,
        text: 'Sistema Ideal'
      }
    } else if (gorduraConsumoAdicionado > 5) {
      return {
        color: '#05a937',
        check: true,
        text: 'Sistema Recomendado'
      }
    } else {
      return {
        color: '#ccc',
        check: false,
        text: ''
      }
    }
  };

  const monthPermission = (month) => {
    if(month === 'maio' || month === 'junho' || month === 'julho' || month === 'agosto') {
      return true
    } 

    return false;
  }

  return (
    <Dialog
        fullScreen
        open={view}
        onClose={() => closeAll()}
        disableEnforceFocus
      >
        <Loading view={loading} backgroundColor='#ffffff91' fixed={false} />
          <div className={style.sheets_header}>
            <div className={style.sheets_logo}>
              <img src={Logo} alt='' />
            </div>
            <div className={style.title}>
              <h1>Planilha de Créditos</h1>
            </div>
            <ul>
            <li>
              <b>Nome: </b>
              <input type="text" value={data.nome} readOnly />
            </li>
            <li>
              <Box textAlign={'start!important'} width={`${kit.length * 10 + 90}px!important`} marginRight={'1rem'}>
                <b>Kit: </b>
                <input
                  type="number"
                  style={{ width: `${kit.length * 10 + 10}px` }}
                  value={kit ?? ''}
                  onChange={(e) => setKit(e.target.value)}
                  autoComplete='off' /> kWp
              </Box>
              <Box textAlign={'start!important'} width={`${generation?.length * 10 + 170}px!important`}>
                <b>Geração Média: </b>
                <input
                  type="number"
                  style={{ width: `${generation?.length * 10 + 10}px` }}
                  value={generation ?? ''}
                  onChange={(e) => setGeneration(e.target.value)}
                  autoComplete='off' /> kWh
              </Box>
            </li>
          </ul>
            {/* <ul>
              <li>
                <b>Nome: </b>
                <input type="text" value={data.nome} readOnly />
              </li>
              <li>
                <b>Kit: </b>
                <input 
                 type="text"
                 style={{ width: `${kit.length * 10 + 10}px` }}
                 value={kit ?? ''}
                 onChange={(e) => setKit(e.target.value)}
                 autoComplete='off'/> kWp
              </li>
            </ul> */}
            <IconButton
              className={style.print_off}
              sx={{ position: 'absolute', right: '0' }}
              color="inherit"
              onClick={() => closeAll()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        <div className={style.sheets_container}>
          <div className={style.sheets_result}>
          <form onSubmit={onSubmit}>
          <TableContainer className={style.sheets} >
            <Table size="small" aria-label="sticky table">
              <TableHead>
                <TableRow className={style.sheets_table_header}>
                  <TableCell align="center"></TableCell>
                  {meses.map((m, index) => (
                    <TableCell key={index} sx={{ textTransform: 'capitalize' }} align="center">{m.substring(0,3)}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={style.table_row}>
                  <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}><b>Geração</b></TableCell>
                  {meses.map((m, index) => (
                    <TableCell key={index} sx={{ padding: '0' }} className={style.table_td}>
                      <input
                       style={{ fontWeight: 'bold' }}
                       type="number"
                       label="Geração Atual"
                       name={`${m}.geracao`}
                       value={state[m]?.geracao ? state[m]?.geracao.replace('.', ',') : ''}
                       autoComplete="off"
                       onChange={handleChange}
                       required
                       disabled={checkSheet} />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow className={style.table_row}>
                  <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Consumo Atual</TableCell>
                  {meses.map((m, index) => (
                    <TableCell key={index} className={style.table_td}>
                    <input
                     type="number"
                     label="Geração Atual"
                     name={`${m}.consumo_atual`}
                     value={(state[m]?.consumo_atual && state[m]?.consumo_atual.replace('.', ',')) || ''}
                     autoComplete="off"
                     onChange={handleChange}
                     required
                     disabled={checkSheet} />
                  </TableCell>
                  ))}
                </TableRow>
                <TableRow className={style.table_row}>
                  <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>
                    Consumo Adicionado
                  </TableCell>
                  <TableCell className={style.consumption} colSpan={12} sx={{ padding: '0!important', border: 'none!important' }}>
                    <Box className={style.consumption_box}>
                      <ButtonCB onClick={() => setOpen({...open, consumption: true})} startIcon={<ElectricBoltIcon />} disableElevation sx={{ padding: '0.3rem 0.7rem', fontWeight: 500 }} color='warning' variant='contained'>Consumo Adicionado</ButtonCB>
                    </Box>
                    {meses.map((m, index) => (
                      <TableCell 
                        key={index}
                        className={style.table_td}
                        sx={monthPermission(m) ? { backgroundColor: "#2778c42b" } : {backgroundColor: '#f0e6c7'}}>
                      <input
                      type="text"
                      label="Consumo Adicionado"
                      name={`${m}.consumo_adicionado`}
                      value={(state[m]?.consumo_adicionado && state[m]?.consumo_adicionado.replace('.', ',')) || ''}
                      autoComplete="off"
                      onChange={handleChange}
                      required
                      disabled />
                    </TableCell>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow className={style.table_row}>
                  <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Consumo Futuro Real</TableCell>
                  {meses.map((m, index) => (
                    <TableCell className={style.table_td} sx={{ fontWeight: 'bold' }} key={index}>{(Number(state[m]?.consumo_atual) + Number(state[m]?.consumo_adicionado)).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "") || '0,00'}</TableCell>
                  ))}
                </TableRow>
                <TableRow className={style.table_row}>
                  <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Créditos Sem Consumo Adicionado</TableCell>
                  {meses.map((m, index) => (
                    <TableCell className={style.table_td} key={index}>{(Number(state[m]?.geracao) - Number(state[m]?.consumo_atual)).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "") || '0,00'}</TableCell>
                  ))}
                </TableRow>
                <TableRow className={style.table_row}>
                  <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Créditos Com Consumo Adicionado</TableCell>
                  {meses.map((m, index) => (
                    <TableCell className={style.table_td} sx={{ fontWeight: 'bold' }} key={index}>{(Number(state[m]?.geracao) - (Number(state[m]?.consumo_atual) + Number(state[m]?.consumo_adicionado))).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "") || '0,00'}</TableCell>
                  ))}
                </TableRow>
                <TableRow sx={{margin: '1rem 0'}}>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Saldo Anual de Créditos Sem Consumo Adicionado kW/h</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{SumValue('CSC').toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Saldo Anual de Créditos Com Consumo Adicionado kW/h</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{SumValue('CCC').toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#34A853', color: "#fff" }}>Média de Consumo Mensal SEM Adicionado</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{SumValue('MSC').toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0e6c7' }}>Média de Consumo Futuro Real COM Adicionado</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid #ccc' }}>{SumValue('MCC').toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "")}</TableCell>
                </TableRow>
                <TableRow sx={{margin: '1rem 0'}}>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Média Mensal de Créditos Sem Adicionados kW/h</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{(SumValue('CSC')/12).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Média Mensal de Créditos Com Consumo Adicionado kW/h</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{(SumValue('CCC')/12).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#34A853', color: "#fff" }}>Gordura Média SEM Consumo Adicionado</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      { isNaN((SumValue('CSC')/12) / SumValue('MSC')) || ((SumValue('CSC')/12) / SumValue('MSC')) === Infinity ? 0 : (((SumValue('CSC')/12) / SumValue('MSC')) * 100).toFixed(2)
                      } %
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#2778c4', color: "#fff" }}>Gordura Média COM Consumo Adicionado</TableCell>
                    <TableCell className={style.alert} sx={{ border: '1px solid #ccc' }}>
                      {!checkPercentage().check ? 
                      <ReportProblemIcon  
                      className={style.print_off} color='error' /> : 
                      <NewTooltip title={checkPercentage().text} placement='top'>
                        <ThumbUpAltIcon 
                        className={style.print_off} sx={{ fill: checkPercentage().color }} />
                      </NewTooltip>}
                      {(((SumValue('CCC')/12) / SumValue('MCC')) * 100).toFixed(2)} %
                      </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '1rem' }} colSpan={13}></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#cccccc3d" }}>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '1.2rem', backgroundColor: '#ccc' }}>Observação:</TableCell>
                  <TableCell colSpan={12}>
                    <input
                    className={style.input_obs}
                    type='text'
                    value={observacao}
                    onChange={(e) => setObservacao(e.target.value)}
                     />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
            {/* <NewTooltip title={!checkPercentage().check ? 'Para liberar o sistema, a planilha precisa ter uma gordura acima de 1%' : ''} placement='top' followCursor> */}
          <div className={`${style.extra_container} ${style.print_off}`}>
            <img src={PainelSolar} alt='' />
              <Box sx={{ display: 'flex', gap: '1.3rem', alignItems: 'center' }}>
                <Badge badgeContent='Novo' color='error'>
                  <h2>Orçamento</h2>
                </Badge>
                {/* {!checkPercentage().check ?
                <LockIcon sx={{ fill: '#ccc' }} /> : null}             */}
              </Box>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Checkbox checked={showExtra} onChange={() => handleCheck(!showExtra)} />
                <h3>Ativar Orçamento</h3>
              </Box>
            {showExtra ? 
              <>
              <div className={`${style.extra_container_item} ${audio ? style.confirm_color : ''}`}>
              <h3>Mensagem de Voz <b>(Obrigatório)</b>{audio ? ' ✅' : null}</h3>
              <p>Envie um audio explicando o por que o Kit atende a necessidade dele.</p>
              <div className={`${style.sheets_buttons} ${style.print_off}`}>
                <AudioRecorder setAudio={setAudio} audio={audio} disabled={false} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}> 
                  <Button 
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    component="label" 
                    variant="contained" 
                    // disabled={!checkPercentage().check}
                    onChange={(e) => sendAudio(e)} 
                    startIcon={<AudioFileIcon />}>
                    Enviar Áudio
                    <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                  </Button>
                </Box>
              </div>
              </div>
              <div className={`${style.extra_container_item} ${googleEarth.complete ? style.confirm_color : ''}`}>
                  <h3>Imagem do Google Earth <b>(Obrigatório)</b>{googleEarth.complete ? ' ✅' : null}</h3>
                <p>Envie uma imagem via satélite do local de instalação que pode ser obtida através do <b>Google Earth</b>.</p>
                {googleEarth.file.length > 0 ?
                  <img className={style.img_upload} src={googleEarth.file} alt='Imagem do Google Earth' /> : null
                }
                <div className={`${style.sheets_buttons} ${style.print_off}`}>
                  <NewTooltip title='Clique aqui para abrir no endereço informado' placement='top'>
                    <IconButton onClick={() => window.open(`https://earth.google.com/web/@${customer.endereco?.lat},${customer.endereco?.lng},0a,0d,35y,0h,45t,0r`, '_blank')}>
                      <MapIcon />
                    </IconButton>
                  </NewTooltip>
                  <Button 
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    component="label" 
                    variant="contained" 
                    // disabled={!checkPercentage().check}
                    onChange={(e) => sendPhoto(e)} 
                    startIcon={<AddToPhotosIcon />}>
                    Enviar Foto
                    <VisuallyHiddenInput multiple={false} type="file" accept="image/png,image/jpeg" />
                  </Button>
                  {googleEarth.file.length > 0 ?
                    <NewTooltip title='Excluir Imagem' placement='top'>
                      <IconButton color='error' onClick={() => setGoogleEarth({ file: '', complete: null })}>
                          <DeleteRounded />
                      </IconButton>
                    </NewTooltip> : null 
                  }
                </div>
              </div>
              <Estimate sheet={customer} setProposalRef={setProposal} view={open.estimate} close={() => setOpen({...open, estimate: false})} cloneSheet={showExtra} />
              <div className={`${style.extra_container_item} ${proposal ? style.confirm_color : ''}`}>
                        <h3>Proposta <b>(Obrigatório)</b>{proposal ? ' ✅' : null}</h3>
                        <p>Clique no botão abaixo para selecionar a proposta do kit recomendado para o cliente.</p>
                        <ThemeProvider theme={theme}>
                          <Button
                            disableElevation
                            sx={{ textTransform: 'capitalize' }}
                            variant='contained'
                            color='success'
                            // disabled={!checkPercentage().check}
                            onClick={() => setOpen({...open, estimate: true})}
                            startIcon={<RequestQuoteRoundedIcon />}>
                            Enviar Proposta
                          </Button>
                        </ThemeProvider>
                      </div></> : null
            }
          </div>
            {/* </NewTooltip> */}
          <div className={`${style.button_footer} ${style.print_off}`}>
            <ThemeProvider theme={theme}>
                <Button disableElevation sx={{ textTransform: 'capitalize' }} type='submit' color='success' variant='contained'>Criar Planilha</Button>
                <ButtonCB onClick={() => setOpen({...open, consumption: true})} startIcon={<ElectricBoltIcon />} disableElevation sx={{ padding: '0.3rem 0.7rem', fontWeight: 500 }} color='warning' variant='contained'>Consumo Adicionado</ButtonCB>
                <Button sx={{ textTransform: 'capitalize' }} onClick={() => close('create')}>Voltar</Button>
            </ThemeProvider>
          </div>
          <Consumption view={open.consumption} close={() => setOpen({...open, consumption: false})} equipments={equipments} setEquipments={setEquipments} />
          </form>
          <div className={`${style.footer} ${style.print_off}`}>
          </div>
          </div>
        </div>
      </Dialog>
  )
}

export default Create
import React, { useState } from 'react'

import styles from '../style.module.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, TextField } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../../data/theme';

import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import LoadingButton from '@mui/lab/LoadingButton';
import Swal from 'sweetalert2';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';

import { auth, dataBase } from '../../../firebase/database';
import { doc, updateDoc } from 'firebase/firestore';

const UpdatePassword = ({ userRef, open, close }) => {
    const [pass, setPass] = useState({
        senha: '',
        confirmarSenha: ''
    });
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const updatePasswordWithReauthentication = async (novaSenha) => {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(userRef.email, userRef.senha);
    
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, novaSenha);
      };

    const onSubmit = (e) => {
        try {
            e.preventDefault();
            if(pass.senha !== pass.confirmarSenha) {
                return null;
            }
            Swal.fire({
                title: 'Atenção',
                text: `Você deseja alterar a sua Senha?`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
              }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoadingButton(true);
                    await updatePasswordWithReauthentication(pass.senha);

                    await updateDoc(doc(dataBase, 'Membros', userRef.uid), {
                        senha: pass.senha
                    }).then(() => {
                        Swal.fire({
                            title: 'Senha Alterada',
                            html: `A Senha foi alterada com sucesso.`,
                            icon: "success",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonColor: "#0eb05f",
                          })
                    })
                    setLoadingButton(false);
                    onClose();
                }
            })
        } catch (error) {
            console.log(error);
        }
        
    }

    const onClose = () => {
        close();
        setErrors({});
        setPass({
            senha: '',
            confirmarSenha: ''
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
            setPass({
                ...pass,
                [name]: value
            });
            setErrors({
                ...errors,
                [name]: ''
            })
    }

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const error = validateInput(name, value);
            setErrors({
                ...errors,
                [name]: error
            });
    }

    const validateInput = (name, value) => {
        let errorMsg = '';
    
        switch(name) {
            case 'senha': 
            if (!value) {
                errorMsg = 'É necessário informar uma senha.';
              }
            break;
            case 'confirmarSenha': 
            if (!value) {
                errorMsg = 'É necessário informar uma senha.';
            } else if(value !== pass.senha) {
                errorMsg = 'As senhas não conferem.';
            }
            break;
            default: 
                return null;
        }
        
        return errorMsg;
    }
    
  return (
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
    >
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Alterar Senha</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          Preencha os campos abaixo para alterar a <b>Senha</b>.
        </DialogContentText>
        <form className={styles.form_profile} onSubmit={onSubmit}>
        <ThemeProvider theme={theme}>
        <TextField
            name="senha"
            fullWidth
            label="Nova Senha"
            value={pass.senha}
            onChange={handleChange} 
            onBlur={handleBlur}
            error={!!errors.senha}
            inputProps={{ 
                minLength: 6
             }}
            FormHelperTextProps={{
                component: (item) => {
                    return (
                    <span className={`${item.className} ${styles.error}`}>
                        <ErrorIcon sx={{ fontSize: '1.2rem' }} /> {item.children}
                    </span>
                    );
                },
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }}
            helperText={errors.senha}
            required
        />
        <TextField
            name="confirmarSenha"
            fullWidth
            label="Confirme sua senha"
            value={pass.confirmarSenha}
            onChange={handleChange} 
            onBlur={handleBlur}
            error={!!errors.confirmarSenha}
            FormHelperTextProps={{
                component: (item) => {
                    return (
                    <span className={`${item.className} ${styles.error}`}>
                        <ErrorIcon sx={{ fontSize: '1.2rem' }} /> {item.children}
                    </span>
                    );
                },
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }}
            helperText={errors.confirmarSenha}
            required
        />
          </ThemeProvider>
          <ThemeProvider theme={theme}>
          <DialogActions sx={{ justifyContent: 'center' }}>
          <LoadingButton disableElevation
           sx={{ textTransform: 'capitalize' }}
           variant="contained"
           color="success"
           loading={loadingButton}
           type='submit'>Confirmar</LoadingButton>
          <Button 
            onClick={onClose}
            disableElevation
            color="error"
            disabled={loadingButton}
            sx={{ textTransform: 'capitalize' }}
            variant="contained">Cancelar</Button>
        </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default UpdatePassword
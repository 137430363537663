import { useState, useRef, useEffect } from 'react'
import styles from './styles.module.scss';
import { Button, IconButton, TextField, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import SlickSlider from "react-slick";
import { ThemeProvider, styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link, useNavigate } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import CurrencyInput from "react-currency-input-field";
import { usePlacesWidget } from "react-google-autocomplete";
import moment from 'moment';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';

import { theme } from '../../../data/theme';
import { dataBase } from "../../../firebase/database";
import { addDoc, collection, doc, getDocs, serverTimestamp, updateDoc } from "firebase/firestore";
import { KeyMaps } from '../../../data/Data';
import Swal from "sweetalert2";
import useUpload from '../../../hooks/useUploadCleaner';
import { PatternFormat } from 'react-number-format';

const CreateWork = ({isLoaded, userRef, visits, sellers }) => {
  const [info, setInfo] = useState({
    nome: '',
    altura: '',
    condominio: '',
    consultora: '',
    endereco: '',
    telefone: '',
    telha: ''
  });
  const [fachada, setFachada] = useState([]);
  const [telhado, setTelhado] = useState([]);
  const [telhadoVideo, setTelhadoVideo] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [visitas, setVisitas] = useState([]);
  const { uploadImages } = useUpload();
  const [newInput, setNewInput] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [steps, setSteps] = useState([]);
  const [consultoras, setConsultoras] = useState([]);
  const navigate = useNavigate();
  const slider = useRef(null);
  const slider2 = useRef(null);
  const slider3 = useRef(null);
  const [position, setPosition] = useState({
    lat: -23.109655741119823,
    lng: -47.71509175415125
  });
  const filter = createFilterOptions();

  const { ref } = usePlacesWidget({
    apiKey: KeyMaps,
    inputAutocompleteValue: "country",
    onPlaceSelected: (place) => {
      const address = place.formatted_address;
      const cityRef = place.address_components.find(ref => ref.types[0] === 'administrative_area_level_2');
      setInfo({
        ...info,
        cidade: cityRef.long_name,
        endereco: address.substring(0, address.length - 19),
      })
      setPosition({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng()
      })
    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "br" },
      fields: ["formatted_address", "address_components", "geometry.location"],
    },
  });

  const onMarkerDragEnd = (event) => {
    const newPos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setPosition(newPos);
  };

  useEffect(() => {
    if(userRef) {
      setInfo({...info, consultora: userRef.nome_ref});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userRef])

  useEffect(() => {
    if(visits) {
      const uniqueNames = new Set();
        const sheets = visits.reduce((accumulator, data, index) => {
            if (data.categoria === 'comercial_tecnica' && !uniqueNames.has(data.cliente)) {
              uniqueNames.add(data.cliente);
              accumulator.push({
                nome: data.cliente,
                telefone: data.preData?.telefone,
                endereco: data.endereco,
                consultora: data.consultora,
                condominio: data.condominio,
                cidade: data.cidade,
                id: data.id
              });
            }
            return accumulator;
          }, []);
      setVisitas(sheets.sort((a, b) => a.nome.localeCompare(b.nome)));
    }
  },[visits])

  useEffect(() => {
    if(consultoras) {
      setConsultoras(sellers.filter(obj => obj.nome === 'Pós-Venda').map(obj => obj.nome_ref));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sellers])

  const handleInfo = async (ref) => {
    const querySnapshot = await getDocs(collection(dataBase, "Visitas", ref.id, 'Tecnica'));
    const steps = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setSteps(steps);

    const step1 = steps.find(data => data.etapa === 1);
    const step2 = steps.find(data => data.etapa === 2);
    const step3 = steps.find(data => data.etapa === 3);
    const step8 = steps.find(data => data.etapa === 8);
    let telhadoTipo = '';
    if(step1) {
      setPosition(step1.GPS_padrao);
      setFachada(step1.fachada);
    }
    if(step2) {
      setTelhado(step2.telhadoPerto);
      setTelhadoVideo(step2.telhadoVideo);
    }
    if(step8) {
      setModulos(step8.modulos);
    }
    if(step3) {
      const telhas = step3.telhado.map(data => data.tipo);
      telhadoTipo = telhas.join(', ');
    }

    if(ref.endereco) {
      setNewInput(false);
    } else {
      setNewInput(true);
    }

    setInfo({
      ...info,
      nome: ref.nome,
      consultora: userRef && userRef.nome_ref,
      telefone: ref.telefone,
      endereco: ref.endereco,
      cidade: ref.cidade,
      condominio: ref.condominio,
      id: ref.id,
      altura: step3 ? step3.altura_telhado : '',
      telha: telhadoTipo
    })
  }

  console.log(info)

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const settingsPhotos = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000
  };

  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'fachada') {
      if(fachada) {
        setFachada([...fachada, ...files]);
      } else {
        setFachada(files);
      }
    } else if(type === 'telhado') {
      if(telhado) {
        setTelhado([...telhado, ...files]);
      } else {
        setTelhado(files);
      }
    } else if(type === 'modulos') {
      if(modulos) {
        setModulos([...modulos, ...files]);
      } else {
        setModulos(files);
      }
    } else if(type === 'telhadoVideo') {
      if(telhadoVideo) {
        setTelhadoVideo([...telhadoVideo, ...files]);
      } else {
        setTelhadoVideo(files);
      }
    }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
     switch (type) {
      case 'fachada':
        setFachada(newPhotos);
        break;
      case 'telhado':
        setTelhado(newPhotos);
        break;
      case 'modulos':
        setModulos(newPhotos);
        break;
      case 'telhadoVideo':
        setTelhadoVideo(newPhotos);
        break;
        default:
     }
    }
  }

  
  const isArrayOnlyStrings = (arr) => {
    if (arr.length === 0) {
        return false;
    }

    let hasString = false;

    for (let element of arr) {
        if (typeof element !== 'string') {
            return false;
        } else {
            hasString = true;
        }
    }

    return hasString;
}



const onSubmit = async (e) => {
  try {
    e.preventDefault();
    console.log(fachada);
    Swal.fire({
      title: 'Cadastro',
      html: `Você deseja cadastrar uma nova <b>Limpeza?</b>`,
      icon: "question",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.isConfirmed) {

        await addDoc(collection(dataBase, "Limpezas"),{
          ...info,
          ...position,
          createAt: serverTimestamp(),
          fachada: isArrayOnlyStrings(fachada) ? fachada : [],
          telhado: isArrayOnlyStrings(telhado) ? telhado : [],
          modulos: isArrayOnlyStrings(modulos) ? modulos : [],
          telhadoVideo: isArrayOnlyStrings(telhadoVideo) ? telhadoVideo : [],
          analista: {
            id: userRef && userRef.id,
            nome: userRef && userRef.nome_ref
          },
          jornada: {
            etapa: 'Orçamento',
            index: 0,
            sigla: 'O',
            cor: '#dcdbad',
            data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
          }
        }).then(async (result) => {
          let visita = {};
          let ref = {};

          if(!isArrayOnlyStrings(fachada)) {
            const resultFachada = await uploadImages(fachada, 'fachada', 0);
            visita.fachada = resultFachada.map(object => object.downloadURL);
            ref.fachada = resultFachada.map(object => object.fullPath);
          }

          if(!isArrayOnlyStrings(telhado)) {
            const resultTelhado = await uploadImages(telhado, 'telhado', 1);
            visita.telhado = resultTelhado.map(object => object.downloadURL);
            ref.telhado = resultTelhado.map(object => object.fullPath);
          }

          if(!isArrayOnlyStrings(modulos)) {
            const resultModulos = await uploadImages(modulos, 'modulos', 2);
            visita.modulos = resultModulos.map(object => object.downloadURL);
            ref.modulos = resultModulos.map(object => object.fullPath);
          }

          if(!isArrayOnlyStrings(telhadoVideo)) {
            const resultTelhadoVideo = await uploadImages(telhadoVideo, 'telhadoVideo', 3);
            visita.telhadoVideo = resultTelhadoVideo.map(object => object.downloadURL);
            ref.telhadoVideo = resultTelhadoVideo.map(object => object.fullPath);
          }

          await updateDoc(doc(dataBase, 'Limpezas', result.id), {
            ref: {
              ...ref
            },
            ...visita
          })

            Swal.fire({
              title: 'Cadastro',
              html: `A <b>Limpeza</b> foi cadastrada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#111",
            }).then(() => {
              navigate('/limpeza')
            })
          })
        }

    })

  } catch (error) {

  }
}

  return (
      <div className={styles.panel}>
        <div className={styles.panel_header}>
          <IconButton sx={{ position: 'absolute', left: 0 }} onClick={() => navigate(`/limpeza`)}><ArrowCircleLeftOutlinedIcon sx={{ fontSize: '50px' }} /></IconButton>
          <div className={styles.panel_header_title}>
            <h1>Cadastrar Limpeza</h1>
          </div>  
        </div>
      <form onSubmit={onSubmit} className={styles.panel_content}>
        <h2>Dados do Cliente</h2>
        <div className={styles.panel_content_box}>
          <div className={styles.panel_content_title}>
            <h3>Informações</h3>
          </div>
          <div className={styles.panel_content_input}>
          <Autocomplete
            value={info.nome}
            fullWidth
            sx={{ alignItems: 'center', marginTop: '8px' }}
            onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setInfo(newValue);
              console.log('a')
            } else if (newValue && newValue.inputValue) {
              setInfo(newValue.inputValue);
            } else {
                handleInfo(newValue)
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.nome);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                nome: `Adicionar "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable={true}
          id="free-solo-with-text-demo"
          options={visitas}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Opção Regular
            return option.nome;
          }}
          renderOption={(props, option) => <li {...props}>{option.nome}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField {...params}
            label="Nome"
            type="search"
            />
          )}
        />
          <PatternFormat
            value={info.telefone || ''}
            onChange={(e) => setInfo({...info, telefone: e.target.value})}
            format="## (##) ##### ####"
            mask="_"
            style={{ marginTop: '0.5rem' }}
            fullWidth
            customInput={TextField}
            placeholder="00 (00) 00000 0000"
            label="Telefone"
            minLength={9}
            variant="outlined"
            required
          />
          </div>
          <div className={styles.panel_content_input}>
          <FormControl fullWidth margin='dense'>
          <InputLabel id="disjuntor">Analista</InputLabel>
            <Select
              labelId='disjuntor'
              label="Analista"
              value={info.consultora ?? ''}
              disabled={userRef && userRef.nome === "Pós-Venda" ? true : false}
              onChange={(e) => setInfo({...info, consultora: e.target.value})}
              required>
              {consultoras && consultoras.map((data, index) => (
                <MenuItem key={index} value={data}>{data}</MenuItem>
              ))}     
              </Select>
          </FormControl>
          <CurrencyInput
              customInput={TextField}
              style={{ margin: '0.8rem 0rem 0.7rem 0' }}
              label="Valor do Serviço"
              placeholder="R$ 00"
              intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              onValueChange={(value) => setInfo({...info, valor: value})}
              decimalsLimit={2}
              value={info.valor || ''}
              required
              fullWidth
              />
          </div>
          <div className={styles.panel_content_title}>
            <h3>Endereço</h3>
          </div>
          <div className={styles.panel_content_title}>
          {isLoaded &&
            <GoogleMap mapContainerStyle={{ width: '100%', height: '300px' }} zoom={18} center={{ lat: position.lat, lng: position.lng }}>
              {position && <MarkerF  position={{ lat: position.lat, lng: position.lng }} draggable={true} onDragEnd={onMarkerDragEnd} />}
            </GoogleMap>
          }
          </div>
          <div className={styles.panel_content_input}>
            {newInput ? 
            <TextField
              margin="dense"
              inputRef={ref}
              label="Rua / Avenida"
              fullWidth
              required
              variant="outlined"
            /> : 
            <TextField
              margin="dense"
              value={info.endereco || ''}
              // onChange={(e) => setInfo({ ...info, endereco: e.target.value })}
              onClick={() => setNewInput(true)}
              InputLabelProps={{ shrink: true }}
              label="Rua / Avenida"
              fullWidth
              required
              variant="outlined"
            />
            }
            <TextField
              margin="dense"
              id="name"
              label="Cidade"
              type="text"
              value={info.cidade ?? ''}
              onChange={(e) => setInfo({ ...info, cidade: e.target.value })}
              fullWidth
              required
              variant="outlined"
              />
            <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
            <InputLabel id="condominio">Mora em Condominio?</InputLabel>
            <Select
            labelId='condominio'
            label="Mora em Condominio?"
            value={info.condominio ?? ''}
            onChange={(e) => setInfo({ ...info, condominio: e.target.value })}
            required>
              <MenuItem value="Sim ✅">Sim</MenuItem>
              <MenuItem value="Não ❌">Não</MenuItem>
            </Select>
          </FormControl>
            </div>
          <div className={styles.panel_content_title}>
            <h3>Telhado</h3>
          </div>
          <div className={styles.panel_content_input}>
          <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
            <InputLabel id="altura">Altura da Casa</InputLabel>
            <Select
            labelId='altura'
            label="Altura da Casa"
            value={info.altura || ''}
            onChange={(e) => setInfo({ ...info, altura: e.target.value })}
            required>
              <MenuItem value="Casa Térrea">Casa Térrea</MenuItem>
              <MenuItem value="Sobrado">Sobrado</MenuItem>
              <MenuItem value="Triplex">Triplex</MenuItem>
              <MenuItem value="Prédio">Prédio</MenuItem>
            </Select>
          </FormControl>
            <TextField
              margin="dense"
              id="name"
              label="Tipo de Telha"
              type="text"
              value={info.telha ?? ''}
              onChange={(e) => setInfo({ ...info, telha: e.target.value })}
              fullWidth
              required
              variant="outlined"
            />
          </div>
        </div>
        <h2>Fotos</h2>
        <div className={styles.panel_content_box}>
          <div className={styles.panel_content_title}>
            <h3>Fachada</h3>
          </div>
          <div className={styles.panel_content_photos}>
          {fachada.length > 0 ?
          <div className={styles.slider_container}>
            {fachada.length > 1 ? 
              <IconButton onClick={() => slider.current.slickPrev()}>
                <ArrowCircleLeftOutlinedIcon sx={{ fontSize: '35px' }} />
              </IconButton> : null
            }
            <SlickSlider ref={slider} {...settingsPhotos} className={styles.slider}>
                      {fachada && fachada.map((img, index) => (
                        <div key={index} className={styles.photos}>
                          <img rel="preconnect" src={typeof(img) === 'object' ? img.file : img} alt="" />
                          <p>{typeof(img) === 'object' ? img.complete.name : `Fachada ${index + 1} [Visita]`}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, fachada, 'fachada')}>Excluir</Button>
                        </div>
                      ))}
              </SlickSlider>
              {fachada.length > 1 ? 
                <IconButton onClick={() => slider.current.slickNext()}>
                <ArrowCircleRightOutlinedIcon sx={{ fontSize: '35px' }} />
                </IconButton> : null
              }
            </div> : null
            }
            {(isArrayOnlyStrings(fachada)) ? 
                null : <Button 
                  disableElevation
                  sx={{ textTransform: 'capitalize' }}
                  component="label" 
                  variant="contained" 
                  onChange={(e) => sendPhoto(e, 'fachada')} 
                  startIcon={<AddAPhotoOutlinedIcon />}>
                  Adicionar
                  <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
                </Button>
            }
          </div>
        </div>
        <div className={styles.panel_content_box}>
          <div className={styles.panel_content_title}>
            <h3>Telhado</h3>
          </div>
          <div className={styles.panel_content_photos}>
          {telhado.length > 0 ?
          <div className={styles.slider_container}>
            {telhado.length > 1 ? 
              <IconButton onClick={() => slider2.current.slickPrev()}>
                <ArrowCircleLeftOutlinedIcon sx={{ fontSize: '35px' }} />
              </IconButton> : null
            }
            <SlickSlider ref={slider2} {...settingsPhotos} className={styles.slider}>
                      {telhado && telhado.map((img, index) => (
                        <div key={index} className={styles.photos}>
                          <img rel="preconnect" src={typeof(img) === 'object' ? img.file : img} alt="" />
                          <p>{typeof(img) === 'object' ? img.complete.name : `Telhado ${index + 1} [Visita]`}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, telhado, 'telhado')}>Excluir</Button>
                        </div>
                      ))}
              </SlickSlider>
              {telhado.length > 1 ? 
                <IconButton onClick={() => slider2.current.slickNext()}>
                <ArrowCircleRightOutlinedIcon sx={{ fontSize: '35px' }} />
                </IconButton> : null
              }
            </div> : null
            }
            {(isArrayOnlyStrings(telhado)) ? 
                null : <Button 
                  disableElevation
                  sx={{ textTransform: 'capitalize' }}
                  component="label" 
                  variant="contained" 
                  onChange={(e) => sendPhoto(e, 'telhado')} 
                  startIcon={<AddAPhotoOutlinedIcon />}>
                  Adicionar
                  <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
                </Button>
            }
          </div>
        </div>
        <div className={styles.panel_content_box}>
          <div className={styles.panel_content_title}>
            <h3>Módulos</h3>
          </div>
          <div className={styles.panel_content_photos}>
          {modulos.length > 0 ?
          <div className={styles.slider_container}>
            {modulos.length > 1 ? 
              <IconButton onClick={() => slider3.current.slickPrev()}>
                <ArrowCircleLeftOutlinedIcon sx={{ fontSize: '35px' }} />
              </IconButton> : null
            }
            <SlickSlider ref={slider3} {...settingsPhotos} className={styles.slider}>
                      {modulos && modulos.map((img, index) => (
                        <div key={index} className={styles.photos}>
                          <img rel="preconnect" src={typeof(img) === 'object' ? img.file : img} alt="" />
                          <p>{typeof(img) === 'object' ? img.complete.name : `Módulos ${index + 1} [Visita]`}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, modulos, 'modulos')}>Excluir</Button>
                        </div>
                      ))}
              </SlickSlider>
              {modulos.length > 1 ? 
                <IconButton onClick={() => slider3.current.slickNext()}>
                <ArrowCircleRightOutlinedIcon sx={{ fontSize: '35px' }} />
                </IconButton> : null
              }
            </div> : null
            }
            {(isArrayOnlyStrings(modulos)) ? 
                null : <Button 
                  disableElevation
                  sx={{ textTransform: 'capitalize' }}
                  component="label" 
                  variant="contained" 
                  onChange={(e) => sendPhoto(e, 'modulos')} 
                  startIcon={<AddAPhotoOutlinedIcon />}>
                  Adicionar
                  <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
                </Button>
            }
          </div>
        </div>
        <h2>Vídeos</h2>
        <div className={styles.panel_content_box}>
          <div className={styles.panel_content_title}>
            <h3>Telhado</h3>
          </div>
          <div className={styles.panel_content_photos}>
          {telhadoVideo.length > 0 ?
          <div className={styles.slider_container}>
            {telhadoVideo.length > 1 ? 
              <IconButton onClick={() => slider.current.slickPrev()}>
                <ArrowCircleLeftOutlinedIcon sx={{ fontSize: '35px' }} />
              </IconButton> : null
            }
            <SlickSlider ref={slider} {...settingsPhotos} className={styles.slider}>
                      {telhadoVideo && telhadoVideo.map((img, index) => (
                        <div key={index} className={styles.photos}>
                          <video src={typeof(img) === 'object' ? img.file : img} width='90%' controls muted playsInline />
                          <p>{typeof(img) === 'object' ? img.complete.name : `Telhado ${index + 1} [Visita]`}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, telhadoVideo, 'telhadoVideo')}>Excluir</Button>
                        </div>
                      ))}
              </SlickSlider>
              {telhadoVideo.length > 1 ? 
                <IconButton onClick={() => slider.current.slickNext()}>
                <ArrowCircleRightOutlinedIcon sx={{ fontSize: '35px' }} />
                </IconButton> : null
              }
            </div> : null
            }
            {(isArrayOnlyStrings(telhadoVideo)) ? 
                null : <Button 
                  disableElevation
                  sx={{ textTransform: 'capitalize' }}
                  component="label" 
                  variant="contained" 
                  onChange={(e) => sendPhoto(e, 'telhadoVideo')} 
                  startIcon={<AddAPhotoOutlinedIcon />}>
                  Adicionar
                  <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
                </Button>
            }
          </div>
        </div>
        <div className={styles.button_footer}>
        <ThemeProvider theme={theme}>
          <Button 
            disableElevation 
            color='secondary'
            variant={'contained'}
            type='submit'
            style={{ textTransform: 'capitalize' }}>
              Cadastrar
          </Button>
          <Link to={'/limpeza'}>
            <Button 
              disableElevation 
              variant={'outlined'}
              style={{ textTransform: 'capitalize' }}>
                Cancelar
            </Button>
          </Link>
        </ThemeProvider>
        </div>
      </form>
    </div>
  )
}

export default CreateWork
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, styled, TextField, ThemeProvider, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import { FaFileUpload } from "react-icons/fa";
import { Link } from 'react-router-dom';

import CurrencyInput from "react-currency-input-field";
import NewTooltip from '../../../components/Tooltip';
import useViewDocBox from '../../../hooks/useViewDocBox';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import { MdOutlineSendToMobile } from "react-icons/md";

import { theme } from '../../../data/theme';
import { VisuallyHiddenInput } from '../../../components/InputFile';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import { toast } from '../../../components/Toast';
import Swal from 'sweetalert2';
import useUploadPhotos from '../../../hooks/useUploadPhotos';
import moment from 'moment';
import Loading from '../../../components/Loading';
import { capitalizeString } from '../../../functions/capitalizeString';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AudioRecorder from '../../../components/AudioRecorder/Index';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { generateId } from '../../../functions/generateId';
import CustomAudioPlayerFixed from '../../../components/AudioPlayerFixed/Index';
import axios from 'axios';

const SendDoc = ({ userRef, customer, view, close, indexProposal, type, simulationRef, setSimulationRef, sheetRef }) => {
    const { uploadImages } = useUploadPhotos();
    const [nameDocument, setNameDocument] = useState(null);
    const [name, setName] = useState(null);
    const [cpfCNPJ, setCpfCNPJ] = useState(null);
    const [situation, setSituation] = useState('');
    const [audio, setAudio] = useState('');
    const [document, setDocument] = useState([]);
    const [loading, setLoading] = useState(false);
    const [simulation, setSimulation] = useState(null);
    const [activeFinancing, setActiveFinancing] = useState(false);
    const [carencia, setCarencia] = useState('');
    const inputRef = useRef(null);
    const [installments, setInstallments] = useState([
        {
            quantidade: '',
            valor: ''
        }
    ]);

    const { viewDoc } = useViewDocBox();
    const DocsItems = styled(Box)(({ color }) => ({
        padding: '0.5rem 1rem',
        borderRadius: '5px',
        backgroundColor: color || "#fff",
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid rgba(0, 0, 0, 0.12)',
    }));
    const optionsSimulation = [
        { nome: 'Banco CorSolar' },
        { nome: 'Banco BV' },
        { nome: 'BTG Pactual' },
        { nome: 'Caixa Econômica Federal' },
        { nome: 'Cartão de Crédito' },
        { nome: 'EOS' },
        { nome: 'FGTS' },
        { nome: 'Losango' },
        { nome: 'Santander' },
        { nome: 'Sicred' },
        { nome: 'Sicoob' },
        { nome: 'Sol Agora' },
        { nome: 'Solfacil' },
        { nome: 'SolPlace' },
    ];
    const optionsSituation = [
        { nome: 'Aprovado' },
        { nome: 'Negado' },
        { nome: 'Aguardando' },
    ];

    console.log(sheetRef);

    const closeBox = () => {
        close();
        setTimeout(() => {
            setNameDocument('');
            setDocument([]);
            setSimulationRef(null);
            setActiveFinancing(false);
            setAudio(null);
            setSimulation(null);
            setName('');
            setCpfCNPJ('');
            setSituation('');
            setInstallments([
                {
                    quantidade: '',
                    valor: ''
                }
            ]);
        }, 500);
    }

    useEffect(() => {
        if (simulationRef) { // Caso o cliente já tenha uma simulação vinculada ao documento
            const simulationsRef = customer.proposta[indexProposal].simulacoes;
            if (simulationsRef) {
                const newSimulation = simulationsRef.find(data => data.id === simulationRef.simulation_id);
                console.log(newSimulation);
                setSimulation(newSimulation);
                setNameDocument(newSimulation.banco);
                setName(newSimulation.nome);
                setCpfCNPJ(newSimulation.cpfCNPJ);
                setSituation(newSimulation.situacao);
                setActiveFinancing(true);
                setCarencia(newSimulation.carencia);
                setInstallments(newSimulation.parcelas);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationRef, indexProposal])

    // console.log(customer?.proposta[indexProposal]?.simulacoes)
    // console.log(simulation)

    const handleUpload = async () => {
        const storage = getStorage();
        if (!audio) {
            return;
        }

        try {
            // Upload do áudio
            let filesRef = {};

            if (audio) {
                const audioRef = ref(storage, `Clientes/${customer.id}/Mensagem-${Date.now()}.webm`);
                const audioSnapshot = await uploadBytes(audioRef, audio.complete);
                const audioURL = await getDownloadURL(audioSnapshot.ref);
                console.log('Áudio enviado com sucesso! URL:', audioURL);
                filesRef = {
                    url: audioURL,
                    ref: audioSnapshot.ref.fullPath
                };
            }

            return filesRef;

        } catch (error) {
            console.error('Erro ao enviar arquivos:', error);
        }
    };

    const saveDoc = async () => {

        try {
            const result = await Swal.fire({
                title: 'Atenção',
                text: `Você deseja enviar o Documento?`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#05a937",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })

            if (result.isConfirmed) {
                setLoading(true);
                let file = {
                    url: [],
                    ref: []
                }
                const resultGeradora = document ? await uploadImages(document, nameDocument, 0, customer.id, 'Clientes') : null;
                if (resultGeradora) {
                    file = resultGeradora.map(object => ({
                        url: object.downloadURL,
                        ref: object.fullPath
                    }));
                }
                const proposals = customer.proposta;
                const fatura = proposals[indexProposal].documentos;
                const proposalRef = proposals[indexProposal];

                // Inicializa o array de documentos se necessário
                if (!fatura[type]) {
                    fatura[type] = [];
                }

                if (!proposalRef.id) {
                    proposalRef.id = generateId(proposalRef.nome);
                }

                const payments = customer.pagamentos || [];
                const simulations = proposalRef.simulacoes || [];
                const simulationId = generateId(nameDocument);
                let simulationData = {
                    nome: name,
                    cpfCNPJ: cpfCNPJ,
                    banco: nameDocument,
                    situacao: situation,
                    id: simulationId
                };
                if (activeFinancing) {
                    simulationData = {
                        ...simulationData,
                        carencia: carencia,
                        parcelas: installments,
                        mensagemVoz: await handleUpload()
                    }
                }

                // else {
                //     // payments.push({ metodo: nameDocument, situacao: situation });
                //     // fatura[type].push({...file, simulation_id: simulationId });
                // }

                simulations.push(simulationData); // Armazena as simulações
                proposalRef.simulacoes = simulations; // Envia dados para 'Proposta'

                payments.push({ // Envia dados para 'Pagamentos'
                    metodo: nameDocument,
                    situacao: situation,
                    orcamento_id: proposalRef.id,
                    simulacao_id: simulationId
                });

                fatura[type].push({ ...file[0], simulation_id: simulationId });

                // Adiciona o novo documento ao array
                console.log(fatura);

                const proposalData = {
                    proposta: proposals,
                    pagamentos: payments,
                    historico: [
                        ...customer.historico,
                        {
                            data: moment().format('DD/MM/YYYY - HH:mm'),
                            evento: 'Orçamento Alterado',
                            text: `O documento <b>${nameDocument}</b> foi ADICIONADO na aba <b>${capitalizeString(type)}</b> do orçamento <b>${proposalRef.nome}</b>.`,
                            usuario_id: userRef?.id,
                            usuario_nome: userRef?.nome
                        },
                        {
                            data: moment().format('DD/MM/YYYY - HH:mm'),
                            evento: 'Pagamento Alterado',
                            text: `<b>[Adicionado]:</b> ${nameDocument} - ${situation}.`,
                            usuario_id: userRef?.id,
                            usuario_nome: userRef?.nome
                        }
                    ]
                }

                await updateDoc(doc(dataBase, 'Clientes', customer.id), proposalData);
                // Exiba uma mensagem de sucesso
                await Swal.fire({
                    title: 'Documentos',
                    html: `O Documento foi enviado com sucesso.`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                })
                setLoading(false);
                closeBox();
            }

        } catch (error) {
            setLoading(false);
            console.log(error)
            toast({ icon: 'error', text: `Erro ao salvar o documento. Erro: ${error}` });
        }
    }
    const sendSimulation = async () => {

        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja enviar a <b>Simulação</b> ao Cliente?`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#05a937",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })

            if (result.isConfirmed) {
                setLoading(true);

                const proposals = customer.proposta;
                const sheets = customer.planilha;
                const proposalRef = proposals[indexProposal];
                const newSimulation = simulation;
                const sheet = sheets.find(data => data.orcamento_solicitado === sheetRef.orcamento_solicitado || data?.orcamento_id === proposalRef?.id);
                const sheetIndex = sheets.findIndex(data => data.orcamento_solicitado === sheetRef.orcamento_solicitado || data?.orcamento_id === proposalRef?.id);

                if (!proposalRef.id) {
                    proposalRef.id = generateId(proposalRef.nome);
                }
                sheet.simulacao_enviada = true;
                newSimulation.status = 'Enviado';
                proposalRef.simulacao_enviada = true;

                const proposalData = {
                    proposta: proposals,
                    planilha: sheets,
                    historico: [
                        ...customer.historico,
                        {
                            data: moment().format('DD/MM/YYYY - HH:mm'),
                            evento: 'Simulação Enviada',
                            text: `A <b>simulação</b> do <b>${simulation.banco}</b> foi enviado para o cliente.`,
                            usuario_id: userRef?.id,
                            usuario_nome: userRef?.nome
                        }
                    ]
                }

                await axios.post('https://n8n.corpbrasil.cloud/webhook/a213344f-021f-4220-ae1d-f710cb332151', {
                    nome: customer.nome,
                    telefone: customer.telefone,
                    id: `${customer.id}-${sheetIndex}-simulacao`,
                    chave: situation === "Negado" ? "Simulação Negado" : "Simulação",
                    cidade: customer.cidade,
                    analista: userRef?.nome,
                    id_agenda: customer.id
                })

                await updateDoc(doc(dataBase, 'Clientes', customer.id), proposalData);

                await Swal.fire({
                    title: 'Simulação',
                    html: `A Simulação foi enviada com sucesso ao cliente.`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                })
                setLoading(false);
                closeBox();
            }

        } catch (error) {
            setLoading(false);
            console.log(error)
            toast({ icon: 'error', text: `Erro ao salvar o documento. Erro: ${error}` });
        }
    }

    const sendFile = async (e) => {
        const ref = e.target.files;
        const files = [];
        Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
        if (document) {
            setDocument([...document, ...files])
        } else {
            setDocument(files);
        }
    }

    const deleteFile = (index) => {
        if (index >= 0 && index < document.length) {
            const newPhotos = [...document];
            newPhotos.splice(index, 1);
            setDocument(newPhotos);
        }
    }

    
    const hasEmptyOrUndefinedProperty = (objects) =>
        objects.some(obj =>
            Object.values(obj).some(value => value === "" || value === undefined || value === null)
        );

    console.log(installments)

    const checkButton = () => {
        if (type === 'simulacoes') {
            if (situation === 'Aprovado' && activeFinancing) {
                if (document?.length === 0 || !document || nameDocument?.length === 0 ||
                    situation?.length === 0 || carencia?.length === 0 || hasEmptyOrUndefinedProperty(installments) || !audio) return false

            } else {
                if ((!simulationRef && document?.length === 0) || nameDocument?.length === 0 || situation?.length === 0 || name?.length < 3 || cpfCNPJ?.length < 14) return false
            }
        } else if (type === 'enviar simulação') {
            if (name?.length < 3 || cpfCNPJ?.length < 14) return false
        } else {
            if (document?.length === 0 || !document || nameDocument?.length === 0) return false
        }
        return true;
    }

    const checkFinancing = () => {
        if (situation === 'Aprovado' && nameDocument !== 'Cartão de Crédito' && nameDocument !== 'FGTS') {
            return true;
        }
        return false;
    }

    const handleInstallments = (value, index, field) => {
        const currentInstallments = installments;
        const newInstallments = [...currentInstallments];
        const newInstallment = { ...newInstallments[index], [field]: value };
        newInstallments[index] = newInstallment;
        return setInstallments(newInstallments);
    }

    const addNewInstallments = () => {
        const newInstallment = {
            quantidade: '',
            valor: '',
        };

        setInstallments(currentInstallment => [...(currentInstallment ?? []), newInstallment]);
    };

    const removeInstallment = async (index) => {
        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja excluir a <b>Parcela?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })
            if (result.isConfirmed) {
                const newInstallment = installments.filter((_, i) => i !== index);
                setInstallments(newInstallment);
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o pagamento.', error: error });
        }
    };

    const sendAudio = (e) => {
        const audio = e.target.files[0];
        setAudio({ file: URL.createObjectURL(audio), complete: audio });
        console.log(audio)
    }

    const mask = (v) => {
        v = v.replace(/\D/g, "")
    
        if (v.length <= 11) {
          v = v.replace(/(\d{3})(\d)/, "$1.$2")
          v = v.replace(/(\d{3})(\d)/, "$1.$2")
          v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        } else {
          v = v.substring(0, 14); // limita em 14 números
          v = v.replace(/^(\d{2})(\d)/, "$1.$2")
          v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
          v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
          v = v.replace(/(\d{4})(\d)/, "$1-$2")
        }
    
        return v
      }

    return (
        <Dialog
            sx={{ overflow: 'hidden' }}
            open={view}
            fullWidth
            maxWidth="md">
            <IconButton
                aria-label="close"
                onClick={closeBox}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            ><CloseIcon /></IconButton>
            <DialogTitle align='center'>{capitalizeString(type === 'simulacoes' || type === 'enviar simulação' ? 'Simulações' : type)}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Loading view={loading} />
                {simulationRef ?
                    <DialogContentText align='center'>
                        ⚠️ Revise as informações antes de enviar ⚠️
                    </DialogContentText> :
                    <DialogContentText align='center'>
                        Informe o <b>nome</b> e o <b>PDF/Imagem</b> do documento.
                    </DialogContentText>
                }
                <Grid container spacing={1}>
                    {type === 'simulacoes' || type === 'enviar simulação' ?
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label='Nome Completo'
                                    sx={{ backgroundColor: "#fff" }}
                                    value={name || ''}
                                    onChange={(e) => setName(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label='CPF / CNPJ'
                                    sx={{ backgroundColor: "#fff" }}
                                    value={cpfCNPJ || ''}
                                    onChange={(e) => setCpfCNPJ(mask(e.target.value))} />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Autocomplete
                                    value={nameDocument}
                                    fullWidth
                                    sx={{ alignItems: 'center', backgroundColor: "#fff" }}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === "string") {
                                            setNameDocument(newValue);
                                        } else if (newValue && newValue.inputValue) {
                                            setNameDocument(newValue.inputValue);
                                        } else {
                                            setNameDocument(newValue ? newValue.nome : '');
                                        }
                                    }}
                                    selectOnFocus
                                    noOptionsText="Forma de Pagamento não encontrado"
                                    clearOnBlur
                                    handleHomeEndKeys
                                    disabled={Boolean(simulationRef)}
                                    disableClearable={true}
                                    id="free-solo-with-text-demo"
                                    options={optionsSimulation}
                                    getOptionLabel={(option) => {
                                        // Add "inputValue" to handle the newly created option
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.nome;
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.nome}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Banco"
                                            required
                                            type='search'
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                        // This function ensures that the selected option matches the value
                                        return option.nome === value || option.nome === value.nome;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <FormControl fullWidth required>
                                    <InputLabel id="Situação">Situação</InputLabel>
                                    <Select
                                        labelId='Situação'
                                        label="Situação"
                                        value={situation}
                                        disabled={Boolean(simulationRef)}
                                        onChange={(e) => setSituation(e.target.value)}
                                        required>
                                        {optionsSituation.map((option) => (
                                            <MenuItem key={option.nome} value={option.nome}>{option.nome}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {checkFinancing() ?
                                <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                        backgroundColor: "#f6f6f6",
                                        borderLeft: '5px solid #ccc',
                                        padding: '0.2rem 0.5rem 1rem 0.5rem',
                                        margin: '1rem 0 0 0',
                                        borderRadius: '5px'
                                    }}>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Checkbox
                                            checked={activeFinancing}
                                            color='success'
                                            disabled={simulationRef}
                                            onChange={() => setActiveFinancing(!activeFinancing)}
                                            inputProps={{ 'aria-label': 'controlled' }} />
                                        <Typography variant='h6'>Financiamento</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <FormControl fullWidth required disabled={!activeFinancing}>
                                            <InputLabel id="Carência">Carência</InputLabel>
                                            <Select
                                                labelId='Carência'
                                                label="Carência"
                                                value={carencia}
                                                sx={{ backgroundColor: "#fff" }}
                                                onChange={(e) => setCarencia(e.target.value)}
                                                required>
                                                {[90, 120, 360, 720].map((option) => (
                                                    <MenuItem key={option} value={option}>{option} Dias</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            fullWidth
                                            label='Número de Parcelas'
                                            inputProps={{ min: 0 }}
                                            disabled={!activeFinancing}
                                            sx={{ backgroundColor: "#fff" }}
                                            value={installments[0].quantidade || ''}
                                            onChange={(e) => handleInstallments(e.target.value, 0, 'quantidade')} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <CurrencyInput
                                            customInput={TextField}
                                            label="Valor das Parcelas"
                                            placeholder="R$ 00"
                                            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                            onValueChange={(value) => handleInstallments(value, 0, 'valor')}
                                            decimalsLimit={2}
                                            disabled={!activeFinancing}
                                            sx={{ backgroundColor: "#fff" }}
                                            value={installments[0].valor || ''}
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    {installments?.length > 1 && installments.map((data, index) => (
                                        index === 0 ? null :
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} key={index} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            label='Número de Parcelas'
                                                            inputProps={{ min: 0 }}
                                                            sx={{ backgroundColor: "#fff" }}
                                                            disabled={!activeFinancing}
                                                            value={data.quantidade || ''}
                                                            onChange={(e) => handleInstallments(e.target.value, index, 'quantidade')} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.3rem' }}>
                                                        <CurrencyInput
                                                            customInput={TextField}
                                                            label="Valor das Parcelas"
                                                            placeholder="R$ 00"
                                                            sx={{ backgroundColor: "#fff" }}
                                                            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                                            onValueChange={(value) => handleInstallments(value, index, 'valor')}
                                                            decimalsLimit={2}
                                                            disabled={!activeFinancing}
                                                            value={data.valor || ''}
                                                            required
                                                            fullWidth
                                                        />
                                                        <NewTooltip title='Excluir Parcela' placement='top'>
                                                            <IconButton disabled={!activeFinancing} onClick={() => removeInstallment(index)} color='error'>
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </NewTooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    ))}
                                    <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                                        {!simulationRef &&
                                            <Button disabled={!activeFinancing} onClick={addNewInstallments} sx={{ textTransform: 'capitalize' }} color='success' startIcon={<AddCircleRoundedIcon />}>
                                                Adicionar Parcelas
                                            </Button>}
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.3rem' }}>
                                        <h3>Mensagem de Voz</h3>
                                        {!simulationRef &&
                                            <p>Envie ou grave um áudio explicando sobre o financiamento para o cliente.</p>
                                        }
                                        {simulation?.mensagemVoz?.url ?
                                            <Box sx={{ display: 'flex', width: '100%', gap: '0.5rem', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                                                <CustomAudioPlayerFixed url={simulation?.mensagemVoz?.url} />
                                                {/* <NewTooltip title='Excluir Áudio' placement='top'>
                                                    <IconButton onClick={() => deleteFile('Áudio')} color='error'>
                                                        <DeleteRounded />
                                                    </IconButton>
                                                </NewTooltip> */}
                                            </Box> :
                                            <div>
                                                <AudioRecorder setAudio={setAudio} audio={audio} disabled={!activeFinancing} />
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                                    <Button
                                                        disableElevation
                                                        sx={{ textTransform: 'capitalize' }}
                                                        component="label"
                                                        variant="contained"
                                                        onChange={(e) => sendAudio(e)}
                                                        disabled={!activeFinancing}
                                                        startIcon={<AudioFileIcon />}>
                                                        Enviar Áudio
                                                        <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                                                    </Button>
                                                </Box>
                                            </div>
                                        }
                                    </Grid>
                                </Grid> : null
                            }
                        </>
                        :
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                label="Nome"
                                fullWidth
                                variant='outlined'
                                required
                                value={nameDocument || ''}
                                onChange={(e) => setNameDocument(e.target.value)} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid sx={{ marginTop: '1rem', backgroundColor: "#f6f6f6", borderRadius: '5px', padding: '0.3rem 0 1rem 0', borderLeft: '5px solid #ccc' }} container spacing={1} xs={12}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography variant='h6'>Arquivo</Typography>
                            </Grid>
                            {simulationRef?.url ?
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <DocsItems>
                                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                            <a className='link_black' href={simulationRef.url} target='_blank' rel='noreferrer'>{simulationRef?.ref?.split(`Clientes/${customer.id}/`)[1]}</a>
                                        </Box>
                                        {/* <NewTooltip id="Clickable" title="Excluir" placement="top">
                                        <IconButton onClick={() => deleteFile(index)} color="error">
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip> */}
                                    </DocsItems>
                                </Grid> :
                                document.length > 0 ?
                                    document?.map((file, index) => (
                                        <Grid key={index} item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <DocsItems>
                                                <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }} onClick={() => viewDoc(file)}>
                                                    <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                                    <Link to='' style={{ color: "#000" }}>{file?.complete?.name}</Link>
                                                </Box>
                                                <NewTooltip id="Clickable" title="Excluir" placement="top">
                                                    <IconButton onClick={() => deleteFile(index)} color="error">
                                                        <DeleteRounded />
                                                    </IconButton>
                                                </NewTooltip>
                                            </DocsItems>
                                        </Grid>
                                    )) : <Grid item xs={12} justifyContent={'center'}>
                                        <Typography textAlign={'center'} color={"#838383"}>Nenhum arquivo encontrado</Typography>
                                    </Grid>
                            }
                            {!simulationRef?.url && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    sx={{ textTransform: 'capitalize' }}
                                    variant='contained'
                                    component="label"
                                    color='info'
                                    onChange={(e) => sendFile(e)}
                                    startIcon={<FaFileUpload />}
                                    disableElevation>Adicionar Arquivo
                                    <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                {simulationRef ?
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant='contained'
                        disableElevation
                        onClick={sendSimulation}
                        startIcon={<MdOutlineSendToMobile />}
                        disabled={!checkButton()}
                        color="info">
                        Enviar
                    </Button> :
                    <ThemeProvider theme={theme}>
                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            variant='contained'
                            disableElevation
                            onClick={saveDoc}
                            disabled={!checkButton()}
                            color="success">
                            Salvar
                        </Button>
                    </ThemeProvider>
                }
                <Button
                    onClick={closeBox}
                    sx={{ textTransform: 'capitalize' }}
                    disableElevation
                    variant='contained'
                    color="primary">
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SendDoc
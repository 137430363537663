import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { memo } from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import formatCurrency from '../../../functions/formatCurrency';

const InstallmentCheckbox = memo(({ data, installment, handleInstallments, disabled = false }) => (
    <FormControlLabel 
    disabled={disabled}
    sx={{ display: 'flex',
        gap: '1.5rem',
        margin: 0
     }}
        control={
            <Checkbox
                color="success"
                value={data.quantidade}
                sx={{
                    '& .MuiSvgIcon-root': {
                      display: disabled ? 'none' : 'flex',
                    },
                    '&.Mui-checked': { color: "#0FA958" }
                  }} 
                checked={data.quantidade === installment?.quantidade}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={handleInstallments}
            />
        }
        label={<Box>
            <h3>{data.quantidade} parcelas</h3>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <p>{formatCurrency(data.valor)} mês</p>
            </Box>
        </Box>}
    />

), (prevProps, nextProps) => {
    // Compare apenas as props que realmente importam para evitar renderizações desnecessárias
    return prevProps.data.quantidade === nextProps.data.quantidade && prevProps.installment?.quantidade === nextProps.installment?.quantidade;
});

export default memo(InstallmentCheckbox);
import { useState, useRef, memo } from 'react'
import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
import SlickSlider from "react-slick";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import TelhadoMedida from '../../../../images/visit/telhado_medida.jpeg';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useAuth from '../../../../hooks/useAuth';
import moment from 'moment';
import axios from 'axios';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';


const RegisterVisitStep3 = ({visit, open, close }) => {
  // const [open, setOpen] = useState(false);
  const theme = useTheme();
  const slider = useRef(null);
  const { uploadImages, changeProgress } = useUpload(visit.id);
  const { CircularProgressWithLabel  } = useCircular();
  const { user } = useAuth();
  const [step, setStep] = useState(0);
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [checkData, setCheckData] = useState(false);
  const [openAnotation, setOpenAnotation] = useState(false);
  const [anotation, setAnotation] = useState('');
  const [telhadoMedida, setTelhadoMedida] = useState([]);
  const [alturaTelhado, setAlturaTelhado] = useState('Casa Térrea');
  const [telhados, setTelhados] = useState([{tipo: '', medida: ''}]);
  // const [photos, setPhotos] = useState();
  const [anotacao, setAnotacao] = useState('');
  const [analyse, setAnalyse] = useState('Não ❌');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const steps = [
    'Fotos com Medição - Telhado',
    'Medidas'
  ];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  const closeBox = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setCheckData(false);
      setTelhadoMedida([])
      setTelhados([{tipo: '', medida: ''}]);
      // setPhotos();
      setAnotacao('');
    }, 500);
  }

  const changeStep = (direction) => {
    if(direction === 'go') {
       if (step < 1 && telhadoMedida.length === 0) {
        let validationPhoto;
        if(telhadoMedida.length === 0) {
          validationPhoto = 'do telhado com a <b> Medida'
        }
        return Swal.fire({
          title: 'Fotos',
          html: `Envie a foto ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#F39200",
          confirmButtonText: "Ok",
        })
      }
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  }

  const sendPhoto = async (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'telhadoMedida') {
      if(telhadoMedida) {
        setTelhadoMedida([...telhadoMedida, ...files])
      } else {
        setTelhadoMedida(files);
      }
    } 
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
      // if(newPhotos.length === 0) {
      //   setOpenPhoto(false);
      // }
    // setPhotos({data: newPhotos, type: photos.type});
     switch (type) {
      case 'telhadoMedida':
        setTelhadoMedida(newPhotos);
        break;
        default:
     }
    }
  }

  const sendVisit = async () => {
    try {
      let phone;
      if(visit.consultora === 'Ana') {
        phone = '5515974027861'
      } else if(visit.consultora === 'Bruna') {
        phone = '5515991017299'
      } else if(visit.consultora === 'Lia') {
        phone = '5515974061485'
      }
      if(telhados.find(telhado => telhado.medida.length === 0 || telhado.tipo.length === 0 )) {
       return Swal.fire({
          title: 'Atenção',
          html: `Preencha todos os campos para concluir a <b>etapa</b>.`,
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Fechar",
        })
      } else {
        Swal.fire({
          title: 'Visita Técnica - Fotos (Med. Telhado)',
          html: `Todos os dados estão <b>corretos</b>?`,
          icon: "question",
          showDenyButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          confirmButtonText: "Sim",
          denyButtonText: `Não`,
        }).then(async (result) => {
          if(result.isConfirmed) {
            setStep(2)

            let visita = {};
            let ref = {};
            const collectionAnot = visit.anotacao ? visit.anotacao : [];
            if(anotacao.length > 0) {
              collectionAnot.push({
                data: `[${moment(new Date()).format('DD/MM/YYYY')} às ${moment(new Date()).format('HH:mm')}] ${user.name}:`,
                dataCreat: new Date(),
                text: anotacao ? anotacao : '',
                uid: user.id
              })
            }

            await updateDoc(doc(dataBase, 'Visitas', visit.id), {
              anotacao: collectionAnot,
              analise_telhado: analyse,
              anotacao_telhado: anotation,
              jornada: {
                etapa: 'Visita Técnica',
                index: 1,
                sigla: 'VT',
                cor: '#addcc5',
                data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
              }
            })

            try {

            const resultTelhadoMedida = await uploadImages(telhadoMedida, 'telhadoMedida', 0)
              visita.telhadoMedida = resultTelhadoMedida.map(object => object.downloadURL);
              ref.telhadoMedida = resultTelhadoMedida.map(object => object.fullPath);
              setCheckData(true);

              await addDoc(collection(dataBase, 'Visitas', visit.id, 'Tecnica'), {
               ...visita,
               ref: ref,
               etapa: 3,
               telhado: telhados,
               altura_telhado: alturaTelhado,
               analise_status: analyse === 'Sim ✅' ? 'Aberto' : 'Fechado'
              }).then(async () => {
                 await axios.post('https://n8n.corpbrasil.cloud/webhook/acda61a7-49bc-4f08-8c96-59eb67e24324', {
                   nome: visit.cliente,
                   cidade: visit.cidade,
                   concessionaria: visit.concessionaria,
                   consultora: visit.consultora,
                   phone: phone,
                   marcado: visit.chegadaCliente,
                   dia: moment(visit.data).format('DD/MM/YYYY'),
                   anotacao: anotation,
                   anotacao_padrao: visit.anotacao_padrao,
                   bitola_ramal: visit.medida_bitola_ramal,
                   bitola_cliente: visit.medida_bitola_cliente,
                   dps: visit.dps,
                   analise_padrao: visit.analise_padrao,
                   analise_telhado: analyse,
                   energia_ca: visit.injecao_energia_ca,
                   id: visit.id
                 })
                  Swal.fire({
                    title: 'Visita Técnica - Fotos (Med. Telhado)',
                    html: `A <b>Etapa</b> foi concluida com sucesso.`,
                    icon: "success",
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                    confirmButtonText: "Fechar",
                    }).then(() => {
                      closeBox();
                    })
                 })

            } catch (error) {
              console.error('Ocorreu um erro: ', error)
            }
            }
          })
        }
    } catch (error) {
      console.log(error)
    }
  }

  const handleInput = (index, value, type) => {
    const newInputs = [...telhados];
    if(type === 'tipo') {
      newInputs[index].tipo = value;
    } else {
      newInputs[index].medida = value;
    }
    setTelhados(newInputs);
  }

  const addInput = () => {
    setTelhados([...telhados, {tipo: '', medida: ''}])
  }

  const deleteInput = (index) => {
    const newInputs = telhados.filter(((_, i) => i !== index))
    setTelhados(newInputs);
  }

  return (
    <>
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={() => closeBox()}
    >
      <IconButton
        aria-label="close"
        onClick={() => closeBox()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Visita Técnica - Fotos (Med. Telhado)</DialogTitle>
      <DialogContent className={style.dialog_content}>
        <div className={style.stepper}>
          <Stepper sx={{ width: '100%' }} activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      {step < 1 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
          {telhadoMedida && telhadoMedida.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 1:</b> Tire foto de <b>perto</b> do telhado do local. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={TelhadoMedida} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Tire foto de <b>perto</b> do telhado do local. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {telhadoMedida && telhadoMedida.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, telhadoMedida, 'telhadoMedida')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
            </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                component="label" 
                variant="contained" 
                onChange={(e) => sendPhoto(e, 'telhadoMedida')} 
                startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
                {analyse === 'Sim ✅' && 
                  <Button
                    disableElevation
                    sx={{ textTransform: 'capitalize' }} 
                    variant="contained" 
                    color='warning' 
                    onClick={() => {setOpenAnotation(true)}} 
                    startIcon={<AddCircleOutline />}>Anotação</Button>
                }
                {analyse === 'Sim ✅' ? 
                  <Button 
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    variant="outlined" 
                    color='error' 
                    onClick={() => {setAnalyse('Não ❌')}} 
                    endIcon={<CancelIcon />}>Cancelar Análise</Button> :
                  <Button 
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    variant="contained" 
                    color='success' 
                    onClick={() => {setAnalyse('Sim ✅')}} 
                    startIcon={<TroubleshootIcon />}>enviar para Análise</Button>
                }
            </ThemeProvider>
          </div>
          </div>
        </div>}
        {step === 1 &&
        <div className={style.register_container}>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <b>Informe o tipo e a medida da telha</b>.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
          {telhados.map((telhado, index) => (
            <div key={index} className={style.input_double}>
              <TextField
                fullWidth
                margin="dense"
                label="Tipo de Telha"
                type="text"
                value={telhado.tipo ? telhado.tipo : ''}
                onChange={(e) => handleInput(index, e.target.value, 'tipo')}
                variant="outlined" />
              <TextField
                fullWidth
                margin="dense"
                label="Medida da Telha (Ex: 32x12cm)"
                type="text"
                value={telhado.medida ? telhado.medida : ''}
                onChange={(e) => handleInput(index, e.target.value, 'medida')}
                variant="outlined" />
                {index === 0 ? 
                  <IconButton disabled className={style.delete_input} variant="outlined"></IconButton> :      
                  <IconButton color='error' className={style.delete_input} onClick={() => deleteInput(index)} variant="outlined"><CloseIcon /></IconButton>       
                }
            </div>
          ))}
        <Button sx={{ margin: '1rem 0' }} color='success' variant='outlined' onClick={() => addInput()} startIcon={<AddCircleIcon />}>Adicionar telha</Button>
        <p>Informe a <b>altura do telhado</b> de acordo com o tipo do local</p>
        <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
          <InputLabel id="altura">Altura do Telhado</InputLabel>
          <Select
          labelId='altura'
          label="Altura do Telhado"
          value={alturaTelhado}
          onChange={(e) => setAlturaTelhado(e.target.value)}
          required>
            <MenuItem value="Casa Térrea">Casa Térrea</MenuItem>
            <MenuItem value="Sobrado">Sobrado</MenuItem>
            <MenuItem value="Triplex">Triplex</MenuItem>
            <MenuItem value="Prédio">Prédio</MenuItem>
          </Select>
        </FormControl>
        <div className={style.input}>
          <p>Caso preferir, deixe uma <b>anotação</b> a respeito da visita.</p>
          <TextField
            fullWidth
            margin="dense"
            label="Anotação"
            type="text"
            rows={3}
            multiline
            value={anotacao}
            onChange={(e) => setAnotacao(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
      </div>}
        {step === 2 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
            <li>
            {checkData ? 
              <CheckIcon color='success' /> :
              <CircularProgressWithLabel value={changeProgress[0]} />
            }
             Fotos da medida do telhado</li>
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
        {step > 0 ?
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant='contained' 
            disabled={step > 1 ? true: false} 
            onClick={() => changeStep('back')}>Voltar</Button> :
            <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant='contained' 
            disabled={step > 1 ? true: false} 
            onClick={() => close()}>Fechar</Button>
        }
        {step > 0 ? 
          <Button
            disableElevation
            sx={{ textTransform: 'capitalize' }} 
            variant='contained' 
            disabled={step > 1 ? true: false} 
            color='success' 
            startIcon={<SendIcon />} onClick={() => sendVisit()}>Enviar</Button> :
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant='contained' 
            disabled={step > 1 ? true: false} 
            onClick={() => changeStep('go')}>Próximo</Button>
        }
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    {/* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div className={style.photo_card}>
                <img src={photo.file} alt='' /> :
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */}
      <Dialog
      className={style.anotation_container}
      sx={{ padding: '1rem' }}
      open={openAnotation}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenAnotation(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenAnotation(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Anotação</DialogTitle>
        <DialogContent className={style.register_container}>
        <DialogContentText>
          Deixe uma anotação a respeito do <b>Telhado</b>
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            fullWidth
            margin="dense"
            label="Anotação"
            type="text"
            rows={10}
            multiline
            value={anotation}
            onChange={(e) => setAnotation(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button 
              disableElevation
              sx={{ textTransform: 'capitalize' }}
              variant='contained' 
              onClick={() => setOpenAnotation(false)}>Fechar</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog>
      </>
  )
}

export default memo(RegisterVisitStep3);
import React, { useState, useEffect, memo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteDoc, doc, getDoc, onSnapshot, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import moment from 'moment';
import Swal from "sweetalert2";
import CurrencyInput from "react-currency-input-field";

import { ThemeProvider } from '@mui/material/styles';
import {
  Button, IconButton, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Popover,
  Box, Autocomplete, Badge, Grid,
  Tabs,
  Tab,
  styled 
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PeopleIcon from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CancelIcon from '@mui/icons-material/Cancel';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircle from '@mui/icons-material/CheckCircle';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'; // CPF
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded'; // Email
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'; // Empresa
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'; // Endereço
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

import styles from '../styles.module.scss';
import "cooltipz-css";

import NewTooltip from "../../../components/Tooltip";
import { useCadastro } from '../../../contexts/CadastroContext';
import { theme } from '../../../data/theme';
import { KeyMaps } from '../../../data/Data';
import { dataBase } from '../../../firebase/database';
import Create from '../../Credit/Components/Create/Index';
import CreateSheet from '../Components/CreateSheet';

import PanelSolar from '../../../images/icons/PainelSolar.png';
import removeNullProperties from '../../../functions/removeNullProperties';
import useGoPage from '../../../hooks/useGoPage';
import sortData from '../../../functions/sortData';
import History from '../../../components/History/Index';
import Loading from '../../../components/Loading';
import TaskAssignment from '../Components/TaskAssignment';
import InfoCustomer from '../Components/InfoCustomer';
import StatusCustomer from '../Components/StatusCustomer';
import Proposal from '../Components/Proposal';
import Annotation from '../Components/Annotation';
import { CustomTabPanel } from '../../../components/CustomTabPanel';
import AddProposal from '../Components/AddProposal';
import Payment from '../Components/Payment';
import Geocode from "react-geocode";
import RegisterVisit from '../Components/RegisterVisit';
import useWindowWidth from '../../../hooks/useWindowWidth';

Geocode.setLanguage("pt-BR");
Geocode.setRegion("br");
Geocode.setApiKey(KeyMaps);
Geocode.setLocationType("ROOFTOP");

const CustomerProfile = ({ isLoaded, leads, userRef, visitsAll, members }) => {
  const { atualizarCadastro } = useCadastro();
  const goPage = useGoPage();
  const width = useWindowWidth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [propostas, setPropostas] = useState([]);
  const [propostasList, setPropostasList] = useState([]);
  const [propostasListRef, setPropostasListRef] = useState([]);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;
  const filter = createFilterOptions();
  const [tabsValue, setTabsValue] = useState(0);
  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });
  // const [page, setPage] = useState(0);
  const [open, setOpen] = useState({
    customer: false
  });
  const [view, setView] = useState({
    proposta: false,
    credit: false,
    address: false,
    addProposta: false,
    register: false
  });
  const [assistant, setAssistant] = useState({
    nome: 'Nenhum',
    id: '0'
  });
  const [indicator, setIndicator] = useState({
    nome: 'Nenhum',
    codigo: '0'
  });
  const [indicatorAll, setIndicatorAll] = useState([]);
  const [visits, setVisits] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [customer, setCustomer] = useState({});
  const [customerRef, setCustomerRef] = useState({});
  const { idCustomers } = useParams();
  const navigate = useNavigate();
  const [responsible, setResponsible] = useState({
    nome: '',
    id: '',
    img: ''
  });
  const [representative, setRepresentative] = useState({
    nome: 'Nenhum',
    id: '0',
    codigo: 'Nenhum'
  });
  const [comission, setComission] = useState({});
  const [checkComission, setCheckComission] = useState(false);
  const [viewSaveProposta, setViewSaveProposta] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const [propostasSheet, indicatorSheet] = await Promise.all([
        axios.get('https://script.google.com/macros/s/AKfycbwl_i83rMEHlAXIx7FWhuwkjnW7uuBTIi2FmdjucMU3WEUx-JuFrG8TSHeMo2zHhXNp8g/exec'),
        axios.get('https://script.google.com/macros/s/AKfycbwMviRTzfHzf4kf_Bi08kTTHToTgg8SukNnC13Z_Jdrp-_qn475hqZhjA__soFglxQ8tg/exec')
      ]);
      const propostasRef = propostasSheet.data.GoogleSheetData;
      const propostaFormated = propostasRef.map((data) => {
        return {
          nome: data[10],
          telefone: data[2],
          valor: parseFloat(String(data[4]).replace(/\.|,/g, m => m === ',' ? '.' : '')),
          codigo: data[9]
        }
      });
      const propostasListFull = propostaFormated.filter(data => data.nome);
      setPropostasListRef(propostasListFull);

      const indicatorData = indicatorSheet.data.GoogleSheetData.filter(data => data[0] !== 'Código' && data[1].length > 0);
      const indicatorOptions = indicatorData.reduce((uniqueList, currentData) => {

        if (currentData[0] !== 'Data' && currentData[0].trim() !== '') {
          uniqueList.push({
            codigo: currentData[0],
            nome: currentData[1],
            telefone: currentData[9].replace(/\D/g, ''),
            cpf: currentData[4],
            cidade: currentData[5],
            data: moment(currentData[2]).format('DD/MM/YYYY'),
            email: currentData[3],
            endereco: currentData[6],
            bairro: currentData[7],
            pix: currentData[8],
          });
        }

        return uniqueList;
      }, []);


      setIndicatorAll([
        {
          codigo: '0',
          nome: 'Nenhum'
        },
        ...sortData(indicatorOptions),
      ]);
    }

    fetchData();
  }, [userRef])

  useEffect(() => {
    if (propostasListRef) {
      // if (userRef && userRef.cargo === 'Vendedor(a)' && userRef.time) { // Arrumar
      //   setPropostasList(propostasListRef.filter(data => data.codigo.includes(userRef.time)));
      // } else {
      // }
      setPropostasList(propostasListRef);
    }
  }, [propostasListRef, userRef])

  useEffect(() => {
    if (!customer) return
    // Set proposals
    setPropostas(prev => (JSON.stringify(prev) !== JSON.stringify(customer.propostas) ? customer.propostas : prev));
  }, [customer])

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(await doc(dataBase, "Clientes", idCustomers), (doc) => {
          // Atualiza os dados em tempo real
          setCustomer({ ...doc.data(), id: doc.id });
          setCustomerRef({ ...doc.data(), id: doc.id });
        });
        onSnapshot(await doc(dataBase, "Comissoes", idCustomers), (doc) => {
          // Atualiza os dados em tempo real
          setComission({ ...doc.data() })
          setCheckComission(doc.exists())
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (visitsAll) {
      setVisits(visitsAll.filter((visit) => visit.cliente_id === customer.id));
      setLoading(false);
    }
  }, [visitsAll, customer])

  const ativeProposta = (index) => {
    if (propostas[index].ativo === true) {
      Swal.fire({
        title: 'Atenção',
        html: `Você deseja <b>desativar</b> a Comissão dessa Proposta?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setPropostas(setInativo(propostas, index));
          setViewSaveProposta(true);
        }
      })
    } else {
      Swal.fire({
        title: 'Atenção',
        html: `Você deseja <b>ativar</b> a Comissão dessa Proposta?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const newPropostas = setAtivo(propostas, index);
          const changePropostas = newPropostas.map(data => {
            const { commissionPercentage, commissionValue } = handleCommissionValue(data.valor_inicial, data.valor_final);
            return {
              ativo: data.ativo,
              kit: data.kit,
              valor_inicial: typeof data.valor_inicial === 'number' ? data.valor_inicial : Number(data.valor_inicial.replace(',', '.')),
              valor_final: typeof data.valor_final === 'number' ? data.valor_final : Number(data.valor_final.replace(',', '.')),
              comissao: {
                valor: commissionValue,
                porcentagem: commissionPercentage,
                status: ''
              },
            }
          })
          setPropostas(changePropostas);
          setViewSaveProposta(true);
        }
      })
    }
  };

  const setAtivo = (propostasRef, indexAtivo) => {
    return propostasRef.map((proposta, index) => ({
      ...proposta,
      ativo: index === indexAtivo,
    }));
  };

  const setInativo = (propostasRef, indexAtivo) => {
    return propostasRef.map((proposta, index) => ({
      ...proposta,
      ativo: index === indexAtivo ? !proposta.ativo : proposta.ativo,
    }));
  };

  const createCredit = () => {
    try {
      Swal.fire({
        title: 'Planilha de Crédito',
        html: `Você deseja criar uma nova <b>Planilha?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {

          // const { value:kit } = await Swal.fire({
          //   title: 'Planilha de Crédito',
          //   html: `Informe a <b>Potência do Kit</b>. (Somente número. Ex: 5.5)`,
          //   showCancelButton: true,
          //   showCloseButton: true,
          //   confirmButtonColor: "#0eb05f",
          //   confirmButtonText: "Continuar",
          //   cancelButtonText: "Fechar",
          //   input: 'number',
          //   inputValidator: (value) => {
          //     if (!value) {
          //       return 'Informe a Potência do Kit para prosseguir'
          //     }
          //   },
          // })

          setView({
            ...view,
            address: true //Credit
          });

          // if(kit) {
          //   setCustomer({...customer, kit: kit});

          // sendData({
          //   nome: data.nome,
          //   cidade: data.cidade,
          //   telefone: data.telefone,
          //   kit: kit,
          //   consultora: data.consultora,
          //   consultora_uid: data.consultora_uid
          // })
          // openBox('create');
          // }
        }
      })
    } catch (error) {

    }
  }

  const openSheet = (value, newAddress) => {

    setView({
      ...view,
      credit: true,
      address: false
    });

    const address = customer.endereco_list ?? [];
    const addressRef = {
      endereco: value.endereco,
      lat: value.lat,
      lng: value.lng,
      local: value.local
    };

    if (newAddress && customer.endereco) {
      address.push(addressRef);
    }

    console.log(address);

    setCustomer({
      ...customer,
      kit: value.kit,
      geracao: value.geracao,
      cidade: customer.endereco ? customer.cidade : value.cidade,
      cep: customer.endereco ? customer.cep : value.cep,
      endereco: customer.endereco ?? addressRef,
      endereco_list: address,
      endereco_escolhido: addressRef
    });

  }


  const deleteCredit = (data, index) => {
    try {
      Swal.fire({
        title: 'Planilha de Crédito',
        html: `Você deseja excluir a <b>Planilha?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const docRef = doc(dataBase, 'Clientes', idCustomers);
          let collectionPlanilhas = customer.planilha;
          const kitRef = customer.planilha[index].kit;
          if (index >= 0 && index < collectionPlanilhas.length) {
            collectionPlanilhas.splice(index, 1);
          }
          console.log(collectionPlanilhas)
          await updateDoc(docRef, { planilha: collectionPlanilhas }).then(() => {
            Swal.fire({
              title: 'Planilha de Crédito',
              html: `A Planilha de Crédito do kit ${kitRef} kWp foi excluida com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
          });
        }
      })
    } catch (error) {
      console.log('ERRO: ', + error);
    }
  }

  const deleteCustomer = () => {
    try {
      Swal.fire({
        title: 'Atenção',
        html: `Você deseja excluir o Cliente <b>${customer.nome}?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const comissionDoc = await getDoc(doc(dataBase, 'Comissoes', idCustomers));
          if (comissionDoc.exists()) {
            await deleteDoc(doc(dataBase, 'Comissoes', idCustomers))
          }
          await deleteDoc(doc(dataBase, 'Clientes', idCustomers)).then(() => {
            navigate('/clientes')
            Swal.fire({
              title: 'Clientes',
              html: `O Cliente <b>${customer.nome}</b> foi excluido com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
          });
        }
      })
    } catch (error) {
      console.log('ERRO: ', + error);
    }
  }

  const calculateCommission = (discount) => {
    if (discount >= 5) return 2;
    if (discount >= 4.5) return 2.5;
    if (discount >= 4) return 3;
    if (discount >= 3.5) return 3.5;
    if (discount >= 3) return 4;
    if (discount >= 2.5) return 4.5;
    if (discount >= 2) return 5;
    if (discount >= 1.5) return 5.5;
    if (discount >= 1) return 6;
    if (discount >= 0.5) return 6.5;
    return null; // Caso o desconto não se encaixe em nenhuma faixa, retorna nulo.
  };

  const handleCommissionValue = (valueInitial, valueEnd) => {
    const initial = parseFloat(valueInitial);
    const end = parseFloat(valueEnd);
    const discountPercentage = ((initial - end) / initial) * 100;
    // console.log(`Desconto concedido: ${discountPercentage}%`);

    const commissionPercentage = calculateCommission(discountPercentage);
    // setPercentage(commissionPercentage);
    let commissionValue = 0;

    if (commissionPercentage !== null) {
      commissionValue = (end * commissionPercentage) / 100;
    }

    // console.log(`Comissão devida: ${commissionValue}`);
    return { commissionValue, commissionPercentage };
  };

  const saveProposta = async (e) => {
    try {
      e.preventDefault();

      const comissionRef = await getDoc(doc(dataBase, 'Comissoes', idCustomers));
      const propostaAtive = propostas.find(data => data.ativo === true);

      let msg = '';
      if (comission) {
        if (propostaAtive) {
          msg = '<b>O Cliente possui uma comissão ativa e caso os dados da proposta sejam alterados, a comissão também será alterada!</b> <br /><br />'
        } else {
          msg = `<b>Detectamos que o cliente possui uma Comissão ativa, porém você desativou. Caso nenhuma comissão esteja ativa, a comissão do Representante será excluida!</b> <br /><br />`
        }
      }
      console.log(propostas)
      Swal.fire({
        title: 'Atenção',
        html: msg + `Você deseja confirmar a alteração dos dados?`,
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const changePropostas = await propostas.map(data => {
            const { commissionPercentage, commissionValue } = handleCommissionValue(data.valor_inicial, data.valor_final);
            return {
              ativo: data.ativo,
              kit: data.kit,
              valor_inicial: (typeof data.valor_inicial === 'number' ? data.valor_inicial : parseFloat(data.valor_inicial.replace(',', '.'))),
              valor_final: (typeof data.valor_final === 'number' ? data.valor_final : parseFloat(data.valor_final.replace(',', '.'))),
              comissao: {
                valor: commissionValue,
                porcentagem: commissionPercentage,
                status: ''
              },
            }
          })
          console.log(changePropostas);
          const propostaAtiveFormated = changePropostas.find(data => data.ativo === true);

          await updateDoc(doc(dataBase, 'Clientes', idCustomers), {
            propostas: changePropostas
          }).then(async () => {
            if (propostaAtive) {
              if (comissionRef.exists()) {
                await updateDoc(doc(dataBase, "Comissoes", idCustomers), {
                  ...propostaAtiveFormated
                })
              } else {
                await setDoc(doc(dataBase, "Comissoes", idCustomers), {
                  ...propostaAtiveFormated,
                  representante: {
                    ref_indicador: customer.representante?.codigo,
                    id: customer.representante?.id,
                    nome: customer.representante?.nome
                  },
                  backoffice_id: customer.consultora_uid,
                  nome: customer.nome,
                  etapa: 'Faturamento Pendente',
                  etapa_id: 0,
                  data: moment().format('DD/MM/YYYY'),
                  data_completa: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`,
                  createAt: serverTimestamp()
                })
              }
            } else if (comissionRef.exists()) {
              await deleteDoc(doc(dataBase, "Comissoes", idCustomers))
            }
            Swal.fire({
              title: 'Proposta',
              html: `Os dados foram salvos com sucesso.`,
              icon: "success",
              showCloseButton: true,
              confirmButtonColor: "#111",
              confirmButtonText: "Ok",
            })
            setViewSaveProposta(false);
          })
        }
      })

    } catch (error) {
      console.log('Erro: ' + error);
    }
  }

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const averageValue = (data) => {
    let somaConsumoAtual = 0;
    let somaConsumoAdicionado = 0;
    let somaGeracao = 0;
    let count = 0;

    for (const mes in data) {
      if (mes !== "kit" && mes !== "gordura" && mes !== "motivo" && mes !== "observacao") {
        const dadosMes = data[mes];
        // Certifique-se de que os valores são números válidos antes de somá-los
        const consumoAtual = parseFloat(dadosMes?.consumo_atual) || 0;
        const consumoAdicionado = parseFloat(dadosMes?.consumo_adicionado) || 0;
        const geracao = parseFloat(dadosMes?.geracao) || 0;

        somaConsumoAtual += consumoAtual;
        somaConsumoAdicionado += consumoAdicionado;
        somaGeracao += geracao;
        count++;
      }
    }

    // Evite divisão por zero
    const divisor = count > 0 ? count : 1;

    const mediaConsumoAtual = (somaConsumoAtual / divisor).toFixed(0);
    const mediaConsumoAdicionado = (somaConsumoAdicionado / divisor).toFixed(0);
    const mediaGeracao = (somaGeracao / divisor).toFixed(0);

    return {
      mediaConsumoAtual,
      mediaConsumoAdicionado,
      mediaGeracao
    }
  }

  const addNewProposta = () => {
    // Define o objeto com os valores iniciais para a nova proposta
    const newProposta = {
      kit: '',
      ativo: false,
      valor_inicial: '',
      valor_final: '',
      comissao: 0
    };
    setViewSaveProposta(true);
    // Atualiza o estado para incluir a nova proposta no array de propostas
    setPropostas(currentPropostas => [...(currentPropostas ?? []), newProposta]);
  };

  const removeProposta = (index) => {
    Swal.fire({
      title: 'Proposta',
      html: `Você deseja remover a <b>Proposta?</b>`,
      icon: "question",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setPropostas(currentPropostas => currentPropostas.filter((_, i) => i !== index));
        setViewSaveProposta(true);
      }
    })
  };

  const handlePropostaChange = (index, field, value) => {
    setPropostas(currentPropostas => {
      const newPropostas = [...currentPropostas];
      const newProposta = { ...newPropostas[index], [field]: value };
      newPropostas[index] = newProposta;
      return newPropostas;
    });
  };

  const closeProposta = () => {
    setPropostas(customer.propostas ? customer.propostas : null);
    setViewSaveProposta(false);
  }

  const handleVisit = (visit) => {
    const cadastro = {
      preData: {
        nome: customer.nome,
        telefone: customer.telefone ?? '00000000000',
        cpfOuCnpj: customer.doc ?? '00000000000',
      },
      cliente_id: customer.id,
      consultora: customer.consultora,
      indicador: {
        id: customer.representante?.id ?? '0',
        nome: customer.representante?.nome ?? 'Nenhum',
        ref_indicador: customer.representante?.codigo ?? 'Nenhum'
      },
      endereco: customer.endereco ? {
        ...customer.endereco,
        cidade: customer.cidade
      } : null
    };

    atualizarCadastro(removeNullProperties(cadastro));
    navigate(`/visitas/criar/${visit}`);
  }

  const closeSheet = () => {
    setView({ ...view, credit: false });
    setCustomer(customerRef);
  }

  const checkStatusSystem = (data) => {
    const { extra, reserva, sistemaRecomendado } = data;

    if (extra) {
      if (reserva) {
          return {
            text: 'Aceito',
            color: '#02b902'
          };
      } else if(sistemaRecomendado) {
        return {
          text: 'Enviado ao Cliente',
          color: '#58abfd'
        };
      }
      return {
        text: 'Gerado',
        color: '#ffe016'
      };
    }
  }

  const checkUser = (role) => {
    switch (role) {

    }
    if (userRef?.cargo === 'Vendedor(a)' || userRef?.cargo === 'Administrador') {
      if (customer.assistente === userRef?.id || customer.consultora_uid === userRef?.uid || userRef?.cargo === 'Administrador') {
        return true;
      } else {
        return false;
      }
    }
  }

  // const confirmVisit = async () => {
  //   try {

  //     const result = await Swal.fire({
  //       title: 'Atenção',
  //       html: `Você deseja registrar a visita com o(a) cliente <b>${customer.nome}?</b>`,
  //       icon: "warning",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     })

  //     if (result.isConfirmed) {
  //       setLoading(true);

  //       const getLocation = () => {
  //         return new Promise((resolve, reject) => {
  //           navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //               resolve({
  //                 lat: position.coords.latitude,
  //                 lng: position.coords.longitude,
  //               });
  //             },
  //             (error) => {
  //               reject(error);
  //             }
  //           );
  //         });
  //       };

  //       try {
  //         const location = await getLocation();
  //         console.log(location);
  //         const geocodeResult = await Geocode.fromLatLng(location.lat, location.lng);
  //         const cityRef = geocodeResult.results[0].address_components;
  //         const address = geocodeResult.results[0].formatted_address;
  //         const city = cityRef.find((ref) => ref.types.includes('administrative_area_level_2'));

  //         console.log(geocodeResult);

  //         // await axios.post('https://n8n.corpbrasil.cloud/webhook/dc166bdc-afb7-49dc-882d-cad82a4c5327', { // Ativar novamente!
  //         //   nome: userRef?.nome,
  //         //   cliente: customer.nome,
  //         //   telefone: customer.telefone,
  //         //   empresa: customer.empresa || '',
  //         //   cidade: customer.cidade || '',
  //         //   link: `https://corpbrasil.app/clientes/${customer.id}`,
  //         //   localizacao: `https://maps.google.com/?q=${location.lat},${location.lng}`,
  //         //   cidade_registrada: city.long_name,
  //         //   telefone_bc: '15991573088'
  //         // })

  //         Swal.fire({
  //           title: 'Registro de Visita',
  //           html: `A visita em <b>${city.long_name}</b> foi confirmada com sucesso!`,
  //           icon: 'success',
  //           confirmButtonColor: "#111",
  //           confirmButtonText: "Ok"
  //         });

  //         const visitsRef = customer.visitas || []

  //         await updateDoc(doc(dataBase, 'Clientes', customer.id), {
  //           visitas: [...visitsRef, {
  //             data: moment().format('DD/MM/YYYY - HH:mm'),
  //             createAt: new Date(),
  //             ...location,
  //             endereco: address,
  //             cidade: city.long_name,
  //             usuario_id: userRef?.id,
  //             usuario_nome: userRef?.nome
  //           }],
  //           historico: [...customer.historico, {
  //             data: moment().format('DD/MM/YYYY - HH:mm'),
  //             evento: 'Visita Registrada',
  //             text: `Foi registrado uma visita em <b>${address}</b>.`,
  //             usuario_id: userRef?.id,
  //             usuario_nome: userRef?.nome
  //           }]
  //         });

  //       } catch (error) {
  //         console.error("Erro ao obter a localização:", error);
  //         Swal.fire({
  //           title: 'Erro',
  //           html: 'Não foi possível obter a localização. Por favor, <b>verifique se o GPS está ativado</b> e permita o acesso à localização.',
  //           icon: 'error',
  //           confirmButtonColor: "#111",
  //           confirmButtonText: "Ok",
  //         });
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log('ERRO:', error)
  //   }
  // }

  const truncatedText = (text, number) => {
    return text.length > number ? `${text.substring(0, number)}...` : text;
  }

  const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: 120,
    textTransform: 'capitalize',
    borderRadius: '5px 5px 0 0',
    fontSize: '18px',
    backgroundColor: '#DBDFE2',
    '&.Mui-selected': {
      backgroundColor: "#fff",
      color: "#000",
    },
  }));

  const getMemberColor = (id) => {
    const member = members.find((member) => member.id === id);
    return member ? member.cor : 'transparent';
}

  return (
    <div className={styles.panel}>
      <Loading view={loading} />
      <RegisterVisit
            userRef={userRef} 
            leads={leads} 
            members={members} 
            view={open.register} 
            customerRef={customer}
            close={() => setOpen({...view, register: false})}
            type="Profile" />
      <CreateSheet isLoaded={isLoaded} customer={customer} view={view.address} close={() => setView({ ...view, address: false })} openSheet={openSheet} />
      <Grid container className={styles.panel_header}>
        <Grid item xs={12} sm={2} md={1}>
          <NewTooltip title='Voltar' placement='top'>
            <IconButton onClick={() => navigate('/clientes')}><ArrowCircleLeftOutlinedIcon sx={{ fontSize: '50px' }} /></IconButton>
          </NewTooltip>
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
        <div className={styles.panel_header_title}>
          <PersonIcon sx={{ fontSize: '40px' }} />
          <h1>{customer?.nome}</h1>
          <h3>{customer?.cidade || 'Não Informado'}</h3>
        </div>
        </Grid>
        <Grid item xs={6} md={2} >
            <TaskAssignment
              customer={customer}
              responsible={responsible}
              setResponsible={setResponsible}
              userRef={userRef}
              members={members}
              assistant={assistant}
              setAssistant={setAssistant}
              representative={representative}
              setRepresentative={setRepresentative}
              indicator={indicator}
              indicatorAll={indicatorAll}
              setIndicator={setIndicator}
              checkComission={checkComission} />
        </Grid>
      </Grid>
      <div className={styles.panel_content_main}>
        <div className={styles.panel_content_item}> {/* Proposta e Histórico */}
          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item xs={12} sm={9} md={9}>
              <div className={styles.panel_content_box} style={{ margin: '0', padding: '0' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '0.5rem' }}>
                  <ul className={styles.customer_data}>
                    <li>
                      <BadgeOutlinedIcon />
                      {customer?.doc ?
                        customer?.doc :
                        <Link to='' onClick={() => setOpen({ ...open, customer: true })}>CNPJ/CPF</Link>
                      }
                    </li>
                    <li>
                      <MailOutlineRoundedIcon />
                      {customer?.email ?
                        <a href={`mailto:${customer?.email}`}>{customer?.email}</a> :
                        <Link to='' onClick={() => setOpen({ ...open, customer: true })}>Email</Link>
                      }
                    </li>
                    <li>
                      <BusinessRoundedIcon />
                      {customer?.empresa ?
                        truncatedText(customer?.empresa, 9) :
                        <Link to='' onClick={() => setOpen({ ...open, customer: true })}>Empresa</Link>
                      }
                    </li>
                    <li>
                      <WhatsAppIcon />
                      {customer?.telefone ?
                        <a href={`https://api.whatsapp.com/send/?phone=${customer?.telefone}`} target="_blank" rel="noopener noreferrer">{customer?.telefone}</a> :
                        <Link>Telefone</Link>
                      }
                    </li>
                    <li>
                      <LocationOnOutlinedIcon />
                      {customer?.endereco?.endereco ?
                        <a href={`https://maps.google.com/?q=${customer?.endereco?.lat},${customer?.endereco?.lng}`} target="_blank" rel="noopener noreferrer">{truncatedText(customer?.endereco.endereco, 17)}</a> :
                        <Link to='' onClick={() => setOpen({ ...open, customer: true })}>Endereço</Link>
                      }
                    </li>
                  </ul>
                  <NewTooltip title='Visualizar/Editar Dados' placement='top'>
                    <IconButton onClick={() => setOpen({ ...open, customer: true })}><EditNoteOutlinedIcon /></IconButton>
                  </NewTooltip>
                </Box>
                <InfoCustomer open={open.customer} close={() => setOpen({ ...open, customer: false })} customer={customer} setCustomer={setCustomer} checkUser={checkUser} customerRef={customerRef} userRef={userRef} />
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <div className={styles.panel_content_box} style={{ margin: '0', padding: '0' }}>
                <div className={styles.customer_status}>
                  <h3>Status</h3>
                  <StatusCustomer customer={customer} userRef={userRef} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Annotation customer={customer} userRef={userRef} checkUser={checkUser} members={members} />
              <div className={styles.panel_content_box} style={{ flex: 1 }}>
                <div className={styles.panel_content_title} style={{ margin: "1rem 0" }}>
                <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between', alignItems: 'center', color: "#515151"}}>
                  <RequestQuoteRoundedIcon sx={{ fill: "#063" }} />
                  <h3>Orçamento</h3>
                </Box>
                  <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      variant='contained'
                      color='success'
                      size='small'
                      disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid && userRef?.id !== customer.assistente}
                      startIcon={<AddCircle />}
                      onClick={() => setView({...view, addProposta: true})}>
                      Novo Orçamento
                  </Button>
                </div>
                <AddProposal members={members} userRef={userRef} customer={customer} view={view.addProposta} close={() => setView({...open, addProposta: false})} />
                <Proposal userRef={userRef} customer={customer} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                  <div className={styles.panel_content_box} style={{ marginBottom: 0, padding: 0 }}>
                      <Payment customer={customer} userRef={userRef} />
                  </div>
                  <div className={styles.panel_content_box} style={{ flex: 1, height: '100%' }}>
                    <History userRef={userRef} history={customer.historico} members={members} />
                  </div>
            </Grid>
            <Grid item xs={12}>
              <ThemeProvider theme={theme}>
                <Tabs
                  value={tabsValue}
                  onChange={(e, newValue) => setTabsValue(newValue)}
                  aria-label="Informações do Lead"
                  variant="fullWidth"
                  className={styles.tabs_extra}
                >
                  <StyledTab
                    icon={<DescriptionIcon sx={{ fill: "#4cda00", fontSize: '28px' }} />}
                    iconPosition={width > 600 ? 'start' : 'top'}
                    label={`Planilhas de Crédito (${customer?.planilha?.length || 0})`}
                    {...a11yProps(0)} />
                  <StyledTab
                    icon={<CalendarMonthIcon sx={{ fill: "#0ca358", fontSize: '28px' }} />}
                    iconPosition={width > 600 ? 'start' : 'top'}
                    label={`Visitas Comerciais (${customer?.visitas?.length || 0})`}
                    {...a11yProps(1)} />
                  <StyledTab
                    icon={<CalendarMonthIcon sx={{ fill: "#576af5", fontSize: '28px' }} />}
                    iconPosition={width > 600 ? 'start' : 'top'}
                    label={`Visitas Técnicas (${visits?.length})`}
                    {...a11yProps(1)} />
                </Tabs>
              </ThemeProvider>
              <CustomTabPanel style={{ backgroundColor: '#fff', boxShadow: '0px 2px 10px 0px rgba(25, 93, 194, 0.07)' }} value={tabsValue} index={0}>
                <Create customer={customer} view={view.credit} userRef={userRef} close={closeSheet} data={customer} />
                <TableContainer className={styles.table_visits}>
                  <Table stickyHeader aria-label="sticky table" sx={{ borderRadius: '0' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Orçamento</TableCell>
                        <TableCell align="center">Kit</TableCell>
                        <TableCell align="center">Geração Média</TableCell>
                        <TableCell align="center">Consumo Médio</TableCell>
                        <TableCell align="center">Consumo Adicionado</TableCell>
                        {userRef?.cargo === 'Administrador' ?
                          <TableCell align="center">Ação</TableCell> : null
                        }
                      </TableRow>
                    </TableHead>
                    {customer?.planilha ? customer.planilha.map((data, index) => (
                      <TableBody key={index}>
                        <TableRow
                          className={styles.row_sheet}
                          hover
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align="center" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
                            {data.extra ?
                              <ThemeProvider theme={theme}>
                                <Badge
                                  variant="dot"
                                  sx={{ "& .MuiBadge-badge": { width: 15, height: 15, borderRadius: '50%', backgroundColor: checkStatusSystem(data).color } }}>
                                  <NewTooltip title={`Orçamento ${checkStatusSystem(data).text}`} placement={'top'}>
                                    <img src={PanelSolar} alt='' style={{ width: 35, height: 35 }} />
                                  </NewTooltip>
                                </Badge>
                              </ThemeProvider> :
                              <NewTooltip title='Orçamento Inativo' placement={'top'}>
                                <img src={PanelSolar} alt='' style={{ width: 35, height: 35, opacity: 0.3 }} />
                              </NewTooltip>
                            }
                            {/* {data.orcamento ?
                              <ThemeProvider theme={theme}>
                                <Badge
                                  variant="dot"
                                  sx={{ "& .MuiBadge-badge": { width: 15, height: 15, borderRadius: '50%', backgroundColor: checkStatusSystem(data).color } }}>
                                  <NewTooltip title={`Reserva de Equipamentos ${checkStatusSystem(data).text}`} placement={'top'}>
                                    <img src={Reserva} alt='' style={{ width: 30, height: 30 }} />
                                  </NewTooltip>
                                </Badge>
                              </ThemeProvider> :
                              <NewTooltip title='Reserva de Equipamentos Inativo' placement={'top'}>
                                <img src={Reserva} alt='' style={{ width: 30, height: 30, opacity: 0.3 }} />
                              </NewTooltip>
                            } */}
                          </TableCell>
                          <TableCell sx={{ minWidth: '90px' }} align="center">
                            <Link className='link_black' to={`/credito/${customer.id}/${index}`}>
                              <NewTooltip title={'Visualizar Planilha'} placement={'top'}>
                                <p>{data.kit} kWp</p>
                              </NewTooltip>
                            </Link>
                          </TableCell>
                          <TableCell align="center">{averageValue(data)?.mediaGeracao}</TableCell>
                          <TableCell align="center">{averageValue(data)?.mediaConsumoAtual}</TableCell>
                          <TableCell align="center">{averageValue(data)?.mediaConsumoAdicionado}</TableCell>
                          {userRef?.cargo === 'Administrador' ?
                          <TableCell align="center">
                            <NewTooltip title={userRef && userRef.cargo !== 'Administrador' ? 'Ação Bloqueada' : 'Excluir Planilha'} placement={'top'}>
                              <span>
                                <IconButton disabled={userRef && userRef.cargo !== 'Administrador'} onClick={() => deleteCredit(data, index)} color='error'><DeleteForeverIcon /></IconButton>
                              </span>
                            </NewTooltip>
                          </TableCell> : null }
                        </TableRow>
                      </TableBody>
                    )) :  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={6}>
                        <p className={styles.value}>Nenhuma Planilha Encontrada</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>}
                    
                  </Table>
                  {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    labelRowsPerPage="Planilhas por página"
                    component="div"
                    count={customer?.planilha?.length ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                </TableContainer>
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize', marginBottom: '1rem' }}
                      variant='contained'
                      aria-describedby={id}
                      disabled={!checkUser()}
                      startIcon={<AddCircle />}
                      onClick={() => createCredit()}>
                      Nova Planilha
                    </Button>
                  </Box>
              </CustomTabPanel>
              <CustomTabPanel style={{ backgroundColor: '#fff', boxShadow: '0px 2px 10px 0px rgba(25, 93, 194, 0.07)' }} value={tabsValue} index={1}>
                <TableContainer className={styles.table_visits}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Data</TableCell>
                        <TableCell align="center">Feedback</TableCell>
                        <TableCell align="center">Localização</TableCell>
                        <TableCell align="center">Consultor</TableCell>
                      </TableRow>
                    </TableHead>
                    {customer?.visitas?.length > 0 ? customer.visitas.map((data, index) => (
                      <TableBody key={index}>
                        <TableRow
                          className={styles.row_sheet}
                          hover
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align="center">
                            {data.data.replace('-', 'às')}
                          </TableCell>
                          <TableCell align="center">{data?.feedback || 'Não Informado'}</TableCell>
                          <TableCell align="center">{data.endereco}</TableCell>
                          <TableCell className={styles.consultora} align="center">
                            <p className={styles.table_data} style={{ backgroundColor: getMemberColor(data.usuario_id) }}>{data.usuario_nome}</p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )) :
                    <TableBody>
                        <TableRow>
                          <TableCell align='center' colSpan={11}>
                            <p className={styles.value}>Nenhuma Visita Encontrada</p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    }
                  </Table>
                  {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    labelRowsPerPage="Visitas por página"
                    component="div"
                    count={customer?.visitas?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel style={{ backgroundColor: '#fff', boxShadow: '0px 2px 10px 0px rgba(25, 93, 194, 0.07)' }} value={tabsValue} index={2}>
                <TableContainer className={styles.table_visits}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Categoria</TableCell>
                        <TableCell align="center">Data</TableCell>
                        <TableCell align="center">Etapa</TableCell>
                        <TableCell align="center">Nome</TableCell>
                        <TableCell align="center">Consultora</TableCell>
                        <TableCell align="center">Técnico</TableCell>
                        <TableCell align="center">Analista</TableCell>
                      </TableRow>
                    </TableHead>
                    {visits?.map((data, index) => (
                      <TableBody key={index}>
                        <TableRow
                          className={styles.row_sheet}
                          hover
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          {data.categoria === "lunch" &&
                            <TableCell className="type-icon lunch" aria-label="Almoço" data-cooltipz-dir="right">
                              <p className={styles.table_data}><RestaurantIcon /></p>
                            </TableCell>}
                          {data.categoria === "comercial_tecnica" &&
                            <TableCell className="type-icon">
                              <NewTooltip title={'Comercial + Técnica'} placement={'top'}>
                                <p className={`${styles.table_data} comercial_tec`}><PeopleIcon /></p>
                              </NewTooltip>
                            </TableCell>}
                          {data.categoria === "comercial" &&
                            <TableCell className="type-icon">
                              <NewTooltip title={'Comercial'} placement={'top'}>
                                <p className={`${styles.table_data} comercial`}><RequestQuoteIcon /></p>
                              </NewTooltip>
                            </TableCell>}
                          {data.categoria === "pos_venda" &&
                            <TableCell className="type-icon">
                              <NewTooltip title={'Pós-Venda'} placement={'top'}>
                                <p className={`${styles.table_data} pos_venda`}><EngineeringIcon /></p>
                              </NewTooltip>
                            </TableCell>}
                          {data.categoria === "tecnica" &&
                            <TableCell className="type-icon">
                              <NewTooltip title={'Técnica'} placement={'top'}>
                                <p className={`${styles.table_data} tec`}><EngineeringIcon /></p>
                              </NewTooltip>
                            </TableCell>}
                          <TableCell align="center">{moment(data.data).format('DD/MM/YYYY')}</TableCell>
                          {data.categoria === 'comercial_tecnica' ?
                            <TableCell align="center">
                              <NewTooltip title={data.jornada?.etapa} placement={'top'}>
                                <p className={styles.table_data} style={{ backgroundColor: `${data.jornada?.cor}`, width: '40px' }}>{data.jornada && data.jornada.sigla}</p>
                              </NewTooltip>
                            </TableCell> :
                            <TableCell align="center">
                              <NewTooltip title={'Nenhum'} placement={'top'}>
                                <p className={styles.table_data}>N</p>
                              </NewTooltip>
                            </TableCell>
                          }
                          <TableCell align="center">
                            <Link className='link_black' to={`/visitas/${data.id}`} onClick={() => goPage(null, 'top')}>
                              <NewTooltip title={'Visualizar Visita'} placement={'top'}>
                                <p>{data.cliente}</p>
                              </NewTooltip>
                            </Link>
                          </TableCell>
                          <TableCell className={styles.consultora} align="center">
                            <p className={styles.table_data} style={{ backgroundColor: getMemberColor(data.uid) }}>{data.consultora}</p>
                          </TableCell>
                          <TableCell align="center">{data.tecnico}</TableCell>
                          <TableCell className={styles.consultora} align="center">
                            <p className={styles.table_data} style={{ backgroundColor: getMemberColor(data.representante?.id), color: data.representante?.nome ? "#fff" : '#000' }}>{data.representante?.nome || 'Nenhuma'}</p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {visits && visits.length === 0 &&
                      <TableBody>
                        <TableRow>
                          <TableCell align='center' colSpan={11}>
                            <p className={styles.value}>Nenhuma Visita Encontrada</p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    }
                  </Table>
                  {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    labelRowsPerPage="Visitas por página"
                    component="div"
                    count={visits ? visits.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                  <Button
                    disableElevation
                    sx={{ textTransform: 'capitalize', marginBottom: '1rem' }}
                    variant='contained'
                    aria-describedby={id}
                    disabled={!checkUser()}
                    startIcon={<AddCircle />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}>
                    Nova Visita
                  </Button>
                  <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box width={'100%'} component={'div'} flexDirection={'column'} display={'flex'}>
                      <ThemeProvider theme={theme}>
                        <Button onClick={() => handleVisit('comercial')} sx={{ textTransform: 'capitalize', padding: '0.5rem' }} startIcon={<RequestQuoteIcon sx={{ fill: '#0eb05f' }} />}>Comercial</Button>
                        <Button onClick={() => handleVisit('comercial_tecnica')} sx={{ textTransform: 'capitalize', padding: '0.5rem' }} startIcon={<PeopleIcon sx={{ fill: '#576af5' }} />}>Comercial + Técnica</Button>
                        <Button onClick={() => handleVisit('tecnica')} sx={{ textTransform: 'capitalize', padding: '0.5rem' }} startIcon={<EngineeringIcon sx={{ fill: '#1c256e' }} />}>Técnica</Button>
                        <Button onClick={() => handleVisit('pos_venda')} sx={{ textTransform: 'capitalize', padding: '0.5rem' }} startIcon={<EngineeringIcon sx={{ fill: '#e24725' }} />}>Pós-Venda</Button>
                      </ThemeProvider>
                    </Box>
                  </Popover>
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </div>
      </div>
      {customer?.representante && customer?.representante?.nome !== 'Nenhum' ?
        <div className={styles.panel_content_item}>
          <h2>Propostas</h2>
          <div className={styles.panel_content_box} style={{ padding: '1.5rem 2rem 1rem 1rem' }}>
            <form onSubmit={saveProposta}>
              {propostas && propostas.length > 0 ? propostas.map((data, index) => (
                <div key={index} className={[styles.panel_content_input]} style={{ alignItems: 'center' }}>
                  <NewTooltip title={propostas[index].ativo === true ? 'Desativar Comissão dessa Proposta' : 'Ativar Comissão dessa Proposta'} placement={'top'}>
                    <IconButton
                      onClick={() => ativeProposta(index)}
                      sx={{ height: '40px' }}>
                      {data.ativo ?
                        <StarIcon sx={{ fill: '#ffe44c' }} /> :
                        <StarBorderIcon sx={{ fill: '#ccc' }} />
                      }
                    </IconButton>
                  </NewTooltip>
                  <Autocomplete
                    value={data.kit || ''}
                    fullWidth
                    sx={{ alignItems: 'center', marginBottom: '0.2rem' }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        handlePropostaChange(index, 'kit', newValue)
                        console.log('a')
                      } else if (newValue && newValue.inputValue) {
                        handlePropostaChange(index, 'kit', newValue.inputValue)
                      } else {
                        handlePropostaChange(index, 'kit', newValue.nome);
                        handlePropostaChange(index, 'valor_inicial', newValue.valor)
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.nome);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          nome: `Adicionar "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable={true}
                    id="free-solo-with-text-demo"
                    options={propostasList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Opção Regular
                      return option.nome;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.nome}</li>}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params}
                        label="Kit Fotovoltaico"
                        type="search"
                      />
                    )}
                  />
                  <CurrencyInput
                    customInput={TextField}
                    style={{ margin: '0.5rem 0rem 0.7rem 0' }}
                    label="Valor Inicial"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    onValueChange={(value) => handlePropostaChange(index, 'valor_inicial', value)}
                    decimalsLimit={2}
                    value={data.valor_inicial || ''}
                    required
                    disabled={!viewSaveProposta}
                    fullWidth
                  />
                  <CurrencyInput
                    customInput={TextField}
                    style={{ margin: '0.5rem 0rem 0.7rem 0' }}
                    label="Valor Final"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    onValueChange={(value) => handlePropostaChange(index, 'valor_final', value)}
                    decimalsLimit={2}
                    value={data.valor_final || ''}
                    required
                    fullWidth
                    disabled={!viewSaveProposta}
                  />
                  <IconButton
                    color='error'
                    sx={{ height: '40px' }}
                    disabled={!viewSaveProposta}
                    // disabled={updateProposta() ? index < (customer.propostas && customer.propostas.length) : false}
                    onClick={() => removeProposta(index)}><DeleteForeverIcon /></IconButton>
                </div>
              )) :
                <h2 className='visit-aviso'>Nenhuma Proposta Encontrada</h2>

              }
              {viewSaveProposta ?
                <div className={styles.panel_content_input}>
                  <Button
                    disableElevation
                    variant='contained'
                    color='success'
                    type='submit'
                    startIcon={<CheckCircle />}
                    sx={{ textTransform: 'capitalize' }}>Salvar</Button>
                  <Button
                    disableElevation
                    variant='contained'
                    color='error'
                    startIcon={<CancelIcon />}
                    sx={{ textTransform: 'capitalize' }}
                    onClick={closeProposta}>Cancelar</Button>
                </div> : null
              }
            </form>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {!viewSaveProposta && (propostas && propostas.length > 0) ?
                <NewTooltip title={'Alterar Propostas'} placement='top'>
                  <IconButton
                    sx={{ borderRadius: '10px' }}
                    onClick={() => setViewSaveProposta(true)}>
                    <EditIcon />
                  </IconButton>
                </NewTooltip>
                : null}
              <Button
                disableElevation
                disabled={!checkUser()}
                variant='contained'
                startIcon={<AddCircle />}
                sx={{ textTransform: 'capitalize' }}
                onClick={addNewProposta}>Adicionar Proposta</Button>
            </Box>
          </div>
        </div> : null
      }
      <div className={styles.button_footer}>
        {(userRef?.cargo === 'Administrador' || userRef?.uid === customer.consultora_uid || userRef?.uid === customer.assistente) ?
          <div className={styles.button_footer_box}>
            <ThemeProvider theme={theme}>
              {checkComission ?
                <Link to={`/comissao/${idCustomers}`}>
                  <Button
                    disableElevation
                    variant='contained'
                    color='success'
                    startIcon={<RequestQuoteIcon />}
                    style={{ textTransform: 'capitalize' }}>
                    Visualizar Comissão
                  </Button>
                </Link> : null
              }
              <Button
                disableElevation
                variant='contained'
                color='secondary'
                disabled={userRef?.cargo !== 'Administrador' && userRef?.id !== customer.consultora_uid}
                startIcon={<MapsHomeWorkRoundedIcon />}
                onClick={() => setOpen({...open, register: true})}
                style={{ textTransform: 'capitalize' }}>
                {width > 600 ? 'Registrar Visita' : 'Reg. Visita'}
              </Button>
              <Button
                disableElevation
                variant='contained'
                startIcon={<EditNoteOutlinedIcon />}
                onClick={() => setOpen({ ...open, customer: true })}
                style={{ textTransform: 'capitalize' }}>
                Editar
              </Button>
              <Button
                disableElevation
                color='error'
                variant='contained'
                disabled={userRef?.cargo === 'Vendedor(a)' && userRef?.time && customer.consultora_uid !== userRef?.id}
                startIcon={<DeleteForeverIcon />}
                onClick={() => deleteCustomer()}
                style={{ textTransform: 'capitalize' }}>
                Excluir
              </Button>
            </ThemeProvider></div>
          : null}
      </div>
    </div>
  )
}

export default memo(CustomerProfile);
import { useState, memo, useMemo, useCallback, useEffect } from "react";

// Css
// import "cooltipz-css";
import styles from "../style.module.scss";
// import '../../styles/_filter.scss';

import { ReactComponent as ReportIcon } from '../../../images/icons/Report.svg';

import { PatternFormat } from "react-number-format";

import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import moment from "moment";
import styled from "@emotion/styled";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../data/theme";
import useUploadPhotos from "../../../hooks/useUploadPhotos";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import Swal from "sweetalert2";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from "react-router-dom";
import NewTooltip from "../../../components/Tooltip";

const CreateReport = ({ user, userRef }) => {
  const [result, setResult] = useState({ 
    result: 0,
    media_consumo: 0, 
    media_geracao: 0 ,
    instantaneo: '0'
  });
  const navigate = useNavigate();
  const { uploadImages } = useUploadPhotos();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    data: moment(new Date()).format('YYYY-MM-DD'),
    data_chamado: moment(new Date()).format('YYYY-MM-DD'),
    analista: '',
    cliente: '',
    email: '',
    telefone: '',
    cidade: ''
  });
  const [sistema, setSistema] = useState({
    potencia_sistema: '',
    potencia_inversor: '',
    placa_quantidade: '',
    placa_potencia: '',
    img: []
  });
  const [box, setBox] = useState([]);
  const [boxBenefits, setBoxBenefits] = useState([]);
  const [geracao, setGeracao] = useState([
    { mes: null, ano: null, kwh: 0, img: [] },
    { mes: null, ano: null, kwh: 0, img: [] },
    { mes: null, ano: null, kwh: 0, img: [] }
  ]);
  const [consumo, setConsumo] = useState([
    { mes: '', ano: '', consumo: 0, instantaneo: 0 },
    { mes: '', ano: '', consumo: 0, instantaneo: 0 },
    { mes: '', ano: '', consumo: 0, instantaneo: 0 }
  ]);
  const [report, setReport] = useState({
    consumo_media: 0,
    geracao_media: 0,
    consumo_atual: 0
  });
  const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const ano = ['2024', '2023', '2022', '2021', '2020', '2019'];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  useEffect(() => {
    if(userRef) {
      setInfo({...info, analista: userRef.nome_ref})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userRef])

  const calculatedValues = useMemo(() => {
    const resultGeracao = geracao.reduce((acc, data) => acc + Number(data.kwh), 0);
    const mediaGeracao = (resultGeracao / geracao.length).toFixed(2);

    const maxLength = Math.max(...boxBenefits.map(item => item.dados.length));

    const totalInjetadoIndex = Array.from({ length: maxLength }, (_, index) => {
      return boxBenefits.reduce((sum, item) => {
        return sum + (parseFloat(item.dados[index]?.injetado) || 0);
      }, 0);
    })

    const updatedReport = consumo.map((data, index) => {
      const instantaneo = (geracao[index].kwh - (Number(data.injetado) + (totalInjetadoIndex[index] || 0))).toFixed(2);
      const consumo = ((geracao[index].kwh - (Number(data.injetado) + (totalInjetadoIndex[index] || 0))) + Number(data.fornecido)).toFixed(2)
      return { ...data, instantaneo, consumo };
    });

    const consumoAtual = updatedReport.reduce((acc, data) => acc + Number(data.consumo), 0);
    const mediaConsumo = (consumoAtual / updatedReport.length).toFixed(2);
    const diferenca = (mediaConsumo - report.consumo_media);
    console.log(report.consumo_media)
    const resultFinal = ((diferenca / Number(report.consumo_media)) * 100).toFixed(2);

    return {
      resultGeracao,
      mediaGeracao,
      updatedReport,
      consumoAtual,
      mediaConsumo,
      diferenca,
      resultFinal,
      totalInjetadoIndex
    };
  }, [consumo, geracao, report, boxBenefits]);

  console.log(calculatedValues.totalInjetadoIndex)
  console.log(calculatedValues.updatedReport)
  console.log(geracao);

  const handleResult = (type) => {

      let msg = {
        consumo: '',
        geracao: ''
      };
      let ref = {
        consumo: '',
        geracao: ''
      };
      let background = {
        consumo: '#fdf6e4',
        geracao: '#fdf6e4'
      };
      let border = {
        consumo: '#f3c700',
        geracao: '#f3c700'
      };

      const mediaGeracao = calculatedValues.mediaGeracao;
      const diff = (calculatedValues.mediaGeracao - report.geracao_media).toFixed(2);
      const diff20 = ((mediaGeracao / 100) * 20) * -1
      const consumoAtual = calculatedValues.mediaConsumo
      

      if (diff === 0) {
        msg.geracao = <p>A geração média produzida de <b>{mediaGeracao} kWh</b> se <b>MANTÉM</b> igual a geração média estimada de <b>{report.geracao_media} kWh.</b></p>
        ref.geracao = `<p>A geração média produzida de <b>${mediaGeracao} kWh</b> se <b>MANTÉM</b> igual a geração média estimada de <b>${report.geracao_media} kWh.</b></p>`
        background.geracao = '#fdf6e4'
        border.geracao = '#f3c700'
      } else if (diff > diff20 && diff < 0) {
        msg.geracao = <p>A geração média produzida está dentro do esperado.</p>
        ref.geracao = `<p>A geração média produzida está dentro do esperado.</p>`
        background.geracao = '#eafde4'
        border.geracao = 'green'
      } else if (diff < diff20) {
        msg.geracao = <p>A geração média produzida de <b>{mediaGeracao} kWh</b> está <b>{String(diff).replace(/-/g, '')} kWh ABAIXO</b> da geração média estimada de <b>{report.geracao_media} kWh.</b></p>
        ref.geracao = `<p>A geração média produzida de <b>${mediaGeracao} kWh</b> está <b>${String(diff).replace(/-/g, '')} kWh ABAIXO</b> da geração média estimada de <b>${report.geracao_media} kWh.</b></p>`
        background.geracao = '#ffeded'
        border.geracao = 'red'
      } else {
        msg.geracao = <p>A geração média produzida de <b>{mediaGeracao} kWh</b> está <b>{String(diff).replace(/-/g, '')} kWh ACIMA</b> da geração média estimada de <b>{report.geracao_media} kWh.</b></p>
        ref.geracao = `<p>A geração média produzida de <b>${mediaGeracao} kWh</b> está <b>${String(diff).replace(/-/g, '')} kWh ACIMA</b> da geração média estimada de <b>${report.geracao_media} kWh.</b></p>`
        background.geracao = '#eafde4'
        border.geracao = 'green'
      }

      const resultConsumo = calculatedValues.resultFinal;

      if (resultConsumo === '0.00') {
        msg.consumo = <p>O consumo médio atual de <b>{consumoAtual} kWh</b> se <b>MANTÉM</b> igual ao consumo antes do sistema.</p>
        ref.consumo = `<p>O consumo médio de <b>${consumoAtual} kWp</b> se <b>MANTÉM</b> igual ao consumo antes do sistema.</p>`
        background.consumo = '#fdf6e4'
        border.consumo = '#f3c700'
      } else if (resultConsumo < 0) {
        msg.consumo = <p>O consumo médio de <b>{consumoAtual} kWh</b> está <b> {resultConsumo.replace(/-/g, '')}% MENOR</b> que o consumo antes do sistema.</p>
        ref.consumo = `<p>O consumo médio de <b>${consumoAtual} kWp</b> está <b> ${resultConsumo.replace(/-/g, '')}% MENOR</b> que o consumo antes do sistema.</p>`
        background.consumo = '#eafde4'
        border.consumo = 'green'
      } else if (resultConsumo > 0) {
        msg.consumo = <p>O consumo médio de <b>{consumoAtual} kWh</b> está <b> {resultConsumo.replace(/-/g, '')}% MAIOR</b> que o consumo antes do sistema.</p>
        ref.consumo = `<p>O consumo médio de <b>${consumoAtual} kWp</b> está <b> ${resultConsumo.replace(/-/g, '')}% MAIOR</b> que o consumo antes do sistema.</p>`
        background.consumo = '#ffeded'
        border.consumo = 'red'
      }

    if(type === 'consumo') {
      setConsumo(calculatedValues.updatedReport);
      setReport({ ...report, consumo_atual: calculatedValues.mediaConsumo });
      setResult({
          ...result,
          media_geracao: calculatedValues.mediaGeracao,
          media_consumo: calculatedValues.mediaConsumo,
          result_geracao: (calculatedValues.mediaGeracao - report.geracao_media).toFixed(2),
          result: resultConsumo,
          text_consumo: msg.consumo,
          text_consumo_ref: ref.consumo,
          color_consumo: {
            background: background.consumo,
            border: border.consumo,
          },
      });
    } else {
      setResult({
          ...result,
          media_geracao: calculatedValues.mediaGeracao,
          media_consumo: calculatedValues.mediaConsumo,
          result_geracao: (calculatedValues.mediaGeracao - report.geracao_media).toFixed(2),
          text_geracao: msg.geracao,
          text_geracao_ref: ref.geracao,
          color_geracao: {
            background: background.geracao,
            border: border.geracao,
          }, 
          result: calculatedValues.resultFinal
      });
      setReport({ ...report, consumo_atual: calculatedValues.mediaConsumo });
    }
};

  const handleInput = (event, index, type, ind) => {
    const file = event.target.files[0];
    const fileFormated = { file: URL.createObjectURL(file), complete: file };
    if(type === 'box') {
      handleChangeBoxPhoto(fileFormated, index);
    } else if(type === 'geração') {
      handleChangeGeracaoPhoto(fileFormated, index);
    } else if(type === 'sistema') {
      handleChangeSistemaPhoto(fileFormated, index);
    } else if(type === 'consumo') {
      handleChangeConsumoPhoto(fileFormated, index);
    } else if(type === 'beneficiaria') {
      handleChangeBenefitsPhoto(fileFormated, index, ind);
    }
  };

  const handleDeletePhoto = useCallback((index, ind, type) => {
    if(type === 'box') {
      const updatedBox = [...box];
      const updatedArquivo = [...updatedBox[index].arquivo];
      updatedArquivo.splice(ind, 1);
      updatedBox[index] = { ...updatedBox[index], arquivo: updatedArquivo };
      setBox(updatedBox);
    } else if(type === 'geração') {
      const updatedReport = [...geracao];
      updatedReport[index] = { ...updatedReport[index], img: null };
      console.log(updatedReport)
      console.log(geracao)
      setGeracao(updatedReport);  
    } else if(type === 'sistema') {
      let updatedReport = sistema;
      const sistemaImg = sistema.img;
      console.log(sistemaImg)
      sistemaImg.splice(index, 1);
      updatedReport = { ...updatedReport, img: sistemaImg };
      setSistema(updatedReport);  
    } else if(type === 'consumo') {
      const updatedReport = [...consumo];
      updatedReport[index] = { ...updatedReport[index], img: null };
      setConsumo(updatedReport);   
    } else if(type === 'beneficiaria') {
      const updatedReport = [...boxBenefits];
      updatedReport[ind]['dados'][index] = { ...updatedReport[ind]['dados'][index], img: null };
      setBoxBenefits(updatedReport);   
    }
  }, [box, geracao, sistema, consumo, boxBenefits]);

  const handleChangeBoxPhoto = useCallback((fileFormated, index) => {
    const updatedReport = [...box];
    updatedReport[index].arquivo.push(fileFormated);
    setBox(updatedReport);
  }, [box]);

  const handleChangeSistemaPhoto = useCallback((fileFormated, index) => {

    let updatedReport = sistema;
    const sistemaImg = sistema.img
    sistemaImg.push(fileFormated);

    updatedReport = { ...updatedReport, img: sistemaImg };

    setSistema(updatedReport);

    console.log(sistema.img)
    console.log(fileFormated)

  }, [sistema]);

  const handleChangeGeracaoPhoto = useCallback((fileFormated, index) => {
    const updatedReport = [...geracao];
    updatedReport[index] = { ...updatedReport[index], img: fileFormated };
    setGeracao(updatedReport);
  }, [geracao]);

  const handleChangeConsumoPhoto = useCallback((fileFormated, index) => {
    const updatedReport = [...consumo];
    updatedReport[index] = { ...updatedReport[index], img: fileFormated };
    setConsumo(updatedReport);
    console.log(consumo)
  }, [consumo]);

  const handleChangeBenefitsPhoto = useCallback((fileFormated, index, ind) => {
    const updatedReport = [...boxBenefits];
    updatedReport[ind]['dados'][index] = { ...updatedReport[ind]['dados'][index], img: fileFormated };
    setBoxBenefits(updatedReport);
    console.log(boxBenefits)
  }, [boxBenefits]);

  const handleChangeGeracao = useCallback((e, index) => {
    const { name, value } = e.target;
    const updatedReport = [...geracao];
    updatedReport[index][name] = value;
    console.log(updatedReport)
    setGeracao(updatedReport);
  }, [geracao]);

  const handleChangeConsumo = useCallback((e, index) => {
    const { name, value } = e.target;
    const updatedReport = [...consumo];
    updatedReport[index][name] = value;
    setConsumo(updatedReport);
  }, [consumo]);

  const handleChangeBox = (e, index) => {
    const { name, value } = e.target;
    const updatedBox = [...box]; // Copia o array 'box' para evitar mutações diretas
    // Atualiza apenas o 'titulo' do objeto no índice especificado
    updatedBox[index][name] = value;

    // Atualiza o estado 'box' com o novo array modificado
    setBox(updatedBox);
};

const handleChangeBoxBenefits = (e, index) => {
    const { name, value } = e.target;
    const updatedBox = [...boxBenefits]; // Copia o array 'box' para evitar mutações diretas
    // Atualiza apenas o 'titulo' do objeto no índice especificado
    updatedBox[index][name] = value;

    // Atualiza o estado 'box' com o novo array modificado
    setBoxBenefits(updatedBox);
};

  const handleChangeBoxDados = (e, ind, index) => {
    const { name, value } = e.target;
    console.log(ind, index)
    const updatedBoxDados = [...boxBenefits[index].dados]; // Copia o array 'box' para evitar mutações diretas
    const updatedBox = [...boxBenefits]; // Copia o array 'box' para evitar mutações diretas
    // // Atualiza apenas o 'titulo' do objeto no índice especificado
    updatedBoxDados[ind][name] = value;
    updatedBox[index]['dados'] = updatedBoxDados;

    setBoxBenefits(updatedBox);
};

// console.log(boxBenefits[0].dados)

const handleMediaBox = (ind, index) => {
  const boxRef = [...boxBenefits[index].dados];
  const updatedBox = [...boxBenefits];
  
  const resultInjecao = boxRef.reduce((acc, data) => acc + Number(data.injetado), 0);
  const mediaInjecao = (resultInjecao / boxRef.length).toFixed(2);

  const resultFornecido = boxRef.reduce((acc, data) => acc + Number(data.fornecido), 0);
  const mediaFornecido = (resultFornecido / boxRef.length).toFixed(2);

  const diferenca = (boxBenefits[index].valor - mediaFornecido).toFixed(2);
  const resultFinal = ((diferenca / mediaFornecido) * 100).toFixed(2);

  let msg = '';
  let msgRef = '';
  let background = '#fdf6e4';
  let border = '#f3c700';

  console.log(resultFinal)

  if (resultFinal === '0.00') {
    msg = <p>O consumo atual de <b>{boxBenefits[index].valor} kWh</b> se mantém igual ao consumo anterior de <b>{boxBenefits[index].valor} kWh.</b></p>
    msgRef = `<p>O consumo atual de <b>${boxBenefits[index].valor} kWh</b> se mantém igual ao consumo anterior de <b>${boxBenefits[index].valor} kWh.</b></p>`
    background = '#fdf6e4'
    border = '#f3c700'
  } else if (resultFinal < 0) {
    msg = <p>O consumo atual de <b>{mediaFornecido} kWh</b> está <b>{String(resultFinal).replace(/-/g, '')}% ACIMA</b> do consumo anterior ao projeto, que era de <b>{boxBenefits[index].valor} kWh.</b></p>
    msgRef = `<p>O consumo atual de <b>${mediaFornecido} kWh</b> está <b>${String(resultFinal).replace(/-/g, '')}% ACIMA</b> do consumo anterior ao projeto, que era de <b>${boxBenefits[index].valor} kWh.</b></p>`
    background = '#ffeded'
    border = 'red'
  } else {
    msg = <p>O consumo atual de <b>{mediaFornecido} kWh</b> está <b>{String(resultFinal).replace(/-/g, '')}% ABAIXO</b> do consumo anterior ao projeto, que era de <b>{boxBenefits[index].valor} kWh.</b></p>
    msgRef = `<p>O consumo atual de <b>${mediaFornecido} kWh</b> está <b>${String(resultFinal).replace(/-/g, '')}% ABAIXO</b> do consumo anterior ao projeto, que era de <b>${boxBenefits[index].valor} kWh.</b></p>`
    background = '#eafde4'
    border = 'green'
  }

  updatedBox[index]['media_injecao'] = mediaInjecao
  updatedBox[index]['media_consumo'] = mediaFornecido
  updatedBox[index]['resultado'] = resultFinal
  updatedBox[index]['resultado_texto'] = msg
  updatedBox[index]['resultado_texto_ref'] = msgRef
  updatedBox[index]['resultado_cor'] = {
    background: background,
    border: border
  }

  console.log(mediaInjecao);
  console.log(mediaFornecido);
  console.log(resultFinal);

  console.log(updatedBox)
    
  setBoxBenefits(updatedBox);
}

  const changeBoxBenefits = (type) => {
    const newBox = [...boxBenefits];
    if(type === 'add') {
      newBox.push({
        titulo: 'Beneficiária',
        codigo: '0',
        valor: '0',
        media_injecao: '',
        media_consumo: '',
        resultado: '',
        img: [],
        dados: [{
          mes: '',
          ano: '',
          injetado: '',
          fornecido: ''
        },{
          mes: '',
          ano: '',
          injetado: '',
          fornecido: ''
        },{
          mes: '',
          ano: '',
          injetado: '',
          fornecido: ''
        }]
      })
    } else {
      newBox.pop();
    }
    setBoxBenefits(newBox);
  }

  const changeBox = (type) => {
    const newBox = [...box];
    if(type === 'add') {
      newBox.push({
        titulo: '',
        observacao: '',
        arquivo: []
      })
    } else {
      newBox.pop();
    }
    setBox(newBox);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('INFO', info)
    // console.log('SISTEMA', sistema)
    // console.log('REPORT', report)
    // console.log('CONSUMO', consumo)
    // console.log('GERAÇÃO', geracao)
    // console.log('BENEFICIOS', boxBenefits)
    // console.log('BOX', box)
    // console.log('RESULT', result)

    try {

      const imageGeracao = geracao.every(obj => {
        return obj.img && Object.keys(obj.img).length > 0
      })

      const imageConsumo = consumo.every(obj => {
        return obj.img && Object.keys(obj.img).length > 0
      })

      const imageBox = box.every(obj => {
        return obj.arquivo && Object.keys(obj.arquivo).length > 0
      })

      const imageBenefits = boxBenefits.map(data =>
        data.dados.every(dado => dado.hasOwnProperty('img') && dado.img !== null)
      );

      const verifyBenefits = imageBenefits.every(value => value === true);

      const imageSistema = sistema.img ? true : false   

      if(!imageGeracao) {
        return Swal.fire({
          title: 'Atenção',
          html: `Envie todas as imagens de <b>Monitoramento da Geração.</b> para prosseguir.`,
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Fechar",
        })
      }

      if(!imageConsumo) {
        return Swal.fire({
          title: 'Atenção',
          html: `Envie todas as imagens do <b>Consumo</b> para prosseguir.`,
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Fechar",
        })
      }

      if(!imageBox) {
        return Swal.fire({
          title: 'Atenção',
          html: `Envie pelo menos 1 imagem ou video no <b>Campo adicional</b> para prosseguir.`,
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Fechar",
        })
      }

      if(!imageSistema) {
        return Swal.fire({
          title: 'Atenção',
          html: `Envie imagem do <b>Sistema</b> para prosseguir.`,
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Fechar",
        })
      }

      if(!verifyBenefits) {
        return Swal.fire({
          title: 'Atenção',
          html: `Envie todas as imagens da <b>Beneficiária</b> para prosseguir.`,
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Fechar",
        })
      }

      Swal.fire({
        title: 'Geração x Consumo',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (resultt) => {
        if(resultt.isConfirmed) {
          setLoading(true);

          const resultRef = {...result};

          resultRef.text_consumo = '';
          resultRef.text_geracao = '';

          await addDoc(collection(dataBase, 'Relatorio'), {
            createAt: new Date(),
            data: moment(new Date()).format('DD / MMMM / YYYY - HH:mm'),
            setor: 'Pós-Venda',
            info: info,
            report: report,
            result: resultRef,
          }).then(async (resultt) => {
      
            let geracaoRef = [];
            let boxRef = [];
            let sistemaRef = [];
            let consumoRef = [];
            let beneficiariaRef = [];

            sistemaRef.push(...sistema.img);

            geracao.forEach(({ img }) => {
              geracaoRef.push(img);
            })

            box.forEach(({ arquivo }) => {
              boxRef.push(arquivo);
            })

            consumo.forEach(({ img }) => {
              consumoRef.push(img);
            })

            console.log(consumoRef)

            boxBenefits.forEach((data, index) => {
              beneficiariaRef[index] = data.dados.map(({ img }) => img);
            });

            let file = {
              sistema: {
                url: [],
                ref: []
              },
              consumo: {
                url: [],
                ref: []
              },
              geracao: {
                url: [],
                ref: []
              }
            };

            const resultSistema = await uploadImages(sistemaRef, 'sistema', 0, resultt.id, 'Relatorio')
              file.sistema.url = resultSistema.map(object => object.downloadURL);
              file.sistema.ref = resultSistema.map(object => object.fullPath);

            const resultConsumo = await uploadImages(consumoRef, 'consumo', 0, resultt.id, 'Relatorio')
              file.consumo.url = resultConsumo.map(object => object.downloadURL);
              file.consumo.ref = resultConsumo.map(object => object.fullPath);

            let newConsumo = consumo.map((item, index) => {
                return {...item, img: {ref: file.consumo.ref[index], url: file.consumo.url[index]}}
            })

            let newSistema = sistema;
            const sistemaImg = sistema.img;

            newSistema.img = sistemaImg.map((item, index) => {
              return {ref: file.sistema.ref[index], url: file.sistema.url[index]}
            })

            // {ref: file.sistema.ref[0], url: file.sistema.url[0]}

            console.log(newSistema);
            console.log(file);

            const resultadosBenefits = await Promise.all(beneficiariaRef.map(async (array, index) => {
              const resultadoArray = await uploadImages(array, `benefits_${index}`, index, 'Relatorio');
                return {
                  url: resultadoArray.map(object => object.downloadURL),
                  ref: resultadoArray.map(object => object.fullPath)
                };
              }))


              const newBenefits = boxBenefits.map((item, index) => {
                const novosDados = item.dados.map((ref, idx) => ({
                    ...ref,
                    img: {
                        ref: resultadosBenefits[index]['ref'][idx],
                        url: resultadosBenefits[index]['url'][idx]
                    }
                }));
                return { ...item, dados: novosDados, resultado_texto: '' };
            });
      
            const resultGeracao = await uploadImages(geracaoRef, 'geracao', 0, resultt.id, 'Relatorio')
              file.geracao.url = resultGeracao.map(object => object.downloadURL);
              file.geracao.ref = resultGeracao.map(object => object.fullPath);
      
            const resultadosBox = await Promise.all(boxRef.map(async (array, index) => {
              const resultadoArray = await uploadImages(array, `box_${index}`, index, resultt.id, 'Relatorio');
              return {
                url: resultadoArray.map(object => object.downloadURL),
                ref: resultadoArray.map(object => object.fullPath)
              };
            }))
      
            const objetoFinal = resultadosBox.reduce((obj, resultado, index) => {
              obj[`${index}`] = resultado;
              return obj;
            }, {});
      
            let newGeracao = geracao.map((item, index) => {
              return {...item, img: {ref: file.geracao.ref[index], url: file.geracao.url[index]}}
            })
      
            let newBox = box.map((item, index) => {
              return {...item, arquivo: objetoFinal[index]}
            })
      
            await updateDoc(doc(dataBase, 'Relatorio', resultt.id), {
              box: newBox, 
              geracao: newGeracao,
              beneficiaria: newBenefits,
              sistema: newSistema,
              consumo: newConsumo
            }).then(() => {
              setLoading(false);
              Swal.fire({
                title: 'Geração x Consumo',
                html: `O Relatório foi salvo com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#111",
                confirmButtonText: "Ok",
                })
                navigate('/relatorio');
              })
          })
        }
      })

    } catch (error) {
      console.error('Ocorreu um erro: ', error);
      setLoading(false);
    }
  }


  return (
    <div className={styles.container_panel}>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer + 111,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          overflow: 'hidden'
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h3>Salvando...</h3>
      </Backdrop>
      <div className={styles.title_panel}>
        <ReportIcon className={styles.prospecction_icon}/>
        <h2>Relatório - Geração x Consumo</h2>
      </div>
      <div className={styles.report_container}>
        <ThemeProvider theme={theme}>
        <form onSubmit={onSubmit} autoComplete="false">
          <div className={styles.report_container_box}> 
            <div className={styles.report_header}>
              <h3>Informações</h3>
            </div>
            <div className={styles.report_form}>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Nome da Analista'
                  type="text"
                  value={info.analista || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  disabled
                  onChange={(e) => setInfo({...info, analista: e.target.value})} />
                <TextField fullWidth
                  label='Data do Relatório'
                  type="date"
                  value={info.data || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setInfo({...info, data: e.target.value})} />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Cliente'
                  type="text"
                  value={info.cliente || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setInfo({...info, cliente: e.target.value})} />
                <TextField fullWidth
                  label='Email'
                  type="email"
                  value={info.email || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setInfo({...info, email: e.target.value})} />
                <PatternFormat
                  fullWidth
                  customInput={TextField}
                  onChange={(e) => setInfo({...info, telefone: e.target.value})}
                  format="(##) #####-####"
                  mask="_"
                  placeholder="(00) 00000-0000"
                  value={info.telefone || ''}
                  label="Telefone"
                  minLength={9}
                  variant="outlined"
                  color="primary"
                  required
                  autoComplete="off"
                />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Cidade'
                  type="text"
                  value={info.cidade || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setInfo({...info, cidade: e.target.value})} />
                <TextField fullWidth
                  label='Data do Chamado'
                  type="date"
                  value={info.data_chamado || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setInfo({...info, data_chamado: e.target.value})} />
              </div>
            </div>
          </div>
          <div className={styles.report_container_box}> 
            <div className={styles.report_header}>
              <h3>Sistema</h3>
            </div>
            <div className={styles.report_form}>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Potência do Sistema (kWp)'
                  type="number"
                  value={sistema.potencia_sistema || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setSistema({...sistema, potencia_sistema: e.target.value})} />
                <TextField fullWidth
                  label='Potência do Inversor (kW)'
                  type="number"
                  value={sistema.potencia_inversor || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setSistema({...sistema, potencia_inversor: e.target.value})} />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Quantidade de Placa'
                  type="number"
                  value={sistema.placa_quantidade || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setSistema({...sistema, placa_quantidade: e.target.value})} />
                <TextField fullWidth
                  label='Potência individual da Placa (Wp)'
                  type="number"
                  value={sistema.placa_potencia || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setSistema({...sistema, placa_potencia: e.target.value})} />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Potência Total das Placas (Wp) [Automático]'
                  type="number"
                  disabled
                  value={sistema.placa_potencia * sistema.placa_quantidade || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                   />
              </div>
              <div className={styles.report_input}>
                  {sistema.img.length > 0 && 
                    sistema.img.map((data, index) => (
                      <div className={styles.report_img}>
                        <img key={index} src={data.file} alt="" />
                        <button onClick={() => handleDeletePhoto(index, 0, 'sistema')}><CloseIcon sx={{ fontSize: '20px' }} /></button>
                      </div>
                    ))
                  }
              </div>
              <div className={styles.report_input} style={{ flexDirection: 'column' }}>
                <b className={styles.legend}>(Máx: 2 imagens)</b>
                <NewTooltip title={sistema.img.length >= 2 ? 'Só é permitido até 2 imagens. Exclua uma imagem para adicionar outra' : ''} placement={'top'}>
                    <span>
                      <Button
                        disableElevation
                        component="label"
                        color='secondary'
                        variant="contained"
                        type='file'
                        disabled={sistema.img.length >= 2}
                        sx={{ textTransform: 'capitalize' }}
                        onChange={(e) => handleInput(e, 0, 'sistema')}
                        startIcon={<UpgradeIcon />}>
                        <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg" />
                        Enviar Imagem
                      </Button>
                    </span>
                </NewTooltip>
                </div>
            </div>
          </div>
          <div className={styles.report_container_box}> 
            <div className={styles.report_header}>
              <h3>Geração</h3>
            </div>
            <div className={styles.report_form}>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Unidade Geradora'
                  type="number"
                  value={report.unidade_geradora || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onChange={(e) => setReport((prevReport) => ({
                    ...prevReport,
                    unidade_geradora: e.target.value,
                  }))} />
                <TextField fullWidth
                  label='Média de Consumo antes da instalação'
                  type="number"
                  value={report.consumo_media || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onBlur={() => handleResult('consumo')}
                  onChange={(e) => setReport((prevReport) => ({
                    ...prevReport,
                    consumo_media: e.target.value,
                  }))} />
                <TextField fullWidth
                  label='Média da Geração Estimada'
                  type="number"
                  value={report.geracao_media || ''}
                  variant="outlined"
                  autoComplete="off"
                  required
                  onBlur={() => handleResult('geração')}
                  onChange={(e) => setReport({...report, geracao_media: e.target.value})} />
              </div>
              <div className={styles.report_input}>
                <h4>Monitoramento da Geração dos últimos 3 meses</h4>
              </div>
              {geracao.map((item, index) => (
                <><div key={index} className={styles.report_input}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Mês</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      name="mes"
                      value={item.mes ?? ''}
                      label="Mês"
                      required
                      onChange={(e) => handleChangeGeracao(e, index)}>
                      {meses.map((data, index) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Ano</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      name="ano"
                      value={item.ano ?? ''}
                      label="Ano"
                      required
                      onChange={(e) => handleChangeGeracao(e, index)}>
                      {ano.map((data, index) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField fullWidth
                    label='kWh'
                    name="kwh"
                    type="number"
                    value={item.kwh ?? ''}
                    variant="outlined"
                    autoComplete="off"
                    required
                    onBlur={() => handleResult('geração')}
                    onChange={(e) => handleChangeGeracao(e, index)} />
                  <TextField fullWidth
                    label='kWh Estimado'
                    name="estimado"
                    type="number"
                    sx={{
                      backgroundColor: "#79ff792b",
                      '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                        '& fieldset': { // Seleciona a borda do TextField
                          borderColor: 'green', // Define a cor da borda
                        },
                        '&:hover fieldset': { // Estilo da borda quando hover
                          borderColor: 'green',
                        },
                        '&.Mui-focused fieldset': { // Estilo da borda quando focado
                          borderColor: 'green',
                        },
                      },
                    }}
                    value={item.estimado ?? ''}
                    variant="outlined"
                    autoComplete="off"
                    required
                    onChange={(e) => handleChangeGeracao(e, index)} />
                </div>
                <div className={styles.report_input}>
                  {item.img?.file && 
                    <div className={styles.report_img}>
                      <img src={item.img?.file} alt="" />
                      <button onClick={() => handleDeletePhoto(index, 0, 'geração')}><CloseIcon sx={{ fontSize: '20px' }} /></button>
                    </div>
                  }
                </div>
                <div className={styles.report_input}>
                    <Button
                      disableElevation
                      component="label"
                      color='secondary'
                      variant="contained"
                      type='file'
                      sx={{ textTransform: 'capitalize' }}
                      onChange={(e) => handleInput(e, index, 'geração')}
                      startIcon={<UpgradeIcon />}>
                      <VisuallyHiddenInput type="file" accept="image/png,image/jpeg" />
                      Enviar Imagem
                    </Button>
                </div>
                </>
              ))}
            </div>
          </div>
          <div className={styles.report_container_box}> 
            <div className={styles.report_header}>
              <h3>Resumo da Geração</h3>
            </div>
            <div className={styles.report_form} style={{ gap: '1rem' }}>
              <div className={styles.report_input}>
                <div className={styles.report_text}>
                  <h4>Geração Média Estimada do Projeto</h4>
                  <p><b>{report.geracao_media}</b> kWh</p>
                </div>
            </div>
            <h4>Geração Produzida</h4>
            <div className={styles.report_text_container}>
              {geracao.map((data, index) => (
                <div key={index} className={styles.report_text}>
                  <p>{data.mes ?? 'Mês'} / {data.ano ?? 'Ano'} = <b>{data.kwh}</b> kWh</p>
                </div>
              ))}
            </div>
            </div>
          </div>
          <div className={styles.report_container_box}> 
            <div className={styles.report_header}>
              <h3>Conclusão</h3>
            </div>
            <div className={styles.report_form}>
              <div className={styles.report_text_container}>
                <div className={styles.report_text}>
                  <h4>Geração Média Estimada Mês</h4>
                  <p><b>{report.geracao_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Geração Média Produzida Mês</h4>
                  <p><b>{result.media_geracao}</b> kWh</p>
                </div>
              </div>
              <div className={styles.report_input}>
                <div style={{ backgroundColor: result.color_geracao?.background ?? '#fdf6e4',
                  padding: '1rem',
                  borderRadius: '5px',
                  border: `1px solid ${result.color_geracao?.border ?? "#f3c700"}`
                 }} className={styles.report_text}>
                  <h4>Diferença de Geração</h4>
                  {result.text_geracao ?? <p>A geração média produzida de <b>{result.media_geracao} kWh</b> se <b>MANTÉM</b> igual a geração média estimada de <b>{report.geracao_media} kWh.</b></p>}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.report_container_box}> 
            <div className={styles.report_header}>
              <h3>Consumo</h3>
            </div>
            <div className={styles.report_form}>
              <div className={styles.report_input}>
                <h4>Média de Consumo Após a Instalação</h4>
              </div>
              {consumo.map((item, index) => (
                <><div key={index} className={styles.report_input}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Mês</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      name="mes"
                      value={item.mes ?? ''}
                      label="Mês"
                      required
                      onChange={(e) => handleChangeConsumo(e, index)}>
                      {meses.map((data, index) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Ano</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      name="ano"
                      value={item.ano ?? ''}
                      label="Ano"
                      required
                      onChange={(e) => handleChangeConsumo(e, index)}>
                      {ano.map((data, index) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField fullWidth
                    label='Injetado p/ Conce (kWh)'
                    name="injetado"
                    type="number"
                    color="error"
                    sx={{
                      '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                        '& fieldset': { // Seleciona a borda do TextField
                          borderColor: '#e3bf00', // Define a cor da borda
                        },
                        '&:hover fieldset': { // Estilo da borda quando hover
                          borderColor: '#e3bf00',
                        },
                        '&.Mui-focused fieldset': { // Estilo da borda quando focado
                          borderColor: '#e3bf00',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: '#111', // Define a cor padrão do título
                        '&.Mui-focused': {
                          color: '#111', // Define a cor do título quando focado
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: '#111', // Define a cor da fonte
                        backgroundColor: '#fef8da'
                      },
                    }}
                    value={item.injetado || ''}
                    variant="outlined"
                    autoComplete="off"
                    required
                    onBlur={() => handleResult('consumo')}
                    onChange={(e) => handleChangeConsumo(e, index)} />
                  <TextField fullWidth
                    label='Fornec. Conce (kWh)'
                    name="fornecido"
                    type="number"
                    sx={{
                      '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                        '& fieldset': { // Seleciona a borda do TextField
                          borderColor: 'red', // Define a cor da borda
                        },
                        '&:hover fieldset': { // Estilo da borda quando hover
                          borderColor: 'red',
                        },
                        '&.Mui-focused fieldset': { // Estilo da borda quando focado
                          borderColor: 'red',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: '#111', // Define a cor do título
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: '#111', // Define a cor da fonte
                        backgroundColor: '#ffeded'
                      },
                    }}
                    value={item.fornecido || ''}
                    variant="outlined"
                    autoComplete="off"
                    required
                    onBlur={() => handleResult('consumo')}
                    onChange={(e) => handleChangeConsumo(e, index)} />
                  <TextField fullWidth
                    label='Geração (kWh)'
                    name="kwh"
                    type="number"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#111",
                    },
                    '& input:disabled': {
                      bgcolor: '#17ff172b', // Cor de fundo desativada
                      borderColor: 'green', // Cor da borda desativada
                    },
                    '& label.Mui-disabled': {
                      color: '#111', // Cor do label desativado
                    },
                   }}
                    value={geracao[index].kwh || ''}
                    variant="outlined"
                    autoComplete="off"
                    disabled
                    required
                    onBlur={() => handleResult('geração')}
                    onChange={(e) => handleChangeGeracao(e, index)} />
                  <TextField fullWidth
                    label='Instantâneo (kWh) [A]'
                    name="consumo_instantaneo"
                    type="number"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                    },
                   }}
                    value={item.instantaneo || ''}
                    variant="outlined"
                    autoComplete="off"
                    disabled />
                  <TextField fullWidth
                    label='Consumo Total (kWh)'
                    name="consumo_instantaneo"
                    type="number"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }
                    }}
                    value={item.consumo || ''}
                    variant="outlined"
                    autoComplete="off"
                    disabled />
                </div>
                <div className={styles.report_input}>
                  {item.img?.file && 
                    <div className={styles.report_img}>
                      <img src={item.img?.file} alt="" />
                      <button onClick={() => handleDeletePhoto(index, 0, 'consumo')}><CloseIcon sx={{ fontSize: '20px' }} /></button>
                    </div>
                  }
                </div>
                <div className={styles.report_input}>
                    <Button
                      disableElevation
                      component="label"
                      color='secondary'
                      variant="contained"
                      type='file'
                      sx={{ textTransform: 'capitalize' }}
                      onChange={(e) => handleInput(e, index, 'consumo')}
                      startIcon={<UpgradeIcon />}>
                      <VisuallyHiddenInput type="file" accept="image/png,image/jpeg" />
                      Enviar Imagem
                    </Button>
                </div>
                </>
              ))}
              {/* <div className={styles.report_input}>
                <IconButton
                color="success"
                onClick={() => handleChangeInput('add')}>
                  <AddCircleOutlineIcon />
                </IconButton>
              {consumo.length > 3 && 
                <IconButton
                color="error"
                onClick={() => handleChangeInput('delete')}>
                  <CancelOutlinedIcon />
                </IconButton>
              }
              </div> */}
              <div className={styles.report_text_container}>
                <div className={styles.report_text}>
                  <h4>Média Consumo <mark>Antes</mark> do Sistema</h4>
                  <p><b>{report.consumo_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Consumo <mark>Após</mark> o Sistema</h4>
                  <p><b>{report.consumo_atual}</b> kWh</p>
                </div>
              </div>
              <div className={styles.report_input}>
                <div style={{ backgroundColor: result.color_consumo?.background ?? '#fdf6e4',
                  padding: '1rem',
                  borderRadius: '5px',
                  border: `1px solid ${result.color_consumo?.border ?? "#f3c700"}`
                 }} className={styles.report_text}>
                  <h4>Resultado de Consumo</h4>
                  {result.text_consumo ?? <p>O consumo atual se <b>MANTÉM</b> igual ao consumo anterior</p>}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.report_container_box}> 
            <div className={styles.report_header}>
              <h3>Conclusão Final</h3>
            </div>
            <div className={styles.report_form}>
              <div className={styles.report_text_container}>
                <div className={styles.report_text}>
                  <h4>Média Consumo Antes</h4>
                  <p><b>{report.consumo_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Consumo Atual</h4>
                  <p><b>{report.consumo_atual}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Geração Estimada do Projeto</h4>
                  <p><b>{report.geracao_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Geração Produzida</h4>
                  <p><b>{result.media_geracao}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Estimada x Produzida</h4>
                  <p><b>{report.geracao_media}</b> kWh x <b>{result.media_geracao}</b> kWh</p>
                </div>
              </div>
            </div>
          </div>
          {boxBenefits && boxBenefits.map((data, index) => (
            <div key={index} className={styles.report_container_box}> 
              <div className={styles.report_header}>
                <input 
                type="text"
                name="titulo"
                value={data.titulo ?? ''}
                autoComplete="off"
                style={{ width: `${data.titulo.length * 10 + 5}px`, textAlign: 'center'  }}
                onChange={(e) => handleChangeBoxBenefits(e, index)} />
                <div className={styles.title_content}>
                  <h3>UC: </h3>
                  <input 
                  type="text"
                  name="codigo"
                  value={data.codigo ?? ''}
                  autoComplete="off"
                  style={{ width: `${data.codigo.length * 10 + 5}px`  }}
                  onChange={(e) => handleChangeBoxBenefits(e, index)} />
                </div>
                <div className={styles.title_content}>
                  <h3>Média do Consumo Antes do Projeto:</h3>
                  <input 
                  type="text"
                  name="valor"
                  value={data.valor ?? ''}
                  autoComplete="off"
                  style={{ width: `${data.valor.length * 10 + 5}px`  }}
                  onChange={(e) => handleChangeBoxBenefits(e, index)} />
                  <h3>kWh</h3>
                </div>
              </div>
              <div className={styles.report_form}>
                {/* <div className={styles.report_input}>
                  <h4>Média de Consumo Após a Instalação</h4>
                </div> */}
                {data.dados && data.dados.map((item, ind) => (
                  <><div key={ind} className={styles.report_input}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">Mês</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        name="mes"
                        value={item.mes ?? ''}
                        label="Mês"
                        required
                        onChange={(e) => handleChangeBoxDados(e, ind, index)}>
                        {meses.map((data, index) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">Ano</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        name="ano"
                        value={item.ano ?? ''}
                        label="Ano"
                        required
                        onChange={(e) => handleChangeBoxDados(e, ind, index)}>
                        {ano.map((data, index) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField fullWidth
                      label='Injetado p/ Conce (kWh)'
                      name="injetado"
                      type="number"
                      color="error"
                      sx={{
                        '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                          '& fieldset': { // Seleciona a borda do TextField
                            borderColor: '#e3bf00', // Define a cor da borda
                          },
                          '&:hover fieldset': { // Estilo da borda quando hover
                            borderColor: '#e3bf00',
                          },
                          '&.Mui-focused fieldset': { // Estilo da borda quando focado
                            borderColor: '#e3bf00',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: '#111', // Define a cor padrão do título
                          '&.Mui-focused': {
                            color: '#111', // Define a cor do título quando focado
                          },
                        },
                      }}
                      InputProps={{
                        sx: {
                          color: '#111', // Define a cor da fonte
                          backgroundColor: '#fef8da'
                        },
                      }}
                      value={item.injetado || ''}
                      variant="outlined"
                      autoComplete="off"
                      required
                      onBlur={() => {handleMediaBox(ind, index); handleResult('consumo')}}
                      onChange={(e) => handleChangeBoxDados(e, ind, index)} />
                    <TextField fullWidth
                      label='Fornec. Conce (kWh)'
                      name="fornecido"
                      type="number"
                      sx={{
                        '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                          '& fieldset': { // Seleciona a borda do TextField
                            borderColor: 'red', // Define a cor da borda
                          },
                          '&:hover fieldset': { // Estilo da borda quando hover
                            borderColor: 'red',
                          },
                          '&.Mui-focused fieldset': { // Estilo da borda quando focado
                            borderColor: 'red',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: '#111', // Define a cor do título
                        },
                      }}
                      InputProps={{
                        sx: {
                          color: '#111', // Define a cor da fonte
                          backgroundColor: '#ffeded'
                        },
                      }}
                      value={item.fornecido || ''}
                      variant="outlined"
                      autoComplete="off"
                      required
                      onBlur={() => handleMediaBox(ind, index)}
                      onChange={(e) => handleChangeBoxDados(e, ind, index)} />
                  </div>
                  <div className={styles.report_input}>
                    {item.img?.file && 
                      <div className={styles.report_img}>
                        <img src={item.img?.file} alt="" />
                        <button onClick={() => handleDeletePhoto(ind, index, 'beneficiaria')}><CloseIcon sx={{ fontSize: '20px' }} /></button>
                      </div>
                    }
                  </div>
                  <div className={styles.report_input}>
                    <Button
                      disableElevation
                      component="label"
                      color='secondary'
                      variant="contained"
                      type='file'
                      sx={{ textTransform: 'capitalize' }}
                      onChange={(e) => handleInput(e, ind, 'beneficiaria', index)}
                      startIcon={<UpgradeIcon />}>
                      <VisuallyHiddenInput type="file" accept="image/png,image/jpeg" />
                      Enviar Imagem
                    </Button>
                </div>
                  </>
                ))}
                {/* <div className={styles.report_input}>
                  <IconButton
                  color="success"
                  onClick={() => handleChangeInput('add')}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                {report.consumo_relatorio.length > 3 && 
                  <IconButton
                  color="error"
                  onClick={() => handleChangeInput('delete')}>
                    <CancelOutlinedIcon />
                  </IconButton>
                }
                </div> */}
                <div></div>
                <div className={styles.report_text_container}>
                  <div className={styles.report_text}>
                    <h4>Média Injeção de Crédito</h4>
                    <p><b>{data.media_injecao}</b> kWh</p>
                  </div>
                  <div className={styles.report_text}>
                    <h4>Média Fornecido Concessionária</h4>
                    <p><b>{data.media_consumo}</b> kWh</p>
                  </div>
                </div>
                <div className={styles.report_input}>
                  <div style={{ backgroundColor: data.resultado_cor?.background ?? '#fdf6e4',
                  padding: '1rem',
                  borderRadius: '5px',
                  border: `1px solid ${data.resultado_cor?.border ?? "#f3c700"}`
                 }} className={styles.report_text}>
                    <h4>Resultado de Consumo</h4>
                    {data.resultado_texto ?? <p>O consumo atual está <b>{boxBenefits[index].valor} kWh</b> se mantém igual ao consumo atual de .</p>}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className={styles.report_input}>
            <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => changeBoxBenefits('add')}
            >Adicionar Beneficiária</Button>
            {boxBenefits && boxBenefits.length > 0 &&
              <Button 
              disableElevation
              sx={{ textTransform: 'capitalize' }}
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => changeBoxBenefits()}
              >Remover Beneficiária</Button>
            }
          </div>
          {box && box.map((data, index) => (
            <div className={styles.report_container_box}> 
              <div className={styles.report_header}>
                <input 
                  type="text"
                  name="titulo"
                  style={{ textAlign: 'center' }}
                  value={data.titulo ?? ''}
                  placeholder="Digite um titulo"
                  autoComplete="off"
                  required
                  onChange={(e) => handleChangeBox(e, index)} />
              </div>
              <div className={styles.report_form}>
                <div className={styles.report_input}>
                  {data.arquivo.length > 0 ? data.arquivo.map((ref, ind) => (
                    <>
                    <div className={styles.report_img}>
                    {ref.complete.type.includes("image") ? 
                      <img style={{ left: '1.7rem' }} src={ref.file} alt="" /> :
                      <video style={{ left: '1.7rem' }} src={ref.file} width='100%' controls muted playsInline /> 
                    }
                      <button onClick={() => handleDeletePhoto(index, ind, 'box')}><CloseIcon sx={{ fontSize: '20px' }} /></button>
                    </div>
                    </>
                  )) : 
                  <h3 className={styles.text_file}>Nenhum Arquivo enviado</h3>
                }
                </div>
                  {/* {data.arquivo?.file && 
                    <img className={styles.report_img} src={data.arquivo?.file} alt="" />
                  } */}
                <div className={styles.report_input}>
                    <Button
                      disableElevation
                      component="label"
                      color='secondary'
                      variant="contained"
                      type='file'
                      sx={{ textTransform: 'capitalize' }}
                      onChange={(e) => handleInput(e, index, 'box')}
                      startIcon={<UpgradeIcon />}>
                      <VisuallyHiddenInput multiple type="file" accept="image/png,image/jpeg, video/*" />
                      Enviar Imagem / Vídeo
                    </Button>
                </div>
                <div className={styles.report_input}>
                  <TextField fullWidth
                      label='Observação'
                      multiline
                      rows={5}
                      name="observacao"
                      type="text"
                      placeholder="Deixe uma observação a respeito"
                      value={box[index].observacao || ''}
                      variant="outlined"
                      autoComplete="off"
                      required
                      onChange={(e) => handleChangeBox(e, index)} />
                </div>
              </div>
            </div>
          ))}
          <div className={styles.report_input}>
            <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => changeBox('add')}>
              Adicionar Campo
            </Button>
            {box && box.length > 0 && 
              <Button 
              disableElevation
              sx={{ textTransform: 'capitalize' }}
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => changeBox()}
              color="error">
                Remover Campo
              </Button>
            }
          </div>
          <div className={styles.report_container_box} style={{ marginBottom: '5rem' }}> 
            <div className={styles.report_header}>
              <h3>Observação Final</h3>
            </div>
            <div className={styles.report_form}>
              <div className={styles.report_input}>
                <TextField fullWidth
                    label='Observação'
                    multiline
                    rows={5}
                    name="kwh"
                    type="text"
                    placeholder="Deixe uma observação a respeito do relatório"
                    value={report.observacao || ''}
                    variant="outlined"
                    autoComplete="off"
                    required
                    onChange={(e) => setReport({...report, observacao: e.target.value})} />
              </div>
            </div>
          </div>
          <div className={styles.footer_button}>
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            startIcon={<CheckIcon />}
            type="submit"
            color="success">Salvar Relátorio</Button>
            <Link to='/relatorio'>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant="contained"
                color="error">Cancelar</Button>
            </Link>
          </div>
        </form>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default memo(CreateReport);

import { useState, useRef } from 'react'
import Swal from "sweetalert2";
// import style from "./style.module.scss";
import style from '../../../../styles/Box/style.module.scss';
import SlickSlider from "react-slick";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import TelhadoMedida from '../../../../images/visit/telhado_projeto_medida.jpeg';
import TelhadoPlaca from '../../../../images/visit/telhado_projeto_placa.jpeg';
import TelhadoIrradiacao from '../../../../images/visit/telhado_projeto_irradiacao.jpeg';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import moment from 'moment';

const RegisterVisitStep4 = ({visit,  open, close, userRef, idStep4 }) => {
  const theme = useTheme();
  const { uploadImages, changeProgress } = useUpload(visit.id);
  const { CircularProgressWithLabel  } = useCircular();
  const slider = useRef(null);
  const slider2 = useRef(null);
  const slider3 = useRef(null);
  const [step, setStep] = useState(0);
  const [checkData, setCheckData] = useState([false], [false],[false], [false], [false], [false], [false], [false]);
  const [telhadoMedida, setTelhadoMedida] = useState([]);
  const [telhadoPlaca, setTelhadoPlaca] = useState([]);
  const [telhadoIrradiacao, setTelhadoIrradiacao] = useState([]);
  const [kit, setKit] = useState([]);
  const [inversor, setInversor] = useState({ dataSheet: [], manual: [] });
  const [modulo, setModulo] = useState({ dataSheet: [], manual: [] });
  const [observacaoTelhado, setObservacaoTelhado] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const checkPhotos = ['Fotos do Telhado c/ Medidas', 'Fotos do Telhado com as Placas'];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  console.log(idStep4);

  const closeBox = () => {
    close();
    setTimeout(() => { // Parei aqui
      setStep(0);
      setCheckData([false], [false],[false], [false]);
      setTelhadoMedida([]);
      setTelhadoPlaca([]);
      setTelhadoIrradiacao([]);
      setKit([]);
      setObservacaoTelhado('');
    }, 500);
  }

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  const sendPhoto = async (e, type) => { // Envia as fotos de acordo com o tipo
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'telhadoMedida') {
      if(telhadoMedida) {
        setTelhadoMedida([...telhadoMedida, ...files])
      } else {
        setTelhadoMedida(files);
      }
    } else if(type === 'telhadoPlaca') {
      if(telhadoPlaca) {
        setTelhadoPlaca([...telhadoPlaca, ...files])
      } else {
        setTelhadoPlaca(files);
      }
    } else if(type === 'telhadoIrradiacao') {
      if(telhadoIrradiacao) {
        setTelhadoIrradiacao([...telhadoIrradiacao, ...files])
      } else {
        setTelhadoIrradiacao(files);
      }
    } else if(type === 'dataSheetInversor') {
      if(inversor.dataSheet) {
        setInversor({...inversor, dataSheet: [...inversor.dataSheet, ...files] })
      } else {
        setInversor({...inversor, dataSheet: files});
      }
    } else if(type === 'manualInversor') {
      if(inversor.manual) {
        setInversor({...inversor, manual: [...inversor.manual, ...files] })
      } else {
        setInversor({...inversor, manual: files});
      }
    } else if(type === 'dataSheetModulo') {
      if(modulo.dataSheet) {
        setModulo({...modulo, dataSheet: [...modulo.dataSheet, ...files] })
      } else {
        setModulo({...modulo, dataSheet: files});
      }
    } else if(type === 'manualModulo') {
      if(modulo.manual) {
        setModulo({...modulo, manual: [...modulo.manual, ...files] })
      } else {
        setModulo({...modulo, manual: files});
      }
    } else if(type === 'kit') {
      if(kit) {
        setKit([...kit, ...files])
      } else {
        setKit(files);
      }
    }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(telhadoMedida);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);

     switch (type) {
      case 'telhadoMedida':
        setTelhadoMedida(newPhotos);
        break;
      case 'telhadoPlaca':
        setTelhadoPlaca(newPhotos);
        break;
      case 'telhadoIrradiacao':
        setTelhadoIrradiacao(newPhotos);
        break;
      case 'dataSheetInversor':
        setInversor({...inversor, dataSheet: newPhotos});
        break;
      case 'manualInversor':
        setInversor({...inversor, manual: newPhotos});
        break;
      case 'dataSheetModulo':
        setModulo({...modulo, dataSheet: newPhotos});
        break;
      case 'manualModulo':
        setModulo({...modulo, manual: newPhotos});
        break;
      case 'kit':
        setKit(newPhotos);
        break;
        default:
     }
    }
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }
  
  const sendVisit = () => {
    if (telhadoMedida.length === 0 || telhadoPlaca.length === 0 || telhadoIrradiacao.length === 0) {
      let validationPhoto;
      if(telhadoMedida.length === 0) {
        validationPhoto = 'a foto do <b>Telhado com todas as medidas'
      } else if(telhadoPlaca.length === 0) {
        validationPhoto = 'a foto do <b>Telhado com as placas'
      } else if(telhadoIrradiacao.length === 0) {
        validationPhoto = 'a foto do <b>Telhado com irradiação'
      }
      return Swal.fire({
        title: 'Fotos',
        html: `Envie a foto ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Projeto',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          setStep(1)
          let visita = {};
          let ref = {};
          // const collectionAnot = visit.anotacao ? visit.anotacao : [];
          // collectionAnot.push({
          //   data: `[${moment(new Date()).format('DD/MM/YYYY')} às ${moment(new Date()).format('HH:mm')}] ${user.name}:`,
          //   dataCreat: new Date(),
          //   text: anotacao ? anotacao : '',
          //   uid: user.id
          // })
          // console.log(collectionAnot)
          await updateDoc(doc(dataBase, 'Visitas', visit.id), {
            projeto_user: {
             data: moment().format('yyyy-MM-DD'),
             nome: userRef && userRef.nome_ref,
             id: userRef && userRef.id
            },
            jornada: {
              etapa: 'Projeto',
              index: 2,
              sigla: 'P',
              cor: '#5facff',
              data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
            }
          })

          try {
            const resultTelhadoMedida = await uploadImages(telhadoMedida, 'telhadoMedida', 0);
              visita.telhadoMedida = resultTelhadoMedida.map(object => object.downloadURL);
              ref.telhadoMedida = resultTelhadoMedida.map(object => object.fullPath);
              handleChangeCheck(0);

            const resultTelhadoPlaca = await uploadImages(telhadoPlaca, 'telhadoPlaca', 1);
              visita.telhadoPlaca = resultTelhadoPlaca.map(object => object.downloadURL);
              ref.telhadoPlaca = resultTelhadoPlaca.map(object => object.fullPath);
              handleChangeCheck(1);

            const resultTelhadoIrradiacao = await uploadImages(telhadoIrradiacao, 'telhadoIrradiacao', 2);
              visita.telhadoIrradiacao = resultTelhadoIrradiacao.map(object => object.downloadURL);
              ref.telhadoIrradiacao = resultTelhadoIrradiacao.map(object => object.fullPath);
              handleChangeCheck(2);

              const stepRef = idStep4 ?
                doc(dataBase, 'Visitas', visit.id, 'Tecnica', idStep4) :
                collection(dataBase, 'Visitas', visit.id, 'Tecnica');

              const docData = {
                ...visita,
                ref: ref,
                etapa: 4,
                observacao_telhado: observacaoTelhado
              };

              const docRef = idStep4 ? updateDoc : addDoc;
              
              await docRef(stepRef, docData);
              Swal.fire({
                title: 'Projeto',
                html: `A etapa de <b>Projeto</b> foi concluída com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#111",
                confirmButtonText: "Ok",
            }).then(() => {
                closeBox();
            });
              // if(idStep4) {
              //   await updateDoc(collection(dataBase, 'Visitas', visit.id, 'Tecnica', idStep4), {
              //     ...visita,
              //     ref: ref,
              //     etapa: 4,
              //     observacao_telhado: observacaoTelhado
              //    }).then(() => {
              //        Swal.fire({
              //          title: 'Projeto',
              //          html: `A etapa de <b>Projeto</b> foi concluida com sucesso.`,
              //          icon: "success",
              //          showCloseButton: true,
              //          confirmButtonColor: "#111",
              //          confirmButtonText: "Ok",
              //          }).then(() => {
              //            closeBox();
              //          })
              //   })   
              // } else {
              //   await addDoc(collection(dataBase, 'Visitas', visit.id, 'Tecnica'), {
              //    ...visita,
              //    ref: ref,
              //    etapa: 4,
              //    observacao_telhado: observacaoTelhado
              //   }).then(() => {
              //       Swal.fire({
              //         title: 'Projeto',
              //         html: `A etapa de <b>Projeto</b> foi concluida com sucesso.`,
              //         icon: "success",
              //         showCloseButton: true,
              //         confirmButtonColor: "#111",
              //         confirmButtonText: "Ok",
              //         }).then(() => {
              //           closeBox();
              //         })
              //        })   
              // }
           
          } catch(error) {
            console.error('Erro ao concluir etapa do projeto:', error);
            Swal.fire({
              title: 'Erro',
              text: 'Ocorreu um erro ao concluir a etapa do projeto.',
              icon: "error",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#111",
          });
          }
          }
        })
      }
  }

  return (
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={() => closeBox()}
    >
      <IconButton
        aria-label="close"
        onClick={() => closeBox()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Projeto</DialogTitle>
      <DialogContent className={style.dialog_content}>
      {step < 1 &&
        <div className={style.register_container}>
          <div className={style.photo_item}>
            {telhadoMedida && telhadoMedida.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1: </b>Envie 1 foto <b>do telhado</b> com todas as medidas. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={TelhadoMedida} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1: </b>Envie 1 foto <b>do telhado</b> com todas as medidas. <br />
                </DialogContentText><div className={style.slider_container}>
                  {telhadoMedida && telhadoMedida.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  }
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {telhadoMedida && telhadoMedida.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, telhadoMedida, 'telhadoMedida')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                    {telhadoMedida && telhadoMedida.length > 1 &&
                      <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                    }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'telhadoMedida')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {telhadoPlaca && telhadoPlaca.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2: </b>Envie 1 foto <b>do telhado</b> com as placas. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={TelhadoPlaca} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2: </b>Envie 1 foto <b>do telhado</b> com as placas. <br />
                </DialogContentText><div className={style.slider_container}>
                  {telhadoPlaca && telhadoPlaca.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider2?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  }
                    <SlickSlider ref={slider2} {...settingsVisit} className={style.slider}>
                      {telhadoPlaca && telhadoPlaca.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, telhadoPlaca, 'telhadoPlaca')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                    {telhadoPlaca && telhadoPlaca.length > 1 &&
                      <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider2?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                    }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'telhadoPlaca')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.photo_item}>
            {telhadoIrradiacao && telhadoIrradiacao.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3: </b>Envie foto ou vídeo do <b>telhado com irradiação</b>. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={TelhadoIrradiacao} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3: </b>Envie foto ou vídeo do <b>telhado com irradiação</b>. <br />
                </DialogContentText><div className={style.slider_container}>
                    {telhadoIrradiacao && telhadoIrradiacao.length > 1 &&
                      <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider3?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                    }
                    <SlickSlider ref={slider3} {...settingsVisit} className={style.slider}>
                      {telhadoIrradiacao && telhadoIrradiacao.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type !== 'image/jpeg' && img.complete.type !== 'image/png' && img.complete.type !== 'image/jpg' ? 
                            <video src={img.file} width='100%' controls muted playsInline>
                            </video>
                            :
                            <img src={img.file} alt='' />
                          }
                          <p>{img.complete.name}</p>
                          <Button 
                            disableElevation 
                            startIcon={<DeleteForeverIcon />}
                            variant='contained' 
                            color='error' 
                            sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                            onClick={() => deletePhoto(index, telhadoIrradiacao, 'telhadoIrradiacao')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  {telhadoIrradiacao && telhadoIrradiacao.length > 1 &&
                    <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider3?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton>
                  }
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
                disableElevation
                component="label" 
                variant="contained" 
                sx={{ textTransform: 'capitalize' }}
                onChange={(e) => sendPhoto(e, 'telhadoIrradiacao')} startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="video/*, image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          <div className={style.register_container}>
        <DialogContentText sx={{ textAlign: "center" }}>
        Caso preferir, deixe uma <b>observação</b> a respeito do <b>projeto</b>.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            fullWidth
            margin="dense"
            label="Observação"
            type="text"
            rows={3}
            multiline
            value={observacaoTelhado}
            onChange={(e) => setObservacaoTelhado(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        </div>
        </div>}
        {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li>
                {checkData[index] ?
                  <CheckIcon color='success' /> :
                  <CircularProgressWithLabel value={changeProgress[index]} />
                }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <Button 
            disableElevation
            component="label" 
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 0 ? true: false} 
            onClick={() => close()}>Fechar</Button>
          <Button 
            disableElevation
            component="label" 
            variant="contained" 
            sx={{ textTransform: 'capitalize' }}
            disabled={step > 0 ? true: false} 
            color='success' startIcon={<SendIcon />} 
            onClick={() => sendVisit()}>Enviar</Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
      
  )
}

export default RegisterVisitStep4;
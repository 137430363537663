import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // cria alertas personalizado
import { dataBase } from "../../../../firebase/database";
import { Company } from "../../../../data/Data";
import Autocomplete from '@mui/material/Autocomplete';
import CurrencyInput from "react-currency-input-field";

// import "../../_modal.scss";

import { PatternFormat } from "react-number-format";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { theme } from '../../../../data/theme';
import { ThemeProvider, DialogContentText, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from "./styles.module.scss";
import moment from "moment";

const Edit = ({ members, work, open, close }) => {
  // const { register, handleSubmit } = useForm();
  const [info, setInfo] = useState({
    nome: "",
    razao_social: "",
    cnpj: "",
    valor_fechado: "",
    data: moment().format("YYYY-MM-DD"),
    horario: '00:00',
    id: '' 
  });

  useEffect(() => {
   if(open) {
    setInfo({
      nome: work.terceirizado?.nome ?? '',
      razao_social: work.terceirizado?.razao_social ?? '',
      cnpj: work.terceirizado?.cnpj ?? '',
      valor_fechado: work.terceirizado?.valor_fechado ?? '',
      data: work.terceirizado?.data ?? moment().format("YYYY-MM-DD"),
      horario: work.terceirizado?.horario ?? '00:00',
      id: work.terceirizado?.id ?? ''
    })
    console.log(info)
   } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, work])

  const closeBox = () => {
    close();
    setInfo({
      nome: "",
      razao_social: "",
      cnpj: "",
      valor_fechado: "",
      data: moment().format("YYYY-MM-DD"),
      horario: '00:00',
      id: '' 
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault();
      try {
        console.log(work);
        Swal.fire({
          title: Company,
          text: `Você deseja confirmar o Terceirizado?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await updateDoc(doc(dataBase, 'Limpezas', work.id), {
              terceirizado: {
                ...info
              },
              jornada: {
                etapa: 'Agendado',
                index: 1,
                sigla: 'A',
                cor: '#5facff',
                data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
              } 
            }).then(async () => {
                console.log(work)
                if(work.terceirizado) {
                  const docCleaner = await getDoc(doc(dataBase, 'Membros', work.terceirizado.id));
                  if(docCleaner.exists()) {
                    const filterObras = docCleaner.data().obras.filter(data => data.id !== work.id);
                    console.log(filterObras)
                    await updateDoc(doc(dataBase, 'Membros', work.terceirizado.id), {
                      obras: filterObras
                    }) 
                    console.log('aaaa')
                  }
                }
                const refCleaner = members.find(member => member.id === info.id);
                let obras = refCleaner.obras || []
                await updateDoc(doc(dataBase, 'Membros', info.id), {
                  obras: adicionarObraUsuario(work.id, work.nome, false, obras)
                })
              })
              Swal.fire({
                title: Company,
                html: `O Terceirizado <b> ${info.nome}</b> foi definido com sucesso.`,
                icon: "success",
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonColor: "#111",
              }).then((result) => {
                if (result.isConfirmed) {
                  closeBox();
                }
              });
            }    
        });
      } catch (error) {
        // console.log(error);
      }
  };

  function adicionarObraUsuario(id, nome, finalizado, obras) {
    // Verifica se há algum objeto de obra com o mesmo ID
    const obraExistente = obras.find(obra => obra.id === id);
  
    if (!obraExistente) {
      // Se a obra não existir, adiciona ao array de obras do usuário
      obras.push({
        id: id,
        nome: nome,
        finalizado: finalizado,
        data: info.data,
        horario: info.horario
      });
    } else {
      const obrasFormated = obras.filter(data => data.id !== id);
      obrasFormated.push({
        id: id,
        nome: nome,
        finalizado: finalizado,
        data: info.data,
        horario: info.horario
      });
      obras = obrasFormated;
    }

    return obras
  }

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <IconButton
          aria-label="close"
          onClick={() => closeBox()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Definir Terceirizado</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
          Preencha os campos abaixo para definir o <b>Terceirizado</b> da limpeza.
        </DialogContentText>
        <form onSubmit={onSubmit}>
        <ThemeProvider theme={theme}>
        <div className={styles.label_content}>
          <Autocomplete
                value={info.nome !== null ? info.nome : members.find(data => data.id === work.id)}
                fullWidth
                sx={{ alignItems: 'center', marginTop: '8px' }}
                onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setInfo({...info, nome: newValue});
                  console.log('a')
                } else if (newValue && newValue.inputValue) {
                  setInfo({...info, nome: newValue.inputValue});
              } else {
                  setInfo({
                    ...info,
                    nome: newValue.nome,
                    razao_social: newValue.razao_social,
                    cnpj: newValue.cnpj,
                    id: newValue.id
                  });
              }
            }}
            // filterOptions={(options, params) => {
            //   const filtered = filter(options, params);

            //   const { inputValue } = params;
            //   // Suggest the creation of a new value
            //   const isExisting = options.some((option) => inputValue === option.nome);
            //   if (inputValue !== '' && !isExisting) {
            //     filtered.push({
            //       inputValue,
            //       nome: `Adicionar "${inputValue}"`,
            //     });
            //   }

            //   return filtered;
            // }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disableClearable={true}
            id="free-solo-with-text-demo"
            options={members}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Opção Regular
              return option.nome;
            }}
            renderOption={(props, option) => <li {...props}>{option.nome}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params}
              label="Nome"
              type="search"
              />
            )}
          />
          <CurrencyInput
            customInput={TextField}
            style={{ margin: '1rem 0rem 0.5rem 0' }}
            label="Valor Combinado pelo Serviço"
            placeholder="R$ 00"
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            onValueChange={(value) => setInfo({...info, valor_fechado: value})}
            decimalsLimit={2}
            value={info.valor_fechado || ''}
            required
            fullWidth
          />
        </div>
        <div className={styles.label_content}>
          <TextField
            margin="dense"
            id="name"
            label="Razão Social"
            type="text"
            value={info.razao_social ?? ''}
            onChange={(e) => setInfo({ ...info, razao_social: e.target.value })}
            fullWidth
            required
            disabled
            variant="outlined"
          />
          <PatternFormat
            value={info.cnpj ?? ''}
            onChange={(e) => setInfo({ ...info, cnpj: e.target.value })}
            format="##.###.###/####-##"
            mask="_"
            fullWidth
            sx={{ marginTop: '5px' }}
            placeholder="00.000.000/0000-00"
            label="CNPJ"
            disabled
            customInput={TextField}
            minLength={9}
            variant="outlined"
            color="primary"
            required
          /> 
        </div>
        <div className={styles.label_content}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', margin: '0.5rem 0' }}>Data da Limpeza</Typography>
        </div>
        <div className={styles.label_content}>
          <TextField
            margin="dense"
            id="name"
            label="Dia"
            type="date"
            value={info.data ?? ''}
            onChange={(e) => setInfo({ ...info, data: e.target.value })}
            fullWidth
            inputProps={{ minLength: 6 }}
            required
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="name"
            label="Horário"
            type="time"
            value={info.horario ?? '00:00'}
            onChange={(e) => setInfo({ ...info, horario: e.target.value })}
            fullWidth
            inputProps={{ minLength: 6 }}
            required
            variant="outlined"
          />
        </div>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
          <DialogActions sx={{ justifyContent: 'center', marginTop: '1rem' }}>
            <Button disableElevation sx={{ textTransform: 'capitalize' }} color="success" variant="contained" type="submit">Confirmar</Button>
            <Button disableElevation sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => closeBox()}>Cancelar</Button>
          </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Edit;

import { useState, forwardRef, useEffect } from "react";
import { onSnapshot, query, collection, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";

// import Header from "../../components/Header/Index";
import Create from "./Components/Create/Index";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { dataBase } from "../../firebase/database";
import { useMember } from "../../hooks/useMember";

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Css
import "cooltipz-css";
import style from "./style.module.scss";
import "../../styles/_buttons.scss";
// import AddLead from "./Components/Leads/Add";
import { theme } from "../../data/theme";
import { ThemeProvider } from "@emotion/react";
// import EditLead from "./Components/Leads/Edit";
import NewTooltip from "../../components/Tooltip";
import { Box } from "@mui/material";

const Credit = ({user, userRef, check}) => {
  const { getMemberColor } = useMember();
  const [search, setSearch] = useState('');
  const [option, setOption] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [leadsRef, setLeadsRef] = useState([]);
  const [leadsRefAll, setLeadsRefAll] = useState([]);
  const [leads, setLeads] = useState([]);
  const [data, setData] = useState([]);
  const [view, setView] = useState({ create: false, add: false, edit: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(query(collection(dataBase, "Planilha"), orderBy("createAt", 'desc')), (data) => {
          // Atualiza os dados em tempo real
          setSheets(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
        onSnapshot(query(collection(dataBase, "Clientes"), orderBy("createAt", 'desc')), (data) => {
          // Atualiza os dados em tempo real
          setLeadsRefAll(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
        // onSnapshot(collection(dataBase, "Membros"), (data) => {
        //   // Atualiza os dados em tempo real
        //   setMembers(
        //     data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //   );
        // });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
      if(leadsRefAll) {
        if(userRef && userRef.cargo !== "Administrador") {
          setLeads(leadsRefAll.filter((data) => data.consultora_uid === userRef.uid));
          setLeadsRef(leadsRefAll.filter((data) => data.consultora_uid === userRef.uid))
        } else {
          setLeads(leadsRefAll);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[leadsRefAll])

    useEffect(() => {
      if(leads) {
        const uniqueOptions = leads.reduce((uniqueList, currentData) => {
          const isExisting = uniqueList.some(item => item.nome === currentData.nome);
    
          if (!isExisting && currentData.nome.trim() !== '') {
            uniqueList.push({
              id: uniqueList.length,
              nome: currentData.nome
            });
          }
    
          return uniqueList;
        }, []);
        console.log(uniqueOptions)
        setOption(uniqueOptions.sort((a, b) => a.nome.localeCompare(b.nome)))
      }
    },[leads])


  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };

  // const openBox = (type, data) => {
  //   if(type === 'create') {
  //     setView({ ...view, create: true});
  //   } else if(type === 'add') {
  //     setView({ ...view, add: true});
  //   } else {
  //     leadRef.current = data;
  //     setView({ ...view, edit: true});
  //   }
  // }

  const closeBox = (type) => {
    if(type === 'all') {
      setView({ create: false, add: false, edit: false});
      setData([]);
    } else if(type === 'create') {
      setView({ ...view, create: false});
    } else if(type === 'add') {
      setView({ ...view, add: false});
    } else {
      setView({ ...view, edit: false});
    }
  } 

  console.log(data)

  // const sendData = (props) => {
  //   setData(props);
  // }

  const handleChange = (event, newValue) => {
    if (newValue === "") {
      setSearch(newValue); // Se o novo valor for vazio, mantenha como vazio
    } else {
      // Verifica se o novo valor existe nas opções
      const optionExists = option.some(option => option.nome === newValue);
  
      if (optionExists) {
        setSearch(newValue);
        setLeads(leadsRefAll.filter(data => data.nome === newValue));
      } else {
        // Trate o caso em que o valor não corresponde a nenhuma opção existente, se necessário
      }
    }
  };

  return (
    <div className={style.container_panel}>
      <div className='title-panel'>
        <InsertDriveFileOutlinedIcon sx={{ width: '42px', height: '42px', marginBottom: '0.5rem' }} />
        <h2>Planilha de Crédito</h2>
      </div>
      <div className={style.content_panel}>
        <div className={style.header_sheet}>
          {/* <div className="btn_add">
            <button
             onClick={() => openBox("add")}>
              <span className="visit-icon comercial-fill"><PostAddOutlinedIcon /></span>
              <div className="visit-text"><p>Nova Planilha</p></div>
             </button>
           </div> */}
           <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '700px',
            gap: '1rem'
           }}>
             <p><b>Atenção:</b> A partir de agora, as planilhas serão criadas pelo perfil do cliente, proporcionando maior organização e facilitando as futuras operações da empresa.</p>
              <b>Clique no botão <OpenInNewIcon sx={{ fill: "#313131" }} /> para entrar no perfil do Cliente.</b>
           </Box>
        </div>
        <h2 className={style.title_sheet}>Lista de Clientes</h2>
        <div className={style.search}>
        <ThemeProvider theme={theme}>
                    <Autocomplete
                      value={search}
                      disableClearable
                      noOptionsText={'Nenhum Cliente Cadastrado'}
                      sx={{ width: '15rem' }}
                      options={option && option.map((option) => option.nome)}
                      onInputChange={(event, newInputValue) => {
                        if (newInputValue === '') {
                          setLeads(leadsRef);
                          setSearch('');
                        }
                      }}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          key={params.id}
                          label="Pesquisar Cliente"
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    /> 
                  </ThemeProvider>
        </div>
        <Create leads={leads} view={view.create} close={closeBox} userRef={userRef} Sheets={sheets} data={data}/>
        {/* <AddLead leads={leads} view={view.add} close={closeBox} userRef={userRef} members={members} openSheet={() => openBox('create')} sendData={sendData} checkClose={checkClose} /> */}
        {/* <EditLead members={members} leads={leads} view={view.edit} close={closeBox} userRef={userRef} sendData={sendData} checkClose={checkClose} lead={leadRef.current}/> */}
        <TableContainer className={style.table_sheets} component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Planilhas</TableCell>
                    <TableCell align="center">Ultima Planilha Criada</TableCell>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center">Telefone</TableCell>
                    <TableCell align="center">Consultora</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {leads && leads.length > 0 ? leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                  <TableRow
                    key={index}
                    hover
                    className={`list-visit`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="center"><b>{data.planilha ? data.planilha.length : 0}</b></TableCell>
                    <TableCell align="center">{data.data && data.data.replace('-', 'às')}</TableCell>
                    <TableCell align="center">{data.nome}</TableCell>
                    <TableCell align="center">{data.cidade ?? 'Não Informado'}</TableCell>
                    <TableCell align="center">{data.telefone ?? 'Não Informado'}</TableCell>
                    <TableCell className={style.consultora} align="center" sx={{ backgroundColor: getMemberColor(data.consultora_uid) }}>{data.consultora}</TableCell>
                    <TableCell align="center" sx={{ width: '50px' }}>
                    <NewTooltip title={'Visualizar Perfil do Cliente'} placement={'top'}>
                      <Link to={`/clientes/${data.id}`}>
                          <IconButton
                            size="small">
                            <OpenInNewIcon />
                          </IconButton> 
                        </Link>
                    </NewTooltip> 
                    </TableCell>
                  </TableRow>
                )) : 
                <TableRow>
                  <TableCell align="center" sx={{ fontSize: '17px' }} colSpan={7}>
                    <p style={{ padding: '0.5rem 0' }}>Nenhum Cliente Cadastrado</p>
                  </TableCell>
                </TableRow>}
                </TableBody>
              </Table>
              <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              labelRowsPerPage="Lista por página"
              component="div"
              count={leads ? leads.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
      </div>
      <Snackbar open={check} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Você está sem conexão. Verifique a sua conexão com a internet.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Credit;

import { DialogActions, DialogContent, DialogContentText, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField, ThemeProvider } from '@mui/material'
import React, { memo, useState } from 'react'

import ButtonCB from '../../../components/Button';

import Bv from '../../../images/icons/Bv.png';
import Santander from '../../../images/icons/Santander.png';
import Losango from '../../../images/icons/Losango.png';
import SolAgora from '../../../images/icons/SolAgora.png';
// import SolFacil from '../../../images/icons/SolFacil.png';
import Btg from '../../../images/icons/Banco_BTG.jpg';
import { PatternFormat } from 'react-number-format';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from '../../../components/Toast';
import { doc, updateDoc } from 'firebase/firestore';

import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import { theme } from '../../../data/theme';
import CurrencyInput from 'react-currency-input-field';
const imagesList = [Bv, Santander, Losango, SolAgora, Btg];

const Simulation = ({ customer, system, assistant, setRefresh }) => {
    const Image = styled('img')(() => ({
        borderRadius: '10px',
        maxWidth: '50px'
    }))
    const [errors, setErrors] = useState({});
    const [simulation, setSimulation] = useState({
        tipo: '',
        nome: '',
        entrada: '',
        entrada_valor: '',
        doc: '',
        data: '',
        cep: '',
        email: '',
        renda: '',
        profissao: '',
        nome_mae: ''
    });
    const [loading, setLoading] = useState(false);
    const handleSimulation = (e) => {
        const { name, value } = e.target;
        setSimulation({ ...simulation, [name]: value });
    }

    const hasTrueProperty = (obj) => {
        return Object.values(obj).some(value => value === true);
    }

    const closeBox = () => {
        setTimeout(() => {
            setSimulation({
                tipo: '',
                nome: '',
                entrada: '',
                entrada_valor: '',
                doc: '',
                data: '',
                cep: '',
                email: '',
                renda: '',
                profissao: '',
                nome_mae: ''
            });
            setErrors({});
            setLoading(false);
        }, 500);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        console.log(simulation);

        if (hasTrueProperty(errors)) {
            return null;
        }
        const result = await Swal.fire({
            title: 'Atenção',
            html: `Os dados estão corretos?`,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        });

        if (result.isConfirmed) {
            try {
                setLoading(true);
                await axios.post('https://n8n.corpbrasil.cloud/webhook/d0e1b79e-4261-4c0b-b3d2-ed4f80d546ff', {
                    cliente: customer.nome,
                    id: customer.id, 
                    cidade: customer.cidade,
                    telefone: customer.telefone,
                    vendedor: customer.consultora,
                    assistente: assistant.nome,
                    telefone_assistente: assistant.telefone,
                    simulacao: simulation
                })

                const newProposal = [...customer.proposta];
                const proposalRef = newProposal.find(data => data.nome === system.orcamento.proposta);
                const proposalRefIndex = newProposal.findIndex(data => data.nome === system.orcamento.proposta);
                if (proposalRef) {
                    proposalRef.simulacao = true;
                    newProposal[proposalRefIndex] = proposalRef
                }

                await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                    simulacao: simulation,
                    proposta: newProposal,
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Simulação Solicitada',
                        text: `O cliente solicitou uma simulação de financiamento através do orçamento <b>${system.orcamento_solicitado}</b>.`,
                        usuario_id: '0',
                        usuario_nome: 'Robô'
                    }]
                });

                closeBox();
                setRefresh(true);
                setLoading(false);

                await Swal.fire({
                    title: 'Parabéns!',
                    html: `A <b>Simulação</b> foi solicitada com sucesso! <br /> Em breve entraremos em contato com você.`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                })

            } catch (error) {
                setLoading(false);
                console.log(error)
                toast({ icon: 'error', text: 'Ocorreu um erro ao enviar os dados. Tente novamente mais tarde.' })
            }
        }
    }

    const checkInput = (type) => {
        const { nome, doc, data, cep } = simulation;
        switch (type) {
            case 'nome':
                return !isFullName(nome);
            case 'doc':
                return doc.includes("_") || simulation?.tipo === 'Física' ? !validaCPF(doc) : !validaCNPJ(doc);
            case 'data':
                return data.includes("_") && data.length > 2;
            case 'cep':
                return cep.includes("_") && cep.length > 2;
            default:
                return false;
        }
    }

    const isFullName = (name) => {
        return (
            typeof name === 'string' &&
            name.trim().split(' ').length >= 2 &&
            name.trim().split(' ').every(part => part.length >= 2)
        );
    }

    const onBlurCheck = (e) => {
        const { name, value } = e.target;
        if (value) {
            setErrors({ ...errors, [name]: checkInput(name) });
        } else {
            setErrors({ ...errors, [name]: null });
        }
    }

    const validaCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        let soma = 0;
        for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
        let resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(9))) return false;
        soma = 0;
        for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        return resto === parseInt(cpf.charAt(10));
    }

    const validaCNPJ = (cnpj) => {
        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj.length !== 14) return false;
        const validacao = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        const cnpjNumeros = cnpj.substr(0, 12).split('').map(num => parseInt(num));
        const digito1 = cnpjNumeros.reduce((soma, num, index) => soma + (num * validacao[1 + index]), 0) % 11;
        if (cnpj.charAt(12) !== ((digito1 < 2 ? 0 : 11 - digito1).toString())) return false;
        const cnpjNumeros2 = cnpj.substr(0, 13).split('').map(num => parseInt(num));
        const digito2 = cnpjNumeros2.reduce((soma, num, index) => soma + (num * validacao[index]), 0) % 11;
        return cnpj.charAt(13) === ((digito2 < 2 ? 0 : 11 - digito2).toString());
    }

    console.log(errors)
    console.log('teste')

    return (

            <form onSubmit={onSubmit}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '0.6rem', padding: '0 1rem 0.8rem 1rem' }}>
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', marginTop: '0rem' }}>
                        {imagesList.map((data, index) => (
                            <Grid key={index} item xs={2.4} sm={'auto'}>
                                <Image src={data} alt={data} />
                            </Grid>
                        ))}
                        <Grid item xs={12} >
                            <DialogContentText>Para começar, precisamos de alguns dados.</DialogContentText>
                        </Grid>
                        <ThemeProvider theme={theme}>
                            <Grid item xs={12} sx={{ marginTop: '0.4rem' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="entrada">A Simulação será com entrada?</InputLabel>
                                    <Select
                                        labelId='entrada'
                                        label="A Simulação será com entrada?"
                                        name='entrada'
                                        sx={{ backgroundColor: "#fff" }}
                                        value={simulation?.entrada || ''}
                                        onChange={handleSimulation}
                                        required>
                                        <MenuItem value="Sim">Sim</MenuItem>
                                        <MenuItem value="Não">Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {simulation.entrada === 'Sim' && 
                                <Grid item xs={12}>
                                    <CurrencyInput
                                                customInput={TextField}
                                                label="Valor da Entrada"
                                                color='info'
                                                InputProps={{
                                                    sx: {
                                                        '& .MuiInputBase-input': {
                                                            backgroundColor: '#fff', // Aplica o fundo branco ao input
                                                        },
                                                    },
                                                }}
                                                placeholder="R$ 00"
                                                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                                onValueChange={(formatted) => setSimulation({ ...simulation, entrada_valor: formatted })}
                                                decimalsLimit={2}
                                                onBlur={onBlurCheck}
                                                required
                                                value={simulation.entrada_valor || ''}
                                                helperText={errors.entrada_valor && 'Insira um valor'}
                                                fullWidth
                                            />
                                </Grid>
                            }
                            <Grid item xs={12} sx={{ marginTop: '0.4rem' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="tipo">A Simulação é para uma pessoa:</InputLabel>
                                    <Select
                                        labelId='tipo'
                                        label="A Simulação é para uma pessoa:"
                                        name='tipo'
                                        sx={{ backgroundColor: "#fff" }}
                                        value={simulation?.tipo || ''}
                                        onChange={handleSimulation}
                                        required>
                                        <MenuItem value="Física">Pessoa Física</MenuItem>
                                        <MenuItem value="Jurídica">Pessoa Jurídica</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="nome"
                                    fullWidth
                                    required
                                    sx={{ backgroundColor: "#fff" }}
                                    onBlur={onBlurCheck}
                                    error={errors.nome}
                                    placeholder='José Maria da Silva'
                                    label={simulation?.tipo === 'Jurídica' ? 'Razão Social' : "Nome Completo"}
                                    value={simulation.nome || ''}
                                    helperText={errors.nome && 'O nome não está de acordo'}
                                    onChange={handleSimulation}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PatternFormat
                                    value={simulation.doc || ''}
                                    onChange={handleSimulation}
                                    format={simulation?.tipo === 'Jurídica' ? '##.###.###/####-##' : "###.###.###-##"}
                                    mask="_"
                                    sx={{ backgroundColor: "#fff" }}
                                    name="doc"
                                    required
                                    fullWidth
                                    customInput={TextField}
                                    placeholder={simulation?.tipo === 'Jurídica' ?  '00.000.000/0000-00' : "000.000.000-00"}
                                    label={simulation?.tipo === 'Jurídica' ? 'CNPJ' : "CPF"}
                                    minLength={10}
                                    error={errors.doc}
                                    helperText={errors.doc && 'O documento é inválido.'}
                                    onBlur={onBlurCheck}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PatternFormat
                                    value={simulation.data || ''}
                                    onChange={handleSimulation}
                                    format="##/##/####"
                                    sx={{ backgroundColor: "#fff" }}
                                    mask="_"
                                    name="data"
                                    fullWidth
                                    required
                                    onBlur={onBlurCheck}
                                    error={errors.data}
                                    customInput={TextField}
                                    placeholder="00/00/0000"
                                    helperText={errors.data && 'A data está incompleta'}
                                    label={simulation?.tipo === 'Jurídica' ? 'Data de Fundação' : "Data de Nascimento"}
                                    minLength={10}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PatternFormat
                                    value={simulation.cep || ''}
                                    onChange={handleSimulation}
                                    format="#####-###"
                                    mask="_"
                                    name="cep"
                                    sx={{ backgroundColor: "#fff" }}
                                    fullWidth
                                    onBlur={onBlurCheck}
                                    error={errors.cep}
                                    customInput={TextField}
                                    placeholder="00000-000"
                                    label="CEP"
                                    helperText={errors.cep && 'O cep está incompleto'}
                                    required
                                    minLength={9}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    fullWidth
                                    type='email'
                                    required
                                    sx={{ backgroundColor: "#fff" }}
                                    onBlur={onBlurCheck}
                                    error={errors.email}
                                    placeholder='jose@gmail.com'
                                    label='Email'
                                    value={simulation.email || ''}
                                    helperText={errors.email && 'O email não está de acordo'}
                                    onChange={handleSimulation}
                                />
                            </Grid>
                            {simulation?.tipo !== 'Jurídica' && 
                            <><Grid item xs={12}>
                                <TextField
                                    name="nome_mae"
                                    fullWidth
                                    required
                                    sx={{ backgroundColor: "#fff" }}
                                    onBlur={onBlurCheck}
                                    error={errors.nome_mae}
                                    placeholder='Maria Lurdes dos Santos'
                                    label='Nome Completo da Mãe'
                                    value={simulation.nome_mae || ''}
                                    helperText={errors.nome_mae && 'O nome não está de acordo'}
                                    onChange={handleSimulation}
                                />
                            </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="profissao"
                                        fullWidth
                                        required
                                        sx={{ backgroundColor: "#fff" }}
                                        onBlur={onBlurCheck}
                                        error={errors.email}
                                        placeholder='Vendedor Autonomo'
                                        label='Profissão'
                                        value={simulation.profissao || ''}
                                        helperText={errors.profissao && 'A profissão não está de acordo'}
                                        onChange={handleSimulation}
                                    />
                                </Grid></>
                            }
                            <Grid item xs={12} sm={simulation?.tipo === 'Jurídica' ? 12 : 6}>
                                    <CurrencyInput
                                        customInput={TextField}
                                        label={simulation?.tipo === 'Jurídica' ? "Faturamento Anual" : "Renda Mensal"}
                                        color='info'
                                        InputProps={{
                                            sx: {
                                                '& .MuiInputBase-input': {
                                                    backgroundColor: '#fff', // Aplica o fundo branco ao input
                                                },
                                            },
                                        }}
                                        placeholder="R$ 00"
                                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                        onValueChange={(formatted) => setSimulation({ ...simulation, renda: formatted })}
                                        decimalsLimit={2}
                                        onBlur={onBlurCheck}
                                        required
                                        value={simulation.renda || ''}
                                        helperText={errors.renda && 'O valor está vazio'}
                                        fullWidth
                                    />
                                </Grid>
                        </ThemeProvider>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '0rem 0 0.3rem 0' }}>
                    <ButtonCB 
                        loading={loading}
                        fullWidth
                        sx={{ maxWidth: '350px', margin: '0 0.5rem' }} 
                        background='#209CFF'
                        type='submit'>
                            {!loading && 'Solicitar Simulação'}
                    </ButtonCB>
                </DialogActions>
            </form>
    )
}

export default memo(Simulation);
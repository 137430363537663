import React, { useState } from 'react'

import styles from '../styles.module.scss';
import { Avatar, Box, Chip, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Typography } from '@mui/material';
import NewTooltip from '../../../components/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SpeakerNotesRoundedIcon from '@mui/icons-material/SpeakerNotesRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Swal from 'sweetalert2';
import { doc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import { dataBase } from '../../../firebase/database';
import { StyledMenu } from '../../../components/StyledMenu';
import { toast } from '../../../components/Toast';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../../data/theme';

const Annotation = ({ customer, userRef, checkUser, members }) => {
    const [annotation, setAnnotation] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [checkAnnotation, setCheckAnnotation] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [annotationUser, setAnnotationUser] = useState(null);

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const confirmAnot = async () => {
        try {
            const docRef = doc(dataBase, 'Clientes', customer.id);
            const msg = annotationUser ? "<b>nova mensagem alterada?</b>" : '<b>Mensagem?</b>'
            const collectionAnot = customer.anotacao ? customer.anotacao : [];
            const find = collectionAnot.findIndex((date) => date.dataCreat === checkAnnotation)
            console.log("🚀 ~ confirmAnot ~ find:", collectionAnot[find])

            Swal.fire({
                title: 'Atenção',
                html: `Você deseja enviar a ${msg}`,
                icon: "warning",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const collectionAnot = customer.anotacao ? customer.anotacao : [];
                    const find = collectionAnot.findIndex((date) => date.dataCreat === checkAnnotation)
                    if (find === -1) {
                        collectionAnot.push({
                            data: `[${moment(new Date()).format('DD/MM/YYYY')} às ${moment(new Date()).format('HH:mm')}] ${userRef && userRef.nome}:`,
                            dataCreat: new Date(),
                            text: annotation,
                            uid: userRef?.uid
                        })
                    } else {
                        collectionAnot[find] = {
                            ...collectionAnot[find],
                            text: annotation,
                            ref: {
                                dataCreat: new Date(),
                                uid: userRef?.uid,
                                nome: userRef?.nome
                            }
                        }
                    }
                    await updateDoc(docRef, {
                        anotacao: collectionAnot,
                        updateAt: new Date()
                    }).then(() => {
                        toast({ icon: 'success', text: `Mensagem ${annotationUser ? 'alterada' : 'enviada'} com sucesso!` });
                        setAnnotation('');
                        setCheckAnnotation(null);
                        setAnnotationUser(null);
                    });
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteAnot = async (anot) => {
        try {
            const docRef = doc(dataBase, 'Clientes', customer.id);
            handleMenuClose();

            Swal.fire({
                title: 'Anotação',
                html: `Você deseja excluir a <b>Anotação?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const collectionAnot = customer.anotacao;
                    const find = collectionAnot.findIndex((date) => date.dataCreat === anot.dataCreat);
                    collectionAnot.splice(find, 1)
                    await updateDoc(docRef, {
                        anotacao: collectionAnot,
                        updateAt: new Date()
                    }).then(() => {
                        toast({ icon: 'success', text: 'Mensagem excluida com sucesso!' });
                        setAnnotation('');
                        setCheckAnnotation(null);
                        setAnnotationUser(null);
                    });
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const checkMember = (data) => {
        const member = members.find(m => m.id === data);
        return member;
    }

    const handleEdit = (anot, type) => {
        const { text, dataCreat } = anot;
        console.log(anot)

        if (type === 'cancel') {
            setAnnotation('');
            setAnnotationUser(null);
        } else {
            setAnnotation(text);
            setCheckAnnotation(dataCreat);
            setAnnotationUser({
                id: userRef.id,
                nome: userRef.nome
            });
            handleMenuClose();
        }
    }

    return (
        <div className={styles.panel_content_item}> {/* Anotação */}
            <div className={styles.annotation}>
                <div className={styles.annotation_header}>
                    <SpeakerNotesRoundedIcon sx={{ fill: "#adadad" }} />
                    <h3>Anotação</h3>
                </div>
                <List className={styles.annotation_box} >
                    {customer.anotacao ? customer?.anotacao.map((anot, index) => (
                        <ListItem sx={{ alignItems: 'flex-start', padding: '0 1rem' }} key={index}>
                            <ListItemAvatar>
                                <Avatar sizes='small' sx={{ margin: '0.4rem auto', width: 30, height: 30 }} alt={'Teste'} src={checkMember(anot.uid).photo?.url} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                                <Typography component='h4' fontWeight={500}>{checkMember(anot.uid).nome}</Typography>
                                                •
                                                <Typography variant="body2" color="#8d8d8d">
                                                    {moment(anot.dataCreat.toDate()).format("DD/MM/YYYY - HH:mm").replace("-", "às")}
                                                </Typography>
                                            </Box>
                                            {anot.ref &&
                                                <NewTooltip title={`Editada por ${anot.ref?.nome} em ${moment(anot.ref?.dataCreat.toDate()).format('DD/MM/YYYY - HH:mm').replace("-", "às")}`} placement='top'>
                                                    <Chip label='Editada' sx={{ cursor: 'pointer', position: 'relative', fontSize: '12px', height: '20px', right: '38px' }} />
                                                </NewTooltip>
                                            }
                                        </Box>
                                    </React.Fragment>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
                                            <Box className={styles.annotation_box_item}>
                                                {anot.text}
                                            </Box>
                                            <NewTooltip title='Opções' placement='top'>
                                                <IconButton
                                                    size='small'
                                                    id="menu"
                                                    onClick={(event) => handleClick(event, index)}
                                                    aria-controls={open ? 'menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                >
                                                    <MoreVertRoundedIcon />
                                                </IconButton>
                                            </NewTooltip>
                                            <StyledMenu
                                                MenuListProps={{ 'aria-labelledby': 'menu' }}
                                                id="menu"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'right',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open && selectedIndex === index}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        handleEdit(anot, '');
                                                        handleMenuClose();
                                                    }}
                                                    disableRipple
                                                >
                                                    <EditIcon /> Editar
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        deleteAnot(anot);
                                                        handleMenuClose();
                                                    }}
                                                    disableRipple
                                                >
                                                    <DeleteIcon sx={{ fill: "#d32f2f" }} />Excluir
                                                </MenuItem>
                                            </StyledMenu>
                                        </Box>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    )) : <Box sx={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant='h5' color="#ccc">Nenhuma Anotação Encontrada</Typography>
                    </Box>
                    }
                </List>
            </div>
            <div className={styles.annotation_box_msg}>
                <input
                    placeholder='Mensagem'
                    value={annotation}
                    onChange={(e) => setAnnotation(e.target.value)} />
                {annotationUser ?
                    <NewTooltip title='Cancelar Edição' placement='top'>
                        <IconButton
                            color='error'
                            onClick={() => handleEdit({}, 'cancel')}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </NewTooltip> : null
                }
                <ThemeProvider theme={theme}>
                    <NewTooltip title={annotationUser ? 'Enviar Edição' : 'Enviar'} placement='top'>
                        <IconButton
                            disabled={!annotation}
                            color={annotationUser ? 'success' : 'inherit'}
                            onClick={confirmAnot}>
                            {annotationUser ? <CheckRoundedIcon /> : <SendRoundedIcon />}
                        </IconButton>
                    </NewTooltip>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default Annotation
import { useState } from 'react';
import { getDownloadURL, ref, uploadBytesResumable, getStorage } from "firebase/storage";

const useUpload = (refItem) => {
  const [changeProgress, setChangeProgress] = useState([]);
  const storage = getStorage();

  const uploadImage = async (image, type, index, visit) => {
    return new Promise((resolve, reject) => {
      const storageReff = ref(storage, `Limpeza/${visit}/${type}_${image.complete.name}`);
      const uploadTask = uploadBytesResumable(storageReff, image.complete, { contentType: image.complete.type });

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        handleProgress(progress, index);
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error(error);
        reject(error);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // handleRef(storageReff.fullPath, index); 
          resolve({ downloadURL, fullPath: storageReff.fullPath });
        });
      });
    });
  };


  const handleProgress = (progress, index) => {
    setChangeProgress((prevChangeProgress) => {
      const newChangeProgress = [...prevChangeProgress];
      newChangeProgress[index] = progress;
      return newChangeProgress;
    });
  };

  const uploadImages = async (images, type, index, visit = refItem) => {
    const imagePromises = Array.from(images, (image) => uploadImage(image, type, index, visit));
    const imageRes = await Promise.all(imagePromises);
    return imageRes;
  };

  return { uploadImages, changeProgress };
};

export default useUpload;

import { collection, onSnapshot} from 'firebase/firestore';
import { useEffect, useState, memo } from 'react';
// import SlickSlider from "react-slick";
import { dataBase } from '../../../../firebase/database';
// import moment from 'moment';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "cooltipz-css";

// import Alert from '@mui/material/Alert';
// import AlertTitle from '@mui/material/AlertTitle';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import PropTypes from 'prop-types';
// import style from "./style.module.scss";
// import {Box } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import EditIcon from '@mui/icons-material/Edit';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import InfoIcon from '@mui/icons-material/Info';

// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import CloseIcon from '@mui/icons-material/Close';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import BlockIcon from '@mui/icons-material/Block';
// import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
// import ArticleIcon from '@mui/icons-material/Article';

// import { theme } from '../../../../data/theme';
// import { ThemeProvider } from '@emotion/react';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
// import AddCircle from '@mui/icons-material/AddCircle';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { Link } from 'react-router-dom';

const VisitTec = ({ data, handleCheckStep, userRef, handleStepRef, setIdStep1, setIdStep4, setStep2}) => {
  const [dataVisit, setDataVisit] = useState();
  // const [info, setInfo] = useState();
  // const [open, setOpen] = useState(false);
  // const [anotacao, setAnotacao] = useState('');
  // const [checkAnotacao, setCheckAnotacao] = useState('');
  // const [viewEdit, setViewEdit] = useState(false);
  // const [photosStep1, setPhotosStep1] = useState();
  // const [photosStep2, setPhotosStep2] = useState();
  // const [photosStep3, setPhotosStep3] = useState();
  // const [photosStep4, setPhotosStep4] = useState();
  // const [viewPhoto, setViewPhoto] = useState('');
  // const [TabsValue, setTabsValue] = useState(0);
  const [checkStep, setCheckStep] = useState({step0: false, step1: false, step1_5: false, step2: false, step3: false, step4: false})
  // const slider = useRef(null);
  const visitCollectionRef = collection(dataBase, 'Visitas', data.id, 'Tecnica');
  // const [addInstall, setAddInstall] = useState(false);
  // const [instaladores, setInstaladores] = useState([{nome: '', documento: ''}]);
  // const a11yProps = (index) => {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  // const journey = ['Proposta Comercial', 'Visita Técnica', 'Projeto', 'Faturamento', 'Venda Concluida', 'Comissão'];
  // const journeySG = ['PC', 'VT', 'P', 'F', 'VC', 'C'];
  // const journeyColor = ['#dcdbad', '#addcc5', '#5facff', '#adc0dc', '#bedcad', '#6ece99'];

   useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(await visitCollectionRef, (schedule) => {
          // Atualiza os dados em tempo real
          setDataVisit(
            schedule.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
          handleStepRef(
            schedule.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          ); 
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // const CustomTabPanel = (props) => {
  //   const { children, value, index, ...other } = props;
  
  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box display={'flex'} alignItems={'center'}>
  //           {children}
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // CustomTabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired,
  // };

  useEffect(() => {
    if (dataVisit) {
      const step0 = dataVisit.find((etapa) => etapa.etapa === 1);
      if(step0) {
        setIdStep1(step0.id);
      }
      const step1 = dataVisit.find((etapa) => etapa.etapa === 1 && etapa.injecao_energia_ca);
      const step15 = dataVisit.find((etapa) => etapa.etapa === 1 && etapa.fachada);
      const step2Ref = dataVisit.find((etapa) => etapa.etapa === 2);
      if(step2Ref) {
        setStep2(step2Ref);
      }
      const step25 = dataVisit.find((etapa) => etapa.etapa === 2 && etapa.telhadoVideo);      const step3 = dataVisit.find((etapa) => etapa.etapa === 3);
      const step4 = dataVisit.find((etapa) => etapa.etapa === 4 && etapa.telhadoPlaca);
      const step4Ref = dataVisit.find((etapa) => etapa.etapa === 4); // Utilizado para caso já exista a etapa 4
      const step45 = dataVisit.find((etapa) => etapa.etapa === 4 && etapa.inversorDataSheet);
      if(step4Ref) {
        setIdStep4(step4Ref.id);
      }
      let photos = [];
      // const photosFull1 = step15 && photos.concat(step15.amperimetro, step15.bengala, step15.disjuntor, step15.fachada, step15.padrao, step15.poste, step15.relogio)
      const photosFull2 = step2Ref && photos.concat(step2Ref.telhadoPerto, step2Ref.telhadoLonge);
      const videosFull2 = step2Ref && step2Ref.telhadoVideo;
      let FilesStep2 = [];
      if(step2Ref) {
        for (let i = 0; i < photosFull2.length; i++) {
          FilesStep2.push({ type: 'photo', url: photosFull2[i]});
        }
      }
      if(step25) {
        for (let i = 0; i < videosFull2.length; i++) {
          FilesStep2.push({ type: 'video', url: videosFull2[i]});
        }
      }
      // const photosFull4 = step4 && photos.concat(step4.telhadoMedida, step4.telhadoPlaca, step4.telhadoIrradiacao, step4.kit)
      // const photosFull3 = step3 && step3.telhadoMedida;
      // setPhotosStep1(photosFull1);
      // setPhotosStep2(FilesStep2);
      // setPhotosStep3(photosFull3);
      // setPhotosStep4(photosFull4);
      // setInfo({
      //   gps_padrao: step0 && step0.GPS_padrao,
      //   telhado: step3 && step3.telhado,
      //   anotacao: step4 && step4.anotacao,
      //   anotacao_padrao: step1 && step1.anotacao,
      //   injecao_energia_ca: step1 && step1.injecao_energia_ca,
      //   condominio: step4 && step4.condominio
      // })
      handleCheckStep({ 
        step0: step0 ? true : false,
        step1: step1 ? true : false,
        step1_5: step15 ? true : false,
        step2: step2Ref ? true : false,
        step2_5: step25 ? true : false,
        step3: step3 ? true : false,
        step4: step4 ? true : false,
        step4_5: step45 ? true : false
       })
      setCheckStep({ 
        step0: step0 ? true : false,
        step1: step1 ? true : false,
        step1_5: step15 ? true : false,
        step2: step2Ref ? true : false,
        step2_5: step25 ? true : false,
        step3: step3 ? true : false,
        step4: step4 ? true : false,
        step4_5: step45 ? true : false
       })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataVisit, data])

  console.log(checkStep);

  // const settingsVisit = {
  //   dots: true,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   // autoplay: true,
  //   adaptiveHeight: true,
  //   autoplaySpeed: 2000,
  // };

  // const changeView = (change, data) => {
  //   if(change === 'open') {
  //     setOpen(true);
  //     setViewPhoto(data);
  //   } else {
  //     setOpen(false);
  //     setTimeout(() => {
  //       setViewPhoto('');
  //     }, 500);
  //   }
  // }

  // const deleteAnot = async(anot) => {
  //   try {
  //     const docRef = doc(dataBase, 'Visitas', data.id);
  //     Swal.fire({
  //       title: 'Anotação',
  //       html: `Você deseja excluir a <b>Anotação?</b>`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //       if(result.isConfirmed) {
  //         const collectionAnot = data.anotacao;
  //         const find = collectionAnot.findIndex((date) => date.dataCreat === anot.dataCreat);
  //         collectionAnot.splice(find, 1)
  //         await updateDoc(docRef, {
  //           anotacao: collectionAnot,
  //           updateAt: serverTimestamp()
  //         }).then(() => {
  //           Swal.fire({
  //             title: 'Anotação',
  //             html: `A Anotação foi deletada com sucesso.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#111",
  //           })
  //           setViewEdit(null)
  //           setCheckAnotacao();
  //           setAnotacao();
  //         });
  //       }
  //     })
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  // const confirmAnot = async() => {
  //   try {

  //     const docRef = doc(dataBase, 'Visitas', data.id);
  //     Swal.fire({
  //       title: 'Anotação',
  //       html: `Você deseja alterar a <b>Anotação?</b>`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //       if(result.isConfirmed) {
  //         const collectionAnot = data.anotacao ? data.anotacao : [];
  //         const find = collectionAnot.findIndex((date) => date.dataCreat === checkAnotacao)
  //         if(find === -1) {
  //           collectionAnot.push({
  //             data: `[${moment(new Date()).format('DD/MM/YYYY')} às ${moment(new Date()).format('HH:mm')}] ${userRef && userRef.nome}:`,
  //             dataCreat: new Date(),
  //             text: anotacao,
  //             uid: userRef && userRef.uid
  //           })
  //         } else {
  //           collectionAnot[find] = {
  //                 data: collectionAnot[find].data,
  //                 dataCreat: new Date(),
  //                 text: anotacao,
  //                 uid: userRef && userRef.uid
  //               }
  //         }
  //         await updateDoc(docRef, {
  //           anotacao: collectionAnot,
  //           updateAt: serverTimestamp()
  //         }).then(() => {
  //           Swal.fire({
  //             title: 'Anotação',
  //             html: `A Anotação foi alterada com sucesso.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#0eb05f",
  //           })
  //           setViewEdit(null);
  //           setCheckAnotacao();
  //           setAnotacao();
  //         });
  //       }
  //     })
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  // const confirmInstall = async() => {
  //   try {
  //     if(instaladores.find(data => data.nome === '' || data.documento === '')) {
  //       return Swal.fire({
  //         title: 'Instaladores',
  //         html: `Não é possivel confirmar caso o campo <b>Nome</b> ou <b>RG/CPF</b> esteja vazio.`,
  //         icon: "warning",
  //         showCloseButton: true,
  //         confirmButtonColor: "red",
  //         confirmButtonText: "Ok",
  //       })
  //      } else {
  //        Swal.fire({
  //          title: 'Instaladores',
  //          html: `Você deseja confirmar os <b>Instaladores?</b>`,
  //          icon: "question",
  //          showCancelButton: true,
  //          showCloseButton: true,
  //          confirmButtonColor: "#0eb05f",
  //          cancelButtonColor: "#d33",
  //          confirmButtonText: "Sim",
  //          cancelButtonText: "Não",
  //        }).then(async (result) => {
  //          if(result.isConfirmed) {
  //           const docRef = doc(dataBase, 'Visitas', data.id);
  //           let collectionInstall = data.instaladores ? data.instaladores : [];
  //           collectionInstall = collectionInstall.concat(instaladores);
  //            await updateDoc(docRef, {
  //              instaladores: collectionInstall
  //            }).then(() => {
  //              Swal.fire({
  //                title: 'Instaladores',
  //                html: `Os instaladores foram confirmados com sucesso.`,
  //                icon: "success",
  //                showConfirmButton: true,
  //                showCloseButton: true,
  //                confirmButtonColor: "#111",
  //              })
  //              setInstaladores([{nome: '', documento: ''}])
  //              setAddInstall(false)
  //              setAnotacao();
  //            });
  //          }
  //        })
  //      }
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  // const handleAnot = (anot, type) => {
  //   if(type === 'close') {
  //     setViewEdit();
  //     setAnotacao();
  //     setCheckAnotacao();
  //   } else if(type === 'edit') {
  //     setAnotacao(anot.text);
  //     setViewEdit(data.id);
  //     setCheckAnotacao(anot.dataCreat);
  //   } else if(type === 'add') {
  //     setViewEdit(data.id);
  //   }
  // }

  // const handleInput = (index, value, type) => {
  //   const newInputs = [...instaladores];
  //   if(type === 'nome') {
  //     newInputs[index].nome = value;
  //   } else {
  //     newInputs[index].documento = value;
  //   }
  //   setInstaladores(newInputs);
  // }

  // const deleteInput = (index) => {
  //   const newInputs = instaladores.filter(((_, i) => i !== index))
  //   setInstaladores(newInputs);
  // }

  // const deleteInstall = (document) => {
  //   try {
  //     Swal.fire({
  //       title: 'Instaladores',
  //       html: `Você deseja excluir o <b>Instalador?</b>`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //       if(result.isConfirmed) {
  //       const docRef = doc(dataBase, 'Visitas', data.id);
  //        let collectionInstall = data.instaladores;
  //        collectionInstall = collectionInstall.filter(data => data.documento !== document);
  //         await updateDoc(docRef, {
  //           instaladores: collectionInstall
  //         }).then(() => {
  //           Swal.fire({
  //             title: 'Instaladores',
  //             html: `O instalador foi excluido com sucesso.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#0eb05f",
  //           })
  //         });
  //       }
  //     })
  //   } catch (error) {

  //   }
  // }

  // const addInput = () => {
  //   if(!addInstall) {
  //     return setAddInstall(true);
  //   } else {
  //     setInstaladores([...instaladores, {nome: '', documento: ''}])
  //   }
  // }

  // const closeInstall = () => {
  //   setInstaladores([{nome: '', documento: ''}])
  //   setAddInstall(false);
  // }

  // const viewAnalysis = (title, msg) => {
  //   Swal.fire({
  //     title: title,
  //     html: `<b>Técnico: </b> ${msg}`,
  //     icon: 'warning',
  //     showCloseButton: true,
  //     confirmButtonColor: "#111",
  //     confirmButtonText: 'Fechar'
  //   })
  // }

  // const handlejourney = async (journey, index) => {
  //   try {
  //     Swal.fire({
  //       title: 'Jornada do Cliente',
  //       html: `Você deseja alterar para <b>${journey}</b>?`,
  //       icon: 'question',
  //       showCloseButton: true,
  //       showCancelButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: 'Sim',
  //       cancelButtonText: 'Não',
  //     }).then(async (result) => {
  //       if(result.isConfirmed) {
  //         const docRef = doc(dataBase, 'Visitas', data.id);
  //         if(journey === 'Venda Concluida' && data.indicador.nome !== "Nenhum") {
  //           await updateDoc(doc(dataBase, 'Comissoes', data.id), {
  //             etapa: 'Venda Concluida',
  //             updateAt: serverTimestamp(),
  //             data: moment().format('DD/MM/YYYY'),
  //             historico: [
  //               {
  //                 data: moment().format('DD/MM/YYYY HH:mm'),
  //                 text: `A BackOffice <b>${userRef && userRef.nome}</b> confirmou a venda do Kit Fotovoltaico.`
  //               }
  //             ]
  //           })
  //         }
  //         await updateDoc(docRef, {
  //           jornada: {
  //             etapa: journey,
  //             index: index,
  //             sigla: journeySG[index],
  //             cor: journeyColor[index],
  //             data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
  //           }
  //         }).then(() => {
  //           Swal.fire({
  //             title: 'Jornada do Cliente',
  //             html: `A Jornada do Cliente foi alterada com sucesso.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#0eb05f",
  //           })
  //         });
  //       }
  //     })
  //   } catch (error) {
  //     console.error('[ERRO]: ', error)
  //   }
  // }
  
  // return (
    // <div className={style.visit_tec}>
    //   <Dialog
    //                     open={open}
    //                     fullScreen
    //                     onClose={() => changeView('close')}
    //                     >
    //                       <IconButton
    //                         aria-label="close"
    //                         onClick={() => changeView('close')}
    //                         sx={{
    //                           position: 'absolute',
    //                           right: 8,
    //                           top: 8,
    //                           color: (theme) => theme.palette.grey[500],
    //                         }}
    //                       ><CloseIcon /></IconButton>
    //                       <div className={style.photo}>
    //                         <img src={viewPhoto && viewPhoto} alt='' />
    //                       </div>
    //                       <ThemeProvider theme={theme}>
    //                       <DialogActions sx={{ justifyContent: 'center' }}>
    //                         <Button variant='contained' onClick={() => changeView('close')}>FECHAR</Button>
    //                       </DialogActions>
    //                       </ThemeProvider>
    //                       </Dialog>
    //     {data.categoria === 'comercial_tecnica' ? 
    //       <><h2>Jornada do Cliente</h2>
    //       <div className={style.step_box}>
    //         <div className={style.step_client}>
    //           {journey.map((journey, index) => {
    //             const step = data.jornada?.index; 
    //             let styleStep = null;
    //             if(step === index) {
    //               if(journey === 'Comissão') {
    //                 styleStep = style.active_item_last;
    //               } else {
    //                 styleStep = style.current_active_item;
    //               }
    //             } else if (step > index ) {
    //               styleStep = style.active_item
    //             }
    //             return (
    //               <div className={styleStep}>
    //                 <button disabled={(userRef && userRef.cargo !== 'Administrador' && userRef.nome !== 'Pós-Venda') || index < 1 ? true : false} 
    //                 onClick={() => handlejourney(journey, index)}>
    //                   {journey}
    //                 </button>
    //               </div>
    //           )})}
    //         </div>
    //         {data.jornada ? 
    //           <span>Atualização: {data.jornada?.data}</span> : null
    //         }
    //       </div></> : null
    //     }
    //     {data.observacao && 
    //       <Alert severity="info" color='warning' sx={{ margin: '1rem 0', width: '90%', justifyContent: 'center' }}>
    //         <AlertTitle>Observação</AlertTitle>
    //         <b>{data.observacao}</b>
    //       </Alert>    
    //     }
    //     <h2>Informações</h2>
    //     <div className={style.info_content}>
    //       <div className={style.items}>
    //         <ul className={style.info_item}>
    //           <li><p><b>Nome:</b> {data.cliente}</p></li>
    //           <li><p><b>Cidade: </b>{data.cidade}</p></li>
    //           <li><p><b>Telefone: </b>{data.preData?.telefone}</p></li>
    //           <li><p><b>Análise Padrão: </b>{data.analise_padrao && data.analise_padrao ?
    //            data.analise_padrao : 'Aguardando o Técnico'}
    //            {data.analise_padrao === 'Sim ✅' && <IconButton aria-label="Visualizar Análise do Técnico" data-cooltipz-dir="right" size='small' sx={{ marginBottom: '0.2rem' }} onClick={() => viewAnalysis('Análise Padrão', data.anotacao_padrao)} ><InfoIcon sx={{ width: '20px', height: '20px' }} /></IconButton>}</p></li>
    //           <li><p><b>Análise Telhado: </b>{data.analise_telhado && data.analise_telhado ? data.analise_telhado : 'Aguardando o Técnico'}
    //           {data.analise_telhado === 'Sim ✅' && <IconButton aria-label="Visualizar Análise do Técnico" data-cooltipz-dir="right" size='small' sx={{ marginBottom: '0.2rem' }} onClick={() => viewAnalysis('Análise Telhado', data.anotacao_telhado)} ><InfoIcon sx={{ width: '20px', height: '20px' }} /></IconButton>}</p></li>
    //         </ul>          
    //         <ul className={style.info_item}>
    //           <li><p><b>Concessionária:</b> {data.concessionaria ? data.concessionaria : 'Não Informado'}</p></li>
    //           <li><p><b>Bitola Ramal de Entrada:</b> {data.medida_bitola_ramal ? data.medida_bitola_ramal : 'Aguardando o Técnico'}</p></li>
    //           <li><p><b>Bitola Rede do Cliente:</b> {data.medida_bitola_cliente ? data.medida_bitola_cliente : 'Aguardando o Técnico'}</p></li>
    //           <li><p><b>Precisa trocar o Disjuntor? </b> {data.trocar_disjuntor ? data.trocar_disjuntor : 'Aguardando o Técnico'}</p></li>
    //         </ul>          
    //         <ul className={style.info_item}>
    //           <li><p><b>A injeção de Energia CA deverá ser no Disjuntor geral?</b> {checkStep.step1 ? info.injecao_energia_ca : 'Aguardando o Técnico'}</p></li>
    //           <li><p><b>Possui DPS?</b> {data.dps ? data.dps : 'Aguardando o Técnico'}</p></li>
    //           <li><p><b>Localização do GPS do Padrão:</b> {checkStep.step0 ? <a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${info && info.gps_padrao.lat},${info && info.gps_padrao.lng}`} >Clique aqui</a> : 'Aguardando o Técnico'} </p></li>
    //           <li><p><b>Será realizado Monitoramento? </b>{data.monitoramento ? data.monitoramento : 'Aguardando o Pós-Venda'}</p></li>
    //         </ul>          
    //       </div>
    //         <h3>Telhado</h3>
    //       <div className={style.items}>
    //           {checkStep.step3 ? info.telhado.map((data, index) => (
    //           <ul key={index} className={style.info_item}>
    //             <li><p><b>Tipo:</b> {data.tipo}</p></li>
    //             <li><p><b>Medida: </b>{data.medida}</p></li>
    //           </ul>                  
    //           )) : 
    //           <ul className={style.info_item}>
    //             <li><p><b>Tipo:</b> Aguardando o Técnico</p></li>
    //             <li><p><b>Medida: </b> Aguardando o Técnico</p></li>
    //           </ul>}
    //       </div>
    //       <h3>A Instalação será em Condomínio? {data && data.condominio ? data.condominio : "Não Informado"}</h3>
    //       {data && data.condominio === 'Sim ✅' && 
    //       <><div className={style.items_install}>
    //         {data.instaladores && data.instaladores.map((data, index) => (
    //           <><ul key={index} className={style.info_item}>
    //             <li><p><b>Nome:</b> {data.nome}</p></li>
    //             <li><p><b>RG/CPF: </b>{data.documento}</p></li>
    //           </ul>
    //           <IconButton color='error' className={style.delete_input} onClick={() => deleteInstall(data.documento)} variant="outlined"><CloseIcon /></IconButton> </>
    //         ))}
    //       </div>
    //       <div className={style.items_big}>
    //           {addInstall ?
    //             <div>
    //               {instaladores.map((pessoa, index) => (
    //                 <div key={index} className={style.input_double}>
    //                   <TextField
    //                     autoFocus
    //                     fullWidth
    //                     margin="dense"
    //                     label="Nome"
    //                     type="text"
    //                     value={pessoa.nome ? pessoa.nome : ''}
    //                     onChange={(e) => handleInput(index, e.target.value, 'nome')}
    //                     variant="outlined" />
    //                   <TextField
    //                     autoFocus
    //                     fullWidth
    //                     margin="dense"
    //                     label="RG/CPF"
    //                     type="text"
    //                     value={pessoa.documento ? pessoa.documento : ''}
    //                     onChange={(e) => handleInput(index, e.target.value, 'documento')}
    //                     variant="outlined" />
    //                   <IconButton color='error' disabled={index === 0 ? true : false} className={style.delete_input} onClick={() => deleteInput(index)} variant="outlined"><CloseIcon /></IconButton>
    //                 </div>
    //               ))}
    //               <Button sx={{ margin: '1rem 0', width: '15rem' }} color='success' variant='outlined' onClick={() => addInput()} startIcon={<AddCircleIcon />}>Adicionar Instalador</Button>
    //               <div className={style.anotacao_button}>
    //                 <Button
    //                   variant="outlined"
    //                   color="success"
    //                   size="small"
    //                   type="submit"
    //                   startIcon={<CheckCircleOutlineIcon />}
    //                   onClick={() => confirmInstall()}
    //                 >
    //                   Confirmar
    //                 </Button>
    //                 <Button
    //                   variant="outlined"
    //                   color="error"
    //                   size="small"
    //                   type="submit"
    //                   startIcon={<BlockIcon />}
    //                   onClick={() => closeInstall()}
    //                 >
    //                   Cancelar
    //                 </Button>
    //               </div>
    //             </div> :
    //             <Button sx={{ margin: '1rem 0', width: '15rem' }} color='success' variant='outlined' onClick={() => addInput()} startIcon={<AddCircleIcon />}>Adicionar Instalador</Button>}
    //         </div>
    //         </>
    //       }
    //     </div>
    //     <div>
    //     </div>
    //     <h2>Anotação</h2>
    //     {viewEdit && viewEdit === data.id ?
    //       <><textarea className={style.edit_anotacao} value={anotacao} onChange={(e) => setAnotacao(e.target.value)} cols="30" rows="5"></textarea>
    //       <div className={style.anotacao_button}>
    //       <Button
    //         variant="outlined"
    //         color="success"
    //         size="small"
    //         type="submit"
    //         startIcon={<CheckCircleOutlineIcon />}
    //         onClick={() => confirmAnot()}
    //       >
    //         Confirmar
    //       </Button>
    //       <Button
    //         variant="outlined"
    //         color="error"
    //         size="small"
    //         type="submit"
    //         startIcon={<BlockIcon />}
    //         onClick={() => handleAnot(data.id, 'close')}
    //       >
    //         Cancelar
    //       </Button>
    //     </div></>:
    //        <><div className={style.anotacao}>
    //         {data.anotacao && data.anotacao.map((anot, index) => (
    //           <div key={index}>
    //             <div>
    //             <b>{anot.data}</b>
    //             <p>{anot.text}</p>
    //             </div>
    //               {userRef && (userRef.uid === anot.uid || userRef.cargo === 'Administrador') && 
    //             <div>
    //             <IconButton
    //               aria-label="Editar Anotação"
    //               data-cooltipz-dir="left"
    //               size="small"
    //               onClick={() => handleAnot(anot, 'edit')}
    //               ><EditIcon /></IconButton>
    //             <IconButton
    //               aria-label="Deletar Anotação"
    //               data-cooltipz-dir="left"
    //               size="small"
    //               color='error'
    //               onClick={() => deleteAnot(anot)}
    //               ><DeleteIcon /></IconButton>
    //             </div>
    //               }
    //             </div>
    //         ))}
    //     </div>
    //     <Button variant="outlined"
    //         color="success"
    //         size="small"
    //         sx={{ marginBottom: '1rem' }}
    //         startIcon={<AddCircle />}
    //         onClick={() => setViewEdit(data.id, 'add')}>Adicionar Anotacao</Button>
    //     </>}
            
    //     <h2>Fotos</h2>
    //      <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Fotos" centered>
    //           <Tab label="Entrada" {...a11yProps(1)} />
    //           <Tab label="Telhado" {...a11yProps(2)} />
    //           <Tab label="Telhado com medida" {...a11yProps(3)} />
    //           <Tab label="Projeto" {...a11yProps(4)} />
    //      </Tabs>
    //      <CustomTabPanel value={TabsValue} index={0}>
    //       {checkStep.step1_5 ? 
    //         <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
    //         <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
    //         {photosStep1 && photosStep1.map((photo, index) => (
    //           <div key={index} className={style.photos} onClick={() => changeView('open', photo)}>
    //             <img rel="preconnect" src={photo} alt="" />
    //           </div>
    //         ))}
    //       </SlickSlider>
    //       <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> :
    //         <h2 className={style.alert}>As Fotos ainda não foram enviadas pelo Técnico</h2>
    //     }
    //      </CustomTabPanel>
    //      <CustomTabPanel value={TabsValue} index={1}>
    //       {checkStep.step2 ? 
    //       <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton><SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
    //         {photosStep2 && photosStep2.map((photo, index) => (
    //           <div key={index} className={style.photos}>
    //             {photo.type === 'photo' ? 
    //             <img rel="preconnect" src={photo.url} alt="" onClick={() => changeView('open', photo.url)} /> : 
    //             <video className={style.img_visit} width='100%' controls muted playsInline>
    //               <source src={photo.url} type="video/mp4"/>
    //             </video>
    //           }
    //           </div>
    //         ))}
    //       </SlickSlider><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> :
    //       <h2 className={style.alert}>As Fotos ainda não foram enviadas pelo Técnico</h2> 
    //       }
    //      </CustomTabPanel>
    //      <CustomTabPanel value={TabsValue} index={2}>
    //       {checkStep.step3 ? 
    //       <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
    //       {photosStep3.length === 1 ? 
    //       <div className={style.photos} onClick={() => changeView('open', photosStep3)}>
    //         <img rel="preconnect" src={photosStep3} alt="" />
    //       </div> : 
    //       <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
    //         {photosStep3 && photosStep3.map((photo, index) => (
    //           <div data-index={index} key={index} className={style.photos} onClick={() => changeView('open', photo)}>
    //             <img rel="preconnect" src={photo} alt="" />
    //           </div>
    //         ))}
    //       </SlickSlider>
    //       }
    //       <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> : 
    //       <h2 className={style.alert}>As Fotos ainda não foram enviadas pelo Técnico</h2>
    //     }
    //      </CustomTabPanel>
    //      <CustomTabPanel value={TabsValue} index={3}>
    //       {checkStep.step4 ? 
    //       <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
    //       {photosStep4.length === 1 ? 
    //       <div className={style.photos} onClick={() => changeView('open', photosStep3)}>
    //         <img rel="preconnect" src={photosStep4} alt="" />
    //       </div> : 
    //       <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
    //         {photosStep4 && photosStep4.map((photo, index) => (
    //           <div data-index={index} key={index} className={style.photos} onClick={() => changeView('open', photo)}>
    //             <img rel="preconnect" src={photo} alt="" />
    //           </div>
    //         ))}
    //       </SlickSlider>
    //       }
    //       <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> : 
    //       <h2 className={style.alert}>As Fotos ainda não foram enviadas pelo Pós-Venda</h2>
    //     }
    //      </CustomTabPanel>
    //      <div>
    //       <Link to={"../visita/" + data.id} target='_blank'>
    //         <Button 
    //         disableElevation 
    //         startIcon={<ArticleIcon />}
    //         sx={{ margin: '1rem 1rem 2rem 0' }} 
    //         disabled={checkStep.step1_5 && checkStep.step2 && checkStep.step3 ? false : true}
    //         variant="contained">Ver Visita Completa</Button> 
    //       </Link>
    //       <Link to={"../visita/" + data.id + "/orcamento"} target='_blank'>
    //         <Button 
    //         disableElevation
    //         startIcon={<RequestQuoteIcon />}
    //         sx={{ margin: '1rem 0 2rem 0' }} 
    //         disabled={checkStep.step1_5 && checkStep.step2 && checkStep.step3 ? false : true}
    //         variant="contained" 
    //         color='warning'>Orçamento</Button> 
    //       </Link>
    //      </div>
    //   </div>
  // )
}

export default memo(VisitTec);
import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { dataBase } from '../../firebase/database';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { AlertTitle, Box, IconButton, MenuItem, Select, SvgIcon, TextField } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { Link } from 'react-router-dom';
import moment from 'moment';

import NewTooltip from '../../components/Tooltip';
import { useMember } from '../../hooks/useMember';
import formatCurrency from '../../functions/formatCurrency';

import { ReactComponent as ScheduleIcon } from "../../images/icons/Schedule1.svg";

import style from "./style.module.scss";
import Swal from 'sweetalert2';

const Install = ({ visits, userRef }) => {
  // eslint-disable-next-line no-unused-vars
  const [works, setWorks] = useState([]);
  const { getMemberColor } = useMember();
  const [loadingButton, setLoadingButton] = useState(false);
  const [info, setInfo] = useState({
    cliente: null,
    entrega_kit: '',
    data: moment(),
    horario: moment(),
    historico_instalacao: [],
    historico_entrega: [],
    historico_instalacao_final: [],
  });
  const [worksRef, setWorksRef] = useState([]);
  const [modeEdit, setModeEdit] = useState(null);
  const month = ['Todos','Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
  const [year, setYear] = useState('2024');
  const [monthSelect, setMonthSelect] = useState(
    '00'
  );
  
  useEffect(() => {
    if (visits) {
      const works = visits.filter((data) => data.instalador?.dataRef);

      setWorksRef(works.sort((a, b) => {
       if(a.instalador.dataRef < b.instalador.dataRef) {
        return 1;
       }
       if(a.instalador.dataRef > b.instalador.dataRef) {
        return -1;
       }
       return 0;
      }))
    }
  },[visits])

  useEffect(() => {
    if(worksRef) {
      if(monthSelect === '00') {
        setWorks(worksRef);
      } else {
        setWorks(worksRef.filter(data => data.instalador?.data.substring(0,7) === year + '-' + monthSelect));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[worksRef, monthSelect])

  const isPeriodAvailable = (work) => { // Inserior o ID do Tecnico
    // Criar intervalos de datas para o novo período, sem considerar a hora
    const workId  = work.id;
    const newStart = info.data;
    const newEnd = info.data_final;
    const newPeriodStart = moment(newStart).startOf('day');
    const newPeriodEnd = moment(newEnd).endOf('day');
    const worksFilter = works.filter(data => data.id !== workId && data.instalador.uid === work.instalador.uid);
    console.log("🚀 ~ isPeriodAvailable ~ worksFilter:", worksFilter)
    const workPeriods = worksFilter.map((data) => {
      return {
        start: data.instalador.data,
        end: data.instalador.data_final ?? data.instalador.data
      }
    })
  
    // Verificar se o novo período colide com algum dos períodos agendados
    for (let period of workPeriods) {
      const scheduledStart = moment(period.start).startOf('day');
      const scheduledEnd = moment(period.end).endOf('day');
  
      // Verificar se há sobreposição entre os períodos ou se são no mesmo dia
      if (newPeriodStart.isBetween(scheduledStart, scheduledEnd, null, '[]') ||
          newPeriodEnd.isBetween(scheduledStart, scheduledEnd, null, '[]') ||
          scheduledStart.isBetween(newPeriodStart, newPeriodEnd, null, '[]') ||
          scheduledEnd.isBetween(newPeriodStart, newPeriodEnd, null, '[]') ||
          newPeriodStart.isSame(scheduledStart, 'day') ||
          newPeriodEnd.isSame(scheduledEnd, 'day') ||
          scheduledStart.isSame(newPeriodStart, 'day') ||
          scheduledEnd.isSame(newPeriodEnd, 'day')) {
        return false;
      }
    }
  
    return true;
  };

  const cancelInstall = async (data) => {
    try {
      Swal.fire({
        title: 'Instalação',
        html: `Você deseja cancelar o <b>Agendamento da Instalação</b>?`,
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if(result.isConfirmed) {
          const docRef = doc(dataBase, 'Visitas', data.id);
          await updateDoc(docRef, {
            instalador: {
              ...data.instalador,
              nome: data.instalador?.nome,
              telefone: data.instalador?.telefone,
              uid: data.instalador?.uid,
              dataRef: null,
              data: null,
              horario: null
            }
          }).then(() => {
            Swal.fire({
              title: 'Instalação',
              html: `O Agendamento da Instalação foi cancelado com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
          });
        }
      })
    } catch (error) {
      console.error('[ERRO]: ', error)
    }
  }

  const handleEdit = (work) => {
    setModeEdit(work.id);
    console.log(work)
    if(work.instalador.data || work.instalador.horario) {
      setInfo({...info, 
        data: work.instalador.data,
        data_final: work.instalador.data_final ?? '',
        cliente: work.instalador.cliente ?? '',
        horario: work.instalador.horario,
        horario_final: work.instalador.horario_final ?? '',
        entrega_kit: work.instalador.entrega_kit ?? '',
        historico_instalacao: work.instalador.historico_instalacao ? [...work.instalador.historico_instalacao] : [],
        historico_instalacao_final: work.instalador.historico_instalacao_final ? [...work.instalador.historico_instalacao_final] : [],
        historico_entrega: work.instalador.historico_entrega ? [...work.instalador.historico_entrega] : [],
      })
    }
  }

  const saveInstall = async (work) => {
    try {
      console.log(info);
      if(info.cliente === null || (info.data_final.length > 0 && info.horario_final.length === 0)) {
        let msg = '';
        if(info.cliente === null) {
          msg = `Você deve selecionar o nível do <b>Cliente</b> para prosseguir.`
        } else if (info.data_final.length === 0) {
          msg = `Você deve selecionar a <b>Data Final</b> para prosseguir.`
        } else if (info.horario_final.length === 0) {
          msg = `Você deve selecionar o <b>Horário Final</b> para prosseguir.`
        }

        return Swal.fire({
          title: 'Instalação',
          html: msg,
          icon: "error",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
        })
        
      } else {
        const confirmed = await Swal.fire({
          title: 'Instalação',
          html: `Você deseja salvar as informações?`,
          icon: 'question',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })

        if(confirmed.isConfirmed) {
          setLoadingButton(true);
          let props = {...info};

          if(work.instalador.data !== info.data || work.instalador.horario !== info.horario) {
            props = {
              ...info,
              historico_instalacao: [
                ...info.historico_instalacao,
                {
                  data: work.instalador.data,
                  horario: work.instalador.horario,
                }
              ]
            }
          }

          if((work.instalador.data_final && work.instalador.data_final !== info.data_final) || 
           (work.instalador.horario_final && work.instalador.horario_final !== info.horario_final)) {
            props = {
              ...props,
              historico_instalacao_final: [
                ...info.historico_instalacao_final,
                {
                  data: work.instalador.data_final,
                  horario: work.instalador.horario_final,
                }
              ]
            }
          }

          if (work.instalador.entrega_kit && work.instalador.entrega_kit !== info.entrega_kit) {
            props = {
              ...props,
              historico_entrega: [
                ...info.historico_entrega,
                work.instalador.entrega_kit
              ]
            }
          }

          await updateDoc(doc(dataBase, 'Visitas', work.id), {
            instalador: {
              ...work.instalador,
              ...props,
              dataRef: new Date(`${info.data_final.length > 0 ? info.data_final : info.data}T${info.horario_final.length > 0 ? info.horario_final : info.horario}`)
            }
          })

          await Swal.fire({
            title: 'Instalação',
            html: `As informações foram salvas com sucesso.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
          })
          closeEdit();
          setLoadingButton(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const closeEdit = () => {
    setModeEdit(null);
    setInfo({
      cliente: null,
      entrega_kit: '',
      data: moment(),
      horario: moment(),
      historico_instalacao: [],
      historico_entrega: [],
      historico_instalacao_final: [],
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value)
    setInfo({...info, [name]: value});
  }

  console.log(works)

  const colorClient = (value) => {
    switch (value) {
      case 'Aguardando':
        return "#11d532";
      case 'Em Espera':
        return "#ffe233";
      case 'Cobrando':
        return "#ff3f3f";
      default: return "#ccc"
    }
  }

  const alertHistoric = (type, historic) => {

    let historicMsg;
    if(type === 'instalação') {
      historicMsg = historic.map(
        (e) =>
          "Data: <b>" +
          moment(e.data).format('DD/MM/YYYY') +
          "</b> Horário: <b>" +
          e.horario +
          "</b></br>"
      );
    } else {
      historicMsg = historic.map(
        (e) =>
          "Data: <b>" +
          moment(e).format('DD/MM/YYYY') + "</b></br>"
      );
    }

    return Swal.fire({
      title: 'Histórico',
      html: `${historicMsg}`,
      icon: "info",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      didRender: () => {
        const container = document.getElementById('swal2-html-container');
        if (container) {
          container.innerHTML = container.innerHTML.replace(/<br>,/g, '<br>');
        }
      }
    })
  }

  const hasDifferences = (obj1, obj2) => {
    const obj1Ref = {
        cliente: obj1.cliente,
        entrega_kit: obj1.entrega_kit,
        data: obj1.data,
        horario: obj1.horario, 
        data_final: obj1.data_final,
        horario_final: obj1.horario_final, 
    }
    const obj2Ref = {
        cliente: obj2.cliente ?? '',
        entrega_kit: obj2.entrega_kit ?? '',
        data: obj2.data,
        horario: obj2.horario, 
        data_final: obj2.data_final ?? '',
        horario_final: obj2.horario_final ?? '', 
    }


    const keys1 = Object.keys(obj1Ref);
  
    // Verificar se os valores das propriedades são diferentes
    for (let key of keys1) {
      if (obj1[key] !== obj2Ref[key]) {
        return true;
      }
    }
  
    return false;
  };

  const viewDetails = (work) => {

    const workDetails = `Quantidade de Módulos: <b>${work.kit_info?.placa_quantidade ?? 'Não Informado'}</b></br>` + 
    `Preço da Instalação: <b>${work.kit_info?.instalacao ? formatCurrency(work.kit_info?.instalacao) : 'Não Informado'}</b>`;

    return Swal.fire({
      title: 'Detalhes',
      html: `${workDetails}`,
      icon: "info",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      didRender: () => {
        const container = document.getElementById('swal2-html-container');
        if (container) {
          container.innerHTML = container.innerHTML.replace(/<br>,/g, '<br>');
        }
      }
    })
  }

  return (
    <div className="container-schedule">
      <div className="title-schedule">
        <ScheduleIcon />
        <h2>Agenda {year} </h2>
        <h2>Instalações {monthSelect === '00' ? 'do Ano' : `de ${month[parseFloat(monthSelect)]}`} </h2>
            <div className="schedule-month">
            <select
              value={monthSelect}
              className="schedule-month__select"
              name="month"
              onChange={(e) => setMonthSelect(e.target.value)}
            >
              {month.map((data, index) => {
                const numberFormat = index < 10 ? (index).toString().padStart(2, '0') : (index).toString()
                return (
                  <option key={index} value={numberFormat}>{data}</option>
                )
              })}
            </select>
            <select
                value={year}
                className="schedule-month__select"
                name="year"
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="2024">2024</option>
              </select>
          </div>
      </div>
      <div className={style.content_panel}>
          <div className={style.box_panel_works}>
            <TableContainer className={style.table_center} component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Cliente</TableCell>
                    <TableCell align="center">Etapa</TableCell>
                    <TableCell align="center">Cliente</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center">Instalador</TableCell>
                    <TableCell align="center" colSpan={2}>
                      Data da Instalação (Inicio e Fim)
                    </TableCell>
                    <TableCell align="center">Entrega do Kit</TableCell>
                    <TableCell align="center">Analista</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {works.length > 0 ? works.map((work) => (
                    <>
                    <TableRow
                        hover
                        key={work.id}
                        className={style.row_work}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">
                        {modeEdit === work.id ? 
                        <Select
                        size='small'
                        name='cliente'
                        className={style.client}
                        value={info.cliente ?? ''}
                        onChange={handleChange}
                        variant='standard'>
                          <MenuItem value="Aguardando"><SvgIcon sx={{ marginRight: '0.2rem' }}><CircleIcon sx={{ fill: "#11d532"}} /></SvgIcon> Aguardando</MenuItem>
                          <MenuItem value="Em Espera"><SvgIcon sx={{ marginRight: '0.2rem' }}><CircleIcon sx={{ fill: "#ffe233" }} /></SvgIcon> Em Espera</MenuItem>
                          <MenuItem value="Cobrando"><SvgIcon sx={{ marginRight: '0.2rem' }}><CircleIcon sx={{ fill: "#ff3f3f" }} /></SvgIcon> Cobrando</MenuItem>
                        </Select> : 
                          <NewTooltip title={work.instalador?.cliente ?? 'Não Definido'} placement="top">
                            <CircleIcon sx={{ fill: colorClient(work.instalador?.cliente) , fontSize: "35px" }} />
                          </NewTooltip>
                          }
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: work.instalacao ? '#39e58f' : work.instalacao_etapas ? '#ffbd49cf' : '#47cdfda1' }}>{work.instalacao ? 'Concluida' : work.instalacao_etapas ? 'Finalizado' : 'Agendado'}</TableCell>
                        <NewTooltip title='Clique para ver mais detalhes' placement='right'>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => viewDetails(work)} align="center">{work.cliente}</TableCell>
                        </NewTooltip>
                        <TableCell align="center">{work.cidade}</TableCell>
                        <TableCell align="center" sx={{ color: "#fff", backgroundColor: getMemberColor(work.instalador?.uid) }}>{work.instalador?.nome}</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: "#e6ffe1" }}>
                          {modeEdit === work.id ?
                            <><TextField 
                              variant="standard" 
                              sx={{ fontSize: '0.7rem', marginRight: '0.5rem' }} 
                              size='small' 
                              type='date'
                              name='data'
                              onBlur={() => isPeriodAvailable(work)}
                              value={info.data ?? ''}
                              onChange={handleChange} /> 
                            <TextField 
                              variant="standard" 
                              sx={{ fontSize: '0.7rem' }} 
                              size='small' 
                              type='time'
                              name='horario'
                              onBlur={() => isPeriodAvailable(work)}
                              value={info.horario ?? ''}
                              onChange={handleChange} /></>:
                          work.instalador?.data ? 
                          <>{moment(work.instalador?.data).format("DD/MM/YYYY")} ás {work.instalador?.horario}
                            {work.instalador.historico_instalacao && work.instalador.historico_instalacao.length > 0 ? 
                              <NewTooltip title='Visualizar Datas Anteriores' placement='top'>
                                <IconButton onClick={() => alertHistoric('instalação', work.instalador.historico_instalacao)} size='small' sx={{ marginLeft: '0.2rem' }}><AccessTimeFilledRoundedIcon sx={{ fontSize: '20px' }} /></IconButton>
                              </NewTooltip> : null
                            }</> :
                           'Ainda não definido'}
                        </TableCell>
                        <TableCell align="center" sx={{ backgroundColor: "#f8fdda" }}>
                        {modeEdit === work.id ?
                            <><TextField 
                              variant="standard" 
                              sx={{ fontSize: '0.7rem', marginRight: '0.5rem' }} 
                              size='small' 
                              type='date'
                              name='data_final'
                              onBlur={() => isPeriodAvailable(work)}
                              value={info.data_final ?? ''}
                              onChange={handleChange} /> 
                            <TextField 
                              variant="standard" 
                              sx={{ fontSize: '0.7rem' }} 
                              size='small' 
                              type='time'
                              name='horario_final'
                              onBlur={() => isPeriodAvailable(work)}
                              value={info.horario_final ?? ''}
                              onChange={handleChange} /></>:
                          work.instalador?.data_final ? 
                          <>{moment(work.instalador?.data_final).format("DD/MM/YYYY")} ás {work.instalador?.horario_final}
                            {work.instalador.historico_instalacao_final && work.instalador.historico_instalacao_final.length > 0 ? 
                              <NewTooltip title='Visualizar Datas Anteriores' placement='top'>
                                <IconButton onClick={() => alertHistoric('instalação', work.instalador.historico_instalacao_final)} size='small' sx={{ marginLeft: '0.2rem' }}><AccessTimeFilledRoundedIcon sx={{ fontSize: '20px' }} /></IconButton>
                              </NewTooltip> : null
                            }</> :
                           'Ainda não definido'}
                        </TableCell>
                        <TableCell align="center">
                          {modeEdit === work.id ? 
                            <TextField 
                              sx={{ fontSize: '0.7rem' }} 
                              variant="standard" 
                              size='small' 
                              type='date'
                              name='entrega_kit'
                              value={info.entrega_kit ?? ''}
                              onChange={handleChange} /> : 
                            work.instalador?.entrega_kit ? 
                          <>{moment(work.instalador?.entrega_kit).format("DD/MM/YYYY")}
                            {work.instalador.historico_entrega && work.instalador.historico_entrega.length > 0 ? 
                              <NewTooltip title='Visualizar Datas Anteriores' placement='top'>
                                <IconButton onClick={() => alertHistoric('entrega', work.instalador.historico_entrega)} size='small' sx={{ marginLeft: '0.2rem' }}><AccessTimeFilledRoundedIcon sx={{ fontSize: '20px' }} /></IconButton>
                              </NewTooltip> : null
                            }</> :
                           'Ainda não definido' 
                          }
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#fff", backgroundColor: "#932a2a" }}>{work.representante?.nome}</TableCell>
                        <TableCell align="center" sx={{ display: 'flex' }}>
                          {userRef && (userRef.cargo === 'Administrador' || userRef.nome === 'Pós-Venda') && 
                          <><Link to={`/visitas/${work.id}`} target="_blank">
                            <NewTooltip title='Visualizar Visita' placement='top'>
                              <IconButton
                                size="small">
                                <OpenInNewIcon />
                              </IconButton> 
                            </NewTooltip>
                          </Link>
                          {userRef.id === work.representante?.id || userRef.cargo === 'Administrador' ?
                            <><NewTooltip title='Editar' placement='top'>
                              <IconButton
                                size="small"
                                disabled={modeEdit === work.id}
                                onClick={() => handleEdit(work)}>
                                <EditIcon />
                              </IconButton>
                            </NewTooltip>
                            <NewTooltip title='Cancelar Agendamento' placement='top'>
                              <IconButton
                                size="small"
                                disabled={modeEdit === work.id}
                                color='error'
                                onClick={() => cancelInstall(work)}>
                                <DeleteIcon />
                              </IconButton>
                            </NewTooltip>
                            </> : null
                          }
                          </>
                           }
                        </TableCell>
                    </TableRow>
                    {modeEdit === work.id ?
                      <TableRow>
                      <TableCell colSpan={10} sx={{ padding: '0.5rem' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0.8rem' }}>
                          {isPeriodAvailable(work) ? 
                            <Alert severity="success">
                              <AlertTitle><b>Data de Instalação</b></AlertTitle>
                                O periodo informado está livre.
                            </Alert> : 
                            <Alert severity="error">
                            <AlertTitle><b>Data de Instalação</b></AlertTitle>
                              O periodo informado <b>NÃO</b> está livre.
                          </Alert>
                          }
                        </Box>
                        <div className={style.buttons_edit}>
                          <LoadingButton
                            size='small'
                            color='success'
                            disableElevation
                            loading={loadingButton}
                            disabled={!hasDifferences(info, work.instalador) || !isPeriodAvailable(work)}
                            sx={{ textTransform: 'capitalize' }}
                            variant='contained'
                            startIcon={<CheckIcon />}
                            onClick={() => saveInstall(work)}>
                              Salvar
                          </LoadingButton>
                          <LoadingButton
                            size='small'
                            color='error'
                            disableElevation
                            sx={{ textTransform: 'capitalize' }}
                            variant='contained'
                            startIcon={<CloseIcon />}
                            onClick={closeEdit}>
                              Cancelar
                          </LoadingButton>
                        </div>
                      </TableCell>
                    </TableRow> : null
                    }
                    </>
                  )) : 
                  <TableRow>
                    <TableCell align="center" colSpan={8}><p style={{ padding: '0.5rem 0' }}>Não foi encontrado nenhuma Instalação nesse período</p></TableCell>
                  </TableRow>}
                  </TableBody>
              </Table>
            </TableContainer>
          </div>
      </div>
    </div>
  )
}

export default Install
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { doc, onSnapshot, collection } from "firebase/firestore";
import { dataBase } from "../../firebase/database";
import moment from "moment";
import style from "./style.module.scss";

import Logo from "../../images/LogoCORPBRASIL.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PrintVisit = () => {
  const [visit, setVisit] = useState([]);
  const [visitTec, setVisitTec] = useState([]);
  const [checkStep, setCheckStep] = useState({
    step3: false,
    step5: false,
    step6: false,
    step7: false,
  });
  const [info, setInfo] = useState();
  const [quadro, setQuadro] = useState();
  const [amperimetro, setAmperimetro] = useState();
  const [bengala, setBengala] = useState();
  const [disjuntor, setDisjuntor] = useState();
  const [fachada, setFachada] = useState();
  const [padrao, setPadrao] = useState();
  const [poste, setPoste] = useState();
  const [relogio, setRelogio] = useState();
  const [photosStep2, setPhotosStep2] = useState();
  const [photosStep4, setPhotosStep4] = useState();
  const [kit, setKit] = useState();
  // const [OpenInstallStep, setOpenInstallStep] = useState('0');
  let { idVisit } = useParams();
  const visitCollectionRef = collection(
    dataBase,
    "Visitas",
    idVisit,
    "Tecnica"
  );

  useEffect(() => {
    const getVisit = async () => {
      onSnapshot(doc(dataBase, "Visitas", idVisit), (doc) => {
        setVisit(doc.data());
      });
      onSnapshot(await visitCollectionRef, (visit) => {
        // Atualiza os dados em tempo real
        setVisitTec(visit.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // puxa a coleção 'Chats' para o state
      });
    };
    getVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if(visit) {
  //     document.title = visit.cliente ? visit.cliente + ' - Visita Comercial + Técnica - CORPBRASIL Energia Solar' : 'Visita Comercial + Técnica - CORPBRASIL Energia Solar';
  //   }
  // },[visit, visitTec])

  useEffect(() => {
    if (visitTec) {
      const step1 = visitTec.find((etapa) => etapa.etapa === 1);
      const step2 = visitTec.find((etapa) => etapa.etapa === 2);
      const step3 = visitTec.find((etapa) => etapa.etapa === 3);
      const step4 = visitTec.find((etapa) => etapa.etapa === 4);
      let photos = [];
      const photosFull2 =
        step2 && photos.concat(step2.telhadoPerto, step2.telhadoLonge);
      const videosFull2 = step2?.telhadoVideo;
      let FilesStep2 = [];
      if (step2) {
        for (let i = 0; i < photosFull2?.length; i++) {
          FilesStep2.push({ type: "photo", url: photosFull2[i] });
        }
        for (let i = 0; i < videosFull2?.length; i++) {
          FilesStep2.push({ type: "video", url: videosFull2[i] });
        }
      }
      const photosFull4 =
        step4 &&
        photos.concat(
          step4.telhadoMedida,
          step4.telhadoPlaca,
          step4.telhadoIrradiacao,
        );
      setFachada(step1 && step1.fachada);
      setPoste(step1 && step1.poste);
      setBengala(step1 && step1.bengala);
      setPadrao(step1 && step1.padrao);
      setRelogio(step1 && step1.relogio);
      setDisjuntor(step1 && step1.disjuntor);
      setAmperimetro(step1 && step1.amperimetro);
      setQuadro(step1 && step1.quadro);
      setPhotosStep2(FilesStep2);
      setPhotosStep4(photosFull4);
      setKit(step4 && step4.kit);
      setInfo({
        gps_padrao: step1 && step1.GPS_padrao,
        telhado: step3 && step3.telhado,
        anotacao: step4 && step4.anotacao,
        anotacao_padrao: step1 && step1.anotacao,
        altura_telhado: step3 && step3.altura_telhado,
        condominio: step4 && step4.condominio,
        comissionamento: step4 && step4.comissionamento,
        horario: step4 && step4.horario
      });
      setCheckStep({
        step1: step1 ? true : false,
        step3: step3 ? true : false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitTec, visit]);


  return (
    <div className={style.pdf_main}>
      <HelmetProvider>
        <Helmet>
          <title>
            {`Cliente ${visit?.cliente || ''} | CORPBRASIL Energia Renovável`}</title>
          <meta name="title" content={`Cliente ${visit?.cliente || ''} | CORPBRASIL Energia Renovável`} />
          <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
        </Helmet>
      </HelmetProvider>
    <div id="PDF" className={style.pdf}>
      <div className={style.pdf_header}>
        <Link to={'/'}>
          <img className={style.pdf_logo} src={Logo} alt="CORPBRASIL" />
        </Link>
        <h1>Visita Comercial + Técnica</h1>
        <h2>{visit.cliente}</h2>
      </div>
      <div className={style.pdf_item_container}>
        <h2>Informações</h2>
        <ul className={style.pdf_text}>
          <li>
            <b>Data da Visita:</b> {moment(visit.data).format("DD/MM/YYYY")}
          </li>
          <li>
            <b>Cidade:</b> {visit.cidade ? visit.cidade : 'Carregando'}
          </li>
          <li>
            <b>Consultora:</b> {visit.consultora ? visit.consultora : 'Carregando'}
          </li>
          <li>
            <b>Técnico:</b> {visit.tecnico ? visit.tecnico : 'Carregando'}
          </li>
          {/* <li>
            <p><b>Endereço do Cliente:</b> {visit && visit.endereco} {checkStep.step1 ? <a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${info && info.gps_padrao.lat},${info && info.gps_padrao.lng}`} >Clique aqui</a> : 'Aguardando o Técnico'} </p>
          </li> */}
          <li>
            <p>
              <b>Mora em Condomínio? </b> {visit && visit.condominio ? visit.condominio : 'Não Informado'} 
            </p>
            {visit && visit.condominio === 'Sim ✅' && checkStep.step4 &&
              <p><b>Horário:</b> { info.horario ? info.horario.entrada : 'Não Informado'} - {info.horario ? info.horario.saida : 'Não Informado'}</p>         
            }
          </li>
          <li>
            <p><b>Comissionamento será via Wi-fi?</b> {info && info.comissionamento ? info.comissionamento : 'Não Informado'}</p>
          </li>
        </ul>
      </div>
      <div className={style.pdf_item_container}>
        <h2>Detalhes da Rede de Energia</h2>
        <ul className={style.pdf_text}>
          <li>
            <b>Concessionária de Energia:</b>{" "}
            {visit.concessionaria ? visit.concessionaria : "Não Informado"}
          </li>
          <li>
            <b>Medida da Bitola do Ramal de Entrada:</b>{" "}
            {visit.medida_bitola_ramal
              ? visit.medida_bitola_ramal
              : "Não informado pelo Técnico"}
          </li>
          <li>
            <b>Medida da Bitola da Rede do Cliente:</b>{" "}
            {visit.medida_bitola_cliente
              ? visit.medida_bitola_cliente
              : "Não informado pelo Técnico"}
          </li>
          <li>
            <b>Possui DPS?</b>{" "}
            {visit.dps ? visit.dps : "Não informado pelo Técnico"}
          </li>
          <li>
            <b>A injeção de Energia CA obrigatoriamente deverá ser no Disjuntor geral? </b>
            {visit.injecao_energia_ca ? visit.injecao_energia_ca : "Não informado pelo Técnico"}
          </li>
          <li>
            <b>Precisa trocar o Disjuntor? </b>
            {visit.trocar_disjuntor ? visit.trocar_disjuntor : "Não informado pelo Técnico"}
          </li>
        </ul>
      </div>
      <div className={style.pdf_item_container}>
        <h2>Telhado</h2>
          {checkStep.step3 &&
            <ul className={style.pdf_text}>
              <li>
                <b>Altura da Casa:</b> {info.altura_telhado ? info.altura_telhado : 'Não Informado'}
              </li>
            {info.telhado.map((data, index) => (
                <><li>
                <b>Tipo:</b> {data.tipo}
              </li><li>
                  <b>Medida:</b> {data.medida}
                </li></>
            ))}
            </ul>}
      </div>
      <h1>Fotos</h1>
      <div className={style.pdf_item_container}>
        <h2>Entrada</h2>
        {/* <div className={style.report}>
          <ThemeProvider theme={theme}>
            <Button variant="contained" startIcon={<ArticleIcon />}>Gerar Relátorio</Button>
          </ThemeProvider>
        </div> */}
        <div className={style.photo_content} style={{ padding: '2rem 0 1.5rem 0' }}>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Fachada</h2>
            </div>
              <div className={style.photos}>
                {fachada &&
                  fachada.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Poste</h2>
            </div>
              <div className={style.photos}>
                {poste &&
                  poste.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Bengala e Entrada do Ramal de Energia</h2>
            </div>
              <div className={style.photos}>
                {bengala &&
                  bengala.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Caixa do Padrão</h2>
            </div>
              <div className={style.photos}>
                {padrao &&
                  padrao.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Relógio</h2>
            </div>
              <div className={style.photos}>
                {relogio &&
                  relogio.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Disjuntor</h2>
            </div>
              <div className={style.photos}>
                {disjuntor &&
                  disjuntor.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Amperímetro</h2>
            </div>
              <div className={style.photos}>
                {amperimetro &&
                  amperimetro.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
          <div className={style.photo_container}>
            <div className={style.photo_title}>
              <h2>Quadro de Distribuição</h2>
            </div>
              <div className={style.photos}>
                {quadro &&
                  quadro.map((photo, index) => (
                    <div className={style.img_pdf}>
                      <img
                        key={index}
                        rel="preconnect"
                        src={photo}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
          </div>
        </div>
        <h2>Telhado</h2>
        <div className={style.photo_content}>
          {photosStep2 &&
            photosStep2.map((photo, index) => (
              <>
                {photo.type === "photo" ? (
                  <div className={style.img_pdf}>
                    <img
                      rel="preconnect"
                      src={`${photo.url}`}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className={`${style.img_pdf} ${style.print}`}>
                    <video
                      width="100%"
                      controls
                      muted
                      playsInline
                    >
                      <source src={photo.url} type="video/mp4" />
                    </video>
                  </div>
                )}
              </>
            ))}
        </div>
        <h2>Projeto</h2>
        <div className={style.photo_content}>
          {photosStep4 ? (
            photosStep4.map((photo, index) => (
              <div className={style.img_pdf}>
                <img
                  key={index}
                  rel="preconnect"
                  src={photo}
                  alt=""
                />
              </div>
            ))
          ) : (
            <p className={style.pdf_alert}>Ainda não foi criado</p>
          )}
        </div>
        <h2>Observação sobre o Telhado</h2>
        <div className={style.obs_container}>
          {info && info.observacao_telhado ? (
            <p className={style.pdf_paragraph}>{info.observacao_telhado}</p>
          ) : (
            <p className={style.pdf_alert}>Nenhuma</p>
          )}
        </div>
        <h2>Kit</h2>
        <div className={style.photo_content}>
          {kit ? (
            kit.map((photo, index) => (
              <div className={style.img_pdf}>
                <img
                  key={index}
                  rel="preconnect"
                  src={photo}
                  alt=""
                />
              </div>
            ))
          ) : (
            <p className={style.pdf_alert}>Ainda não foi criado</p>
          )}
        </div>
        <div className={style.pdf_item_container}>
          <h2>Informações</h2>
          <ul className={style.pdf_text}>
            <li>
            <b>Potência do Inversor: </b>
            {visit.kit_info ? visit.kit_info?.inversor + ' kWp' : 'Aguardando o Pós-Venda'}
          </li>
          <li>
            <b>Quantidade de Placas:</b>
             <p>{visit.kit_info ? visit.kit_info?.placa_quantidade : 'Aguardando o Pós-Venda'} </p>
          </li>
          <li>
            <b>Potência das Placas</b>
            <p>{visit.kit_info ? visit.kit_info?.placa_potencia + ' W' : 'Aguardando o Pós-Venda'} </p>
          </li>
          <li>
            <b>Tipo de Estrutura:</b>
            <p> {visit.kit_info?.estrutura ?? 'Aguardando o Pós-Venda'} </p>
          </li>
          </ul>
        </div> 
        <h2>Observação sobre o Kit</h2>
        <div className={style.obs_container}>
          {info && info.observacao_kit ? (
            <p className={style.pdf_paragraph}>{info.observacao_kit}</p>
          ) : (
            <p className={style.pdf_alert}>Nenhuma</p>
          )}
        </div>
        <h2>Observação sobre a Instalação</h2>
        <div className={style.obs_container}>
          {visit && visit.observacao_instalacao ? (
            <p className={style.pdf_paragraph}>{visit && visit.observacao_instalacao}</p>
          ) : (
            <p className={style.pdf_alert}>Nenhuma</p>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default PrintVisit;

import { useState, memo, useEffect } from "react";
import { dataBase } from "../../firebase/database";
// import Header from "../../components/Header/Index";
import {updateDoc, doc, deleteDoc} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// Css
// import "cooltipz-css";
import styles from "./style.module.scss";
// import '../../styles/_filter.scss';

import { ReactComponent as ReportIcon } from '../../images/icons/Report.svg';

import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Collapse from '@mui/material/Collapse';
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../data/theme";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteIcon from '@mui/icons-material/Delete';

import moment from "moment";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Report = ({ user, userRef, alerts, reports}) => {
  const storage = getStorage();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [reportRef, setReportRef] = useState({
    comercial: [],
    cliente: []
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [TabsValue, setTabsValue] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleToggle = async (id) => {
    if(reports.length - userRef.relatorio !== 0) {
      await updateDoc(doc(dataBase,"Membros", userRef.id), {
        relatorio: (reports.length - userRef.relatorio) + userRef.relatorio 
      })
    }
    setOpen((prevState) => ({[id]: !prevState[id] }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if(reports) {
      const cliente = reports.filter((data) => data.info);
      const comercial = reports.filter((data) => data.setor === 'Comercial')

      setReportRef({...reportRef, 
        cliente: cliente,
        comercial: userRef && userRef.nome !== 'Pós-Venda' && comercial
      });
      console.log(reports)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reports])

  console.log(reportRef)

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} alignItems={'center'}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const deleteReport = (data) => {
    try {
      Swal.fire({
        title: 'Relatório',
        html: `Tem certeza que deseja excluir o <b>Relatório</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          console.log(data)

          const imgsRef = [];
          data.geracao.map((item) => {
            return imgsRef.push(item.img.ref)
          })
          data.box.map((item) => {
            return imgsRef.push(...item.arquivo.ref)
          })
          
          imgsRef.push(data.sistema.img.ref)

          data.consumo.map((item) => {
            return imgsRef.push(item.img.ref)
          })

          imgsRef.push(...data.beneficiaria.flatMap(item => item.dados.map(data => data.img.ref)));

          console.log(imgsRef)
          
          const resultDelete = imgsRef.map(async (item) => {
           return await deleteObject(ref(storage, item)).then(() => {
              console.log('Deletado com sucesso')
            }).catch((error) => {
              console.log('ERRO: ', error)
            })
          })

          if(resultDelete) {
            await deleteDoc(doc(dataBase, 'Relatorio', data.id)).then(() => {
              Swal.fire({
                title: 'Relatório',
                html: `O Relatório foi excluido com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#111",
                confirmButtonText: "Ok",
                })
            })
          }


        }
      })
    } catch (error) {
      console.log('[ERRO]: ', error)
    }
  }

  return (
    <div className={styles.container_panel}>
      {/* <Header user={user} userRef={userRef} alerts={alerts}></Header> */}
      <div className={styles.title_panel}>
        <ReportIcon className={styles.prospecction_icon}/>
        <h2>Relatório</h2>
      </div>
      <div className={styles.box_panel_add}>
      {userRef && userRef.nome_ref  && 
      <Link to='/relatorio/criar'>
        <button>
          <PostAddIcon className={styles.icon_user} /> 
          <p>Criar Relátorio</p>
        </button>
       </Link>
      }
      </div>
      <ThemeProvider theme={theme}>
          <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Setores" centered>
              <Tab sx={{ textTransform: 'capitalize' }} label="Pós-Venda" {...a11yProps(1)} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Comercial" {...a11yProps(2)} />
          </Tabs>
        </ThemeProvider>
      <div className={styles.content_panel}>
        <div className={styles.box_panel}>
          <CustomTabPanel value={TabsValue} index={0}>
            <div className={styles.box_activity}>
              <TableContainer component={Paper}>
                <Table stickyHeader className="table" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Data de Criação</TableCell>
                      <TableCell align="center">Data do chamado</TableCell>
                      <TableCell align="center">Setor</TableCell>
                      <TableCell align="center">Cliente</TableCell>
                      <TableCell align="center">Cidade</TableCell>
                      <TableCell align="center">Responsável</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  {reportRef.cliente ? 
                    reportRef.cliente.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                  <TableBody key={index}>
                    <TableRow
                      hover
                      className={`list-visit`}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell align="center">
                        <Link to={`/relatorio/${data.id}/pdf`} target="_blank"><IconButton
                          aria-label="Visualizar"
                          data-cooltipz-dir="right"
                          size="small">
                          <VisibilityIcon />
                        </IconButton></Link>
                        </TableCell>
                      <TableCell align="center">{data.data.replace('/', 'de').replace('/','de').replace('-', 'às')}</TableCell>
                      <TableCell align="center">{moment(data.info.data_chamado).format('DD/MM/YYYY')}</TableCell>
                      <TableCell align="center">{data.setor}</TableCell>
                      <TableCell align="center">{data.info.cliente}</TableCell>
                      <TableCell align="center">{data.info.cidade}</TableCell>
                      <TableCell align="center">{data.info.analista}</TableCell>
                      <TableCell align="center">
                      <IconButton
                          aria-label="Excluir"
                          color="error"
                          data-cooltipz-dir="left"
                          size="small"
                          onClick={() => deleteReport(data)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )) : 
                  <TableBody>
                    <TableRow 
                      className={`list-visit`}>
                      <TableCell>
                        Nenhum Relatorio
                      </TableCell>
                    </TableRow>
                  </TableBody>}
                </Table>
                <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                labelRowsPerPage="Relatório por página"
                component="div"
                count={reports ? reports.length : 0}
                page={!reports || reports.length <= 0 ? 0 : page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={TabsValue} index={1}>
            <div className={styles.box_activity}>
              <TableContainer className={styles.table_center} component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Data de Criação</TableCell>
                      <TableCell align="center">Setor</TableCell>
                      <TableCell align="center">Titulo</TableCell>
                      <TableCell align="center">Consultora</TableCell>
                      <TableCell align="center">Responsável</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  {reportRef.comercial && reportRef.comercial.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                  <TableBody key={index}>
                    <TableRow
                      hover
                      className={`list-visit`}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell align="center">{data.data.replace('/', 'de').replace('/','de').replace('-', 'às')}</TableCell>
                      <TableCell align="center">{data.setor}</TableCell>
                      <TableCell align="center">Relátorio {data.consultora} - {data.data_inicio} - {data.data_final}</TableCell>
                      <TableCell align="center">{data.consultora}</TableCell>
                      <TableCell align="center">{data.responsavel}</TableCell>
                      <TableCell align="center" sx={{ width: '50px' }}>
                        <IconButton
                          aria-label="Expandir"
                          data-cooltipz-dir="left"
                          size="small"
                          onClick={() => handleToggle(data.id)}>
                          {open[data.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow key={data.id}>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0, height: 0 }} colSpan={6}>
                          <Collapse in={open[data.id]} timeout="auto" unmountOnExit colSpan={6}>
                          <Box className={styles.info_anotacao} margin={3}>
                          <div className={styles.anotacao}>{data.anotacao}
                          <h4 className={styles.anotacao_title}>Relátorio Comercial - {data && data.data_inicio} até {data && data.data_final}</h4>
                            <div className={styles.anotacao_item}>
                            <h4>Visitas</h4>
                              <ul>
                                <li>
                                Foram agendadas <b>{data && data.visitas} visitas</b> ao longo do período.
                              </li>
                                <li>
                                Destas, <b>{data && data.visitas_confirmada} visitas</b> foram confirmadas e <b>{data && data.visitas_naoConfirmada} visitas</b> não foram confirmadas.
                              </li>
                                <li>
                                A meta de visitas para o período era de <b>{data && data.visitas_meta}</b>, e você antigiu <b>{data && data.visitas_metaR}%</b>.
                              </li>
                              </ul>
                            </div>
                            <div className={styles.anotacao_item}>
                            <h4>Vendas</h4>
                              <ul>
                              {data && data.vendas ? 
                                <li>
                                Foi registrado <b>{data && data.vendas} venda(s)</b>, resultando em <b>{data && data.vendas_metaR}%</b> da meta de vendas alcançada.
                                </li> :
                                <li>
                                Não foram registradas vendas, resultando em <b>0%</b> da meta de vendas alcançada.
                                </li> 
                                }
                                <li>
                                A meta de vendas para o período era de <b>{data && data.vendas_meta}</b>.
                              </li>
                              </ul>
                            </div>
                            <div className={styles.anotacao_item}>
                            <h4>Leads</h4>
                              <ul>
                                <li>
                                Foram gerados <b>{data && data.leads} leads</b> durante o período.
                              </li>
                                <li>
                                <b>{data && data.leadsSheet_robo}</b> vieram do Robô, <b>{data && data.leadsSheet_meetime}</b> da Meetime e <b>{data && data.leadsSheet_ganho}</b> de Prospecção.
                              </li>
                              </ul>
                            </div>
                            <div className={styles.anotacao_item}>
                            <h4>Prospecção</h4>
                              <ul>
                                <li>
                                Você possui <b>{data && data.prospeccao ? data.prospeccao : 0} leads ativos</b>.
                              </li>
                                <li>
                                Durante o periodo, <b>{data && data.prospeccao_ganho} novo(s) lead(s)</b> foi adquirido, enquanto <b>{data && data.prospeccao_perdido} leads</b> foram perdidos.
                              </li>
                              </ul>
                            </div>
                            <div className={styles.anotacao_item}>
                            <h4>Atividades Realizadas</h4>
                              <ul>
                                <li>
                                Um total de <b>{data && data.atividades}</b> atividades foram realizadas.
                              </li>
                                <li>
                                A distribuição das atividades foi a seguinte:
                                <ul>
                                  <li>Email: <b>{data && data.atividades_email}</b> atividades</li>
                                  <li>Ligação: <b>{data && data.atividades_ligacao}</b> atividades</li>
                                  <li>WhatsApp: <b>{data && data.atividades_whats}</b> atividade</li>
                                </ul>
                              </li>
                              <li>Você alcançou <b>{data && data.atividades_metaR}%</b> da meta de atividades, com uma meta estabelecida de <b>{data && data.atividades_meta} atividades.</b></li>
                              </ul>
                            </div>
                            <div className={styles.anotacao_item}>
                            <h4>Feedback</h4>
                            <p>{data && data.feedback}</p>
                            </div>
                          </div>
                          </Box>
                        </Collapse>
                    </TableCell>
                  </TableRow>
                </TableBody>
                  ))}
                </Table>
                <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                labelRowsPerPage="Relatório por página"
                component="div"
                count={reportRef.comercial ? reportRef.comercial.length : 0}
                page={!reportRef.comercial || reportRef.comercial.length <= 0 ? 0 : page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

export default memo(Report);

export const formatPhoneBR = (numero) => {
    // Remove todos os caracteres não numéricos
    const cleaned = ('' + numero).replace(/\D/g, '');

    // Remove o código do país "55" se estiver presente
    const semCodigoPais = cleaned.startsWith('55') ? cleaned.slice(2) : cleaned;
  
    // Verifica se é um número móvel (11 dígitos) ou fixo (10 dígitos)
    const isMobile = semCodigoPais.length === 11;
  
    // Divide a string em partes
    const codigoArea = semCodigoPais.slice(0, 2);
    const parte1 = semCodigoPais.slice(2, isMobile ? 7 : 6);
    const parte2 = semCodigoPais.slice(isMobile ? 7 : 6);
  
    // Concatena as partes no formato desejado
    return isMobile 
      ? `(${codigoArea}) 9${parte1}-${parte2}` 
      : `(${codigoArea}) ${parte1}-${parte2}`;
  }
import Dialog from "@mui/material/Dialog";
import SlickSlider from "react-slick";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';

import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

import { ThemeProvider, styled, useTheme } from '@mui/material/styles';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import styles from '../../../styles/Box/style.module.scss';
import { theme2 } from "../../../data/theme";
import Conversa from '../../../images/comission/Conversa.jpg';
import { useEffect, useRef, useState } from "react";
import useUploadPhotos from "../../../hooks/useUploadPhotos";
import Swal from "sweetalert2";
import { dataBase } from "../../../firebase/database";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import axios from "axios";
import useCircular from "../../../hooks/useCircular";
import moment from "moment";

const Agreed = ({ userRef, comissionData, open, close }) => {
    const { uploadImages, changeProgress } = useUploadPhotos();
    const theme = useTheme();
    const slider = useRef(null);
    const { CircularProgressWithLabel  } = useCircular();
    const [viewDrop, setViewDrop] = useState(false);
    const [step, setStep] = useState(0);
    const [checkPhotos, setCheckPhotos] = useState(false);
    const [comission, setComission] = useState([]);
    const [percentage, setPercentage] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const percentages = [
        {
            value: 7,
            label: '7%'
        },
        {
            value: 6.5,
            label: '6,5%'
        },
        {
            value: 6,
            label: '6%'
        },
        {
            value: 5.5,
            label: '5,5%'
        },
        {
            value: 5,
            label: '5%'
        },
        {
            value: 4.5,
            label: '4,5%'
        },
        {
            value: 4,
            label: '4%'
        },
        {
            value: 3.5,
            label: '3,5%'
        },
        {
            value: 3,
            label: '3%'
        },
        {
            value: 2.5,
            label: '2,5%'
        },
        {
            value: 2,
            label: '2%'
        },
    ]
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })
    const settingsPhotos = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
    };

const closeAll = () => {
    setComission([]);
    setPercentage('');
    setStep(0);
    close();
}

useEffect(() => {
    if(comissionData) {
        setPercentage(comissionData.comissao?.porcentagem);
    }
},[comissionData])

const sendPhoto = async (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if(type === 'comissão') {
      if(comission) {
        setComission([...comission, ...files])
      } else {
        setComission(files);
      }
    } 
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);

     switch (type) {
      case 'comissão':
        setComission(newPhotos);
        break;
        default:
     }
    }
  }

  const onSubmit = async(e) => {
    try {
        e.preventDefault();
        console.log(comissionData)
        if(comission.length === 0) {
            return Swal.fire({
                title: 'Atenção',
                html: `Envie a <b>Foto da Conversa</b> para concluir o envio da Comissão Acordada.`,
                icon: "warning",
                showCloseButton: true,
                confirmButtonColor: "#111",
                confirmButtonText: "Fechar",
              })
        } else {
            // await enviarSolicitacaoPost();
            Swal.fire({
                title: 'Comissão Acordada',
                html: `Todos os dados estão <b>corretos</b>?`,
                icon: "question",
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                confirmButtonText: "Sim",
                denyButtonText: `Não`,
              }).then(async (result) => {
                if(result.isConfirmed) {
                    setStep(1);

                    const resultComission = await uploadImages(comission, 'Print_Comissao', 0, comissionData.id, 'Comissao');
                    const photos = resultComission.map(obj => obj.downloadURL);
                    const photosRef = resultComission.map(obj => obj.fullPath);
                    setCheckPhotos(true);
                    closeAll();
                    setViewDrop(true);
                    await updateDoc(doc(dataBase, 'Comissoes', comissionData.id), {
                        comissao_acordada: {
                            url: photos,
                            ref: photosRef,
                            porcentagem: percentage,
                            status : 'Aguardando',
                            data: moment().format('DD/MM/YYYY HH:mm'),
                        },
                        historico: Array.isArray(comissionData.historico) ? [
                            ...comissionData.historico,
                            {
                                data: moment().format('DD/MM/YYYY HH:mm'),
                                text: `A Foto da Comissão Acordada foi enviada pela Assistente de Vendas <b>${userRef && userRef.nome}</b>.`
                            }
                        ] : [
                            {
                                data: moment().format('DD/MM/YYYY HH:mm'),
                                text: `A Foto da Comissão Acordada foi enviada pela Assistente de Vendas <b>${userRef && userRef.nome}</b>.`
                            }
                        ]
                    })
                    
                    await enviarSolicitacaoPost();
                    setViewDrop(false);
                    
                    Swal.fire({
                        title: 'Comissão Acordada',
                        html: `A <b>Comissão Acordada</b> foi concluida com sucesso.`,
                        icon: "success",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonColor: "#0eb05f",
                    })
                }
            })
        }
    } catch (error) {
        console.log('erro');
    }
  }

  const enviarSolicitacaoPost = async () => {
    const representanteDoc = await getDoc(doc(dataBase, 'Membros', comissionData.representante?.id));
    const backofficeDoc = await getDoc(doc(dataBase, 'Membros', comissionData.backoffice_id));
    if(representanteDoc.exists() && backofficeDoc.exists()) {
        const representanteRef = representanteDoc.data();
        const backofficeRef = backofficeDoc.data();
        await axios.post('https://n8n.corpbrasil.cloud/webhook/a1b14270-f55d-4a96-bbb5-7d8a6f328809', {
            telefone: representanteRef.telefone,
            nome: representanteRef.nome,
            cidade: representanteRef.cidade,
            porcentagem: percentage,
            codigo: representanteRef.id_representante,
            representante: backofficeRef.nome,
            id: comissionData.id,
            cliente: comissionData.nome,
            motivo: '@NM'
        })
  }
}

  return (
    <div>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewDrop}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Dialog
            className={styles.dialog}
            open={open}
            fullScreen={fullScreen}
            maxWidth="lg"
            sx={{ zIndex: 90 }}
            // onClose={() => closeAll()}
        >
            <IconButton
                aria-label="close"
                onClick={() => closeAll()}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            ><CloseIcon /></IconButton>
          <DialogTitle align="center">Comissão Acordada</DialogTitle>
          <ThemeProvider theme={theme2}>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    {step === 0 ? 
                        <div className={styles.register_container}>
                        <div className={styles.photo_item}>
                        {comission && comission.length === 0 ? 
                            <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                                <b>Passo 1:</b> Envie uma print a respeito da conversa sobre a <b>Comissão Acordada.</b><br />
                                Exemplo:
                                </DialogContentText>
                                <img className={styles.img_visit} src={Conversa} alt='' /></> : 
                            <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                                <b>Passo 1:</b> Envie uma print a respeito da conversa sobre a <b>Comissão Acordada.</b>
                                </DialogContentText><div className={styles.slider_container}>
                                    <SlickSlider ref={slider} {...settingsPhotos} className={styles.slider}>
                                    {comission && comission.map((img, index) => (
                                        <div key={index} className={styles.photos}>
                                        <img rel="preconnect" src={img.file} alt="" />
                                        <p>{img.complete.name}</p>
                                        <Button 
                                        disableElevation 
                                        startIcon={<DeleteForeverIcon />}
                                        variant='contained' 
                                        color='error' 
                                        sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                                        onClick={() => deletePhoto(index, comission, 'comissão')}>Excluir</Button>
                                        </div>
                                    ))}
                                    </SlickSlider>
                            </div></>
                            }
                            <div className={styles.buttons_photo}>
                            <Button 
                                disableElevation
                                sx={{ textTransform: 'capitalize' }}
                                component="label" 
                                variant="contained" 
                                onChange={(e) => sendPhoto(e, 'comissão')} 
                                startIcon={<AddToPhotosIcon />}>
                                Enviar Foto
                                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
                            </Button>
                        </div>
                        </div>
                        {/* <DialogContentText sx={{ textAlign: "center", margin: '1rem 0' }}>
                            <b>Passo 2:</b> Escolha a porcentagem acordada com o Representante.
                        </DialogContentText> */}
                        <div className={styles.input}>
                        <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
                            <InputLabel id="porcentagem">Porcentagem</InputLabel>
                            <Select
                            labelId='porcentagem'
                            label="Porcentagem"
                            value={percentage}
                            disabled
                            onChange={(e) => setPercentage(e.target.value)}
                            required>
                                {percentages.map((data, index) =>(
                                    <MenuItem key={index} value={data.value}>{data.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>  
                        </div>  
                        </div> : null
                    }
                    {step === 1 ? 
                        <div className={styles.view_send}>
                        <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                        <h3>Enviando dados, aguarde...</h3>
                        <ul>
                          <li>
                          {checkPhotos ? 
                            <CheckIcon color='success' /> :
                            <CircularProgressWithLabel value={changeProgress[0]} />
                          }
                           Fotos da Comissão Acordada</li>
                        </ul>
                      </DialogContentText>
                        </div> : null
                    }
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
                    <Button 
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    variant='contained' 
                    onClick={() => closeAll()}>Fechar</Button>
                    <Button
                    disableElevation
                    sx={{ textTransform: 'capitalize' }} 
                    variant='contained' 
                    color='success'
                    type="submit"
                    disabled={step > 0} 
                    startIcon={<SendIcon />}>Enviar</Button>
                </DialogActions>
            </form>
          </ThemeProvider>
        </Dialog>
    </div>
  )
}

export default Agreed
import { Alert, AlertTitle, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { HiOutlineBanknotes } from "react-icons/hi2";
import { LuCircleDashed } from "react-icons/lu";
import { MdPayments } from "react-icons/md";

import { theme } from '../../../data/theme'
import Loading from '../../../components/Loading';
import styles from './styles.module.scss';

import NewTooltip from '../../../components/Tooltip';
import Swal from 'sweetalert2';
import { toast } from '../../../components/Toast';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import { StyledMenu } from '../../../components/StyledMenu';
import { capitalizeString } from '../../../functions/capitalizeString';
import AddCircle from '@mui/icons-material/AddCircle';

const Payment = ({ customer, userRef }) => {
    const [view, setView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState([]);
    const [methodsChange, setMethodsChange] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const userPermission = userRef?.cargo === 'Administrador' || userRef?.id === customer.consultora_uid || userRef.id === customer.assistente;
    const optionsMethod = [
        { nome: 'Banco CorSolar' },
        { nome: 'Banco BV' },
        { nome: 'BTG Pactual' },
        { nome: 'Caixa Econômica Federal' },
        { nome: 'Cartão de Crédito' },
        { nome: 'EOS' },
        { nome: 'FGTS' },
        { nome: 'Losango' },
        { nome: 'Santander' },
        { nome: 'Sicred' },
        { nome: 'Sicoob' },
        { nome: 'Sol Agora' },
        { nome: 'Solfacil' },
        { nome: 'SolPlace' },
    ];

    const optionsMethodSituation = [
        { nome: 'Aprovado' },
        { nome: 'Negado' },
        { nome: 'Aguardando' },
    ];

    const chipProps = {
        Aprovado: {
            label: 'Aprovado',
            sx: { backgroundColor: "#e9fbf0", fontWeight: '500' },
            icon: <ThumbUpRoundedIcon sx={{ fontSize: '18px', paddingLeft: '0.2rem' }} />,
            color: 'success',
        },
        Negado: {
            label: 'Negado',
            sx: { backgroundColor: "#fbe9e9", fontWeight: '500' },
            icon: <ThumbDownRoundedIcon sx={{ fontSize: '18px', paddingLeft: '0.2rem' }} />,
            color: 'error',
        },
        Aguardando: {
            label: 'Aguardando',
            sx: { backgroundColor: "#f8f8f8", fontWeight: '500' },
            icon: <HourglassEmptyRoundedIcon sx={{ fontSize: '18px', paddingLeft: '0.2rem' }} />,
        },
    };

    const PaymentChip = ({ data, index, userRef, customer, handleChangePayment }) => {
        const [anchorEls, setAnchorEls] = useState({});

        const handleChipClick = (event, idx) => {
            setAnchorEls(prev => ({ ...prev, [idx]: event.currentTarget }));
        };

        const handleMenuClose = (idx) => {
            setAnchorEls(prev => ({ ...prev, [idx]: null }));
        };

        const open = Boolean(anchorEls[index]);

        return (
            <>
                <Chip
                    {...chipProps[data.situacao]}
                    variant='outlined'
                    size='small'
                    deleteIcon={<KeyboardArrowDownIcon />}
                    onDelete={(e) => handleChipClick(e, index)}
                    disabled={userRef?.uid !== customer.consultora_uid && userRef?.cargo !== 'Administrador' && userRef?.uid !== customer.assistente}
                    id={`chip-status-${index}`}
                    aria-controls={open ? `menu-status-${index}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                />
                <StyledMenu
                    MenuListProps={{ 'aria-labelledby': `chip-status-${index}` }}
                    id={`menu-status-${index}`}
                    anchorEl={anchorEls[index]}
                    open={open}
                    onClose={() => handleMenuClose(index)}
                >
                    <MenuItem
                        onClick={() => handleChangePayment(index, 'Aprovado')}
                        selected={data.situacao === 'Aprovado'}
                        disableRipple
                        disabled={data.situacao === 'Aprovado'}
                        sx={{ color: "#2e7d32" }}>
                        <ThumbUpRoundedIcon sx={{ fill: "#2e7d32" }} />Aprovado
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleChangePayment(index, 'Negado')}
                        selected={data.situacao === 'Negado'}
                        disableRipple
                        disabled={data.situacao === 'Negado'}
                        sx={{ color: "#d32f2f" }}>
                        <ThumbDownRoundedIcon sx={{ fill: "#d32f2f" }} />Negado
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                        onClick={() => handleChangePayment(index, 'Aguardando')}
                        selected={data.situacao === 'Aguardando'}
                        disabled={data.situacao === 'Aguardando'}
                        disableRipple>
                        <HourglassEmptyRoundedIcon />Aguardando
                    </MenuItem>
                </StyledMenu>
            </>
        );
    };


    useEffect(() => {
        if (customer) {
            setMethods(customer.pagamentos || []);
        }
    }, [customer])

    useEffect(() => {
        const findPropsValuelees = !!methodsChange.find(data => data.metodo.length === 0 || data.situacao.length === 0);
        setHasChanges(!!compareData() && !findPropsValuelees);
        console.log('teste')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methodsChange, methods]);

    const closeBox = () => {
        setView(false);
    }

    const openBox = () => {
        setMethodsChange(methods);
        setView(true);
    }

    const addNewPayment = () => {
        const newMethod = {
            metodo: '',
            situacao: '',
            ativo: false
        };

        setMethodsChange(currentMethods => [...(currentMethods ?? []), newMethod]);
    };

    const handleMethodChange = (array, index, field, value) => {
        const currentMethods = array;
        const newMethods = [...currentMethods];
        const newMethod = { ...newMethods[index], [field]: value };
        newMethods[index] = newMethod;
        return newMethods;
    };

    const removePayment = async (index) => {
        try {
            const result = await Swal.fire({
                title: 'Proposta',
                html: `Você deseja excluir o <b>Pagamento?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })
            if (result.isConfirmed) {
                const newMethods = methodsChange.filter((_, i) => i !== index);
                setMethodsChange(newMethods);
                console.log(!compareData())
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o pagamento.', error: error });
        }
    };

    const removePaymentPermanent = async (index) => {
        try {
            const result = await Swal.fire({
                title: 'Proposta',
                html: `Você deseja excluir o <b>Pagamento?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })
            if (result.isConfirmed) {
                const newMethods = methods.filter((_, i) => i !== index);
                const oldItem = methods[index];
                const resultFirebase = await sendFirebase(newMethods, `<b>[Removido]:</b> ${oldItem.metodo} - ${oldItem.situacao}, <b>Ativo:</b> ${oldItem.ativo ? 'Sim' : 'Não'}<br />`, 'excluir');

                if (resultFirebase) {
                    toast({ icon: 'success', text: 'O pagamento foi excluido com sucesso!' });
                    setMethods(newMethods);
                };
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o pagamento.', error: error });
        }
    };

    const savePayment = async () => {
        try {
            const changes = compareData();
            if (!changes) return;
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja salvar o <b>Pagamento?</b>`,
                icon: "warning",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })

            if (result.isConfirmed) {
                setLoading(true);
                await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                    pagamentos: methodsChange,
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Pagamento Alterado',
                        text: compareData(),
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                    }]
                });
                setLoading(false);
                await Swal.fire({
                    title: 'Pagamento',
                    html: `Os dados foram alterados com sucesso.`,
                    icon: "success",
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                    confirmButtonText: "Ok",
                })
                closeBox();
            }
        } catch (error) {
            setLoading(false);
            toast({ icon: 'error', text: 'Ocorreu um erro ao salvar o pagamento.', error: error });
        }
    }

    const compareData = () => {
        let changesList = [];
        const newData = methodsChange;
        const oldData = customer.pagamentos || [];
        const oldDataMap = new Map(oldData.map(item => [item.metodo, item]));
        const newDataMap = new Map(newData.map(item => [item.metodo, item]));

        newData.forEach((newItem) => {
            const oldItem = oldDataMap.get(newItem.metodo);
            if (!oldItem) {
                changesList.push(`<b>[Adicionado]:</b> ${newItem.metodo} - Não Informado > ${newItem.situacao}, Ativo: ${newItem.ativo ? 'Sim' : 'Não'}<br />`);
            } else {
                let change = '';
                if (newItem.situacao !== oldItem.situacao) {
                    change += `${oldItem.situacao} > ${newItem.situacao}`;
                }
                if (newItem.ativo !== oldItem.ativo) {
                    if (change) change += ', ';
                    change += `Ativo: ${oldItem.ativo ? 'Sim' : 'Não'} > ${newItem.ativo ? 'Sim' : 'Não'}`;
                }
                if (change) {
                    changesList.push(`<b>[Alterado]:</b> ${newItem.metodo} - ${change}<br />`);
                }
            }
        });

        oldData.forEach((oldItem) => {
            if (!newDataMap.has(oldItem.metodo)) {
                changesList.push(`<b>[Removido]:</b> ${oldItem.metodo} - ${oldItem.situacao}, Ativo: ${oldItem.ativo ? 'Sim' : 'Não'}<br />`);
            }
        });

        return changesList.length === 0 ? null : changesList.join(' ');
    };

    const handleChangePayment = async (index, type) => {

        const typeFormated = capitalizeString(type);
        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja alterar a situação para <b>${typeFormated}</b>?`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })

            if (result.isConfirmed) {
                const paymentsRef = customer.pagamentos || [];
                const newMethods = handleMethodChange(methods, index, 'situacao', type);
                const resultFirebase = await sendFirebase(newMethods, `<b>[Alterado]:</b> ${methods[index].metodo} - ${paymentsRef[index].situacao} > ${newMethods[index].situacao}`, 'alterar');

                if (resultFirebase) {
                    toast({ icon: 'success', text: 'O pagamento foi alterado com sucesso!' });
                };
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao alterar a situação do pagamento.', error: error });
        }
    }

    const ativePayment = async (index) => {
        let msg = methods[index].ativo ? 'desmarcar' : 'marcar';

        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja <b>${msg}</b> como pagamento principal?`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })
            if (result.isConfirmed) {
                let newMethods = [];
                if (methods[index].ativo) {
                    newMethods = setInativo(methods, index);
                } else {
                    newMethods = setAtivo(methods, index);
                }
                msg = methods[index].ativo ? 'desmarcado' : 'marcardo';

                const resultFirebase = await sendFirebase(newMethods, `<b>[Alterado]:</b> ${methods[index].metodo} - ${methods[index].situacao}, <b>Ativo:</b> Não > Sim`, msg);

                if (resultFirebase) {
                    toast({ icon: 'success', text: 'O pagamento foi ' + msg + ' com sucesso!' });
                    setMethods(newMethods);
                };

            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao ' + msg + ' o pagamento.', error: error });
        }
    }

    const sendFirebase = async (newMethods, text, msg) => {
        try {
            await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                pagamentos: newMethods,
                historico: [...customer.historico, {
                    data: moment().format('DD/MM/YYYY - HH:mm'),
                    evento: 'Pagamento Alterado',
                    text: text,
                    usuario_id: userRef?.id,
                    usuario_nome: userRef?.nome
                }]
            })
            return true;
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao ' + msg + ' o pagamento.', error: error });
            return false;
        }
    }

    const setAtivo = (methodsRef, indexAtivo) => {
        return methodsRef.map((method, index) => ({
            ...method,
            ativo: index === indexAtivo,
        }));
    };

    const setInativo = (methodsRef, indexAtivo) => {
        return methodsRef.map((method, index) => ({
            ...method,
            ativo: index === indexAtivo ? !method.ativo : method.ativo,
        }));
    };

    return (
        <>
            <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 1rem 0.5rem 1rem' }}>
                <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between', alignItems: 'center', color: "#515151" }}>
                    <MdPayments size='1.3rem' />
                    <h3>Pagamento</h3>
                </Box>
                <ThemeProvider theme={theme}>
                    <Button
                        disableElevation
                        sx={{ textTransform: 'capitalize' }}
                        variant='contained'
                        color='primary'
                        size='small'
                        disabled={!userPermission}
                        startIcon={<AddCircle />}
                        onClick={openBox}>
                        Novo Pagamento
                    </Button>
                </ThemeProvider>
            </Box>
            <TableContainer className={styles.table_payment} sx={{ margin: '0', width: 'auto', maxHeight: 150 }}>
                <Table stickyHeader size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align='center'>
                                <Box sx={{
                                    color: "#8F95A3",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '0.4rem'
                                }}>
                                    <HiOutlineBanknotes />Forma
                                </Box>
                            </TableCell>
                            <TableCell align='center'>
                                <Box sx={{
                                    color: "#8F95A3",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '0.4rem'
                                }}>
                                    <LuCircleDashed />Situação
                                </Box>
                            </TableCell>
                            {userPermission ?
                                <TableCell></TableCell> : null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {methods.length > 0 ? methods.map((data, index) => (
                            <TableRow key={index}>
                                <TableCell align='center' sx={{ padding: '0.2rem 0 0.2rem 0.5rem', borderBottom: '1px solid #ebebeb' }}>
                                    <NewTooltip title={data.ativo === true ? 'Desativar Pagamento' : 'Marcar Pagamento como Ativo'} placement={'top'}>
                                        <span>
                                            <IconButton
                                                onClick={() => ativePayment(index)}
                                                size='small'
                                                disabled={!userPermission}
                                            >
                                                {data.ativo ?
                                                    <StarIcon sx={{ fill: '#ffe44c' }} /> :
                                                    <StarBorderIcon sx={{ fill: '#ccc' }} />
                                                }
                                            </IconButton>
                                        </span>
                                    </NewTooltip>
                                </TableCell>
                                <TableCell align='center' sx={{ color: "#000", fontWeight: '500', borderBottom: '1px solid #ebebeb' }}>{data.metodo}</TableCell>
                                <TableCell align='center' sx={{ padding: '0.2rem 0.5rem 0.2rem 0', borderBottom: '1px solid #ebebeb' }}>
                                    <PaymentChip
                                        key={index}
                                        data={data}
                                        index={index}
                                        userRef={userRef}
                                        customer={customer}
                                        handleChangePayment={handleChangePayment}
                                    />
                                </TableCell>
                                {userPermission ?
                                    <TableCell align='center' sx={{ padding: '0.2rem 0.5rem 0.2rem 0', borderBottom: '1px solid #ebebeb' }}>
                                        <NewTooltip title='Excluir Pagamento' placement='top'>
                                            <IconButton size='small' onClick={() => removePaymentPermanent(index)} color='error'>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </NewTooltip>
                                    </TableCell> : null
                                }
                            </TableRow>)) :
                            <TableRow>
                                <TableCell colSpan={4} align='center' sx={{ padding: '1rem 0', color: "#ccc" }}>
                                    <Typography>Nenhum pagamento encontrado</Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                sx={{ overflow: 'hidden' }}
                open={view}
                fullWidth
                maxWidth="md">
                <Loading view={loading} backgroundColor='#ffffffa1' height='100%' />
                <ThemeProvider theme={theme}>
                    <IconButton
                        aria-label="close"
                        onClick={closeBox}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    ><CloseIcon /></IconButton>
                    <DialogTitle align='center'>Pagamento - Histórico</DialogTitle>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
                        <Alert>
                            <AlertTitle>Dados fornecido pelo Cliente</AlertTitle>
                            {customer.simulacao ? 
                                <><p>Tipo de Conta: <b>{customer?.simulacao?.tipo}</b></p>
                                <p>{customer?.simulacao?.tipo === 'Física' ? 'Nome' : 'Razão Social'}: <b>{customer?.simulacao?.nome}</b></p>
                                <p>{customer?.simulacao?.tipo === 'Física' ? 'CPF' : 'CNPJ'}: <b>{customer?.simulacao?.doc}</b></p>
                                <p>{customer?.simulacao?.tipo === 'Física' ? 'Data de Nascimento' : 'Data de Fundação'}: <b>{customer?.simulacao?.data}</b></p>
                                <p>CEP: <b>{customer?.simulacao?.cep}</b></p>
                                <p>Telefone: <b>{customer?.telefone}</b></p>
                                </>
                            :
                            <Typography textAlign='center' variant='p'>Nenhum dado fornecido</Typography> 
                            }
                        </Alert>
                        {methodsChange.length > 0 ?
                            methodsChange.map((data, index) => (
                                <Grid key={index} container spacing={1}>
                                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                        <NewTooltip title={data.ativo === true ? 'Desativar Pagamento' : 'Marcar Pagamento como Ativo'} placement={'top'}>
                                            <IconButton
                                                onClick={() => ativePayment(index)}
                                                sx={{ height: '40px' }}>
                                                {data.ativo ?
                                                    <StarIcon sx={{ fill: '#ffe44c' }} /> :
                                                    <StarBorderIcon sx={{ fill: '#ccc' }} />
                                                }
                                            </IconButton>
                                        </NewTooltip>
                                        <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
                                            <InputLabel id="Banco">Banco</InputLabel>
                                            <Select
                                                labelId='Banco'
                                                label="Banco"
                                                value={data.metodo}
                                                onChange={(e) => setMethodsChange(handleMethodChange(methodsChange, index, 'metodo', e.target.value))}
                                                required>
                                                {optionsMethod.map((option) => (
                                                    <MenuItem key={option.nome} value={option.nome}>{option.nome}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                    <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
                                            <InputLabel id="Banco">Conta</InputLabel>
                                            <Select
                                                labelId='Banco'
                                                label="Banco"
                                                value={data.metodo}
                                                onChange={(e) => setMethodsChange(handleMethodChange(methodsChange, index, 'metodo', e.target.value))}
                                                required>
                                                {optionsMethod.map((option) => (
                                                    <MenuItem key={option.nome} value={option.nome}>{option.nome}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>              
                                    </Grid> */}
                                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                        <FormControl sx={{ marginTop: '0.5rem' }} fullWidth>
                                            <InputLabel id="Situação">Situação</InputLabel>
                                            <Select
                                                labelId='Situação'
                                                label="Situação"
                                                value={data.situacao}
                                                onChange={(e) => setMethodsChange(handleMethodChange(methodsChange, index, 'situacao', e.target.value))}
                                                required>
                                                {optionsMethodSituation.map((option) => (
                                                    <MenuItem key={option.nome} value={option.nome}>{option.nome}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <NewTooltip title='Excluir Pagamento' placement='top'>
                                            <IconButton onClick={() => removePayment(index)} color='error'>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </NewTooltip>
                                    </Grid>
                                </Grid>
                            ))
                            :
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <Typography color='#a1a1a1' textAlign='center' variant='h5' margin='1rem 0'>Nenhum Método encontrado</Typography>
                                </Grid>
                            </Grid>
                        }
                        <Grid container spacing={2}>
                            <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                                <NewTooltip title='Adicionar Novo Método' placement='top'>
                                    <Button onClick={addNewPayment} sx={{ textTransform: 'capitalize' }} color='success' startIcon={<AddCircleRoundedIcon />}>
                                        Adicionar Pagamento
                                    </Button>
                                </NewTooltip>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                        <ThemeProvider theme={theme}>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                variant='contained'
                                disableElevation
                                disabled={!hasChanges}
                                onClick={savePayment}
                                color="success">
                                Salvar
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                disableElevation
                                onClick={closeBox}
                                variant='contained'
                                color="primary">
                                Fechar
                            </Button>
                        </ThemeProvider>
                    </DialogActions>
                </ThemeProvider>
            </Dialog>
        </>

    )
}

export default memo(Payment);
import { Box } from '@mui/material';
import React, { useState } from 'react'
import { createRoot } from 'react-dom/client';
import { Document, Page } from 'react-pdf';
import Swal from 'sweetalert2';

const useViewDocBox = () => {
    const [pageNumber, setPageNumber] = useState();

    const handleDoc = (doc) => {
        if (doc.complete.type === "application/pdf") {
          return true
        } else {
          return false
        }
      }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(numPages);
      }
    
    const viewDoc = (data) => {

        return Swal.fire({
          html: '<div id="swal-react-container"></div>',
          didOpen: () => {
            const container = document.getElementById('swal-react-container');
            const root = createRoot(container);
            root.render(
              handleDoc(data) ? (
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.7rem', alignItems: 'center' }}>
                  <Document file={data.file} onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                    <Page pageNumber={1} width={500} />
                  </Document>
                  <b>{pageNumber} Páginas</b>
                </Box>
              ) : (
                <img src={data.file} alt="Document" className='swal2-image' style={{ height: '500px' }} />
              ),
            );
          },
          imageHeight: 500,
          width: '90%',
          confirmButtonText: 'Fechar',
          confirmButtonColor: '#000',
          showCloseButton: true
        })
      }
  return { viewDoc }
}

export default useViewDocBox;
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // cria alertas personalizado
import { dataBase } from "../../../../firebase/database";
import { Company } from "../../../../data/Data";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { theme } from '../../../../data/theme';
import { ThemeProvider, DialogContentText, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import style from '../../../../styles/Box/style.module.scss'
import moment from "moment";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";

const RegisterInstall = ({visit, open, close }) => {
  // const { register, handleSubmit } = useForm();
  const [info, setInfo] = useState({
    data: moment().format("YYYY-MM-DD"),
    data_final: moment().format("YYYY-MM-DD"),
    horario: '',
    horario_final: '',
  });
const [price, setPrice] = useState('');

  useEffect(() => {
   if(open) {

    // setInfo({
    //   ...info,
    //   data: visit.instalador?.data ?? '2024-05-12',
    //   horario: visit.instalador?.horario ?? '',
    // })

    setPrice(visit.kit_info?.instalacao?? '');
   } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, visit])

  const closeBox = () => {
    close();
    setInfo({
      data: moment().format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
      horario: '',
      horario_final: '',
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(parseFloat(price));
      try {
        console.log(visit)
        console.log(info)
        Swal.fire({
          title: Company,
          text: `Você deseja confirmar a Data de Instalação?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const docInstall = await getDoc(doc(dataBase, 'Membros', visit.instalador.uid));
            const installRef = docInstall.data();

            await updateDoc(doc(dataBase, 'Visitas', visit.id), {
              instalador: {
                ...info,
                dataRef: new Date(`${info.data}T${info.horario}`),
                nome: visit.instalador?.nome,
                telefone: visit.instalador?.telefone ?? '', // As instalações que não foram agendadas estão sem o numero de telefone
                uid: visit.instalador?.uid,
              },
              kit_info: {
                ...visit.kit_info,
                instalacao: price
              }
            }).then(async () => {
                
                if(docInstall.exists()) {
                  const filterObras = installRef.obras.filter(data => data.id !== visit.id);
                  filterObras.push({
                    id: visit.id,
                    nome: visit.cliente,
                    finalizado: visit.instalacao || '',
                    ...info
                  })
                  // console.log(filterObras)
                  await updateDoc(doc(dataBase, 'Membros', installRef.uid), {
                    obras: filterObras
                  })
                }
              })

              const representanteDoc = await getDoc(doc(dataBase, 'Membros', visit.representante.id));
                if(representanteDoc.exists()) {
                  const representanteRef = representanteDoc.data();

                  await axios.post('https://n8n.corpbrasil.cloud/webhook/bacb4985-f57b-4b7b-8de6-5270c7af33c1', {
                    data: moment(info.data).format('DD/MM/YYYY'),
                    horario: info.horario,
                    id: visit.id,
                    cliente: visit.cliente,
                    cidade: visit.cidade,
                    consultora: visit.consultora,
                    nome: visit.instalador?.nome,
                    analista: representanteRef.nome_ref,
                    telefone: installRef.telefone ?? '',
                    telefone_analista: representanteRef.telefone ?? '',
                    horas_restante: calculateRemainingHours(moment(`${info.data} 08:00`)) 
                  })
                }


              Swal.fire({
                title: Company,
                html: `A <b>Data de Instalação</b> foi definida com sucesso.`,
                icon: "success",
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonColor: "#111",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  closeBox();
                }
              });
            }    
        });
      } catch (error) {
        // console.log(error);
      }
  };

  function calculateRemainingHours(targetDateString) {
    // Define o formato da data e hora de entrada
    const dateFormat = 'DD/MM/YYYY HH:mm';
  
    // Parse a string de data/hora para um objeto moment
    const targetMoment = moment(targetDateString, dateFormat);
  
    // Verifica se a data é válida
    if (!targetMoment.isValid()) {
      throw new Error('Data e hora fornecidas são inválidas.');
    }
  
    // Obtém o momento atual
    const now = moment();
  
    // Calcula a diferença em horas
    const differenceInHours = targetMoment.diff(now, 'hours', true);
  
    return differenceInHours.toFixed(2);
  }

  return (
    <Dialog
      className={style.dialog}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <IconButton
          aria-label="close"
          onClick={() => closeBox()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Definir Data de Instalação</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
          Preencha os campos abaixo para definir a <b>data da Instalação</b>.
        </DialogContentText>
        <form onSubmit={onSubmit}>
        <ThemeProvider theme={theme}>
        <div className={style.input_double}>
        <TextField
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            value={visit.instalador?.nome ?? ''}
            fullWidth
            disabled
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="name"
            label="Telefone"
            type="text"
            value={visit.instalador?.telefone ?? ''}
            fullWidth
            disabled
            variant="outlined"
          />
        </div>
        <div className={style.label_content}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: "center", fontSize: '1rem', margin: '0.5rem 0' }}>Data da Instalação</Typography>
        </div>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
          Inicio
        </DialogContentText>
        <div className={style.input_double}>
          <TextField
            margin="dense"
            id="name"
            label="Dia"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={info.data}
            onChange={(e) => setInfo({ ...info, data: e.target.value })}
            fullWidth
            inputProps={{ minLength: 6 }}
            required
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="name"
            label="Horário"
            type="time"
            InputLabelProps={{ shrink: true }}
            value={info.horario ?? '00:00'}
            onChange={(e) => setInfo({ ...info, horario: e.target.value })}
            fullWidth
            inputProps={{ minLength: 6 }}
            required
            variant="outlined"
          />
        </div>
        <DialogContentText sx={{ textAlign: "center", margin: '0.3rem 0' }}>
          Fim
        </DialogContentText>
        <div className={style.input_double}>
          <TextField
            margin="dense"
            id="name"
            label="Dia"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={info.data_final}
            onChange={(e) => setInfo({ ...info, data_final: e.target.value })}
            fullWidth
            inputProps={{ minLength: 6 }}
            required
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="name"
            label="Horário"
            type="time"
            InputLabelProps={{ shrink: true }}
            value={info.horario_final}
            onChange={(e) => setInfo({ ...info, horario_final: e.target.value })}
            fullWidth
            inputProps={{ minLength: 6 }}
            required
            variant="outlined"
          />
        </div>
        <div className={style.input_double}>
          <CurrencyInput
            fullWidth
            style={{ margin: '1rem 0 0.5rem 0' }}
            customInput={TextField}
            label="Valor Fechado"
            placeholder="R$ 00"
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            onValueChange={(value) =>
              setPrice(value)
            }
            value={price}
            min={50}
            color="primary"
            required
          />
        </div>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
          <DialogActions sx={{ justifyContent: 'center', marginTop: '1rem' }}>
            <Button disableElevation sx={{ textTransform: 'capitalize' }} color="success" variant="contained" type="submit">Confirmar</Button>
            <Button disableElevation sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => closeBox()}>Cancelar</Button>
          </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterInstall;

import { updateDoc, doc } from 'firebase/firestore';
import { useEffect, useState } from 'react'
import Swal from "sweetalert2"; // cria alertas personalizado
import { dataBase } from '../../../../firebase/database';
import { Company } from '../../../../data/Data'

import styles from "../styles.module.scss";

import Dialog from "@mui/material/Dialog";
import { PatternFormat } from "react-number-format";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { theme } from '../../../../data/theme';
import { InputAdornment, ThemeProvider } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

const EditAdmin = ({ userRef, members, memberRef, open, close, openBox }) => {
  const [check, setCheck] = useState({});
  const [cor, setCor] = useState('');
  const [telefone, setTelefone] = useState('');
  const [idCRM, setIdCRM] = useState('0');
  const [veiculo, setVeiculo] = useState('0');
  const [cargo, setCargo] = useState('');
  const [cidade, setCidade] = useState("");
  const [pix, setPix] = useState("");
  const [grupo, setGrupo] = useState({
    time: '',
    numero: ''
  });

 useEffect(() => {
  if(open) {
    setCor(memberRef && memberRef.cor);
    setTelefone(memberRef && memberRef.telefone);
    setIdCRM(memberRef && memberRef.id_sm);
    setVeiculo(memberRef && memberRef.veiculo);
    setCargo(memberRef && memberRef.cargo);
    setPix(memberRef && memberRef.pix);
    setCidade(memberRef && memberRef.cidade);
    setCheck({});
    if(memberRef && memberRef.cargo === 'Representante') {
      setGrupo({
        time: memberRef.grupo?.time,
        numero: memberRef.grupo?.numero
      })
    }
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[open])

 const checkCode = (value) => {
  console.log(value);
  const representantes = members.filter(member => member.cargo === 'Representante' && (member.grupo?.time === grupo.time && member.grupo?.numero !== memberRef.grupo?.numero));
  return !!representantes.find(data => data.id_representante === value);
}

const validateInput = (name, value) => {
  let errormsg = '';
  switch(name) {
    case 'numero':
      if(!value) {
        errormsg = 'Código Inválido.'
      }
      if(checkCode(`${grupo.time}${value}`)) {
        errormsg = 'Este código já está em uso.'
      }
    break;
    default: return null;
  }

  return errormsg;
}

const handleBlur = (e) => {
  const { name, value } = e.target;
  const error = validateInput(name, value);
  console.log(check);
  setCheck({...check, 
    [name]: error
  })
}

const handleChangeGrupo = (event) => {
  const { name, value } = event.target;
  setGrupo({
   ...grupo,
    [name]: value
  })
  setCheck({
    ...check,
    [name]: ''
  })
}

  const onSubmit = async (e) => {
        e.preventDefault();
        try {
          close();
          Swal.fire({
            title: 'Atenção',
            text: `Você deseja alterar os dados?`,
            icon: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const telefoneFormatado = typeof telefone === 'number' ? telefone : telefone.replace(/\D/g, '');
              let props = {
                  id_sm: idCRM,
                  veiculo: veiculo,
                  telefone: telefoneFormatado,
                  cor: cor,
                  cidade: cidade
                }
                if(memberRef.cargo === 'Representante') {
                  props = {
                    ...props,
                    pix: pix,
                    grupo: {
                      ...grupo
                    },
                    id_representante: `${grupo.time}${grupo.numero}`
                  }
                }
              await updateDoc(doc(dataBase,"Membros", memberRef.id), props).then(() => {
                Swal.fire({
               title: Company,
               html: 'Os dados do Colaborador(a) foi alterado com sucesso.',
               icon: "success",
               showConfirmButton: true,
               showCloseButton: true,
               confirmButtonColor: "#111"
             }).then(() => {
               close();
             })
              })
              } else {
                openBox('edit');
              }   
            })
        } catch (error) {
          // console.log(error);
        }
    }

  const handleTeam = (value) => {
    const representantes = members.filter(member => member.cargo === 'Representante' && member.grupo?.time === value);
    const representanteId = memberRef.cargo === 'Representante' && memberRef.grupo?.time === value ? memberRef.grupo?.numero : representantes.length + 1
    setGrupo({
      time: value,
      numero: representantes.length > 0 ? representanteId.toString().padStart(2, '0') : '01'
    })
    console.log(value)
    const corRef = value === 'CBRLJ' ? '#FF8957' : '#52CBFF';
    setCor(corRef);
  }

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={() => close()}
    >
      <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Editar Colaborador(a)</DialogTitle>
      <DialogContent>
        {/* <DialogContentText sx={{ textAlign: "center" }}>
          Preencha os campos abaixo para agendar a <b>Visita</b>.
        </DialogContentText> */}
        <form onSubmit={onSubmit}>
        <ThemeProvider theme={theme}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nome"
          type="text"
          value={memberRef && memberRef.nome}
          fullWidth
          disabled
          variant="outlined"
        />
          <FormControl sx={{ margin: "0.3rem 0" }} fullWidth>
            <InputLabel id="simple-select-label">Cargo</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={cargo ? cargo : ''}
              label="Cargo"
              onChange={(e) => setCargo(e.target.value)}
              disabled
            >
              <MenuItem value="Vendedor(a)">Vendedor(a)</MenuItem>
              <MenuItem value="Técnico">Técnico</MenuItem>
              <MenuItem value="Administrador">Administrador</MenuItem>
              <MenuItem value="Instalador">Instalador</MenuItem>
              <MenuItem value="Representante">Representante</MenuItem>
              <MenuItem value="Limpeza">Limpeza</MenuItem>
            </Select>
          </FormControl>
          {cargo === 'Representante' ? 
          <><div className={styles.input_double}>
            <FormControl sx={{ margin: "0.5rem 0" }} fullWidth>
              <InputLabel id="simple-select-label">Time</InputLabel>
              <Select
                labelId="simple-select-label"
                value={grupo.time || ''}
                label="Time"
                disabled={userRef && userRef.time ? true : false}
                onChange={(e) => handleTeam(e.target.value)}
                required
              >
                <MenuItem value="CBRLJ">Laranja</MenuItem>
                <MenuItem value="CBRAZ">Azul</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="numero"
              id="name"
              label="Código do Representante"
              inputProps={{ 
                minLength: 2,
                maxLength: 2
               }}
              type="number"
              value={grupo.numero || ''}
              onChange={handleChangeGrupo}
              onBlur={handleBlur}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"><b style={{ color: "#3C3C3C" }}>{grupo.time}</b></InputAdornment>,
              }}
              error={!!check.numero}
              helperText={check.numero}
              FormHelperTextProps={{
                component: (item) => {
                    return (
                    <span className={`${item.className} ${styles.error}`}>
                        <ErrorIcon sx={{ fontSize: '1.2rem' }} /> {item.children}
                    </span>
                    );
                },
            }}
              required
              variant="outlined"
            />
          </div>
          <div className={styles.input_double}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Cidade"
              value={cidade ? cidade : ''}
              onChange={(e) => setCidade(e.target.value)}
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Chave Pix"
              value={pix ? pix : ''}
              onChange={(e) => setPix(e.target.value)}
              fullWidth
              required
              variant="outlined"
            />
          </div></>
           :
          <><div className={styles.input_double}>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            label="ID do CRM"
            type="number"
            value={idCRM ? idCRM : ''}
            onChange={(e) => setIdCRM(e.target.value)}
            fullWidth
            required
            variant="outlined"
          />
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Cidade"
              value={cidade ? cidade : ''}
              onChange={(e) => setCidade(e.target.value)}
              fullWidth
              required
              variant="outlined"
            /></div>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Veiculo"
            type="text"
            value={veiculo ? veiculo : ''}
            onChange={(e) => setVeiculo(e.target.value)}
            fullWidth
            required
            variant="outlined"
          /></>
          }
        <div className={styles.label_content}>
          <div>
            <span>Telefone</span>
            <PatternFormat
              className="label__input"
              onChange={(e) => setTelefone(e.target.value)}
              format="## (##) ##### ####"
              mask="_"
              placeholder="00 (00) 00000 0000"
              value={telefone ? telefone : ''}
              label="Telefone"
              minLength={9}
              variant="outlined"
              color="primary"
              required
            />
          </div>
          <div>
          <span>Cor</span>
          <input type="color"
            className={styles.color}
            value={cor ? cor : ''}
            style={{ backgroundColor: cor }}
            onChange={(e) => setCor(e.target.value)}
            required
            disabled={memberRef && memberRef.cargo === 'Representante'}
          />
             <p className={styles.name_color}>{memberRef && memberRef.nome}</p>
          </div>
        </div>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
          <DialogActions sx={{ justifyContent: 'center' }}>
          <Button disableElevation
           sx={{ textTransform: 'capitalize' }}
           variant="contained"
           color="success"
           type='submit'>Confirmar</Button>
          <Button 
           onClick={() => close()}
           disableElevation
            color="error"
            sx={{ textTransform: 'capitalize' }}
            variant="contained">Cancelar</Button>
        </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
    // <div className='modal-visit'>
    //    <div className='modal-box-visit'>
    //         <div className='modal-box-visit__close'>
    //             <button onClick={returnAdmin} className='btn-close' />
    //         </div>
    //         <h4>Editar {memberRef.cargo === 'Técnico' ? 'Veículo do Técnico' : 'Cor'}</h4> 
    //     <form className='form-visit' onSubmit={handleSubmit(onSubmit)}>
    //     <label className="form-visit__label">
    //     <p>Colaborador(a)</p>
    //         <input
    //           className="form-visit__text"
    //           type="text"
    //           {...register("nome")}
    //           disabled
    //         />
    //       </label>
           
    //       {memberRef.cargo !== 'Técnico' ? 
    //         (<><div className='form-visit__color' style={{ flexDirection: 'column' }}>
    //           <p>Escolha uma cor de destaque</p>
    //           <input
    //             type="color"
    //             autoComplete="off"
    //             value={cor}
    //             onChange={(e) => setCor(e.target.value)}
    //             required />
    //         </div><div className='form-visit__exemple'>
    //             <h3>Resultado:</h3>
    //             <p style={cor && {
    //               backgroundColor: cor,
    //               borderBottom: '1px solid' + cor,
    //               borderRight: '1px solid' + cor,
    //               borderLeft: '1px solid' + cor,
    //               color: "#fff",
    //               textShadow: '#5a5a5a -1px 0px 5px',
    //             }}>{memberRef.nome}</p>
    //           </div></>) : (<label className="form-visit__label">
    //        <p>Veículo</p>
    //         <input
    //           className="form-visit__text"
    //           type="number"
    //           placeholder="Digite o número do Veículo"
    //           autoComplete="off"
    //           onInput={(e) => e.target.value = e.target.value.slice(0, 3)}
    //           {...register("veiculo")}
    //           required
    //         />
    //       </label>)
    //         }
    //     <input className='form-visit__btn' type="submit" value="CONFIRMAR"/>
    //   </form> 
    //     </div> 
    // </div>
  )
}

export default EditAdmin
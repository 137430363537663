import { Alert, AlertTitle, Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import style from './style.module.scss';

import Telhado from '../../../images/system/projeto/Telhado.jpg';
import Irradiacao from '../../../images/system/projeto/Irradiacao.jpg';
import Projeto from '../../../images/system/projeto/Projeto.jpg';
import Projeto_Final from '../../../images/system/projeto/Projeto_Final.jpg';

import { MdEngineering } from "react-icons/md";
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RefreshIcon from '@mui/icons-material/Refresh';

import NewTooltip from '../../../components/Tooltip';
import { VisuallyHiddenInput } from '../../../components/InputFile';
import CustomAudioPlayerFixed from '../../../components/AudioPlayerFixed/Index';
import AudioRecorder from '../../../components/AudioRecorder/Index';
import ButtonCB from '../../../components/Button';
import axios from 'axios';
import sortData from '../../../functions/sortData';
import formatCurrency from '../../../functions/formatCurrency';
import Swal from 'sweetalert2';
import { toast } from '../../../components/Toast';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import removeNullProperties from '../../../functions/removeNullProperties';
import { doc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import Loading from '../../../components/Loading';
import PropTypes from 'prop-types';
// import { doc, updateDoc } from 'firebase/firestore';
// import { dataBase } from '../../../firebase/database';
// import moment from 'moment';

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Visit = ({ customer, sheet, index, userRef }) => {
    const inputRef = useRef(null);
    const [projectAudio, setProjectAudio] = useState(null);
    const [electricalAudio, setElectricalAudio] = useState(null);
    const [TabsValue, setTabsValue] = useState(0);
    const [valueTotal, setValueTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [improvements, setImprovements] = useState([
        {
            id: '',
            distribuidor: '',
            nome: '',
            categoria: '',
            quantidade: '',
            valor: ''
        }
    ]);
    const [equipmentsList, setEquipmentsList] = useState([]);
    const [reversalAudio, setReversalAudio] = useState(null);
    const [activeImprovements, setActivemprovements] = useState({ view: false, custos: '' });
    const [project, setProject] = useState({
        imagens: {
            projeto: {
                file: '',
                complete: null,
            },
            irradiacao: {
                file: '',
                complete: null,
            },
            projeto_final: {
                file: '',
                complete: null,
            }
        }
    });
    const [electrical, setElectrical] = useState([{
        file: '',
        complete: null
    }]);
    const [roof, setRoof] = useState([{
        file: '',
        complete: null
    }]);
    const [commentary, setCommentary] = useState({
        projeto: '',
        irradiacao: '',
        projeto_final: '',
    });
    const [commentaryEletrical, setCommentaryEletrical] = useState({
        0: ''
    });
    const [commentaryRoof, setCommentaryRoof] = useState({
        0: ''
    });
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const loadingEquipments = equipmentsList.length === 0;

    useEffect(() => {

        const fetchData = async () => {
            try {
                const equipmentSheet = await axios.get('https://script.google.com/macros/s/AKfycbzkvm0nIMUg8Bcaj3WcmGXuaSzhpaluB60__2vxVbUoCyn8LgJosReiUOQIBefS_7YlmQ/exec');

                const equipmentData = equipmentSheet.data.GoogleSheetData.filter(data => data[0] !== 'Id' && data[1].length > 0);
                const equipmentOptions = equipmentData.reduce((uniqueList, currentData) => {

                    if (typeof currentData[0] === 'string' && currentData[0].trim() !== '') {
                        uniqueList.push({
                            id: currentData[0],
                            distribuidor: currentData[1],
                            nome: currentData[2],
                            categoria: currentData[3],
                            quantidade: 1,
                            tipo_quantidade: currentData[4],
                            valor: currentData[6]
                        });
                    }

                    return uniqueList;
                }, []);

                setEquipmentsList([
                    ...sortData(equipmentOptions, 'nome'),
                ]);


            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [refresh])

    useEffect(() => {
        if (improvements) {
            setValueTotal(improvements?.reduce((acc, item) => acc + (item.valor_total || 0), 0));
        }
    }, [improvements])

    const handleProject = (e, type, action = 'add') => {
        const { files, name } = e.target;
        const file = files?.[0];

        let updatedProject = { ...project };

        if (action === 'delete') {
            updatedProject[type] = {
                ...updatedProject[type],
                [name]: { file: '', complete: null }
            };
        } else if (file) {
            updatedProject[type] = {
                ...updatedProject[type],
                [name]: { file: URL.createObjectURL(file), complete: file }
            };
        }

        setProject(updatedProject);
    };

    const handleEletrical = (e, index, action) => {
        const { files } = e.target;
        const file = files?.[0];

        let updatedEletrical = [...electrical];

        if (action === 'delete') {
            updatedEletrical[index] = { file: '', complete: null }

        } else if (file) {
            updatedEletrical[index] = { file: URL.createObjectURL(file), complete: file }
        };
        console.log(updatedEletrical)
        setElectrical(updatedEletrical);
    };

    const handleRoof = (e, index, action) => {
        const { files } = e.target;
        const file = files?.[0];

        let updatedRoof = [...roof];

        if (action === 'delete') {
            updatedRoof[index] = { file: '', complete: null }

        } else if (file) {
            updatedRoof[index] = { file: URL.createObjectURL(file), complete: file }
        };
        console.log(updatedRoof)
        setRoof(updatedRoof);
    };

    const sendAudio = (e, type) => {
        const audio = e.target.files[0];
        const audioData = { file: URL.createObjectURL(audio), complete: audio };

        // Mapeia os tipos para os setters corretos
        const typeSetterMap = {
            'projeto': setProjectAudio,
            'elétrica': setElectricalAudio,
            'reversao': setReversalAudio,
        };

        // Chama o setter correspondente com os dados de áudio
        if (typeSetterMap[type]) {
            typeSetterMap[type](audioData);
        } else {
            console.error('Tipo de áudio desconhecido:', type);
        }
    };

    const addNewPhotos = (type) => {
        const newPhoto = {
            file: '',
            complete: null
        }
        if (type === 'telhado') {
            setRoof([...roof, newPhoto]);
        } else if (type === 'elétrica') {
            setElectrical([...electrical, newPhoto]);
        }
    }

    const addNewEquipment = () => {
        const newEquipment = {
            id: '',
            distribuidor: '',
            nome: '',
            categoria: '',
            tipo_quantidade: '',
            quantidade: '',
            valor: ''
        }
        setImprovements([...improvements, newEquipment]);
    }

    const removeEquipment = async (index) => {
        try {
            const result = await Swal.fire({
                title: 'Atenção',
                html: `Você deseja excluir o <b>Material?</b>`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
            })
            if (result.isConfirmed) {
                const newEquipments = improvements.filter((_, i) => i !== index);
                setImprovements(newEquipments);
            }
        } catch (error) {
            toast({ icon: 'error', text: 'Ocorreu um erro ao excluir o material.', error: error });
        }
    };

    const removePhoto = (index, type) => {
        if (type === 'telhado') {
            let newRoofPhotos = [...roof];
            console.log(newRoofPhotos);
            newRoofPhotos.splice(index, 1);
            setRoof(newRoofPhotos);
        } else {
            let newEletricalPhotos = [...electrical];
            console.log(newEletricalPhotos);
            newEletricalPhotos.splice(index, 1);
            setElectrical(newEletricalPhotos);
        }
    }

    const titleSection = (title) => {
        switch (title) {
            case 'telhado':
                return 'Imagem Aérea';
            case 'projeto':
                return 'Projeto';
            case 'irradiacao':
                return 'Estudo de Irradiação';
            case 'projeto_final':
                return 'Projeto Finalizado';
            default: return null
        }
    }

    const imageSection = (title) => {
        switch (title) {
            case 'telhado':
                return Telhado;
            case 'projeto':
                return Projeto;
            case 'irradiacao':
                return Irradiacao;
            case 'projeto_final':
                return Projeto_Final;
            default: return null
        }
    }

    const handleEquipmentChange = (array, index, field, value) => {
        const currentEquipments = array;
        const newEquipments = [...currentEquipments];
        const newEquipment = { ...newEquipments[index], [field]: value ? Number(value) : '' };
        const valorTotal = (newEquipment.valor * newEquipment.quantidade);
        newEquipment.valor_total = valorTotal;
        newEquipments[index] = newEquipment;
        return newEquipments;
    };

    const handleEquipmentChangeList = (array, index, obj) => {
        const currentEquipments = array;
        const newEquipments = [...currentEquipments];

        // Verifica se já existe uma quantidade e multiplica o valor pelo novo valor, se houver
        const existingEquipment = newEquipments[index] || {};
        const quantidadeAnterior = existingEquipment.quantidade || 1; // Se não houver quantidade, assume 1

        const newEquipment = {
            ...existingEquipment,
            ...obj,
            quantidade: quantidadeAnterior ? existingEquipment.quantidade : obj.quantidade, // Usa a nova quantidade se disponível
        };

        // Calcula o valor total multiplicando o valor pela quantidade
        if (newEquipment.valor && newEquipment.quantidade) {
            newEquipment.valor_total = newEquipment.valor * newEquipment.quantidade;
        } else {
            newEquipment.valor_total = 0; // Define 0 se não houver valor ou quantidade
        }

        newEquipments[index] = newEquipment;
        return newEquipments;
    };

    console.log(projectAudio, project);

    const verificarImagensPreenchidas = (obj) => {
        // Verifica se 'imagens' existe e é um objeto
        if (!obj.imagens || typeof obj.imagens !== 'object') {
            console.log("O objeto não contém a propriedade 'imagens' ou não é um objeto válido.");
            return false;
        }

        // Itera sobre cada chave em 'imagens'
        for (const key in obj.imagens) {
            if (obj.imagens.hasOwnProperty(key)) {
                const imagem = obj.imagens[key];

                // Verifica se 'file' está preenchido
                if (!imagem.file) {
                    console.log(`A propriedade 'file' de '${key}' está vazia.`);
                    return false;
                }

                // Verifica se 'complete' tem ao menos uma propriedade
                if (imagem.complete.length === 0) {
                    console.log(`A propriedade 'complete' de '${key}' está vazia.`);
                    return false;
                }
            }
        }

        // Se todas as propriedades estiverem preenchidas, retorna true
        return true;
    };

    const verificarImagensPreenchidasArray = (imagensArray) => {
        // Verifica se 'imagensArray' é realmente um array
        if (!Array.isArray(imagensArray)) {
            console.log("O objeto fornecido não é um array.");
            return false;
        }

        // Itera sobre cada item no array de imagens
        for (let i = 0; i < imagensArray.length; i++) {
            const imagem = imagensArray[i];

            // Verifica se 'file' está preenchido
            if (!imagem.file) {
                console.log(`A propriedade 'file' no índice ${i} está vazia.`);
                return false;
            }

            // Verifica se 'complete' tem ao menos uma propriedade
            if (imagem.complete.length === 0) {
                console.log(`A propriedade 'complete' no índice ${i} está vazia.`);
                return false;
            }
        }

        // Retorna true se todas as imagens tiverem 'file' e 'complete' preenchidos
        return true;
    };

    const verificarPropriedadesVazias = (array) => {
        // Verifica se o argumento passado é um array
        if (!Array.isArray(array)) {
            console.log("O valor fornecido não é um array.");
            return false;
        }

        // Itera sobre cada item do array
        for (let i = 0; i < array.length; i++) {
            const item = array[i];

            // Itera sobre as propriedades do item
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    // Verifica se a propriedade está vazia, undefined ou null
                    if (item[key] === "" || item[key] === null || item[key] === undefined) {
                        console.log(`A propriedade '${key}' no item do índice ${i} está vazia.`);
                        return false;
                    }
                }
            }
        }

        // Retorna true se nenhuma propriedade estiver vazia
        return true;
    };

    const checkProject = () => {
        const check1 = verificarImagensPreenchidasArray(roof);
        const check2 = verificarImagensPreenchidas(project) && projectAudio?.file?.length > 0
        return check1 && check2;
    }

    // const extractNumberBeforeK = (str = '1K-') => {
    //     const regex = /(\d+(?:\.\d+)?)K-/; // Expressão regular para capturar números antes de 'K-'
    //     const match = str.match(regex);    // Verifica se há correspondência na string
    //     if (match) {
    //         return parseFloat(match[1]);     // Retorna o número como float
    //     }
    //     return null; // Retorna null se não houver correspondência
    // };

    const checkElectrical = () => {
        const check1 = verificarImagensPreenchidasArray(electrical) && electricalAudio !== null;
        const check2 = activeImprovements.view === true ? verificarPropriedadesVazias(improvements) && activeImprovements?.custos?.length > 0 : true;
        // const check3 = extractNumberBeforeK(sheet?.orcamento?.inversor) > 7.5 ? reversalAudio !== null : true;
        // console.log(check1, check2, check3);
        return check1 && check2;
    }

    console.log(reversalAudio)
    console.log(checkElectrical())
    console.log(verificarImagensPreenchidasArray(electrical), electricalAudio, reversalAudio);
    console.log(verificarPropriedadesVazias(improvements));

    const handleUpload = async (data, name) => {

        if (!data) {
            return;
        }

        try {
            // Upload do áudio
            let filesRef = {};

            if (data) {
                const storage = getStorage();
                const mimeType = data.complete.type;
                const format = mimeType.split('/')[1]; // Extrai o formato a partir do tipo MIME
                const fileName = `${name}.${format}`;

                // Define a referência no storage com o nome correto e formato dinâmico
                const dataRef = ref(storage, `Clientes/${customer.id}/${fileName}-${Date.now()}.${format}`);
                const dataSnapshot = await uploadBytes(dataRef, data.complete);
                const dataURL = await getDownloadURL(dataSnapshot.ref);
                // console.log('Áudio enviado com sucesso! URL:', audioURL);
                filesRef = {
                    url: dataURL,
                    ref: dataSnapshot.ref.fullPath
                };
            }

            return filesRef;

        } catch (error) {
            console.error('Erro ao enviar arquivos:', error);
        }
    };

    const processImages = async (objRef, type) => {
        // Transforma o objeto em array, se necessário
        if (!objRef) {
            return null;
        }

        let projectImgs = {};

        if (Array.isArray(objRef)) {
            projectImgs = await Promise.all(
                objRef.map(async (data, index) => {
                    const uploadResult = await handleUpload(data);
                    if(type === 'telhado') {
                        return {
                            ...uploadResult,
                            comentario: commentaryRoof[index] || ''
                        };
                    } else {
                        return {
                            ...uploadResult,
                            comentario: commentaryEletrical[index] || ''
                        };
                    }
                })
            );
        } else {
            await Promise.all(
                Object.entries(objRef).map(async ([key, value]) => {
                    const uploadResult = await handleUpload(value);
                    projectImgs[key] = { ...uploadResult, comentario: commentary[key] }; // Adiciona a chave e o resultado

                })
            );
        }

        return projectImgs;
    };

    const onSubmit = async () => {

        const result = await Swal.fire({
            title: 'Atenção',
            html: `Os dados estão corretos?`,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        });

        if (result.isConfirmed) {
            try {
                setLoading(true);

                const roofImgs = await processImages(roof, 'telhado');
                const projectImgs = await processImages(project.imagens, 'elétrica');
                const electricalImgs = await processImages(electrical);
                const reversalAudioRef = await handleUpload(reversalAudio);
                const projectAudioRef = await handleUpload(projectAudio);
                const electricalAudioRef = await handleUpload(electricalAudio);

                const visit = {
                    projeto: {
                        ...projectImgs,
                        telhado: roofImgs,
                        audio: projectAudioRef
                    },
                    rede_eletrica: {
                        analise: electricalImgs,
                        melhorias: activeImprovements.view ? {
                            material: [...improvements],
                            valor_total: valueTotal,
                            custos: activeImprovements.custos
                        } : null,
                        audio_rede: electricalAudioRef,
                        audio_reversao: reversalAudioRef
                    }
                }
                visit.rede_eletrica = removeNullProperties(visit.rede_eletrica);
                visit.responsavel_id = userRef?.id;

                let updatedSheets = [...customer.planilha];
                let newSheet = sheet;
                newSheet.visita_tecnica = visit;
                newSheet.historico = [
                    ...sheet.historico,
                    {
                        data: moment().format('DD/MM/YYYY HH:mm'),
                        text: `Os dados da Visita Técnica foi salva.`
                    }];

                updatedSheets[index] = newSheet;
                
                await updateDoc(doc(dataBase, 'Clientes', customer.id), {
                    planilha: updatedSheets,
                    historico: [...customer.historico, {
                        data: moment().format('DD/MM/YYYY - HH:mm'),
                        evento: 'Visita Técnica Salvada',
                        text: `Salvou os dados da Visita Técnica do cliente do orçamento ${sheet.orcamento_solicitado || 'Não Informado'} através da planilha <a href=https://corpbrasil.app/credito/${customer.id}/${index} rel="noreferrer" target="_blank">${sheet.kit} kWp</a>.`,
                        usuario_id: userRef?.id,
                        usuario_nome: userRef?.nome
                    }]
                });

                setLoading(false);
                window.location.reload();
                await Swal.fire({
                    title: 'Parabéns!',
                    html: `A <b>Visita Técnica</b> foi salva com sucesso!`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                })

            } catch (error) {
                setLoading(false);
                toast({ icon: 'error', text: 'Ocorreu um erro ao enviar os dados. Tente novamente mais tarde.' })
            }
        }
    }

    return (
        <div className={`${style.extra_container} ${style.print_off} ${false ? style.lock_color : null}`}>
            <MdEngineering size={'5.4rem'} color='#1c256e' />
            <Box sx={{ display: 'flex', gap: '1.3rem', alignItems: 'center' }}>
                <h2>Visita Técnica</h2>
                {/* <Badge badgeContent='' color='success'>
                </Badge> */}
            </Box>
            <Loading view={loading} backgroundColor='#ffffffc9' />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Visita Técnica" centered>
                    <Tab
                        sx={{ textTransform: 'capitalize' }}
                        label="Projeto"
                        icon={checkProject() ? <DoneAllIcon color='success' /> : false}
                        iconPosition='start'
                        {...a11yProps(1)} />
                    <Tab
                        sx={{ textTransform: 'capitalize' }}
                        style={{ pointerEvents: "auto" }}
                        label={'Rede Elétrica'}
                        icon={checkElectrical() ? <DoneAllIcon color='success' /> : false}
                        iconPosition='start'
                        {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={TabsValue} index={0}>
                <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                {roof.map((data, index) => (
                    <div key={index} className={`${style.extra_container_item} ${data?.complete || sheet?.visita_tecnica?.projeto?.telhado[index]?.url ? style.confirm_color : ''}`}>
                        <h3>Imagem Aérea<b>(Obrigatório)</b> {data?.complete || sheet?.visita_tecnica?.projeto?.telhado[index]?.url ? ' ✅' : null}{index !== 0 && <IconButton color='error' onClick={() => removePhoto(index, 'telhado')}><CancelIcon /></IconButton>}</h3>
                        <p>Envie uma imagem do telhado.</p>
                        {sheet?.visita_tecnica?.projeto?.telhado[index]?.url ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    gap: '0.5rem',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '1rem'
                                }}
                                className={style.print_off}
                            >
                                <img
                                    className={style.img_upload}
                                    src={sheet?.visita_tecnica?.projeto?.telhado[index]?.url || ''}
                                    alt="Imagem do Google Earth"
                                />
                                <NewTooltip title="Excluir Imagem" placement="top">
                                    <IconButton color="error">
                                        <DeleteRounded />
                                    </IconButton>
                                </NewTooltip>
                            </Box>
                        ) : (
                            <>
                                {data?.file ? (
                                    <img
                                        className={style.img_upload}
                                        src={data?.file}
                                        alt="Imagem do Telhado"
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            marginTop: '1rem'
                                        }}
                                    >
                                        <p>Exemplo:</p>
                                            <img
                                                className={style.img_upload}
                                                src={Telhado}
                                                alt="Imagem do Telhado"
                                            />
                                    </Box>
                                )}
                                <TextField
                                    label="Comentário"
                                    sx={{ backgroundColor: "#fff" }}
                                    multiline
                                    name='telhado'
                                    fullWidth
                                    // disabled={!data?.file}
                                    value={commentaryRoof[`${index}`]}
                                    rows={3}
                                    onChange={(e) => setCommentaryRoof({ ...commentaryRoof, [`${index}`]: e.target.value })}
                                />
                                <div className={style.sheets_buttons}>
                                    <Button
                                        disableElevation
                                        sx={{ textTransform: 'capitalize' }}
                                        component="label"
                                        variant="contained"
                                        // disabled={cloneSheet}
                                        onChange={(e) => handleRoof(e, index)}
                                        startIcon={<AddToPhotosIcon />}
                                    >
                                        Enviar Foto
                                        <VisuallyHiddenInput
                                            multiple={false}
                                            type="file"
                                            accept="image/png,image/jpeg"
                                        />
                                    </Button>
                                    {data?.file && (
                                        <NewTooltip title="Excluir Imagem" placement="top">
                                            <IconButton
                                                color="error"
                                                onClick={() => handleRoof({ target: { name: '' } }, index, 'delete')}>
                                                <DeleteRounded />
                                            </IconButton>
                                        </NewTooltip>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                ))}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ButtonCB background="#058eda" startIcon={<AddPhotoAlternateIcon />} onClick={() => addNewPhotos('telhado')}>Adicionar mais Fotos</ButtonCB>
                    </Box>
                    {Object.entries(commentary).map(([key, value]) => (
                        <div key={key} className={`${style.extra_container_item} ${project?.imagens?.[key]?.complete || sheet?.visita_tecnica?.projeto?.[key]?.url ? style.confirm_color : ''}`}>
                            <h3>{titleSection(key)} <b>(Obrigatório)</b> {project?.imagens?.[key]?.complete || sheet?.visita_tecnica?.projeto?.[key]?.url ? ' ✅' : null}</h3>
                            <p>Envie uma imagem.</p>
                            {sheet?.visita_tecnica?.projeto?.[key]?.url ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        gap: '0.5rem',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '1rem'
                                    }}
                                    className={style.print_off}
                                >
                                    <img
                                        className={style.img_upload}
                                        src={sheet?.visita_tecnica?.projeto?.[key]?.url || ''}
                                        alt="Imagem do Google Earth"
                                    />
                                    <NewTooltip title="Excluir Imagem" placement="top">
                                        <IconButton color="error">
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip>
                                </Box>
                            ) : (
                                <>
                                    {project?.imagens?.[key]?.file ? (
                                        <img
                                            className={style.img_upload}
                                            src={project?.imagens?.[key]?.file}
                                            alt="Imagem"
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                marginTop: '1rem'
                                            }}
                                        >
                                            <p>Exemplo:</p>
                                            <img
                                                className={style.img_upload}
                                                src={imageSection(key)}
                                                alt="Imagem"
                                            />
                                        </Box>
                                    )}
                                    <TextField
                                        label="Comentário"
                                        sx={{ backgroundColor: "#fff" }}
                                        multiline
                                        name='imagem'
                                        fullWidth
                                        // disabled={!project?.imagens?.[key]?.file}
                                        value={commentary[key]}
                                        rows={3}
                                        onChange={(e) => setCommentary({ ...commentary, [key]: e.target.value })}
                                    />
                                    <div className={style.sheets_buttons}>
                                        <Button
                                            disableElevation
                                            sx={{ textTransform: 'capitalize' }}
                                            component="label"
                                            variant="contained"
                                            // disabled={cloneSheet}
                                            onChange={(e) => handleProject(e, 'imagens')}
                                            startIcon={<AddToPhotosIcon />}
                                        >
                                            Enviar Foto
                                            <VisuallyHiddenInput
                                                name={key}
                                                multiple={false}
                                                type="file"
                                                accept="image/png,image/jpeg"
                                            />
                                        </Button>
                                        {project?.imagens?.[key]?.file && (
                                            <NewTooltip title="Excluir Imagem" placement="top">
                                                <IconButton
                                                    color="error"
                                                    onClick={() => handleProject({ target: { name: key } }, 'imagens', 'delete')}
                                                >
                                                    <DeleteRounded />
                                                </IconButton>
                                            </NewTooltip>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                    <div className={`${style.extra_container_item} ${projectAudio?.complete || sheet?.visita_tecnica?.projeto?.audio?.url ? style.confirm_color : ''}`}>
                        <h3>Áudio sobre o Projeto <b>(Obrigatório)</b>{projectAudio?.complete || sheet?.visita_tecnica?.projeto?.audio?.url ? ' ✅' : null}</h3>
                        <p>Envie um audio explicando como é o projeto do cliente.</p>
                        {sheet?.visita_tecnica?.projeto?.audio?.url ?
                            <Box sx={{ display: 'flex', width: '100%', gap: '0.5rem', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }} className={style.print_off}>
                                <CustomAudioPlayerFixed url={sheet?.visita_tecnica?.projeto?.audio?.url} />
                                <NewTooltip title='Excluir Áudio' placement='top'>
                                    <IconButton color='error'>
                                        <DeleteRounded />
                                    </IconButton>
                                </NewTooltip>
                            </Box> :
                            <div className={`${style.sheets_buttons} ${style.print_off}`}>
                                <AudioRecorder setAudio={setProjectAudio} audio={projectAudio} />
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                    <Button
                                        disableElevation
                                        sx={{ textTransform: 'capitalize' }}
                                        component="label"
                                        variant="contained"
                                        onChange={(e) => sendAudio(e, 'projeto')}
                                        startIcon={<AudioFileIcon />}>
                                        Enviar Áudio
                                        <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                                    </Button>
                                </Box>
                            </div>
                        }
                    </div>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={TabsValue} index={1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {electrical.map((data, index) => (
                        <div key={index} className={`${style.extra_container_item} ${data?.complete || sheet?.visita_tecnica?.rede_eletrica?.analise[index]?.url ? style.confirm_color : ''}`}>
                            <h3>Imagem <b>(Obrigatório)</b> {data?.complete || sheet?.visita_tecnica?.rede_eletrica?.analise[index]?.url ? ' ✅' : null}{index !== 0 && <IconButton color='error' onClick={() => removePhoto(index)}><CancelIcon /></IconButton>}</h3>
                            <p>Envie uma imagem da rede elétrica do cliente.</p>
                            {sheet?.visita_tecnica?.rede_eletrica?.analise[index]?.url ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        gap: '0.5rem',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '1rem'
                                    }}
                                    className={style.print_off}
                                >
                                    <img
                                        className={style.img_upload}
                                        src={sheet?.visita_tecnica?.rede_eletrica?.analise[index]?.url || ''}
                                        alt="Imagem do Google Earth"
                                    />
                                    <NewTooltip title="Excluir Imagem" placement="top">
                                        <IconButton color="error">
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip>
                                </Box>
                            ) : (
                                <>
                                    {data?.file ? (
                                        <img
                                            className={style.img_upload}
                                            src={data?.file}
                                            alt="Imagem"
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                marginTop: '1rem'
                                            }}
                                        >
                                        </Box>
                                    )}
                                    <TextField
                                        label="Comentário"
                                        sx={{ backgroundColor: "#fff" }}
                                        multiline
                                        fullWidth
                                        // disabled={!data?.file}
                                        value={commentaryEletrical[`${index}`]}
                                        rows={3}
                                        onChange={(e) => setCommentaryEletrical({ ...commentaryEletrical, [`${index}`]: e.target.value })}
                                    />
                                    <div className={style.sheets_buttons}>
                                        <Button
                                            disableElevation
                                            sx={{ textTransform: 'capitalize' }}
                                            component="label"
                                            variant="contained"
                                            // disabled={cloneSheet}
                                            onChange={(e) => handleEletrical(e, index)}
                                            startIcon={<AddToPhotosIcon />}
                                        >
                                            Enviar Foto
                                            <VisuallyHiddenInput
                                                multiple={false}
                                                type="file"
                                                accept="image/png,image/jpeg"
                                            />
                                        </Button>
                                        {data?.file && (
                                            <NewTooltip title="Excluir Imagem" placement="top">
                                                <IconButton
                                                    color="error"
                                                    onClick={() => handleEletrical({ target: { name: '' } }, index, 'delete')}>
                                                    <DeleteRounded />
                                                </IconButton>
                                            </NewTooltip>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                    <ButtonCB background="#058eda" startIcon={<AddPhotoAlternateIcon />} onClick={() => addNewPhotos('elétrica')}>Adicionar mais Fotos</ButtonCB>
                    <Grid container spacing={2} sx={{ margin: '1rem 0', maxWidth: '800px', width: '100%', backgroundColor: "#f9f9f9", borderRadius: '10px', padding: '1rem 1rem 2rem 0rem', borderLeft: '4px solid #ccc' }}>
                        <Grid item xs={12} sx={{ textAlign: 'center', position: 'relative' }}>
                            <h2>Melhorias Elétricas</h2>
                            <NewTooltip title="Atualizar Materiais" placement='top'>
                                <IconButton sx={{ position: 'absolute', right: 0, top: 0 }} onClick={() => setRefresh(!refresh)}>
                                    <RefreshIcon />
                                </IconButton>
                            </NewTooltip>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <p>Será necessário melhorias elétricas?</p>
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={activeImprovements.view}
                                        color='success'
                                        label='123'
                                        onChange={() => setActivemprovements({ ...activeImprovements, view: !activeImprovements.view })}
                                        name={'1'}
                                        icon={<RadioButtonUncheckedIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />}
                                        checkedIcon={<CheckCircleIcon sx={{ fill: "#0FA958", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                                }
                                label={
                                    <Typography sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                                        Sim, gostaria de incluir melhorias.
                                    </Typography>
                                }
                            />
                            {activeImprovements?.view ?
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipo">A Melhoria terá custos?</InputLabel>
                                        <Select
                                            labelId='tipo'
                                            label="A Melhoria terá custos?"
                                            name='tipo'
                                            sx={{ backgroundColor: "#fff" }}
                                            value={activeImprovements.custos || ''}
                                            onChange={(e) => setActivemprovements({ ...activeImprovements, custos: e.target.value })}
                                            required>
                                            <MenuItem value={'Sim'}>Sim, Com Custos</MenuItem>
                                            <MenuItem value={'Não'}>Não, Sem Custos</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid> : null
                            }
                        </Grid>
                        {activeImprovements?.view ?
                            <Grid item xs={12}>
                                {improvements?.length > 0 ?
                                    improvements?.map((data, index) => (
                                        <Grid container spacing={2} marginBottom={'1rem'}>
                                            <Grid item xs={5.5}>
                                                <Autocomplete
                                                    value={data}
                                                    fullWidth
                                                    loading={loadingEquipments}
                                                    loadingText="Carregando..."
                                                    sx={{ alignItems: 'center' }}
                                                    onChange={(event, newValue) => {
                                                        if (newValue != null) {
                                                            setImprovements(handleEquipmentChangeList(improvements, index, newValue));
                                                        } else {
                                                            // Caso seja necessário lidar com a limpeza do campo
                                                            // setImprovements({});
                                                            const newEquipment = {
                                                                id: '',
                                                                distribuidor: '',
                                                                nome: '',
                                                                categoria: '',
                                                                tipo_quantidade: '',
                                                                quantidade: '',
                                                                valor: ''
                                                            }
                                                            const newImprovements = [...improvements];
                                                            newImprovements[index] = newEquipment;
                                                            setImprovements(newImprovements);
                                                        }
                                                    }}
                                                    id="free-solo-with-text-demo"
                                                    options={equipmentsList}
                                                    getOptionLabel={(option) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Add "xxx" option created dynamically
                                                        if (option.inputValue) {
                                                            return option.inputValue;
                                                        }
                                                        // Opção Regular
                                                        return option.nome;
                                                    }}
                                                    renderOption={(props, option) => {
                                                        return <li {...props}>{option.nome}</li>;
                                                    }}
                                                    // noOptionsText="Cliente não encontrado"
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            label={`Material - ${index + 1}`}
                                                            sx={{ backgroundColor: "#fff" }}
                                                        />
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                                                />
                                            </Grid>
                                            <Grid item xs={6.5} sx={{ display: 'flex', gap: '1rem' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', flexDirection: 'column', padding: '0.5rem 1rem', color: "#663c00", backgroundColor: "#fff4e5", borderRadius: '5px', minWidth: '100px' }}>
                                                    <b>Categoria</b>
                                                    <p>{data.categoria || 'Nenhuma'}</p>
                                                </Box>
                                                <TextField
                                                    label={data.tipo_quantidade === 'metro' ? 'Metros' : "Qntd"}
                                                    sx={{ backgroundColor: "#fff" }}
                                                    type="number"
                                                    value={data.quantidade}
                                                    onChange={(e) => setImprovements(handleEquipmentChange(improvements, index, 'quantidade', e.target.value))}
                                                />
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', flexDirection: 'column', padding: '0.5rem 1rem', color: "#164a26", backgroundColor: "#ebf8ef", borderRadius: '5px' }}>
                                                    <b>Preço</b>
                                                    <p>{formatCurrency(data.valor_total || 0)}</p>
                                                </Box>
                                                <NewTooltip title='Excluir Material' placement='top'>
                                                    <IconButton size='small' disabled={index === 0} onClick={() => removeEquipment(index)} color='error'>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </NewTooltip>
                                            </Grid>
                                        </Grid>
                                    )) : null}
                                <Grid container spacing={2}>
                                    <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                                        <ButtonCB onClick={addNewEquipment} background="#313131" startIcon={<AddCircleRoundedIcon />}>
                                            Adicionar Material
                                        </ButtonCB>
                                    </Grid>
                                    <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Alert icon={false} color='success' sx={{ borderLeft: '10px solid #26b151', padding: '0.5rem 1rem' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <p>Valor Total</p>
                                                    <h1>{formatCurrency(valueTotal || 0)}</h1>
                                                </Box>
                                            </Box>
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </Grid> : null
                        }
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div className={`${style.extra_container_item} ${electricalAudio?.complete || sheet?.visita_tecnica?.rede_eletrica?.audio_rede?.url ? style.confirm_color : ''}`}>
                            <h3>Áudio sobre a Rede Elétrica <b>(Obrigatório)</b>{electricalAudio?.complete || sheet?.visita_tecnica?.rede_eletrica?.audio_rede?.url ? ' ✅' : null}</h3>
                            <p>Envie um audio explicando sobre a rede elétrica do cliente.</p>
                            {sheet?.visita_tecnica?.rede_eletrica?.audio_rede?.url ?
                                <Box sx={{ display: 'flex', width: '100%', gap: '0.5rem', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }} className={style.print_off}>
                                    <CustomAudioPlayerFixed url={sheet?.visita_tecnica?.rede_eletrica?.audio_rede?.url} />
                                    <NewTooltip title='Excluir Áudio' placement='top'>
                                        <IconButton color='error'>
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip>
                                </Box> :
                                <div className={`${style.sheets_buttons} ${style.print_off}`}>
                                    <AudioRecorder setAudio={setElectricalAudio} audio={electricalAudio} />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                        <Button
                                            disableElevation
                                            sx={{ textTransform: 'capitalize' }}
                                            component="label"
                                            variant="contained"
                                            onChange={(e) => sendAudio(e, 'elétrica')}
                                            startIcon={<AudioFileIcon />}>
                                            Enviar Áudio
                                            <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                                        </Button>
                                    </Box>
                                </div>
                            }
                        </div>
                    </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Alert severity='info' sx={{ maxWidth: '800px', marginTop: '1rem' }}>
                                <AlertTitle>Texto sobre a Reversão de Fluxo</AlertTitle>
                                <p>De acordo com a norma da Aneel 1.098, projetos de energia solar com inversores acima de 7.5 kW precisam passar por uma análise para verificar se a rede pode suportar o sistema.
                                    Projetos que tiverem beneficiarias mesmo abaixo de 7.5 kW será submetido a análise de reversão de fluxo pela concessionária de energia.</p>
                            </Alert>
                            <div className={`${style.extra_container_item} ${reversalAudio?.complete ? style.confirm_color : ''}`}>
                                <h3>Áudio sobre Reversão de fluxo <b>(Para inversor acima de 7.5 kW ⚠️)</b>{reversalAudio?.complete ? ' ✅' : null}</h3>
                                <p>Envie um audio explicando sobre a reversão de fluxo.</p>
                                {sheet?.projeto?.reversao_audio?.url ?
                                    <Box sx={{ display: 'flex', width: '100%', gap: '0.5rem', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }} className={style.print_off}>
                                        <CustomAudioPlayerFixed url={sheet?.projeto?.reversao_audio?.url} />
                                        <NewTooltip title='Excluir Áudio' placement='top'>
                                            <IconButton color='error'>
                                                <DeleteRounded />
                                            </IconButton>
                                        </NewTooltip>
                                    </Box> :
                                    <div className={`${style.sheets_buttons} ${style.print_off}`}>
                                        <AudioRecorder setAudio={setReversalAudio} audio={reversalAudio} />
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                            <Button
                                                disableElevation
                                                sx={{ textTransform: 'capitalize' }}
                                                component="label"
                                                variant="contained"
                                                onChange={(e) => sendAudio(e, 'reversao')}
                                                startIcon={<AudioFileIcon />}>
                                                Enviar Áudio
                                                <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                                            </Button>
                                        </Box>
                                    </div>
                                }
                            </div>
                        </Grid>
                </Box>
            </CustomTabPanel>
            <ButtonCB
                background="#00cc61"
                disabled={!checkElectrical() || !checkProject()}
                onClick={onSubmit}>Salvar Visita
            </ButtonCB>
        </div>
    )
}

export default Visit
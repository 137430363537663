const sortData = (data, props) => {
    return data.sort((a, b) => {
      if (a[props] < b[props]) {
        return -1;
      }
      if (a[props] > b[props]) {
        return 1;
      }
      return 0;
    })
  }

  export default sortData;
import { useState, useRef } from 'react'
import Swal from "sweetalert2";
import style from "./style.module.scss";
import SlickSlider from "react-slick";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';

import CheckIcon from '@mui/icons-material/Check'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Vistoria from '../../../../images/install/vistoria.jpg';
import Telhas from '../../../../images/install/telhas.jpg';
import Kit_Solar from '../../../../images/install/kit_solar.jpg';

import { addDoc, collection } from 'firebase/firestore';
import { dataBase } from '../../../../firebase/database';
import useUpload from '../../../../hooks/useUpload';
import useCircular from '../../../../hooks/useCircular';

const InstallStep2 = ({visit,  open, close }) => {
  const theme = useTheme();
  const slider = useRef(null);
  const { uploadImages, changeProgress } = useUpload(visit);
  const { CircularProgressWithLabel  } = useCircular();
  // const [openPhoto, setOpenPhoto] = useState(0);
  const [step, setStep] = useState(0);
  const [vistoria, setVistoria] = useState([]);
  const [kitSolar, setKitSolar] = useState([]);
  const [telhas, setTelhas] = useState([]);
  // const [photos, setPhotos] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checkData, setCheckData] = useState([false]);
  const checkPhotos = visit.kit_info && visit.kit_info.estrutura === 'Cerâmico' ? 
  ['Fotos do Kit Solar', 'Video da Vistoria dos Módulos', 'Fotos das Telhas'] : ['Fotos do Kit Solar', 'Video da Vistoria dos Módulos'];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
  };

  // const changeStep = (direction) => {
  //   if(direction === 'go') {
  //     if(step === 1 && newPosition !== position) {
  //       return Swal.fire({
  //         title: 'GPS do Padrão',
  //         html: `Confirme o <b>GPS do Padrão</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     } else if (telhadoPerto.length === 0 || telhadoLonge.length === 0 || telhadoVideo.length === 0) {
  //       let validationPhoto;
  //       if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Perto'
  //       } else if(telhadoPerto.length === 0) {
  //         validationPhoto = 'a foto do <b>Telhado de Longe'
  //       } else if(telhadoVideo.length === 0) {
  //         validationPhoto = 'um vídeo do <b>Telhado'
  //       }
  //       return Swal.fire({
  //         title: 'Fotos & Vídeos',
  //         html: `Envie ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
  //         icon: "error",
  //         showCloseButton: true,
  //         confirmButtonColor: "#F39200",
  //         confirmButtonText: "Ok",
  //       })
  //     }
  //     setStep(step + 1);
  //   } else {
  //     setStep(step - 1);
  //   }
  // }

  const closeBox = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setCheckData([false]);
      setVistoria([]);
      setKitSolar([]);
      setTelhas([]);
    }, 500);
  }

  const handleChangeCheck= (index) => {
    setCheckData((prevCheckData) => {
      const newCheckData = [...prevCheckData]; // Cria uma cópia do array existente
      newCheckData[index] = !newCheckData[index]; // Inverte o valor do elemento específico
      return newCheckData; // Retorna o novo array atualizado
    });
  }

  const sendPhoto = (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    console.log(files)
    if(type === 'kitSolar') { 
      if(kitSolar) {
        setKitSolar([...kitSolar, ...files])
      } else {
        setKitSolar(files);
      }
    } else if(type === 'vistoria') { 
      if(vistoria) {
        setVistoria([...vistoria, ...files])
      } else {
        setVistoria(files);
      }
    } else if(type === 'telhas') { 
      if(telhas) {
        setTelhas([...telhas, ...files])
      } else {
        setTelhas(files);
      }
    }
  }

  const deletePhoto = (index, photos, type) => {
    console.log(index);
    if(index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
      // if(newPhotos.length === 0) {
      //   setOpenPhoto(false);
      // }
    // setPhotos({data: newPhotos, type: photos.type});
     switch (type) {
      case 'kitSolar':
        setKitSolar(newPhotos);
        break;
      case 'vistoria':
        setVistoria(newPhotos);
        break;
      case 'telhas':
        setTelhas(newPhotos);
        break;
        default:
     }
    }
  }

  // const viewPhoto = (photo, type) => {
  //   setPhotos({data: photo, type: type});
  //   setOpenPhoto(true);
  // }

  const sendVisit = () => {
    if (kitSolar.length === 0 || vistoria.length === 0 || (visit.kit_info && visit.kit_info.estrutura === 'Cerâmico' && telhas.length === 0)) {
      let validationPhoto;
      if(kitSolar.length === 0) {
        validationPhoto = 'Uma foto do <b> Kit Solar'
      } else if(vistoria.length === 0) {
        validationPhoto = 'o video da <b>Vistoria dos Módulos'
      } else if (visit.kit_info && visit.kit_info.estrutura === 'Cerâmico' && telhas.length === 0) {
        validationPhoto = 'uma foto das <b>Telhas'
      }
      return Swal.fire({
        title: 'Fotos & Vídeos',
        html: `Envie ${validationPhoto}</b> para prosseguir para a próxima etapa.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#111",
        confirmButtonText: "Ok",
      })
    } else {
      Swal.fire({
        title: 'Vistoria',
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#F39200",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let visita = {};
          let ref = {};
          setStep(1);

          try {
            const resultKitSolar = await uploadImages(kitSolar, 'kitSolar', 0);
              visita.kitSolar = resultKitSolar.map(object => object.downloadURL);
              ref.kitSolar = resultKitSolar.map(object => object.fullPath);
              console.log(visita)
              handleChangeCheck(0);

            const resultVistoria = await uploadImages(vistoria, 'vistoria', 1);
              visita.vistoria = resultVistoria.map(object => object.downloadURL);
              ref.vistoria = resultVistoria.map(object => object.fullPath);
              console.log(visita)
              handleChangeCheck(1);

              if (visit.kit_info && visit.kit_info.estrutura === 'Cerâmico') {
                const resultTelhas = await uploadImages(telhas, 'telhas', 2);
                  visita.telhas = resultTelhas.map(object => object.downloadURL);
                  ref.telhas = resultTelhas.map(object => object.fullPath);
                  console.log(visita)
                  handleChangeCheck(2);
              }

              await addDoc(collection(dataBase, 'Visitas', visit, 'Tecnica'), {
               ...visita,
               ref: ref,
               etapa: 6
              }).then(() => {
                 Swal.fire({
                 title: 'Vistoria dos Módulos',
                 html: `A <b>Vistoria</b> foi concluida com sucesso.`,
                 icon: "success",
                 showCloseButton: true,
                 confirmButtonColor: "#F39200",
                 confirmButtonText: "Ok",
                 })
                 closeBox();
         })
          } catch (error) {
            console.error('Ocorreu um erro: ', error);
          }  
          }
        })
    }
  }

  console.log(vistoria)

  return (
    <Dialog
      className={style.dialog}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      sx={{ zIndex: 90 }}
      onClose={() => closeBox()}
    >
      <IconButton
        aria-label="close"
        onClick={() => closeBox()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      ><CloseIcon /></IconButton>
      <DialogTitle align="center">Vistoria</DialogTitle>
      <DialogContent className={style.dialog_content}>
      {step === 0 &&
        <div className={style.register_container}>
           <div className={style.photo_item}>
            {kitSolar && kitSolar.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Envie uma foto ou video do Kit Solar Completo <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Kit_Solar} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 1:</b> Envie uma foto ou video do Kit Solar Completo <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {kitSolar && kitSolar.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                           }
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }} 
                          onClick={() => deletePhoto(index, kitSolar, 'kitSolar')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
              disableElevation 
              component="label" 
              sx={{ textTransform: 'capitalize' }}
              variant="contained" 
              onChange={(e) => sendPhoto(e, 'kitSolar')} 
              startIcon={<AddToPhotosIcon />}>
                Enviar Foto / Video
                <VisuallyHiddenInput multiple={true} type="file" accept="video/*,image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
           <div className={style.photo_item}>
            {vistoria && vistoria.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Grave e envie um video checando se todos os <b>materiais do kit estão OK</b> <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Vistoria} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 2:</b> Grave e envie um video checando se todos os <b>materiais do kit estão OK</b> <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {vistoria && vistoria.map((img, index) => (
                        <div key={index} className={style.photos}>
                          {img.complete.type.includes("image") ? 
                            <img rel="preconnect" src={img.file} alt="" /> : 
                            <video src={img.file} width='90%' controls muted playsInline />
                           }
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, vistoria, 'vistoria')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
                  </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
              disableElevation 
              component="label" 
              sx={{ textTransform: 'capitalize' }}
              variant="contained"
              onChange={(e) => sendPhoto(e, 'vistoria')} 
              startIcon={<AddToPhotosIcon />}>
                Enviar Video
                <VisuallyHiddenInput multiple={true} type="file" accept="video/*,image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
          </div>
          {visit.kit_info && visit.kit_info.estrutura === 'Cerâmico' && 
           <div className={style.photo_item}>
            {telhas && telhas.length === 0 ? 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                <b>Passo 3:</b> Envie uma foto ou mais das <b>30 telhas</b> adicionais do cliente. <br />
                  Exemplo:
                </DialogContentText>
                <img className={style.img_visit} src={Telhas} alt='' /></> : 
              <><DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
                  <b>Passo 3:</b> Envie uma foto ou mais das <b>30 telhas</b> adicionais do cliente. <br />
                </DialogContentText><div className={style.slider_container}>
                    <SlickSlider ref={slider} {...settingsVisit} className={style.slider}>
                      {telhas && telhas.map((img, index) => (
                        <div key={index} className={style.photos}>
                          <img rel="preconnect" src={img.file} alt="" />
                          <p>{img.complete.name}</p>
                          <Button 
                          disableElevation 
                          startIcon={<DeleteForeverIcon />}
                          variant='contained' 
                          color='error' 
                          sx={{ margin: '0.8rem', textTransform: 'capitalize' }}
                          onClick={() => deletePhoto(index, telhas, 'telhas')}>Excluir</Button>
                        </div>
                      ))}
                    </SlickSlider>
            </div></>
            }
            <div className={style.buttons_photo}>
            <ThemeProvider theme={theme2}>
              <Button 
              disableElevation 
              component="label" 
              sx={{ textTransform: 'capitalize' }}
              variant="contained"
              onChange={(e) => sendPhoto(e, 'telhas')} 
              startIcon={<AddToPhotosIcon />}>
                Enviar Foto
                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg" />
              </Button>
            </ThemeProvider>
          </div>
            </div>
          }
        </div>
      }
        {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li key={index}>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} /> 
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>}
      </DialogContent>
      <ThemeProvider theme={theme2}>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <Button 
           disableElevation
           variant='contained'
           sx={{ textTransform: 'capitalize' }}
           disabled={step > 0 ? true: false} 
           onClick={() => closeBox()}>Fechar</Button>
          <Button 
           disableElevation
           variant='contained'
           sx={{ textTransform: 'capitalize' }}
           disabled={step > 0 ? true: false} 
           color='success' 
           onClick={() => sendVisit()}>Enviar</Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    /* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div key={index} className={style.photo_card}>
              {photo.complete.type !== 'image/jpeg' && photo.complete.type !== 'image/png' && photo.complete.type !== 'image/jpg' ? 
              <video src={photo.file} width='100%' controls muted playsInline>
              </video>
              :
              <img src={photo.file} alt='' />
            }
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button 
            disableElevation 
            variant='contained' 
            onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */
  )
}

export default InstallStep2
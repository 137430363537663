import React from 'react';
import { addDoc, collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { memo, useEffect, useRef, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from "moment";
import "moment/locale/pt-br";
import CloseIcon from '@mui/icons-material/Close';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import { idPaulo, KeyMaps } from "../../../data/Data";
import { dataBase } from "../../../firebase/database";

import styles from './styles.module.scss';
import { Autocomplete, Box, Button, createFilterOptions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, ListItemIcon, ListItemText, Step, StepLabel, Stepper, TextField } from "@mui/material";
import AutocompleteMaps from "../../../components/AutoCompleteMaps";
import AudioRecorder from "../../../components/AudioRecorder/Index";
import NewTooltip from "../../../components/Tooltip";
import { VisuallyHiddenInput } from "../../../components/InputFile";
import { ThemeProvider } from "@emotion/react";
import { theme, themeInput } from "../../../data/theme";
import { AutoSizer, List } from "react-virtualized";
import { formatPhoneBR } from '../../../functions/FormatPhoneBR';
import { Link } from 'react-router-dom';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import sortData from '../../../functions/sortData';
import LoadingButton from '@mui/lab/LoadingButton';
import useUploadPhotos from '../../../hooks/useUploadPhotos';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import useViewDocBox from '../../../hooks/useViewDocBox';

// Configurar a URL do worker PDF

const CreateProposal = ({
  isLoaded,
  leadsAll,
  members,
  view,
  close,
  userRef,
}) => {
  const { uploadImages } = useUploadPhotos();
  const { viewDoc } = useViewDocBox();
  const [responsible, setResponsible] = useState({
    nome: 'Paulo',
    id: ''
  });
  // const [indicator, setIndicator] = useState({
  //   nome: 'Nenhum',
  //   id: '0'
  // });
  // const [indicators, setIndicators] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [leads, setLeads] = useState([]);
  const [audio, setAudio] = useState(null);
  const [assistant, setAssistant] = useState([]);
  const [invoiceGenerator, setInvoiceGenerator] = useState(null);
  const [invoiceBenefit, setInvoiceBenefit] = useState(null);
  const inputRef = useRef(null);
  const [step, setStep] = useState(0);
  const [lead, setLead] = useState({
    cep: '',
    nome: '',
    telefone: '',
    endereco: {
      endereco: '',
      lat: -23.109715809172528,
      lng: -47.71509284910918,
    },
    cidade: '',
    numero: '',
    complemento: '',
    bairro: '',
    estado: 'SP'
  });
  const filter = createFilterOptions();
  const steps = [
    'Dados',
    'Documentos',
    'Anotação'
  ];
  const [open, setOpen] = useState({
    indicator: false,
    leads: false,
    modal: false,
    generator: {
      open: false,
      type: ''
    },
    benefits: {
      open: false,
      type: ''
    },
  });
  const [annotation, setAnnotation] = useState('');
  const [consumption, setConsumption] = useState(null);
  const [loading, setLoading] = useState(false);
  // const loadingIndicator = open.indicator && indicators.length === 0;
  const loadingLeads = open.leads && leads.length === 0;
  const storage = getStorage();

  // Componente de Lista Virtualizada
  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 36;

    return (
      <div ref={ref}>
        <div {...other} style={{ flex: '1 1 auto' }} >
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={200}
                width={width}
                rowHeight={itemSize}
                overscanCount={5}
                rowCount={itemCount}
                rowRenderer={props => {
                  return React.cloneElement(children[props.index], {
                    style: props.style
                  });
                }}
                role={role}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  });

  console.log(leads)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [leadsResult] = await Promise.all([
          axios.get('https://script.google.com/macros/s/AKfycbxad1yCWiFmL9Q2qXIMglIFbH-m9KafsaNoD9UVYrhKgdmdjHpAlbJ-IxeTj-OroUjjsw/exec'),
        ]);

        const leadsData = leadsResult.data.GoogleSheetData.filter(data => data[0] !== 'Data' && data[0].length > 0);
        const leadsOptions = leadsData.reduce((uniqueList, currentData) => {
          const telefone = String(currentData[2]).startsWith('55') ? String(currentData[2]).slice(2) : String(currentData[2]);
          if (!uniqueList.some(item => item.telefone === telefone)) {
            uniqueList.push({
              nome: currentData[1],
              telefone: telefone,
              cidade_ref: currentData[4],
              consultora_ref: currentData[9],
              assistente: currentData[10]
            });
          }
          return uniqueList;
        }, []);

        setLeads(leadsOptions);
        console.log(leadsData)

        // const indicatorData = indicatorResult.data.GoogleSheetData.filter(data => data[0] !== 'Código' && data[1].length > 0);
        // const indicatorOptions = indicatorData.reduce((uniqueList, currentData) => {

        //   if (currentData[0] !== 'Data' && currentData[0].trim() !== '') {
        //     uniqueList.push({
        //       id: currentData[0],
        //       nome: currentData[1],
        //       telefone: currentData[9].replace(/\D/g, ''),
        //       cpf: currentData[4],
        //       cidade: currentData[5],
        //       data: moment(currentData[2]).format('DD/MM/YYYY'),
        //       email: currentData[3],
        //       endereco: currentData[6],
        //       bairro: currentData[7],
        //       pix: currentData[8],
        //     });
        //   }

        //   return uniqueList;
        // }, []);


        // setIndicators([
        //   {
        //     id: '0',
        //     nome: 'Nenhum'
        //   },
        //   ...sortData(indicatorOptions, 'nome'),
        // ]);

      } catch (error) {
        console.error("Erro ao buscar dados", error);
      }
    };

    fetchData();
  }, []);

  console.log(lead)

  useEffect(() => {
    if (members) {
      const docRef = doc(dataBase, 'Roleta', 'Assistente');

      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const dataRef = doc.data();
          setAssistant(members.find(data => data.id === dataRef.id));
        } else {
          console.log('Sem documento!');
        }
      }, (error) => {
        console.log('ERRO: ', error);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [members]);

  console.log(assistant)

  useEffect(() => {
    if (members) {
      const filterResponsibles = members.filter((member) => (member.cargo === 'Vendedor(a)' && !member.desativado) || member.nome === 'CORPBRASIL')
      // const filterRepresentatives = members.filter((member) => member.cargo === 'Representante')

      const responsiblesRef = filterResponsibles.map((member) => {
        return {
          nome: member.nome === 'Pós-Venda' ? member.nome_ref : member.nome,
          id: member.id,
          img: member.photo?.url
        }
      }).sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      })

      const responsibleSellerMaster = responsiblesRef.filter((data) => data.id === idPaulo);
      setResponsibles(responsibleSellerMaster);
      setResponsible(responsibleSellerMaster[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members])

  const checkLead = (phone) => {
    return leadsAll.find(data => data.telefone === phone)
  }

  console.log(lead);

  const checkStep = (stepRef) => {
    if (step === 0) {
      if (!lead.endereco.endereco || !lead.telefone || lead?.nome.length < 3) {
        return true
      } else {
        return false
      }
    } else {
      console.log(consumption)
      if ((open.generator.type === 'doc' && !invoiceGenerator) ||
        (open.benefits.type === 'doc' && !invoiceBenefit) || !audio ||
        (open.generator.type === 'input' && consumption?.geradora?.length < 2) ||
        (open.benefits.type === 'input' && consumption?.beneficiaria?.length < 2)) {
        console.log('NENHUM')
        return true;
      } return false
    }
  }

   const handleUpload = async (id) => {

    if (!audio) {
      // alert("Por favor, selecione um arquivo de áudio e uma foto primeiro!");
      return;
    }

    const audioRef = ref(storage, `Clientes/${id}/Mensagem-${Date.now()}.webm`);

    try {
      // Upload do áudio
      const audioSnapshot = await uploadBytes(audioRef, audio.complete);
      const audioURL = await getDownloadURL(audioSnapshot.ref);

      console.log('Áudio enviado com sucesso! URL:', audioURL);

      const filesRef = {};

      if (audio) {
        filesRef.mensagemVoz = {
          url: audioURL,
          ref: audioSnapshot.ref.fullPath
        };
      }

      return filesRef;

    } catch (error) {
      console.error('Erro ao enviar arquivos:', error);
    }
  };

  const onSubmit = async (e) => {

    e.preventDefault();

    if (step < 2) {
      return setStep((prev) => (prev + 1));
    } else if (step === 2) {
      console.log({
        ...lead,
        consultora_uid: responsible.id,
        consultora: responsible.nome,
        createAt: new Date(),
        data: moment().format('DD MMM YYYY - HH:mm'),
        dataRef: moment().format('DD MMM YYYY - HH:mm'),
        assistente: members.find(data => data.nome === lead.assistente)?.id || '',
        proposta: {
          fatura: {
            geradora: open.generator.type === 'input' ? {
              valor: consumption.geradora
            } : 
              invoiceGenerator ? {...invoiceGenerator} : {}
            ,
            beneficiaria: open.benefits.type === 'input' ? {
              valor: consumption.beneficiaria
            } : invoiceBenefit ? {...invoiceBenefit} : {}
          }
        },
        historico: [{
          data: moment().format('DD/MM/YYYY - HH:mm'),
          evento: 'Cliente Criado',
          text: `O Cliente foi criado pelo metódo <b>Gerar Proposta Comercial</b>.`,
          usuario_id: userRef?.id,
          usuario_nome: userRef?.nome
        }],
        anotacao: [{
          data: `[${moment(new Date()).format('DD/MM/YYYY')} às ${moment(new Date()).format('HH:mm')}] ${userRef && userRef.nome}:`,
          dataCreat: new Date(),
          text: annotation,
          uid: userRef?.uid
        }]
      })
      try {
        const resultAlert = await Swal.fire({
          title: 'Atenção',
          html: `Você deseja gerar uma nova <b>Proposta Comercial?</b>`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })

        if (resultAlert.isConfirmed) {
          // let telefoneFormatado = ''.replace(/\D/g, '');
        setLoading(true);

         const resultDoc = await addDoc(collection(dataBase, 'Clientes'), {
            ...lead,
            consultora_uid: responsible.id,
            consultora: responsible.nome,
            createAt: new Date(),
            data: moment().format('DD MMM YYYY - HH:mm'),
            dataRef: moment().format('DD MMM YYYY - HH:mm'),
            assistente: members.find(data => data.nome === lead.assistente)?.id || assistant.id,
            historico: [{
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Cliente Criado',
              text: `O Cliente foi criado pelo método <b>Gerar Proposta Comercial</b>.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome
            }],
            anotacao: !annotation ? [] : [{
              data: `[${moment(new Date()).format('DD/MM/YYYY')} às ${moment(new Date()).format('HH:mm')}] ${userRef && userRef.nome}:`,
              dataCreat: new Date(),
              text: annotation,
              uid: userRef?.uid
            }]
          })

          let file = {
            geradora: {
              url: [],
              ref: []
            },
            beneficiaria: {
              url: [],
              ref: []
            }
          };

          const resultGeradora = invoiceGenerator ? await uploadImages(invoiceGenerator, 'geradora', 0, resultDoc.id, 'Clientes') : null;
          if(invoiceGenerator) {
            file.geradora.url = resultGeradora.map(object => object.downloadURL);
            file.geradora.ref = resultGeradora.map(object => object.fullPath);
          }

          const resultBeneficiaria = invoiceBenefit ? await uploadImages(invoiceBenefit, 'beneficiaria', 1, resultDoc.id, 'Clientes') : null;
          if(resultBeneficiaria) {
            file.beneficiaria.url = resultBeneficiaria.map(object => object.downloadURL);
            file.beneficiaria.ref = resultBeneficiaria.map(object => object.fullPath);
          }

          const audioDoc = await handleUpload(resultDoc.id);

          const newAssistant = {
            escolhida: assistant.escolhida === 'Erika' ? 'Lara' : 'Erika',
            id: assistant.escolhida === 'Erika' ? 'kYpEI7u8f4TlQuqPcHUXdAFWw0c2' : '7z33h3zwBjdlmBgwDHOxtSA9Hjz1'
          }

          await updateDoc(doc(dataBase, 'Roleta', 'Assistente'), newAssistant);

          await updateDoc(doc(dataBase, 'Clientes', resultDoc.id), {
            proposta: {
              fatura: {
                geradora: open.generator.type === 'input' ? {
                  valor: consumption.geradora
                } : 
                  invoiceGenerator ? {...file.geradora} : {}
                ,
                beneficiaria: open.benefits.type === 'input' ? {
                  valor: consumption.beneficiaria
                } : 
                  invoiceBenefit ? {...file.beneficiaria} : {}
              },
              ...audioDoc
            },
          })

          setLoading(false);

          await Swal.fire({
            title: 'Proposta Comercial',
            html: `A Proposta foi gerada com sucesso!`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          });

          close();

          // axios.post('https://n8n.corpbrasil.cloud/webhook/7cfc8217-7c93-4959-afaf-8292eb7884ac', {
          //   ...userData,
          //   telefone: '55' + telefoneFormatado,
          //   consultora: userRef.nome,
          //   status: 'Ativo',
          //   ID: result.id
          // })

        }
      } catch (error) {
        console.log('ERRO: ', error);
      }
    }

  };

  console.log(assistant)

  const sendAudio = (e) => {
    const audio = e.target.files[0];
    setAudio({ file: URL.createObjectURL(audio), complete: audio });
  }

  const sendPhoto = async (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) => files.push({ file: URL.createObjectURL(image), complete: image }));
    if (type === 'geradora') {
      if (invoiceGenerator) {

        setInvoiceGenerator([...invoiceGenerator, ...files])
      } else {
        setInvoiceGenerator(files);
      }
    } else if (type === 'beneficiaria') {
      if (invoiceBenefit) {
        setInvoiceBenefit([...invoiceBenefit, ...files])
      } else {
        setInvoiceBenefit(files);
      }
    }
  }

  const deletePhoto = (index, photos, type) => {

    if (index >= 0 && index < photos.length) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);

      console.log("🚀 ~ deletePhoto ~ type:", newPhotos)
      switch (type) {
        case 'geradora':
          setInvoiceGenerator(newPhotos);
          break;
        case 'beneficiaria':
          setInvoiceBenefit(newPhotos);
          break;
        default:
      }
    }
  }

  const checkInputs = () => {
    const { telefone, nome } = lead;
    const { endereco } = lead.endereco;
    const isNomeValid = nome.length > 2;
    const isTelefoneValid = telefone.length > 10 && !telefone.includes('_');
    const isEnderecoValid = endereco.length > 0;

    const areAllInputsValid = isNomeValid && isTelefoneValid && isEnderecoValid && !checkLead(lead?.telefone);
    // const areAllInputsValid = isEnderecoValid;

    if (areAllInputsValid) {
      return true;
    }

    return false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLead((prevState) => ({ ...prevState, [name]: value }));
  }

  const detailsLead = async (leadRef) => {
    return Swal.fire({
      title: 'Detalhes do Cliente',
      html: `Cliente: <b>${leadRef.nome}</b></br>` +
        `Telefone: <b>${leadRef.telefone}</b></br>` +
        `Consultor(a): <b>${leadRef.consultora}</b></br>` +
        `Cidade: <b>${leadRef.cidade}</b></br></br>`,
      icon: 'info',
      showCloseButton: true,
      confirmButtonColor: '#000',
      confirmButtonText: 'Fechar',
    })
  }

  const backInvoice = (type) => {
    if (type === 'geradora') {
      setOpen({ ...open, generator: { open: false, type: '' } });
    } else {
      setOpen({ ...open, benefits: { open: false, type: '' } });
    }
  }

  const checkDoc = (doc) => {
    if (doc === 'generator') {
      if ((invoiceGenerator && open.generator.type === 'doc') || (consumption?.geradora?.length > 1 && open.generator.type === 'input')) {
        return true
      } return false
    } else {
      if ((invoiceBenefit && open.benefits.type === 'doc') || (consumption?.beneficiaria?.length > 1 && open.benefits.type === 'input')) {
        return true
      } return false
    }
  }

  return (
    <div className="box-visit">
      <Dialog
        className={styles.dialog}
        sx={{ overflow: 'hidden' }}
        open={view}
        fullWidth
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align="center">Gerar Proposta Comercial</DialogTitle>
        <form onSubmit={onSubmit} autoComplete="off">
          <DialogContent sx={{ overflow: 'auto' }}>
            <Stepper sx={{ width: '100%', margin: '1rem 0 1.5rem 0' }} activeStep={step} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {step === 0 &&
              <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
                Preencha os campos obrigatórios <b>(*)</b> abaixo para gerar uma nova <b>Proposta Comercial</b>.
              </DialogContentText>}
            {step === 1 &&
              <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
                O envio dos documentos <b>não é obrigatório</b>, porém facilita a geração da proposta.
              </DialogContentText>}
            {step === 2 &&
              <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
                Caso preferir, deixe uma anotação a respeito da <b>Proposta Comercial</b>.
              </DialogContentText>}
            <Grid container spacing={0} columnSpacing={1}>
              {step === 0 &&
                <>
                  {/* <Grid item xs={12} alignItems={'center'}>
                    <Alert icon={<img style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={assistant?.photo.url} alt='' />}>
                      <AlertTitle sx={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}><InfoRoundedIcon sx={{ fontSize: '18px' }} /> O Lead já possui uma assistente definida.</AlertTitle>
                      <p><b>Nome:</b> {assistant?.nome} <b>Telefone:</b> {assistant?.telefone}</p>
                    </Alert>
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={responsible}
                      sx={{ marginTop: '0.55rem' }}
                      disabled={userRef && userRef.cargo === 'Vendedor(a)' && !userRef.time ? true : false}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setResponsible({
                            nome: newValue.nome,
                            id: newValue.id,
                            img: newValue.img
                          });
                        } else {
                          // Caso seja necessário lidar com a limpeza do campo
                          setResponsible({});
                        }
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      fullWidth
                      disableClearable={true}
                      id="free-solo-with-text-demo"
                      options={responsibles}
                      getOptionLabel={(option) => option.nome}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <ListItemIcon>
                            <img src={option.img} alt={option.nome} style={{ width: 24, height: 24, borderRadius: '50%' }} />
                          </ListItemIcon>
                          <ListItemText primary={option.nome} />
                        </li>
                      )}
                      noOptionsText="Responsável não encontrado"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Responsável"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: responsible.img && (
                              <InputAdornment position="start">
                                <img src={responsible.img} alt={responsible.nome} style={{ width: 24, height: 24, borderRadius: '50%' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={indicator}
                      loading={loadingIndicator}
                      open={open.indicator}
                      onOpen={() => {
                        setOpen({ ...open, indicator: true });
                      }}
                      onClose={() => {
                        setOpen({ ...open, indicator: false });
                      }}
                      loadingText="Carregando..."
                      sx={{ marginTop: '0.55rem' }}
                      // disabled={userRef && userRef.cargo === 'Vendedor(a)' && !userRef.time ? true : false}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setIndicator(newValue);
                        } else {
                          // Caso seja necessário lidar com a limpeza do campo
                          setIndicator({});
                        }
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      fullWidth
                      disableClearable={true}
                      id="free-solo-with-text-demo"
                      options={indicators}
                      getOptionLabel={(option) => option.nome}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <ListItemText primary={option.nome} />
                        </li>
                      )}
                      noOptionsText="Indicador não encontrado"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Indicador"
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </Grid> */}
                  {/* {indicator?.nome !== 'Nenhum' ?
                    <Grid item xs={12} md={12} sx={{ margin: '0.5rem 0' }} >
                      <Alert severity="info">
                        <AlertTitle>Dados do Indicador</AlertTitle>
                        <p><strong>Nome:</strong> {indicator.nome} <strong>({indicator.id})</strong></p>
                        <p><strong>Cidade:</strong> {indicator.cidade}</p>
                      </Alert>
                    </Grid> : null
                  } */}
                  <ThemeProvider theme={themeInput}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={lead}
                        sx={{ marginTop: '0.55rem' }}
                        disableListWrap
                        open={open.leads}
                        onOpen={() => {
                          setOpen({ ...open, leads: true });
                        }}
                        onClose={() => {
                          setOpen({ ...open, leads: false });
                        }}
                        ListboxComponent={ListboxComponent}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            setLead((prev) => ({ ...prev, telefone: newValue }));

                          } else if (newValue && newValue.inputValue) {
                            setLead((prev) => ({ ...prev, telefone: newValue.inputValue }));
                          } else {
                            setLead((prev) => ({ ...prev, ...newValue }));
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.telefone);

                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              inputValue,
                              nome: `Adicionar "${inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        clearOnBlur
                        handleHomeEndKeys
                        fullWidth
                        loading={loadingLeads}
                        loadingText='Carregando...'
                        disableClearable={true}
                        id="free-solo-with-text-demo"
                        options={leads}
                        noOptionsText="Lead não encontrado"
                        getOptionLabel={(option) => option.telefone ? option.telefone : ''}
                        renderOption={(props, option) =>
                          <li {...props}>
                            {option.telefone ? `${formatPhoneBR(option.telefone)} - ${option.nome}(${option.cidade_ref})` : option.nome}

                          </li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Telefone (Somente Número)"
                            type="number"
                            InputProps={{ // Não funcionou
                              ...params.InputProps,
                              inputProps: {
                                ...params.inputProps,
                                maxLength: 11
                              }
                            }}
                            AutoComplete='no-password'
                            error={checkLead(lead?.telefone)}
                            helperText={
                              checkLead(lead?.telefone) ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
                                  <InfoRoundedIcon sx={{ fontSize: '15px' }} /><strong>Telefone já cadastrado.</strong> Veja mais detalhes {' '}
                                  <b><Link style={{ color: "red" }} to="" onClick={() => detailsLead(checkLead(lead?.telefone))}>aqui</Link></b>
                                </Box>
                              ) : ''
                            }
                          />
                        )}
                        isOptionEqualToValue={(option, value) => option.telefone === value.telefone}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Nome Completo"
                        type="text"
                        name="nome"
                        required
                        value={lead?.nome ?? ''}
                        onChange={handleChange}
                        variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {isLoaded && view &&
                        <AutocompleteMaps KeyMaps={KeyMaps} address={lead?.endereco?.endereco} setCustomer={setLead} title='Endereço' />
                      }
                    </Grid>
                  </ThemeProvider>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Empresa"
                      type="text"
                      name="empresa"
                      value={lead?.empresa ?? ''}
                      onChange={handleChange}
                      variant="outlined" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Email"
                      type="email"
                      name="email"
                      value={lead?.email ?? ''}
                      onChange={handleChange}
                      variant="outlined" />
                  </Grid></>}
              {step === 1 && <>
                <ThemeProvider theme={theme}>
                  <Grid item sm={12}>
                    <div className={`${styles.extra_container_item} ${checkDoc('generator') ? styles.confirm_color : ''}`}>
                      <ThemeProvider theme={theme}>
                        <div className={styles.extra_container_item_title}>
                          {open.generator.open &&
                            <NewTooltip title='Voltar' placement='top'>
                              <IconButton sx={{ position: 'absolute', left: 0 }} onClick={() => backInvoice('geradora')}>
                                <KeyboardReturnIcon />
                              </IconButton>
                            </NewTooltip>
                          }
                          <h3>Consumo da Geradora {checkDoc('generator') ? ' ✅' : null}</h3>
                          {open.generator.type === 'doc' &&
                            <Box sx={{ position: 'absolute', display: 'flex', justifyContent: 'center', right: 0 }}>
                              <Button
                                disableElevation
                                sx={{ textTransform: 'capitalize' }}
                                component="label"
                                variant="contained"
                                size='small'
                                onChange={(e) => sendPhoto(e, 'geradora')}
                                startIcon={<AddToPhotosIcon />}>
                                Enviar Imagem / PDF
                                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                              </Button>
                            </Box>
                          }
                        </div>
                        {open.generator.open ?
                          open.generator.type === 'doc' ?
                            invoiceGenerator?.map((invoice, index) => (
                              <div key={index} className={styles.extra_container_item_file}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }} onClick={() => viewDoc(invoice)}>
                                  <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                  <Link to='' style={{ color: "#000" }}>{invoice?.complete?.name}</Link>
                                </Box>
                                <NewTooltip id="Clickable" title='Excluir' placement='top'>
                                  <IconButton className='clickable' color='error' onClick={() => deletePhoto(index, invoiceGenerator, 'geradora')}>
                                    <DeleteRounded />
                                  </IconButton>
                                </NewTooltip>
                              </div>
                            )) :
                            <TextField
                              label="Digite o consumo médio"
                              sx={{ backgroundColor: "#fff" }}
                              InputProps={{
                                endAdornment: <InputAdornment position="start">kWh</InputAdornment>,
                                inputProps: { style: { textAlign: 'center' } }
                              }}
                              type='number'
                              value={consumption?.geradora ?? ''}
                              onChange={(e) => setConsumption({ ...consumption, geradora: e.target.value })}
                            /> :
                          <div>
                            <p>Você possui a <b>imagem</b> ou <b>PDF</b> da fatura?</p>
                            <Box sx={{ display: 'flex', gap: '0.5rem', margin: '0.5rem', justifyContent: 'center' }}>
                              <Button
                                disableElevation
                                variant='contained'
                                color='success'
                                onClick={() => setOpen({ ...open, generator: { open: true, type: 'doc' } })}
                                sx={{ textTransform: 'capitalize' }}>
                                Sim
                              </Button>
                              <Button
                                disableElevation
                                variant='contained'
                                onClick={() => setOpen({ ...open, generator: { open: true, type: 'input' } })}
                                sx={{ textTransform: 'capitalize' }}>
                                Não
                              </Button>
                            </Box>
                          </div>
                        }
                      </ThemeProvider>
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={`${styles.extra_container_item} ${checkDoc('') ? styles.confirm_color : ''}`}>
                      <ThemeProvider theme={theme}>
                        <div className={styles.extra_container_item_title}>
                          {open.benefits.open &&
                            <NewTooltip title='Voltar' placement='top'>
                              <IconButton sx={{ position: 'absolute', left: 0 }} onClick={() => backInvoice('beneficiaria')}>
                                <KeyboardReturnIcon />
                              </IconButton>
                            </NewTooltip>
                          }
                          <h3>Consumo da Beneficiária{checkDoc('') ? ' ✅' : null}</h3>
                          {open.benefits.type === 'doc' &&
                            <Box sx={{ position: 'absolute', display: 'flex', justifyContent: 'center', right: 0 }}>
                              <Button
                                disableElevation
                                sx={{ textTransform: 'capitalize' }}
                                component="label"
                                variant="contained"
                                size='small'
                                onChange={(e) => sendPhoto(e, 'beneficiaria')}
                                startIcon={<AddToPhotosIcon />}>
                                Enviar Imagem / PDF
                                <VisuallyHiddenInput multiple={true} type="file" accept="image/png,image/jpeg, application/pdf" />
                              </Button>
                            </Box>}
                        </div>
                      </ThemeProvider>
                      {open.benefits.open ?
                        open.benefits.type === 'doc' ?
                          invoiceBenefit?.map((invoice, index) => (
                            <div key={index} className={styles.extra_container_item_file}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }} onClick={() => viewDoc(invoice)}>
                                <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                                <Link to='' style={{ color: "#000" }}>{invoice?.complete?.name}</Link>
                              </Box>
                              <NewTooltip id="Clickable" title='Excluir' placement='top'>
                                <IconButton className='clickable' color='error' onClick={() => deletePhoto(index, invoiceBenefit, 'beneficiaria')}>
                                  <DeleteRounded />
                                </IconButton>
                              </NewTooltip>
                            </div>
                          )) :
                          <TextField
                            label="Digite o consumo médio"
                            sx={{ backgroundColor: "#fff" }}
                            type='number'
                            InputProps={{
                              endAdornment: <InputAdornment position="start">kWh</InputAdornment>,
                              inputProps: { style: { textAlign: 'center' } }
                            }}
                            value={consumption?.beneficiaria ?? ''}
                            onChange={(e) => setConsumption({ ...consumption, beneficiaria: e.target.value })}
                          /> :
                        <div>
                          <p>Você possui a <b>imagem</b> ou <b>PDF</b> da fatura?</p>
                          <Box sx={{ display: 'flex', gap: '0.5rem', margin: '0.5rem', justifyContent: 'center' }}>
                            <Button
                              disableElevation
                              variant='contained'
                              color='success'
                              onClick={() => setOpen({ ...open, benefits: { open: true, type: 'doc' } })}
                              sx={{ textTransform: 'capitalize' }}>
                              Sim
                            </Button>
                            <Button
                              disableElevation
                              variant='contained'
                              onClick={() => setOpen({ ...open, benefits: { open: true, type: 'input' } })}
                              sx={{ textTransform: 'capitalize' }}>
                              Não
                            </Button>
                          </Box>
                        </div>
                      }
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={`${styles.extra_container_item} ${audio ? styles.confirm_color : ''}`}>
                      <Box sx={{ textAlign: 'center' }}>
                        <h3>Áudio{audio ? ' ✅' : null}</h3>
                        <p>Envie ou grave um áudio para complementar a <b>Proposta Comercial</b>.</p>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <AudioRecorder setAudio={setAudio} audio={audio} disabled={false} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                          <Button
                            disableElevation
                            sx={{ textTransform: 'capitalize' }}
                            component="label"
                            variant="contained"
                            onChange={(e) => sendAudio(e)}
                            startIcon={<AudioFileIcon />}>
                            Enviar Áudio
                            <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                </ThemeProvider>
              </>
              }
              {step === 2 &&
                <ThemeProvider theme={theme}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      fullWidth
                      rows={4}
                      sx={{ backgroundColor: "#fff4e5", marginTop: '1rem' }}
                      label='Anotação'
                      value={annotation}
                      onChange={(e) => setAnnotation(e.target.value)}
                      variant='outlined' />
                  </Grid>
                </ThemeProvider>
              }
            </Grid>
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', margin: '1rem 0' }}>
            <ThemeProvider theme={theme}>
              <NewTooltip title={!checkInputs() && step === 0 ? 'Preencha os campos obrigatórios(*) para continuar' : ''} placement='top'>
                <span>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    loading={loading}
                    disabled={checkStep()}
                    sx={{ textTransform: 'capitalize' }}
                    onClick={onSubmit}
                    type="submit"
                  >{step === 2 ? 'Gerar' : 'Continuar'}</LoadingButton>
                </span>
              </NewTooltip>
              <Button
                disableElevation
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                onClick={step === 0 ? () => close() : () => setStep((prev) => (prev - 1))}>
                {step === 0 ? 'Cancelar' : 'Voltar'}
              </Button>
            </ThemeProvider>
          </DialogActions>
        </form>
      </Dialog>
      {/* <div className="box-visit__box">
        <div className="box-visit__close">
          <button onClick={returnPage} className="btn-close" />
        </div>
        <h4>Cadastrar Lead</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="box-visit__container">
            <span className="box-visit__notice">Campo com * é obrigatório</span>
            <div className="box-visit__form">
              <label className="label">
                <p>Responsável *</p>
                <input
                  className="label__input"
                  type="text"
                  placeholder="Digite o nome"
                  autoComplete="off"
                  required
                  {...register("nome")} />
              </label>
              <label className="label">
                <p>Empresa *</p>
                <input
                  className="label__input"
                  type="text"
                  placeholder="Digite o nome da empresa"
                  autoComplete="off"
                  required
                  {...register("empresa")} />
              </label>
              <label className="label">
                <p>Cidade *</p>
                <input
                  className="label__input"
                  type="text"
                  placeholder="Digite a cidade"
                  autoComplete="off"
                  required
                  {...register("cidade")} />
              </label>
              <label className="label">
                <p>Telefone</p>
                <PatternFormat
                  className="label__input"
                  value={celular || ''}
                  onChange={(e) => setCelular(e.target.value)}
                  format="(##) ##### ####"
                  mask="_"
                  placeholder="(DD) 00000-0000"
                  label="Celular"
                  variant="outlined"
                  color="primary"
                  required />
              </label>
              <label className="label">
                <p>Email</p>
                <input
                  className="label__input"
                  type="text"
                  placeholder="Digite o email"
                  autoComplete="off"
                  {...register("email")} />
              </label>
              <label className="label">
                <p>Consumo de Energia</p>
                <input
                  className="label__input"
                  type="text"
                  placeholder="Digite o consumo de energia"
                  autoComplete="off"
                  {...register("consumo")} />
              </label>
              <label className="label-max">
                <p>Anotação</p>
                <textarea
                  className="label__textarea"
                  type="text"
                  placeholder="Digite uma observação"
                  autoComplete="off"
                  {...register("anotacao")} />
              </label>
            </div>
          </div>
          <input className="box-visit__btn" type="submit" value="CRIAR" />
        </form>
      </div> */}
    </div>
  );
};

export default memo(CreateProposal);

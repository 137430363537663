import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Company } from '../data/Data';
import { dataBase } from '../firebase/database';
import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useCadastro } from '../contexts/CadastroContext';
import { useState } from 'react';
import useAuth from './useAuth';

function useVisit(scheduleRef, members) {
  const navigate = useNavigate();
  const { atualizarCadastro } = useCadastro();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  function getMonthlyWeekNumber(dt) {
    // como função interna, permite reuso
    var getmonweek = function (myDate) {
      var today = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate(), 0, 0, 0);
      var first_of_month = new Date(myDate.getFullYear(), myDate.getMonth(), 1, 0, 0, 0);
      var p = Math.floor((today.getTime() - first_of_month.getTime()) / 1000 / 60 / 60 / 24 / 7);
      // ajuste de contagem
      if (today.getDay() < first_of_month.getDay()) ++p;
      // ISO 8601.
      if (first_of_month.getDay() <= 3) p++;
      return p;
    }
    // último dia do mês
    var udm = (new Date(dt.getFullYear(), dt.getMonth() + 1, 0, 0, 0, 0)).getDate();
    /*  Nos seis primeiros dias de um mês: verifica se estamos antes do primeiro Domingo.
     *  Caso positivo, usa o último dia do mês anterior para o cálculo.
     */
    if ((dt.getDate() < 7) && ((dt.getDate() - dt.getDay()) < -2))
      return getmonweek(new Date(dt.getFullYear(), dt.getMonth(), 0));
    /*  Nos seis últimos dias de um mês: verifica se estamos dentro ou depois do último Domingo.
     *  Caso positivo, retorna 1 "de pronto".
     */
    else if ((dt.getDate() > (udm - 6)) && ((dt.getDate() - dt.getDay()) > (udm - 3)))
      return 1;
    else
      return getmonweek(dt);
  }


  const createVisit = async (data, proposta) => {
    try {
      setLoading(true);
      const date = new Date(data.data);
      let props = {
        ...data,
        jornada: {
          etapa: 'Proposta Comercial',
          index: 0,
          sigla: 'PC',
          cor: '#dcdbad',
          data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
        }
      }

      console.log(data)

      // if(data.indicador.nome !== 'Nenhum') {
      //   props.propostas = [
      //     ...proposta
      //   ]
      // }

      // console.log(props);

      const result = await addDoc(scheduleRef, props);

      // if(proposta) { Antigo metodo de adicionar Comissão
      //   const propostaAtive = proposta.find(data => data.ativo === true);
      //   if(data.indicador.nome !== 'Nenhum' && propostaAtive) {
      //     await setDoc(doc(dataBase, "Comissoes", result.id), {
      //       ...propostaAtive,
      //       representante: {
      //         ...data.indicador
      //       },
      //       backoffice_id: data.uid,
      //       nome: data.cliente,
      //       visita_data: data.dia,
      //       etapa: 'Faturamento Pendente',
      //       data: moment().format('DD/MM/YYYY'),
      //       createAt: serverTimestamp()
      //     })
      //   }
      // }

      if(props.cliente_id) {
        console.log('existe')
        const resultCustomer = await getDoc(doc(dataBase, 'Clientes', props.cliente_id));
        if(resultCustomer.exists()) {
          const customerData = resultCustomer.data();
          console.log(customerData)
          await updateDoc(doc(dataBase, 'Clientes', props.cliente_id), {
            historico: [...customerData.historico, {
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Visita Criada',
              visita: props.categoria,
              text: `Foi criado uma <a href=https://corpbrasil.app/visitas/${result.id} rel="noreferrer" target="_blank">visita</a> no dia <b>${moment(props.dia).format('DD/MM/YYYY')}</b> às <b>${props.chegadaCliente}</b> em <b>${props.cidade}</b>.`,
              usuario_id: user?.id,
              usuario_nome: user?.nome
            }]
          })
        }
      }

      await axios.post('https://n8n.corpbrasil.cloud/webhook/dfbbb99b-1721-4a7d-8ac0-f95335b15aa7', {
        data: moment(data.data).format("DD/MM/YYYY"),
        ID: result.id,
        nome: data.tecnico,
        cliente: data.cliente,
        saida: data.saidaEmpresa,
        marcado: data.chegadaCliente,
        consultora: data.consultora,
        telefone: data.preData ? data.preData.telefone : '',
        city: data.cidade,
        duracao: data.visita,
        saidaCliente: data.saidaDoCliente,
        semana: getMonthlyWeekNumber(date),
        mes: moment(data.data).format("M"),
        ende: data.endereco,
        confirmada: 'Não',
        categoria: data.categoria,
        extra: data.preData
      });

      await members && members.map(async (member) => {
        if (member.nome !== data.consultora && data.categoria !== 'lunch') {
          await addDoc(collection(dataBase, "Membros", member.id, 'Notificacao'), {
            createAt: serverTimestamp(),
            type: 'Visita',
            data: moment().format('YYYY-MM-DD'),
            text: `<b>${data.consultora}</b> criou uma <b>Visita</b> para o dia <b>${moment(data.data).format("DD/MM/YYYY")}</b> ás <b>${data.chegadaCliente}</b>. <a href="/agenda" rel="noreferrer">Clique aqui</a> para conferir ou acesse a página de visitas.`
          })
        }
      })

      await axios.post('https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/45898/Z8sTf2Pi46I1/', {
        data: moment(data.data).format("DD/MM/YYYY"),
        nome: data.tecnico,
        cliente: data.cliente,
        endereco: data.endereco.length < 3 ? data.cidade : data.endereco,
        saida: data.saidaEmpresa,
        marcado: data.chegadaCliente,
        chegada: data.chegadaEmpresa,
        tipo: data.tipo,
        consultora: data.consultora,
        telefone: "5515992661213",
        lat: data.lat,
        lng: data.lng,
        duracao: data.visita,
        saidaCliente: data.saidaDoCliente,
        categoria: data.categoria
      })

      setLoading(false);

      const response = await Swal.fire({
        title: Company,
        html: `A visita em <b>${data.cidade}</b> foi criada com sucesso!`,
        icon: "success",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      })


      if (response) {
        navigate('/visitas')
      }

      atualizarCadastro(null);

    } catch (e) {
      setLoading(false);
      console.error('ERRO: ', e)
    }
  }

  return {
    createVisit,
    loading
  }
}

export default useVisit

import IconButton from '@mui/material/IconButton';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import styles from '../styles.module.scss';
import { useEffect, useState } from 'react';
import { deleteField, doc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import moment from 'moment';
import { Button, ThemeProvider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { theme } from '../../../data/theme';
import parse from 'html-react-parser';
import axios from 'axios';
import Agreed from '../Components/Agreed';
import Invoice from '../Components/Invoice';
import FileViewer from '../../../hooks/useFileViewer';
import Voucher from '../Components/Voucher';
import NewTooltip from '../../../components/Tooltip';

const ComissionFull = ({ userRef }) => {
  const { idComission } = useParams();
  const [comission, setComission] = useState({});
  const [open, setOpen] = useState(false);
  const [representative, setRepresentative] = useState({});
  const [customer, setCustomer] = useState({});
  const [backOffice, setBackOffice] = useState({});

  const journey = ['Faturamento Pendente', 'Venda Concluida', 'Comissão Paga'];


useEffect(() => {
  const fetchData = async() => {
    onSnapshot(doc(dataBase, "Comissoes", idComission), (doc) => {
      setComission({...doc.data(), id: doc.id});
    });
  }

  fetchData();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

useEffect(() => {
  if(comission && comission.representante && comission.representante.id) {
    const fetchData = async() => {
      onSnapshot(doc(dataBase, "Membros", comission && comission.representante.id), (doc) => {
        setRepresentative(doc.data());
      });
      onSnapshot(doc(dataBase, "Clientes", idComission), (doc) => {
        setCustomer({...doc.data(), id: doc.id});
      });
      onSnapshot(doc(dataBase, "Membros", comission && comission.backoffice_id), (doc) => {
        setBackOffice({...doc.data(), id: doc.id});
      });
    }
  
    fetchData();
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[comission])

console.log(representative)

const formatarTelefone = (telefone) => {
  // Adiciona os parênteses, traços e espaços conforme o formato do número de telefone
  return telefone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '$1 ($2) $3-$4');
}

// const confirmAnalyse = async (text = '') => {
//   try {
//     console.log(text);
//     const { value: textSwal } = await Swal.fire({
//       title: 'Análise da Comissão',
//       input: 'textarea',
//       html: 'Digite abaixo sua análise sobre o pagamento da comissão',
//       inputPlaceholder: 'Digite aqui...',
//       inputValue: text,
//       inputAttributes: {
//         'aria-label': 'Digite aqui'
//       },
//       inputValidator: (value) => {
//         if (!value.trim()) {
//           return 'Por favor, digite o motivo para poder finalizar.';
//         }
//       },
//       showCancelButton: true,
//       showCloseButton: true,
//       confirmButtonColor: '#0eb05f',
//       cancelButtonColor: '#111',
//       confirmButtonText: 'Finalizar',
//       cancelButtonText: 'Fechar',
//     });

//     if (textSwal) {
//       const { isConfirmed } = await Swal.fire({
//         title: 'Análise da Comissão',
//         html: 'A Análise está correta?',
//         icon: 'question',
//         showCancelButton: true,
//         showCloseButton: true,
//         confirmButtonColor: '#0eb05f',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Sim',
//         cancelButtonText: 'Não',
//       });

//       if (isConfirmed) {
//         await updateDoc(doc(dataBase, 'Comissoes', idComission), {
//           comissao: {
//             ...comission.comissao,
//             status: 'Aceito', // Aceito ou Recusado
//             resposta: textSwal
//           },
//           historico: [
//             ...comission.historico,
//             {
//               data: moment().format('DD/MM/YYYY HH:mm'),
//               text: `Análise da Comissão foi finalizado pelo(a) <b>${userRef && userRef.nome}</b>. Conclusão: ${textSwal}`
//             }
//           ]
//         });

//         Swal.fire({
//           title: 'Análise da Comissão',
//           html: 'A <b>Análise da Comissão</b> foi finalizada com sucesso.',
//           icon: 'success',
//           showConfirmButton: true,
//           showCloseButton: true,
//           confirmButtonColor: '#0eb05f',
//         });
//       } else {
//         confirmAnalyse(textSwal);
//       }
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

// const handleComprovante = async (result) => {
//   try {
//     if(result === 'Aceitar') {
//       Swal.fire({
//         title: 'Nota Fiscal',
//         html: `A Nota Fiscal está correta?`,
//         icon: "question",
//         showCancelButton: true,
//         showCloseButton: true,
//         confirmButtonColor: "#0eb05f",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Sim",
//         cancelButtonText: "Não",
//       }).then(async (result) => {
//         if(result.isConfirmed) {
//           await updateDoc(doc(dataBase, 'Comissoes', idComission), {
//             recibo: {
//               ...comission.recibo,
//               status: 'Aceito'
//             },
//             historico: [
//               ...comission.historico,
//               {
//                 data: moment().format('DD/MM/YYYY HH:mm'),
//                 text: `Nota Fiscal foi aceito pelo(a) <b>${userRef && userRef.nome}</b>`
//               }
//             ]
//           }).then(() => {
//             Swal.fire({
//               title: 'Nota Fiscal',
//               html: 'A <b>Nota Fiscal</b> foi aceita com sucesso.',
//               icon: 'success',
//               showConfirmButton: true,
//               showCloseButton: true,
//               confirmButtonColor: '#0eb05f',
//             });
//           })
//         }
//       })
//     } else {
//         const { value: textSwal } = await Swal.fire({
//           title: 'Nota Fiscal',
//           input: 'textarea',
//           html: 'Digite abaixo o motivo da <b>Nota Fiscal</b> ser recusado',
//           inputPlaceholder: 'Digite aqui...',
//           inputAttributes: {
//             'aria-label': 'Digite aqui'
//           },
//           inputValidator: (value) => {
//             if (!value.trim()) {
//               return 'Por favor, digite o motivo para prosseguir.';
//             }
//           },
//           showCancelButton: true,
//           showCloseButton: true,
//           confirmButtonColor: '#0eb05f',
//           cancelButtonColor: '#111',
//           confirmButtonText: 'Confirmar',
//           cancelButtonText: 'Fechar',
//         });

//         if(textSwal) {
//           await updateDoc(doc(dataBase, 'Comissoes', idComission), {
//             recibo: {
//               status: 'Recusado'
//             },
//             historico: [
//               ...comission.historico,
//               {
//                 data: moment().format('DD/MM/YYYY HH:mm'),
//                 text: `<a href=${comission.recibo?.url} rel="noreferrer" target="_blank">Nota Fiscal</a> do Representante foi recusado pelo(a) <b>${userRef && userRef.nome}</b>. Motivo: ${textSwal}`
//               }
//             ]
//           }).then(() => {
//             Swal.fire({
//               title: 'Nota Fiscal',
//               html: 'A <b>Nota Fiscal</b> foi recusada com sucesso.',
//               icon: 'success',
//               showConfirmButton: true,
//               showCloseButton: true,
//               confirmButtonColor: '#0eb05f',
//             });
//           })   
//         }
//     }
//   } catch (error) {
//     console.log(error)
//   }
// }

const handleHelp = async (type) => {
  try { 
    let msg = type === 'resposta' ? `Mensagem: <b>${comission.ajuda?.motivo}</b> <br /><br /> Responda a mensagem referente a solicitação de ajuda: ` : 'Digite abaixo o motivo da solicitação de ajuda:'
    const { value: textSwal } = await Swal.fire({
      title: 'Ajuda',
      input: 'textarea',
      html: msg,
      inputPlaceholder: 'Digite aqui...',
      inputAttributes: {
        'aria-label': 'Digite aqui'
      },
      inputValidator: (value) => {
        if (!value.trim()) {
          return 'Por favor, digite o motivo para prosseguir.';
        }
      },
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#0eb05f',
      cancelButtonColor: '#111',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Fechar',
    });

    if(textSwal) {
      if(type === 'resposta') {
        await updateDoc(doc(dataBase, 'Comissoes', idComission), {
          ajuda: deleteField(),
          updateAt: serverTimestamp(),
          historico: [
           ...comission.historico,
            {
              data: moment().format('DD/MM/YYYY HH:mm'),
              text: `O <b>Departamento Financeiro CORPBRASIL</b> respondeu a solicitação de ajuda. Resposta: ${textSwal}.`
            }
          ]
        })
      } else {
        await updateDoc(doc(dataBase, 'Comissoes', idComission), {
          ajuda: {
            status: 'Solicitado',
            data: moment().format('DD/MM/YYYY HH:mm'),
            motivo: textSwal,
          },
          updateAt: serverTimestamp(),
          historico: [
           ...comission.historico,
            {
              data: moment().format('DD/MM/YYYY HH:mm'),
              text: `O Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b> solicitou um pedido de ajuda. Motivo: ${textSwal}.`
            }
          ]
        })
      }

        await enviarSolicitacaoPost(0, textSwal, 'ajuda', type);

        Swal.fire({
          title: 'Ajuda',
          html: 'A <b>Solicitação de Ajuda</b> foi realizado com sucesso.',
          icon:'success',
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: '#0eb05f',
        });
    }
  } catch (error) {
    console.log('Ocorreu um erro: ' + error)
  }
}

const handleComprovante = async (result, type) => {
  try {
    const title = type === 'nota_fiscal' ? 'Nota Fiscal' : 'Comissão Acordada';
    if (result === 'Aceitar') {
      Swal.fire({
        title: title,
        html: `O ${type === 'nota_fiscal' ? 'comprovante' : 'documento'} está correto?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const updateData = type === 'nota_fiscal' ?
          {
            recibo: {
              ...comission.recibo,
              status: 'Aceito'
            },
            historico: [
              ...comission.historico,
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `A Nota Fiscal foi aceita pelo(a) <b>Departamento Financeiro CORPBRASIL</b>.`
              }
            ]
          } :
          {
            comissao_acordada: {
              ...comission.comissao_acordada,
              status: 'Aceito'
            },
            historico: [
              ...comission.historico,
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `A Comissão Acordada foi aceita pelo(a) Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b>.`
              }
            ]
          };
          if(type === 'nota_fiscal') {
            await enviarSolicitacaoPost(0, '', 'nota_fiscal_aceita');
          } else {
            await enviarSolicitacaoPost(0, '', 'comissão_acordada_aceita');
          }
          await updateDoc(doc(dataBase, 'Comissoes', idComission), updateData).then(() => {
            Swal.fire({
              title: title,
              html: `O ${type === 'nota_fiscal' ? 'O comprovante' : 'documento'} foi aceito com sucesso.`,
              icon: 'success',
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: '#0eb05f',
            });
          });
        }
      })
    } else {
      const { value: textSwal } = await Swal.fire({
        title: title,
        input: 'textarea',
        html: `Digite abaixo o motivo do ${type === 'nota_fiscal' ? 'comprovante' : 'documento'} ser recusado`,
        inputPlaceholder: 'Digite aqui...',
        inputAttributes: {
          'aria-label': 'Digite aqui'
        },
        inputValidator: (value) => {
          if (!value.trim()) {
            return 'Por favor, digite o motivo para prosseguir.';
          }
        },
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: '#0eb05f',
        cancelButtonColor: '#111',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Fechar',
      });

      if (textSwal) {
        const updateData = type === 'nota_fiscal' ?
          {
            recibo: {
              status: 'Recusado'
            },
            historico: Array.isArray(comission.historico) ? [
              ...comission.historico,
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `A Nota Fiscal do Representante foi recusado pelo(a) <b>Departamento Financeiro CORPBRASIL</b>. Motivo: ${textSwal}.`,
                link: comission.recibo?.url
              }
            ] : [
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `A Nota Fiscal do Representante foi recusado pelo <b>Departamento Financeiro CORPBRASIL</b>. Motivo: ${textSwal}.`,
                link: comission.recibo?.url
              }
            ]
          } :
          {
            comissao_acordada: deleteField(),
            historico: Array.isArray(comission.historico) ? [
              ...comission.historico,
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `A Comissão Acordada foi recusada pelo(a) Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b>. Motivo: ${textSwal}.`,
                link: comission.comissao_acordada.url
              }
            ] : [
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `A Comissão Acordada foi recusada pelo(a) Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b>. Motivo: ${textSwal}.`,
                link: comission.comissao_acordada.url
              }
            ]
          };
        const percentage = comission.comissao_acordada.porcentagem;
        await updateDoc(doc(dataBase, 'Comissoes', idComission), updateData);
        if(type === 'nota_fiscal') {
          await enviarSolicitacaoPost(percentage, textSwal, 'nota_fiscal_negada');
        } else {
          await enviarSolicitacaoPost(percentage, textSwal, 'comissão_acordada_negada');
        }
 
          Swal.fire({
            title: title,
            html: `O ${type === 'nota_fiscal' ? 'comprovante' : 'documento'} foi recusado com sucesso.`,
            icon: 'success',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: '#0eb05f',
          });
      }
    }
  } catch (error) {
    console.log(error)
  }
}

const enviarSolicitacaoPostVenda = async (props) => {
  try {
    await axios.post('https://n8n.corpbrasil.cloud/webhook/febca167-a1fa-43c9-bab6-304bd2919b84', props)
  } catch (error) {
    console.log(error)
  }
}

const enviarSolicitacaoPost = async (percentage, value, type, response) => {
  if(type === 'ajuda') {
        await axios.post('https://n8n.corpbrasil.cloud/webhook/aef17f75-e35a-43fc-bf53-6645b1dc4541', {
            telefone: representative.telefone, //representanteRef.telefone
            consultora: backOffice.nome,
            nome: representative.nome,
            cidade: representative.cidade,
            codigo: representative.id_representante,
            id: idComission,
            motivo: value,
            cliente: comission.nome,
            tipo: response ?? ''
        })
      } else if(type === 'comissão_acordada_negada') {
        await axios.post('https://n8n.corpbrasil.cloud/webhook/159724b4-1f66-42ef-ae6d-6f159089db53', {
            telefone: '5515991573088', // backofficeRef.telefone
            nome: backOffice.nome,
            cidade: userRef && userRef.cidade,
            porcentagem: percentage,
            codigo: userRef && userRef.id_representante,
            representante: userRef && userRef.nome,
            id: idComission,
            motivo: value,
            cliente: comission.nome,
        })
      } else if(type === 'comissão_acordada_aceita') {
        await axios.post('https://n8n.corpbrasil.cloud/webhook/8a37a456-0da6-4d5f-a06a-67410f9dcaf6', {
            telefone: '5515991573088', //backofficeRef.telefone
            nome: backOffice.nome,
            cidade: userRef && userRef.cidade,
            porcentagem: percentage,
            codigo: userRef && userRef.id_representante,
            representante: userRef && userRef.nome,
            id: idComission,
            cliente: comission.nome
        })
      } else if(type === 'nota_fiscal_negada') {
        await axios.post('https://n8n.corpbrasil.cloud/webhook/083161f4-577f-4108-946b-8c68a8ab6586', {
            telefone: representative.telefone, // userRef.telefone
            nome: backOffice.nome,
            cidade: userRef && userRef.cidade,
            porcentagem: percentage,
            codigo: userRef && userRef.id_representante,
            representante: userRef && userRef.nome,
            id: idComission,
            cliente: comission.nome,
            motivo: value,
            resposta: 'negada'
        })
      } else if(type === 'nota_fiscal_aceita') {
        await axios.post('https://n8n.corpbrasil.cloud/webhook/083161f4-577f-4108-946b-8c68a8ab6586', {
            telefone: representative.telefone, // userRef.telefone
            nome: representative.nome,
            cidade: userRef && userRef.cidade,
            porcentagem: percentage,
            codigo: userRef && userRef.id_representante,
            representante: userRef && userRef.nome,
            id: idComission,
            cliente: comission.nome,
            resposta: 'aceita'
        })
      }
}

// const sendComprovante = async () => {
//   try {
//     Swal.fire({
//       title: 'Comprovante de Pagamento',
//       html: `O Comprovante de Pagamento está correto?`,
//       icon: "question",
//       showCancelButton: true,
//       showCloseButton: true,
//       confirmButtonColor: "#0eb05f",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Sim",
//       cancelButtonText: "Não",
//     }).then(async (result) => {
//       if(result.isConfirmed) {
//         const storage = getStorage();
          
//           // Cria uma referência para o armazenamento do Firebase com um nome único
//           const imageName = `Comissao/${idComission}/ComprovanteComissao`; // Substitua 'nome_da_imagem' pelo nome desejado
//           const storageRef = ref(storage, imageName);
          
//           // Realiza o upload do arquivo para o armazenamento do Firebase
//           // await uploadBytes(storageRef, imageSrc, metadata);
//           await uploadBytes(storageRef, comprovante.complete).then((snapshot) => {
//             console.log('Uploaded!');
//           });
          
//           // Obtém a URL da imagem enviada
//           const imageUrl = await getDownloadURL(storageRef);
          
//           // Obtém o caminho (path) onde o arquivo foi armazenado
//           const path = storageRef.fullPath;

//           console.log(path);
//           console.log(imageUrl);

//           await updateDoc(doc(dataBase, 'Comissoes', idComission), {
//             comprovante: {
//               url: imageUrl,
//               path: path,
//               data: `${moment().format('DD/MM/YYYY')} ás ${moment().format('HH:mm')}`,
//               status: 'Finalizado'
//             },
//             comissao: {
//               ...comission.comissao,
//               status: 'Pago'
//             },
//             recibo: {
//               ...comission.recibo,
//               status: 'Pago'
//             },
//             updateAt: serverTimestamp(),
//             etapa: 'Comissão Paga',
//             historico: [
//               ...comission.historico,
//               {
//                 data: moment().format('DD/MM/YYYY HH:mm'),
//                 text: `Comprovante de Pagamento da Comissão foi enviado pelo(a) <b>${userRef && userRef.nome}</b>`
//               }
//             ]
//           }).then(async () => {
//             await updateDoc(doc(dataBase, 'Visitas', idComission), {
//               jornada: {
//                 etapa: 'Comissão',
//                 index: 5,
//                 sigla: 'C',
//                 cor: '#6ece99',
//                 data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
//               }
//             }).then(() => {
//               Swal.fire({
//                 title: 'Comprovante de Pagamento',
//                 html: 'O <b>Comprovante de Pagamento da Comissão</b> foi enviado com sucesso.',
//                 icon: 'success',
//                 showConfirmButton: true,
//                 showCloseButton: true,
//                 confirmButtonColor: '#0eb05f',
//               });
//               setComprovante({ file: null, complete: null })
//             })
//           }) 
//       }
//     })
//   } catch (error) {
//     UseAlert(error);
//   }
// }

const permission = (type) => {
  if(type === "Empresa") {
    if(userRef && (userRef.cargo === 'Administrador' || comission.representante.ref_indicador.includes(userRef.time))) {
      return true
    } else {
      return false
    }
  } else {
    if(userRef && (userRef.cargo === 'Administrador' || comission.representante.id === userRef.id)) {
      return true
    } else {
      return false
    }
  }
}

const handlejourney = async (journey, index) => {
  try {
    if(journey === 'Venda Concluida' && comission.comissao_acordada?.status !== 'Aceito') {
      return Swal.fire({
        title: 'Atenção',
        html: `O <b>Documento da Comissão Acordada</b> ainda não foi enviado.`,
        icon: 'warning',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fechar',
        confirmButtonColor: '#111',
      });
    } else if(journey === 'Comissão Paga' && comission.comprovante?.status !== 'Finalizado') {
      return Swal.fire({
        title: 'Atenção',
        html: `O <b>Comprovante de Pagamento da Comissão</b> ainda não foi enviado.`,
        icon: 'warning',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fechar',
        confirmButtonColor: '#111',
      });
    }
     Swal.fire({
      title: 'Atenção',
      html: `Você deseja alterar para <b>${journey}</b>?`,
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#0eb05f",
      cancelButtonColor: "#d33",
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if(result.isConfirmed) {
        let props = {
          etapa: journey,
          etapa_id: index,
          updateAt: serverTimestamp(),
          data: `${moment().format('DD/MM/YYYY')}`,
          data_completa: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`,
        };
        if(journey === 'Venda Concluida') {
          const dataHttps = {
            nome: comission.nome,
            cidade: comission.cidade,
            consultora: backOffice.nome,
            representante: representative.nome,
            telefone: '5515991573088',
            codigo: representative.id_representante,
            etapa: journey,
            id: idComission,
            kit: comission.kit,
            valor: Number(comission.valor_final).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }
          await enviarSolicitacaoPostVenda(dataHttps);
          props = {
            ...props,
            historico: Array.isArray(comission.historico) ? [
              ...comission.historico,
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `O <b>Departamento Financeiro CORPBRASIL</b> confirmou a venda do Kit Fotovoltaico.`
              }
            ] : [
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `O <b>Departamento Financeiro CORPBRASIL</b> confirmou a venda do Kit Fotovoltaico.`
              }
            ]
          }
        }
        
        await updateDoc(doc(dataBase, 'Comissoes', idComission), props).then(() => {
          Swal.fire({
            title: 'Etapa',
            html: `A Etapa foi alterada com sucesso.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
          })
        });
      }
    })
  } catch (error) {
    console.error('[ERRO]: ', error)
  }
}

  return (
    <div className={styles.panel}>
      <div className={styles.panel_header}>
        <IconButton className={styles.arrow} onClick={() => window.history.back()}><ArrowCircleLeftOutlinedIcon sx={{ fontSize: '50px' }} /></IconButton>
        <div className={styles.panel_header_title}>
        {comission ? (
            <>
              <h1 className={styles.name}>{comission && comission.nome}</h1>
              <h2>{comission && comission.etapa}</h2>
            </>
          ) : (
          <Stack alignItems={'center'} gap={1} margin={0}>
            <Skeleton variant="rectangular" width={300} height={30} />
            <Skeleton variant="rectangular" width={250} height={30} />
          </Stack>
          )}
        </div>  
      </div>
        <div className={styles.panel_content}>
          <div className={styles.step_box} style={{ paddingTop: '1rem' }}>
            <div className={styles.panel_content_title}>
              <h3>Etapa</h3>
            </div>
            <div className={styles.step_client}>
              {journey.map((journey, index) => {
                const step = comission.etapa_id; 
                let styleStep = null;
                if(step === index) {
                  if(journey === 'Comissão Paga') {
                    styleStep = styles.active_item_last;
                  } else {
                    styleStep = styles.current_active_item;
                  }
                } else if (step > index ) {
                  styleStep = styles.active_item
                }
                return (
                  <div className={styleStep}>
                      <button 
                      // disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.nome !== 'Pós-Venda'))} 
                      onClick={() => handlejourney(journey, index)}>
                      {journey}
                    </button>
                  </div>
              )})}
            </div>
              <span>Atualização: {comission.data_completa}</span>
          </div>
          {representative ? (
            <>
            <h2>Detalhes</h2>
            <div className={styles.panel_content_box}>
                <div>
                  <div className={styles.panel_content_title}>
                    <h3>Representante Comercial</h3>
                  </div>
                  <div className={styles.panel_content_text}>
                    <p><b>Nome:</b> {representative && representative.nome}</p>
                    <p><b>Código:</b> {representative && representative.id_representante}</p>
                    <p><b>Telefone:</b> {formatarTelefone(representative.telefone ? representative.telefone : 'Não Informado')}</p>
                    <p><b>Cidade:</b> {representative && representative.cidade}</p>
                  </div>
                </div>
                <div>
                  <div className={styles.panel_content_title}>
                    <h3>Proposta</h3>
                  </div>
                  <div className={styles.panel_content_text}>
                    <p><b>Kit Fotovoltaico:</b> {comission && comission.kit}</p>
                    <p><b>Valor Inicial:</b> {comission.valor_inicial ? comission.valor_inicial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</p>
                    <p><b>Valor Fechado:</b> {comission.valor_final ? comission.valor_final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</p>
                    <p><b>Comissão</b></p>
                    <p><b>Valor:</b> {comission.comissao ? comission.comissao.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</p>
                    <p><b>Porcentagem:</b> {comission.comissao ? comission.comissao.porcentagem : 0} %</p>
                  </div>
                </div>
                <div>
                  <div className={styles.panel_content_title}>
                    <h3>Cliente</h3>
                    <NewTooltip title={'Ver mais informações do Cliente'} placement={'top'}>
                      <Link to={`/clientes/${idComission}`}>
                        <IconButton size='small'>
                          <OpenInNewIcon />
                        </IconButton>
                      </Link>
                    </NewTooltip>
                  </div>
                  <div className={styles.panel_content_text}>
                    <p><b>Empresa:</b> {customer.empresa ? customer.empresa : 'Não Informado'}</p>
                    <p><b>Cidade:</b> {customer.cidade ? customer.cidade : 'Não Informado'}</p>
                    <p><b>Telefone:</b> {formatarTelefone(customer.telefone ? '55' + customer.telefone : 'Não Informado')}</p>
                    <p><h3>Ass. de Vendas</h3></p>
                    <p><b>Nome:</b> {backOffice && backOffice.nome}</p>
                    <p><b>Telefone:</b> {formatarTelefone(backOffice.telefone ? backOffice.telefone : '000000')}</p>
                  </div>
                </div>
            </div>
          </>
          ) : (
          <Stack alignItems={'center'} gap={5} margin={0}>
            <Skeleton variant="rectangular" width={100} height={25} />
            <Skeleton variant="rectangular" width={'100%'} height={207} />
          </Stack>
          )}
          {Object.keys(comission).length === 0 ? (
            <Stack alignItems={'center'} gap={5} margin={0}>
              <Skeleton variant="rectangular" width={300} height={25} />
              <Skeleton variant="rectangular" width={'100%'} height={207} />
            </Stack>
          ) : (
          <>
            <h2>Comissão Acordada</h2>
            <div className={styles.panel_content_box}>
                {comission && comission.comissao_acordada ?
                  <div className={styles.panel_item}>
                    <div className={styles.panel_content_photos}>
                      {comission.comissao_acordada && comission.comissao_acordada?.url.map((data, index) => (
                        <img key={index} src={data} alt='' />
                      ))}
                    </div>
                    {comission.comissao_acordada && comission.comissao_acordada?.status !== 'Aceito' ? 
                    <>
                    <div className={styles.panel_content_title}>
                      {userRef && userRef.cargo === 'Administrador' ? 
                        <h3>Enviado em {comission.comissao_acordada.data}</h3> : 
                        <h3>Aguardando Aprovação do Representante</h3> } 
                    </div>
                    <div className={styles.comission_button} style={{ marginTop: '1rem' }}>
                          {permission('Representante') ? 
                            <ThemeProvider theme={theme}>
                              <Button
                                disableElevation
                                variant='contained'
                                color='success'
                                sx={{ textTransform: 'capitalize' }}
                                startIcon={<CheckCircleIcon />}
                                onClick={() => handleComprovante('Aceitar', 'comissao')}>Aceitar</Button>
                              <Button
                                disableElevation
                                variant='contained'
                                color='error'
                                sx={{ textTransform: 'capitalize' }}
                                startIcon={<CancelIcon />}
                                onClick={() => handleComprovante('Recusar', 'comissao')}>Recusar</Button>
                            </ThemeProvider> : null
                          }
                    </div></> : 
                    <div className={styles.panel_content_title}>
                      <h3>Enviado em {comission.comissao_acordada.data}</h3>
                  </div>
                    }
                  </div> :
                    <div className={styles.panel_item}>
                      {permission('Empresa') ?
                     <div className={styles.panel_item}>
                      <Agreed userRef={userRef} comissionData={comission} open={open === 'Acordada'} close={() => setOpen('')} />
                      <Button
                        disableElevation
                        variant='contained'
                        color='warning'
                        sx={{ textTransform: 'capitalize' }}
                        startIcon={<ArticleIcon sx={{ fill: "#fff" }} />}
                        onClick={() => setOpen('Acordada')}>Comissão Acordada</Button>
                     </div> : 
                      <div className={styles.panel_item}>
                       <h2 className='visit-aviso'>Aguardando o envio do documento</h2>
                      </div>
                     }
                    </div>}
            </div>
          </> )}
          {Object.keys(comission).length === 0 ? (
            <Stack alignItems={'center'} gap={5} margin={0}>
              <Skeleton variant="rectangular" width={300} height={25} />
              <Skeleton variant="rectangular" width={'100%'} height={207} />
            </Stack>
          ) : (
          <>
            <h2>Nota Fiscal - Comissão</h2>
            <div className={styles.panel_content_box}>
            <Invoice userRef={userRef} comissionData={comission} open={open === 'Nota'} close={() => setOpen('')} />
                {comission && comission.etapa === 'Faturamento Pendente' ?
                  <div className={styles.panel_item} >
                    <h2 className='visit-aviso'>Aguardando a venda ser concluida</h2>
                  </div> :
                  comission.recibo && comission.recibo.url ?
                    <div className={styles.panel_item} style={{ width: '100%' }}>
                        {comission.recibo && comission.recibo.url.map((data, index) => (
                        <div key={index} className={styles.panel_content_photos}>
                          <FileViewer fileUrl={data} />
                        </div>
                        ))}
                      <div className={styles.panel_content_title}>
                        <h3>Enviado em {comission.recibo.data}</h3>
                      </div> 
                      {permission('Empresa') && comission.recibo.status !== 'Aceito' && comission.recibo.status !== 'Pago' ? // Quando a comissão ainda não foi aceita pelo faturamento
                        <div className={styles.comission_button} style={{ marginTop: '1rem' }}>
                          {permission('Empresa') ? 
                            <ThemeProvider theme={theme}>
                              <Button
                                disableElevation
                                variant='contained'
                                color='success'
                                sx={{ textTransform: 'capitalize' }}
                                startIcon={<CheckCircleIcon />}
                                onClick={() => handleComprovante('Aceitar', 'nota_fiscal')}>Aceitar</Button>
                              <Button
                                disableElevation
                                variant='contained'
                                color='error'
                                sx={{ textTransform: 'capitalize' }}
                                startIcon={<CancelIcon />}
                                onClick={() => handleComprovante('Recusar', 'nota_fiscal')}>Recusar</Button>
                            </ThemeProvider> : null
                          }
                        </div> : null}
                    </div> :
                    <div className={styles.panel_item}>
                      {userRef && (userRef.cargo === 'Administrador' || userRef.id === comission.representante?.id) ?
                     <div className={styles.panel_item}>
                      <Invoice userRef={userRef} comissionData={comission} open={open === 'Nota'} close={() => setOpen('')} />
                      <Button
                        disableElevation
                        variant='contained'
                        color='success'
                        sx={{ textTransform: 'capitalize' }}
                        startIcon={<ArticleIcon sx={{ fill: "#fff" }} />}
                        onClick={() => setOpen('Nota')}>Nota Fiscal</Button>
                     </div> : 
                      <div className={styles.panel_item}>
                       <h2 className='visit-aviso'>Aguardando o envio do documento</h2>
                      </div>
                     }
                    </div>}
            </div>
          </> )}
          {Object.keys(comission).length === 0 ? (
            <Stack alignItems={'center'} gap={5} margin={0}>
              <Skeleton variant="rectangular" width={300} height={25} />
              <Skeleton variant="rectangular" width={'100%'} height={207} />
            </Stack>
          ) : (
          <>
            <h2>Comprovante de Pagamento da Comissão</h2>
            <div className={styles.panel_content_box}>
              <Voucher userRef={userRef} comissionData={comission} open={open === 'Comprovante'} close={() => setOpen('')} />
            {comission && comission.comprovante?.url ? (
                <div className={styles.panel_item}>
                  <div className={styles.panel_content_photos}>
                    <img src={comission.comprovante?.url} alt='' />
                  </div>
                  <div className={styles.panel_content_title}>
                    <h3>Enviado em {comission.comprovante?.data}</h3>
                  </div>
                    {comission.ajuda ? 
                    <div className={styles.panel_content_title}>
                        {permission('Empresa') ?
                        <Button 
                        disableElevation
                        variant='contained'
                        color='warning'
                        sx={{ textTransform: 'capitalize' }}
                        startIcon={<HelpIcon />}
                        onClick={() => handleHelp('resposta')}>Responder Ajuda</Button>
                        : null
                      }</div> : 
                        comission.etapa === 'Comissão Paga' && permission() ?
                        <Button 
                        disableElevation
                        variant='contained'
                        sx={{ textTransform: 'capitalize' }}
                        startIcon={<HelpIcon />}
                        onClick={() => handleHelp()}>Solicitar Ajuda</Button> : null
                      
                    }
                </div>
              ) : (
                permission('Empresa') ?
                comission.recibo && comission.recibo.url ?
                  <div>
                    <Button
                      disableElevation
                      variant='contained'
                      color='success'
                      sx={{ textTransform: 'capitalize' }}
                      startIcon={<ArticleIcon sx={{ fill: "#fff" }} />}
                      onClick={() => setOpen('Comprovante')}>Comprovante</Button>
                  </div> : 
                  <div className={styles.panel_item}>
                    <h2 className='visit-aviso'>Aguardando o envio do comprovante</h2>
                  </div> :
                  <div className={styles.panel_item}>
                    <h2 className='visit-aviso'>Aguardando o envio do comprovante</h2>
                  </div>
              )
            }
            </div>
          </>)}
          {Object.keys(comission).length === 0 ? (
            <Stack alignItems={'center'} gap={5} margin={0}>
              <Skeleton variant="rectangular" width={300} height={25} />
              <Skeleton variant="rectangular" width={'100%'} height={207} />
            </Stack>
          ) : (
          <>
            <h2>Histórico</h2>
            <div className={styles.panel_content_history}>
            {comission && comission.historico ?
            <ul>
              {comission.historico && comission.historico.slice().reverse().map((data, index) => (
                <li key={index}>
                  [{data.data}]: {parse(data.text)} <br />
                  {data.link ? 
                  <p>
                    {data.link.map((link, index) =>(
                      <a key={index} href={link} rel='noreferrer' target='_blank'>
                        <Button 
                          variant='outlined' 
                          sx={{ margin: '0.5rem', textTransform: 'capitalize' }} 
                          size='small' 
                          startIcon={<ImageIcon />}>
                          Visualizar {index + 1}
                        </Button>
                      </a>
                    ))}
                  </p>
                   : null
                  }
                </li>
              ))}
            </ul> : 
            <div className={styles.panel_item}>
              <h2 className='visit-aviso'>Nenhum Histórico a ser exibido</h2>
            </div>
            }
            </div>
          </>)}
        </div>
    </div>
  )
}

export default ComissionFull
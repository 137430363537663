import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import style from '../../../../styles/Box/style.module.scss'
// import SlickSlider from "react-slick";
import CurrencyInput from "react-currency-input-field";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider, useTheme } from "@mui/material/styles";
// import { styled } from '@mui/material/styles';
import { theme2 } from "../../../../data/theme";
import { MenuItem, FormControl, InputLabel, Select } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import SendIcon from "@mui/icons-material/Send";
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// import TelhadoMedida from '../../../../images/visit/telhado_projeto_medida.jpeg';
// import TelhadoPlaca from '../../../../images/visit/telhado_projeto_placa.jpeg';
// import TelhadoIrradiacao from '../../../../images/visit/telhado_projeto_irradiacao.jpeg';
// import Kit from '../../../../images/visit/kit.jpg';
import { collection, doc, updateDoc } from "firebase/firestore";
import { dataBase } from "../../../../firebase/database";
import { onSnapshot } from "firebase/firestore";
// import useAuth from '../../../../hooks/useAuth';
// import useUpload from '../../../../hooks/useUpload';
// import useCircular from '../../../../hooks/useCircular';

const RegisterVisitStep5 = ({ visit, open, close }) => {
  // const [open, setOpen] = useState(false);
  const theme = useTheme();
  // const { uploadImages, changeProgress } = useUpload(visit.id);
  // const { CircularProgressWithLabel  } = useCircular();
  // const { user } = useAuth();
  const [step, setStep] = useState(0);
  // const [openPhoto, setOpenPhoto] = useState(0);
  // const [checkData, setCheckData] = useState([false], [false],[false], [false]);
  // const [photos, setPhotos] = useState();
  const [infraCA, setInfraCA] = useState({ custo: "", fornecedor: "" });
  const [kit, setKit] = useState({
    valor: "",
    fornecedor: "",
    venda: "",
    instalacao: "",
  });
  const [fornecedores, setFornecedores] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const suppliersCollectionRef = collection(dataBase, "Fornecedores");

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(await suppliersCollectionRef, (data) => {
          // Atualiza os dados em tempo real
          setFornecedores(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          ); // puxa a coleção 'Chats' para o state
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if(visit && visit.kit_info) {
      setKit({
        valor: visit.kit_info.valor,
        fornecedor: visit.kit_info.fornecedor,
        venda: visit.kit_info.valor,
        instalacao: visit.kit_info.venda,
      })
    }
  },[visit, open])

  const closeBox = () => {
    close();
    setTimeout(() => {
      // Parei aqui
      setStep(0);
      setKit({ valor: "", fornecedor: "", venda: "", instalacao: "" });
      setInfraCA({ custo: "", fornecedor: "" });
    }, 500);
  };

  console.log(visit);

  const sendVisit = () => {
    if (
      (kit && kit.valor.length === 0) ||
      (kit && kit.fornecedor.length === 0) ||
      (kit && kit.venda.length === 0) ||
      (kit && kit.instalacao.length === 0) ||
      (infraCA && infraCA.custo.length === 0) ||
      (infraCA && infraCA.fornecedor.length === 0)
    ) {
      let validationPhoto;
      if (kit.valor.length === 0) {
        validationPhoto = "Informe o <b>Valor do Kit";
      } else if (kit.fornecedor.length === 0) {
        validationPhoto = "Escolha o <b>Fornecedor do Kit";
      } else if (kit.venda.length === 0) {
        validationPhoto = "Informe o <b>Valor da Venda do Kit";
      } else if (kit.instalacao.length === 0) {
        validationPhoto = "Informe o <b>Custo de Instalação";
      } else if (infraCA.custo.length === 0) {
        validationPhoto = "Informe o <b>Valor do Custo de Infra CA";
      } else if (infraCA.fornecedor.length === 0) {
        validationPhoto = "Informe o <b>Fornecedor de Infra CA";
      }
      return Swal.fire({
        title: "Adicional",
        html: `${validationPhoto}</b> para enviar os dados.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#F39200",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: "Financeiro",
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#F39200",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        try {
          if (result.isConfirmed) {
            await updateDoc(doc(dataBase, "Visitas", visit.id), {
              kit_info: { ...kit, ...visit.kit_info },
              infra_ca: infraCA,
            }).then(() => {
              Swal.fire({
                title: "Financeiro",
                html: `A etapa de <b>Financeiro</b> foi concluída com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#F39200",
                confirmButtonText: "Ok",
              }).then(() => {
                closeBox();
              });
            });
          }
        } catch (error) {
          console.error("Ocorreu um erro: ", error);
        }
      });
    }
  };

  const sendKit = (e) => {
    e.preventDefault();
    try {
      Swal.fire({
        title: "KIT",
        html: `Todos os dados estão <b>corretos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
          if (result.isConfirmed) {
            await updateDoc(doc(dataBase, "Visitas", visit.id), {
              kit_info: { ...kit, ...visit.kit_info }
            }).then(() => {
              Swal.fire({
                title: "Financeiro",
                html: `Os dados do <b>Kit</b> foram confirmados com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#F39200",
                confirmButtonText: "Ok",
              })
            });
          
        }
      });
    } catch (error) {
      console.log('[ERRO]' + error);
    }
  }

  return (
    <>
      <Dialog
        className={style.dialog}
        open={open}
        fullScreen={fullScreen}
        maxWidth="lg"
        sx={{ zIndex: 90 }}
        onClose={() => closeBox()}
      >
        <IconButton
          aria-label="close"
          onClick={() => closeBox()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle align="center" sx={{ backgroundColor: "#f6f6f6" }}>
          Financeiro
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: "#f6f6f6" }}
          className={style.dialog_content}
        >
          <div className={style.register_container}>
            <form className={style.form} onSubmit={sendKit}>
              <div className={style.box_step}>
                <h3 className={style.title}>KIT</h3>
                <DialogContentText
                  sx={{ textAlign: "center", margin: "0.5rem 0 0.5rem 0" }}
                >
                  Informe o 💵 <b>Valor do Kit</b> e o 🚚 <b>Fornecedor</b>
                </DialogContentText>
                <div className={style.input_double}>
                  <CurrencyInput
                    fullWidth
                    customInput={TextField}
                    className="label__text"
                    label="Valor do Kit"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    onValueChange={(value) => setKit({ ...kit, valor: value })}
                    value={kit.valor || ""}
                    min={50}
                    color="primary"
                    required
                  />
                  <FormControl fullWidth>
                    <InputLabel id="altura">Fornecedor</InputLabel>
                    <Select
                      labelId="altura"
                      label="Resposta"
                      value={kit.fornecedor}
                      onChange={(e) =>
                        setKit({ ...kit, fornecedor: e.target.value })
                      }
                      required
                    >
                      {fornecedores &&
                        fornecedores.map((data, index) => (
                          <MenuItem key={index} value={data.nome}>
                            {data.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <DialogContentText
                  sx={{ textAlign: "center", margin: "0.5rem 0" }}
                >
                  Informe o Valor da <b>Venda do Kit</b> e o{" "}
                  <b>Custo de Instalação</b>
                </DialogContentText>
                <div className={style.input_double}>
                  <CurrencyInput
                    fullWidth
                    customInput={TextField}
                    className="label__text"
                    label="Valor da Venda"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    onValueChange={(value) => setKit({ ...kit, venda: value })}
                    value={kit.venda || ""}
                    min={50}
                    color="primary"
                    required
                  />
                  <CurrencyInput
                    fullWidth
                    customInput={TextField}
                    className="label__text"
                    label="Custo de Instalação"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    onValueChange={(value) =>
                      setKit({ ...kit, instalacao: value })
                    }
                    value={kit.instalacao || ""}
                    min={50}
                    color="primary"
                    required
                  />
                </div>
              </div>
              <Button
                disableElevation
                color="success"
                type="submit"
                startIcon={visit && visit.kit_info && <CheckIcon />}
                sx={{ textTransform: "capitalize", width: "8rem" }}
                variant="contained"
              >
                Confirmar
              </Button>
            </form>
            <div className={style.box_step}>
              <h3 className={style.title}>Infra CA</h3>
              <DialogContentText
                sx={{ textAlign: "center", margin: "0.5rem 0 0.5rem 0" }}
              >
                Informe o 💵 <b>Custo de Infra CA</b> e o 🚚 <b>Fornecedor</b>
              </DialogContentText>
              <div className={style.input_double}>
                <CurrencyInput
                  fullWidth
                  customInput={TextField}
                  className="label__text"
                  label="Custo Infra CA"
                  placeholder="R$ 00"
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  onValueChange={(value) =>
                    setInfraCA({ ...infraCA, custo: value })
                  }
                  value={infraCA.custo || ""}
                  min={50}
                  color="primary"
                />
                <FormControl fullWidth>
                  <InputLabel id="altura">Fornecedor</InputLabel>
                  <Select
                    labelId="altura"
                    label="Resposta"
                    value={infraCA.fornecedor}
                    onChange={(e) =>
                      setInfraCA({ ...infraCA, fornecedor: e.target.value })
                    }
                    required
                  >
                    {fornecedores &&
                      fornecedores.map((data, index) => (
                        <MenuItem key={index} value={data.nome}>
                          {data.nome}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions
            sx={{
              justifyContent: "center",
              paddingBottom: "1rem",
              backgroundColor: "#f6f6f6",
            }}
          >
            <Button
              disableElevation
              component="label"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              disabled={step > 2 ? true : false}
              onClick={() => close()}
            >
              Fechar
            </Button>
            <Button
              disableElevation
              component="label"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              disabled={step > 2 ? true : false}
              color="success"
              startIcon={<SendIcon />}
              onClick={() => sendVisit()}
            >
              Enviar
            </Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog>
      {/* <Dialog
      className={style.photo_container}
      sx={{ padding: '1rem' }}
      open={openPhoto}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setOpenPhoto(false)}
    >
        <IconButton
          aria-label="close"
          onClick={() => setOpenPhoto(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <div className={style.photo_box}>
          {photos && photos.data.map((photo, index) => (
            <div className={style.photo_card}>
                <img src={photo.file} alt='' /> :
              <Button variant='contained' color='error' onClick={() => deletePhoto(index, photos)}>Excluir</Button>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <DialogActions className={style.fatura_buttons} sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => setOpenPhoto(false)}>FECHAR</Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog> */}
    </>
  );
};

export default RegisterVisitStep5;

import Swal from "sweetalert2";

export const toast = ({ icon, text, error, position = 'top-end' }) => {
    const colors = {
        success: {
            background: "#e9fbf0",
            color: "#2e7d32"
        },
        error: {
            background: "#d32f2f",
            color: "#fbe9e9"
        }
    };

    const toastRef = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: 3000,
    color: colors[icon].color,
    timerProgressBar: true,
    background: colors[icon].background,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  return toastRef.fire({
    icon: icon,
    title: text,
    text: error
  });
}
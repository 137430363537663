import { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import PaymentsIcon from '@mui/icons-material/Payments';
import ArticleIcon from '@mui/icons-material/Article';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import { ThemeProvider } from '@emotion/react';
import { theme } from '../../data/theme';
import { Box, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import NewTooltip from '../../components/Tooltip';

import { ReactComponent as Solar } from "../../images/icons/Solar.svg";
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';
import { dataBase } from '../../firebase/database';
import Agreed from './Components/Agreed';
import Invoice from './Components/Invoice';
import Voucher from './Components/Voucher';

const Comission = ({ userRef }) => {
  const [TabsValue, setTabsValue] = useState(0);
  const [open, setOpen] = useState('');
  const [check, setCheck] = useState(true);
  const [comission, setComission] = useState({});
  const [comissionsRef, setComissionsRef] = useState([]);
  const [comissions, setComissions] = useState({
    faturamento: [],
    venda: [],
    pago: []
  });
  const ComissionCollectionRef = collection(dataBase,"Comissoes");
    const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(ComissionCollectionRef, (comission) => {
          // Atualiza os dados em tempo real
          const allComissions = comission.docs.map((doc) => ({ 
            ...doc.data(),
            id: doc.id 
          }));
          const ComissionRef = userRef && userRef.cargo === 'Representante' ?
           allComissions.filter(data => data.representante.id === userRef.uid) : userRef && userRef.time ? allComissions.filter(data => data.backoffice_id === userRef.uid) : allComissions;
          setComissionsRef(ComissionRef);
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

console.log(comissions)

  useEffect(
    () => {
      if(comissionsRef) {
        const fetchData = async () => {
        const faturaStep = comissionsRef.filter(data => data.etapa === 'Faturamento Pendente');
        const vendaStep = comissionsRef.filter(data => data.etapa === 'Venda Concluida');
        const pagoStep = comissionsRef.filter(data => data.etapa === 'Comissão Paga');
        setComissions({
          faturamento: faturaStep,
          venda: vendaStep,
          pago: pagoStep
        })
      setTimeout(() => {
        setCheck(false);
      }, 1200);
      }

      fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comissionsRef]
  );

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        className={styles.comissions}
        id={`simple-tabpanel-${index}`}
        style={{ width: '100%', }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'} gap={'1rem'}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // const requestComission = (data) => {
  //   try {
  //     Swal.fire({
  //       title: 'Comissão',
  //       html: `O valor de <b>${data.comissao?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b> está correto?`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //       if(result.isConfirmed) {
  //         await updateDoc(doc(dataBase, 'Comissoes', data.id), {
  //           comissao: {
  //             ...data.comissao,
  //             status: 'Aceito', // Aceito ou Recusado
  //           },
  //           historico: [
  //             ...data.historico,
  //             {
  //               data: moment().format('DD/MM/YYYY HH:mm'),
  //               text: `O Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b> solicitou a comissão`
  //             }
  //           ]
  //          }).then(() => {
  //           Swal.fire({
  //             title: 'Comissão',
  //             html: `A <b>Comissão</b> foi solicitada com sucesso. Em breve o setor de faturamento entrará em contato.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#0eb05f",
  //           })
  //          })
  //       } else {
  //         const { value: text } = await Swal.fire({
  //           title: 'Comissão',
  //           input: "textarea",
  //           html: `Explique abaixo o motivo de não estar correto a comissão no valor de 
  //           <b>${data.comissao?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b>.`,
  //           inputPlaceholder: "Digite aqui...",
  //           inputAttributes: {
  //             "aria-label": "Digite aqui"
  //           },
  //           inputValidator: (value) => {
  //             if (!value) {
  //               return "Por favor, digite o motivo para poder enviar";
  //             }
  //           },
  //           showCancelButton: true,
  //           showCloseButton: true,
  //           confirmButtonColor: "#0eb05f",
  //           cancelButtonColor: "#111",
  //           confirmButtonText: "Enviar",
  //           cancelButtonText: "Fechar",
  //         });
  //         if (text) {
  //          await updateDoc(doc(dataBase, 'Comissoes', data.id), {
  //           comissao: {
  //             ...data.comissao,
  //             status: 'Recusado', // Aceito ou Recusado
  //             motivo: text
  //           },
  //           historico: [
  //             ...data.historico,
  //             {
  //               data: moment().format('DD/MM/YYYY HH:mm'),
  //               text: `O Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b> recusou o valor da comissão. Motivo: ${text}`
  //             }
  //           ]
  //          }).then(() => {
  //           Swal.fire({
  //             title: 'Comissão',
  //             html: `Foi solicitado uma <b>análise da comissão</b>. Em breve o setor de faturamento entrará em contato.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#0eb05f",
  //           })
  //          })
  //         }
  //       }})
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

// const sendComprovante = async (data) => {
//   try {

//     const { value: file } = await Swal.fire({
//       title: "Nota Fiscal",
//       html: `Selecione a imagem da <b>Nota Fiscal</b> da Comissão.`,
//       input: "file",
//       inputAttributes: {
//         accept: "image/*",
//         "aria-label": "Upload your profile picture"
//       },
//       showCancelButton: true,
//       cancelButtonText: "Cancelar",
//       showCloseButton: true,
//       confirmButtonColor: '#1976d2',
//       cancelButtonColor: '#111',
//       confirmButtonText: "Visualizar"
//     });

//     const inputFile = document.querySelector('.swal2-file');
//     inputFile.addEventListener('change', () => {
//     const selectedFile = inputFile.files[0];
//       // Aqui você pode chamar sua função passando o arquivo selecionado como argumento
//       console.log(selectedFile);
//     });
    
//     if (file) {
//       const reader = new FileReader();
// reader.onload = async (e) => {
//   try {
//     const imageSrc = e.target.result;
//     const { value: file } = await Swal.fire({
//       title: 'Nota Fiscal',
//       html: 'A <b>Nota Fiscal</b> está correto?',
//       imageUrl: imageSrc,
//       imageAlt: 'Nota Fiscal',
//       showCancelButton: true,
//       cancelButtonText: 'Não',
//       showCloseButton: true,
//       confirmButtonText: 'Sim',
//       confirmButtonColor: '#0eb05f',
//       cancelButtonColor: '#d33',
//       showLoaderOnConfirm: true,
//       preConfirm: async (imageFile) => {
//         try {
//           // Verifica se o arquivo foi enviado
//           if (!imageFile) {
//             throw new Error('Por favor, selecione uma imagem.');
//           }
          
//           // Inicializa o armazenamento do Firebase
//           const storage = getStorage();
          
//           // Cria uma referência para o armazenamento do Firebase com um nome único
//           const imageName = `Comissao/${data.id}/Comprovante`; // Substitua 'nome_da_imagem' pelo nome desejado
//           const storageRef = ref(storage, imageName);
          
//           // Realiza o upload do arquivo para o armazenamento do Firebase
//           // await uploadBytes(storageRef, imageSrc, metadata);
//           await uploadString(storageRef, imageSrc, 'data_url').then((snapshot) => {
//             console.log('Uploaded a base64 string!');
//           });
          
//           // Obtém a URL da imagem enviada
//           const imageUrl = await getDownloadURL(storageRef);
          
//           // Obtém o caminho (path) onde o arquivo foi armazenado
//           const path = storageRef.fullPath;

//           console.log(path);
//           console.log(imageUrl);

//           await updateDoc(doc(dataBase, 'Comissoes', data.id), {
//             recibo: {
//               url: imageUrl,
//               path: path,
//               data: `${moment().format('DD/MM/YYYY')} ás ${moment().format('HH:mm')}`,
//               status: 'Aguardando'
//             },
//             historico: [
//               ...data.historico,
//               {
//                 data: moment().format('DD/MM/YYYY HH:mm'),
//                 text: `Nota Fiscal foi enviado pelo(a) Representante <b>${userRef && userRef.nome} (${userRef && userRef.id_representante})</b>`
//               }
//             ]
//           }).then(async () => {
//             const backofficeDoc = await getDoc(doc(dataBase, 'Membros', data.backoffice_id));
//               const visitDoc = await getDoc(doc(dataBase, 'Visitas', data.id));
//               if(backofficeDoc.exists() && visitDoc.exists()) {
//                 const backofficeRef = backofficeDoc.data();
//                 const visitRef = visitDoc.data();
//                 const valorFormatado = data.valor_inicial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

//                 await axios.post('https://n8n.corpbrasil.cloud/webhook/30aab85e-3008-4651-9215-fdcf243cd4d4', {
//                   data: `${moment(visitRef.data).format('DD/MM/YYYY')} às ${visitRef.chegadaCliente}`,
//                   nome: data.nome,
//                   cidade: userRef.cidade,
//                   consultora: backofficeRef.nome,
//                   representante: visitRef.indicador.nome,
//                   codigo: userRef.id_representante,
//                   kit: data.kit,
//                   valor: valorFormatado,
//                   id: data.id
//                 })
//                 Swal.fire({
//                   title: 'Nota Fiscal',
//                   html: `A <b>Nota Fiscal</b> foi enviada com sucesso.`,
//                   icon: "success",
//                   showConfirmButton: true,
//                   showCloseButton: true,
//                   confirmButtonColor: "#0eb05f",
//                 })
//               }
//           })
          
//         } catch (error) {
//           throw new Error(`Falha ao enviar a imagem: ${error.message}`);
//         }
//       },
//       allowOutsideClick: () => !Swal.isLoading()
//         });

//         if (!file) {
//           sendComprovante(data);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     reader.readAsDataURL(file);
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

const sendDoc = (data, type) => {
    setComission(data);
    console.log(type)
    setOpen(type);
}

const permission = (data, type) => {
  if(type === "Empresa") {
    if(userRef && (userRef.cargo === 'Administrador' || data.representante.ref_indicador.includes(userRef.time))) {
      return true
    } else {
      return false
    }
  } else {
    if(userRef && (userRef.cargo === 'Administrador' || data.representante?.id === userRef.id)) {
      return true
    } else {
      return false
    }
  }
}

  return (
    <div className='container-schedules'>
      <div className='title-panel'>
        <PaymentsIcon sx={{ width: '50px', height: '50px' }} />
        <h2>Comissões</h2>
      </div>
      <div className='content-schedule' style={{ marginTop: '2rem' }}>
      <ThemeProvider theme={theme}>
        <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Comissão" centered>
            <Tab sx={{ textTransform: 'capitalize' }} label="Fatur. Pendente" {...a11yProps(1)} />
            <Tab sx={{ textTransform: 'capitalize' }} label="Venda Concluida" {...a11yProps(2)} />
            <Tab sx={{ textTransform: 'capitalize' }} label="Comissão Paga" {...a11yProps(3)} />
        </Tabs>
      </ThemeProvider>
      <CustomTabPanel value={TabsValue} index={0}>
      {check ? (
        <Stack width={'100%'} maxWidth={380} gap={2}>
          <Skeleton variant='rounded' width='100%' height={180} />
          <Skeleton variant='rounded' width='100%' height={180} />
          <Skeleton variant='rounded' width='100%' height={180} />
        </Stack>
      ) : (comissions.faturamento.length > 0 ? 
        <><Agreed userRef={userRef} comissionData={comission} open={open === 'Acordada'} close={() => setOpen(false)} />
        {comissions.faturamento.map((data, index) => (
        <div key={index} className={styles.comission_item}>
            <div className={styles.comission_content}>
              <Solar />
              <div className={styles.comission_text}>
                <h3>{data.nome}</h3>
                <p>Kit: <b>{data.kit}</b></p>
                <p>Valor: <b>{data.valor_final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
                <p>Comissão: <b>{data.comissao?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
              </div>
            </div>
            <span className={styles.comission_date} style={{ color: "#646464" }}>Faturamento Pendente: {data.data} ⌛</span>
            <div className={styles.comission_button}>
              <ThemeProvider theme={theme}>
                {permission(data, 'Empresa') ? 
                  <NewTooltip arrow title={data.comissao_acordada ? 'Aguardando a confirmação do Representante' : 'Clique aqui para enviar a comissão acordada com o Representante' } placement='top'>
                    <span>
                      <Button 
                        disableElevation
                        variant='contained'
                        color='warning'
                        disabled={data.comissao_acordada ? true : false}
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => sendDoc(data, 'Acordada')}
                        startIcon={<ArticleIcon sx={{ fill: "#fff" }} />}>
                        C. Acordada        
                      </Button>
                    </span>
                  </NewTooltip> : null
                }
                <NewTooltip arrow title='Clique aqui para ver mais detalhes a respeito da comissão' placement='top'>
                  <Link to={`/comissao/${data.id}`}>
                    <Button 
                      disableElevation
                      variant='contained'
                      sx={{ textTransform: 'capitalize' }}
                      startIcon={<ArticleIcon sx={{ fill: "#fff" }} />}>
                      Mais Detalhes         
                    </Button>
                  </Link>
                </NewTooltip>
              </ThemeProvider>
            </div>
        </div>
        ))}</> : 
        <div className="visit-aviso">
          <h2>Nenhuma Comissão Encontrada</h2>
        </div>)}
      </CustomTabPanel>
      <CustomTabPanel value={TabsValue} index={1}>
      {check ? (
        <Stack width={'100%'} maxWidth={380} gap={2}>
          <Skeleton variant='rounded' width='100%' height={180} />
          <Skeleton variant='rounded' width='100%' height={180} />
          <Skeleton variant='rounded' width='100%' height={180} />
        </Stack>
      ) : (comissions.venda.length > 0 ? 
        <><Invoice userRef={userRef} comissionData={comission} open={open === 'Nota'} close={() => setOpen('')} />
        <Voucher userRef={userRef} comissionData={comission} open={open === 'Comprovante'} close={() => setOpen('')} />
        {comissions.venda.map((data, index) => (
        <div key={index} className={styles.comission_item}>
            <div className={styles.comission_content}>
              <Solar />
              <div className={styles.comission_text}>
                <h3>{data.nome}</h3>
                <p>Kit: <b>{data.kit}</b></p>
                <p>Valor: <b>{data.valor_final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
                <p>Comissão: <b>{data.comissao?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
              </div>
            </div>
            <span className={styles.comission_date} style={{ color: "#646464" }}>Venda Concluida: {data.data} ✅</span>
            {data.comissao?.motivo ? 
              <span className={styles.comission_date}>Análise de Comissão {data.comissao?.status === 'Recusado' ? 'Solicitada ⌛' : 'Finalizada ✅'}</span> : null
            }
            <div className={styles.comission_button}>
              {data.recibo && data.recibo.url ? 
                    permission(data, 'Empresa') ?
                    <Button 
                      disableElevation
                      variant='contained'
                      sx={{ textTransform: 'capitalize' }}
                      color='success'
                      startIcon={<RequestQuoteIcon />}
                      onClick={() => sendDoc(data, 'Comprovante')}>
                      Comprovante        
                    </Button> : null
                     :
                    permission(data, '') ?
                    <Button 
                      disableElevation
                      variant='contained'
                      sx={{ textTransform: 'capitalize' }}
                      startIcon={<RequestQuoteIcon />}
                      onClick={() => sendDoc(data, 'Nota')}>
                      Enviar NF      
                    </Button> : null
              }
              
              <ThemeProvider theme={theme}>
              <NewTooltip arrow title='Clique aqui para ver mais detalhes a respeito da comissão' placement='top'>
                  <Link to={`/comissao/${data.id}`}>
                    <Button 
                      disableElevation
                      variant='contained'
                      sx={{ textTransform: 'capitalize' }}
                      startIcon={<ArticleIcon sx={{ fill: "#fff" }} />}>
                      Mais Detalhes         
                    </Button>
                  </Link>
                </NewTooltip>
              </ThemeProvider>
            </div>
        </div>
        ))}</> : 
        <div className="visit-aviso">
          <h2>Nenhuma Comissão Encontrada</h2> 
        </div>)}
      </CustomTabPanel>
      <CustomTabPanel value={TabsValue} index={2}>
      {check ? (
        <Stack width={'100%'} maxWidth={380} gap={2}>
          <Skeleton variant='rounded' width='100%' height={180} />
          <Skeleton variant='rounded' width='100%' height={180} />
          <Skeleton variant='rounded' width='100%' height={180} />
        </Stack>
      ) : ( 
      comissions.pago.length > 0 ? 
        comissions.pago.map((data, index) => (
        <div key={index} className={styles.comission_item}>
            <div className={styles.comission_content}>
              <Solar />
              <div className={styles.comission_text}>
                <h3>{data.nome}</h3>
                <p>Kit: <b>{data.kit}</b></p>
                <p>Valor: <b>{data.valor_final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
                <p>Comissão: <b>{data.comissao?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
              </div>
            </div>
            <span className={styles.comission_date} style={{ color: "#646464" }}>Comissão Paga: {data.data} ✅</span>
            <div className={styles.comission_button}>
              <ThemeProvider theme={theme}>
              <NewTooltip arrow title='Clique aqui para ver mais detalhes a respeito da comissão' placement='top'>
                  <Link to={`/comissao/${data.id}`}>
                    <Button 
                      disableElevation
                      variant='contained'
                      sx={{ textTransform: 'capitalize' }}
                      startIcon={<ArticleIcon sx={{ fill: "#fff" }} />}>
                      Mais Detalhes         
                    </Button>
                  </Link>
                </NewTooltip>
              </ThemeProvider>
            </div>
        </div>
        )) : 
        <div className="visit-aviso">
          <h2>Nenhuma Comissão Encontrada</h2>
        </div>)}
      </CustomTabPanel>
      </div>
    </div>
  )
}

export default Comission
import React from 'react'
import { useState, useEffect, memo, useRef } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import moment from 'moment';
import axios from 'axios';

import styles from "./styles.module.scss";

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { ReactComponent as CheckIcon } from "../../../images/icons/Check.svg";
import { ReactComponent as BlockIcon } from "../../../images/icons/Block.svg";
import { ReactComponent as Email } from '../../../images/icons/Mail.svg';
import { ReactComponent as Phone } from '../../../images/icons/Phone.svg';
import { ReactComponent as WhatsApp } from '../../../images/icons/WhatsApp.svg';
import { ReactComponent as Trophy } from '../../../images/icons/Trophy.svg';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';

const Dashboard = ({ visits, getLeads, dataBase, dateValue, activity, leads, consultora, sendData }) => {
  const [dataChart, setdataChart] = useState();
  const [dataChart2, setdataChart2] = useState();
  const [dataChart3, setdataChart3] = useState();
  const [dataChart4, setdataChart4] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [confirmar, setConfirmar] = useState();
  const [nconfirmar, setNconfirmar] = useState();
  const [ganho, setGanho] = useState(0);
  const [perdido, setPerdido] = useState(0);
  const [visitas, setVisitas] = useState();
  const [atividades, setAtividades] = useState(activity);
  const [allLeads, setAllLeads] = useState();
  const [leadsSheets, setLeadsSheets] = useState();
  const [vendasSheets, setVendasSheets] = useState();
  const [ganho2, setGanho2] = useState(0);
  const [visitas2, setVisitas2] = useState();
  const [atividades2, setAtividades2] = useState(activity);
  const [allLeads2, setAllLeads2] = useState();
  const [leadsSheets2, setLeadsSheets2] = useState();
  const [vendasSheets2, setVendasSheets2] = useState();
  const [leadsSheetsRef, setLeadsSheetsRef] = useState();
  const [vendasSheetsRef, setVendasSheetsRef] = useState();
  const [leadsRobo, setLeadsRobo] = useState();
  const [leadsMeetime, setLeadsMeetime] = useState();
  // const [check, setCheck] = useState(false);
  // const [loading, setLoading] = useState(true);
  const daysRef = useRef(0);
  const meta = useRef({});

  const colors = ['rgb(68, 191, 43)', 'rgb(229 221 6)', '#063'];


  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get('https://script.google.com/macros/s/AKfycbxad1yCWiFmL9Q2qXIMglIFbH-m9KafsaNoD9UVYrhKgdmdjHpAlbJ-IxeTj-OroUjjsw/exec')
          .then((result) => {
            let sheets = [];
            result.data.GoogleSheetData.forEach((data) => {
              if (data[0].length > 1) {
                // const newData = Object.assign({}, data);
                sheets.push(data)
              }
            })
            setLeadsSheets(sheets);
            setLeadsSheetsRef(sheets);
            getLeads(sheets.shift());
          })
      } catch {

      }
    }
    fetchData();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(() => {
    if (leadsSheets) {
      getLeads(leadsSheets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadsSheets])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get('https://script.google.com/macros/s/AKfycbyWrl_taZ0cqe8PG5NxLVPljlCNZZH62U6dAe09DRc5wQtwwOW16k7jtGZ-jb28CJjc/exec')
          .then((result) => {
            let sheets = [];
            result.data.GoogleSheetData.forEach((data) => {
              if (data[0] !== 'Data') {
                // const newData = Object.assign({}, data);
                sheets.push(data)
              }
            })
            setVendasSheets(sheets);
            setVendasSheetsRef(sheets);
          })
      } catch {
      }
    }
    fetchData();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  // console.log(vendasSheetsRef)

  //   useEffect(() => {
  //       const fetchData = async () => {
  //         try {
  //           let items = [];
  //           meses.map(async (mes) => {
  //             await onSnapshot(query(collection(dataBase,"Agendas","2023", mes)), (visit) => {
  //               // Atualiza os dados em tempo real
  //               visit.forEach((doc) => {
  //                 items.push(doc.data())
  //               })
  //             });
  //           })  
  //           //console.log(items)
  //           setVisitas(items);
  //           setVisitasAll(items);
  //           // setLoading(false);
  //         } catch {
  //         }
  //       }
  //       fetchData();
  // },
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     []);

  useEffect(() => {
    if (visits) {
      setVisitas(visits);
    }
  }, [visits])

  // console.log(visitas);

  useEffect(() => {
    if (visitas || visitas2) {
      setConfirmar(visitas.filter((vis) => vis.confirmar === true).length);
      setNconfirmar(visitas.filter((vis) => vis.confirmar === false).length);
    }
  }, [visitas, visitas2, dateValue]);

  // useEffect(() => {
  //   if(visitas && !check) {
  //     visitas.map((visita) => {
  //       return addDoc(collection(dataBase, 'Visitas'), visita);
  //     })
  //     setCheck(true);
  //   }
  // },[visitas])


  useEffect(() => {
    if (consultora === 'Geral') {
      if (dateValue) {
        const data1 = moment(dateValue[0]).format('YYYY-MM-DD');
        const data2 = moment(dateValue[1]).format('YYYY-MM-DD');
        setVisitas(visits && visits.filter((item) => (
          moment(data1).isSameOrBefore(moment(item.data).format('YYYY-MM-DD')) &&
          moment(data2).isSameOrAfter(moment(item.data).format('YYYY-MM-DD')) && item.consultora !== 'Pós-Venda'
        )))
        setAtividades(activity && activity.filter((item) => (moment(data1).isSameOrBefore(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')))))
        setLeadsSheets(leadsSheetsRef && leadsSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')))))
        setVendasSheets(vendasSheetsRef && vendasSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')))))
        setAllLeads(leads);
        setGanho(leads ? leads.filter((vis) => vis.status === 'Ganho' && vis.updateAt &&
          moment(data1).isSameOrBefore(moment(vis.updateAt.seconds * 1000)) &&
          moment(data2).isSameOrAfter(moment(vis.updateAt.seconds * 1000))).length : 0);
        setPerdido(leads ? leads.filter((vis) => vis.status === 'Perdido' && vis.updateAt &&
          moment(data1).isSameOrBefore(moment(vis.updateAt.seconds * 1000)) &&
          moment(data2).isSameOrAfter(moment(vis.updateAt.seconds * 1000))).length : 0);
      } else {
        setVisitas(visits && visits);
        setAtividades(activity);
        setData1(activity ? activity.filter((vis) => vis.atividade === 'Email').length : 0);
        setData2(activity ? activity.filter((vis) => vis.atividade === 'Ligação').length : 0);
        setData3(activity ? activity.filter((vis) => vis.atividade === 'WhatsApp').length : 0);
        setGanho(leads ? leads.filter((vis) => vis.status === 'Ganho').length : 0);
        setPerdido(leads ? leads.filter((vis) => vis.status === 'Perdido').length : 0);
        setAllLeads(leads);
        setLeadsSheets(leadsSheetsRef && leadsSheetsRef);
        setVendasSheets(vendasSheetsRef && vendasSheetsRef);
      }
    } else if (consultora === 'Bruna Mayara Paulo') {
      if (dateValue) {
        const data1 = moment(dateValue[0]).format('YYYY-MM-DD');
        const data2 = moment(dateValue[1]).format('YYYY-MM-DD');
        setVisitas(visits && visits.filter((item) => (moment(data1).isSameOrBefore(moment(item.data).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.data).format('YYYY-MM-DD')) && item.consultora !== 'Pós-Venda')))
        setAtividades(activity && activity.filter((item) => (moment(data1).isSameOrBefore(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')))));
        setVendasSheets(vendasSheetsRef && vendasSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')))))
        setLeadsSheets(leadsSheetsRef && leadsSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')))))
        setVisitas2({
          mayara: visits && visits.filter((item) => (moment(data1).isSameOrBefore(moment(item.data).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.data).format('YYYY-MM-DD')) && item.consultora === 'Mayara')),
          bruna: visits && visits.filter((item) => (moment(data1).isSameOrBefore(moment(item.data).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.data).format('YYYY-MM-DD')) && item.consultora === 'Bruna')),
          paulo: visits && visits.filter((item) => (moment(data1).isSameOrBefore(moment(item.data).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.data).format('YYYY-MM-DD')) && item.consultora === 'Paulo')),
        })
        setAtividades2({
          mayara: activity && activity.filter((item) => (moment(data1).isSameOrBefore(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && item.consultora === 'Mayara')),
          bruna: activity && activity.filter((item) => (moment(data1).isSameOrBefore(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && item.consultora === 'Bruna')),
          paulo: activity && activity.filter((item) => (moment(data1).isSameOrBefore(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && item.consultora === 'Paulo')),
        })
        setAllLeads2({
          mayara: leads && leads.filter(item => item.consultora === 'Mayara'),
          bruna: leads && leads.filter(item => item.consultora === 'Bruna'),
          paulo: leads && leads.filter(item => item.consultora === 'paulo')
        });
        setLeadsSheets2({
          mayara: leadsSheetsRef && leadsSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[9] === 'Mayara')),
          bruna: leadsSheetsRef && leadsSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[9] === 'Bruna')),
          paulo: leadsSheetsRef && leadsSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[9] === 'Paulo')),
        })
        setVendasSheets2({
          mayara: vendasSheetsRef && vendasSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[7] === 'Mayara')),
          bruna: vendasSheetsRef && vendasSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[7] === 'Bruna')),
          paulo: vendasSheetsRef && vendasSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[7] === 'Paulo'))
        })
        setGanho2({
          mayara: leads ? leads.filter((vis) => vis.status === 'Ganho' && vis.updateAt && vis.consultora === 'Mayara' &&
            moment(data1).isSameOrBefore(moment(vis.updateAt.seconds * 1000)) &&
            moment(data2).isSameOrAfter(moment(vis.updateAt.seconds * 1000))).length : 0,
          bruna: leads ? leads.filter((vis) => vis.status === 'Ganho' && vis.updateAt && vis.consultora === 'Bruna' &&
            moment(data1).isSameOrBefore(moment(vis.updateAt.seconds * 1000)) &&
            moment(data2).isSameOrAfter(moment(vis.updateAt.seconds * 1000))).length : 0,
          paulo: leads ? leads.filter((vis) => vis.status === 'Ganho' && vis.updateAt && vis.consultora === 'Paulo' &&
            moment(data1).isSameOrBefore(moment(vis.updateAt.seconds * 1000)) &&
            moment(data2).isSameOrAfter(moment(vis.updateAt.seconds * 1000))).length : 0
        });
      } else {
        setVisitas(visits && visits);
        setAtividades(activity);
        setGanho(leads ? leads.filter((vis) => vis.status === 'Ganho' && vis.consultora === consultora).length : 0);
        setPerdido(leads ? leads.filter((vis) => vis.status === 'Perdido' && vis.consultora === consultora).length : 0);
        setAllLeads(leads && leads.filter(item => item.consultora === consultora));
        setLeadsSheets(leadsSheetsRef && leadsSheetsRef);
        setVendasSheets(vendasSheetsRef && vendasSheetsRef);
      }
    } else {
      if (dateValue) {
        const data1 = moment(dateValue[0]).format('YYYY-MM-DD');
        const data2 = moment(dateValue[1]).format('YYYY-MM-DD');
        setVisitas(visits && visits.filter((item) => (moment(data1).isSameOrBefore(moment(item.data).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.data).format('YYYY-MM-DD')) && item.consultora === consultora)))
        setAtividades(activity && activity.filter((item) => (moment(data1).isSameOrBefore(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item.createAt.seconds * 1000).format('YYYY-MM-DD')) && item.consultora === consultora)))
        setAllLeads(leads && leads.filter(item => item.consultora === consultora));
        setLeadsSheets(leadsSheetsRef && leadsSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[9] === consultora)))
        setVendasSheets(vendasSheetsRef && vendasSheetsRef.filter((item) => (moment(data1).isSameOrBefore(moment(item[0]).format('YYYY-MM-DD')) && moment(data2).isSameOrAfter(moment(item[0]).format('YYYY-MM-DD')) && item[7] === consultora)))
        setGanho(leads ? leads.filter((vis) => vis.status === 'Ganho' && vis.updateAt && vis.consultora === consultora &&
          moment(data1).isSameOrBefore(moment(vis.updateAt.seconds * 1000)) &&
          moment(data2).isSameOrAfter(moment(vis.updateAt.seconds * 1000))).length : 0);
        setPerdido(leads ? leads.filter((vis) => vis.status === 'Perdido' && vis.updateAt && vis.consultora === consultora &&
          moment(data1).isSameOrBefore(moment(vis.updateAt.seconds * 1000)) &&
          moment(data2).isSameOrAfter(moment(vis.updateAt.seconds * 1000))).length : 0);
      } else {
        setVisitas(visits && visits);
        setAtividades(activity);
        setGanho(leads ? leads.filter((vis) => vis.status === 'Ganho' && vis.consultora === consultora).length : 0);
        setPerdido(leads ? leads.filter((vis) => vis.status === 'Perdido' && vis.consultora === consultora).length : 0);
        setAllLeads(leads && leads.filter(item => item.consultora === consultora));
        setLeadsSheets(leadsSheetsRef && leadsSheetsRef);
        setVendasSheets(vendasSheetsRef && vendasSheetsRef);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, consultora, leads, activity]);

  // console.log(dataChart)

  useEffect(() => {
    if (dateValue || consultora) {
      sendData({
        data_inicio: dateValue && moment(dateValue[0]).format('DD/MM/YYYY'),
        data_final: dateValue && moment(dateValue[1]).format('DD/MM/YYYY'),
        consultora: consultora && consultora,
        visitas: visitas && visitas.length,
        visitas_confirmada: confirmar,
        visitas_naoConfirmada: nconfirmar,
        visitas_meta: meta.current.visitas,
        visitas_metaR: visitas && (visitas.length / meta.current.visitas * 100).toFixed(0),
        vendas: vendasSheets && vendasSheets.length,
        vendas_meta: meta.current.vendas,
        vendas_metaR: vendasSheets && (vendasSheets.length / meta.current.vendas * 100).toFixed(0),
        leads: leadsSheets && leadsSheets.length + ganho,
        leadsSheet_robo: leadsRobo,
        leadsSheet_meetime: leadsMeetime,
        leadsSheet_ganho: ganho,
        prospeccao: allLeads && allLeads.length,
        prospeccao_ganho: ganho,
        prospeccao_perdido: perdido,
        atividades: atividades && atividades.length,
        atividades_email: data1,
        atividades_ligacao: data2,
        atividades_whats: data3,
        atividades_meta: meta.current.atividades,
        atividades_metaR: atividades && (atividades.length / meta.current.atividades * 100).toFixed(0)
      })
      // console.log({
      //   visitas: visitas && visitas.length,
      //   visitas_confirmada: confirmar,
      //   visitas_naoConfirmada: nconfirmar,
      //   visitas_meta: 5,
      //   visitas_metaR: visitas && visitas.length/5*100,
      //   vendas: vendasSheets && vendasSheets.length,
      //   vendas_meta: 2,
      //   vendas_metaR: vendasSheets && vendasSheets.length/5*100,
      //   leads: leadsSheets && leadsSheets.length + ganho,
      //   leadsSheet_robo: leadsRobo,
      //   leadsSheet_meetime: leadsMeetime,
      //   leadsSheet_ganho: ganho,
      //   prospeccao: allLeads && allLeads.length,
      //   prospeccao_ganho: ganho,
      //   prospeccao_perdido: perdido,
      //   atividades: atividades && atividades.length,
      //   atividades_email: data1,
      //   atividades_ligacao: data2,
      //   atividades_whats: data3,
      //   atividades_meta: 100,
      //   atividades_metaR: atividades && atividades.length/100*100})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, consultora, visitas, confirmar, nconfirmar, vendasSheets, leadsSheets, ganho, leadsRobo, leadsMeetime, allLeads, perdido, atividades, data1, data2, data3])


  useEffect(() => {
    if (atividades && consultora === 'Geral') {
      setData1(atividades ? atividades.filter((vis) => vis.atividade === 'Email').length : activity && activity.filter((vis) => vis.atividade === 'Email').length);
      setData2(atividades ? atividades.filter((vis) => vis.atividade === 'Ligação').length : activity && activity.filter((vis) => vis.atividade === 'Ligação').length);
      setData3(atividades ? atividades.filter((vis) => vis.atividade === 'WhatsApp').length : activity && activity.filter((vis) => vis.atividade === 'WhatsApp').length);
    } else {
      setData1(atividades ? atividades.filter((vis) => vis.atividade === 'Email' && vis.consultora !== 'Leticia').length : 0);
      setData2(atividades ? atividades.filter((vis) => vis.atividade === 'Ligação' && vis.consultora !== 'Leticia').length : 0);
      setData3(atividades ? atividades.filter((vis) => vis.atividade === 'WhatsApp' && vis.consultora !== 'Leticia').length : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atividades, allLeads, dateValue, activity, consultora])

  useEffect(() => {
    if (leadsSheets || dateValue) {
      setLeadsRobo(leadsSheets && leadsSheets.filter((lead) => lead[10] === '').length);
      setLeadsMeetime(leadsSheets && leadsSheets.filter((lead) => lead[10] !== '').length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadsSheets, dateValue])

  useEffect(() => {
    if (visitas && dateValue) {
      if (consultora === 'Bruna Mayara Paulo') {
        let dataChartRef = [];
        let dataChartRef2 = [];
        let inicio2 = moment(dateValue[0]);
        let final = moment(dateValue[1]);
        // let nomes = ['Ana', 'Bruna', 'Lia'];
        // nomes.map(data => (
        for (let inicio = inicio2; inicio <= final; inicio.add(1, 'days')) {
          dataChartRef.push({
            name: moment(inicio).format("DD/MM/YYYY"),
            Bruna: visitas && visitas.filter((v) => v.data === moment(inicio).format("YYYY-MM-DD") && v.consultora === 'Bruna' && v.categoria !== 'pos_venda').length, 
            Mayara: visitas && visitas.filter((v) => v.data === moment(inicio).format("YYYY-MM-DD") && v.consultora === 'Mayara' && v.categoria !== 'pos_venda').length,
            Paulo: visitas && visitas.filter((v) => v.data === moment(inicio).format("YYYY-MM-DD") && v.consultora === 'Paulo' && v.categoria !== 'pos_venda').length, 
          })
        }
        dataChartRef2 = dataChartRef.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } return 0;
        })
        setdataChart(dataChartRef2)
      } else {
        let dataChartRef = [];
        let dataChartRef2 = [];
        let inicio2 = moment(dateValue[0]);
        let final = moment(dateValue[1]);
        for (let inicio = inicio2; inicio <= final; inicio.add(1, 'days')) {
          dataChartRef.push({
            name: moment(inicio).format("DD/MM/YYYY"), 
            Confirmada: visitas && visitas.filter((v) => v.data === moment(inicio).format("YYYY-MM-DD") && v.confirmar === true && v.categoria !== 'pos_venda').length,
            Nao_Confirmada: visitas && visitas.filter((v) => v.data === moment(inicio).format("YYYY-MM-DD") && v.confirmar === false && v.categoria !== 'pos_venda').length
          })
        }
        dataChartRef2 = dataChartRef.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } return 0;
        })
        setdataChart(dataChartRef2)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitas, dateValue])

  console.log(dataChart);

  useEffect(() => {
    if (vendasSheets && dateValue) {
      if (consultora === 'Bruna Mayara Paulo' || consultora === 'Geral') {
        let dataChartRef = [];
        let dataChartRef2 = [];
        let dataChartRef3 = [];
        let dataChartRef4 = [];
        let inicio2 = moment(dateValue[0]);
        let final = moment(dateValue[1]);
        let nomes = ['Bruna', 'Paulo', 'Mayara'];
        // nomes.map(data => (
        for (let inicio = inicio2; inicio <= final; inicio.add(1, 'days')) {
          dataChartRef.push({
            name: moment(inicio).format("DD/MM/YYYY"), 
            Bruna: vendasSheetsRef && vendasSheetsRef.filter((item) => moment(item[0]).format('YYYY-MM-DD') === moment(inicio).format("YYYY-MM-DD") && item[7] === 'Bruna').length, 
            Mayara: vendasSheetsRef && vendasSheetsRef.filter((item) => moment(item[0]).format('YYYY-MM-DD') === moment(inicio).format("YYYY-MM-DD") && item[7] === 'Mayara').length,
            Paulo: vendasSheetsRef && vendasSheetsRef.filter((item) => moment(item[0]).format('YYYY-MM-DD') === moment(inicio).format("YYYY-MM-DD") && item[7] === 'Paulo').length
          })
        }
        dataChartRef2 = dataChartRef.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } return 0;
        })

        nomes.map((item) => (
          dataChartRef3.push({
            name: item,
            valor: vendasSheets && vendasSheets.filter((data) => (data[7] === item)).reduce((soma, subArray) => soma + subArray[2], 0).toFixed(0),
            fill: (item === 'Mayara' && '#e5dd06') || (item === 'Bruna' && '#44BF2B') || (item === 'Paulo' && '#063')
          })
        ));
        dataChartRef4 = dataChartRef3.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } return 0;
        })
        setdataChart3(dataChartRef2)
        setdataChart4(dataChartRef4)
      } else {
        let dataChartRef = [];
        let dataChartRef2 = [];
        let inicio2 = moment(dateValue[0]);
        let final = moment(dateValue[1]);
        for (let inicio = inicio2; inicio <= final; inicio.add(1, 'days')) {
          dataChartRef.push({
            name: moment(inicio).format("DD/MM/YYYY"), Confirmada: visitas && visitas.filter((v) => v.data === moment(inicio).format("YYYY-MM-DD") && v.confirmar === true && v.categoria !== 'pos_venda').length,
            Nao_Confirmada: visitas && visitas.filter((v) => v.data === moment(inicio).format("YYYY-MM-DD") && v.confirmar === false && v.categoria !== 'pos_venda').length
          })
        }
        dataChartRef2 = dataChartRef.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } return 0;
        })
        setdataChart(dataChartRef2)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendasSheets, dateValue])

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  const renderCustomAxisTick = ({ x, y, payload }) => {
    return (
      <text x={x} y={y} fill="#666" textAnchor="end" dy={8}>
        {formatter.format(payload.value)}
      </text>
    );
  };

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip">
  //         <p className="label">{`${label} : ${formatter.format(payload[0].value)}`}</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  useEffect(() => {
    if (dateValue) {
      const countDays = () => {
        let count = 0;
        let dataInicio = moment(dateValue[0]);
        let dataFim = moment(dateValue[1]);

        while (dataInicio <= moment(dataFim)) {
          if (dataInicio.day() !== 0 && dataInicio.day() !== 6) {
            count++;
          }
          dataInicio.add(1, 'days');
        }
        daysRef.current = count;
        meta.current.vendas = consultora && (consultora !== 'Geral' && consultora !== 'Bruna Mayara Paulo') ? count / 5 : (count / 5) * 3
        meta.current.visitas = consultora && (consultora !== 'Geral' && consultora !== 'Bruna Mayara Paulo') ? count * 1 : (count * 1) * 3
        meta.current.ganho = consultora && consultora !== 'Geral' ? count * 1 : (count * 1) * 3
        meta.current.atividades = consultora && (consultora !== 'Geral' && consultora !== 'Bruna Mayara Paulo') ? count * 20 : (count * 20) * 3;
      }
      countDays();
    }
  }, [dateValue, consultora])

  useEffect(() => {
    if (atividades && dateValue) {
      if (consultora === 'Bruna Mayara Paulo') {
        let dataChartRef = [];
        let dataChartRef2 = [];
        // let final = moment(dateValue[1]);
        // let inicio2 = moment(dateValue[0]);
        // for(let inicio = inicio2; inicio <= final; inicio.add(1, 'days')) {

        //     dataChartRef.push({ name: moment(inicio2).format("DD/MM/YYYY"), Atividades: atividades && atividades.filter((v) => v.dataRef === moment(inicio2).format("YYYY-MM-DD")).length})
        //   }
        let nomes = ['Bruna', 'Paulo', 'Mayara'];
        nomes.map(data => (
          dataChartRef.push({
            name: data,
            Atividades: atividades && atividades.filter((v) => v.consultora === data).length,
            fill: (data === 'Mayara' && '#e5dd06') || (data === 'Bruna' && '#44BF2B') || (data === 'Paulo' && '#063')
          })
        ))
        dataChartRef2 = dataChartRef.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } return 0;
        })
        setdataChart2(dataChartRef2)
      } else {
        let dataChartRef = [];
        let dataChartRef2 = [];
        let final = moment(dateValue[1]);
        let inicio2 = moment(dateValue[0]);
        for (let inicio = inicio2; inicio <= final; inicio.add(1, 'days')) {

          dataChartRef.push({ name: moment(inicio2).format("DD/MM/YYYY"), Atividades: atividades && atividades.filter((v) => v.dataRef === moment(inicio2).format("YYYY-MM-DD")).length })
        }
        dataChartRef2 = dataChartRef.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } return 0;
        })
        setdataChart2(dataChartRef2)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitas, dateValue])

  // useEffect(() => {
  //   if(atividades) {
  //     let dataChartRef = [];
  //     let dataChartRef2 = [];
  //     let dia = [];
  //       atividades && atividades.forEach((vis) => {
  //         if(!dia.includes(moment(vis.createAt.seconds*1000).format('YYYY-MM-DD'))) {   
  //           dia.push(moment(vis.createAt.seconds*1000).format('YYYY-MM-DD'))
  //           dataChartRef.push({ name: moment(vis.createAt.seconds*1000).format('DD/MM/YYYY'), Atividades: atividades && atividades.filter((v) => moment(v.createAt.seconds*1000).format('YYYY-MM-DD') === moment(vis.createAt.seconds*1000).format('YYYY-MM-DD')).length})
  //         }
  //       })
  //       dataChartRef2 = dataChartRef.sort((a,b) => {
  //         if(a.name > b.name) {
  //           return 1;
  //         } else if(a.name < b.name) {
  //           return -1;
  //         } return 0;
  //       })
  //       setdataChart2(dataChartRef2)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[visitas])


  return (
    <div className={styles.dashboard}>
      <h2 className={styles.dashboard_title}>Visitas e Vendas</h2>
      <div className={styles.dashboard__content} style={{ marginBottom: '1rem' }}>
        <div className={styles.dashboard__box1}>
          <div className={styles.dashboard__box1_info}>
            <div className={styles.dashboard__box1_info_list}>
              {consultora === 'Bruna Mayara Paulo' ?
                <h1 className={styles.titles}>
                  <b data-cooltipz-dir="top" aria-label='Bruna' style={{ color: colors[1] }}> {visitas2 && visitas2.bruna.length ? visitas2.bruna.length : 0}</b>
                  <b data-cooltipz-dir="top" aria-label='Mayara' style={{ color: colors[0] }}>{visitas2 && visitas2.mayara.length ? visitas2.mayara.length : 0}</b>
                  <b data-cooltipz-dir="top" aria-label='Paulo' style={{ color: colors[2] }}> {visitas2 && visitas2.paulo.length ? visitas2.paulo.length : 0}</b>
                </h1> :
                <h1>{visitas && visitas.length ? visitas.length : 0}</h1>
              }
              <h3>Visitas Agendadas</h3>
            </div>
            <div className={styles.dashboard__box1_info_list}>
              <div>
                <CheckIcon />
                <h1>{confirmar ? confirmar : 0}</h1>
                <p>Confirmada(s)</p>
              </div>
              <div>
                <BlockIcon />
                <h1>{nconfirmar ? nconfirmar : 0}</h1>
                <p>Não Confirmada(s)</p>
              </div>
            </div>
            <div className={styles.dashboard__meta}>
              <span className={styles.visit_icon}><Trophy /></span>
              <p>Meta do Periodo</p>
              <h2>{meta.current.visitas ? meta.current.visitas : 0}</h2>
            </div>
          </div>
        </div>
        <div className="dashboard__box3">
          {dateValue ?
            <><h2>Visitas</h2>
              <ResponsiveContainer width="95%" height="80%">
                {consultora === 'Bruna Mayara Paulo' ?
                  <LineChart
                    width={500}
                    height={300}
                    data={dataChart}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line dataKey="Bruna" strokeWidth={2} type="monotone" stroke={colors[1]} />
                    <Line dataKey="Mayara" strokeWidth={2} type="monotone" stroke={colors[0]} />
                    <Line dataKey="Paulo" strokeWidth={2} type="monotone" stroke={colors[2]} />
                  </LineChart> :
                  <LineChart
                    width={500}
                    height={300}
                    data={dataChart}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={[-1, 'dataMax + 5']} allowDataOverflow={true} />
                    <Tooltip />
                    <Legend />
                    <Line type="basics" legendType='plainline' name='Confirmada' strokeWidth={2} dataKey="Confirmada" stroke="green" activeDot={{ r: 8 }}>
                      <LabelList dataKey="Confirmada" offset={8} position="top" />
                    </Line>
                    <Line type="basics" legendType='plainline' name='Não Confirmada' strokeWidth={2} dataKey="Nao_Confirmada" label="Não Confirmada" stroke="red" activeDot={{ r: 8 }}>
                      <LabelList dataKey="Nao_Confirmada" offset={8} position="top" />
                    </Line>
                  </LineChart>
                }
              </ResponsiveContainer></> :
            <h2 className={styles.dashboard_alert}>Selecione um período de data para visualizar o gráfico de visita</h2>
          }
        </div>
        <div className={styles.dashboard__box4}>
          <div className={styles.dashboard__box4_item}>
            {leadsSheets ?
              <><div>
                {consultora === 'Bruna Mayara Paulo' ?
                  <h1>
                    <b data-cooltipz-dir="top" aria-label='Bruna' style={{ color: colors[1] }}> {(leadsSheets2 && ganho2) && leadsSheets2.bruna?.length + ganho2.bruna}</b>
                    <b data-cooltipz-dir="top" aria-label='Mayara' style={{ color: colors[0] }}> {(leadsSheets2 && ganho2) && leadsSheets2.mayara?.length + ganho2.mayara}</b>
                    <b data-cooltipz-dir="top" aria-label='Paulo' style={{ color: colors[2] }}> {(leadsSheets2 && ganho2) && leadsSheets2.paulo?.length + ganho2.paulo}</b>
                  </h1> :
                  <h1>{(leadsSheets && ganho) && leadsSheets.length ? leadsSheets.length + ganho : leadsSheets && leadsSheets.length}</h1>
                }
                <h2>Leads</h2>
              </div><div>
                  <p style={{ textAlign: 'center' }}><b>{leadsRobo && leadsRobo}</b> Trafégo Pago | <b>{leadsMeetime && leadsMeetime}</b> Meetime <br /> <b>{ganho && ganho}</b> Prospecção</p>
                </div></> :
              <Stack width='90%'>
                <Skeleton variant='rounded' width={100} height={50} />
                <Skeleton variant='rounded' width='100%' height={50} />
              </Stack>
            }
          </div>
          <div className={styles.dashboard__box4_item2}>
            {vendasSheets ?
              <><div>
                {consultora === 'Bruna Mayara Paulo' ?
                  <h1>
                    <b data-cooltipz-dir="top" aria-label='Bruna' style={{ color: colors[1] }}> {vendasSheets2 ? vendasSheets2.bruna?.length : 0}</b>
                    <b data-cooltipz-dir="top" aria-label='Mayara' style={{ color: colors[0] }}> {vendasSheets2 ? vendasSheets2.mayara?.length : 0}</b>
                    <b data-cooltipz-dir="top" aria-label='Paulo' style={{ color: colors[2] }}> {vendasSheets2 ? vendasSheets2.paulo?.length : 0}</b>
                  </h1> :
                  <h1>{vendasSheets ? vendasSheets.length : 0}</h1>
                }
                <h2>Vendas</h2>
                {consultora === 'Bruna Mayara Paulo' ?
                  <h3>
                    <b style={{ color: 'green' }}>{vendasSheets2 && meta.current.vendas && dateValue ? (((vendasSheets2.mayara?.length + vendasSheets2.bruna?.length + vendasSheets2.paulo?.length) / meta.current.vendas) * 100).toFixed(0) : 0}%</b>&nbsp;da Meta Alcançada
                  </h3> :
                  <h3>
                    <b style={{ color: 'green' }}>{vendasSheets && meta.current.vendas && dateValue ? (((vendasSheets.length) / meta.current.vendas) * 100).toFixed(0) : 0}%</b>&nbsp;da Meta Alcançada
                  </h3>
                }
              </div><div className={styles.dashboard__meta}>
                  <span className={styles.visit_icon}><Trophy /></span>
                  <p>Meta do Periodo</p>
                  <h2>{Math.floor(meta.current.vendas && dateValue ? meta.current.vendas : 0)}</h2>
                </div></> :
              <Stack width='90%' justifyContent='center'>
                <Skeleton variant='rounded' width={100} height={40} />
                <Skeleton variant='rounded' width={100} height={40} />
                <Skeleton variant='rounded' width='100%' height={30} />
                <Skeleton variant='rounded' width='100%' height={30} />
              </Stack>
            }
          </div>
        </div>
      </div>
      {(consultora === 'Geral' || consultora === 'Bruna Mayara Paulo') &&
        <div className={styles.dashboard__content}>
          <div className="dashboard__box3">
            {dateValue ?
              <><h2>Vendas</h2>
                <ResponsiveContainer width="95%" height="80%">
                  <BarChart
                    width={500}
                    height={300}
                    data={dataChart3}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Bruna" fill={colors[1]} />
                    <Bar dataKey="Mayara" fill={colors[0]} />
                    <Bar dataKey="Paulo" fill={colors[2]} />
                  </BarChart>
                </ResponsiveContainer></> :
              <h2 className={styles.dashboard_alert}>Selecione um período de data para visualizar o gráfico de visita</h2>
            }
          </div>
          <div className="dashboard__box3">
            {dateValue ?
              <><h2>Valor Total de Vendas</h2>
                <ResponsiveContainer width="90%" height="80%">
                  <BarChart
                    width={500}
                    height={300}
                    data={dataChart4}
                    margin={{
                      top: 20,
                      right: 20,
                      left: 60,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tick={renderCustomAxisTick} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="valor" name='Valor Total' stackId="a" fill='#327125' />
                  </BarChart>
                </ResponsiveContainer></> :
              <h2 className={styles.dashboard_alert}>Selecione um período de data para visualizar o gráfico de atividades</h2>
            }
          </div>
        </div>
      }
      <h2 className={styles.dashboard_title}>Prospecção</h2>
      <div className={styles.dashboard__content}>
        <div className={styles.dashboard__box2}>
          <div className={styles.dashboard__box2_info}>
            <div className={styles.dashboard__box2_info_list}>
              {consultora === 'Bruna Mayara Paulo' ?
                <h1 className={styles.titles}>
                  <b data-cooltipz-dir="top" aria-label='Bruna' style={{ color: colors[1] }}> {allLeads2 && allLeads2.bruna.length ? allLeads2.bruna.length : 0}</b>
                  <b data-cooltipz-dir="top" aria-label='Mayara' style={{ color: colors[0] }}>{allLeads2 && allLeads2.mayara.length ? allLeads2.mayara.length : 0}</b>
                  <b data-cooltipz-dir="top" aria-label='Paulo' style={{ color: colors[2] }}> {allLeads2 && allLeads2.paulo.length ? allLeads2.paulo.length : 0}</b>
                </h1> :
                <h1>{allLeads && allLeads.length ? allLeads.length : 0}</h1>
              }
              <h2>Leads Ativos</h2>
            </div>
            <div className={styles.dashboard__box2_info_list}>
              <div>
                <HowToRegOutlinedIcon style={{ fill: 'green' }} />
                <h1>{ganho && ganho ? ganho : 0}</h1>
                <p>Leads Ganhos</p>
              </div>
              <div>
                <PersonAddDisabledOutlinedIcon style={{ fill: 'red' }} />
                <h1>{perdido ? perdido : 0}</h1>
                <p>Leads Perdidos</p>
              </div>
            </div>
            <div className={styles.dashboard__box2_info_list}>
              <h3><b style={{ color: 'green' }}>{ganho && meta.current.ganho && dateValue ? ((ganho && ganho / meta.current.ganho) * 100).toFixed(0) : 0}%</b>&nbsp;da Meta Alcançada</h3>
            </div>
            <div className={styles.dashboard__meta}>
              <span className={styles.visit_icon}><Trophy /></span>
              <p>Meta do Periodo</p>
              <h2>{meta.current.ganho && dateValue ? meta.current.ganho : 0}</h2>
            </div>
          </div>
        </div>
        <div className={styles.dashboard__box2}>
          <div className={styles.dashboard__box2_info}>
            <div className={styles.dashboard__box2_info_list}>
              {consultora === 'Bruna Mayara Paulo' ?
                <h1 className={styles.titles}>
                  <b data-cooltipz-dir="top" aria-label='Bruna' style={{ color: colors[1] }}> {atividades2 && atividades2.bruna?.length ? atividades2.bruna.length : 0}</b>
                  <b data-cooltipz-dir="top" aria-label='Mayara' style={{ color: colors[0] }}>{atividades2 && atividades2.mayara?.length ? atividades2.mayara.length : 0}</b>
                  <b data-cooltipz-dir="top" aria-label='Paulo' style={{ color: colors[2] }}> {atividades2 && atividades2.paulo?.length ? atividades2.paulo.length : 0}</b>
                </h1> :
                <h1>{atividades && atividades ? atividades.length : 0}</h1>
              }
              <h2>Atividades Realizadas</h2>
            </div>
            <ul className={styles.dashboard__box2_info_list}>
              <li className={styles.dashboard__box2_info_list_item}>
                <div>
                  <span style={{ backgroundColor: '#8a8a8a' }}><Email /></span>
                  <p>Email</p>
                </div>
                <h3>{data1 ? data1 : 0}</h3>
              </li>
              <li className={styles.dashboard__box2_info_list_item}>
                <div>
                  <span style={{ backgroundColor: '#576af5' }}><Phone /></span>
                  <p>Ligação</p>
                </div>
                <h3>{data2 ? data2 : 0}</h3>
              </li>
              <li className={styles.dashboard__box2_info_list_item}>
                <div>
                  <span style={{ backgroundColor: '#44bf2b' }}><WhatsApp /></span>
                  <p>WhatsApp</p>
                </div>
                <h3>{data3 ? data3 : 0}</h3>
              </li>
            </ul>
            <div className={styles.dashboard__box2_info_list}>
              <h3><b style={{ color: 'green' }}>{atividades && meta.current.atividades && dateValue ? ((atividades.length / meta.current.atividades) * 100).toFixed(0) : 0}%</b>&nbsp;da Meta Alcançada</h3>
            </div>
            <div className={styles.dashboard__meta}>
              <span className={styles.visit_icon}><Trophy /></span>
              <p>Meta do Periodo</p>
              <h2>{meta.current.atividades && dateValue ? meta.current.atividades : 0}</h2>
            </div>
          </div>
        </div>
        <div className="dashboard__box3">
          {dateValue ?
            <><h2>Atividades</h2>
              <ResponsiveContainer width="95%" height="80%">
                {consultora === 'Bruna Mayara Paulo' ?
                  <BarChart
                    width={500}
                    height={300}
                    data={dataChart2}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Atividades" stackId="a" fill="rgb(126, 129, 255)" />
                  </BarChart> :
                  <LineChart
                    width={500}
                    height={300}
                    data={dataChart2}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={[-1, 'dataMax + 5']} allowDataOverflow={true} />
                    <Tooltip />
                    <Legend />
                    <Line type="basics" legendType='plainline' dataKey="Atividades" dot={true} stroke="rgb(126, 129, 255)" activeDot={{ r: 8 }}>
                      <LabelList dataKey="Atividades" position="top" offset={8} />
                    </Line>
                    {/* <ReferenceLine label="Meta" stroke="green" strokeDasharray="4 4" segment={[{ x: '02/10/2023', y: 0 }, { x: '10/10/2023', y: 400 }]} /> */}
                  </LineChart>}
              </ResponsiveContainer></> :
            <h2 className={styles.dashboard_alert}>Selecione um período de data para visualizar o gráfico de atividades</h2>
          }
        </div>
      </div>
    </div>
  )
}

export default memo(Dashboard);
import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// import NossaHistoria from '../../images/NossaHistoria.jpg';
import { ReactComponent as Google } from '../../images/icons/Google.svg';
import { ReactComponent as Instagram } from '../../images/icons/Instagram.svg';
import { ReactComponent as Facebook } from '../../images/icons/Facebook.svg';
import { ReactComponent as Tiktok } from '../../images/icons/Tiktok.svg';
import { ReactComponent as Youtube } from '../../images/icons/Youtube.svg';

import { Box, Divider, IconButton } from '@mui/material';
import SlickSlider from "react-slick";
import moment from 'moment';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactPlayer from 'react-player';

import imagesProject from '../../utils/importImages';

import { Depoimentos } from '../../data/Testimony';
import NewTooltip from '../../components/Tooltip';
import queryString from 'query-string';

const History = () => {
    const sliderProject = useRef();
    const sliderAssessments = useRef();
    const [name, setName] = useState(null);
    const settingsProjeto = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      };
    const settingsAssessments = settingsProjeto;

    // useEffect(() => {
    //     const getAccessToken = async () => {
    //       if (!accessToken) {
    //         const newAccessToken = await refreshAccessToken();
    //         setAccessToken(newAccessToken);
    //       }
    //     };
    
    //     getAccessToken();
    //   }, [accessToken]);

    // const { reviews, loading, error } = useGoogleReviews('ChIJJ6B1twQNxpQRLvrUQYY3WMY', '16044202400792665571', accessToken);

    useEffect(() => {

      const parsed = queryString.parse(window.location.search);
      setName(parsed.nome);

    },[])

    console.log(name)

    const calculateDifference = (startDate) => {
        const now = moment();
        const diffInDays = now.diff(startDate, 'days');
    
        if (diffInDays === 0) {
          return ('Hoje');
        } else if (diffInDays === 1) {
          return ('Ontem');
        } else if (diffInDays > 7) {
          const weeks = Math.floor(diffInDays / 7);
          return (`Há ${weeks} semana(s)`);
        } else {
          return (`Há ${diffInDays} dia(s)`);
        }
      };

    
  return (
    <div className={styles.assessments_container}>
      <HelmetProvider>
        <Helmet>
          <title>Conheça a CORPBRASIL</title>
          <meta name="title" content="Conheça a CORPBRASIL" />
          <meta name="description" content="A Experiência dos Nossos Clientes com Energia Solar" />
        </Helmet>
      </HelmetProvider>
      {/* {name ?  
        null : 
        <div className="fab">
            <div>
            <Badge anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} badgeContent={'1'} color="error">
            <button data-cooltipz-size="large" className={"btn-cotacao whats tooltip"} aria-label={'Está com dúvidas? Entre em contato conosco'} data-cooltipz-dir="left">
              <a id="Botão-Flutuante_Entrar_em_Contato" href="https://bit.ly/CORPBRASIL-WhatsApp"><WhatsAppLogo /></a>
            </button>
          </Badge>
            </div>
        </div>
      } */}
        <div className={styles.assessments_content}>
            <div className={styles.assessments_header}>
                {/* <img src={Logo} alt='' /> */}
                <div className={`${styles.title} ${styles.title_mobile}`}>
                    <div className={styles.title_mobile}>
                      <h1>Conheça a</h1>
                      <div className={styles.title_mobile_text}>
                        <h1>CORPBRASIL</h1>
                        <div className={styles.title_logo}>
                          <h3>ENERGIA</h3>
                          <h3>RENOVÁVEL</h3>
                        </div>
                      </div>
                        {/*<p>
                        <b>{name}</b>{name ? ', na' : 'Na'} CorpBrasil transformamos desafios em oportunidades, impulsionados pela necessidade de soluções energéticas sustentáveis. Criamos a <b>CorpBrasil Energia Renovável</b> para fornecer sistemas fotovoltaicos de alta qualidade e a <b >CorpBrasil Facilits</b> para oferecer serviços de manutenção e limpeza contínuos. Com um compromisso inabalável com a eficiência e a sustentabilidade, convidamos você a se juntar a nós na construção de um futuro mais verde e brilhante.</p> */}
                          <div className={`${styles.corpbrasil_video} ${styles.video_16_9}`} style={{ height: '515px' }}>
                            <ReactPlayer 
                              width='100%'
                              height='100%'
                              id="video-promocional"     
                              url='https://streamable.com/ablnqq' 
                              controls />
                          </div>
                    </div> 
                </div>
            </div>
            <div className={styles.assessments_main}>
                <div className={styles.assessments_item}>
                    <Google />
                    <div className={styles.title}>
                        <h1>Avaliações no Google</h1>
                    </div>
                    <Box
                        sx={{
                            width: 200,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            justifyContent: 'center'
                        }}
                        >
                        <Box><h3>4,9</h3></Box>
                        <Rating
                            name="google"
                            value={5}
                            readOnly
                            precision={0.5}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                    </Box>
                    {/* <a href='https://www.google.com.br/search?ibp=gwp;0,7&q=CORPBRASIL+Energia+Renov%C3%A1vel&ludocid=14292234467229891118&lsig=AB86z5XXpvP_MoJFrWKKLazEa2CV#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D ' target='_blank' rel='noreferrer'>Ver avaliações</a> */}
                    <SlickSlider id='Slider-Google' {...settingsAssessments} ref={sliderAssessments} className={styles.slider}>
                        {Depoimentos.map((data, index) => (
                            <div key={index} className={styles.google_assessment}>
                                <div className={styles.google_assessment_item}>
                                  <div className={styles.google_assessment_item_header}>
                                      {data.foto ? 
                                          <img src={data.foto} alt='' /> : 
                                          <div className={styles.profile_photo} style={{ backgroundColor: data.cor }}>
                                              <b>{data.nome.charAt(0).toUpperCase()}</b>
                                          </div>
                                      }
                                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <NewTooltip title='Visualizar comentário no Google' placement='top'>
                                          <a href={data.link} target='_blank' rel='noreferrer' className={styles.name}>{data.nome}</a>
                                        </NewTooltip>
                                        <span style={{ fontSize: '0.8rem' }}>{data.avaliacoes === '1' ? `${data.avaliacoes} avaliação` : `${data.avaliacoes} avaliações` } </span>
                                      </Box>
                                  </div>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', marginBottom: '0.5rem' }}>
                                        <Rating
                                        name="google"
                                        value={5}
                                        readOnly
                                        precision={0.5}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                    />
                                    <p>{calculateDifference(data.data)}</p>
                                    </Box>
                                  <Divider />
                                  <div className={styles.google_assessment_item_text}>
                                    <p>{data.texto}</p>
                                  </div>
                                </div>
                            </div>
                        ))}
                    </SlickSlider>
                    <Box>
                        <IconButton onClick={() => sliderAssessments.current.slickPrev()}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton onClick={() => sliderAssessments.current.slickNext()}><KeyboardArrowRightIcon /></IconButton>
                    </Box>
                </div>
                <div className={styles.assessments_item}>
                    <div className={styles.title}>
                        <h1>Instalações</h1>
                        <h1>Concluídas</h1>
                    </div>
                    <SlickSlider {...settingsProjeto} ref={sliderProject} className={styles.slider}>
                        {imagesProject.map((projeto, index) => (
                            <div key={index} className={styles.project}>
                                <img className={styles.project_item} rel="preconnect" src={projeto} alt="" />
                            </div>
                        ))}
                    </SlickSlider>
                    <Box>
                        <IconButton onClick={() => sliderProject.current.slickPrev()}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton onClick={() => sliderProject.current.slickNext()}><KeyboardArrowRightIcon /></IconButton>
                    </Box>
                </div>
                <div className={styles.assessments_item} style={{ padding: '0 1rem 1rem 1rem' }}>
                    <h2 className={styles.thin_text}>“<b>Gerando energia, transformando vidas</b>”</h2>
                    <div className={`${styles.corpbrasil_video} ${styles.video_9_9}`} style={{ height: '900px' }}>
                      <ReactPlayer 
                        width='100%'
                        height='100%'
                        url='https://streamable.com/hql58e' 
                        controls />
                    </div>
                </div>
                <footer>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Box sx={{ display: 'flex', gap: '0.9rem'}}>
                            <a href='https://www.instagram.com/corpbrasilenergia/' target='_blank' rel='noreferrer'>
                                <Instagram />
                            </a>
                            <a href='https://www.facebook.com/corpbrasilenergia' target='_blank' rel='noreferrer'>
                                <Facebook />
                            </a>
                            <a href='https://www.tiktok.com/@corpbrasilenergia' target='_blank' rel='noreferrer'>
                                <Tiktok/>
                            </a>
                            <a href='https://www.youtube.com/channel/UCcrOtQLkQOpvTfO3OyfYxyA' target='_blank' rel='noreferrer'>
                                <Youtube/>
                            </a>
                        </Box>
                        <h3>@corpbrasilenergia</h3>
                    </Box>
                    <div className={styles.info}>
                        <p>Rua José Ferreira Alves, 163, Nova Tietê/SP</p>
                        <p>0800 591 6174 <b style={{ color: '#FFBA40' }}>|</b>
                         <a href='https://www.corpbrasil.com'>www.<b>CORPBRASIL</b>.com</a>
                         </p>
                    </div>
                </footer>            
            </div>
        </div>
    </div>
  )
}

export default History;
import { useState, forwardRef, useEffect } from "react";
import { onSnapshot, query, collection, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";

// import Header from "../../components/Header/Index";
// import Create from "./Components/Create/Index";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// import  Swal  from "sweetalert2/dist/sweetalert2";
// import axios from 'axios';
import { dataBase } from "../../firebase/database";


// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import DeleteIcon from '@mui/icons-material/Delete';
// import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
// import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import BoltIcon from '@mui/icons-material/Bolt';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import CancelIcon from '@mui/icons-material/Cancel';
// import EditIcon from '@mui/icons-material/Edit';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

// Css
import "cooltipz-css";
import style from "./styles.module.scss";
import "../../styles/_buttons.scss";
import Logo from '../../images/logo192.png';

// import AddLead from "./Components/Leads/Add";
import { theme } from "../../data/theme";
import { ThemeProvider } from "@emotion/react";
import { Box, Button, Checkbox, Chip, FormControlLabel, Tooltip } from "@mui/material";
import NewTooltip from "../../components/Tooltip";
import AddCustomer from "./Components/Add";
import { useMember } from "../../hooks/useMember";
import useGoPage from "../../hooks/useGoPage";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import RegisterVisit from "./Components/RegisterVisit";
import useWindowWidth from "../../hooks/useWindowWidth";

const Customers = ({ isLoaded, userRef, check }) => {
  const width = useWindowWidth();
  const navigate = useNavigate();
  const { getMemberColor } = useMember();
  const goPage = useGoPage();
  const [search, setSearch] = useState('');
  const [viewProspection, setviewProspection] = useState(false);
  const [option, setOption] = useState([]);
  // const [sheets, setSheets] = useState([]);
  const [leadsRef, setLeadsRef] = useState([]);
  const [leadsRefAll, setLeadsRefAll] = useState([]);
  const [leads, setLeads] = useState([]);
  const [members, setMembers] = useState([]);
  const [view, setView] = useState({
    add: false,
    register: false
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const chipProps = {
    ganho: {
      label: width > 600 ? 'Ganho' : 'G',
      sx: { backgroundColor: "#e9fbf0", fontWeight: '500' },
      icon: <ThumbUpRoundedIcon sx={{ fontSize: '22px', paddingLeft: '0.2rem' }} />,
      color: 'success',
    },
    perdido: {
      label: width > 600 ? 'Perdido' : 'P',
      sx: { backgroundColor: "#fbe9e9", fontWeight: '500' },
      icon: <ThumbDownRoundedIcon sx={{ fontSize: '22px', paddingLeft: '0.2rem' }} />,
      color: 'error',
    },
    aberto: {
      label: width > 600 ? 'Aberto' : 'A',
      sx: { backgroundColor: "#f8f8f8", fontWeight: '500' },
      icon: <VisibilityRoundedIcon sx={{ fontSize: '22px', paddingLeft: '0.2rem' }} />,
    },
  };

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(query(collection(dataBase, "Clientes"), orderBy("createAt", 'desc')), (data) => {
          // Atualiza os dados em tempo real
          setLeadsRefAll(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
        onSnapshot(collection(dataBase, "Membros"), (data) => {
          // Atualiza os dados em tempo real
          setMembers(
            data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(() => {
    if (leadsRefAll) {
      if (userRef?.cargo !== "Administrador" && !userRef?.nome_ref) {
        const deniedConsultora = userRef.nome === 'Lara' ? 'Erika' : 'Lara';
        if (!userRef.time) {
          const leadsRule = leadsRefAll.filter((data) => data.consultora_uid === userRef.uid || data.representante?.id === userRef.uid || data.assistente === userRef.uid)
          setLeads(leadsRule);
          setLeadsRef(leadsRule);
        } else {
          if(viewProspection) {
            setLeads(leadsRefAll.filter((data) => data.consultora !== deniedConsultora));
          } else {
            setLeads(leadsRefAll.filter((data) => data.consultora !== deniedConsultora && (data.proposta?.length > 0 || (data.consultora === 'Paulo'))));
          }
          setLeadsRef(leadsRefAll.filter((data) => data.consultora !== deniedConsultora));
        }
      } else {
        if(viewProspection) {
          setLeads(leadsRefAll);
        } else {
          setLeads(leadsRefAll.filter((data) => (data.proposta?.length > 0 || (data.consultora === 'Paulo'))));
        }
        setLeadsRef(leadsRefAll)
      }

      const leadsHistory = leadsRef.filter((data) => data.historico);
      console.log(leadsHistory)

      // const updatePhones = async () => { // Para caso seja necessário atualizar alguma informação em massa dos clientes

      //   try {
      //     leadsRefAll.map(async (data) => (
      //       await updateDoc(doc(dataBase, 'Clientes', data.id), {
      //         historico: [{
      //             data: moment(data.createAt.toDate()).format('DD/MM/YYYY - HH:mm'),
      //             evento: 'Cliente Criado',
      //             text: `O Cliente foi criado pelo metódo <b>Novo Cliente</b>.`,
      //             usuario_id: data.consultora_uid,
      //             usuario_nome: data.consultora
      //           }],
      //       })
      //     ))

      //   } catch (error) {
      //      console.log('ERRO: ', error)
      //    }
      //  }

      // updatePhones();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadsRefAll, viewProspection])

  useEffect(() => {
    if (leads) {
      const uniqueOptions = leads.reduce((uniqueList, currentData) => {
        const isExisting = uniqueList.some(item => item.nome === currentData.nome);

        if (!isExisting && currentData?.nome?.trim() !== '') {
          uniqueList.push({
            id: uniqueList.length,
            nome: currentData.nome
          });
        }

        return uniqueList;
      }, []);
      console.log(uniqueOptions)
      setOption(uniqueOptions.sort((a, b) => a.nome?.localeCompare(b?.nome)))
    }
  }, [leads])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  console.log(leads)

  const handleChange = (event, newValue) => {
    if (newValue === "") {
      setSearch(newValue); // Se o novo valor for vazio, mantenha como vazio
    } else {
      // Verifica se o novo valor existe nas opções
      const optionExists = option.some(option => option.nome === newValue);

      if (optionExists) {
        setSearch(newValue);
        setLeads(leadsRefAll.filter(data => data.nome === newValue));
      } else {
        // Trate o caso em que o valor não corresponde a nenhuma opção existente, se necessário
      }
    }
  };

  const formatarTelefone = (telefone) => {
    // Adiciona os parênteses, traços e espaços conforme o formato do número de telefone
    return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  const formatText = (name) => {
    return name.length > 10 ? `${name.substring(0, 10)}...` : name
  };

  const formatTextLink = (name, id) => {
    const linkElement = (
      <Link className="link_black" to={`${id}`} onClick={() => goPage(null, 'top')}>
        {name.length > 15 ? `${name.substring(0, 15)}...` : name}
      </Link>
    );

    if (name.length > 15) {
      return (
        <NewTooltip title={name ?? ''} placement="top">
          {linkElement}
        </NewTooltip>
      );
    } else {
      return linkElement;
    }
  };

  const NewTableRow = ({ children, onClick }) => {
    const [showTooltip, setShowTooltip] = useState(true);

    const handleTrClick = (event) => {
      if (event.target === event.currentTarget || !event.target.closest('.clickable')) {
        onClick();
        setShowTooltip(false);
      }
    };

    const handleMouseOver = (event) => {
      if (event.target.closest('.clickable')) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    };

    return (
      <Tooltip
        title={showTooltip ? 'Clique para visualizar o Cliente' : ''}
        placement='bottom'
        followCursor={true}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10], // [skidding, distance]
              },
            },
          ],
        }}
      >
        <TableRow
          hover
          onClick={handleTrClick}
          onMouseOver={handleMouseOver}
          sx={{ cursor: 'pointer' }}
        >
          {children}
        </TableRow>
      </Tooltip>
    );
  };

  const goCustomer = (value) => {
    navigate(value);
    window.scrollTo(0, 0);
  }

  const getPhotoMember = (idMember) => {
    const member = members.find((member) => member.id === idMember);
    if (member) {
      return member.photo?.url || Logo;
    } else {
      return Logo;
    }
  }

  const getMember = (idMember) => {
    const member = members.find((member) => member.id === idMember);
    if (member) {
      return member.nome;
    }
    return 'Nenhum'
  }

  return (
    <div className={style.container_panel}>
      <div className={'title-panel'}>
        <GroupIcon sx={{ width: '62px', height: '62px', marginBottom: '0.5rem' }} />
        <h2>Clientes</h2>
      </div>
      <div className={style.content_panel}>
        {isLoaded &&
          <AddCustomer
            isLoaded={isLoaded}
            userRef={userRef}
            leads={leads}
            members={members}
            view={view.add}
            close={() => setView({ ...view, add: false })} />}
        {isLoaded &&
          <RegisterVisit
            userRef={userRef}
            leads={leads}
            members={members}
            view={view.register}
            close={() => setView({ ...view, register: false })} />}
        <div className={style.search}>
          <ThemeProvider theme={theme}>
            <Autocomplete
              value={search}
              disableClearable
              noOptionsText={'Nenhum Cliente Cadastrado'}
              sx={{ width: '15rem' }}
              options={option && option.map((option) => option.nome)}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === '') {
                  setLeads(leadsRef);
                  setSearch('');
                }
              }}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key={params.id}
                  sx={{ backgroundColor: "#fff" }}
                  label="Pesquisar Cliente"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              {userRef?.local === 'Rua'}
              <FormControlLabel
                control={
                  <Checkbox checked={viewProspection} color="success" onChange={() => setviewProspection(!viewProspection)} name="Clientes sem Orçamento" />
                }
                label="Todos Clientes" />
              {(userRef?.cargo === 'Vendedor(a)' || userRef?.cargo === 'Administrador') ?
                <>
                  {userRef.local === 'Empresa' &&
                    <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      variant='contained'
                      color="secondary"
                      startIcon={<AddLocationAltIcon />}
                      onClick={() => setView({ ...view, register: true })}>
                      Registrar Visita
                    </Button>
                  }
                  {userRef?.local === 'Rua' ?
                    <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      variant='contained'
                      color='success'
                      startIcon={<AddLocationAltIcon />}
                      onClick={() => setView({ ...view, register: true })}>
                      Registrar Visita
                    </Button> :
                    <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      variant='contained'
                      color='success'
                      startIcon={<PersonAddIcon />}
                      onClick={() => setView({ ...view, add: true })}>
                      Novo Cliente
                    </Button>
                  }
                </> : null
              }
            </Box>
          </ThemeProvider>
        </div>
        <TableContainer className={style.table_sheets} component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {userRef?.cargo === 'Representante' ?
                <TableRow>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Cidade</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow> :
                <TableRow>
                  <TableCell className={style.desktop} align="center">Status</TableCell>
                  {width > 600 &&
                    <><TableCell className={style.desktop} align="center">Data de Inscrição</TableCell>
                      <TableCell className={style.desktop} align="center">Planilhas</TableCell>
                      <TableCell className={style.desktop} align="center">Orçamentos</TableCell></>

                  }
                  <TableCell align="center">Nome</TableCell>
                  {width > 600 &&
                    <TableCell className={style.desktop} align="center">Empresa</TableCell>
                  }
                  <TableCell align="center">Cidade</TableCell>
                  <TableCell className={style.desktop} align="center">Telefone</TableCell>
                  {userRef?.cargo === 'Vendedor(a)' && !userRef?.time && userRef.nome !== 'Pós-Venda' ?
                    null :
                    <>
                      <TableCell className={style.desktop} align="center">Responsável</TableCell>
                      <TableCell className={style.desktop} align="center">Assistente</TableCell>
                    </>
                  }
                  {/* <TableCell align="center"></TableCell> */}
                </TableRow>
              }
            </TableHead>
            <TableBody>
              {leads.length > 0 ? leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                <NewTableRow
                  hover
                  key={index}
                  onClick={() => goCustomer(`${data.id}`)}
                >
                  {userRef && userRef.cargo === 'Representante' ?
                    <>
                      <TableCell align="center">{data.nome}</TableCell>
                      <TableCell align="center">{data.cidade}</TableCell></> :
                    <>
                      <TableCell className={style.desktop} align="center">
                        <Chip
                          {...chipProps[data.status?.valor || 'aberto']}
                          variant='outlined'
                          id="chip-status" />
                      </TableCell>
                      {width > 600 &&
                        <>
                          <TableCell className={style.desktop} align="center">{moment(data.createAt.toDate()).format("DD/MM/YYYY")}</TableCell>
                          <TableCell align="center"><b>{data.planilha?.length || 0}</b></TableCell>
                          <TableCell align="center"><b>{data.proposta?.length || 0}</b></TableCell>
                        </>
                      }
                      <TableCell align="center">{formatTextLink(data?.nome || '', data.id)}</TableCell>
                      {width > 600 &&
                        <TableCell className={style.desktop} align="center">{formatText(data?.empresa || '', data.id)}</TableCell>
                      }
                      <TableCell align="center">{data.cidade ?? 'Não Informado'}</TableCell>
                      <TableCell className={style.desktop} align="center">{formatarTelefone(String(data.telefone ?? 'Não Informado'))}</TableCell>
                      {userRef?.cargo === 'Vendedor(a)' && !userRef?.time && userRef.nome !== 'Pós-Venda' ?
                        null :
                        <><TableCell
                          sx={{ width: '145px' }}
                          align="center" >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(data.consultora_uid), borderRadius: '10px' }}>
                            <img src={getPhotoMember(data.consultora_uid)} alt={data.consultora} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                            <p style={{ color: "#fff" }}>{data.consultora}</p>
                          </Box>
                        </TableCell>
                          <TableCell sx={{ width: '145px' }} align="center">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(data.assistente), borderRadius: '10px' }}>
                              <img src={getPhotoMember(data.assistente)} alt={data.assistente} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                              <p style={{ color: getMember(data.assistente) === 'Nenhum' ? "#000" : "#fff" }}>{getMember(data.assistente)}</p>
                            </Box>
                          </TableCell></>
                      }
                    </>
                  }
                </NewTableRow>
              )) :
                <TableRow>
                  <TableCell align="center" sx={{ fontSize: '17px' }} colSpan={9}>
                    <p style={{ padding: '0.5rem 0' }}>Nenhum Cliente Cadastrado</p>
                  </TableCell>
                </TableRow>}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Clientes por página"
            component="div"
            count={leads ? leads.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <Snackbar open={check} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Você está sem conexão. Verifique a sua conexão com a internet.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Customers;

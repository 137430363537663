import { useEffect, useState } from 'react';

const File = ({ url, index, classname }) => {
  const [mediaType, setMediaType] = useState('');

  useEffect(() => {
    const validateMediaType = async () => {
      try {
        const pathWithoutQueryParams = url.split('?')[0];
        const fileExtension = pathWithoutQueryParams.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
          setMediaType('image');
        } else if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
          setMediaType('video');
        } else {
          setMediaType(null);
        }
      } catch (error) {
        console.error('Error validating media type:', error);
        setMediaType(null);
      }
    };

    if (url) {
      validateMediaType();
    }
  }, [url]);

  if (mediaType === null) {
    return <p>Carregando...</p>; // Ou qualquer outra lógica que você deseja aplicar enquanto a validação está em andamento.
  }

  if (mediaType === 'image') {
    return <img 
    key={index}
    className={classname}
    rel="preconnect"
    src={url}
    alt="" />;
  } else if (mediaType === 'video') {
    return <video 
    index={index}
    className={classname} 
    src={url}
    width="100%"
    playsInline 
    controls />;
  } else {
    return <p>URL inválida ou tipo de mídia desconhecido.</p>;
  }
}

export default File;
const formatCurrency = (value) => {
    let newValue = value;

    if (typeof value === 'string' && value.includes(',')) {
        newValue = value.replace(',','.')
    }

    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(newValue);

    return formattedValue;
}

export default formatCurrency;
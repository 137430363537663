import React, { useEffect, useState } from 'react'

import { Box, Button, Typography } from '@mui/material';

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../firebase/database';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import { ReactComponent as Instagram } from '../../images/icons/Instagram.svg';
import Newtooltip from '../../components/Tooltip';

import Logo2 from '../../images/LogoMulher.png';

import moment from 'moment';
import styles from './styles.module.scss';
import Swal from 'sweetalert2';
import axios from 'axios';

const ConfirmVisit = () => {

    const { idVisit } = useParams();
    const [visit, setVisit] = useState(null);


    useEffect(() => {
        const getVisit = async () => {
            try {
                const visitRef = doc(dataBase, 'Visitas', idVisit);
                const visitDoc = await getDoc(visitRef);
      
                if(visitDoc.exists()) {
                  setVisit(visitDoc.data())
                }
            } catch (error) {
                console.log('erro: ', error);
            }
        };
        getVisit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const sendConfirm = async (value) => {

        try {

            const result = await Swal.fire({
                title: 'Confirmação de Visita',
                html: `Você deseja realizar essa ação?`,
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0eb05f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
              })

            if(result.isConfirmed) {
                
                let props;
                if(value === '✅ Aceito') {
                    props = {
                        confirmarVisita: {
                            status: value,
                            data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`,
                        }
                    }
                } else {
                    const { value: text } = await Swal.fire({
                        title: 'Confirmação de Visita',
                        input: "textarea",
                        html: `Informe abaixo o horário combinado com a vendedora.</b>`,
                        inputPlaceholder: "Digite aqui...",
                        inputAttributes: {
                          "aria-label": "Digite aqui"
                        },
                        inputValidator: (value) => {
                          if (!value) {
                            return "Por favor, digite o hórario combinado para prosseguir.";
                          }
                        },
                        showCancelButton: true,
                        showCloseButton: true,
                        confirmButtonColor: "#0eb05f",
                        cancelButtonColor: "#111",
                        confirmButtonText: "Enviar",
                        cancelButtonText: "Fechar",
                      });

                      props = {
                        confirmarVisita: {
                            status: value,
                            resposta: `Resposta: ${text}`,
                            data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`,
                        }
                      }
                }

                console.log(props);

                await updateDoc(doc(dataBase, 'Visitas', idVisit), props);

                const consultoraDoc = await getDoc(doc(dataBase, 'Membros', visit.uid))

                let telefone = null;

                if(consultoraDoc.exists()) {
                    const consultoraData = consultoraDoc.data();
                    telefone = consultoraData.telefone;
                }
                
                await axios.post('https://n8n.corpbrasil.cloud/webhook/63c5c9c2-881e-436f-addd-198c2c0b7199', {
                    visitId: idVisit,
                    telefone: telefone ?? '5515991573088',
                    telefone_cliente: visit.preData.telefone,
                    nome: visit.cliente,
                    consultora: visit.consultora,
                    cidade: visit.cidade,
                    endereco: visit.endereco,
                    status: value,
                    ...props,
                    data: `${moment(visit.data).format('DD/MM/YYYY')} às ${visit.chegadaCliente}`,
                });
                
                const msg = await Swal.fire({
                    title: 'Sucesso',
                    html: `A Visita foi confirmada com sucesso!`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                })

                if(msg.isConfirmed) {
                    window.location.reload();
                }

            }

        } catch (error) {
            console.log('Erro:', error)
        }
      }

  return (
    <div className={styles.visit_container}>
            {visit ? 
                <div className={styles.visit_content}>
                    {visit.confirmarVisita ? 
                        <div className={styles.confirm_container}>
                            <VerifiedRoundedIcon sx={{ fontSize: '115px', color: "#72cd4e" }} />
                            <Typography variant='h4' fontWeight={'bold'}>Visita Confirmada!</Typography>
                            <Typography>Agradecemos por confirmar a visita. Estamos muito felizes em apresentar
                                <b className={styles.text_important}> nossas soluções de energia solar </b>e como <b className={styles.text_important}> podemos contribuir para a sua melhor decisão.</b>
                            </Typography>
                            <Typography>Enquanto isso, conheça um pouco mais a <b>CORPBRASIL Energia Renovável</b>.</Typography>
                            <Typography fontSize={'30px'}>👇</Typography>
                            <Newtooltip title='Clique aqui para acessar o nosso instagram' placement='top'>
                                <a href='https://www.instagram.com/corpbrasilenergia/' className={styles.instagram}>
                                    <Instagram />
                                    <div>
                                        <span>Acesse Nosso Instagram</span>
                                        <h4>@corpbrasilenergia</h4>
                                    </div>
                                </a>
                            </Newtooltip>
                        </div> :
                        <>
                            <img style={{ maxWidth: '200px'}} src={Logo2} alt='' />
                            <div className={styles.visit_title}>
                                {/* <EventAvailableIcon sx={{ fontSize: '45px' }} /> */}
                                <div className={styles.logo_title}>
                                    <h2><b>CORP</b>BRASIL</h2>
                                    <h3>ENERGIA RENOVAVEL</h3>
                                </div>
                                <Typography variant='h4'> Confirmação de Visita</Typography>
                            </div>
                            <p>Olá, <b style={{ fontSize: '1.2rem' }}>{visit && visit.cliente}</b>!</p>
                            <p style={{ lineHeight: '1.3rem' }}>Por favor, confirme o agendamento da sua visita:</p>
                            {/* <p>Seguem as informações abaixo:</p> */}
                            <div className={styles.box_info}>
                                <p>📅 <b>Data:</b> {moment(visit && visit.dia).format('DD/MM/yyyy')}</p>
                                <p>🕔 <b>Horário:</b> {visit && visit.chegadaCliente}</p>
                                <p>🏠 <b>Endereço:</b> {visit && visit.endereco}</p>
                                <p>🏙 <b>Cidade:</b> {visit && visit.cidade}</p>
                                {visit.categoria === 'tecnica' ? 
                                <p>🧑‍🔧 <b>Técnico:</b> {visit && visit.tecnico}</p> : 
                                <p>🙎 <b>Vendedora:</b> {visit && visit.consultora}</p>
                                }
                            </div>
                            <p className={styles.pergunta}>O horário está de acordo com o combinado?</p>
                            <Box sx={{ 
                                display: 'flex',
                                gap: '1rem',
                                width: '100%'
                            }}>
                                <Button 
                                    disableElevation
                                    variant='contained'
                                    fullWidth
                                    size='large'
                                    color='success'
                                    sx={{ textTransform: 'capitalize' }}
                                    onClick={() => sendConfirm('✅ Aceito')}
                                >Sim</Button>
                                <Button 
                                    disableElevation
                                    variant='contained'
                                    fullWidth
                                    size='large'
                                    color='error'
                                    sx={{ textTransform: 'capitalize' }}
                                    onClick={() => sendConfirm('❌ Recusado')}
                                >Não</Button>
                            </Box>
                        </>
                    }
                </div>
                : 
                <Box sx={{ width: '100%', height: '90vh', display: 'flex' }}>
                    <CircularProgress sx={{ margin: 'auto' }} color='success' />
                </Box>
            }
        </div> 
  )
}

export default ConfirmVisit
import { setDoc, doc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // cria alertas personalizado
import { auth } from "../../../../firebase/database";
import { dataBase } from "../../../../firebase/database";
import { Company } from "../../../../data/Data";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// import "../../_modal.scss";

import { PatternFormat } from "react-number-format";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { theme } from '../../../../data/theme';
import { ThemeProvider, DialogContentText, InputAdornment } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

import axios from 'axios';
import styles from "../styles.module.scss";

const CreateAdmin = ({ userRef, members, open, close, openBox  }) => {
  // const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [check, setCheck] = useState({});
  const [cargo, setCargo] = useState("Vendedor(a)");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [idCRM, setIdCRM] = useState("0");
  const [telefone, setTelefone] = useState("");
  const [veiculo, setVeiculo] = useState("0");
  const [cor, setCor] = useState("#000000");
  const [cidade, setCidade] = useState("");
  const [pix, setPix] = useState("");
  const [grupo, setGrupo] = useState({
    time: '',
    numero: ''
  });

  useEffect(() => {
    if(userRef && members) {
      if(userRef.cargo === 'Vendedor(a)') {
        setCargo('Representante');
      }
      if(userRef.time && userRef.cargo === 'Vendedor(a)') {
        handleTeam(userRef.time);
      }
    }
    if(open) {
      setCheck({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userRef, members, open])

  const onSubmit = async (e) => {
    e.preventDefault();
    close()
    const findEmail = members.find((member) => member.email === email);
    if (findEmail) {
      Swal.fire({
        title: Company,
        html: `O email <b>${email}</b> já está cadastrado no sistema.`,
        icon: "warning",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      });
      return openBox('create');
    } else {
      try {
        Swal.fire({
          title: Company,
          text: `Você deseja cadastrar um novo Colaborador(a)?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const telefoneFormatado = telefone.replace(/\D/g, '');
            await createUserWithEmailAndPassword(
              auth,
              email,
              senha
            ).then(async (userCredential) => {
                // Signed in
                updateProfile(auth.currentUser, {
                  displayName: nome,
                })
                const user = userCredential.user;
                let props = {
                  email: email,
                  nome: nome,
                  senha: senha,
                  cor: cor,
                  veiculo: veiculo,
                  cargo: cargo,
                  uid: user.uid,
                  relatorio: 0,
                  id_sm: idCRM,
                  telefone: telefoneFormatado,
                  cidade: cidade,
                  pix: pix,

                }
                if(cargo === 'Representante') {
                  props = {
                    ...props,
                    grupo: {
                      ...grupo
                    },
                    id_representante: `${grupo.time}${grupo.numero}`
                  }
                } 
                await setDoc(doc(dataBase, "Membros", user.uid), props);
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
              });
              if(cargo === 'Representante') {
                await axios.post('https://n8n.corpbrasil.cloud/webhook/ba8e96f9-00a3-4ccb-b219-93547f64eb12', {
                  nome: nome,
                  email: email,
                  senha: senha,
                  telefone: telefoneFormatado
                })
              }
            navigate("/");
            Swal.fire({
              title: Company,
              html: `O Colaborador(a) <b> ${nome}</b> foi cadastrado com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#111",
            })
            // .then((result) => {
            //   if (result.isConfirmed) {
            //     window.location.reload(true); // Recarrega a pagina
            //   }
            // });
          } else {
            openBox('create')
          }
        });
      } catch (error) {
        // console.log(error);
      }
    }
  };

const handleTeam = (value) => {
  const representantes = members.filter(member => member.cargo === 'Representante' && member.grupo?.time === value);
  const representanteId = representantes.length + 1
  setGrupo({
    time: value,
    numero: representantes.length > 0 ? representanteId.toString().padStart(2, '0') : '01'
  })
  console.log(value)
  const corRef = value === 'CBRLJ' ? '#FF8957' : '#52CBFF';
  setCor(corRef);
}

const handleCargo = (value) => {
  setCargo(value);
  if(value === 'Representante') {
    const corRef = grupo.time === 'CBRLJ' ? '#FF8957' : '#52CBFF';
    setCor(corRef);
  } else {
    setCor("#111111");
  }
}

const checkCode = (value) => {
  console.log(value);
  const representantes = members.filter(member => member.cargo === 'Representante' && member.grupo?.time === grupo.time);
  return !!representantes.find(data => data.id_representante === value);
}

const validateInput = (name, value) => {
  let errormsg = '';
  switch(name) {
    case 'numero':
      if(!value) {
        errormsg = 'Código Inválido.'
      }
      if(checkCode(`${grupo.time}${value}`)) {
        errormsg = 'Este código já está em uso.'
      }
    break;
    default: return null;
  }

  return errormsg;
}

const handleBlur = (e) => {
  const { name, value } = e.target;
  const error = validateInput(name, value);
  console.log(check);
  setCheck({...check, 
    [name]: error
  })
}

const handleChangeGrupo = (event) => {
  const { name, value } = event.target;
  setGrupo({
   ...grupo,
    [name]: value
  })
  setCheck({
    ...check,
    [name]: ''
  })
}

console.log(grupo)

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() => close()}
    >
      <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <DialogTitle align="center">Cadastrar Colaborador(a)</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem' }}>
          Preencha os campos abaixo para cadastrar um novo <b>Colaborador</b>.
        </DialogContentText>
        <form onSubmit={onSubmit}>
        <ThemeProvider theme={theme}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nome"
          type="text"
          value={nome ? nome : ''}
          onChange={(e) => setNome(e.target.value)}
          fullWidth
          required
          variant="outlined"
        />
        <TextField
          margin="dense"
          id="name"
          label="Email"
          type="text"
          value={email ? email : ''}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          variant="outlined"
        />
        <TextField
          margin="dense"
          id="name"
          label="Senha"
          type="text"
          inputProps={{
            minLength: 6
          }}
          value={senha ? senha : ''}
          onChange={(e) => setSenha(e.target.value)}
          fullWidth
          required
          variant="outlined"
        />
          <FormControl sx={{ margin: "0.3rem 0" }} fullWidth>
            <InputLabel id="simple-select-label">Cargo</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={cargo ? cargo : ''}
              label="Cargo"
              disabled={userRef && userRef.cargo !== 'Administrador' ? true : false}
              onChange={(e) => handleCargo(e.target.value)}
              required
            >
              <MenuItem value="Vendedor(a)">Vendedor(a)</MenuItem>
              <MenuItem value="Técnico">Técnico</MenuItem>
              <MenuItem value="Administrador">Administrador</MenuItem>
              <MenuItem value="Instalador">Instalador</MenuItem>
              <MenuItem value="Representante">Representante</MenuItem>
              <MenuItem value="Limpeza">Limpeza</MenuItem>
            </Select>
          </FormControl>
          {cargo === 'Representante' ? 
          <><div className={styles.input_double}>
            <FormControl sx={{ margin: "0.5rem 0" }} fullWidth>
              <InputLabel id="simple-select-label">Time</InputLabel>
              <Select
                labelId="simple-select-label"
                value={grupo.time || ''}
                label="Time"
                disabled={userRef && userRef.cargo !== 'Administrador' ? true : false}
                onChange={(e) => handleTeam(e.target.value)}
                required
              >
                <MenuItem value="CBRLJ">Laranja</MenuItem>
                <MenuItem value="CBRAZ">Azul</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="numero"
              id="name"
              label="Código do Representante"
              inputProps={{ 
                minLength: 2,
                maxLength: 2
               }}
              type="number"
              value={grupo.numero || ''}
              onChange={handleChangeGrupo}
              onBlur={handleBlur}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"><b style={{ color: "#3C3C3C" }}>{grupo.time}</b></InputAdornment>,
              }}
              error={!!check.numero}
              helperText={check.numero}
              FormHelperTextProps={{
                component: (item) => {
                    return (
                    <span className={`${item.className} ${styles.error}`}>
                        <ErrorIcon sx={{ fontSize: '1.2rem' }} /> {item.children}
                    </span>
                    );
                },
            }}
              required
              variant="outlined"
            />
          </div>
          <div className={styles.input_double}>
            <TextField
              margin="dense"
              id="name"
              label="Cidade"
              value={cidade ? cidade : ''}
              onChange={(e) => setCidade(e.target.value)}
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              margin="dense"
              id="name"
              label="Chave Pix"
              value={pix ? pix : ''}
              onChange={(e) => setPix(e.target.value)}
              fullWidth
              required
              variant="outlined"
            />
          </div></>
           :
          <><TextField
            margin="dense"
            id="name"
            label="ID do CRM"
            type="number"
            value={idCRM ? idCRM : ''}
            onChange={(e) => setIdCRM(e.target.value)}
            fullWidth
            required
            variant="outlined"
          />
            <TextField
            margin="dense"
            id="name"
            label="Veiculo"
            type="text"
            value={veiculo ? veiculo : ''}
            onChange={(e) => setVeiculo(e.target.value)}
            fullWidth
            required
            variant="outlined"
          /></>
          }
        <div className={styles.label_content}>
          <div>
            <span>Telefone</span>
            <PatternFormat
              className="label__input"
              onChange={(e) => setTelefone(e.target.value)}
              format="## (##) ##### ####"
              mask="_"
              placeholder="00 (00) 00000 0000"
              value={telefone ? telefone : ''}
              label="Telefone"
              minLength={9}
              variant="outlined"
              color="primary"
              required
            />
          </div>
          <div>
          <span>Cor</span>
          <input type="color"
            className={styles.color}
            value={cor ? cor : ''}
            style={{ backgroundColor: cor }}
            onChange={(e) => setCor(e.target.value)}
            required
            disabled={userRef && userRef.cargo !== 'Administrador' ? true : false}
          />
             <p className={styles.name_color}>{nome && nome}</p>
          </div>
        </div>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
          <DialogActions sx={{ justifyContent: 'center' }}>
          <Button 
           disableElevation
           sx={{ textTransform: 'capitalize' }}
           variant="contained"
           color="success"
           disabled={!!check.numero}
           type="submit">Confirmar</Button>
          <Button
            disableElevation
            color="error"
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            onClick={() => close()}>Cancelar</Button>
        </DialogActions>
          </ThemeProvider>
        </form>
      </DialogContent>
    </Dialog>
    // <div className="modal-visit">
    //   <div className="modal-box-visit">
    //     <div className="modal-box-visit__close">
    //       <button onClick={returnAdmin} className="btn-close" />
    //     </div>
    //     <h4>Cadastrar novo Colaborador</h4>
    //     <form className="form-visit" onSubmit={handleSubmit(onSubmit)}>
    //       <label className="form-visit__label">
    //         <input
    //           className="form-visit__text"
    //           type="text"
    //           placeholder="Digite o nome"
    //           autoComplete="off"
    //           onBlur={(e) => setNome(e.target.value)}
    //           required
    //           minLength={3}
    //         />
    //       </label>
    //       <label className="form-visit__label">
    //         <input
    //           className="form-visit__text"
    //           type="email"
    //           placeholder="Digite o email"
    //           autoComplete="off"
    //           {...register("email")}
    //           required
    //         />
    //       </label>
    //       <div className="form-visit__double">
    //         <label className="form-visit__label">
    //           <input
    //             className="form-visit__text"
    //             type="text"
    //             placeholder="Digite a senha"
    //             autoComplete="off"
    //             {...register("senha")}
    //             required
    //             minLength={6}
    //           />
    //         </label>
    //         <select
    //           value={cargo}
    //           name="cargo"
    //           onChange={(e) => setCargo(e.target.value)}
    //         >
    //           <option value="Vendedor(a)">Vendedor(a)</option>
    //           <option value="Técnico">Técnico</option>
    //           <option value="Administrador">Administrador</option>
    //         </select>
    //       </div>
    //       {cargo === "Vendedor(a)" || cargo === "Administrador" ? (
    //         <>
    //           <div className="form-visit__color">
    //             <p>Escolha uma cor de destaque</p>
    //             <input
    //               type="color"
    //               autoComplete="off"
    //               value={cor}
    //               onChange={(e) => setCor(e.target.value)}
    //               required
    //             />
    //           </div>
    //           {nome && (
    //             <div className="form-visit__exemple">
    //               <h3>Resultado:</h3>
    //               <p
    //                 style={
    //                   cor && {
    //                     backgroundColor: cor,
    //                     borderBottom: "1px solid" + cor,
    //                     borderRight: "1px solid" + cor,
    //                     borderLeft: "1px solid" + cor,
    //                     color: "#fff",
    //                     textShadow: "#5a5a5a -1px 0px 5px",
    //                   }
    //                 }
    //               >
    //                 {nome}
    //               </p>
    //             </div>
    //           )}
    //         </>
    //       ) : (
    //         <label className="form-visit__label">
    //           <input
    //             className="form-visit__text"
    //             type="number"
    //             placeholder="Digite o número do veículo"
    //             autoComplete="off"
    //             onInput={(e) => (e.target.value = e.target.value.slice(0, 3))}
    //             {...register("veiculo")}
    //             required
    //           />
    //         </label>
    //       )}
    //       <input className="form-visit__btn" type="submit" value="CRIAR" />
    //     </form>
    //   </div>
    // </div>
  );
};

export default CreateAdmin;

import { useState, memo, useEffect } from "react";
import { Label, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

// Css
// import "cooltipz-css";
import styles from "../style.module.scss";
// import '../../styles/_filter.scss';

import { PatternFormat } from "react-number-format";

import { TextField } from "@mui/material";

import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../data/theme";
import { doc, onSnapshot } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Logo from "../../../images/LogoCORPBRASIL.png";

const ViewReport = ({ user, userRef }) => {
  const { idReport } = useParams();
  const [item, setItem] = useState([]);
  const [boxImg, setBoxImg] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [valueRef, setValueRef] = useState(0);
  const [yAxisMax, setYAxisMax] = useState(0);

  useEffect(() => {
    const getVisit = async () => {
      await onSnapshot(doc(dataBase, "Relatorio", idReport), (doc) => {
        setItem(doc.data());
      });
    };
    getVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(item)

  useEffect(() => {
    if(item) {
      const imgsBoxRef = [];
      item.box && item.box.map((ref, index) => {
        return imgsBoxRef[index] = [...ref.arquivo.url]
      })
      setBoxImg(imgsBoxRef);

      setDataChart([
        {
          name: item.geracao && item.geracao[0].mes,
          geracao: Number(item.geracao && item.geracao[0].kwh)
        },
        {
          name: item.geracao && item.geracao[1].mes,
          geracao: Number(item.geracao && item.geracao[1].kwh)
        },
        {
          name: item.geracao && item.geracao[2].mes,
          geracao: Number(item.geracao && item.geracao[2].kwh)
        }
      ])

      const diff20 = ((item.result?.media_geracao / 100) * 20)
      setValueRef((item.result?.media_geracao - diff20).toFixed(0));

      document.title = item.info?.cliente ? item.info?.cliente + ' - Relatório Geração x Consumo - CORPBRASIL Energia Solar' : 'Relatório Geração x Consumo - CORPBRASIL Energia Solar';
    }
  },[item])

 useEffect(() => {
  if(dataChart) {
    const maxValue = Math.max(...dataChart.map(entry => entry.geracao));
    setYAxisMax(Math.ceil(maxValue * 1.2));
  }
 },[dataChart])

  const CustomizedLabel = ({ x, y, stroke, value }) => {
    return (
      <text x={x} y={y} dy={-10} fill={stroke} fontSize={15} textAnchor="middle">
        {value}
      </text>
    );
  };

  return (
    <div className={styles.container_panel} style={{ backgroundColor: "#fff" }}>
      {userRef && 
        <div className={styles.pdf_buttom}>
            <button 
            aria-label='Gerar PDF / Imprimir'
            data-cooltipz-dir="left"
            onClick={() => window.print()}>
              <PictureAsPdfIcon sx={{ fill: '#fff' }} />
            </button>
          </div>
      }
      <div className={styles.title_panel}>
        <img src={Logo} alt="" />
        {/* <ReportIcon className={styles.prospecction_icon}/> */}
        <h1>Relatório</h1>
        <h2>Geração x Consumo</h2>
      </div>
      <div className={styles.report_container}>
        <ThemeProvider theme={theme}>
        <form autoComplete="false">
          <div className={styles.report_container_box2}> 
            <div className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Informações</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`}>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Nome da Analista'
                  type="text"
                  value={item.info?.analista || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
                <TextField fullWidth
                  label='Data do Relatório'
                  type="date"
                  value={item.info?.data || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Cliente'
                  type="text"
                  value={item.info?.cliente || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  }/>
                <TextField fullWidth
                  label='Email'
                  type="email"
                  value={item.info?.email || 'Não informado'}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
                <PatternFormat
                  fullWidth
                  customInput={TextField}
                  format="(##) #####-####"
                  mask="_"
                  placeholder="(00) 00000-0000"
                  value={item.info?.telefone || ''}
                  label="Telefone"
                  minLength={9}
                  variant="outlined"
                  color="primary"
                  inputProps={
                    { readOnly: true }
                  }
                />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Cidade'
                  type="text"
                  value={item.info?.cidade || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
                <TextField fullWidth
                  label='Data do Chamado'
                  type="date"
                  value={item.info?.data_chamado || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
              </div>
            </div>
          </div>
          <div className={styles.report_container_box2}> 
            <div className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Sistema</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`}>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Potência do Sistema (kWp)'
                  type="number"
                  value={item.sistema?.potencia_sistema || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
                <TextField fullWidth
                  label='Potência do Inversor (kW)'
                  type="number"
                  value={item.sistema?.potencia_inversor || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Quantidade de Placa'
                  type="number"
                  value={item.sistema?.placa_quantidade || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
                <TextField fullWidth
                  label='Potência individual da Placa (Wp)'
                  type="number"
                  value={item.sistema?.placa_potencia || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
              </div>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Potência Total das Placas (Wp)'
                  type="number"
                  value={item.sistema?.placa_potencia * item.sistema?.placa_quantidade || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  }/>
              </div>
              <div className={styles.report_img_full}>
                {item.sistema && Array.isArray(item.sistema.img) ? (
                  item.sistema.img.map((data, index) => (
                    <img
                      key={index}
                      className={styles.report_img_view}
                      src={data.url}
                      alt=""
                    />
                  ))
                ) : item.sistema?.img ? (
                  <img
                    className={styles.report_img_view}
                    src={item.sistema.img.url}
                    alt=""
                  />
                ) : null}
            </div>
            </div>
          </div>
          <div className={styles.report_container_box2}> 
            <div className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Geração</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`}>
              <div className={styles.report_input}>
                <TextField fullWidth
                  label='Unidade Geradora'
                  type="number"
                  value={item.report?.unidade_geradora || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  }/>
                <TextField fullWidth
                  label='Média de Consumo antes da instalação'
                  type="number"
                  value={item.report?.consumo_media || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  }/>
                <TextField fullWidth
                  label='Média da Geração Estimada'
                  type="number"
                  value={item.report?.geracao_media || ''}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={
                    { readOnly: true }
                  } />
              </div>
              <div className={styles.report_input}>
                <h4>Monitoramento da Geração dos últimos 3 meses</h4>
              </div>
              {item.geracao && item.geracao.map((item, index) => (
                <><div key={index} className={styles.report_input}>
                  <TextField fullWidth
                    label='Mês'
                    type="text"
                    value={item.mes || ''}
                    variant="outlined"
                    autoComplete="off"
                    inputProps={
                      { readOnly: true }
                    } />
                  <TextField fullWidth
                    label='Ano'
                    type="text"
                    value={item.ano || ''}
                    variant="outlined"
                    autoComplete="off"
                    inputProps={
                      { readOnly: true }
                    } />
                  <TextField fullWidth
                    label='kWh'
                    type="text"
                    value={item.kwh || ''}
                    variant="outlined"
                    autoComplete="off"
                    inputProps={
                      { readOnly: true }
                    } />
                    {item.estimado ?
                      <TextField fullWidth
                        label='kWh Estimado'
                        type="text"
                        value={item.estimado || ''}
                        variant="outlined"
                        autoComplete="off"
                        sx={{
                          backgroundColor: "#79ff792b",
                          '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                            '& fieldset': { // Seleciona a borda do TextField
                              borderColor: 'green', // Define a cor da borda
                            },
                            '&:hover fieldset': { // Estilo da borda quando hover
                              borderColor: 'green',
                            },
                            '&.Mui-focused fieldset': { // Estilo da borda quando focado
                              borderColor: 'green',
                            },
                          },
                        }}
                        inputProps={
                          { readOnly: true }
                        } /> : null
                     }
                </div>
                <div className={styles.report_input}>
                  {item.img?.url && 
                    <img className={styles.report_img_view} src={item.img?.url} alt="" />
                  }
                </div>
                </>
              ))}
            </div>
          </div>
          <div className={styles.report_container_box2}> 
            <div className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Resumo da Geração</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`} style={{ gap: '1rem' }}>
              <div className={styles.report_input}>
                <div className={styles.report_text}>
                  <h4>Geração Média Estimada do Projeto</h4>
                  <p><b>{item.report?.geracao_media}</b> kWh</p>
                </div>
            </div>
            <h4>Geração Produzida</h4>
            <div className={styles.report_text_container}>
              {item.geracao && item.geracao.map((data, index) => (
                <div key={index} className={styles.report_text}>
                  <p>{data.mes ?? 'Mês'} / {data.ano ?? 'Ano'} = <b>{data.kwh}</b> kWh</p>
                </div>
              ))}
            </div>
            </div>
          </div>
          <div className={styles.report_container_box2}> 
            <div className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Conclusão</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`}>
              <div className={styles.report_text_container}>
                <div className={styles.report_text}>
                  <h4>Geração Média Estimada Mês</h4>
                  <p><b>{item.report?.geracao_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Geração Média Produzida Mês</h4>
                  <p><b>{item.result?.media_geracao}</b> kWh</p>
                </div>
              </div>
              <div className={styles.report_input}>
                <div style={{ backgroundColor: item.result?.color_geracao?.background ?? '#fdf6e4',
                  padding: '1rem',
                  borderRadius: '5px',
                  border: `1px solid ${item.result?.color_geracao?.border ?? "#f3c700"}`
                 }} className={styles.report_text}>
                  <h4>Diferença de Geração</h4>
                  {item.result && parse(item.result?.text_geracao_ref)}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.report_container_box2}> 
            <div className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Consumo</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`}>
              <div className={styles.report_input}>
                <h4>Média de Consumo Após a Instalação</h4>
              </div>
              {item.consumo && item.consumo.map((ref, index) => (
                <><div key={index} className={styles.report_input}>
                  <TextField fullWidth
                    label='Mês'
                    type="text"
                    value={ref.mes || ''}
                    variant="outlined"
                    autoComplete="off"
                    inputProps={
                      { readOnly: true }
                    } />
                  <TextField fullWidth
                    label='Ano'
                    type="text"
                    value={ref.ano || ''}
                    variant="outlined"
                    autoComplete="off"
                    inputProps={
                      { readOnly: true }
                    } />
                  <TextField fullWidth
                    label='Injetado p/ Conce (kWh)'
                    type="text"
                    value={ref.injetado || ''}
                    variant="outlined"
                    autoComplete="off"
                    sx={{
                      '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                        '& fieldset': { // Seleciona a borda do TextField
                          borderColor: '#e3bf00', // Define a cor da borda
                        },
                        '&:hover fieldset': { // Estilo da borda quando hover
                          borderColor: '#e3bf00',
                        },
                        '&.Mui-focused fieldset': { // Estilo da borda quando focado
                          borderColor: '#e3bf00',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: '#111', // Define a cor padrão do título
                        '&.Mui-focused': {
                          color: '#111', // Define a cor do título quando focado
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: '#111', // Define a cor da fonte
                        backgroundColor: '#fef8da'
                      },
                      readOnly: true
                    }}  />
                  <TextField fullWidth
                    label='Fornec. Conce (kWh)'
                    type="text"
                    value={ref.fornecido || ''}
                    variant="outlined"
                    autoComplete="off"
                    sx={{
                      '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                        '& fieldset': { // Seleciona a borda do TextField
                          borderColor: 'red', // Define a cor da borda
                        },
                        '&:hover fieldset': { // Estilo da borda quando hover
                          borderColor: 'red',
                        },
                        '&.Mui-focused fieldset': { // Estilo da borda quando focado
                          borderColor: 'red',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: '#111', // Define a cor do título
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: '#111', // Define a cor da fonte
                        backgroundColor: '#ffeded'
                      },
                      readOnly: true
                    }}
                    />
                  <TextField fullWidth
                    label='Geração (kWh)'
                    type="text"
                    value={item.geracao[index]?.kwh || ''}
                    variant="outlined"
                    autoComplete="off"
                    sx={{
                      '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                        '& fieldset': { // Seleciona a borda do TextField
                          borderColor: 'green', // Define a cor da borda
                        },
                        '&:hover fieldset': { // Estilo da borda quando hover
                          borderColor: 'green',
                        },
                        '&.Mui-focused fieldset': { // Estilo da borda quando focado
                          borderColor: 'green',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: '#111', // Define a cor padrão do título
                        '&.Mui-focused': {
                          color: '#111', // Define a cor do título quando focado
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: '#111', // Define a cor da fonte
                        backgroundColor: '#17ff172b'
                      },
                      readOnly: true
                    }} />
                    <TextField fullWidth
                    label='Instantâneo (kWh)'
                    type="text"
                    value={Number(ref.instantaneo).toFixed(2) || ''}
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }} />
                    <TextField fullWidth
                    label='Consumo Total (kWh)'
                    type="text"
                    value={ref.consumo || ''}
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }} />
                </div>
                <div className={styles.report_input}>
                  {ref.img?.url && 
                    <img className={styles.report_img_view} src={ref.img?.url} alt="" />
                  }
                </div>
                </>
              ))}
              <div className={styles.report_text_container}>
                <div className={styles.report_text}>
                  <h4>Média Consumo <mark>Antes</mark> do Sistema</h4>
                  <p><b>{item.report?.consumo_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Consumo <mark>Após</mark> o Sistema</h4>
                  <p><b>{item.report?.consumo_atual}</b> kWh</p>
                </div>
              </div>
              <div className={styles.report_input}>
                <div style={{ backgroundColor: item.result?.color_consumo?.background ?? '#fdf6e4',
                  padding: '1rem',
                  borderRadius: '5px',
                  border: `1px solid ${item.result?.color_consumo?.border ?? "#f3c700"}`
                 }} className={styles.report_text}>
                  <h4>Resultado de Consumo</h4>
                  {item.result && parse(item.result?.text_consumo_ref)}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.report_container_box2}> 
            <div className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Conclusão Final</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`}>
              <div className={styles.report_text_container}>
                <div className={styles.report_text}>
                  <h4>Média Consumo Antes</h4>
                  <p><b>{item.report?.consumo_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Consumo Atual</h4>
                  <p><b>{item.report?.consumo_atual}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Geração Estimada do Projeto</h4>
                  <p><b>{item.report?.geracao_media}</b> kWh</p>
                </div>
                <div className={styles.report_text}>
                  <h4>Média Geração Produzida</h4>
                  <p><b>{item.result?.media_geracao}</b> kWh</p>
                </div>
                <div className={styles.report_text} style={{ width: '210px' }}>
                  <h4>Estimada x Produzida</h4>
                  <p><b>{item.report?.geracao_media}</b> kWh x <b>{item.result?.media_geracao}</b> kWh</p>
                </div>
              </div>
              <div className={styles.report_text_container} style={{ height: '300px', marginTop: '1rem' }}>
              <ResponsiveContainer width="110%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={dataChart}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis domain={[0, yAxisMax]} />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="geracao" name="Geração Produzida" stroke="#0eb05f" strokeWidth={3} label={<CustomizedLabel />} />
                  <ReferenceLine y={item.report?.geracao_media} label={<Label value={`Geração Estimada (${item.report?.geracao_media} kWh)`} position="insideBottomRight"/> } stroke="#8884d8" strokeWidth={2} />
                  <ReferenceLine y={valueRef} label={<Label value={`Dentro do estimado (${valueRef} kWh)`} position="insideTopRight"/> } stroke="red" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
              </div>
            </div>
          </div>
          {item.beneficiaria && item.beneficiaria.map((data, index) => (
            <div key={index} className={styles.report_container_box2}> 
              <div className={`${styles.report_header} ${styles.report_header_view}`}>
                <input 
                type="text"
                name="titulo"
                value={data.titulo ?? ''}
                autoComplete="off"
                style={{ width: `${data.titulo.length * 13 + 5}px`, textAlign: 'center'  }}/>
                <div className={styles.title_content}>
                  <h3>UC: </h3>
                  <input 
                  type="text"
                  name="codigo"
                  value={data.codigo ?? ''}
                  autoComplete="off"
                  style={{ width: `${data.codigo.length * 13 + 5}px`  }}/>
                </div>
                <div className={styles.title_content}>
                  <h3>Média do Consumo Antes do Projeto:</h3>
                  <input 
                  type="text"
                  name="valor"
                  value={data.valor ?? ''}
                  autoComplete="off"
                  style={{ width: `${data.valor.length * 13 + 5}px`  }} />
                  <h3>kWh</h3>
                </div>
              </div>
              <div className={`${styles.report_form} ${styles.font_large}`}>
                {data.dados && data.dados.map((ref, ind) => (
                  <><div key={ind} className={styles.report_input}>
                    <TextField fullWidth
                      label='Mês'
                      name="injetado"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={ref.mes || ''}
                      variant="outlined"
                      autoComplete="off" />
                    <TextField fullWidth
                      label='Ano'
                      name="injetado"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={ref.ano || ''}
                      variant="outlined"
                      autoComplete="off" />
                    <TextField fullWidth
                      label='Injetado p/ Conce (kWh)'
                      name="injetado"
                      type="text"
                      sx={{
                        '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                          '& fieldset': { // Seleciona a borda do TextField
                            borderColor: '#e3bf00', // Define a cor da borda
                          },
                          '&:hover fieldset': { // Estilo da borda quando hover
                            borderColor: '#e3bf00',
                          },
                          '&.Mui-focused fieldset': { // Estilo da borda quando focado
                            borderColor: '#e3bf00',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: '#111', // Define a cor padrão do título
                          '&.Mui-focused': {
                            color: '#111', // Define a cor do título quando focado
                          },
                        },
                      }}
                      InputProps={{
                        sx: {
                          color: '#111', // Define a cor da fonte
                          backgroundColor: '#fef8da'
                        },
                        readOnly: true
                      }}
                      value={ref.injetado || ''}
                      variant="outlined"
                      autoComplete="off" />
                    <TextField fullWidth
                      label='Fornec. Conce (kWh)'
                      name="injetado"
                      type="text"
                      sx={{
                        '& .MuiOutlinedInput-root': { // Seleciona o elemento raiz do TextField
                          '& fieldset': { // Seleciona a borda do TextField
                            borderColor: 'red', // Define a cor da borda
                          },
                          '&:hover fieldset': { // Estilo da borda quando hover
                            borderColor: 'red',
                          },
                          '&.Mui-focused fieldset': { // Estilo da borda quando focado
                            borderColor: 'red',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: '#111', // Define a cor do título
                        },
                      }}
                      InputProps={{
                        sx: {
                          color: '#111', // Define a cor da fonte
                          backgroundColor: '#ffeded'
                        },
                        readOnly: true
                      }}
                      value={ref.injetado || ''}
                      variant="outlined"
                      autoComplete="off" />
                  </div>
                  <div className={styles.report_input}>
                  {ref.img?.url && 
                    <img className={styles.report_img_view} src={ref.img?.url} alt="" />
                  }
                </div>
                  </>
                ))}
                <div></div>
                <div className={styles.report_text_container}>
                  <div className={styles.report_text}>
                    <h4>Média Injeção de Crédito</h4>
                    <p><b>{data.media_injecao}</b> kWh</p>
                  </div>
                  <div className={styles.report_text}>
                    <h4>Média Fornecido Concessionária</h4>
                    <p><b>{data.media_consumo}</b> kWh</p>
                  </div>
                </div>
                <div className={styles.report_input}>
                  <div style={{ backgroundColor: data.resultado_cor?.background ?? '#fdf6e4',
                  padding: '1rem',
                  borderRadius: '5px',
                  border: `1px solid ${data.resultado_cor?.border ?? "#f3c700"}`
                 }} className={styles.report_text}>
                    <h4>Resultado de Consumo</h4>
                    {parse(data.resultado_texto_ref)}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {item.box && item.box.map((data, index) => (
            <div className={styles.report_container_box2}> 
              <div className={`${styles.report_header} ${styles.report_header_view}`}>
                <input 
                  type="text"
                  name="titulo"
                  style={{ textAlign: 'center' }}
                  value={data.titulo ?? ''}
                  placeholder="Digite um titulo"
                  autoComplete="off"/>
              </div>
              <div className={`${styles.report_form} ${styles.font_large}`}>
                 {boxImg.length > 0 && boxImg[index].map((ref, index) => (
                      <img className={styles.report_img_view} src={ref} alt="" />
                  ))}
                <div className={styles.report_input}>
                  <TextField fullWidth
                      label='Observação'
                      multiline
                      rows={5}
                      name="observacao"
                      type="text"
                      placeholder="Deixe uma observação a respeito"
                      value={data.observacao || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }} />
                </div>
              </div>
            </div>
          ))}
          <div className={styles.report_container_box2}> 
            <div style={{ backgroundColor: "#0eb05f" }} className={`${styles.report_header} ${styles.report_header_view}`}>
              <h3>Observação Final</h3>
            </div>
            <div className={`${styles.report_form} ${styles.font_large}`}>
              <div className={styles.report_input}>
                <TextField fullWidth
                    label='Observação'
                    multiline
                    rows={15}
                    name="kwh"
                    type="text"
                    placeholder="Deixe uma observação a respeito do relatório"
                    value={item.report?.observacao || ''}
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}/>
              </div>
            </div>
          </div>
          {/* <div className={styles.footer_button}>
          <Button 
            disableElevation
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            startIcon={<CheckIcon />}
            type="submit"
            color="success">Salvar Relátorio</Button>
            <Link to='/relatorio'>
              <Button 
                disableElevation
                sx={{ textTransform: 'capitalize' }}
                variant="contained"
                color="error">Cancelar</Button>
            </Link>
          </div> */}
        </form>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default memo(ViewReport);

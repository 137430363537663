import Swal from "sweetalert2";
import style from "./style.module.scss";
import axios from 'axios';
import moment from "moment";

import { theme } from "../../../../data/theme";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from '@mui/material/styles';
import { theme2 } from '../../../../data/theme';

import { useState } from "react";
import { dataBase } from "../../../../firebase/database";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const Analyse = ({visit, open, setOpenAnotation }) => {
  const [anotation, setAnotation] = useState('');
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const closeBox = () => {
    setOpenAnotation(false);
    setAnotation('');
    setDate(moment(new Date()).format('YYYY-MM-DD'));
  }

  console.log(visit)

  const sendAnalyseEnd = async() => {
    try {
      Swal.fire({
        title: 'Análise',
        html: `Você deseja concluir a <b>Análise?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if(result.isConfirmed) {

          const consultoraDoc = await getDoc(doc(dataBase, 'Membros', visit.uid));
          let phone = '';
          if (consultoraDoc.exists()) {
            const consultora = consultoraDoc.data();
            phone = consultora.telefone;
          }
          
          await updateDoc(doc(dataBase, 'Visitas', visit.id), {
            analise_status: 'Concluido',
            analise_anotacao: anotation,
            analise: {
              data: moment(date).format('DD/MM/YYYY')
            }
          }).then(async () => {
            await axios.post('https://n8n.corpbrasil.cloud/webhook/28172d9f-e133-4af1-a91d-6fc5bfe63620', {
              nome: visit.cliente,
              cidade: visit.cidade,
              concessionaria: visit.concessionaria,
              consultora: visit.consultora,
              phone: phone,
              marcado: visit.chegadaCliente,
              dia: moment(visit.data).format('DD/MM/YYYY'),
              anotacao: anotation,
              analista: visit.representante?.nome,
              id: visit.id
            }).then(() => {
              Swal.fire({
                title: 'Concluido',
                html: `<b>Análise</b> concluida com sucesso.`,
                icon: "success",
                showCloseButton: true,
                confirmButtonColor: "#F39200",
                confirmButtonText: "Ok",
                })
                closeBox();
            })
          })
        }
      })
    } catch (error) {
      console.error('Ocorreu um erro: ', error);
    }
  }

  return (
    <Dialog
      className={style.anotation_container}
      sx={{ padding: '1rem' }}
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => closeBox()}
      >
        <IconButton
          aria-label="close"
          onClick={() => closeBox()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
        <DialogTitle align='center'>Concluir Análise</DialogTitle>
        <DialogContent>
      <div className={style.register_container}>
        <DialogContentText sx={{ marginBottom: '0.2rem', textAlign: 'center' }}>
          Informe a <b>Data da Vistoria e Troca do Relógio</b>.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Nota"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
        <DialogContentText sx={{ marginBottom: '0.2rem' }}>
          Deixe uma nota a respeito da análise do <b>Padrão/Telhado</b> do cliente para concluir.
        </DialogContentText>
        <ThemeProvider theme={theme2}>
        <div className={style.input}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Nota"
            type="text"
            rows={10}
            multiline
            value={anotation}
            onChange={(e) => setAnotation(e.target.value)}
            variant="outlined" />
        </div>
        </ThemeProvider>
      </div>
        {/* {step === 1 &&
        <div className={style.view_send}>
          <DialogContentText sx={{ textAlign: "center", marginBottom: '1rem' }}>
          <h3>Enviando dados, aguarde...</h3>
          <ul>
          {checkPhotos.map((data, index) => (
              <li key={index}>
                {checkData[index] ?
                <CheckIcon color='success' /> :
                <CircularProgressWithLabel value={changeProgress[index]} /> 
              }
              {data}</li>
            ))}
          </ul>
        </DialogContentText>
      </div>} */}
        </DialogContent>
        <ThemeProvider theme={theme2}>
          <DialogActions sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
            <Button 
              disableElevation
              sx={{ textTransform: 'capitalize' }}
              variant='contained' 
              onClick={() => closeBox()}>Fechar</Button>
            <Button
              disableElevation
              sx={{ textTransform: 'capitalize' }}
              variant='contained' 
              color="success" 
              onClick={() => sendAnalyseEnd()}>Enviar</Button>
          </DialogActions>
        </ThemeProvider>
        </Dialog>
  )
}

export default Analyse;
import React, { createContext, useState, useEffect } from "react";
import { auth, dataBase } from "../firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import Loading from "../components/Loading";

export const AuthContext = createContext();

export default function AuthContextProvider(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      if (user) {
        const { uid, displayName, email } = user;
        const userDocRef = doc(dataBase, "Membros", uid);

        const unsubscribeDoc = onSnapshot(
          userDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              setUser({
                id: uid,
                name: displayName,
                email: email,
                ...userData, // Adiciona dados adicionais do Firestore
              });
            } else {
              console.error("No such document in membros collection!");
              setUser({
                id: uid,
                name: displayName,
                email: email,
              });
            }
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching user document:", error);
            setError(error);
            setLoading(false);
          }
        );

        // Limpa o listener do documento quando o componente desmonta
        return () => unsubscribeDoc();
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    // Limpa o listener de autenticação quando o componente desmonta
    return () => unsubscribeAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      {!loading ? props.children : <Loading view={true} />}
    </AuthContext.Provider>
  );
}
